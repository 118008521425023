import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { signupApi } from '../../../apis/authApi';

const initialState = {
  isLoadingSignupUser: false,
  isErrorSignupUser: false,
  isSuccessSignupUser: false,
};

const asyncSignupUserFetch = createAsyncThunk(
  'signupUserSlice/asyncSignupUserFetch',
  // 회원가입 관련 fetch 로직 작성
  async (values, { rejectWithValue }) => {
    try {
      await signupApi(values);
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message || '서버에러');
    }
  }
);

const signupUserSlice = createSlice({
  name: 'signupUser',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncSignupUserFetch.pending, (state) => {
      state.isLoadingSignupUser = true;
    });
    builder.addCase(asyncSignupUserFetch.fulfilled, (state) => {
      state.isLoadingSignupUser = false;
      state.isSuccessSignupUser = true;
    });
    builder.addCase(asyncSignupUserFetch.rejected, (state) => {
      state.isLoadingSignupUser = false;
      state.isErrorSignupUser = true;
    });
  },
});

export default signupUserSlice.reducer;
export { asyncSignupUserFetch };
