import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Tag } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import EmptyTable from './EmptyTable';
import { asyncReadBulkDataFetch } from '../../redux/modules/shared/readBulkData';
import downloadExcelFile from '../../utils/downloadExcelFile';

const TableBody = (props) => {
  const { tableDatas, tableColumns, loading } = props;

  const { handleAccountListTable } = downloadExcelFile();

  const history = useHistory();
  const dispatch = useDispatch();

  const url = window.location.pathname;

  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemsPerPage, setItemsPerPgae] = useState(20);
  const [totalData, setTotalData] = useState({
    loading: false,
    count: 0,
    amount: 0,
  });

  // 마운트시 (로컬 스토리지 값 불러옴)
  useEffect(() => {
    const currentPathname = history.location.pathname;
    const storedPathname = localStorage.getItem('pathname');
    // 현재 url과 저장된 url이 다르면 로컬스토리지에 저장된 값을 삭제하고 currentPage 초기화
    if (storedPathname !== currentPathname) {
      setCurrentPage(1);
      localStorage.removeItem('pathname');
      localStorage.removeItem('pageNumber');
    } else {
      const storedPageNumber = Number(localStorage.getItem('pageNumber'));
      setCurrentPage(storedPageNumber);
    }
  }, [history.location.pathname]);

  console.log(currentPage);

  // 페이지 변경시 (로컬 스토리지에 값 저장)
  const saveToLocalStorage = useCallback(
    (newCurrentPage) => {
      const currentPathname = history.location.pathname;
      localStorage.setItem('pathname', currentPathname);
      localStorage.setItem('pageNumber', newCurrentPage);
    },
    [history.location.pathname]
  );

  // 마지막 페이지
  const totalPages = Math.ceil(tableDatas.length / itemsPerPage);

  useEffect(() => {
    // 현재 페이지에 해당하는 데이터를 계산
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const slicedDatas = tableDatas.slice(startIndex, endIndex);
    setCurrentItems(slicedDatas);
  }, [currentPage, tableDatas, itemsPerPage, history.location.pathname]);

  // 이전 페이지
  const goToPrevPage = () => {
    const newCurrentPage = currentPage - 1;
    setCurrentPage(newCurrentPage);
    saveToLocalStorage(newCurrentPage);
  };
  // 다음 페이지
  const goToNextPage = () => {
    const newCurrentPage = currentPage + 1;
    setCurrentPage(newCurrentPage);
    saveToLocalStorage(newCurrentPage);
  };
  // 첫 페이지
  const goToFirstPage = () => {
    setCurrentPage(1);
    saveToLocalStorage(1);
  };
  // 마지막 페이지
  const goToLastPage = () => {
    setCurrentPage(totalPages);
    saveToLocalStorage(totalPages);
  };

  // 테이블이 order일 경우 건수 / 금액 출력
  useEffect(() => {
    const isOrderTable = url === '/sales-inquiry' || url === '/order';
    if (!isOrderTable) {
      return;
    }
    const getTotalData = async () => {
      setTotalData((prev) => ({ ...prev, loading: true }));
      const orderIds = await tableDatas.map((order) => order.id);

      const batchSize = 200;
      const orderProducts = [];

      for (let i = 0; i < orderIds.length; i += batchSize) {
        const slicedOrderIds = orderIds.slice(i, i + batchSize);
        const slicedProducts = await dispatch(
          asyncReadBulkDataFetch({
            table: 'order_product',
            column: 'order_id',
            array: slicedOrderIds,
          })
        )
          .unwrap()
          .then((res) => res);
        orderProducts.push(...slicedProducts);
      }

      const totalCount = tableDatas.length;
      const totalAmount = orderProducts.reduce((acc, cur) => {
        const { discounted } = cur;
        return acc + discounted;
      }, 0);

      setTotalData({
        loading: false,
        count: totalCount,
        amount: totalAmount,
      });
    };
    getTotalData();
  }, [url, tableDatas]);

  return (
    <div>
      {loading ? (
        <EmptyTable />
      ) : (
        <div>
          <Table
            columns={tableColumns}
            dataSource={currentItems}
            size="large"
            pagination={false}
            bordered
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'sticky',
              bottom: '0',
              padding: '15px 20px',
              borderTop: '1px solid #efefef',
              backgroundColor: 'white',
              width: '100%',
              zIndex: '1',
            }}
          >
            {(url === '/sales-inquiry' || url === '/order') &&
              (totalData.loading ? (
                <Tag
                  style={{
                    position: 'absolute',
                    left: '15px',
                    padding: '2px 5px',
                  }}
                >
                  로딩중...
                </Tag>
              ) : (
                <Tag
                  style={{
                    position: 'absolute',
                    left: '15px',
                    padding: '2px 5px',
                  }}
                >
                  <span>{url === '/order' ? '주문' : '판매'} 건수 </span>
                  <span style={{ color: 'gray' }}>
                    {totalData.count.toLocaleString()}
                  </span>
                  <span>건</span>
                  <span style={{ margin: '0 3px' }}>/</span>
                  <span>{url === '/order' ? '주문' : '판매'} 금액 </span>
                  <span style={{ color: 'gray' }}>
                    {totalData.amount.toLocaleString()}
                  </span>
                  <span>원</span>
                </Tag>
              ))}
            {url === '/account' && (
              <Button
                style={{
                  position: 'absolute',
                  left: '15px',
                }}
                size="small"
                onClick={() => handleAccountListTable(tableColumns, tableDatas)}
              >
                거래처리스트
              </Button>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '0 20px',
              }}
            >
              <Button
                size="small"
                onClick={goToFirstPage}
                disabled={currentPage === 1}
                style={{ borderRadius: '20px', color: 'gray' }}
              >
                <DoubleLeftOutlined />
              </Button>
              <Button
                size="small"
                onClick={goToPrevPage}
                disabled={currentPage === 1}
                style={{ borderRadius: '20px', color: 'gray' }}
              >
                <LeftOutlined />
              </Button>
              <div
                style={{
                  margin: '0 10px',
                  borderBottom: '2px solid #1677ff',
                  padding: '0 5px',
                }}
              >
                {currentPage}
              </div>
              <Button
                size="small"
                onClick={goToNextPage}
                style={{ borderRadius: '20px', color: 'gray' }}
                disabled={currentPage === totalPages}
              >
                <RightOutlined />
              </Button>
              <Button
                size="small"
                onClick={goToLastPage}
                disabled={currentPage === totalPages}
                style={{ borderRadius: '20px', color: 'gray' }}
              >
                <DoubleRightOutlined />
              </Button>
            </div>
            <Tag
              style={{
                position: 'absolute',
                right: '15px',
                padding: '2px 5px',
              }}
            >
              <span style={{ fontSize: '13px' }}>총</span>
              <span style={{ marginLeft: '5px' }}>{totalPages}</span>
              <span style={{ fontSize: '11px', color: 'gray' }}>/page</span>
            </Tag>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(TableBody);

TableBody.propTypes = {
  tableDatas: PropTypes.instanceOf(Array).isRequired,
  tableColumns: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool.isRequired,
};

const RoundButton = styled(Button)`
  border-radius: 50px;
`;
