import React, { useState } from 'react';
import { Layout, theme } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import HeaderComponent from './HeaderComponent';
import BreadCrumbComponent from './BreadCrumbComponent';
import SiderComponent from './SiderComponent';
import {
  DARK_MODE_BACKGROUND,
  DARK_MODE_LAYOUT_COLOR,
  LIGHT_MODE_BACKGROUND,
} from '../../../constants';

const { Content } = Layout;

const LayoutComponent = (props) => {
  const { children, breadCrumbs } = props;
  const { userInfo } = useSelector((state) => state.signinUser);
  const { darkMode } = useSelector((state) => state.darkMode);

  const [open, setOpen] = useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleModal = () => {
    setOpen(true);
  };
  const url = window.location.pathname;
  return (
    <Layout style={{ height: '100vh' }}>
      <HeaderComponent userInfo={userInfo} handleModal={handleModal} />
      <Layout hasSider>
        <SiderComponent
          breadCrumbNav={breadCrumbs[0].breadCrumbNav}
          handleModal={handleModal}
          userInfo={userInfo}
        />
        {url === '/external-services/map-distribution' ||
        url === '/external-services/hantong-dashboard' ||
        url === '/external-services/delivery-tracker' ? (
          <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            {children}
          </div>
        ) : (
          <Layout
            style={{
              padding: '0 24px 24px',
              backgroundColor:
                (url === '/data-calendar' ||
                  url === '/data-calendar/browse-here' ||
                  url === '/data-map') &&
                darkMode &&
                DARK_MODE_LAYOUT_COLOR,
            }}
          >
            <BreadCrumbComponent breadCrumbs={breadCrumbs} />
            <Content
              style={{
                overflow: 'auto',
                scrollbarWidth: url === '/data-calendar' ? 'none' : 'auto',
              }}
            >
              {children}
            </Content>
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};
export default LayoutComponent;

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  breadCrumbs: PropTypes.instanceOf(Array).isRequired,
};
