import React, { forwardRef, useImperativeHandle } from 'react';

import useSmsReceiver from '../../hook/useSmsReceiver';
import ReceiverInput from './ReceiverInput';
import ReceiverSelector from './ReceiverSelector';
import Receivers from './Receivers';

const ReceiverField = forwardRef((_, ref) => {
  const {
    allAccounts,
    addReceiver,
    groupData,
    receiverInfo,
    addReceiverInfo,
    handleReceiverInfo,
    handleReceiversByAllAccounts,
    handleReceiversByGroupCode,
    receivers,
    handleAllCheckReceivers,
    handleAllUnCheckReceivers,
    handleCheckReceiver,
    removeReceiver,
    removeCheckedReceivers,
  } = useSmsReceiver();

  useImperativeHandle(ref, () => ({
    receivers,
  }));

  return (
    <div style={{ width: '100%' }}>
      <ReceiverInput
        receiverInfo={receiverInfo}
        addReceiverInfo={addReceiverInfo}
        handleReceiverInfo={handleReceiverInfo}
      />
      <ReceiverSelector
        allAccounts={allAccounts}
        groupData={groupData}
        handleReceiversByAllAccounts={handleReceiversByAllAccounts}
        handleReceiversByGroupCode={handleReceiversByGroupCode}
        addReceiver={addReceiver}
      />
      <Receivers
        receivers={receivers}
        handleAllCheckReceivers={handleAllCheckReceivers}
        handleAllUnCheckReceivers={handleAllUnCheckReceivers}
        handleCheckReceiver={handleCheckReceiver}
        removeReceiver={removeReceiver}
        removeCheckedReceivers={removeCheckedReceivers}
      />
    </div>
  );
});

export default React.memo(ReceiverField);
