import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import CounsellingFormFields from './CounsellingFormFields';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';

const CounsellingForm = (props) => {
  const { pageType } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [savedFormData, setSavedFormData] = useState({});

  useEffect(() => {
    const getSavedData = async () => {
      try {
        const fetchedSavedFormData = await dispatch(
          asyncReadSpecificDataFetch({ table: 'counselling', id })
        )
          .unwrap()
          .then((res) => res[0]);
        const formattedFormData = {
          adminId: fetchedSavedFormData.admin_id,
          accountName: fetchedSavedFormData.account_name,
          contact: fetchedSavedFormData.contact,
          counsellingDate: fetchedSavedFormData.counselling_date,
          counsellingType: fetchedSavedFormData.counselling_type,
          deliveryDate: fetchedSavedFormData.delivery_date,
          purchaseDate: fetchedSavedFormData.purchase_date,
          registrationName: fetchedSavedFormData.registration_name,
          route: fetchedSavedFormData.route,
          content: fetchedSavedFormData.content,
          reactionLevel: fetchedSavedFormData.reaction_level,
          purchaseIntention: fetchedSavedFormData.purchase_intention,
          regDate: fetchedSavedFormData.reg_date,
        };
        setSavedFormData(formattedFormData);
      } catch (error) {
        throw new Error(error);
      }
    };
    if (pageType === 'update') getSavedData();
  }, []);

  const blankCheck = (value) => {
    return !!(value && value.replace(/\s/g, '').length > 0);
  };
  const contactCheck = (contact) => {
    const pattern1 = /^(?:\d{2}-\d{4}-\d{4})$/;
    const pattern2 = /^(?:\d{3}-\d{4}-\d{4})$/;
    const pattern3 = /^(?:\d{2}-\d{3}-\d{4})$/;
    const pattern4 = /^(?:\d{3}-\d{3}-\d{4})$/;
    const pattern5 = /^(?:\d{4}-\d{4}-\d{4})$/;
    return (
      pattern1.test(contact) ||
      pattern2.test(contact) ||
      pattern3.test(contact) ||
      pattern4.test(contact) ||
      pattern5.test(contact)
    );
  };

  const validationCheckHandler = (formData) => {
    const {
      accountName,
      contact,
      content,
      route,
      counsellingType,
      purchaseIntention,
    } = formData;
    if (!blankCheck(accountName)) {
      alert('업체명을 입력해주세요.');
      return false;
    }
    if (!blankCheck(contact)) {
      alert('연락처를 입력해주세요.');
      return false;
    }
    if (!blankCheck(content)) {
      alert('상담내용을 입력해주세요.');
      return false;
    }
    if (!contactCheck(contact)) {
      alert('연락처 형식을 확인해주세요.');
      return false;
    }
    if (!blankCheck(route)) {
      alert('상담경로를 입력해주세요.');
      return false;
    }
    if (!blankCheck(counsellingType)) {
      alert('상담방식을 입력해주세요.');
      return false;
    }
    if (!blankCheck(purchaseIntention)) {
      alert('구매의향을 입력해주세요.');
      return false;
    }
    return true;
  };

  const handleCreate = async (formData) => {
    const isValidFormData = validationCheckHandler(formData);
    if (!isValidFormData) return;
    const isConfirmCreate = confirm(
      '신규상담/게시판 데이터를 등록하시겠습니까?'
    );
    if (!isConfirmCreate) return;
    try {
      const postData = {
        account_name: formData.accountName,
        admin_id: formData.adminId,
        contact: formData.contact,
        counselling_date: formData.counsellingDate,
        counselling_type: formData.counsellingType,
        delivery_date: formData.deliveryDate,
        purchase_date: formData.purchaseDate,
        registration_name: formData.registrationName,
        route: formData.route,
        content: formData.content,
        reaction_level: formData.reactionLevel,
        purchase_intention: formData.purchaseIntention,
      };
      await dispatch(
        asyncCreateDataFetch({ table: 'counselling', ...postData })
      );
    } catch (error) {
      throw new Error(eror);
    } finally {
      alert('신규상담/게시판에 데이터를 등록하였습니다.');
      history.push('/counselling');
    }
  };

  const handleUpdate = async (formData) => {
    const isValidFormData = validationCheckHandler(formData);
    if (!isValidFormData) return;
    const isConfirmUpdate = confirm(
      '수정된 신규상담/게시판 데이터를 저장하시겠습니까?'
    );
    if (!isConfirmUpdate) return;
    try {
      const postData = {
        account_name: formData.accountName,
        admin_id: formData.adminId,
        contact: formData.contact,
        counselling_date: formData.counsellingDate,
        counselling_type: formData.counsellingType,
        delivery_date: formData.deliveryDate,
        purchase_date: formData.purchaseDate,
        registration_name: formData.registrationName,
        route: formData.route,
        content: formData.content,
        reaction_level: formData.reactionLevel,
        purchase_intention: formData.purchaseIntention,
      };
      await dispatch(
        asyncUpdateDataFetch({
          table: 'counselling',
          regDate: dayjs(savedFormData.regDate)
            .subtract(9, 'h')
            .format('YYYY-MM-DD HH:mm:ss'),
          mod_date: dayjs(),
          state: 1,
          ...postData,
        })
      );
    } catch (error) {
      throw new Error(eror);
    } finally {
      alert('신규상담/게시판에 데이터를 저장하였습니다.');
      history.push('/counselling');
    }
  };

  return (
    <div style={{ maxWidth: '1080px', margin: '0 auto' }}>
      <CounsellingFormFields
        pageType={pageType}
        handleCreate={handleCreate}
        handleUpdate={handleUpdate}
        savedFormData={savedFormData}
      />
    </div>
  );
};

export default CounsellingForm;

CounsellingForm.propTypes = {
  pageType: PropTypes.string.isRequired,
};
