import { useCallback, useEffect, useState } from 'react';

const useGetWarhouseStatusData = (firestoreData) => {
  const [warehouseStatusData, setWarehouseStatusData] = useState({
    koreapk: 0,
    koreapkDone: 0,
    joontech: 0,
    joontechDone: 0,
  });

  const getWarehouseStatusData = useCallback((firestoreData) => {
    const statusData = {
      koreapk: 0,
      koreapkDone: 0,
      joontech: 0,
      joontechDone: 0,
    };

    firestoreData.forEach(({ statusId, warehouse }) => {
      switch (warehouse) {
        case '고려포장':
          statusData.koreapk += 1;
          if (statusId === 'delivered') statusData.koreapkDone += 1;
          break;
        case '준테크':
          statusData.joontech += 1;
          if (statusId === 'delivered') statusData.joontechDone += 1;
          break;
        default:
          break;
      }
    });

    setWarehouseStatusData(statusData);
  }, []);

  useEffect(() => {
    getWarehouseStatusData(firestoreData);
  }, [firestoreData]);

  return warehouseStatusData;
};

export default useGetWarhouseStatusData;
