import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPage: 1,
  pathname: null,
  searchInfo: null,
};

const pagenationSlice = createSlice({
  name: 'pagenation',
  initialState,
  reducers: {
    changePage: (state, action) => {
      const currentPage = action.payload;
      state.currentPage = currentPage;
    },
    changePathname: (state, action) => {
      const pathname = action.payload;
      state.pathname = pathname;
    },
    searchWord: (state, action) => {
      const { word, type } = action.payload;
      state.searchInfo = { word, type };
    },
    initializePagenation: (state, action) => {
      state.currentPage = 1;
      state.pathname = null;
      state.searchInfo = null;
    },
  },
});

export default pagenationSlice.reducer;
export const { changePage, changePathname, initializePagenation, searchWord } =
  pagenationSlice.actions;
