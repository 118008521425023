import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const LinksContainer = ({ children }) => {
  return (
    <div
      style={{
        marginTop: '30px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </div>
  );
};

LinksContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const AuthLinks = () => {
  const pathname = window.location.pathname;
  const history = useHistory();

  if (pathname === '/') {
    return (
      <LinksContainer>
        <Button type="link" onClick={() => history.push('/signup')}>
          회원가입
        </Button>
      </LinksContainer>
    );
  }
  if (pathname === '/signup') {
    return (
      <LinksContainer>
        <Button type="link" onClick={() => history.push('/')}>
          로그인
        </Button>
      </LinksContainer>
    );
  }

  return (
    <LinksContainer>
      <Button type="link" onClick={() => history.goBack()}>
        돌아가기
      </Button>
    </LinksContainer>
  );
};

export default AuthLinks;
