import {
  CalendarOutlined,
  CloseOutlined,
  EditOutlined,
  FileExcelOutlined,
  SafetyCertificateOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Descriptions,
  Input,
  Radio,
  Select,
  Tag,
  ConfigProvider,
  Popover,
} from 'antd';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

// datepicker 한글화
import 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ko';
import locale from 'antd/lib/locale/ko_KR';

import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import AdjustmentModal from './AdjustmentModal';
import SearchDefaultModal from './SearchDefaultModal';
import ProductSetupModal from './ProductSetupModal';
import SafetyStockModal from './SafetyStockModal';
import OrderSettingModal from './OrderSettingModal';

const SearchForm = (props) => {
  const { formData, setFormData, setInventoryDatas, getInventoryDatas } = props;
  const dispatch = useDispatch();

  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [options, setOptions] = useState({
    warehouse: [],
    product: [],
  });
  const [isOpenAdjustmentModal, setIsOpenAdjustmentModal] = useState(false);
  const [isOpenSearchDefaultModal, setIsOpenSearchDefaultModal] =
    useState(false);
  const [isOpenProductSetupModal, setIsOpenProductSetupModal] = useState(false);
  const [isOpenSafetyStockModal, setIsOpenSafetyStockModal] = useState(false);
  const [isOpenOrderSettingModal, setIsOpenOrderSettingModal] = useState(false);

  useEffect(() => {
    const getOptions = async (table) => {
      try {
        const result = await dispatch(asyncReadDataFetch({ table }))
          .unwrap()
          .then((res) =>
            res.map((data) => ({ label: data.name, value: data.id }))
          );
        return result;
      } catch (error) {
        throw new Error(error);
      }
    };
    getOptions('warehouse').then((res) =>
      setOptions((prev) => ({ ...prev, warehouse: res }))
    );
    getOptions('product').then(async (product) => {
      // 취급 품목 가져오기
      const setupProduct = await dispatch(
        asyncReadDataFetch({ table: 'inventory_product_setup' })
      )
        .unwrap()
        .then((res) => res);
      // 품목 옵션을 취급 품목으로 필터링
      const filteredProduct = product.filter((item) => {
        let isSetup = false;
        setupProduct.forEach((setupItem) => {
          if (item.value === setupItem.product_id) isSetup = true;
        });
        return isSetup;
      });
      setOptions((prev) => ({ ...prev, product: filteredProduct }));
    });
  }, [dispatch]);

  const handleSelect = (value, option) => {
    const selectedObj = option.filter((data) => data.value === value)[0];
    if (option === options.warehouse) {
      const isDup = formData.warehouse.filter(
        (data) => data === selectedObj
      ).length;
      if (isDup === 1) {
        alert('이미 선택된 창고입니다.');
        return;
      }
      setFormData((prev) => ({
        ...prev,
        warehouse: [...prev.warehouse, selectedObj],
      }));
    }
    if (option === options.product) {
      const isDup = formData.product.filter(
        (data) => data === selectedObj
      ).length;
      if (isDup === 1) {
        alert('이미 선택된 품목입니다.');
        return;
      }
      setFormData((prev) => ({
        ...prev,
        product: [...prev.product, selectedObj],
      }));
    }
  };

  const handleDelete = (value, option) => {
    if (option === formData.warehouse) {
      setFormData((prev) => ({
        ...prev,
        warehouse: [...option.filter((warehouse) => warehouse.value !== value)],
      }));
    }
    if (option === formData.product) {
      setFormData((prev) => ({
        ...prev,
        product: [...option.filter((product) => product.value !== value)],
      }));
    }
  };

  const handleDatebtn = (dateBtnValue) => {
    if (dateBtnValue === 'today') {
      setFormData((prev) => ({
        ...prev,
        inventoryChangeDate: dayjs().format('YYYY-MM-DD'),
      }));
    }
    if (dateBtnValue === 'yesterday') {
      setFormData((prev) => ({
        ...prev,
        inventoryChangeDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      }));
    }
    getInventoryDatas(dateBtnValue);
  };

  // 취급품목 변경 -> 재고검색 품목선택 옵션 업데이트
  const matchOptionsToUpdatedSetup = (setupProduct) => {
    const updatedSetupProduct = setupProduct.map((item) => ({
      label: item.productName,
      value: item.productId,
    }));
    setOptions((prev) => ({ ...prev, product: updatedSetupProduct }));
  };

  return (
    <div className="select-container">
      <div
        style={{
          fontSize: '16px',
          borderBottom: '1px solid gray',
          paddingBottom: '10px',
        }}
      >
        재고현황 검색
      </div>
      <Descriptions
        column={1}
        bordered
        labelStyle={{ width: '10%', textAlign: 'center' }}
        contentStyle={{ width: '90%' }}
      >
        <Descriptions.Item
          label={
            <div>
              <span>기준일자</span>
              <span style={{ color: 'red' }}>*</span>
            </div>
          }
        >
          <div style={{ display: 'flex', position: 'relative' }}>
            <Input
              style={{ borderRadius: '0px', width: '100%' }}
              value={formData.inventoryChangeDate}
              placeholder="기준일자를 선택해주세요."
            />
            <Button
              style={{ marginLeft: '10px', zIndex: '1' }}
              onClick={() => setIsOpenCalendar((prev) => !prev)}
            >
              <CalendarOutlined />
            </Button>
            {isOpenCalendar && (
              <ConfigProvider locale={locale}>
                <DatePicker
                  open
                  style={{ opacity: '0', position: 'absolute', right: '0' }}
                  onChange={(value) => {
                    setFormData((prev) => ({
                      ...prev,
                      inventoryChangeDate: value.format('YYYY-MM-DD'),
                    }));
                    setIsOpenCalendar(false);
                  }}
                />
              </ConfigProvider>
            )}
          </div>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <div>
              <span>창고</span>
              <span style={{ color: 'red' }}>*</span>
            </div>
          }
        >
          <div style={{ display: 'flex' }}>
            {formData.warehouse.map((data) => (
              <Tag
                color="blue"
                key={data.value}
                style={{
                  marginBottom: '5px',
                  padding: '5px',
                  borderRadius: '20px',
                  paddingRight: '25px',
                  position: 'relative',
                }}
              >
                {data.label}
                <Button
                  type="primary"
                  size="small"
                  style={{
                    borderRadius: '50px',
                    position: 'absolute',
                    right: '0',
                    top: '5px',
                    fontSize: '10px',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '2px',
                  }}
                  onClick={() => handleDelete(data.value, formData.warehouse)}
                >
                  <CloseOutlined />
                </Button>
              </Tag>
            ))}
          </div>
          <Select
            style={{ width: '100%' }}
            options={options.warehouse}
            placeholder="창고를 선택해주세요. (필수)"
            onSelect={(value) => handleSelect(value, options.warehouse)}
            value={null}
          />
        </Descriptions.Item>
        <Descriptions.Item label="품목">
          <div>
            {formData.product.map((data) => (
              <Tag
                color="blue"
                key={data.value}
                style={{
                  marginBottom: '5px',
                  padding: '5px',
                  borderRadius: '20px',
                  paddingRight: '25px',
                  position: 'relative',
                }}
              >
                {data.label}
                <Button
                  type="primary"
                  size="small"
                  style={{
                    borderRadius: '50px',
                    position: 'absolute',
                    right: '0',
                    top: '5px',
                    fontSize: '10px',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '2px',
                  }}
                  onClick={() => handleDelete(data.value, formData.product)}
                >
                  <CloseOutlined />
                </Button>
              </Tag>
            ))}
          </div>
          <Select
            style={{ width: '100%' }}
            options={options.product}
            placeholder="품목을 선택해주세요. (선택)"
            onSelect={(value) => handleSelect(value, options.product)}
            value={null}
          />
        </Descriptions.Item>
        <Descriptions.Item label="출력">
          <Radio.Group
            value={formData.type}
            onChange={(e) => {
              setInventoryDatas([]);
              setFormData((prev) => ({ ...prev, type: e.target.value }));
            }}
          >
            <Radio value="safetyStock">재고현황 + 안전재고</Radio>
            <Radio value="changedStock">재고현황 + 변동재고</Radio>
            <Radio value="unSoldAndUnReceived">재고현황 + 미판매/미입고</Radio>
            <Radio value="basic">재고현황(기본)</Radio>
          </Radio.Group>
        </Descriptions.Item>
      </Descriptions>
      <div
        style={{
          marginTop: '10px',
          backgroundColor: '#fafafa',
          border: '1px solid #efefef',
          padding: '15px',
          borderRadius: '20px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Button
            type="primary"
            style={{ borderRadius: '20px', marginRight: '5px' }}
            onClick={() => {
              getInventoryDatas();
            }}
          >
            검색
          </Button>
          <Button
            style={{ borderRadius: '20px', marginRight: '5px' }}
            onClick={() => handleDatebtn('today')}
          >
            금일
          </Button>
          <Button
            style={{ borderRadius: '20px' }}
            onClick={() => handleDatebtn('yesterday')}
          >
            전일
          </Button>
        </div>
        <div>
          <Button
            style={{ borderRadius: '20px', marginRight: '5px' }}
            onClick={() => setIsOpenSafetyStockModal(true)}
          >
            <SafetyCertificateOutlined />
            안전재고
          </Button>
          <Button
            style={{ borderRadius: '20px', marginRight: '5px' }}
            onClick={() => setIsOpenAdjustmentModal(true)}
          >
            <EditOutlined />
            재고조정
          </Button>
          <Popover
            placement="bottom"
            content={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button onClick={() => setIsOpenSearchDefaultModal(true)}>
                  창고선택
                </Button>
                <Button onClick={() => setIsOpenProductSetupModal(true)}>
                  취급품목
                </Button>
                <Button onClick={() => setIsOpenOrderSettingModal(true)}>
                  출력순번
                </Button>
              </div>
            }
          >
            <Button style={{ borderRadius: '20px', marginRight: '5px' }}>
              <SettingOutlined />
              설정
            </Button>
          </Popover>
          <Button
            style={{
              borderRadius: '20px',
              backgroundColor: '#237804',
              color: 'white',
            }}
          >
            <FileExcelOutlined />
            엑셀
          </Button>
        </div>
      </div>
      {isOpenAdjustmentModal && (
        <AdjustmentModal
          setIsOpenAdjustmentModal={setIsOpenAdjustmentModal}
          setTableDatas={setInventoryDatas}
        />
      )}
      {isOpenSearchDefaultModal && (
        <SearchDefaultModal
          setIsOpenSearchDefaultModal={setIsOpenSearchDefaultModal}
        />
      )}
      {isOpenProductSetupModal && (
        <ProductSetupModal
          setIsOpenProductSetupModal={setIsOpenProductSetupModal}
          matchOptionsToUpdatedSetup={matchOptionsToUpdatedSetup}
        />
      )}
      {isOpenSafetyStockModal && (
        <SafetyStockModal
          setIsOpenSafetyStockModal={setIsOpenSafetyStockModal}
        />
      )}
      {isOpenOrderSettingModal && (
        <OrderSettingModal
          setIsOpenOrderSettingModal={setIsOpenOrderSettingModal}
        />
      )}
    </div>
  );
};

export default SearchForm;

SearchForm.propTypes = {
  formData: PropTypes.instanceOf(Object).isRequired,
  setFormData: PropTypes.func.isRequired,
  setInventoryDatas: PropTypes.func.isRequired,
  getInventoryDatas: PropTypes.func.isRequired,
};
