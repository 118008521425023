import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InventoryStatusCardMain from '../../shared/components/InventoryStatusCardMain';
import InventoryStatusCardContainer from '../../shared/components/InventoryStatusCardContainer';
import InventoryStatusCardContent from '../../shared/components/InventoryStatusCardContent';
import InventoryStatusCardDivider from '../../shared/components/InventoryStatusCardDivider';
import { productCodes } from '../../constants';

// 각 컴포넌트 모듈을 컴파운드화 (객체화) 시킴.
const InventoryStatusCard = Object.assign(InventoryStatusCardMain, {
  Content: InventoryStatusCardContent,
  Container: InventoryStatusCardContainer,
  Divider: InventoryStatusCardDivider,
});

const MajorStatus = (props) => {
  const { forecastData, forecastUpdatedAt } = props;

  const getCardContentProps = useCallback(
    (key) => {
      const props = {
        availableDays: 0,
        stackQuantity: 0,
        productName: key,
        textColor: 'gray',
      };
      if (forecastData[key]) {
        const { availableDays, stackQuantity, productName, textColor } =
          forecastData[key];
        Object.assign(props, {
          availableDays,
          stackQuantity,
          productName,
          textColor,
        });
      }
      return props;
    },
    [forecastData]
  );

  return (
    <InventoryStatusCard
      title="주요 재고"
      updatedAt={forecastUpdatedAt.slice(0, 16)}
    >
      <InventoryStatusCard.Container>
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.pp_04)}
        />
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.epp_01)}
        />
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.epl_001)}
        />
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.epl_007)}
        />
      </InventoryStatusCard.Container>
      <InventoryStatusCard.Divider />
      <InventoryStatusCard.Container>
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.pp_01)}
        />
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.seal_03)}
        />
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.pp_02)}
        />
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.pp_03)}
        />
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.epp_03)}
        />
      </InventoryStatusCard.Container>
    </InventoryStatusCard>
  );
};

export default MajorStatus;

MajorStatus.propTypes = {
  forecastData: PropTypes.instanceOf(Object).isRequired,
  forecastUpdatedAt: PropTypes.string.isRequired,
};
