import React, { useMemo, useState } from 'react';
import { SolutionOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { Link } from 'react-router-dom';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Table from '../../shared/table/Table';
import AddressColumn from '../components/AddressColumn';
import AccountGroupColumn from '../components/AccountGroupColumn';

const Account = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <SolutionOutlined />,
        breadCrumbNav: ' 거래처',
      },
    ],
    []
  );

  // 불러올 table이 뭔지
  const table = 'v_account_hidden';

  // TableHeader에 들어갈 create 버튼 텍스트
  const createButtonText = '거래처 추가';

  // table header에 들어갈 create 버튼의 Modal객체 (Modal 속성으로 넣어줄 객체)
  const createButtonModal = {
    type: 'account',
    modalInfo: {
      modalTitle: '거래처 추가',
      resultStatus: '',
      resultTitle: '',
      resultSubTitle: '',
      buttonText: '',
    },
    dataInfo: {
      dataId: '',
      dataTable: 'account',
      datas: {},
    },
  };

  // table에 넣을 columns. table View를 위한 배열.
  const tableColumns = [
    {
      title: '거래처구분',
      dataIndex: 'account_group1',
      key: 'account_group1',
      align: 'center',
      width: '10%',
      render: (value) => <AccountGroupColumn value={value} />,
    },
    {
      title: '거래처코드',
      dataIndex: 'code',
      key: 'code',
      width: '5%',
      align: 'center',
    },
    {
      title: '사업자번호',
      dataIndex: 'business_number',
      key: 'business_number',
      align: 'center',
      width: '5%',
    },
    {
      title: '거래처명',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <Link to={`/account/${record.key}`}>{record.name}</Link>
      ),
      width: '10%',
      align: 'center',
    },
    {
      title: '대표자명',
      dataIndex: 'representative',
      key: 'representative',
      width: '5%',
      align: 'center',
    },
    {
      title: '샘플거래처',
      dataIndex: 'is_sample',
      render: (data) => {
        if (data) {
          return <Tag color="red">ON</Tag>;
        } else {
          return;
        }
      },
      width: '5%',
      align: 'center',
    },
    {
      title: '첫판매',
      dataIndex: 'first_sale',
      render: (data) => <div>{data}</div>,
      width: '10%',
      align: 'center',
    },
    {
      title: '기본결제유형',
      dataIndex: 'payment_type',
      render: (data) => <div>{data}</div>,
      width: '10%',
      align: 'center',
    },
    {
      title: '지역',
      dataIndex: 'region',
      render: (data) => <div>{data}</div>,
      width: '10%',
      align: 'center',
    },
    {
      title: '주소',
      dataIndex: 'code',
      key: 'address',
      render: (v) => {
        return <AddressColumn code={v} />;
      },
      width: '20%',
      align: 'center',
    },
    {
      title: '휴대폰',
      dataIndex: 'mobile',
      key: 'mobile',
      width: '10%',
      align: 'center',
    },
  ];

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <Table
        tableColumns={tableColumns}
        table={table}
        createButtonText={createButtonText}
        createButtonModal={createButtonModal}
        items={tableColumns}
      />
    </LayoutComponent>
  );
};

export default Account;
