import { DatabaseOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Table from '../../shared/table/Table';
import AccountColumnValue from '../../shared/purchase/AccountColumnValue';
import ProductColumnValue from '../../shared/purchase/ProductColumnValue';
import AdminColumnValue from '../../shared/purchase/AdminColumnValue';
import WarehouseColumnValue from '../../shared/purchase/WarehouseColumnValue';
import TotalPriceColumnValue from '../../shared/purchase/TotalPriceColumnValue';

const Purchase = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <DatabaseOutlined />,
        breadCrumbNav: ' 구매조회',
      },
    ],
    []
  );
  // 불러올 table이 뭔지
  const table = 'purchase';

  // TableHeader에 들어갈 create 버튼 텍스트
  const createButtonText = '구매 추가';

  // table header에 들어갈 create 버튼의 Modal객체 (Modal 속성으로 넣어줄 객체)
  const createButtonModal = {
    type: 'purchase',
    modalInfo: {
      modalTitle: '구매 추가',
      resultStatus: '',
      resultTitle: '',
      resultSubTitle: '',
      buttonText: '',
    },
    dataInfo: {
      dataId: '',
      dataTable: 'purchase',
      datas: {},
    },
  };

  // table에 넣을 columns. table View를 위한 배열.
  const tableColumns = [
    {
      title: '일자',
      dataIndex: 'purchase_date',
      key: 'purchase_date',
      width: '10%',
      align: 'center',
      render: (value) => {
        const result = value?.slice(0, 10);
        return result;
      },
    },
    {
      title: '담당자명',
      dataIndex: 'admin_id',
      key: 'admin_id',
      width: '10%',
      align: 'center',
      render: (value) => <AdminColumnValue adminId={value} />,
    },
    {
      title: '거래처명',
      dataIndex: 'account_id',
      key: 'account_id',
      width: '20%',
      align: 'center',
      render: (value) => <AccountColumnValue accountId={value} />,
    },
    {
      title: '품목명',
      dataIndex: 'id',
      key: 'id',
      width: '20%',
      align: 'center',
      render: (value) => (
        <ProductColumnValue id={value} importIdValue="purchaseId" />
      ),
    },
    {
      title: '구매금액합계',
      dataIndex: 'purchase_total',
      key: 'purchase_total',
      width: '10%',
      align: 'center',
      render: (_, record) => (
        <TotalPriceColumnValue id={record.id} importIdValue="purchaseId" />
      ),
    },
    {
      title: '거래유형',
      dataIndex: 'deal_type',
      key: 'deal_type',
      width: '10%',
      align: 'center',
      render: (value) => {
        if (value === 1) {
          return '부가세율 적용';
        }
        if (value === 0) {
          return '부가세율 미적용';
        }
      },
    },
    {
      title: '창고명',
      dataIndex: 'warehouse_id',
      key: 'warehouse_id',
      width: '20%',
      align: 'center',
      render: (value) => <WarehouseColumnValue warehouseId={value} />,
    },
  ];
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <Table
        tableColumns={tableColumns}
        table={table}
        createButtonText={createButtonText}
        createButtonModal={createButtonModal}
        items={tableColumns}
      />
    </LayoutComponent>
  );
};

export default Purchase;
