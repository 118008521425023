import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { asyncReadRangeDataFetch } from '../../redux/modules/shared/readRangeData';
import { getThirtyDaysDailyChartData } from '../../utils/getChartDatas';
import {
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  LIGHT_MODE_BACKGROUND,
  LIGHT_MODE_FONT_COLOR,
  darkModeColors,
  lightModeColors,
} from '../../constants';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import AbsoluteLeftText from '../../shared/components/AbsoluteLeftText';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ThirtyDaysDailyChart = () => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.darkMode);
  const Colors = darkMode ? darkModeColors : lightModeColors;
  const [chartData, setChartData] = useState([]);

  const startDate = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
  const endDate = dayjs().format('YYYY-MM-DD');

  useEffect(() => {
    const getChartData = async () => {
      try {
        const fetchedSalesData = await dispatch(
          asyncReadRangeDataFetch({
            table: 'v_sales_status',
            column: 'delivery_date',
            startPt: startDate,
            endPt: endDate,
          })
        )
          .unwrap()
          .then((res) => res);

        const thirtyDaysDailyChartData = getThirtyDaysDailyChartData(
          fetchedSalesData,
          startDate,
          endDate
        );

        const resultChartData = thirtyDaysDailyChartData.map((item) => {
          const formattedDate = dayjs(item.date).format('MMMD');
          const firstDayOfMonth = dayjs(item.date).startOf('month');
          const isFirstDayOfMonth = dayjs(item.date).isSame(
            firstDayOfMonth,
            'day'
          );

          return { ...item, date: formattedDate, isFirstDayOfMonth };
        });

        setChartData(resultChartData);
      } catch (error) {
        throw new Error(error);
      }
    };

    getChartData();
  }, []);

  const options = {
    aspectRatio: 3,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        min: 0,
        max: 30000000,
        ticks: {
          stepSize: 10000000,
          callback: (value) => {
            if (value === 0) {
              return value;
            }
            const stepSize = String(value);
            return `${stepSize.slice(0, 1)}천만`;
          },
        },
      },
      x: {
        ticks: {
          maxTicksLimit: 6,
        },
      },
    },
  };

  const average =
    chartData.reduce((acc, cur) => acc + cur.sales, 0) / chartData.length;

  const data = {
    labels: chartData.map((item) => item.date),
    datasets: [
      {
        label: '일일 매출액',
        data: chartData.map((item) => item.sales),
        borderColor: chartData.map((item) => {
          if (item.isFirstDayOfMonth) {
            return Colors.chart_border_1;
          } else {
            return Colors.chart_border_1;
          }
        }),
        backgroundColor: chartData.map((item) => {
          if (item.isFirstDayOfMonth) {
            return Colors.armsone_pink;
          } else {
            return Colors.chart_bg_1;
          }
        }),
      },
      {
        label: '평균 매출액',
        pointStyle: false,
        data: Array(chartData.length).fill(Math.round(average)),
        borderColor: Colors.chart_avg_border,
        backgroundColor: Colors.chart_avg_bg,
      },
    ],
  };

  return (
    <div
      style={{
        padding: '10px',
        // border: '1px solid #efefef',
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
        color: darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR,
        borderRadius: '15px',
      }}
    >
      <div
        style={{
          color: 'gray',
          marginBottom: '5px',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AbsoluteLeftText left="3px" text={`범위 ${startDate}~${endDate}`} />
        <h4 style={{ textAlign: 'center' }}>Month</h4>
        <div
          style={{
            position: 'absolute',
            right: '10px',
            color: Colors.armsone_red,
          }}
        >
          AVG {Math.round(average / 1000).toLocaleString()}
        </div>
      </div>
      <Line options={options} data={data} />
    </div>
  );
};

export default ThirtyDaysDailyChart;
