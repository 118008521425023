import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import useProposalData from '../../hook/useProposalData';
import DeniedComponent from './DeniedComponent';
import OfferComponent from './OfferComponent';

const ProposeDatas = () => {
  const [offerDatas, setOfferDatas] = useState([]);
  const [deniedDatas, setDeniedDatas] = useState([]);
  const [updateDayData, setUpdateDayData] = useState(null);
  const [renderType, setRenderType] = useState('offer');

  const [proposalData] = useProposalData();

  useEffect(() => {
    const getProposeDatas = async (
      offerAcctList,
      deniedAcctList,
      updateDay
    ) => {
      // propose_day 날짜별로 데이터 나눔
      const groupedOfferAcctList = offerAcctList
        .reduce((acc, cur) => {
          const existingDay = acc.find(
            (item) => item.propose_day === cur.propose_day
          );
          if (existingDay) {
            existingDay.datas.push(cur);
          } else {
            acc.push({ propose_day: cur.propose_day, datas: [cur] });
          }
          return acc;
        }, [])
        .sort((a, b) => new Date(a.propose_day) - new Date(b.propose_day));

      setOfferDatas(groupedOfferAcctList);
      setDeniedDatas(deniedAcctList);
      setUpdateDayData(updateDay);
    };

    const { offerAcctList, deniedAcctList, updateDay } = proposalData;

    if (
      offerAcctList.length === 0 &&
      deniedAcctList.length === 0 &&
      updateDay === null
    ) {
      return;
    }

    getProposeDatas(offerAcctList, deniedAcctList, updateDay);
  }, [proposalData]);

  return (
    <div>
      <div
        style={{
          paddingBottom: '10px',
          borderBottom: '1px solid #6d6d6d',
          marginBottom: '5px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Button
            style={{
              fontSize: '16px',
              padding: '0px',
              marginRight: '10px',
              color: renderType === 'offer' ? 'black' : 'gray',
            }}
            size="small"
            type="link"
            onClick={() => setRenderType('offer')}
          >
            주문제안
          </Button>
          <Button
            style={{
              fontSize: '16px',
              padding: '0px',
              color: renderType === 'deny' ? 'black' : 'gray',
            }}
            size="small"
            type="link"
            onClick={() => setRenderType('deny')}
          >
            제안거부
          </Button>
        </div>
        <div style={{ color: 'gray', fontSize: '12px', marginTop: '10px' }}>
          최신화: {updateDayData}
        </div>
      </div>
      {renderType === 'offer' && <OfferComponent offerDatas={offerDatas} />}
      {renderType === 'deny' && (
        <DeniedComponent
          deniedDatas={deniedDatas}
          setDeniedDatas={setDeniedDatas}
        />
      )}
    </div>
  );
};

export default ProposeDatas;
