import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';
import uuid from 'react-uuid';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const OrderSaleDelivery = (props) => {
  const {
    saleData,
    orderProductDatas,
    setOrderProductDatas,
    setOrderSaleDatas,
    orderSaleDatas,
    pageType,
  } = props;
  const [selectedValue, setSelectedValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname === '/order/createOrder') {
      if (!saleData.delivery) {
        setSelectedValue('parcel0');
        return;
      }
      setSelectedValue(saleData.delivery);
    } else {
      const asyncFunc = async () => {
        let delivery = 'parcel0';
        const products = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'order_product',
            eqKey: 'order_id',
            eqValue: saleData.order_id,
          })
        )
          .unwrap()
          .then((res) => res);
        for (const product of products) {
          if (product.product_code.includes('parcel')) {
            delivery = product.product_code;
          }
        }
        setSelectedValue(delivery);
      };
      asyncFunc();
    }
  }, [saleData, dispatch]);

  const handleSelect = async (value) => {
    // orderProductDatas 변경
    if (value === 'parcel0') {
      // 택배비가 0일 때
      const editedDatas = orderProductDatas.filter(
        (data) => !data.product_code.includes('parcel')
      );
      setOrderProductDatas([...editedDatas]);
      const updatedDatas = orderSaleDatas.map((data) => {
        if (data === saleData) {
          return { ...data, delivery: value };
        }
        return { ...data };
      });
      setOrderSaleDatas([...updatedDatas]);
      return;
    }
    // 택배비 코드
    const code = value;
    // 택배비 개수
    const quantity = saleData.sale_quantity;
    // 택배비 단가, 택배비 명, 택배비 창고
    let price = 0;
    let name = '';
    let warehouse = '';
    // 해당 택배비가 등록되어있는지 여부
    let isRegDlvy = true;
    await dispatch(
      asyncReadFilteredDataFetch({
        table: 'product',
        eqKey: 'code',
        eqValue: code,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.length <= 0) {
          alert('먼저 해당 택배비를 출고상품에 등록해주세요.');
          isRegDlvy = false;
          return;
        }
        price = res[0].release_price / 1.1;
        name = res[0].name;
        warehouse = res[0].warehouse_code;
      });
    if (!isRegDlvy) return;
    // 택배비 공급가액
    const supply = price * quantity;
    // 택배비 부가세
    const vat = supply / 10;
    // 주문출고의 택배비 데이터 변경
    const newDelivery = {
      product_code: code,
      product_name: name,
      product_price: Math.round(price),
      product_VAT_price: Math.round(vat),
      product_supply_price: Math.round(supply),
      product_quantity: quantity,
      product_warehouse: warehouse,
    };

    // 기존 delivery데이터가 없는지 체크
    let hasDelivery = false;
    for (const data of orderProductDatas) {
      if (data.product_code.includes('parcel')) {
        hasDelivery = true;
      }
    }
    if (hasDelivery) {
      const editedDatas = await orderProductDatas.map((data) => {
        if (
          data.product_code.includes('parcel') &&
          data.sale_code === saleData.sale_code
        )
          return { ...data, ...newDelivery };
        return { ...data };
      });
      setOrderProductDatas([...editedDatas]);
    } else {
      setOrderProductDatas((prev) => {
        return [
          ...prev,
          {
            ...newDelivery,
            sale_code: prev[0].sale_code,
            discount: 0,
            discount_rate: 0,
            discounted_price: 0,
            discounted_supply: 0,
            discounted_vat: 0,
            key: uuid(),
          },
        ];
      });
    }
    // orderSaleDatas 변경
    const updatedDatas = orderSaleDatas.map((data) => {
      if (data === saleData) {
        return { ...data, delivery: value };
      }
      return { ...data };
    });
    setOrderSaleDatas([...updatedDatas]);
  };

  return (
    <Select
      style={{ width: '100px' }}
      value={selectedValue}
      onChange={(value) => handleSelect(value)}
      disabled={orderSaleDatas.length !== 1 || pageType === 'detail'}
    >
      <Select.Option key="parcel0" value="parcel0">
        0
      </Select.Option>
      <Select.Option key="parcel5" value="parcel5">
        3,000
      </Select.Option>
      <Select.Option key="parcel4" value="parcel4">
        4,000
      </Select.Option>
      <Select.Option key="parcel1" value="parcel1">
        5,000
      </Select.Option>
      <Select.Option key="parcel2" value="parcel2">
        8,000
      </Select.Option>
      <Select.Option key="parcel3" value="parcel3">
        45,000
      </Select.Option>
    </Select>
  );
};

export default OrderSaleDelivery;

OrderSaleDelivery.propTypes = {
  saleData: PropTypes.instanceOf(Object).isRequired,
  orderProductDatas: PropTypes.instanceOf(Array).isRequired,
  orderSaleDatas: PropTypes.instanceOf(Array).isRequired,
  setOrderProductDatas: PropTypes.func.isRequired,
  setOrderSaleDatas: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
};
