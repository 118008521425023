import axios from 'axios';

const baseURL = process.env.REACT_APP_OMS_API_URL;

const axiosWithFile = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  baseURL,
});

axiosWithFile.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const sendMailWithFile = async (postData) => {
  const response = await axiosWithFile.post('/api/mail/send', postData);
  return response.data;
};

const somethingApi = () => {};

export { sendMailWithFile, somethingApi };
