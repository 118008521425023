import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCmsjAoATCW8w_U9__AfvONZ34Z8R7iOSg',
  authDomain: 'hantondeliverytrack.firebaseapp.com',
  projectId: 'hantondeliverytrack',
  storageBucket: 'hantondeliverytrack.appspot.com',
  messagingSenderId: '265161354905',
  appId: '1:265161354905:web:1827fa34e8a91c7f6d1208',
  measurementId: 'G-18RQXLXG0N',
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
export default firestore;
