import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

const ProductQuantity = (props) => {
  const { productQuantity, handleEditOption, productOption, pageType } = props;
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    setQuantity(productQuantity);
  }, [productQuantity]);

  const handleChange = (e) => {
    setQuantity(Number(e.target.value));
    handleEditOption(productOption, e);
  };

  return (
    <Input
      style={{ width: '80px', borderRadius: '0px' }}
      type="number"
      value={quantity}
      onChange={(e) => handleChange(e)}
      disabled={pageType === 'detail'}
    />
  );
};

export default ProductQuantity;

ProductQuantity.propTypes = {
  productQuantity: PropTypes.number.isRequired,
  handleEditOption: PropTypes.func.isRequired,
  productOption: PropTypes.instanceOf(Object).isRequired,
  pageType: PropTypes.string.isRequired,
};
