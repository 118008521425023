import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';

import NationalMap from './NationalMap';
import {
  DARK_MODE_BACKGROUND,
  DARK_MODE_LAYOUT_COLOR,
  LIGHT_MODE_BACKGROUND,
} from '../../constants';
import NumberOfAccountByRegion from './NumberOfAccountByRegion';
import NumberOfAccountByCity from './NumberOfAccountByCity';
import AmountOfSalesByRegion from './AmountOfSalesByRegion';
import AmountOfSalesByCity from './AmountOfSalesByCity';
import PercentageOfAccountByRegion from './PercentageOfAccountByRegion';
import PercentageOfAccountByCity from './PercentageOfAccountByCity';
import PercentageOfSalesByRegion from './PercentageOfSalesByRegion';
import PercentageOfSalesByCity from './PercentageOfSalesByCity';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncReadLikeDataFetch } from '../../redux/modules/shared/readLikeData';
import BarAndLineGraphByRegion from './BarAndLineGraphByRegion';
import BarAndLineGraphByCity from './BarAndLineGraphByCity';
import admDist from '../../db/admDist';

const Map = () => {
  const { darkMode } = useSelector((state) => state.darkMode);
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState({
    region: '경기도',
    city: '고양시',
  });

  const [numberOfAccountDatasByRegion, setNumberOfAccountDatasByRegion] =
    useState([]);
  const [amountOfSalesDatasByRegion, setAmountOfSalesDatasByRegion] = useState(
    []
  );
  const [numberOfAccountDatasByCity, setNumberOfAccountDatasByCity] = useState(
    []
  );
  const [amountOfSalesDatasByCity, setAmountOfSalesDatasByCity] = useState([]);

  useEffect(() => {
    const fetchedSalesDatas = async () => {};
    const fetchedAccountDatas = async () => {
      const regionAndCityArray = [];

      for (const currentAdmDist of admDist) {
        const currentRegion = currentAdmDist.split(' ')[0];
        const currentCity = currentAdmDist.split(' ')[1];
        let isHasRegionValue = false;
        for (const regionAndCity of regionAndCityArray) {
          const region = regionAndCity.region.value;
          const cities = regionAndCity.city;
          if (currentRegion === region) {
            isHasRegionValue = true;
            let isHasCityValue = false;
            for (const city of cities) {
              if (currentCity === city.value) {
                isHasCityValue = true;
              }
            }
            if (!isHasCityValue) {
              cities.push({ value: currentCity, amount: 0 });
            }
          }
        }
        if (!isHasRegionValue) {
          regionAndCityArray.push({
            region: { value: currentRegion, amount: 0 },
            city: [{ value: currentCity, amount: 0 }],
          });
        }
      }

      const fetchedAccounts = await dispatch(
        asyncReadDataFetch({ table: 'account' })
      ).unwrap();

      for (const account of fetchedAccounts) {
        const currentRegion = account.region.split(' ')[0];
        const currentCity = account.region.split(' ')[1];
        for (const regionAndCity of regionAndCityArray) {
          const region = regionAndCity.region.value;
          const cities = regionAndCity.city;
          if (currentRegion === region) {
            regionAndCity.region.amount += 1;
            for (const city of cities) {
              if (currentCity === city.value) {
                city.amount += 1;
              }
            }
          }
        }
      }

      const regionDatas = regionAndCityArray.map((item) => item.region);
      const cityDatas = regionAndCityArray
        .filter((item) => item.region.value === selectedValue.region)
        .map((item) => item.city)[0];

      setNumberOfAccountDatasByRegion(regionDatas);
      setNumberOfAccountDatasByCity(cityDatas);
    };
    fetchedSalesDatas();
    fetchedAccountDatas();
  }, [selectedValue]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'space-between',
        color: 'gray',
        backgroundColor: darkMode && DARK_MODE_LAYOUT_COLOR,
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '10px',
          marginBottom: '10px',
        }}
      >
        <div style={{ width: '50%' }}>
          <NationalMap
            darkMode={darkMode}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
          />
        </div>
        <div style={{ width: '50%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              gap: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '10px',
                height: '30%',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '15px',
                backgroundColor: darkMode
                  ? DARK_MODE_BACKGROUND
                  : LIGHT_MODE_BACKGROUND,
              }}
            >
              <PlusOutlined /> 추가 예정
            </div>
            <div style={{ display: 'flex', gap: '10px', height: '10%' }}>
              <div style={{ width: '25%' }}>
                <NumberOfAccountByRegion
                  darkMode={darkMode}
                  selectedRegion={selectedValue.region}
                  numberOfAccountDatasByRegion={numberOfAccountDatasByRegion}
                />
              </div>
              <div style={{ width: '25%' }}>
                <NumberOfAccountByCity
                  darkMode={darkMode}
                  selectedCity={selectedValue.city}
                  numberOfAccountDatasByCity={numberOfAccountDatasByCity}
                />
              </div>
              <div style={{ width: '25%' }}>
                <AmountOfSalesByRegion
                  darkMode={darkMode}
                  selectedRegion={selectedValue.region}
                />
              </div>
              <div style={{ width: '25%' }}>
                <AmountOfSalesByCity
                  darkMode={darkMode}
                  selectedCity={selectedValue.city}
                />
              </div>
            </div>
            <div style={{ display: 'flex', gap: '10px', height: '30%' }}>
              <div style={{ width: '50%' }}>
                <PercentageOfAccountByRegion
                  darkMode={darkMode}
                  selectedRegion={selectedValue.region}
                  numberOfAccountDatasByRegion={numberOfAccountDatasByRegion}
                />
              </div>
              <div style={{ width: '50%' }}>
                <PercentageOfSalesByRegion
                  darkMode={darkMode}
                  selectedRegion={selectedValue.region}
                />
              </div>
            </div>
            <div style={{ display: 'flex', gap: '10px', height: '30%' }}>
              <div style={{ width: '50%' }}>
                <PercentageOfAccountByCity
                  darkMode={darkMode}
                  selectedCity={selectedValue.city}
                  numberOfAccountDatasByCity={numberOfAccountDatasByCity}
                />
              </div>
              <div style={{ width: '50%' }}>
                <PercentageOfSalesByCity
                  darkMode={darkMode}
                  selectedCity={selectedValue.city}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <div style={{ width: '50%' }}>
          <BarAndLineGraphByRegion
            darkMode={darkMode}
            numberOfAccountDatasByRegion={numberOfAccountDatasByRegion}
          />
        </div>
        <div style={{ width: '50%' }}>
          <BarAndLineGraphByCity
            darkMode={darkMode}
            numberOfAccountDatasByCity={numberOfAccountDatasByCity}
          />
        </div>
      </div>
    </div>
  );
};

export default Map;
