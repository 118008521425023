import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { DARK_MODE_BACKGROUND, LIGHT_MODE_BACKGROUND } from '../../constants';
import useCalcAchievementRate from '../../hook/useCalcAchievementRate';
import MonthlySalesTarget from './MonthlySalesTarget';

const SalesSummaryStatistic = (props) => {
  const { salesSummaryData, monthValue, isLargeScreen } = props;

  const { darkMode } = useSelector((state) => state.darkMode);

  const bgColor = darkMode ? DARK_MODE_BACKGROUND : LIGHT_MODE_BACKGROUND;

  const { calcAchievementRate } = useCalcAchievementRate();

  const [achievementRate, setAchievementRate] = useState(0);

  useEffect(() => {
    (async () => {
      const newAchievementRate = await calcAchievementRate({
        salesMonth: monthValue,
        salesAmount: salesSummaryData,
      });
      setAchievementRate(newAchievementRate);
    })();
  }, [salesSummaryData, monthValue]);

  const headerMonthText =
    monthValue?.slice(-2)[0] === '0'
      ? monthValue.slice(-1)
      : monthValue.slice(-2);

  return (
    <div
      style={{
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: isLargeScreen ? 'row' : 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        padding: '10px',
        color: 'gray',
        backgroundColor: bgColor,
      }}
    >
      <MonthlySalesTarget>
        <div style={{ display: 'flex', gap: '5px' }}>
          <h4>{headerMonthText}월</h4>
          <h4>
            ({achievementRate}
            {typeof achievementRate === 'number' && '%'})
          </h4>
        </div>
      </MonthlySalesTarget>
      <div>{Math.round(salesSummaryData / 10000).toLocaleString()}</div>
    </div>
  );
};

export default SalesSummaryStatistic;

SalesSummaryStatistic.propTypes = {
  salesSummaryData: PropTypes.number.isRequired,
  monthValue: PropTypes.string.isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
};
