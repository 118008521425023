import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const SalesAmountValue = (props) => {
  const { id } = props;
  const dispatch = useDispatch();

  const [salesAmount, setSalesAmount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSalesAmount = async () => {
      await setLoading(true);
      let newSalesAmount = 0;
      const orderProducts = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'order_product',
          eqKey: 'order_id',
          eqValue: id,
        })
      )
        .unwrap()
        .then((res) => {
          return res;
        })
        .catch();
      for (const orderProduct of orderProducts) {
        const {
          product_supply_price,
          product_VAT_price,
          discounted,
          delivery_price,
          discount_rate,
        } = orderProduct;
        let totalPrice = 0;
        if (discount_rate > 0) {
          totalPrice = discounted + delivery_price;
        } else {
          totalPrice =
            product_supply_price + product_VAT_price + delivery_price;
        }
        newSalesAmount += totalPrice;
      }
      await setSalesAmount(newSalesAmount);
      setLoading(false);
    };
    getSalesAmount();
  }, [dispatch, id]);

  return (
    <div>
      {loading ? (
        <Skeleton.Input active size="small" />
      ) : (
        <div>{salesAmount.toLocaleString()}원</div>
      )}
    </div>
  );
};

export default SalesAmountValue;

SalesAmountValue.propTypes = {
  id: PropTypes.string.isRequired,
};
