import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 32px;
`;

function LoginHeader() {
  return (
    <HeaderContainer>
      <div style={{ color: 'gray' }}>한통OMS에 이메일로 로그인</div>
      {/*
      <Link
        to="/signup"
        style={{
          color: 'rgb(0, 101, 255)',
        }}
      >
        회원가입
      </Link>
    */}
    </HeaderContainer>
  );
}

export default LoginHeader;
