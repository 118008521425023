import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Layout, Menu, theme } from 'antd';
import {
  LogoutOutlined,
  GiftOutlined,
  SendOutlined,
  BarChartOutlined,
  StockOutlined,
} from '@ant-design/icons';
import {
  TbUserEdit,
  TbUsersGroup,
  TbShoppingCartPlus,
  TbShoppingCartCheck,
  TbShoppingBagPlus,
  TbShoppingBagCheck,
} from 'react-icons/tb';
import {
  LiaShippingFastSolid,
  LiaFileInvoiceDollarSolid,
} from 'react-icons/lia';
import { BsBox, BsBoxes, BsCalculator, BsPinMap } from 'react-icons/bs';
import { LuWarehouse } from 'react-icons/lu';
import { useSelector } from 'react-redux';

import useModal from '../../../hook/useModal';
import { collapseSidebarConditionsByUser } from '../../../constants';

const { Sider } = Layout;
const { Item, SubMenu } = Menu;

const SiderComponent = (props) => {
  const { breadCrumbNav, userInfo } = props;
  const { openModal } = useModal();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { darkMode } = useSelector((state) => state.darkMode);

  const checkIsConditionToCollapseSidebar = useCallback(
    ({ value, conditions }) => {
      const keysOfConditions = Object.keys(conditions);
      return keysOfConditions.some((key) => conditions[key] === value);
    },
    [userInfo.id]
  );

  return (
    <Sider
      width={150}
      style={{
        background: colorBgContainer,
      }}
      collapsible
      defaultCollapsed={checkIsConditionToCollapseSidebar({
        value: userInfo.id,
        conditions: collapseSidebarConditionsByUser,
      })}
      className={darkMode ? 'is-dark-mode' : 'is-light-mode'}
    >
      <Menu
        theme={darkMode ? 'dark' : 'light'}
        mode="inline"
        defaultSelectedKeys={[breadCrumbNav.trim()]}
        style={{
          height: '100%',
          position: 'relative',
          borderRight: !darkMode && '1px solid lightgray',
        }}
      >
        <SubMenu title="데이터뷰" icon={<BarChartOutlined />}>
          <Item key="데이터달력">
            <Link to="/data-calendar">데이터달력</Link>
          </Item>
          <Item key="데이터달력_1280">
            <Link to="/data-calendar/browse-here">데이터달력_1280</Link>
          </Item>
          {/*
          <Item key="데이터맵">
            <Link to="/data-map">데이터맵</Link>
          </Item>
          */}
        </SubMenu>
        {userInfo.tier < 3 ? (
          <Item key="관리자리스트" icon={<TbUserEdit />}>
            <Link to="/manager">관리자</Link>
          </Item>
        ) : null}
        <SubMenu title="거래처" icon={<TbUsersGroup />}>
          <Item key="거래처">
            <Link to="/account">거래처</Link>
          </Item>
          <Item key="거래처할인">
            <Link to="/account-discount">거래처할인</Link>
          </Item>
          <Item key="거래처그룹">
            <Link to="/accountgroup">거래처그룹</Link>
          </Item>
        </SubMenu>
        <Item key="창고" icon={<LuWarehouse />}>
          <Link to="/warehouse">창고</Link>
        </Item>
        <Item key="출고상품(품목)" icon={<BsBox />}>
          <Link to="/product">출고상품</Link>
        </Item>
        <Item key="판매상품" icon={<GiftOutlined />}>
          <Link to="/sale">판매상품</Link>
        </Item>
        <Item key="계산기" icon={<BsCalculator />}>
          <Link to="/calculator">계산기</Link>
        </Item>
        <Item key="견적서" icon={<LiaFileInvoiceDollarSolid />}>
          <Link to="/estimate">견적서</Link>
        </Item>
        <Item key="주문" icon={<TbShoppingCartPlus />}>
          <Link to="/order">주문</Link>
        </Item>
        <SubMenu title="판매" icon={<TbShoppingCartCheck />}>
          <Item key="판매조회">
            <Link to="/sales-inquiry">판매조회</Link>
          </Item>
          <Item key="판매현황">
            <Link to="/sales-status">판매현황</Link>
          </Item>
        </SubMenu>
        <Item key="출하지시서" icon={<LiaShippingFastSolid />}>
          <Link to="/directions">출하지시서</Link>
        </Item>
        <SubMenu title="발주" icon={<TbShoppingBagPlus />}>
          <Item key="발주조회">
            <Link to="/purchase-order">발주조회</Link>
          </Item>
          {/*
          <Item key="발주현황">
            <Link to="/purchase-order-inquiry">발주현황</Link>
          </Item>
          */}
        </SubMenu>
        <SubMenu title="구매" icon={<TbShoppingBagCheck />}>
          <Item key="구매조회">
            <Link to="/purchase">구매조회</Link>
          </Item>
          {/*
          <Item key="구매현황">
            <Link to="/purchase-status">구매현황</Link>
          </Item>
          */}
          {/*
          <Item key="구매현황">
            <Link to="/purchase-inquiry">구매현황</Link>
          </Item>
           */}
        </SubMenu>
        <SubMenu title="재고" icon={<BsBoxes />}>
          <Item key="재고현황">
            <Link to="/inventory">재고현황</Link>
          </Item>
          <Item key="재고수불부">
            <Link to="/inventory-receipts">재고수불부</Link>
          </Item>
        </SubMenu>
        <Item key="용달" icon={<BsPinMap />}>
          <Link to="/delivery">용달조회</Link>
        </Item>
        <Item key="주문제안" icon={<SendOutlined />}>
          <Link to="/propose">주문제안</Link>
        </Item>
        {/*
        <Item
          key="로그아웃"
          icon={
            <LogoutOutlined style={{ fontSize: '16px', fontWeight: '600' }} />
          }
          style={{
            fontSize: '15px',
            fontWeight: '700',
          }}
          onClick={() => openModal({ type: 'logout' })}
        >
          로그아웃
        </Item>
        */}
      </Menu>
    </Sider>
  );
};

export default SiderComponent;

SiderComponent.propTypes = {
  breadCrumbNav: PropTypes.string.isRequired,
  userInfo: PropTypes.instanceOf(Object).isRequired,
};
