import React, { useState } from 'react';
import { getSmsHistoryApi, getSmsRequestApi } from '../apis/smsApi';

const useSmsHistory = () => {
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState({
    loading: false,
    text: null,
  });

  const createQueryString = (values) => {
    const queryArray = [];

    queryArray.push(
      `${values.rangeType}StartTime=${values.startDate} ${values.startTime}`
    );
    queryArray.push(
      `${values.rangeType}EndTime=${values.endDate} ${values.endTime}`
    );

    if (values.type) {
      queryArray.push(`type=${values.type}`);
    }
    if (values.contentType) {
      queryArray.push(`contentType=${values.contentType}`);
    }
    if (values.statusName) {
      queryArray.push(`statusName=${values.statusName}`);
    }
    if (values.from) {
      queryArray.push(`from=${values.from}`);
    }
    if (values.to) {
      queryArray.push(`to=${values.to}`);
    }

    const queryString = `${queryArray.join('&')}`;

    return queryString;
  };

  const getHistoryData = async ({ values }) => {
    try {
      setLoading({ loading: true, text: '내역을 검색 중입니다...' });
      const queryString = createQueryString(values);
      const postData = { queryString };
      const response = await getSmsHistoryApi(postData);

      const newHistoryData = response.map((data) => ({
        requestTime: data.request_time,
        completeTime: data.complete_time,
        status: data.request_status,
        statusName: data.reception_status,
        type: data.sms_type,
        from: data.send_from,
        to: data.send_to,
      }));

      setHistoryData(newHistoryData);
    } catch (error) {
      window.alert(
        `[검색 오류]\n메시지 검색중 오류가 발생하였습니다.\n${error}`
      );
      setLoading({ loading: false, text: null });
      console.error(error);
    } finally {
      setLoading({ loading: false, text: null });
    }
  };

  return { historyData, loading, getHistoryData };
};

export default useSmsHistory;
