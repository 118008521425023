import React from 'react';

import { useInventoryStatusCardContext } from './InventoryStatusCardMain';

const InventoryStatusCardDivider = () => {
  const { darkMode } = useInventoryStatusCardContext();

  return (
    <div
      style={{
        height: '100%',
        borderLeft: `0.5px solid ${darkMode ? 'gray' : 'lightgray'}`,
      }}
    />
  );
};

export default InventoryStatusCardDivider;
