import React from 'react';
import { Empty, Spin, Table } from 'antd';

import InventoryReceiptsSearch from './InventoryReceiptsSearch';
import InventoryReceiptsTables from './InventoryReceiptsTables';
import useGetInventoryReceiptsData from '../../hook/useGetInventoryReceiptsData';

const InventoryReceiptsHandler = () => {
  const [receiptsData, isLoading, getReceiptsData] =
    useGetInventoryReceiptsData();

  return (
    <div className="select-container" style={{ padding: '20px' }}>
      <div
        style={{
          fontSize: '16px',
          borderBottom: '1px solid gray',
          paddingBottom: '10px',
        }}
      >
        재고수불부 검색
      </div>
      <InventoryReceiptsSearch getReceiptsData={getReceiptsData} />
      <div
        style={{
          fontSize: '16px',
          paddingBottom: '10px',
          marginTop: '50px',
        }}
      >
        재고수불부
      </div>
      {isLoading ? (
        <Spin size="large" style={{ margin: '20px' }} />
      ) : receiptsData.length > 0 ? (
        <InventoryReceiptsTables receiptsData={receiptsData} />
      ) : (
        <div
          style={{
            borderRadius: '20px',
            border: '1px solid lightgray',
            width: '100%',
            height: '200px',
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
          }}
        >
          <Empty description="데이터가 없습니다." />
        </div>
      )}
    </div>
  );
};

export default InventoryReceiptsHandler;
