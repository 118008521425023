import React, { useState } from 'react';
import { Button, Empty } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import axiosInstance from '../../axios';
import { asyncReadPaginatedDataFetch } from '../../redux/modules/shared/readPaginatedData';

const batchSize = 1000;

const OneTimeMigrator = ({ entireTables, partTables }) => {
  const dispatch = useDispatch();

  const [migrationStatus, setMigrationStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidButton, setIsValidButton] = useState(true);
  const [currentPartRange, setCurrentPartRange] = useState({
    from: 0,
    to: batchSize - 1,
  });

  const insertMigrationStatus = (table) => {
    setMigrationStatus((prev) => [
      ...prev,
      { status: '대기', rowCount: { pending: 0, successed: 0 }, table },
    ]);
  };

  const updateMigrationStatus = (table, status, pending = 0, successed = 0) => {
    setMigrationStatus((prev) =>
      prev.map((item) =>
        item.table === table
          ? { ...item, status, rowCount: { pending, successed } }
          : item
      )
    );
  };

  const handleMigration = async () => {
    const confirm = window.confirm(
      '정말 일괄 마이그레이션을 진행하시겠습니까?'
    );
    if (!confirm) return;

    setIsValidButton(false);
    setIsLoading(true);

    try {
      for (const table of entireTables) {
        insertMigrationStatus(table);

        await new Promise((resolve) => setTimeout(resolve, 1500));

        const migrationData = await dispatch(
          asyncReadPaginatedDataFetch({ table })
        ).unwrap();

        updateMigrationStatus(table, '진행중...', migrationData.length);

        const response = await axiosInstance.post(
          `/api/migration/${table}`,
          migrationData
        );

        updateMigrationStatus(
          table,
          '완료',
          migrationData.length,
          response.data
        );
      }

      for (const table of partTables) {
        let isLastBatch = false;

        while (!isLastBatch) {
          const { from, to } = currentPartRange;
          const tableNameForStatus = `${table}_${from}~`;

          insertMigrationStatus(tableNameForStatus);

          await new Promise((resolve) => setTimeout(resolve, 1500));

          const migrationData = await dispatch(
            asyncReadPaginatedDataFetch({ table, from, to })
          ).unwrap();

          updateMigrationStatus(
            tableNameForStatus,
            '진행중...',
            migrationData.length
          );

          const response = await axiosInstance.post(
            `/api/migration/${table}`,
            migrationData
          );

          updateMigrationStatus(
            tableNameForStatus,
            '완료',
            migrationData.length,
            response.data
          );

          const isEqalWithMigratedSizeAndBatchSize =
            response.data === batchSize;

          if (isEqalWithMigratedSizeAndBatchSize) {
            const newFrom = from + batchSize;
            const newTo = to + batchSize;
            currentPartRange.from = newFrom;
            currentPartRange.to = newTo;
            setCurrentPartRange({ from: newFrom, to: newTo });
          } else {
            const newFrom = 0;
            const newTo = batchSize - 1;
            currentPartRange.from = newFrom;
            currentPartRange.to = newTo;
            setCurrentPartRange({ from: newFrom, to: newTo });
            isLastBatch = true;
          }
        }
      }
    } catch (error) {
      window.alert(`마이그레이션 오류 \n${error}`);
      console.error('마이그레이션 오류', error);
    }

    setIsValidButton(true);
    setIsLoading(false);

    window.alert('일괄 마이그레이션 작업이 완료되었습니다.');
  };

  return (
    <div style={{ marginTop: '10px', textAlign: 'center' }}>
      <Button
        type="primary"
        size="large"
        onClick={handleMigration}
        disabled={!isValidButton}
        loading={isLoading}
      >
        MIGRATE ALL
      </Button>
      <div
        style={{
          padding: '30px',
          border: '1px solid gray',
          marginTop: '15px',
          borderRadius: '20px',
          backgroundColor: '#efefef',
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
        }}
      >
        {migrationStatus.length > 0 ? (
          migrationStatus.map((item, index) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
              key={index}
            >
              <h3>{item.table}</h3>
              <div style={{ display: 'flex', gap: '10px' }}>
                <div>pending: {item.rowCount.pending}</div>
                <div>successed: {item.rowCount.successed}</div>
                <div>{item.status}</div>
              </div>
            </div>
          ))
        ) : (
          <Empty style={{ color: 'gray' }} description="" />
        )}
      </div>
    </div>
  );
};

export default OneTimeMigrator;

OneTimeMigrator.propTypes = {
  entireTables: PropTypes.instanceOf(Array).isRequired,
  partTables: PropTypes.instanceOf(Array).isRequired,
};
