import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../axios';
import useSupabase from '../../../serverConfig';

import supabase from '../../../supabase';

const initialState = {
  isLoadingDeleteData: false,
  isErrorDeleteData: false,
  isSuccessDeleteData: false,
};

const asyncDeleteDataFetch = createAsyncThunk(
  'deleteDataSlice/asyncDeleteDataFetch',
  async (values, { rejectWithValue }) => {
    if (values.table === 'sale_product' && values.isBatchDelete === true) {
      // 판매-출고상품 (sale_product) 수정의 경우 sale_code로 우선 일괄 삭제처리함.
      const { saleCode, table } = values;
      try {
        const { data, error } = useSupabase
          ? await supabase.from(table).delete().eq('sale_code', saleCode)
          : await axiosInstance.delete(
              `/api/dynamic-crud/${table}?key=sale_code&value=${saleCode}`
            );

        console.log(data);

        if (error) throw error;
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
    // 주문-판매상품 (order_sale) 또는 주문-출고상품 (order-product) 수정의 경우 order_id로 우선 일괄 삭제처리함.
    if (values.table === 'order_sale' || values.table === 'order_product') {
      const { orderId, table } = values;
      try {
        const { data, error } = useSupabase
          ? await supabase.from(table).delete().eq('order_id', orderId)
          : await axiosInstance.delete(
              `/api/dynamic-crud/${table}?key=order_id&value=${orderId}`
            );

        console.log(data);

        if (error) throw error;
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
    // 거채처-주소 (account_address)의 경우 account_code로 우선 일괄 삭제처리함.
    if (values.table === 'account_address') {
      const { accountCode, table } = values;
      try {
        const { data, error } = useSupabase
          ? await supabase.from(table).delete().eq('account_code', accountCode)
          : await axiosInstance.delete(
              `/api/dynamic-crud/${table}?key=account_code&value=${accountCode}`
            );

        console.log(data);

        if (error) throw error;
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
    // 거래처-담당자 (account_resp)의 경우 account_code로 우선 일괄 삭제처리함.
    if (values.table === 'account_resp') {
      const { accountCode, table } = values;
      try {
        const { data, error } = useSupabase
          ? await supabase.from(table).delete().eq('account_code', accountCode)
          : await axiosInstance.delete(
              `/api/dynamic-crud/${table}?key=account_code&value=${accountCode}`
            );

        console.log(data);

        if (error) throw error;
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
    // 견적서 발송여부 (estimate_sent)의 경우 estimate_id (order의 id값)으로 삭제처리함.
    if (values.table === 'estimate_sent') {
      const { table, estimateId } = values;
      try {
        const { data, error } = useSupabase
          ? await supabase.from(table).delete().eq('estimate_id', estimateId)
          : await axiosInstance.delete(
              `/api/dynamic-crud/${table}?key=estimate_id&value=${estimateId}`
            );

        console.log(data);

        if (error) throw error;
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
    // 주문제안 거절 거래처 (propose_reject)의 경우 create_date로 삭제처리함.
    if (values.table === 'propose_reject') {
      const { table, accountCode } = values;
      try {
        const { data, error } = useSupabase
          ? await supabase.from(table).delete().eq('account_code', accountCode)
          : await axiosInstance.delete(
              `/api/dynamic-crud/${table}?key=account_code&value=${accountCode}`
            );

        console.log(data);

        if (error) throw error;
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }

    // values의 필수 값 => id, table
    const { id, table } = values;
    try {
      const { data, error } = useSupabase
        ? await supabase.from(table).delete().eq('id', id)
        : await axiosInstance.delete(
            `/api/dynamic-crud/${table}?key=id&value=${id}`
          );

      console.log(data);

      if (error) throw error;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const deleteDataSlice = createSlice({
  name: 'deleteData',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncDeleteDataFetch.pending, (state) => {
      state.isLoadingDeleteData = true;
      state.isSuccessDeleteData = false;
      state.isErrorDeleteData = false;
    });
    builder.addCase(asyncDeleteDataFetch.fulfilled, (state) => {
      state.isLoadingDeleteData = false;
      state.isSuccessDeleteData = true;
      state.isErrorDeleteData = false;
    });
    builder.addCase(asyncDeleteDataFetch.rejected, (state) => {
      state.isLoadingDeleteData = false;
      state.isSuccessDeleteData = false;
      state.isErrorDeleteData = true;
    });
  },
});

export default deleteDataSlice.reducer;
export { asyncDeleteDataFetch };
