import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import uuid from 'react-uuid';
import axiosInstance from '../../../axios';
import useSupabase from '../../../serverConfig';

import supabase, { supabaseAdmin } from '../../../supabase';
import isUserMigrated from '../../../userMigrationConfig';

const initialState = {
  isLoadingCreateUser: false,
  isSuccessCreateUser: false,
  isErrorCreateUser: false,
};

// 수파베이스 연결
const createUserBySupabase = async (
  email,
  password,
  username,
  phone,
  tier,
  permission
) => {
  const { data } = await supabaseAdmin.auth.admin.createUser({
    email,
    password,
    email_confirm: true,
    user_metadata: {
      username,
      phone,
    },
  });
  if (data) {
    const { id } = data.user;
    await supabase.from('profiles').update({ tier, permission }).eq('id', id);
    return id;
  }
};
// 자체서버 연결 (유저이식 후)
const createUserByApi = async (
  id,
  email,
  password,
  username,
  phone,
  tier,
  permission
) => {
  let body;
  if (id) {
    body = { id, email, password, username, phone };
  } else {
    body = { id: uuid(), email, password, username, phone };
  }
  const { data } = await axiosInstance.post(`/api/auth/signup`, body);
  if (data) {
    await axiosInstance.patch(
      `/api/dynamic-crud/profiles?key=email&value=${email}`,
      {
        tier,
        permission,
      }
    );
  }
};
// 자체서버 연결 (유저이식 전 까지)
const createUserWithMigrateUser = async (
  email,
  password,
  username,
  phone,
  tier,
  permission
) => {
  const id = await createUserBySupabase(
    email,
    password,
    username,
    phone,
    tier,
    permission
  );
  await createUserByApi(id, email, password, username, phone, tier, permission);
};

const asyncCreateUserFetch = createAsyncThunk(
  'createUserSlice/asyncCreateUserFetch',
  async (values, { rejectWithValue }) => {
    const { email, password, username, phone, tier, permission } = values;
    try {
      if (useSupabase) {
        await createUserBySupabase(
          email,
          password,
          username,
          phone,
          tier,
          permission
        );
      } else {
        if (isUserMigrated) {
          await createUserByApi(
            undefined,
            email,
            password,
            username,
            phone,
            tier,
            permission
          );
        } else {
          await createUserWithMigrateUser(
            email,
            password,
            username,
            phone,
            tier,
            permission
          );
        }
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createUserSlice = createSlice({
  name: 'createUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncCreateUserFetch.pending, (state) => {
      state.isLoadingCreateUser = true;
      state.isSuccessCreateUser = false;
      state.isErrorCreateUser = false;
    });
    builder.addCase(asyncCreateUserFetch.fulfilled, (state) => {
      state.isLoadingCreateUser = false;
      state.isSuccessCreateUser = true;
      state.isErrorCreateUser = false;
    });
    builder.addCase(asyncCreateUserFetch.rejected, (state) => {
      state.isLoadingCreateUser = false;
      state.isSuccessCreateUser = false;
      state.isErrorCreateUser = true;
    });
  },
});

export default createUserSlice.reducer;
export { asyncCreateUserFetch };
