import React, { useState, useEffect } from 'react';
import {
  Button,
  ConfigProvider,
  DatePicker,
  Descriptions,
  Input,
  Popover,
  Select,
  Tag,
} from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// datepicker 한글화
import 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ko';
import locale from 'antd/lib/locale/ko_KR';
import {
  CloseCircleFilled,
  DownOutlined,
  FileExcelOutlined,
  UpOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import SalesStatusExcelHandler from './SalesStatusExcelHandler';
import TaxInvoiceExcelHandler from './TaxInvoiceExcelHandler';

const SalesStatusForm = (props) => {
  const {
    setFormInputs,
    formInputs,
    genSalesStatusDatas,
    salesStatusDatas,
    setSalesStatusDatas,
    setTotalSalesStatusData,
  } = props;

  const dispatch = useDispatch();

  const [showUnderPart, setShowUnderPart] = useState(false);

  // 구분 (category) 옵션
  const categoryOptions = [
    { label: '라인별', value: 'line' },
    { label: '일별', value: 'daily' },
    { label: '월별', value: 'monthly' },
    { label: '주문별', value: 'order' },
    { label: '품목별', value: 'product' },
    { label: '거래처별', value: 'account' },
    { label: '담당자별', value: 'admin' },
    {
      label: <span style={{ color: '#f5222d' }}>세금계산서등록양식</span>,
      value: 'taxInvoice',
    },
  ];
  // 거래유형 (dealtype) 옵션
  const dealTypeOptions = [
    { label: '부가세율 적용', value: '11' },
    { label: '부가세율 미적용', value: '12' },
  ];
  // 창고 (warehouse) 옵션
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  // 거래처 (account) 옵션
  const [accountOptions, setAccountOptions] = useState([]);
  // 품목 (product) 옵션
  const [productOptions, setProductOptions] = useState([]);
  // 담당자 (manager) 옵션
  const [managerOptions, setManagerOptions] = useState([]);

  // 창고, 거래처, 품목, 담당자 옵션생성
  useEffect(() => {
    const getOptions = async (table) => {
      try {
        const datas = await dispatch(asyncReadDataFetch({ table }))
          .unwrap()
          .then((res) => res);
        const result = datas.map((data) => ({
          label: `${data.code} / ${
            table === 'profiles' ? data.username : data.name
          }`,
          value: data.code,
        }));
        return result;
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    };
    getOptions('warehouse').then((options) => setWarehouseOptions(options));
    getOptions('account').then((options) => setAccountOptions(options));
    getOptions('product').then((options) => setProductOptions(options));
    getOptions('profiles').then((options) => setManagerOptions(options));
  }, [dispatch]);
  // // 창고, 거래처, 품목, 담당자 옵션중복검사
  const checkDupOption = (option, value) => {
    let isDup = false;
    for (const optData of formInputs[option]) {
      if (optData.value === value) isDup = true;
    }
    return isDup;
  };
  // 창고, 거래처, 품목, 담당자 옵션선택
  const addSelectedOption = async (value, table) => {
    try {
      // 옵션값 중복검사
      const option = table === 'profiles' ? 'manager' : table;
      const isDup = checkDupOption(option, value);
      if (isDup) {
        alert('이미 선택된 옵션입니다.');
        return;
      }
      // 라벨값
      const label = await dispatch(
        asyncReadFilteredDataFetch({ table, eqKey: 'code', eqValue: value })
      )
        .unwrap()
        .then((res) => (table === 'profiles' ? res[0].username : res[0].name));
      // formInputs state 업데이트
      if (table === 'warehouse') {
        setFormInputs((prev) => ({
          ...prev,
          warehouse: [...prev.warehouse, { label, value }],
        }));
      }
      if (table === 'account') {
        setFormInputs((prev) => ({
          ...prev,
          account: [...prev.account, { label, value }],
        }));
      }
      if (table === 'product') {
        setFormInputs((prev) => ({
          ...prev,
          product: [...prev.product, { label, value }],
        }));
      }
      if (table === 'profiles') {
        setFormInputs((prev) => ({
          ...prev,
          manager: [...prev.manager, { label, value }],
        }));
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };
  // 창고, 거래처, 품목, 담당자 옵션삭제
  const deleteSelectedOption = (option, value) => {
    // 필터
    const filtered = formInputs[option].filter((data) => data.value !== value);
    // formInputs state 업데이트
    if (option === 'warehouse') {
      setFormInputs((prev) => ({
        ...prev,
        warehouse: filtered,
      }));
    }
    if (option === 'account') {
      setFormInputs((prev) => ({
        ...prev,
        account: filtered,
      }));
    }
    if (option === 'product') {
      setFormInputs((prev) => ({
        ...prev,
        product: filtered,
      }));
    }
    if (option === 'manager') {
      setFormInputs((prev) => ({
        ...prev,
        manager: filtered,
      }));
    }
  };
  // 기준일자 옵션선택
  const selectDateOption = (values) => {
    const start = values[0];
    const end = values[1];
    const formatDate = (value) => {
      const year = value.$y.toString().padStart(4, '0');
      const month = (value.$M + 1).toString().padStart(2, '0');
      const day = value.$D.toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    setFormInputs((prev) => ({
      ...prev,
      date: { begin: formatDate(start), end: formatDate(end) },
    }));
  };

  return (
    <>
      <Descriptions bordered labelStyle={{ width: '10%', textAlign: 'center' }}>
        {/* 구분 */}
        <Descriptions.Item
          label={
            <div>
              <span style={{ color: 'red', fontSize: '16px' }}>*</span>구분
            </div>
          }
          span={3}
        >
          <div className="select-container">
            <Select
              options={categoryOptions}
              style={{ width: '100%' }}
              defaultValue="line"
              onSelect={(value) => {
                setFormInputs((prev) => ({ ...prev, category: value }));
                setSalesStatusDatas([]);
                setTotalSalesStatusData({
                  totalDcQuantity: 0,
                  totalQuantity: 0,
                  totalSupply: 0,
                  totalVat: 0,
                  totalSum: 0,
                  totalCount: 0,
                });
              }}
            />
          </div>
        </Descriptions.Item>
        {/* 기준일자 */}
        <Descriptions.Item
          label={
            <div>
              <span style={{ color: 'red', fontSize: '16px' }}>*</span>기준일자
            </div>
          }
          span={3}
        >
          <ConfigProvider locale={locale}>
            <DatePicker.RangePicker
              style={{ borderRadius: '0px', width: '100%' }}
              onChange={(values) => selectDateOption(values)}
            />
          </ConfigProvider>
        </Descriptions.Item>
        {/* 거래처 */}
        <Descriptions.Item label="거래처" span={3}>
          <div style={{ display: 'flex' }}>
            {formInputs.account.map((item, index) => (
              <TagStyle color="blue" key={index}>
                {item.label}
                <ButtonStyle
                  type="link"
                  size="small"
                  onClick={() => deleteSelectedOption('account', item.value)}
                >
                  <CloseCircleFilled />
                </ButtonStyle>
              </TagStyle>
            ))}
          </div>
          <div className="select-container">
            <Select
              showSearch
              filterOption={(input, option) => option.label.includes(input)}
              options={accountOptions}
              style={{ width: '100%' }}
              placeholder="거래처"
              optionFilterProp="children"
              onSelect={(value) => addSelectedOption(value, 'account')}
              value={null}
            />
          </div>
        </Descriptions.Item>
        {/* 품목 */}
        <Descriptions.Item
          label="품목"
          span={3}
          style={{ borderBottom: '1px solid #efefef' }}
        >
          <div style={{ display: 'flex' }}>
            {formInputs.product.map((item, index) => (
              <TagStyle color="blue" key={index}>
                {item.label}
                <ButtonStyle
                  type="link"
                  size="small"
                  onClick={() => deleteSelectedOption('product', item.value)}
                >
                  <CloseCircleFilled />
                </ButtonStyle>
              </TagStyle>
            ))}
          </div>
          <div className="select-container">
            <Select
              showSearch
              filterOption={(input, option) => option.label.includes(input)}
              options={productOptions}
              style={{ width: '100%' }}
              placeholder="품목"
              optionFilterProp="children"
              onSelect={(value) => addSelectedOption(value, 'product')}
              value={null}
            />
          </div>
        </Descriptions.Item>
      </Descriptions>
      {/* 밑 부분 on 버튼 */}
      {!showUnderPart && (
        <div style={{ textAlign: 'center' }}>
          <Button
            size="small"
            style={{
              padding: '0 30px',
              margin: '10px 0',
              borderRadius: '20px',
            }}
            onClick={() => setShowUnderPart(true)}
          >
            <DownOutlined />
          </Button>
        </div>
      )}
      {showUnderPart && (
        <Descriptions
          bordered
          labelStyle={{ width: '10%', textAlign: 'center' }}
        >
          {/* 거래유형 */}
          <Descriptions.Item label="거래유형" span={3}>
            <div className="select-container">
              <Select
                options={dealTypeOptions}
                style={{ width: '100%' }}
                placeholder="거래유형"
                onSelect={(value) =>
                  setFormInputs((prev) => ({
                    ...prev,
                    dealType: value,
                  }))
                }
                value={formInputs.dealType}
              />
            </div>
          </Descriptions.Item>
          {/* 창고 */}
          <Descriptions.Item label="창고" span={3}>
            <div style={{ display: 'flex' }}>
              {formInputs.warehouse.map((item, index) => (
                <TagStyle color="blue" key={index}>
                  {item.label}
                  <ButtonStyle
                    type="link"
                    size="small"
                    onClick={() =>
                      deleteSelectedOption('warehouse', item.value)
                    }
                  >
                    <CloseCircleFilled />
                  </ButtonStyle>
                </TagStyle>
              ))}
            </div>
            <div className="select-container">
              <Select
                style={{ width: '100%' }}
                placeholder="창고"
                options={warehouseOptions}
                onSelect={(value) => addSelectedOption(value, 'warehouse')}
                value={null}
              />
            </div>
          </Descriptions.Item>
          {/* 담당자 */}
          <Descriptions.Item label="담당자" span={3}>
            <div style={{ display: 'flex' }}>
              {formInputs.manager.map((item, index) => (
                <TagStyle color="blue" key={index}>
                  {item.label}
                  <ButtonStyle
                    type="link"
                    size="small"
                    onClick={() => deleteSelectedOption('manager', item.value)}
                  >
                    <CloseCircleFilled />
                  </ButtonStyle>
                </TagStyle>
              ))}
            </div>
            <div className="select-container">
              <Select
                style={{ width: '100%' }}
                placeholder="담당자"
                options={managerOptions}
                onSelect={(value) => addSelectedOption(value, 'profiles')}
                value={null}
              />
            </div>
          </Descriptions.Item>
          {/* 수량 */}
          <Descriptions.Item label="수량" span={3}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <NoBorderInput
                placeholder="MIN"
                type="number"
                value={formInputs.quantity.min}
                onChange={(e) =>
                  setFormInputs((prev) => ({
                    ...prev,
                    quantity: { ...prev.quantity, min: Number(e.target.value) },
                  }))
                }
              />
              <p style={{ margin: '0 10px' }}>~</p>
              <NoBorderInput
                placeholder="MAX"
                type="number"
                value={formInputs.quantity.max}
                onChange={(e) =>
                  setFormInputs((prev) => ({
                    ...prev,
                    quantity: { ...prev.quantity, max: Number(e.target.value) },
                  }))
                }
              />
            </div>
          </Descriptions.Item>
          {/* 단가 */}
          <Descriptions.Item label="단가" span={3}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <NoBorderInput
                placeholder="MIN"
                type="number"
                value={formInputs.price.min}
                onChange={(e) =>
                  setFormInputs((prev) => ({
                    ...prev,
                    price: { ...prev.price, min: Number(e.target.value) },
                  }))
                }
              />
              <p style={{ margin: '0 10px' }}>~</p>
              <NoBorderInput
                placeholder="MAX"
                type="number"
                value={formInputs.price.max}
                onChange={(e) =>
                  setFormInputs((prev) => ({
                    ...prev,
                    price: { ...prev.price, max: Number(e.target.value) },
                  }))
                }
              />
            </div>
          </Descriptions.Item>
          {/* 공급가액 */}
          <Descriptions.Item label="공급가액" span={3}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <NoBorderInput
                placeholder="MIN"
                type="number"
                value={formInputs.supplyPrice.min}
                onChange={(e) =>
                  setFormInputs((prev) => ({
                    ...prev,
                    supplyPrice: {
                      ...prev.supplyPrice,
                      min: Number(e.target.value),
                    },
                  }))
                }
              />
              <p style={{ margin: '0 10px' }}>~</p>
              <NoBorderInput
                placeholder="MAX"
                type="number"
                value={formInputs.supplyPrice.max}
                onChange={(e) =>
                  setFormInputs((prev) => ({
                    ...prev,
                    supplyPrice: {
                      ...prev.supplyPrice,
                      max: Number(e.target.value),
                    },
                  }))
                }
              />
            </div>
          </Descriptions.Item>
          {/* 부가세 */}
          <Descriptions.Item
            label="부가세"
            span={3}
            style={{ borderBottom: '1px solid #efefef' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <NoBorderInput
                placeholder="MIN"
                type="number"
                value={formInputs.vatPrice.min}
                onChange={(e) =>
                  setFormInputs((prev) => ({
                    ...prev,
                    vatPrice: {
                      ...prev.vatPrice,
                      min: Number(e.target.value),
                    },
                  }))
                }
              />
              <p style={{ margin: '0 10px' }}>~</p>
              <NoBorderInput
                placeholder="MAX"
                type="number"
                value={formInputs.vatPrice.max}
                onChange={(e) =>
                  setFormInputs((prev) => ({
                    ...prev,
                    vatPrice: {
                      ...prev.vatPrice,
                      max: Number(e.target.value),
                    },
                  }))
                }
              />
            </div>
          </Descriptions.Item>
        </Descriptions>
      )}
      {/* 밑 부분 off 버튼 */}
      {showUnderPart && (
        <div style={{ textAlign: 'center' }}>
          <Button
            size="small"
            style={{
              padding: '0 30px',
              margin: '10px 0',
              borderRadius: '20px',
            }}
            onClick={() => setShowUnderPart(false)}
          >
            <UpOutlined />
          </Button>
        </div>
      )}
      <SearchButtonBar>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SearchButtonStyle
            type="primary"
            onClick={() => genSalesStatusDatas(false, '')}
          >
            검색
          </SearchButtonStyle>
          <SearchButtonStyle onClick={() => genSalesStatusDatas(true, 'today')}>
            금일
          </SearchButtonStyle>
          <SearchButtonStyle
            onClick={() => genSalesStatusDatas(true, 'yesterday')}
          >
            전일
          </SearchButtonStyle>
          <SearchButtonStyle
            onClick={() => genSalesStatusDatas(true, 'thisWeek')}
          >
            금주(~오늘)
          </SearchButtonStyle>
          <SearchButtonStyle
            onClick={() => genSalesStatusDatas(true, 'lastWeek')}
          >
            전주
          </SearchButtonStyle>
          <SearchButtonStyle
            onClick={() => genSalesStatusDatas(true, 'thisMonth')}
          >
            금월(~오늘)
          </SearchButtonStyle>
          <SearchButtonStyle
            onClick={() => genSalesStatusDatas(true, 'lastMonth')}
          >
            전월
          </SearchButtonStyle>
          <SearchButtonStyle
            onClick={() => genSalesStatusDatas(true, 'thisYear')}
          >
            올해(~오늘)
          </SearchButtonStyle>
          <SearchButtonStyle
            onClick={() => genSalesStatusDatas(true, 'lastYear')}
          >
            작년
          </SearchButtonStyle>
        </div>
        {formInputs.category === 'taxInvoice' ? (
          <TaxInvoiceExcelHandler
            salesStatusDatas={salesStatusDatas}
            date={formInputs.date}
            category={formInputs.category}
            isExistTaxInvoiceData={salesStatusDatas.length >= 1}
          />
        ) : (
          <SalesStatusExcelHandler
            salesStatusDatas={salesStatusDatas}
            date={formInputs.date}
            category={formInputs.category}
          />
        )}
      </SearchButtonBar>
    </>
  );
};

export default SalesStatusForm;

SalesStatusForm.propTypes = {
  setFormInputs: PropTypes.func.isRequired,
  formInputs: PropTypes.instanceOf(Object).isRequired,
  genSalesStatusDatas: PropTypes.func.isRequired,
  salesStatusDatas: PropTypes.instanceOf(Array).isRequired,
  setSalesStatusDatas: PropTypes.func.isRequired,
  setTotalSalesStatusData: PropTypes.func.isRequired,
};

const TagStyle = styled(Tag)`
  margin-bottom: 5px;
  padding: 2px 25px 2px 7px;
  position: relative;
  margin-right: 5px;
  border-radius: 20px;
`;
const ButtonStyle = styled(Button)`
  position: absolute;
  top: 0;
  right: -2px;
  padding: 0;
`;
const SearchButtonBar = styled.div`
  display: flex;
  width: 100%;
  background-color: #fafafa;
  padding: 15px;
  border-radius: 20px;
  justify-content: space-between;
  border: 1px solid #efefef;
`;
const SearchButtonStyle = styled(Button)`
  margin-right: 5px;
  border-radius: 20px;
`;
const NoBorderInput = styled(Input)`
  border-radius: 0px;
`;
