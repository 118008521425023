import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import LoginForm from './LoginForm';
import LoginHeader from './LoginHeader';
import SignupForm from './SignupForm';
import SignupHeader from './SignupHeader';

const Container = styled.div`
  width: 400px;
  height: 430px;
  border-radius: 5px;
  overflow: hidden;
  margin: 30px 0px 50px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: 1px solid lightGray;
  background-color: white;
  padding: 32px;
`;

function AuthContainer(props) {
  const { handleSubmit } = props;
  return (
    <Container>
      <LoginHeader />
      <LoginForm handleSubmit={handleSubmit} />
    </Container>
  );
}

export default AuthContainer;

AuthContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
