import React, { useEffect, useState } from 'react';
import { Button, Modal, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import EstimateBasicInfo from './EstimateBasicInfo';
import EstimateProductInfo from './EstimateProductInfo';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import useModal from '../../hook/useModal';

const EstimateModal = (props) => {
  const { id, setIsOpenEstimateModal } = props;

  const { openModal } = useModal();
  const dispatch = useDispatch();

  const [basicInfoData, setBasicInfoData] = useState({});
  const [saleInfoDatas, setSaleInfoDatas] = useState([]);
  const [productInfoDatas, setProductInfoDatas] = useState([]);
  const [basicInfoLoading, setBasicInfoLoading] = useState(false);
  const [productInfoLoading, setProductInfoLoading] = useState(false);

  // basicInfoData가 들어갈 formValue
  const [formValue, setFormValue] = useState({
    date: '',
    admin: '',
    account: '',
    dealType: '',
    paymentTerms: '',
    warehouse: '',
    expirationDate: 0,
  });
  // basicInfoData값을 formValue에 넣기
  useEffect(() => {
    const getFormValue = async () => {
      try {
        if (!basicInfoData.id) {
          return;
        }
        const { expiration_date } = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'estimate_sent',
            eqKey: 'estimate_id',
            eqValue: basicInfoData.id,
          })
        )
          .unwrap()
          .then((res) => res[0]);
        setFormValue({
          date: basicInfoData.order_date,
          admin: basicInfoData.admin_code,
          account: basicInfoData.account_code,
          dealType: basicInfoData.deal_type,
          paymentTerms: basicInfoData.payment_terms,
          warehouse: basicInfoData.warehouse_code,
          expirationDate: expiration_date,
        });
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };
    getFormValue();
  }, [basicInfoData, dispatch]);

  useEffect(() => {
    const getEstimateDatas = async () => {
      try {
        await setBasicInfoLoading(true);
        await setProductInfoLoading(true);
        const resOfbasicInfo = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'order',
            eqKey: 'id',
            eqValue: id,
          })
        )
          .unwrap()
          .then((res) => res[0]);
        await setBasicInfoData(resOfbasicInfo);
        const resOfproductInfos = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'order_product',
            eqKey: 'order_id',
            eqValue: id,
          })
        )
          .unwrap()
          .then((res) => res);
        await setProductInfoDatas(resOfproductInfos);
        const resOfSaleInfos = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'order_sale',
            eqKey: 'order_id',
            eqValue: id,
          })
        )
          .unwrap()
          .then((res) => res);
        await setSaleInfoDatas(resOfSaleInfos);
        await setBasicInfoLoading(false);
        await setProductInfoLoading(false);
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };
    getEstimateDatas();
  }, [dispatch, id]);

  const deleteEstimate = async () => {
    try {
      // 주문서 (order) 삭제 함수
      await dispatch(asyncUpdateDataFetch({ table: 'order', id, state: 9 }))
        .unwrap()
        .then(() => {
          // 성공 모달 띄우기
          openModal({
            type: 'result',
            modalInfo: {
              modalTitle: '견적서 삭제',
              resultStatus: 'success',
              resultTitle: '견적서를 삭제했습니다.',
              resultSubTitle: '견적서를 데이터에서 삭제했습니다.',
              buttonText: '확인',
            },
          });
          // 모달 닫기
          setIsOpenEstimateModal(false);
        });
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const updateEstimate = async () => {
    try {
      // 주문서 (order) 수정 함수
      await dispatch(
        asyncUpdateDataFetch({
          table: 'order',
          id,
          state: 1,
          account_code: formValue.account,
          admin_code: formValue.admin,
          order_date: formValue.date,
          deal_type: formValue.dealType,
          payment_terms: formValue.paymentTerms,
          warehouse_code: formValue.warehouse,
        })
      )
        .unwrap()
        .then(() => {
          // 성공 모달 띄우기
          openModal({
            type: 'result',
            modalInfo: {
              modalTitle: '견적서 수정',
              resultStatus: 'success',
              resultTitle: '견적서를 수정했습니다.',
              resultSubTitle: '견적서 데이터를 수정했습니다.',
              buttonText: '확인',
            },
          });
          // 모달 닫기
          setIsOpenEstimateModal(false);
        });
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return (
    <Modal
      open
      centered
      width={800}
      onCancel={() => setIsOpenEstimateModal(false)}
      title="견적서 입력"
      footer={
        <div>
          <Popconfirm
            onConfirm={() => deleteEstimate()}
            okText="네"
            cancelText="취소"
            title="정말 견적서를 삭제하시겠습니까?"
          >
            <Button danger type="primary">
              <DeleteOutlined />
              삭제
            </Button>
          </Popconfirm>
          <Button type="primary" onClick={() => updateEstimate()}>
            <SaveOutlined />
            저장
          </Button>
        </div>
      }
    >
      <div style={{ padding: '10px 0' }}>
        <EstimateBasicInfo
          basicInfoData={basicInfoData}
          basicInfoLoading={basicInfoLoading}
          formValue={formValue}
          setFormValue={setFormValue}
        />
        <EstimateProductInfo
          productInfoDatas={productInfoDatas}
          saleInfoDatas={saleInfoDatas}
          productInfoLoading={productInfoLoading}
          basicInfoData={basicInfoData}
        />
      </div>
    </Modal>
  );
};

export default EstimateModal;

EstimateModal.propTypes = {
  id: PropTypes.string.isRequired,
  setIsOpenEstimateModal: PropTypes.func.isRequired,
};
