import React, { useMemo } from 'react';
import { EditOutlined, GiftOutlined } from '@ant-design/icons';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import SaleFormItems from '../components/SaleFormItems';

const UpdateSale = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <GiftOutlined />,
        breadCrumbNav: ' 판매상품',
      },
      {
        breadCrumbIcon: <EditOutlined />,
        breadCrumbNav: ' 수정',
      },
    ],
    []
  );
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px' }}>
        <SaleFormItems pageType="update" />
      </div>
    </LayoutComponent>
  );
};

export default UpdateSale;
