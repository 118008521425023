import React, { useCallback, useState } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { asyncReadRangeDataFetch } from '../../redux/modules/shared/readRangeData';
import DirectionsTable from './DirectionsTable';
import Search from './Search';
import filterBySpecificInputs from '../../utils/filterBySpecificInputs';
import shortenSpecificWord from '../../utils/shortenSpecificWord';
import getDateByBtn from '../../utils/getDateByBtn';
import ExcelDirDownloader from './ExcelDirDownloader';
import AlertShortenedWhName from '../../shared/components/AlertShortenedWhName';
import {
  divideDirBySt,
  divideDirByLocation,
  divideDirName,
} from '../../utils/divideDirDatas';
import DividedDirModal from './DividedDirModal';

const Datas = () => {
  const { filterByWarehouse, filterByDlvyType, filterByExchangeOrReturn } =
    filterBySpecificInputs();
  const { shortenWarehouseName } = shortenSpecificWord();
  const dispatch = useDispatch();
  const [searchOpts, setSearchOpts] = useState({
    sortation: 'line',
    warehouse: [],
    date: null,
    delivery: 'all',
    exchangeOrReturn: 'include',
  });
  const [isHiddenOtherDlvy, setIsHiddenOtherDlvy] = useState(false);
  const [dirLoading, setDirLoading] = useState(false);
  const [dirDatas, setDirDatas] = useState([]);

  const [modalDatas, setModalDatas] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalSubTitle, setModalSubTitle] = useState(null);

  const fetchDatas = async (beginAndEndDate) => {
    // 기준일자에 맞는 데이터 페칭
    const fetchedDatasByDate = await dispatch(
      asyncReadRangeDataFetch({
        table: 'v_shipping_order',
        column: 'delivery_date',
        startPt: beginAndEndDate.begin,
        endPt: beginAndEndDate.end,
      })
    )
      .unwrap()
      .then((res) => {
        // 판매완료된 상품만 필터링
        const filteredOnlySoldDatas = res.filter(
          (data) => data.payment_status === '1'
        );
        // 판매일자 순으로 정렬
        const result = filteredOnlySoldDatas.sort(
          (a, b) => new Date(a.sales_date) - new Date(b.sales_date)
        );
        return result;
      });

    return fetchedDatasByDate;
  };

  const genDirDatas = useCallback(
    async (isDateBtn, dateBtnVal) => {
      try {
        setDirLoading(true);
        // 기준일자 받기
        const beginAndEndDate = getDateByBtn(isDateBtn, dateBtnVal, searchOpts);
        if (!beginAndEndDate) {
          setDirLoading(false);
          return;
        }
        setSearchOpts((prev) => ({ ...prev, date: beginAndEndDate }));
        const { warehouse, delivery, exchangeOrReturn } = searchOpts;
        const fetchedDatasByDate = await fetchDatas(beginAndEndDate);
        // 배송방식에 맞는 데이터 페칭
        // 전부 => 'all', 기본배송 => 'basic', 기타배송 => 'other'
        const filteredByDlvyType = filterByDlvyType(
          delivery,
          fetchedDatasByDate
        );
        // 창고로 필터링
        const filteredByWarehouse = filterByWarehouse(
          warehouse,
          filteredByDlvyType
        );
        // 교환 / 반품 제외
        const filteredByExchangeOrReturn = filterByExchangeOrReturn(
          exchangeOrReturn,
          filteredByWarehouse
        );
        const shortenedWithWhName = shortenWarehouseName(
          filteredByExchangeOrReturn
        );
        // 택배비 거르기
        const resultDatas = shortenedWithWhName.filter(
          (data) => !data.product_name.includes('택배비')
        );
        setDirDatas(
          resultDatas.map((data, index) => ({
            order_number: `${data.delivery_date}/${index + 1}`,
            ...data,
          }))
        );
        setDirLoading(false);
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    },
    [
      searchOpts,
      dispatch,
      filterByWarehouse,
      shortenWarehouseName,
      filterByDlvyType,
    ]
  );

  const genDividedDirDatas = useCallback(
    async (st, location) => {
      try {
        // 기준일자에 맞는 데이터 페칭
        const today = dayjs().format('YYYY-MM-DD');
        const isMonday = dayjs(today).day() === 1;
        const prevDay = isMonday
          ? dayjs(today).subtract(3, 'day').format('YYYY-MM-DD')
          : dayjs(today).subtract(1, 'day').format('YYYY-MM-DD');
        const fetchedDatasByDate = await fetchDatas({
          begin: prevDay,
          end: today,
        });
        // 차수 시간별 필터링
        const filteredByStTime = fetchedDatasByDate.filter((data) => {
          const { time, standard } = divideDirBySt(st, data.sales_date);
          setModalSubTitle(standard);
          return time;
        });
        // 배송방식에 맞는 데이터 페칭
        // 전부 => 'all', 기본배송 => 'basic', 기타배송 => 'other'
        const filteredByDlvyType = filterByDlvyType('all', filteredByStTime);
        // 창고로 필터링
        const filteredByWarehouse = filterByWarehouse(
          divideDirByLocation(location),
          filteredByDlvyType
        );
        // 택배비 거르기
        const resultDatas = filteredByWarehouse.filter(
          (data) => !data.product_name.includes('택배비')
        );
        setModalDatas(
          resultDatas.map((data, index) => ({
            order_number: `${data.delivery_date}/${index + 1}`,
            ...data,
          }))
        );
        setOpenModal(true);
        setModalTitle(divideDirName(st));
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    },
    [
      searchOpts,
      dispatch,
      filterByWarehouse,
      shortenWarehouseName,
      filterByDlvyType,
    ]
  );

  return (
    <>
      <div
        style={{
          fontSize: '16px',
          width: '100%',
          paddingBottom: '10px',
          borderBottom: '1px solid gray',
        }}
      >
        출하지시서 검색
      </div>
      <Search
        setSearchOpts={setSearchOpts}
        searchOpts={searchOpts}
        genDirDatas={genDirDatas}
        setIsHiddenOtherDlvy={setIsHiddenOtherDlvy}
      />
      <div
        style={{
          backgroundColor: '#fafafafa',
          padding: '15px',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px',
          borderRadius: '20px',
          border: '1px solid #efefef',
        }}
      >
        <div style={{ display: 'flex' }}>
          <ButtonStyle onClick={() => genDirDatas(false, '')} type="primary">
            검색
          </ButtonStyle>
          <ButtonStyle onClick={() => genDirDatas(true, 'today')}>
            금일
          </ButtonStyle>
          <ButtonStyle onClick={() => genDirDatas(true, 'yesterday')}>
            전일
          </ButtonStyle>
          <ButtonStyle onClick={() => genDirDatas(true, 'thisWeek')}>
            금주(~오늘)
          </ButtonStyle>
          <ButtonStyle onClick={() => genDirDatas(true, 'lastWeek')}>
            전주
          </ButtonStyle>
          <ButtonStyle onClick={() => genDirDatas(true, 'thisMonth')}>
            금월(~오늘)
          </ButtonStyle>
          <ButtonStyle onClick={() => genDirDatas(true, 'lastMonth')}>
            전월
          </ButtonStyle>
        </div>
        <div>
          <ExcelDirDownloader dirDatas={dirDatas} date={searchOpts.date} />
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#fafafafa',
          padding: '15px',
          display: 'flex',
          marginTop: '10px',
          borderRadius: '20px',
          border: '1px solid #efefef',
        }}
      >
        <ButtonStyle onClick={() => genDividedDirDatas('1', '1')}>
          고려 1차
        </ButtonStyle>
        <ButtonStyle onClick={() => genDividedDirDatas('2', '1')}>
          고려 2차
        </ButtonStyle>
        <ButtonStyle onClick={() => genDividedDirDatas('3', '2')}>
          준테크 1차
        </ButtonStyle>
        <ButtonStyle onClick={() => genDividedDirDatas('4', '2')}>
          준테크 2차
        </ButtonStyle>
      </div>
      <div
        style={{
          fontSize: '16px',
          width: '100%',
          paddingBottom: '10px',
          borderBottom: '1px solid gray',
          marginTop: '50px',
        }}
      >
        출하지시서 현황
      </div>
      <DirectionsTable dirLoading={dirLoading} dirDatas={dirDatas} />
      <AlertShortenedWhName />
      {openModal && (
        <DividedDirModal
          modalDatas={modalDatas}
          setOpenModal={setOpenModal}
          modalTitle={modalTitle}
          modalSubTitle={modalSubTitle}
        />
      )}
    </>
  );
};

export default React.memo(Datas);

const ButtonStyle = styled(Button)`
  margin-right: 5px;
  border-radius: 20px;
`;
