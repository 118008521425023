import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SaleProductDiscount from './SaleProductDiscount';
import SaleProductResult from './SaleProductResult';

const SaleProductInfo = (props) => {
  const { saleProductTableRows, setSaleProductTableRows, pageType } = props;

  return (
    <div
      style={{
        display: 'flex',
        marginTop: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <SaleProductDiscount
        saleProductTableRows={saleProductTableRows}
        setSaleProductTableRows={setSaleProductTableRows}
        pageType={pageType}
      />
      <SaleProductResult saleProductTableRows={saleProductTableRows} />
    </div>
  );
};

export default SaleProductInfo;

SaleProductInfo.propTypes = {
  saleProductTableRows: PropTypes.instanceOf(Array).isRequired,
  setSaleProductTableRows: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
};
