import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  LIGHT_MODE_FONT_COLOR,
} from '../../constants';

const Center = (props) => {
  const { children } = props;
  const { darkMode } = useSelector((state) => state.darkMode);

  const colorStyles = {
    backgroundColor: darkMode ? DARK_MODE_BACKGROUND : 'lightgray',
    color: darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR,
  };

  return <Container style={colorStyles}>{children}</Container>;
};

export default Center;

Center.propTypes = {
  children: PropTypes.node.isRequired,
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
