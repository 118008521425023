import {
  Button,
  Checkbox,
  ConfigProvider,
  DatePicker,
  Descriptions,
  Radio,
  Select,
  Slider,
  Switch,
  Tag,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// datepicker 한글화
import 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ko';
import locale from 'antd/lib/locale/ko_KR';

import { CloseCircleFilled } from '@ant-design/icons';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const Search = (props) => {
  const { searchOpts, setSearchOpts } = props;
  const dispatch = useDispatch();
  const [whOptions, setWhOptions] = useState([]);

  useEffect(() => {
    // 출하창고 검색옵션
    dispatch(asyncReadDataFetch({ table: 'warehouse' }))
      .unwrap()
      .then((res) => {
        const whArr = res.map((data) => ({
          value: data.code,
          label: `${data.code} / ${data.name}`,
        }));
        setWhOptions([...whArr]);
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, [dispatch]);

  const addSelectedWh = async (value) => {
    // 중복체크
    let isDup = false;
    for (const warehouse of searchOpts.warehouse) {
      if (warehouse.value === value) {
        isDup = true;
      }
    }
    if (isDup) {
      alert('이미 선택된 창고입니다.');
      return;
    }
    const selectedWh = await dispatch(
      asyncReadFilteredDataFetch({
        table: 'warehouse',
        eqKey: 'code',
        eqValue: value,
      })
    )
      .unwrap()
      .then((res) => ({ label: res[0].name, value: res[0].code }));
    setSearchOpts((prev) => ({
      ...prev,
      warehouse: [...prev.warehouse, selectedWh],
    }));
  };

  const deleteSelectedWh = async (value) => {
    setSearchOpts((prev) => ({
      ...prev,
      warehouse: prev.warehouse.filter((data) => data.value !== value),
    }));
  };

  return (
    <div style={{ position: 'relative' }}>
      <Descriptions
        bordered
        labelStyle={{ width: '150px', textAlign: 'center' }}
        column={1}
        style={{ borderBottom: '1px solid #efefef' }}
      >
        <Descriptions.Item
          label={
            <div>
              <span style={{ color: 'red' }}>*</span>구분
            </div>
          }
        >
          <div className="select-container">
            <Select
              onChange={(e) => {
                setSearchOpts((prev) => ({ ...prev, sortation: e }));
              }}
              defaultValue="line"
              style={{
                width: '100%',
              }}
              options={[
                {
                  value: 'line',
                  label: '라인별',
                },
              ]}
            />
          </div>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <div>
              <span style={{ color: 'red' }}>*</span>기준일자
            </div>
          }
        >
          <ConfigProvider locale={locale}>
            <DatePicker.RangePicker
              style={{ borderRadius: '0px', width: '100%' }}
              onChange={(e) => {
                const formattedDate = e.map((date) =>
                  date.format('YYYY-MM-DD')
                );
                const [begin, end] = [formattedDate[0], formattedDate[1]];
                setSearchOpts((prev) => ({ ...prev, date: { begin, end } }));
              }}
            />
          </ConfigProvider>
        </Descriptions.Item>
        <Descriptions.Item label={<div>창고</div>}>
          <div style={{ display: 'flex' }}>
            {searchOpts.warehouse.map((item) => (
              <Tag
                color="blue"
                style={{
                  marginBottom: '5px',
                  padding: '2px 2px 2px 10px',
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '20px',
                }}
              >
                <p>{item.label}</p>
                <Button
                  type="link"
                  size="small"
                  onClick={() => deleteSelectedWh(item.value)}
                >
                  <CloseCircleFilled />
                </Button>
              </Tag>
            ))}
          </div>
          <div className="select-container">
            <Select
              options={whOptions}
              style={{ width: '100%' }}
              placeholder="창고"
              onSelect={(value) => addSelectedWh(value)}
              value={null}
            />
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="배송">
          <Radio.Group
            onChange={(e) =>
              setSearchOpts((prev) => ({ ...prev, delivery: e.target.value }))
            }
            value={searchOpts.delivery}
          >
            <Radio value="all">전부</Radio>
            <Radio value="basic">기본배송</Radio>
            <Radio value="other">기타배송</Radio>
          </Radio.Group>
        </Descriptions.Item>
        <Descriptions.Item label="교환/반품건">
          <Radio.Group
            onChange={(e) =>
              setSearchOpts((prev) => ({
                ...prev,
                exchangeOrReturn: e.target.value,
              }))
            }
            value={searchOpts.exchangeOrReturn}
          >
            <Radio value="include">포함</Radio>
            <Radio value="exclude">제외</Radio>
          </Radio.Group>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

Search.propTypes = {
  searchOpts: PropTypes.instanceOf(Object).isRequired,
  setSearchOpts: PropTypes.func.isRequired,
};

export default Search;
