import React from 'react';
import PropTypes from 'prop-types';

const ErrorText = ({ text }) => {
  return (
    <div style={{ marginTop: '5px', marginLeft: '5px', color: 'red' }}>
      {text}
    </div>
  );
};

export default ErrorText;

ErrorText.propTypes = {
  text: PropTypes.string.isRequired,
};
