import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

const RadioFormField = ({ name, value, onChange }) => {
  let data = [];

  switch (name) {
    case 'type':
      data = [
        { value: 'LMS', label: 'LMS' },
        { value: 'SMS', label: 'SMS' },
        { value: 'MMS', label: 'MMS' },
      ];
      break;
    case 'contentType':
      data = [
        { value: 'COMM', label: '일반' },
        { value: 'AD', label: '광고' },
      ];
      break;
    default:
      break;
  }

  return (
    <Radio.Group value={value} onChange={(e) => onChange(name, e.target.value)}>
      {data.map((item, index) => (
        <Radio key={index} value={item.value}>
          {item.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default RadioFormField;

RadioFormField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
