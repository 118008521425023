import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

const SearchInputFormField = ({ name, value, onChangeValue }) => {
  let placeholder;

  if (name === 'to') {
    placeholder = '수신번호를 입력해주세요.';
  }
  if (name === 'from') {
    placeholder = '발신번호를 입력해주세요.';
  }

  return (
    <Input
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChangeValue(name, e.target.value)}
    />
  );
};

export default SearchInputFormField;

SearchInputFormField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string || PropTypes.null,
  onChangeValue: PropTypes.func.isRequired,
};
