import dayjs from 'dayjs';

// 일자 범위
const getDateRange = (startDate, endDate) => {
  const dateRange = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dateRange.push(currentDate);
    currentDate = dayjs(currentDate).add(1, 'day').format('YYYY-MM-DD');
  }
  return dateRange;
};
// 시간 범위
const getHourRange = () => {
  const hourRange = [];
  let currentHour = 8;
  while (currentHour <= 17) {
    hourRange.push(currentHour);
    currentHour += 1;
  }
  const stringHourRange = hourRange.map((item) =>
    `${item}`.length === 1 ? `0${item}` : `${item}`
  );
  return [...stringHourRange];
};

// 일자별로 그룹핑 (delivery_date 사용)
const getGroupedDatasByDeliveryDate = (dateRange, salesDatas) => {
  const groupedSales = salesDatas.reduce((acc, salesData) => {
    const deliveryDate = salesData.delivery_date;
    if (dateRange.includes(deliveryDate)) {
      if (!acc[deliveryDate]) {
        acc[deliveryDate] = [];
      }
      acc[deliveryDate].push(salesData);
    }
    return acc;
  }, {});
  const result = dateRange.map((date) => ({
    date,
    data: groupedSales[date] || [],
  }));
  return result;
};
// 시간별로 그룹핑 (sale_date 사용)
const getGroupedDatasBySalesDate = (hourRange, salesDatas) => {
  const groupedSales = salesDatas.reduce((acc, salesData) => {
    const salesDate = salesData.sales_date;
    const salesHour = salesDate && salesDate.slice(11, 13);
    if (hourRange.includes(salesHour)) {
      if (!acc[salesHour]) {
        acc[salesHour] = [];
      }
      acc[salesHour].push(salesData);
    }
    return acc;
  }, {});
  const result = hourRange.map((hour) => ({
    hour,
    data: groupedSales[hour] || [],
  }));
  return result;
};
// 전일, 익일 나누기
const getExtractedDatasBySelectedDate = (groupedDatas, selectedDate) => {
  const edgeArr = [[], []];
  const extractedDatas = groupedDatas.map((groupedData) => {
    const filteredData = groupedData.data.filter((groupedItem) => {
      const currentDate =
        groupedItem.sales_date && groupedItem.sales_date.slice(0, 10);
      if (currentDate < selectedDate) {
        // 전일
        edgeArr[0].push(groupedItem);
        return false;
      } else if (currentDate > selectedDate) {
        // 익일
        edgeArr[1].push(groupedItem);
        return false;
      } else {
        return true;
      }
    });
    return { ...groupedData, data: filteredData };
  });
  const result = [
    { hour: '전일', data: edgeArr[0] },
    ...extractedDatas,
    { hour: '익일', data: edgeArr[1] },
  ];
  return result;
};

// 일별로 매출 합치기
const getCombinedDatasByDate = (groupedDatas) => {
  const combinedDatas = groupedDatas.map((groupedData) => {
    const { date, data } = groupedData;
    let sumPrice = 0;
    data.forEach((item) => {
      sumPrice += item.price_sum;
    });
    return { date, sales: sumPrice };
  });
  return combinedDatas;
};
// 시간별로 매출 합치기
const getCombinedDatasByHour = (groupedDatas) => {
  const combinedDatas = groupedDatas.map((groupedData) => {
    const { hour, data } = groupedData;
    let sumPrice = 0;
    data.forEach((item) => {
      sumPrice += item.price_sum;
    });
    return { hour, sales: sumPrice };
  });
  return combinedDatas;
};

// 해당월 일별 매출 차트
export const getTheMonthDailyChartData = (salesDatas, startDate, endDate) => {
  const dateRange = getDateRange(startDate, endDate);
  const groupedDatas = getGroupedDatasByDeliveryDate(dateRange, salesDatas);
  const combinedDatas = getCombinedDatasByDate(groupedDatas);
  const result = combinedDatas;
  return result;
};
// 해당일 시간별 매출 차트
export const getTheDayHourlyChartData = (salesDatas, selectedDate) => {
  const hourRange = getHourRange();
  const groupedDatas = getGroupedDatasBySalesDate(hourRange, salesDatas);
  const extractedDatas = getExtractedDatasBySelectedDate(
    groupedDatas,
    selectedDate
  );
  const combinedDatas = getCombinedDatasByHour(extractedDatas);
  const result = combinedDatas;
  return result;
};
// 최근30일 일별 매출 차트
export const getThirtyDaysDailyChartData = (salesDatas, startDate, endDate) => {
  const dateRange = getDateRange(startDate, endDate);
  const groupedDatas = getGroupedDatasByDeliveryDate(dateRange, salesDatas);
  const combinedDatas = getCombinedDatasByDate(groupedDatas);
  const result = combinedDatas;
  return result;
};
// 최근30일 시간별 매출 차트
export const getThirtyDaysHourlyChartData = (salesDatas) => {
  const hourRange = getHourRange();
  const groupedDatas = getGroupedDatasBySalesDate(hourRange, salesDatas);
  const combinedDatas = getCombinedDatasByHour(groupedDatas);
  const result = combinedDatas;
  return result;
};
// 120일 일별 매출 차트
export const getHundredTwentyDaysDailyChartData = (
  salesDatas,
  startDate,
  endDate
) => {
  const dateRange = getDateRange(startDate, endDate);
  const groupedDatas = getGroupedDatasByDeliveryDate(dateRange, salesDatas);
  const combinedDatas = getCombinedDatasByDate(groupedDatas);
  const result = combinedDatas;
  return result;
};
