import React, { useState, useEffect } from 'react';
import { Button, Radio } from 'antd';
import PropTypes from 'prop-types';
import AuthLinks from './AuthLinks';

const radioContainerStyle = {
  display: 'flex',
  gap: '10px',
  marginTop: '10px',
  alignItems: 'center',
  justifyContent: 'center',
};

const EditUserAccessForm = (props) => {
  const { handleEditUserAccess, handleDeleteUser, userAccess } = props;

  const [values, setValues] = useState({
    permission: null,
    tier: null,
  });

  useEffect(() => {
    setValues(userAccess);
  }, [userAccess]);

  const handleChangeValue = (name, value) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickSubmit = (e) => {
    e.preventDefault();

    handleEditUserAccess(values);
  };

  const handleClickButton = () => {
    handleDeleteUser();
  };

  return (
    <form onSubmit={handleClickSubmit} style={{ width: '100%' }}>
      <div style={radioContainerStyle}>
        <p>OMS 시스템 접근: </p>
        <Radio.Group
          value={values.permission}
          onChange={(e) => handleChangeValue('permission', e.target.value)}
        >
          <Radio value={1}>허가</Radio>
          <Radio value={0}>미허가</Radio>
        </Radio.Group>
      </div>
      <div style={radioContainerStyle}>
        <p>유저 등급: </p>
        <Radio.Group
          value={values.tier}
          onChange={(e) => handleChangeValue('tier', e.target.value)}
        >
          <Radio value={1}>1등급</Radio>
          <Radio value={2}>2등급</Radio>
          <Radio value={3}>3등급</Radio>
        </Radio.Group>
      </div>
      <Button
        htmlType="submit"
        size="large"
        style={{ marginTop: '30px', width: '100%' }}
        type="primary"
      >
        저장
      </Button>
      <Button
        onClick={handleClickButton}
        size="large"
        style={{ marginTop: '30px', width: '100%' }}
        type="primary"
        danger
      >
        계정 삭제
      </Button>
      <AuthLinks />
    </form>
  );
};

export default EditUserAccessForm;

EditUserAccessForm.propTypes = {
  handleEditUserAccess: PropTypes.func.isRequired,
  handleDeleteUser: PropTypes.func.isRequired,
  userAccess: PropTypes.instanceOf(Object).isRequired,
};
