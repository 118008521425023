import React, { useState, useEffect } from 'react';
import { Button, Input } from 'antd';
import { useSelector } from 'react-redux';
import { CheckSquareOutlined } from '@ant-design/icons';
import { BsSquare } from 'react-icons/bs';
import PropTypes from 'prop-types';

import AuthLinks from './AuthLinks';

const LoginForm = (props) => {
  const { handleLogin } = props;
  const { isLoadingSigninUser } = useSelector((state) => state.signinUser);

  const [values, setValues] = useState({
    email: null,
    password: null,
  });
  const [check, setCheck] = useState(false);

  useEffect(() => {
    const storedCheck = JSON.parse(localStorage.getItem('remember-email'));
    setCheck(!!storedCheck);
    if (storedCheck) {
      const storedEmail = JSON.parse(localStorage.getItem('email'));
      setValues((prev) => ({ ...prev, email: storedEmail }));
    }
  }, []);

  const handleClickCheck = () => {
    setCheck((prev) => {
      const newValue = !prev;
      if (newValue) {
        localStorage.setItem('remember-email', JSON.stringify('true'));
      } else {
        localStorage.removeItem('remember-email');
      }
      return newValue;
    });
  };

  const handleChangeValue = (name, value) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickSubmit = (e) => {
    e.preventDefault();

    if (check) {
      localStorage.setItem('email', JSON.stringify(values.email));
    } else {
      localStorage.removeItem('email');
    }

    handleLogin(values);
  };

  return (
    <form onSubmit={handleClickSubmit}>
      <Input
        onChange={(e) => handleChangeValue('email', e.target.value)}
        value={values.email}
        size="large"
        placeholder="email"
        type="email"
      />
      <Input.Password
        onChange={(e) => handleChangeValue('password', e.target.value)}
        value={values.password}
        size="large"
        placeholder="password"
        style={{ marginTop: '10px' }}
        minLength={4}
        maxLength={12}
      />
      <p
        onClick={handleClickCheck}
        style={{
          marginTop: '30px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        {check ? <CheckSquareOutlined /> : <BsSquare />} 이메일 저장
      </p>
      <Button
        htmlType="submit"
        loading={isLoadingSigninUser}
        style={{ marginTop: '30px', width: '100%' }}
        size="large"
        type="primary"
        disabled={!values.email || !values.password}
      >
        로그인
      </Button>
      <AuthLinks />
    </form>
  );
};

export default LoginForm;

LoginForm.propTypes = {
  handleLogin: PropTypes.func.isRequired,
};
