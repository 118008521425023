import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

const SubmitButton = ({ loadingInfo }) => {
  return (
    <div>
      <button type="submit" disabled={loadingInfo.loading}>
        <CheckOutlined />
        발송
      </button>
      {loadingInfo.text?.length && (
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            color: 'gray',
            fontSize: '12px',
          }}
        >
          <Spin size="small" />
          <span>{loadingInfo.text}</span>
        </div>
      )}
    </div>
  );
};

export default SubmitButton;

SubmitButton.propTypes = {
  loadingInfo: PropTypes.instanceOf(Object).isRequired,
};
