import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Button, Empty, Popover } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const ChangedStockValue = (props) => {
  const { changedStockByAdd, changedStockByAbstract, stackQuantity } = props;
  const [isClicked, setIsClicked] = useState(false);
  const [changedStock, setChangedStock] = useState({
    add: { total: 0, datas: [] },
    abstract: { total: 0, datas: [] },
  });

  useEffect(() => {
    const formatRegDate = (regDate) => {
      const timestamp = dayjs(regDate).format('YYYY-MM-DD HH:mm:ss');
      return timestamp;
    };
    const formatToAbs = (number) => {
      return Math.abs(number);
    };
    const formatChangedStockDatas = (changedStockDatas) => {
      const formattedChangedStockDatas = changedStockDatas.map(
        (changedStockData) => {
          const timestamp = formatRegDate(changedStockData.reg_date);
          const changedQuantity = formatToAbs(changedStockData.change_quantity);
          return { changedQuantity, timestamp };
        }
      );
      return formattedChangedStockDatas;
    };
    setChangedStock({
      add: {
        total: formatToAbs(changedStockByAdd.total),
        datas: formatChangedStockDatas(changedStockByAdd.datas),
      },
      abstract: {
        total: formatToAbs(changedStockByAbstract.total),
        datas: formatChangedStockDatas(changedStockByAbstract.datas),
      },
    });
  }, [changedStockByAdd, changedStockByAbstract]);

  const popoverContent = (changedStockDatas) => {
    const isEmptyDatas = changedStockDatas.length <= 0;
    if (isEmptyDatas) {
      return (
        <Empty
          description="데이터가 존재하지 않습니다."
          style={{ color: 'gray', fontSize: '11px' }}
        />
      );
    }
    return (
      <div style={{ width: '200px', maxHeight: '300px', overflow: 'auto' }}>
        {changedStockDatas.map((item) => (
          <div
            style={{
              border: '1px solid lightgray',
              borderRadius: '10px',
              padding: '5px 10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '5px 0',
            }}
          >
            <div>{item.changedQuantity} 개</div>
            <div style={{ fontSize: '11px', color: 'gray' }}>
              {item.timestamp}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className="inventory-table-cell"
      style={{
        backgroundColor:
          (changedStock.add.datas.length > 0 ||
            changedStock.abstract.datas.length > 0) &&
          '#bae0ff',
      }}
      onClick={() => setIsClicked((prev) => !prev)}
    >
      {isClicked ? (
        <div style={{ display: 'flex', fontSize: '11px', gap: '10px' }}>
          <div>
            <div>증가</div>
            <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
              <div style={{ color: '#1677ff' }}>{changedStock.add.total}</div>
              <Popover
                content={popoverContent(changedStock.add.datas)}
                placement="bottom"
              >
                <button style={{ fontSize: '10px', cursor: 'pointer' }}>
                  <SearchOutlined />
                </button>
              </Popover>
            </div>
          </div>
          <div>
            <div>감소</div>
            <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
              <div style={{ color: '#f5222d' }}>
                {changedStock.abstract.total}
              </div>
              <Popover
                content={popoverContent(changedStock.abstract.datas)}
                placement="bottom"
              >
                <button style={{ fontSize: '10px', cursor: 'pointer' }}>
                  <SearchOutlined />
                </button>
              </Popover>
            </div>
          </div>
        </div>
      ) : (
        stackQuantity
      )}
    </div>
  );
};

export default ChangedStockValue;

ChangedStockValue.propTypes = {
  changedStockByAdd: PropTypes.instanceOf(Object).isRequired,
  changedStockByAbstract: PropTypes.instanceOf(Object).isRequired,
  stackQuantity: PropTypes.number.isRequired,
};
