import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, List, Modal, Select } from 'antd';

const ReceiverSelector = ({
  allAccounts,
  addReceiver,
  handleReceiversByAllAccounts,
  handleReceiversByGroupCode,
  groupData,
}) => {
  const [openGroupModal, setOpenGroupModal] = useState(false);

  return (
    <>
      <div
        style={{
          marginTop: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <Select
          style={{ width: '100%' }}
          placeholder="거래처 목록에서 수신번호를 선택해주세요."
          showSearch
          filterOption={(keyword, option) =>
            option.label && option.label.includes(keyword)
          }
          options={allAccounts.map((account) => ({
            key: account.id,
            label: `${account.mobile} | ${account.name}`,
            value: account.mobile,
          }))}
          onChange={(mobile) => addReceiver(mobile)}
          value={null}
        />
        <div style={{ display: 'flex', gap: '5px' }}>
          <Button onClick={handleReceiversByAllAccounts}>전체</Button>
          <Button onClick={() => setOpenGroupModal(true)}>구분</Button>
        </div>
      </div>
      {openGroupModal && (
        <Modal
          title="거래처구분"
          open
          closable={false}
          footer={[
            <Button onClick={() => setOpenGroupModal(false)}>취소</Button>,
          ]}
        >
          <List
            style={{ padding: '30px 0' }}
            dataSource={groupData}
            renderItem={(item) => (
              <List.Item>
                <Button
                  size="small"
                  type="link"
                  onClick={() => {
                    setOpenGroupModal(false);
                    handleReceiversByGroupCode(item.code);
                  }}
                >
                  {item.name}
                </Button>
              </List.Item>
            )}
          />
        </Modal>
      )}
    </>
  );
};

export default ReceiverSelector;

ReceiverSelector.propTypes = {
  allAccounts: PropTypes.instanceOf(Array).isRequired,
  groupData: PropTypes.instanceOf(Array).isRequired,
  addReceiver: PropTypes.func.isRequired,
  handleReceiversByAllAccounts: PropTypes.func.isRequired,
  handleReceiversByGroupCode: PropTypes.func.isRequired,
};
