import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const SaleProductResult = (props) => {
  const { saleProductTableRows } = props;
  const dispatch = useDispatch();
  const [results, setResults] = useState({
    supply: 0,
    vat: 0,
    delivery: 0,
    total: 0,
    discount: 0,
    result: 0,
  });

  // 가격정보 출력
  useEffect(() => {
    const asyncFunc = async () => {
      try {
        let price = 0;
        let supply = 0;
        let vat = 0;
        let delivery = 0;
        let discount = 0;
        for (const row of saleProductTableRows) {
          const { product_quantity, product_code, discount_rate } = row;
          const data = await dispatch(
            asyncReadFilteredDataFetch({
              table: 'product',
              eqKey: 'code',
              eqValue: product_code,
            })
          )
            .unwrap()
            .then((res) => res[0]);
          // 택배비 계산
          if (product_code.includes('parcel')) {
            delivery += data.release_price * product_quantity;
            // 단가, 공급가액, 부가세 계산
          } else {
            const newPrice = data.release_price / 1.1;
            const newSupply = newPrice * product_quantity;
            price += newPrice;
            supply += newSupply;
            vat += newSupply / 10;
          }
          // 할인 계산
          if (discount_rate && discount_rate > 0) {
            const original = data.release_price * product_quantity;
            const newdiscount = (original * discount_rate) / 100;
            discount += newdiscount;
          }
        }

        const total = supply + vat + delivery;
        const result = total - discount;

        setResults({
          supply: Math.round(supply),
          vat: Math.round(vat),
          delivery: Math.round(delivery),
          total: Math.round(total),
          discount: Math.round(discount),
          result: Math.round(result),
        });
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    };
    asyncFunc();
  }, [saleProductTableRows, dispatch]);

  return (
    <Container>
      <div style={{ fontSize: '15px', marginBottom: '30px' }}>가격정보</div>
      <FlexC>
        <div>공급가액</div>
        <div>{results.supply.toLocaleString()} 원</div>
      </FlexC>
      <FlexC>
        <div>부가세</div>
        <div>{results.vat.toLocaleString()} 원</div>
      </FlexC>
      <FlexC>
        <div>택배비</div>
        <div>{results.delivery.toLocaleString()} 원</div>
      </FlexC>
      <FlexC>
        <div>합계</div>
        <div>{results.total.toLocaleString()} 원</div>
      </FlexC>
      <FlexC style={{ color: 'gray' }}>
        <div>할인금액</div>
        <div>-{results.discount.toLocaleString()} 원</div>
      </FlexC>
      <DivLine />
      <FlexC style={{ fontWeight: 'bold', marginTop: '70px' }}>
        <div>총액</div>
        <div>{(results.total - results.discount).toLocaleString()} 원</div>
      </FlexC>
    </Container>
  );
};

export default SaleProductResult;

SaleProductResult.propTypes = {
  saleProductTableRows: PropTypes.instanceOf(Array).isRequired,
};

const Container = styled.div`
  width: 400px;
  min-height: 300px;
  padding: 15px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #efefef;
  position: relative;
  margin-top: 20px;
`;
const FlexC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`;
const DivLine = styled.div`
  position: absolute;
  border: 0.5px solid #efefef;
  width: 90%;
  margin: 10px 0;
  bottom: 65px;
  left: 15px;
`;
