import React from 'react';
import { Checkbox, ConfigProvider, DatePicker, TimePicker } from 'antd';
import PropTypes from 'prop-types';

// datepicker 한글화
import 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ko';
import locale from 'antd/lib/locale/ko_KR';

import ErrorText from './ErrorText';

const SendTypeField = ({ values, errors, blur, onChange, onBlur }) => {
  const { isReserveValue, sendDateValue, sendTimeValue, contentTypeValue } =
    values;
  const { sendDateError, sendTimeError } = errors;

  return (
    <div style={{ width: '100%' }}>
      <div>
        {contentTypeValue === 'AD' ? (
          <Checkbox disabled checked={false} />
        ) : (
          <Checkbox
            onBlur={() => onBlur('isReserve')}
            onChange={(e) => onChange('isReserve', e.target.checked)}
          />
        )}
        <span style={{ marginLeft: '10px' }}>예약발송</span>
      </div>
      {isReserveValue && (
        <>
          <div
            style={{
              marginTop: '10px',
              display: 'flex',
              width: '100%',
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                flex: 1,
                border: '1px solid lightgray',
                borderRadius: '5px',
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '10px',
                color: 'gray',
                height: '30px',
              }}
            >
              {!sendDateValue && !sendTimeValue
                ? '예약발송할 날짜와 시간을 선택해주세요.'
                : `${sendDateValue || ''} ${sendTimeValue || ''}`}
            </div>
            <ConfigProvider locale={locale}>
              <DatePicker
                placeholder="연-월-일"
                style={{ width: '160px' }}
                onChange={(_, date) => onChange('sendDate', date)}
              />
              <TimePicker
                format="HH:mm"
                placeholder="시:분"
                style={{ width: '160px' }}
                onChange={(_, time) => onChange('sendTime', time)}
              />
            </ConfigProvider>
          </div>
          {blur && sendDateError && (
            <ErrorText text="예약발송할 날짜를 선택해주세요." />
          )}
          {blur && sendTimeError && (
            <ErrorText text="예약발송할 시간을 선택해주세요." />
          )}
        </>
      )}
    </div>
  );
};

export default SendTypeField;

SendTypeField.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  blur: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};
