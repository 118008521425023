import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loading from '../../shared/components/Loading';
import Access from '../../access/page/Access';

// 로그인 되었을 때 접근 가능한 컴포넌트
const PrivateRoute = (props) => {
  const { component, path, exact } = props;
  const { isLoggedIn, userInfo } = useSelector((state) => state.signinUser);

  // permission이 false일 경우의 access페이지 처리
  if (userInfo.permission === false) {
    return <Access falsePermission />;
  }

  if (isLoggedIn === true) {
    return <Route component={component} path={path} exact={exact} />;
  } else if (isLoggedIn === false) {
    return <Access text="로그아웃" button="로그인" isLoggedIn={isLoggedIn} />;
  } else {
    return <Loading />;
  }
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
};
