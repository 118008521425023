import React from 'react';
import { useSelector } from 'react-redux';

import { tables } from '../../constants';
import EntireMigrator from '../components/EntireMigratior';
import OneTimeMigrator from '../components/OneTimeMigrator';
import PartMigrator from '../components/PartMigrator';

const tablesToMigrateEntireRow = [
  tables.CALENDAR_HOLIDAYS,
  tables.CALENDAR_SALES_LEVEL,
  tables.COUNSELLING,
  tables.COUNSELLING_COMMENT,
  tables.DISCOUNT_ACCOUNT_PRODUCT,
  tables.ECOUNT_SYNC_ERROR_RECORD,
  tables.ESTIMATE_SENT,
  tables.HANTONG_BATCH_DAILY_SALES_QUANTITY,
  tables.HANTONG_BOT_PING,
  tables.HIDDEN_DATA,
  tables.INVENTORY_ERROR_BY_PAYAPP,
  tables.INVENTORY_ORDER_SETTING,
  tables.INVENTORY_PRODUCT_SETUP,
  tables.INVENTORY_SAFETY_STOCK,
  tables.INVENTORY_SEARCH_DEFAULT,
  tables.MONTHLY_HEADCOUNT,
  tables.PRODUCT,
  // tables.PROFILES,
  tables.PROPOSE_DATA,
  tables.PROPOSE_REJECT,
  tables.PURCHASE,
  tables.PURCHASE_ORDER,
  tables.PURCHASE_PRODUCT,
  tables.PURCHASE_ORDER_PRODUCT,
  // tables.RESULT,
  tables.SALE,
  tables.SALE_PRODUCT,
  tables.SALES_TARGET,
  tables.SYNC_ERROR_RECORD,
  tables.WAREHOUSE,
  tables.ACCOUNT,
  tables.ACCOUNT_ADDRESS,
  tables.ACCOUNT_GROUP,
  tables.ACCOUNT_RESP,
];
const tablesToMigratePartRow = [
  tables.ORDER,
  tables.ORDER_SALE,
  tables.ORDER_PRODUCT,
  tables.INVENTORY,
  tables.SESSION_ERROR_RECORD,
];

const Migration = () => {
  const { userInfo } = useSelector((state) => state.signinUser);

  if (userInfo.tier > 1) {
    return (
      <div style={{ padding: '20px' }}>
        <h1>관리자만 접근할 수 있는 페이지입니다. (403 Forbidden)</h1>
      </div>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <h2 style={{ textAlign: 'center' }}>전체 ROW MIGRATION</h2>
      {tablesToMigrateEntireRow.map((table, index) => (
        <EntireMigrator table={table} key={index} />
      ))}
      <h2 style={{ marginTop: '50px', textAlign: 'center' }}>
        부분별 ROW MIGRATION
      </h2>
      {tablesToMigratePartRow.map((table, index) => (
        <PartMigrator table={table} key={index} />
      ))}
      <h2 style={{ marginTop: '50px', textAlign: 'center' }}>
        일괄 ROW MIGRATION
      </h2>
      <OneTimeMigrator
        entireTables={tablesToMigrateEntireRow}
        partTables={tablesToMigratePartRow}
      />
    </div>
  );
};

export default Migration;
