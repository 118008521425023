import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import useModal from '../../hook/useModal';
import AccountgroupData from './AccountgroupData';
import useSupabase from '../../serverConfig';

const AccountgroupModal = () => {
  const { closeModal } = useModal();
  const { modalInfo } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [accountGroups, setAccountGroups] = useState([]);

  useEffect(() => {
    // 상위계층 그룹 코드(upper_code)에서 select할 account_group 테이블 row 가져오기
    const values = { table: 'account_group' };
    dispatch(asyncReadDataFetch(values)).then((res) => {
      // 상위계층 그룹 코드 column이 없는 account_group 테이블 row만 필터링하여 가져오기
      const filteredAccountGroups = res.payload.filter((accountGroup) => {
        return accountGroup.upper_code === null;
      });
      setAccountGroups([...filteredAccountGroups]);
    });
  }, [dispatch]);

  // form에 들어갈 각 item 항목들을 배열로 만듬.
  // TableFormModal에서 form형식으로 매핑됨.
  const items = [
    {
      type: 'input',
      name: 'code',
      label: '그룹 코드 (그룹코드는 자동으로 생성됩니다.)',
      message: '그룹 코드를 입력해주세요.',
      disable: true,
    },
    {
      type: 'input',
      label: '그룹 명',
      name: 'name',
      message: '그룹 명을 입력해주세요.',
    },
    {
      type: 'input',
      name: 'category',
      label: '그룹 카테고리',
      message: '그룹 카테고리를 입력해주세요.',
    },
    {
      type: 'radio',
      name: 'is_hidden',
      label: '거래처 숨김',
      options: [
        { label: 'ON', value: useSupabase ? true : 1 },
        { label: 'OFF', value: useSupabase ? false : 0 },
      ],
    },
    {
      type: 'select',
      name: 'upper_code',
      label: '상위계층 그룹 코드',
      message: '상위계층 그룹 코드가 있으면 선택해주세요.',
      options: [
        ...accountGroups.map((accountGroup) => {
          return {
            label: `${accountGroup.code} (${accountGroup.name})`,
            value: accountGroup.id,
          };
        }),
      ],
    },
  ];

  return (
    <Modal
      open
      onCancel={() => closeModal()}
      title={modalInfo.modalTitle}
      centered
      footer={[<div key="modal" />]}
    >
      <AccountgroupData items={items} />
    </Modal>
  );
};

export default AccountgroupModal;
