import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

const DirectionsTable = (props) => {
  const { dirLoading, dirDatas } = props;

  const columns = useMemo(
    () => [
      {
        title: '주문번호',
        dataIndex: 'order_number',
        key: 'order_number',
        align: 'center',
      },
      {
        title: '품목명',
        dataIndex: 'product_name',
        key: 'product_name',
        align: 'center',
      },
      {
        title: '주문상품수량',
        dataIndex: 'product_quantity',
        key: 'product_quantity',
        align: 'center',
      },
      {
        title: '수취인이름',
        dataIndex: 'account_name',
        key: 'account_name',
        align: 'center',
      },
      {
        title: '수취인전화',
        dataIndex: 'phone',
        key: 'phone',
        align: 'center',
      },
      {
        title: '수취인핸드폰',
        dataIndex: 'mobile',
        key: 'mobile',
        align: 'center',
      },
      {
        title: '수취인우편번호',
        dataIndex: 'post_code',
        key: 'post_code',
        align: 'center',
      },
      {
        title: '주소',
        dataIndex: 'address',
        key: 'address',
        align: 'center',
      },
      {
        title: '메모',
        dataIndex: 'memo',
        key: 'memo',
        align: 'center',
      },
    ],
    []
  );

  return (
    <div
      style={{ width: '100%', overflow: 'auto', maxHeight: '80vh' }}
      className="border-table directions"
    >
      <Table
        dataSource={dirDatas}
        columns={columns}
        pagination={false}
        loading={dirLoading}
        bordered
      />
    </div>
  );
};

export default React.memo(DirectionsTable);

DirectionsTable.propTypes = {
  dirLoading: PropTypes.bool.isRequired,
  dirDatas: PropTypes.instanceOf(Array).isRequired,
};
