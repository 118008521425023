import React, { useEffect, useState, useMemo } from 'react';
import {
  EditOutlined,
  DeleteOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { Button, Space, Tag } from 'antd';
import { useDispatch } from 'react-redux';

import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import useModal from '../../hook/useModal';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Table from '../../shared/table/Table';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const Accountgroup = () => {
  const { openModal } = useModal();
  const dispatch = useDispatch();
  const [upperGroups, setUpperGroups] = useState([]);

  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <SolutionOutlined />,
        breadCrumbNav: ' 거래처그룹',
      },
    ],
    []
  );

  // 상위계층 그룹 코드(upper_code) 데이터 불러오기
  useEffect(() => {
    const values = { table: 'account_group' };
    dispatch(asyncReadDataFetch(values)).then((res) =>
      setUpperGroups([...res.payload])
    );
  }, [dispatch]);

  // 불러올 table이 뭔지
  const table = 'account_group';

  // TableHeader에 들어갈 create 버튼 텍스트
  const createButtonText = '거래처그룹 추가';

  // table header에 들어갈 create 버튼의 Modal객체 (Modal 속성으로 넣어줄 객체)
  const createButtonModal = {
    type: 'accountgroup',
    modalInfo: {
      modalTitle: '거래처그룹 추가',
      resultStatus: '',
      resultTitle: '',
      resultSubTitle: '',
      buttonText: '',
    },
    dataInfo: {
      dataId: '',
      dataTable: 'account_group',
      datas: {},
    },
  };

  // table에 넣을 columns. table View를 위한 배열.
  const tableColumns = [
    {
      title: '그룹코드',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
    },
    {
      title: '그룹명',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '상위계층그룹코드',
      dataIndex: 'upper_code',
      key: 'upper_code',
      render: (_, record) => {
        const selectedUpperGroup = upperGroups.filter((upperGroup) => {
          return upperGroup.id === record.upper_code;
        });
        if (selectedUpperGroup.length === 0) {
          return <div>없음</div>;
        } else {
          return (
            <div>{`${selectedUpperGroup[0].code} (${selectedUpperGroup[0].name})`}</div>
          );
        }
      },
      align: 'center',
    },
    {
      title: '거래처 숨김처리',
      dataIndex: 'is_hidden',
      key: 'is_hidden',
      align: 'center',
      render: (value) => {
        if (value) {
          return <Tag color="red">ON</Tag>;
        } else {
          return <Tag color="blue">OFF</Tag>;
        }
      },
    },
    {
      title: '',
      dataIndex: 'button',
      key: 'button',
      width: '5%',
      align: 'center',
      render: (_, record) => (
        <ButtonContainer>
          <Space.Compact size="small">
            <Button
              onClick={() =>
                openModal({
                  type: 'accountgroup',
                  modalInfo: {
                    modalTitle: '거래처그룹 수정',
                    resultStatus: '',
                    resultTitle: '',
                    resultSubTitle: '',
                    buttonText: '',
                  },
                  dataInfo: {
                    dataId: record.key,
                    dataTable: 'account_group',
                    datas: {},
                  },
                })
              }
            >
              <EditOutlined />
            </Button>
            <Button
              onClick={() =>
                openModal({
                  type: 'accountgroup',
                  modalInfo: {
                    modalTitle: '거래처그룹 삭제',
                    resultStatus: '',
                    resultTitle: '',
                    resultSubTitle: '',
                    buttonText: '',
                  },
                  dataInfo: {
                    dataId: record.key,
                    dataTable: 'account_group',
                    datas: {},
                  },
                })
              }
            >
              <DeleteOutlined />
            </Button>
          </Space.Compact>
        </ButtonContainer>
      ),
    },
  ];

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <Table
        tableColumns={tableColumns}
        table={table}
        createButtonText={createButtonText}
        createButtonModal={createButtonModal}
        items={tableColumns}
      />
    </LayoutComponent>
  );
};

export default Accountgroup;
