import { Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaRegMoon } from 'react-icons/fa';
import { ImSun } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import { changeDarkMode } from '../../../redux/modules/darkMode';

const DarkModeToggler = () => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.darkMode);

  useEffect(() => {
    let currentDarkModeValue;
    currentDarkModeValue =
      window.localStorage.getItem('darkMode') === 'true' || false;
    dispatch(changeDarkMode(currentDarkModeValue));
  }, []);

  const handleChangeDarkMode = (value) => {
    window.localStorage.setItem('darkMode', value);
    dispatch(changeDarkMode(value));
  };

  return (
    <Switch
      style={{
        marginRight: '30px',
        paddingTop: '2.5px',
        backgroundColor: darkMode ? '#1677FF' : 'gray',
      }}
      onChange={(e) => handleChangeDarkMode(e)}
      unCheckedChildren={<ImSun />}
      checkedChildren={<FaRegMoon />}
      checked={darkMode}
    />
  );
};

export default DarkModeToggler;
