import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { asyncSignupUserFetch } from '../../redux/modules/auth/signupUser';
import Center from '../../shared/components/Center';
import SignupForm from '../components/SignupForm';
import SignupHeader from '../components/SignupHeader';
import AuthContainer from '../components/AuthContainer';
import AuthFooter from '../components/AuthFooter';

const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSignup = async (values) => {
    const confirm = window.confirm('입력하신 정보로 회원가입 하시겠습니까?');
    if (!confirm) return;

    const { front, back, ...formValues } = values;
    const phone = `010-${front}-${back}`;

    const postData = {
      ...formValues,
      phone,
    };

    try {
      await dispatch(asyncSignupUserFetch(postData)).unwrap();
      alert('회원가입이 완료 되었습니다.');
      history.push('/');
    } catch (error) {
      alert(error);
      throw error;
    }
  };

  return (
    <Center>
      <AuthContainer>
        <SignupHeader />
        <SignupForm handleSignup={handleSignup} />
      </AuthContainer>
      <AuthFooter />
    </Center>
  );
};

export default Signup;
