import isOwnServerApi from './thirdPartyApiConfig';
import {
  getParsedData,
  getResponse,
  getUrl,
  printResultData,
} from './utils/getFnByThirdPartyApiConfig';

// 용달요금 구하기
const getDlvyPrice = (distance) => {
  let price = 25000;
  if (distance >= 600000) {
    price = 300000;
  } else if (distance >= 550000) {
    price = 270000;
  } else if (distance >= 500000) {
    price = 250000;
  } else if (distance >= 450000) {
    price = 220000;
  } else if (distance >= 400000) {
    price = 200000;
  } else if (distance >= 350000) {
    price = 190000;
  } else if (distance >= 300000) {
    price = 170000;
  } else if (distance >= 250000) {
    price = 150000;
  } else if (distance >= 200000) {
    price = 130000;
  } else if (distance >= 170000) {
    price = 120000;
  } else if (distance >= 150000) {
    price = 110000;
  } else if (distance >= 130000) {
    price = 100000;
  } else if (distance >= 110000) {
    price = 90000;
  } else if (distance >= 90000) {
    price = 80000;
  } else if (distance >= 70000) {
    price = 70000;
  } else if (distance >= 50000) {
    price = 60000;
  } else if (distance >= 30000) {
    price = 50000;
  } else if (distance >= 20000) {
    price = 40000;
  } else if (distance >= 10000) {
    price = 30000;
  }
  return price;
};

// direction 관련 third party api
const direction = () => {
  // 좌표값 받기 api
  const getGeocode = async (address) => {
    const postData = { address };
    const category = 'geocode';
    const url = getUrl(category, isOwnServerApi);

    try {
      const response = await getResponse(url, postData, isOwnServerApi);
      const parsedData = getParsedData(response.data.result_data);
      const resultData = parsedData.addresses[0];
      const { x, y } = resultData;
      const result = `${x},${y}`;
      printResultData(category, result);
      return result;
    } catch (err) {
      throw Error(err);
    }
  };

  // 좌표값 받기 (주소 사용) api
  const getGeocodeForAddress = async (address) => {
    const postData = { address };
    const category = 'geocode';
    const url = getUrl(category, isOwnServerApi);

    try {
      const response = await getResponse(url, postData, isOwnServerApi);
      const parsedData = getParsedData(response.data.result_data);
      const resultData = parsedData.addresses[0];
      const { x, y } = resultData;
      const result = { longitude: x, latitude: y };
      printResultData(category, result);
      return result;
    } catch (err) {
      throw Error(err);
    }
  };

  // 거리값 받기 api
  const getDirection = async (start, goal) => {
    // 출발지와 목적지 좌표값 할당
    let startGeocode = '';
    if (start === '경기도 평택시 진위면 가곡리 126-1') {
      startGeocode = '127.0822877,37.1086106';
    } else {
      startGeocode = await getGeocode(start);
    }
    const goalGeocode = await getGeocode(goal);

    const postData = {
      start: startGeocode,
      goal: goalGeocode,
      option: 'traoptimal',
    };
    const category = 'direction';
    const url = getUrl(category, isOwnServerApi);

    try {
      const response = await getResponse(url, postData, isOwnServerApi);
      const parsedData = getParsedData(response.data.result_data);
      const metersDistance = parsedData.route.traoptimal[0].summary.distance;
      // 거리별 용달요금 할당
      const dlvyPrice = getDlvyPrice(metersDistance);
      const distance = metersDistance / 1000;
      const result = { distance, dlvyPrice, startGeocode, goalGeocode };
      printResultData(category, result);
      return result;
    } catch (err) {
      throw Error(err);
    }
  };

  return { getDirection, getGeocodeForAddress };
};

export default direction;
