import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styled, { keyframes } from 'styled-components';

const slideDown = keyframes`
  0% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateY(0);
  }
`;
const Counter = styled.div`
  font-size: 16px;
  animation: ${({ animate }) => (animate ? slideDown : 'none')} 0.25s
    ease-in-out;
`;

const CalcButton = (props) => {
  const { saleData, selectDatas, isReset, setSaleDatas } = props;
  const [count, setCount] = useState(0);
  const [animate, setAnimate] = useState(false);

  // 카운트 리셋
  useEffect(() => {
    if (isReset) {
      setCount(0);
    }
  }, [isReset]);

  const handleUpButton = () => {
    setCount((prev) => prev + 1);
    selectDatas(saleData, 'up', count);
    // 애니메이션 효과
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 250);
  };
  const handleDownButton = () => {
    setCount((prev) => prev - 1);
    selectDatas(saleData, 'down', count);
    // 애니메이션 효과
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 250);
  };

  return (
    <div
      style={{
        width: '200px',
        height: '140px',
        border: '1px solid lightGray',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        backgroundColor: 'white',
        color: 'black',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          width: '23px',
          height: '23px',
          backgroundColor: 'lightgray',
          borderTopRightRadius: '9px',
          borderBottomLeftRadius: '9px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button size="small" type="link" style={{ color: 'white' }}>
          {saleData.sort_order}
        </Button>
      </div>
      <div style={{ marginBottom: '20px', fontSize: '16px' }}>
        {saleData.sale_name}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={handleDownButton}
          disabled={count < 1}
          style={{
            borderRadius: '100px 0px 0px 100px',
            position: 'absolute',
            zIndex: '1',
            left: '50%',
            transform: 'translateX(-120%)',
          }}
        >
          <MinusOutlined />
        </Button>
        <div
          style={{
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            border: '1px solid lightgray',
            borderRadius: '50%',
            zIndex: '2',
            backgroundColor: count > 0 ? '#1677ff' : 'white',
            color: count > 0 ? 'white' : 'black',
          }}
        >
          <Counter animate={count > 0 && animate}>{count}</Counter>
        </div>
        <Button
          onClick={handleUpButton}
          style={{
            borderRadius: '0px 100px 100px 0px',
            position: 'absolute',
            zIndex: '1',
            right: '50%',
            transform: 'translateX(120%)',
          }}
        >
          <PlusOutlined />
        </Button>
      </div>
    </div>
  );
};

export default CalcButton;

CalcButton.propTypes = {
  saleData: PropTypes.instanceOf(Object).isRequired,
  selectDatas: PropTypes.func.isRequired,
  isReset: PropTypes.bool.isRequired,
  setSaleDatas: PropTypes.func.isRequired,
};
