import React, { useMemo } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

import useModal from '../../hook/useModal';
import MerchandiseData from './MerchandiseData';

const MerchandiseModal = () => {
  const { closeModal } = useModal();
  const { modalInfo } = useSelector((state) => state.modal);

  // form에 들어갈 각 item 항목들을 배열로 만듬.
  // TableFormModal에서 form형식으로 매핑됨.
  const items = useMemo(
    () => [
      {
        type: 'input',
        name: 'code',
        label: '판매상품코드',
        message: '판매상품코드를 입력해주세요.',
        visibility: false,
      },
      {
        type: 'input',
        name: 'name',
        label: '판매상품명',
        message: '판매상품명을 입력해주세요.',
        visibility: false,
      },
    ],
    []
  );

  return (
    <Modal
      open
      onCancel={() => closeModal()}
      title={modalInfo.modalTitle}
      centered
      footer={[<div key="modal" />]}
    >
      <MerchandiseData items={items} />
    </Modal>
  );
};

export default MerchandiseModal;
