import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import CalcButton from './CalcButton';

const CalcButtons = (props) => {
  const { saleDatas, selectDatas, resetDatas, isReset, setSaleDatas } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        padding: '30px 0',
        gap: '15px',
        backgroundColor: '#f7f7f7',
        justifyContent: 'center',
      }}
    >
      <Button
        type="primary"
        danger
        style={{
          width: '200px',
          height: '140px',
          color: 'white',
          fontSize: '18px',
          fontWeight: 'bold',
        }}
        onClick={() => resetDatas()}
      >
        RESET
      </Button>
      {saleDatas.map((saleData) => (
        <CalcButton
          saleData={saleData}
          key={saleData.sale_code}
          selectDatas={selectDatas}
          isReset={isReset}
          setSaleDatas={setSaleDatas}
        />
      ))}
    </div>
  );
};

export default CalcButtons;

CalcButtons.propTypes = {
  saleDatas: PropTypes.instanceOf(Array).isRequired,
  selectDatas: PropTypes.func.isRequired,
  resetDatas: PropTypes.func.isRequired,
  isReset: PropTypes.bool.isRequired,
  setSaleDatas: PropTypes.func.isRequired,
};
