import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Select,
  Descriptions,
  Popconfirm,
  Tag,
  Popover,
  Alert,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  UnorderedListOutlined,
  ReloadOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  InfoOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';

const ProductForm = (props) => {
  const { pageType, formItems, formData, onFinish } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useEffect(() => {
    if (pageType === 'create') {
      const asyncSetForm = async () => {
        try {
          const len = await dispatch(
            asyncReadDataFetch({ table: 'product', reqDataLen: true })
          )
            .unwrap()
            .then((res) => res + 1)
            .catch((err) => {
              console.log(err);
              alert(err.message);
            });
          const productCode = `P${len.toString().padStart(5, '0')}`;
          form.setFieldsValue({
            ...formData,
            oms_code: productCode,
            standard_type: '1',
            category: '1',
            set: '0',
            quantity: '0',
            incoming_price_VAT: '0',
            release_price_VAT: '0',
            warehouse_code: '00009',
            delivery_type: '1',
            is_hidden: false,
          });
        } catch (err) {
          console.log(err);
          alert(err.message);
        }
      };
      asyncSetForm();
    } else {
      form.setFieldsValue({
        ...formData,
      });
    }
  }, [formData, form, pageType, dispatch]);

  // 출하창고 select폼 생성
  useEffect(() => {
    // 출하창고 (warehouse_code) select-search form 생성
    dispatch(asyncReadDataFetch({ table: 'warehouse' })).then((res) => {
      setWarehouseOptions([
        ...res.payload.map((data) => ({
          value: data.code,
          label: `${data.code} / ${data.name}`,
        })),
      ]);
    });
  }, [dispatch]);

  return (
    <div className="product-form-container">
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={(e) => console.log(e)}
      >
        <div
          style={{
            fontSize: '16px',
            width: '100%',
            paddingBottom: '10px',
            borderBottom: '1px solid gray',
          }}
        >
          출고상품(품목) 입력
        </div>
        <Descriptions
          bordered
          labelStyle={{ width: '10%', textAlign: 'center' }}
          contentStyle={{ width: '40%' }}
        >
          {formItems.map((formItem) => {
            if (formItem.type === 'input') {
              return (
                <Descriptions.Item
                  style={{ position: 'relative' }}
                  span={formItem.span}
                  label={
                    (formItem.name === 'name' && (
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <span style={{ color: 'red', marginRight: '5px' }}>
                          *
                        </span>
                        <div>{formItem.label}</div>
                      </div>
                    )) ||
                    (formItem.name === 'code' && (
                      <div
                        style={{
                          position: 'absolute',
                          width: '99.9%',
                          backgroundColor: '#fafafa',
                          top: '85%',
                          left: '1px',
                          zIndex: '1',
                          borderRight: '0.5px solid #efefef',
                        }}
                      >
                        <span style={{ color: 'red' }}>* </span>품목코드
                      </div>
                    )) ||
                    (formItem.name === 'oms_code' && <div />) ||
                    ((formItem.name !== 'name' ||
                      formItem.name !== 'code' ||
                      formItem.name !== 'oms_code') && (
                      <div>{formItem.label}</div>
                    ))
                  }
                >
                  <Form.Item
                    noStyle
                    style={{ marginBottom: '42px', padding: '0 30px' }}
                    name={formItem.name}
                    required={formItem.require}
                  >
                    <Input
                      style={{ borderRadius: '0px' }}
                      disabled={
                        pageType === 'detail' ||
                        formItem.name === 'oms_code' ||
                        (formItem.name === 'code' && pageType === 'update')
                      }
                      placeholder={formItem.message}
                      suffix={
                        formItem.name === 'code' && (
                          <Popover
                            placement="bottomLeft"
                            content={
                              <div>
                                <span style={{ color: 'red' }}>*</span> 한 번
                                저장된 품목코드는 수정할 수 없습니다.
                              </div>
                            }
                          >
                            <InfoCircleOutlined style={{ color: '#a0a0a0' }} />
                          </Popover>
                        )
                      }
                      prefix={
                        (formItem.name === 'oms_code' && (
                          <Tag
                            style={{
                              color: 'gray',
                              margin: '0 10px 0 0',
                              borderRadius: '20px',
                              width: '100px',
                              textAlign: 'center',
                            }}
                          >
                            OMS
                          </Tag>
                        )) ||
                        (formItem.name === 'code' && (
                          <Tag
                            style={{
                              color: 'gray',
                              margin: '0 10px 0 0',
                              borderRadius: '20px',
                              width: '100px',
                              textAlign: 'center',
                            }}
                          >
                            ECOUNT
                          </Tag>
                        ))
                      }
                    />
                  </Form.Item>
                </Descriptions.Item>
              );
            }
            if (formItem.type === 'select') {
              return (
                <Descriptions.Item
                  label={formItem.label}
                  span={formItem.span}
                  labelStyle={{ width: '150px' }}
                >
                  <div className="select-container">
                    <Form.Item
                      noStyle
                      style={{
                        marginBottom: '42px',
                        padding: '0 30px',
                        width: '100%',
                      }}
                      name={formItem.name}
                    >
                      <Select
                        options={
                          formItem.options ||
                          (formItem.name === 'warehouse_code' &&
                            warehouseOptions)
                        }
                        style={{ borderRadius: '0px', width: '100%' }}
                        disabled={pageType === 'detail'}
                        placeholder={formItem.message}
                      />
                    </Form.Item>
                  </div>
                </Descriptions.Item>
              );
            }
          })}
        </Descriptions>
        <div
          style={{
            marginTop: '5px',
            color: 'gray',
            textAlign: 'end',
            marginRight: '25px',
          }}
        >
          배송 방법을 지정하는 경우 품목코드는 COD로 시작합니다. ex)COD01
        </div>
        <div
          style={{
            backgroundColor: '#fafafa',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: '8px',
            border: '1px solid rgb(240, 239, 239)',
            marginTop: '50px',
            padding: '20px 15px',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => history.push('/product')}
              style={{ marginRight: '5px' }}
            >
              <UnorderedListOutlined />
              목록
            </Button>
            <Button onClick={() => window.location.reload()}>
              <ReloadOutlined />
              취소
            </Button>
          </div>
          <Form.Item noStyle>
            {pageType === 'detail' && (
              <div>
                <Button
                  style={{ marginRight: '5px' }}
                  type="primary"
                  onClick={() => history.push(`/product/updateProduct/${id}`)}
                >
                  <EditOutlined />
                  수정
                </Button>
                <Button danger type="primary" htmlType="submit">
                  <DeleteOutlined />
                  삭제
                </Button>
              </div>
            )}
            {pageType === 'create' && (
              <Button type="primary" htmlType="submit">
                <PlusOutlined />
                추가
              </Button>
            )}
            {pageType === 'update' && (
              <Button type="primary" htmlType="submit">
                <SaveOutlined />
                저장
              </Button>
            )}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ProductForm;

ProductForm.propTypes = {
  pageType: PropTypes.string.isRequired,
  formItems: PropTypes.instanceOf(Array).isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
  onFinish: PropTypes.func.isRequired,
};
