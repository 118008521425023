import { Button } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../axios';

import { asyncReadPaginatedDataFetch } from '../../redux/modules/shared/readPaginatedData';

const EntireMigrator = ({ table }) => {
  const dispatch = useDispatch();

  const [migrationStatus, setMigrationStatus] = useState('대기');
  const [isButtonInValid, setIsButtonInValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [rowCount, setRowCount] = useState({
    pending: 0,
    success: 0,
  });

  const handleMigration = async (table) => {
    setIsButtonInValid(true);
    setMigrationStatus('마이그레이션 진행 중...');
    setIsLoading(true);

    try {
      const migrationData = await dispatch(
        asyncReadPaginatedDataFetch({ table })
      ).unwrap();

      setRowCount((prev) => ({ ...prev, pending: migrationData.length }));

      // 서버 migration api 호출
      const response = await axiosInstance.post(
        `/api/migration/${table}`,
        migrationData
      );

      setRowCount((prev) => ({ ...prev, success: response.data }));
    } catch (error) {
      window.alert(`마이그레이션 오류 \n${error}`);
      console.error('마이그레이션 오류', error);

      setIsButtonInValid(false);
      setMigrationStatus('오류');
      setIsLoading(false);
      return;
    }

    setMigrationStatus('완료');
    setIsLoading(false);
  };

  const handleDelete = async (table) => {
    try {
      await axiosInstance.delete(`/api/migration/${table}`);
      window.alert(`테이블: ${table} \n삭제 완료`);
    } catch (error) {
      window.alert(`삭제 오류 \n${error}`);
      console.error('삭제 오류', error);

      setMigrationStatus('삭제 오류');
      return;
    }

    setMigrationStatus('삭제 완료');
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          margin: '5px 0',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h3>{table}</h3>
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <div>pending: {rowCount.pending.toLocaleString()}</div>
          <div>success: {rowCount.success.toLocaleString()}</div>
          <div>{migrationStatus}</div>
          <Button
            disabled={isButtonInValid}
            type="primary"
            onClick={() => handleMigration(table)}
            loading={isLoading}
          >
            MIGRATE
          </Button>
          <Button danger type="primary" onClick={() => handleDelete(table)}>
            DELETE
          </Button>
        </div>
      </div>
      <hr />
    </>
  );
};

export default EntireMigrator;

EntireMigrator.propTypes = {
  table: PropTypes.string.isRequired,
};
