import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { FileDoneOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

// import { Badge, Button, Select, Spin, Tag } from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Table from '../../shared/table/Table';
import ProductValue from '../components/ProductValue';
import AdminValue from '../components/AdminValue';
import SalesAmountValue from '../components/SalesAmountValue';
import AccountValue from '../components/AccountValue';
import DateRangePicker from '../components/DateRangePicker';
import useDateRangeFromLocalStorage from '../../hook/useDateRangeFromLocalStorage';
/*
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncReadBulkDataFetch } from '../../redux/modules/shared/readBulkData';
import StackAmountValue from '../components/AccountStackInfo';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
*/

const SalesInquiry = () => {
  const dispatch = useDispatch();

  const [dateRange, setDateRange] = useState({
    start: dayjs(),
    end: dayjs(),
    type: 'today',
  });

  // localStorage에서 설정된 dateRange정보가 저장되어 있으면 가져옴
  useDateRangeFromLocalStorage(setDateRange);

  /*
  const [orderQuantity, setOrderQuantity] = useState(0);
  const [orderPrice, setOrderPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataGenYr, setDataGenYr] = useState(dayjs().$y);
  const [yrOptions, setYrOptions] = useState([]);
  const [isOpenSummaryData, setIsOpenSummaryData] = useState(false);
  // 조회연도 데이터 옵션 생성
  useEffect(() => {
    const thisYr = dayjs().$y;
    let yrArr = [];
    for (let yr = thisYr; yr >= '2013'; yr -= 1) {
      yrArr.push(yr);
    }
    const newYrOptions = yrArr.map((yr) => ({ label: `${yr}년`, value: yr }));
    setYrOptions([...newYrOptions]);
  }, []);

  const handleSummary = async () => {
    try {
      await setIsOpenSummaryData(true);
      await setLoading(true);
      const orders = await dispatch(
        asyncReadDataFetch({ table: 'order', dataGenYr })
      )
        .unwrap()
        .then((res) => res);
      const orderIds = await orders.map((order) => order.id);
      const ordersQty = orderIds.length;
      setOrderQuantity(ordersQty);

      const batchSize = 200;
      const products = [];

      for (let i = 0; i < orderIds.length; i += batchSize) {
        const slicedOrderIds = orderIds.slice(i, i + batchSize);
        const slicedProducts = await dispatch(
          asyncReadBulkDataFetch({
            table: 'order_product',
            column: 'order_id',
            array: slicedOrderIds,
          })
        )
          .unwrap()
          .then((res) => res);
        products.push(...slicedProducts);
      }

      const pdsPrice = products.reduce((acc, cur) => {
        const { product_supply_price, product_VAT_price } = cur;
        const sum = product_supply_price + product_VAT_price;
        return acc + sum;
      }, 0);
      await setOrderPrice(pdsPrice);

      await setLoading(false);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  // 전체주문건수, 전체주문금액
  useEffect(() => {
    const getQtyAndPrice = async () => {
      try {
        await setLoading(true);
        const orders = await dispatch(
          asyncReadDataFetch({ table: 'order', dataGenYr })
        )
          .unwrap()
          .then((res) => res);
        const orderIds = await orders.map((order) => order.id);
        const ordersQty = orderIds.length;
        setOrderQuantity(ordersQty);

        const batchSize = 200;
        const products = [];

        for (let i = 0; i < orderIds.length; i += batchSize) {
          const slicedOrderIds = orderIds.slice(i, i + batchSize);
          const slicedProducts = await dispatch(
            asyncReadBulkDataFetch({
              table: 'order_product',
              column: 'order_id',
              array: slicedOrderIds,
            })
          )
            .unwrap()
            .then((res) => res);
          products.push(...slicedProducts);
        }

        const pdsPrice = products.reduce((acc, cur) => {
          const { product_supply_price, product_VAT_price } = cur;
          const sum = product_supply_price + product_VAT_price;
          return acc + sum;
        }, 0);
        await setOrderPrice(pdsPrice);

        await setLoading(false);
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    };
    getQtyAndPrice();
  }, [dispatch, dataGenYr]);
  */

  // TableHeader에 들어갈 create 버튼 텍스트
  const createButtonText = '판매조회 추가';

  // breadCrumb 데이터 생성
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <FileDoneOutlined />,
        breadCrumbNav: ' 판매조회',
      },
    ],
    []
  );

  // 불러올 table이 뭔지
  const table = 'order';

  // table에 넣을 columns. table View를 위한 배열.
  const orderTableColumns = useMemo(
    () => [
      {
        title: '출고일',
        dataIndex: 'delivery_date',
        render: (data) => <div>{data}</div>,
        width: '10%',
        align: 'center',
      },
      {
        title: '주문일',
        dataIndex: 'order_date',
        render: (data) => <div>{data}</div>,
        width: '10%',
        align: 'center',
      },
      {
        title: '담당자',
        dataIndex: 'admin_code',
        key: 'admin_code',
        render: (data) => {
          return <AdminValue adminCode={data} />;
        },
        width: '15%',
        align: 'center',
      },
      {
        title: '거래처',
        dataIndex: 'account_code',
        key: 'account_code',
        render: (data) => {
          return <AccountValue accountCode={data} />;
        },
        width: '25%',
        align: 'center',
      },
      {
        title: '출고상품(품목)',
        key: 'products',
        render: (data) => {
          return <ProductValue id={data.id} />;
        },
        width: '30%',
        align: 'center',
      },
      {
        title: '판매금액',
        dataIndex: 'id',
        key: 'sales_amount',
        render: (data) => <SalesAmountValue id={data} />,
        width: '10%',
        align: 'center',
      },
    ],
    []
  );

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          backgroundColor: '#f7f7f7',
          alignItems: 'center',
          paddingBottom: '15px',
        }}
      >
        {/*
        <HeaderContainer>
          <HeaderTitle>조회할 데이터 연도</HeaderTitle>
          <Select
            size="small"
            options={yrOptions}
            value={dataGenYr}
            onChange={(value) => {
              setIsOpenSummaryData(false);
              setDataGenYr(value);
            }}
          />
        </HeaderContainer>
        <Line />
        <HeaderContainer>
          <HeaderTitle>특정날짜 조회</HeaderTitle>
          <HeaderContent>
            <Button
              size="small"
              style={{ marginRight: '5px' }}
              onClick={() => filterSpecificDate('today')}
              disabled
            >
              금일
            </Button>
            <Button
              size="small"
              onClick={() => filterSpecificDate('yesterday')}
              disabled
            >
              전일
            </Button>
          </HeaderContent>
        </HeaderContainer>
        <Line />
        {isOpenSummaryData ? (
          <>
            <HeaderContainer>
              <HeaderTitle>전체주문건수</HeaderTitle>
              <HeaderContent>{orderQuantity.toLocaleString()}</HeaderContent>
            </HeaderContainer>
            <Line />
            <HeaderContainer>
              <HeaderTitle>전체판매금액</HeaderTitle>
              <HeaderContent>
                {loading && <Spin />}
                {!loading && orderPrice.toLocaleString()}
              </HeaderContent>
            </HeaderContainer>
          </>
        ) : (
          <Button size="small" onClick={handleSummary}>
            {`${dataGenYr}년 전체 주문건수, 판매금액 확인`}
          </Button>
        )}
        */}
        <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
      </div>
      <Table
        tableColumns={orderTableColumns}
        table={table}
        items={orderTableColumns}
        createButtonText={createButtonText}
        dateRange={dateRange}
      />
    </LayoutComponent>
  );
};

export default SalesInquiry;

/*
const HeaderContainer = styled.div``;
const HeaderTitle = styled.span`
  color: gray;
  margin-bottom: 10px;
  margin-right: 5px;
`;
const HeaderContent = styled.span`
  font-weight: 600;
`;
const Line = styled.div`
  width: 1px;
  margin: 0 10px;
  height: 15px;
  background-color: lightgray;
`;
*/
