import { Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { asyncDeleteDataFetch } from '../../redux/modules/shared/deleteData';

const DeleteButton = (props) => {
  const { id, table, setErrorDatas } = props;
  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      await dispatch(
        asyncDeleteDataFetch({ table: 'ecount_sync_error_record', id })
      ).then(() => {
        if (table === '주문') {
          setErrorDatas((prev) => ({
            ...prev,
            order: prev.order.filter((item) => item.id !== id),
          }));
        }
        if (table === '판매') {
          setErrorDatas((prev) => ({
            ...prev,
            sales: prev.sales.filter((item) => item.id !== id),
          }));
        }
        if (table === '거래처') {
          setErrorDatas((prev) => ({
            ...prev,
            account: prev.account.filter((item) => item.id !== id),
          }));
        }
      });
    } catch (error) {
      alert(error);
      console.log(error);
    }
  };

  return (
    <Button size="small" type="primary" danger onClick={handleDelete}>
      삭제
    </Button>
  );
};

export default DeleteButton;

DeleteButton.propTypes = {
  id: PropTypes.number.isRequired,
  table: PropTypes.string.isRequired,
  setErrorDatas: PropTypes.func.isRequired,
};
