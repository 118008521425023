import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, Skeleton } from 'antd';
import { useDispatch } from 'react-redux';
import { InfoCircleFilled, SearchOutlined } from '@ant-design/icons';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import AccountStackInfo from './AccountStackInfo';

const AccountValue = (props) => {
  const { accountCode } = props;
  const dispatch = useDispatch();

  const [account, setAccount] = useState('');
  const [loading, setLoading] = useState(true);

  const [isStackInfoModalOpen, setIsStackInfoModalOpen] = useState(false);

  useEffect(() => {
    const asyncFunc = async () => {
      await setLoading(true);
      const accountName = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'account',
          eqKey: 'code',
          eqValue: accountCode,
        })
      )
        .unwrap()
        .then((res) => res[0].name)
        .catch((err) => {
          console.log(err);
        });
      await setAccount(accountName);
      setLoading(false);
    };
    asyncFunc();
  }, [dispatch, accountCode]);

  const showModal = () => {
    setIsStackInfoModalOpen(true);
  };
  const handleCancel = () => {
    setIsStackInfoModalOpen(false);
  };

  return (
    <>
      <div>
        {loading ? (
          <Skeleton.Input size="small" active style={{ height: '20px' }} />
        ) : (
          <Popover
            content={
              <div>
                <Button onClick={showModal} type="primary">
                  <InfoCircleFilled />
                  판매정보
                </Button>
              </div>
            }
            placement="bottom"
          >
            <Button type="link" style={{ color: 'black' }}>
              {account}
            </Button>
          </Popover>
        )}
      </div>
      {isStackInfoModalOpen && (
        <AccountStackInfo
          handleCancel={handleCancel}
          accountName={account}
          accountCode={accountCode}
        />
      )}
    </>
  );
};

export default AccountValue;

AccountValue.propTypes = {
  accountCode: PropTypes.string.isRequired,
};
