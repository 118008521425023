import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Avvvatars from 'avvvatars-react';
import { Button, Popover, Tag } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import { COUNSELLING_REACTION_BAD } from '../../constants';
import CommentForm from './CommentForm';

const CommentItem = (props) => {
  const {
    commentId,
    adminId,
    content,
    reactionLevel,
    regDate,
    handleUpdateComment,
    handleDeleteComment,
    isUpdateComment,
    setIsUpdateComment,
    commentIdToUpdate,
    setCommentIdToUpdate,
  } = props;
  const dispatch = useDispatch();
  const [adminInfo, setAdminInfo] = useState({
    username: null,
    code: null,
    id: null,
  });

  useEffect(() => {
    const getAdminInfo = async () => {
      try {
        const fetchedAdminInfo = await dispatch(
          asyncReadSpecificDataFetch({ table: 'profiles', id: adminId })
        )
          .unwrap()
          .then((res) => res[0]);
        setAdminInfo({
          username: fetchedAdminInfo.username,
          code: fetchedAdminInfo.code,
          id: fetchedAdminInfo.id,
        });
      } catch (error) {
        throw new Error(error);
      }
    };
    getAdminInfo();
  }, [adminId]);

  if (isUpdateComment && commentId === commentIdToUpdate) {
    return (
      <CommentForm
        isUpdateComment
        setIsUpdateComment={setIsUpdateComment}
        handleUpdateComment={handleUpdateComment}
        adminInfo={adminInfo}
        setAdminInfo={setAdminInfo}
        setCommentIdToUpdate={setCommentIdToUpdate}
        commentId={commentId}
        reactionLevel={reactionLevel}
        content={content}
      />
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '12px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <div style={{ marginRight: '10px' }}>
          <Avvvatars style="shape" value={adminInfo.username} />
        </div>
        <div>
          <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
            {adminInfo.username}
          </div>
          <div
            style={{
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'start',
            }}
          >
            <Tag
              color={
                reactionLevel === COUNSELLING_REACTION_BAD ? 'red' : 'blue'
              }
            >
              {reactionLevel}
            </Tag>
            <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
              {content}
            </pre>
          </div>
          <div style={{ fontSize: '12px', color: 'gray' }}>
            {regDate && regDate.slice(0, 19).replace('T', '')}
          </div>
        </div>
      </div>
      <Popover
        placement="left"
        trigger="click"
        content={
          <div
            style={{ display: 'flex', flexDirection: 'column', padding: '0px' }}
          >
            <CommentButtonStyle
              type="link"
              onClick={() => {
                if (isUpdateComment) {
                  alert('다른 댓글을 수정중입니다.');
                  return;
                }
                setIsUpdateComment(true);
                setCommentIdToUpdate(commentId);
              }}
            >
              수정
            </CommentButtonStyle>
            <CommentButtonStyle
              type="link"
              onClick={() => handleDeleteComment(commentId)}
            >
              삭제
            </CommentButtonStyle>
          </div>
        }
      >
        <Button size="small" type="link">
          <MoreOutlined
            style={{ fontWeight: 'bold', fontSize: '22px', color: 'gray' }}
          />
        </Button>
      </Popover>
    </div>
  );
};

export default CommentItem;

CommentItem.propTypes = {
  commentId: PropTypes.number.isRequired,
  adminId: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  reactionLevel: PropTypes.string.isRequired,
  regDate: PropTypes.string.isRequired,
  handleUpdateComment: PropTypes.func.isRequired,
  handleDeleteComment: PropTypes.func.isRequired,
  isUpdateComment: PropTypes.bool.isRequired,
  setIsUpdateComment: PropTypes.func.isRequired,
  commentIdToUpdate: PropTypes.number.isRequired,
  setCommentIdToUpdate: PropTypes.func.isRequired,
};

const CommentButtonStyle = styled(Button)`
  color: black;
  border-radius: 0px;
  background-color: white;
  font-size: 15px;
  :hover {
    background-color: #efefef;
    text-decoration: underline;
  }
`;
