import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import EstimateModal from './EstimateModal';

const EstimateProductValue = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState({ name: '', quantity: 0 });
  const [loading, setLoading] = useState(true);
  const [isOpenEstimateModal, setIsOpenEstimateModal] = useState(false);

  useEffect(() => {
    const getProducts = async () => {
      try {
        await setLoading(true);
        const products = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'order_product',
            eqKey: 'order_id',
            eqValue: id,
          })
        )
          .unwrap()
          .then((res) => res);
        const notAcctDcPds = products.filter((pd) => !pd.is_account_dc);
        const notDlvyPds = notAcctDcPds.filter(
          (pd) => !pd.product_name.includes('택배비')
        );
        const productName = notDlvyPds[0].product_name;
        const productLen = notDlvyPds.length - 1;
        await setValue({ name: productName, quantity: productLen });
        setLoading(false);
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    };
    getProducts();
  }, [dispatch, id]);
  return (
    <div>
      {loading ? (
        <Skeleton.Input active style={{ height: '20px' }} />
      ) : (
        <Button type="link" onClick={() => setIsOpenEstimateModal(true)}>
          {value.name}
          {value.quantity > 0 ? (
            <span>
              <span style={{ color: 'black' }}>외</span> {value.quantity}
              <span style={{ color: 'black' }}>건</span>
            </span>
          ) : null}
        </Button>
      )}
      {isOpenEstimateModal && (
        <EstimateModal
          id={id}
          setIsOpenEstimateModal={setIsOpenEstimateModal}
        />
      )}
    </div>
  );
};

export default EstimateProductValue;

EstimateProductValue.propTypes = {
  id: PropTypes.string.isRequired,
};
