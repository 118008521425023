import { Layout } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ExternalServicesPopover from './ExternalServicesPopover';
import ProfilePopover from './ProfilePopover';
import DashboardButton from './DashBoardButton';
import DarkModeToggler from './DarkModeToggler';
import {
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  LIGHT_MODE_BACKGROUND,
  LIGHT_MODE_FONT_COLOR,
} from '../../../constants';

const { Header } = Layout;

const EmailStyle = styled.div`
  margin: 0 5px;
  color: white;
  opacity: 0.7;
  transition: 0.5s;
  font-size: 16px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const LogoStyle = styled.div`
  font-size: 30px;
  font-family: 'Jua';
  color: white;
  &:hover {
    cursor: pointer;
  }
`;

const isDevelopmentMode = process.env.NODE_ENV === 'development';

const HeaderComponent = (props) => {
  const { userInfo } = props;
  const history = useHistory();

  const { darkMode } = useSelector((state) => state.darkMode);

  return (
    <Header
      className="header"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 50px',
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
        borderBottom: !darkMode && '1px solid lightgray',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LogoStyle
          onClick={() => history.push('/data-calendar')}
          style={{
            color: darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR,
          }}
        >
          한통 OMS {isDevelopmentMode && ': 자체 서버'}
        </LogoStyle>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DarkModeToggler />
        <DashboardButton />
        <ExternalServicesPopover />
        <ProfilePopover userInfo={userInfo} />
      </div>
    </Header>
  );
};

export default HeaderComponent;

HeaderComponent.propTypes = {
  userInfo: PropTypes.instanceOf(Object).isRequired,
};
