/* eslint consistent-return: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../axios';
import useSupabase from '../../../serverConfig';

import supabase from '../../../supabase';
import isUserMigrated from '../../../userMigrationConfig';

const initialState = {
  userInfo: {
    id: '',
    reg_date: '',
    state: null,
    mode_data: '',
    username: '',
    phone: '',
    permission: null,
    tier: null,
    intro: '',
    email: '',
  },
  isLoggedIn: null,
  isLoadingSigninUser: false,
  isErrorSigninUser: false,
  isSuccessSigninUser: false,
};

// 토큰 저장
const saveTokenToLocalStorage = (token) => {
  localStorage.setItem('token', token);
};

// 수파베이스 연결
const signinBySupabase = async (email, password) => {
  const { data } = await supabase.auth.signInWithPassword({
    email,
    password,
  });
  if (data) {
    const { id } = data.session.user;
    const user = await supabase.from('profiles').select().eq('id', id);
    const userInfo = user?.data[0];
    return userInfo;
  }
};
// 자체서버 연결 (유저 이식 후부터)
const signinByApi = async (email, password) => {
  const { data } = await axiosInstance.post('/api/auth/signin', {
    email,
    password,
  });
  const { access_token } = data || null;
  if (access_token) {
    saveTokenToLocalStorage(access_token);
    const user = await axiosInstance.get(
      `/api/dynamic-crud/profiles/key?key=email&value=${email}`
    );
    const userInfo = user?.data[0];
    return userInfo;
  }
};
// 자체서버 연결 (유저 이식 완료 전까지)
const signinWithMigrateUser = async (email, password) => {
  const { data } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (data) {
    const { id } = data.session.user;
    const supabaseUser = await supabase.from('profiles').select().eq('id', id);
    const supabaseUserInfo = supabaseUser?.data[0];
    const { username, phone, permission, tier, intro, code } = supabaseUserInfo;
    const migratedResult = await axiosInstance.post('/api/auth/migrate-user', {
      id,
      email,
      password,
      username,
      phone,
      permission,
      tier,
      intro,
      code,
    });
    saveTokenToLocalStorage(migratedResult.data.access_token);
    const user = await axiosInstance.get(
      `/api/dynamic-crud/profiles/key?key=email&value=${email}`
    );
    const userInfo = user?.data[0];
    return userInfo;
  }
};

// 비동기인 fetch 함수 작성 (로그인)
const asyncSigninUserFetch = createAsyncThunk(
  'signinUserSlice/asyncSigninUserFetch',
  async (values, { rejectWithValue }) => {
    const { email, password } = values;
    try {
      const result = useSupabase
        ? await signinBySupabase(email, password)
        : isUserMigrated
        ? await signinByApi(email, password)
        : await signinWithMigrateUser(email, password);

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const signinUserSlice = createSlice({
  name: 'signinUser',
  initialState,
  // 비동기가 아닌 reducer 작성
  reducers: {
    // 로그아웃 reducer
    logoutReducer: (state, action) => {
      state.userInfo = { ...initialState.userInfo };
      state.isLoggedIn = action.payload.isLoggedIn;
    },
    // 로그인 된 상태의 refresh reducer
    loggedInRefreshSessionReducer: (state, action) => {
      state.userInfo = { ...action.payload.userInfo };
      state.isLoggedIn = action.payload.isLoggedIn;
    },
    // 로그인 안된 상태의 refresh reducer
    notLoggedInRefreshSessionReducer: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn;
    },
  },
  // 비동기인 reducer 작성
  extraReducers: (builder) => {
    builder.addCase(asyncSigninUserFetch.pending, (state) => {
      state.isLoadingSigninUser = true;
    });
    builder.addCase(asyncSigninUserFetch.fulfilled, (state, action) => {
      state.userInfo = { ...action.payload };
      state.isLoadingSigninUser = false;
      state.isSuccessSigninUser = true;
      state.isLoggedIn = true;
    });
    builder.addCase(asyncSigninUserFetch.rejected, (state) => {
      state.isLoadingSigninUser = false;
      state.isErrorSigninUser = true;
    });
  },
});

export default signinUserSlice.reducer;

export const { logoutReducer } = signinUserSlice.actions;
export const {
  loggedInRefreshSessionReducer,
  notLoggedInRefreshSessionReducer,
} = signinUserSlice.actions;
export { asyncSigninUserFetch };
