import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import useSupabase from '../../../serverConfig';

import supabase from '../../../supabase';
import axiosInstance from '../../../axios';

const initialState = {
  isLoadingReadSpecificData: false,
  isErrorReadSpecificData: false,
  isSuccessReadSpecificData: false,
};

const asyncReadSpecificDataFetch = createAsyncThunk(
  'readSpecificDataSlice/asyncReadSpecificDataFetch',
  async (values, { rejectWithValue }) => {
    // values의 필수 값 => id, table
    const { id, table } = values;
    try {
      const { data } = useSupabase
        ? await supabase.from(table).select().eq('id', id)
        : await axiosInstance.get(`/api/dynamic-crud/${table}/${id}`);

      console.log(data);

      const result = data.filter((item) => item.state !== 9);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const readSpecificDataSlice = createSlice({
  name: 'readSpecificData',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncReadSpecificDataFetch.pending, (state) => {
      state.isLoadingReadSpecificData = true;
    });
    builder.addCase(asyncReadSpecificDataFetch.fulfilled, (state) => {
      state.isLoadingReadSpecificData = false;
      state.isSuccessReadSpecificData = true;
    });
    builder.addCase(asyncReadSpecificDataFetch.rejected, (state) => {
      state.isLoadingReadSpecificData = false;
      state.isErrorReadSpecificData = true;
    });
  },
});

export default readSpecificDataSlice.reducer;
export { asyncReadSpecificDataFetch };
