import { configureStore } from '@reduxjs/toolkit';
import modal from './modules/modal';
import darkMode from './modules/darkMode';
import pagenation from './modules/pagenation';

import createData from './modules/shared/createData';
import deleteData from './modules/shared/deleteData';
import readData from './modules/shared/readData';
import updateData from './modules/shared/updateData';
import readFilteredData from './modules/shared/readFilteredData';
import readRangeData from './modules/shared/readRangeData';
import readRangeOrData from './modules/shared/readRangeOrData';
import readBulkData from './modules/shared/readBulkData';
import readMatchData from './modules/shared/readMatchData';
import readMatchLteData from './modules/shared/readMatchLteData';
import readMatchBulkData from './modules/shared/readMatchBulkData';
import readLikeData from './modules/shared/readLikeData';
import readPaginatedData from './modules/shared/readPaginatedData';

// auth 관련
import signoutUser from './modules/auth/signoutUser';
import refreshSession from './modules/auth/refreshSession';
import createUser from './modules/auth/createUser';
import updateUser from './modules/auth/updateUser';
import resetPassword from './modules/auth/resetPassword';
import signupUser from './modules/auth/signupUser';
import signinUser from './modules/auth/signinUser';

const store = configureStore({
  reducer: {
    // 여기 밑에가 변경한 부분
    modal,
    darkMode,
    pagenation,
    signinUser,
    signoutUser,
    signupUser,
    createUser,
    updateUser,
    resetPassword,
    refreshSession,
    createData,
    deleteData,
    readData,
    updateData,
    readFilteredData,
    readRangeData,
    readBulkData,
    readMatchData,
    readMatchLteData,
    readMatchBulkData,
    readLikeData,
    readRangeOrData,
    readPaginatedData,
  },
  // 배포(production) 모드일 땐 redux-devTools가 브라우저에서 보이지 않게 해야함.
  // devTools: false,
});

export default store;
