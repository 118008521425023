import {
  AlertOutlined,
  BellOutlined,
  KeyOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Popover } from 'antd';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FaUserCog } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import useModal from '../../../hook/useModal';
import Avvvatar from '../../components/Avvvatar';

const ProfilePopover = (props) => {
  const { userInfo } = props;
  const { openModal } = useModal();
  const history = useHistory();

  const content = (
    <div style={{ width: '250px' }}>
      <div
        style={{
          width: '100%',
          borderBottom: '1px solid lightgray',
          padding: '10px',
          position: 'relative',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avvvatar username={userInfo.username} />
          <div style={{ marginLeft: '10px' }}>
            <span style={{ fontSize: '16px', fontWeight: '600' }}>
              {userInfo.username} 님
            </span>
          </div>
        </div>
        <div style={{ marginLeft: '5px', color: 'gray' }}>{userInfo.email}</div>
      </div>
      <ContentContainer>
        <Button
          style={{ color: '#3a3a3a' }}
          type="link"
          size="small"
          onClick={() => history.push('/profile')}
        >
          <IconContainer>
            <UserOutlined />
          </IconContainer>
          <span>프로필</span>
        </Button>
      </ContentContainer>
      {userInfo?.tier <= 1 && (
        <ContentContainer>
          <Button
            style={{ color: '#3a3a3a' }}
            type="link"
            size="small"
            onClick={() => history.push('/reset-password')}
          >
            <IconContainer>
              <KeyOutlined />
            </IconContainer>
            <span>비밀번호 재생성</span>
          </Button>
        </ContentContainer>
      )}
      <ContentContainer>
        <Button
          style={{ color: '#3a3a3a' }}
          type="link"
          size="small"
          onClick={() => openModal({ type: 'logout' })}
        >
          <IconContainer>
            <LogoutOutlined />
          </IconContainer>
          <span>로그아웃</span>
        </Button>
      </ContentContainer>
    </div>
  );

  return (
    <div>
      <Popover
        content={content}
        placement="bottomLeft"
        overlayInnerStyle={{
          padding: '0px',
          borderRadius: '5px',
        }}
      >
        <HoverIconStyle>
          <FaUserCog />
        </HoverIconStyle>
      </Popover>
    </div>
  );
};

export default ProfilePopover;

ProfilePopover.propTypes = {
  userInfo: PropTypes.instanceOf(Object).isRequired,
};

const HoverIconStyle = styled.div`
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  opacity: 1;
  :hover {
    opacity: 0.9;
  }
  border: 1px solid lightgray;
`;
const ContentContainer = styled.div`
  padding: 10px;
  width: 100%;
  :hover {
    background-color: #efefef;
  }
`;
const IconContainer = styled.span`
  margin-right: 15px;
  font-weight: 600;
`;
