import axios from 'axios';

const direction = () => {
  // 경,위도 구하기
  const getGeocode = async (address) => {
    // 경,위도 구하는 api호출
    const url = 'https://intosharp.pythonanywhere.com/geocode';
    const postData = { address };
    try {
      const response = await axios({
        method: 'POST',
        url,
        data: postData,
      });
      const parsedData = JSON.parse(response.data.result_data);
      const resultData = parsedData.addresses[0];
      const { x, y } = resultData;
      const result = `${x},${y}`;
      return result;
    } catch (err) {
      throw Error(err);
    }
  };
  // 경, 위도 구하기
  const getGeocodeForAddress = async (address) => {
    // 경,위도 구하는 api호출
    const url = 'https://intosharp.pythonanywhere.com/geocode';
    const postData = { address };
    try {
      const response = await axios({
        method: 'POST',
        url,
        data: postData,
      });
      const parsedData = JSON.parse(response.data.result_data);
      const resultData = parsedData.addresses[0];
      const { x, y } = resultData;
      const result = { longitude: x, latitude: y };
      return result;
    } catch (err) {
      throw Error(err);
    }
  };
  // 용달요금 구하기
  const getDlvyPrice = (distance) => {
    let price = 25000;
    if (distance >= 600000) {
      price = 300000;
    } else if (distance >= 550000) {
      price = 270000;
    } else if (distance >= 500000) {
      price = 250000;
    } else if (distance >= 450000) {
      price = 220000;
    } else if (distance >= 400000) {
      price = 200000;
    } else if (distance >= 350000) {
      price = 190000;
    } else if (distance >= 300000) {
      price = 170000;
    } else if (distance >= 250000) {
      price = 150000;
    } else if (distance >= 200000) {
      price = 130000;
    } else if (distance >= 170000) {
      price = 120000;
    } else if (distance >= 150000) {
      price = 110000;
    } else if (distance >= 130000) {
      price = 100000;
    } else if (distance >= 110000) {
      price = 90000;
    } else if (distance >= 90000) {
      price = 80000;
    } else if (distance >= 70000) {
      price = 70000;
    } else if (distance >= 50000) {
      price = 60000;
    } else if (distance >= 30000) {
      price = 50000;
    } else if (distance >= 20000) {
      price = 40000;
    } else if (distance >= 10000) {
      price = 30000;
    }
    return price;
  };

  const getDirection = async (start, goal) => {
    // 출발지와 목적지 경,위도 값 할당
    let startGeocode = '';
    if (start === '경기도 평택시 진위면 가곡리 126-1') {
      startGeocode = '127.0822877,37.1086106';
    } else {
      startGeocode = await getGeocode(start);
    }
    const goalGeocode = await getGeocode(goal);
    // 거리를 구하는 api호출
    const url = 'https://intosharp.pythonanywhere.com/direction';
    const postData = {
      start: startGeocode,
      goal: goalGeocode,
      option: 'traoptimal',
    };
    try {
      const response = await axios({
        method: 'POST',
        url,
        data: postData,
      });
      const parsedData = JSON.parse(response.data.result_data);
      const metersDistance = parsedData.route.traoptimal[0].summary.distance;
      // 용달요금을 구하는 api호출
      const dlvyPrice = getDlvyPrice(metersDistance);
      const distance = metersDistance / 1000;
      return { distance, dlvyPrice, startGeocode, goalGeocode };
    } catch (err) {
      throw Error(err);
    }
  };

  return { getDirection, getGeocodeForAddress };
};

export default direction;
