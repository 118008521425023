import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  isSuccessSignoutUser: false,
  isLoadingSignoutUser: false,
  isErrorSignoutUser: false,
};

const removeTokenFromLocalStorage = () => {
  localStorage.removeItem('token');
};

const asyncSignoutUserFetch = createAsyncThunk(
  'SignoutUserSlice/asyncSignoutUserFetch',
  async (_, { rejectWithValue }) => {
    try {
      // 토큰 제거
      removeTokenFromLocalStorage();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const signoutUserSlice = createSlice({
  name: 'signoutUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncSignoutUserFetch.pending, (state) => {
      state.isLoadingSignoutUser = true;
    });
    builder.addCase(asyncSignoutUserFetch.fulfilled, (state) => {
      state.isLoadingSignoutUser = false;
      state.isSuccessSignoutUser = true;
    });
    builder.addCase(asyncSignoutUserFetch.rejected, (state) => {
      state.isLoadingSignoutUser = false;
      state.isErrorSignoutUser = true;
    });
  },
});

export default signoutUserSlice.reducer;
export { asyncSignoutUserFetch };
