import { Switch } from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';

const StoreAutoSwitch = () => {
  const dispatch = useDispatch();

  const [isOn, setIsOn] = useState(false);
  const [switchId, setSwitchId] = useState(null);

  useEffect(() => {
    const getSwitchInfo = async () => {
      const { is_on: fetchedIsOn, id: fetchedSwitchId } = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'oms_switch',
          eqKey: 'category',
          eqValue: 'naver_store',
        })
      )
        .unwrap()
        .then((res) => res[0]);
      setIsOn(fetchedIsOn === 'Y');
      setSwitchId(fetchedSwitchId);
    };
    getSwitchInfo();
  }, []);

  const handleChangeValue = async (value) => {
    const res = await dispatch(
      asyncUpdateDataFetch({
        table: 'oms_switch',
        id: switchId,
        is_on: value ? 'Y' : 'N',
      })
    ).unwrap();
    if (res) {
      setIsOn(value);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '5px 10px',
        borderRadius: '5px',
        gap: '5px',
        border: '1px solid lightgray',
        width: 'fit-content',
      }}
    >
      <span>스토어 연동 ON/OFF</span>
      <Switch size="small" checked={isOn} onChange={handleChangeValue} />
    </div>
  );
};

export default StoreAutoSwitch;
