import React from 'react';
import { Button, Input } from 'antd';
import PropTypes from 'prop-types';

const ReceiverInput = ({
  receiverInfo,
  addReceiverInfo,
  handleReceiverInfo,
}) => {
  return (
    <div style={{ display: 'flex', gap: '5px' }}>
      <Input
        placeholder="수신번호 11자리를 입력해주세요. (하이픈(-) 제거.)"
        value={receiverInfo.mobile}
        onChange={(e) => handleReceiverInfo('mobile', e.target.value)}
      />
      <Button onClick={addReceiverInfo}>추가</Button>
    </div>
  );
};

export default ReceiverInput;

ReceiverInput.propTypes = {
  receiverInfo: PropTypes.instanceOf(Object).isRequired,
  addReceiverInfo: PropTypes.func.isRequired,
  handleReceiverInfo: PropTypes.func.isRequired,
};
