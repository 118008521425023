import axios from 'axios';
import axiosInstance from '../axios';
import isOwnServerApi from '../thirdPartyApiConfig';

// url 구분(pythonanywhere/자체서버) 생성
const getUrl = (category, isOwnServerApi) => {
  if (isOwnServerApi) {
    return `/api/third-party/${category}`;
  } else {
    return `https://intosharp.pythonanywhere.com/${category}`;
  }
};

// param url 구분(pythonanywhere/자체서버) 생성
const getParamUrl = (category, param, isOwnServerApi) => {
  if (isOwnServerApi) {
    return `/api/third-party/${category}/${param}`;
  } else {
    return `https://intosharp.pythonanywhere.com/${category}/${param}`;
  }
};

// response 구분(pythonanywhere/자체서버) 생성
const getResponse = async (url, postData, isOwnServerApi) => {
  if (isOwnServerApi) {
    return axiosInstance.post(url, postData);
  } else {
    return axios({ method: 'POST', url, data: postData });
  }
};

// result data 구분(pythonanywhere/자체서버) 생성
const getParsedData = (data) => {
  if (!isOwnServerApi) {
    return JSON.parse(data);
  }
  return data;
};

// 기능 작동 테스트용 프린트 함수
const printResultData = (category, resultData) => {
  console.log({ category, resultData });
};

export { getUrl, getParamUrl, getResponse, getParsedData, printResultData };
