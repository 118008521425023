import React, { useRef } from 'react';
import { Descriptions, Input } from 'antd';

import ReceiverField from './ReceiverField';
import SendTypeField from './SendTypeField';
import ContentField from './ContentField';
import useSmsForm from '../../hook/useSmsForm';
import InputFormField from './InputFormField';
import RadioFormField from './RadioFormField';
import useSmsSubmit from '../../hook/useSmsSubmit';
import SubmitButton from './SubmitButton';
import TemplateButtons from './TemplateButtons';

const commonItemProps = (label) => ({
  label,
  labelStyle: { fontSize: '15px', width: '10%' },
  contentStyle: { width: '90%' },
  span: 3,
});

const SmsForm = () => {
  const {
    values: formValues,
    blurs: formBlurs,
    errors: formErrors,
    handleChangeValue: handleChangeFormValue,
    handleChangeBlur: handleChangeFormBlur,
  } = useSmsForm();
  const { handleSmsSubmit, loadingInfo } = useSmsSubmit();

  // props로 내려줄 객체
  const radioFormFieldProps = (name) => ({
    name,
    value: formValues[name],
    onChange: handleChangeFormValue,
  });
  const inputFormFieldProps = (name) => ({
    name,
    value: formValues[name],
    error: formErrors[name],
    blur: formBlurs[name],
    onChange: handleChangeFormValue,
    onBlur: handleChangeFormBlur,
  });
  const sendTypeFieldProps = {
    values: {
      isReserveValue: formValues.isReserve,
      sendDateValue: formValues.sendDate,
      sendTimeValue: formValues.sendTime,
      contentTypeValue: formValues.contentType,
    },
    errors: {
      sendDateError: formErrors.sendDate,
      sendTimeError: formErrors.sendTime,
    },
    blur: formBlurs.isReserve,
    onChange: handleChangeFormValue,
    onBlur: handleChangeFormBlur,
  };

  const receiverFieldRef = useRef(null);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const receivers = receiverFieldRef.current?.receivers;
        handleSmsSubmit(formErrors, formValues, receivers);
      }}
    >
      <Descriptions bordered>
        <Descriptions.Item {...commonItemProps('타입')}>
          <RadioFormField {...radioFormFieldProps('type')} />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('컨텐츠 타입')}>
          <RadioFormField {...radioFormFieldProps('contentType')} />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('국가번호')}>
          <Input value="대한민국(+82)" disabled />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('발신번호')}>
          <InputFormField {...inputFormFieldProps('sender')} />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('템플릿')}>
          <TemplateButtons onChange={handleChangeFormValue} />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('제목')}>
          <InputFormField {...inputFormFieldProps('title')} />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('내용')}>
          <ContentField
            {...inputFormFieldProps('content')}
            type={formValues.type}
          />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('수신번호')}>
          <ReceiverField ref={receiverFieldRef} />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('발송 구분')}>
          <SendTypeField {...sendTypeFieldProps} />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('발송')}>
          <SubmitButton loadingInfo={loadingInfo} />
        </Descriptions.Item>
      </Descriptions>
    </form>
  );
};

export default SmsForm;
