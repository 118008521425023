import {
  EnvironmentFilled,
  GlobalOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Descriptions, Input, Modal, Select, Tag } from 'antd';
import React, { useState } from 'react';
import DaumPostcode from 'react-daum-postcode';
import PropTypes from 'prop-types';

import direction from '../../direction';

const AddressSearch = (props) => {
  const { setDlvyData, setGeocode, setLoading } = props;
  const { getDirection } = direction();
  const [isDisabledInput, setIsDisabledInput] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [address, setAddress] = useState({
    departure: '경기도 평택시 진위면 가곡리 126-1',
    destination: '',
  });

  const handleComplete = (data) => {
    if (modalType === 'departure') {
      setAddress((prev) => ({ ...prev, departure: data.address }));
    }
    if (modalType === 'destination') {
      setAddress((prev) => ({ ...prev, destination: data.address }));
    }
    setModalType('');
    setIsModalOpen(false);
  };

  const handleOpen = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setModalType('');
    setIsModalOpen(false);
  };

  const handleInput = (e, type) => {
    const { value } = e.target;
    if (type === 'departure') {
      setAddress((prev) => ({ ...prev, departure: value }));
    }
    if (type === 'destination') {
      setAddress((prev) => ({ ...prev, destination: value }));
    }
  };

  const handleClick = async () => {
    try {
      await setLoading(true);
      const result = await getDirection(address.departure, address.destination);
      const { distance, dlvyPrice, startGeocode, goalGeocode } = result;
      // 거리, 요금 데이터
      setDlvyData({ distance, dlvyPrice });
      // 위,경도 데이터
      const [startX, startY] = startGeocode.split(',');
      const [goalX, goalY] = goalGeocode.split(',');
      setGeocode({
        departure: { x: startX, y: startY },
        destination: { x: goalX, y: goalY },
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      alert('도착지를 다시 입력해주세요.');
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f7f7f7',
        padding: '30px',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          width: '100%',
          padding: '20px 50px',
          borderRadius: '5px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{ width: '100%', marginRight: '10px' }}
            onClick={() => setIsDisabledInput(false)}
          >
            <label style={{ fontSize: '15px', marginLeft: '5px' }}>
              출발지
            </label>
            <Input
              disabled={isDisabledInput}
              size="large"
              style={{
                borderRadius: '5px',
                border: '1px solid lightgray',
                marginTop: '5px',
              }}
              value={address.departure}
              onChange={(e) => handleInput(e, 'departure')}
              prefix={
                <Button
                  size="small"
                  style={{
                    marginRight: '10px',
                  }}
                  type="primary"
                  disabled={isDisabledInput}
                  onClick={() => handleOpen('departure')}
                >
                  <EnvironmentFilled />
                </Button>
              }
              placeholder="출발지를 입력해주세요."
            />
          </div>
          <div style={{ width: '100%', marginRight: '10px' }}>
            <label style={{ fontSize: '15px', marginLeft: '5px' }}>
              도착지
            </label>
            <Input
              size="large"
              style={{
                borderRadius: '5px',
                border: '1px solid lightgray',
                marginTop: '5px',
              }}
              value={address.destination}
              onChange={(e) => handleInput(e, 'destination')}
              prefix={
                <Button
                  size="small"
                  style={{
                    marginRight: '10px',
                  }}
                  type="primary"
                  onClick={() => handleOpen('destination')}
                >
                  <EnvironmentFilled />
                </Button>
              }
              placeholder="도착지를 입력해주세요."
            />
          </div>
          <Button
            type="primary"
            onClick={handleClick}
            style={{ width: '100px', height: '40px', marginTop: '22px' }}
          >
            <SearchOutlined />
            검색
          </Button>
        </div>
        {isModalOpen && (
          <Modal
            onCancel={handleCancel}
            open
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            centered
          >
            <DaumPostcode
              onComplete={(data) => handleComplete(data)}
              style={{ margin: '30px 0' }}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default AddressSearch;

AddressSearch.propTypes = {
  setDlvyData: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setGeocode: PropTypes.func.isRequired,
};
