import React from 'react';
import { useDispatch } from 'react-redux';

import { asyncSigninUserFetch } from '../../redux/modules/auth/signinUser';
import Center from '../../shared/components/Center';
import AuthContainer from '../components/AuthContainer';
import AuthFooter from '../components/AuthFooter';
import LoginHeader from '../components/LoginHeader';
import LoginForm from '../components/LoginForm';

const Login = () => {
  const dispatch = useDispatch();

  const handleLogin = async (values) => {
    try {
      await dispatch(asyncSigninUserFetch(values)).unwrap();
    } catch (error) {
      alert(error);
      throw error;
    }
  };

  return (
    <Center>
      <AuthContainer>
        <LoginHeader />
        <LoginForm handleLogin={handleLogin} />
      </AuthContainer>
      <AuthFooter />
    </Center>
  );
};

export default Login;
