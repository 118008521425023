import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Select, Form, Space, Popover } from 'antd';
import {
  ReloadOutlined,
  LoadingOutlined,
  SearchOutlined,
  PlusOutlined,
  FilterOutlined,
  UnorderedListOutlined,
  DatabaseOutlined,
  DatabaseFilled,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import useModal from '../../hook/useModal';
import {
  LIGHT_MODE_FONT_COLOR,
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  LIGHT_MODE_BACKGROUND,
} from '../../constants';

const TableHeader = (props) => {
  const {
    onFinish,
    refreshDatas,
    createButtonText,
    createButtonModal,
    tableColumns,
    checkedTableColumns,
    setCheckedTableColumns,
    sortLatestDatas,
    sortOldestDatas,
    isLatest,
    items,
    tableDatas,
    loading,
  } = props;
  const { isLoadingReadData } = useSelector((state) => state.readData);
  const { darkMode } = useSelector((state) => state.darkMode);
  const { openModal } = useModal();
  const history = useHistory();
  // 검색 옵션
  const [type, setType] = useState('all');
  const [checkedAll, setCheckedAll] = useState(true);

  const defaultColumns = useMemo(() => {
    let defaultColumnsArr = [];
    tableColumns.forEach((tableColumn) => {
      if (
        tableColumn.key === 'button' ||
        tableColumn.key === 'code' ||
        tableColumn.key === 'name' ||
        tableColumn.key === 'username' ||
        tableColumn.key === 'avatar'
      ) {
        defaultColumnsArr.push(tableColumn.title);
      }
    });
    return [...defaultColumnsArr];
  }, [tableColumns]);
  const allColumns = useMemo(() => {
    let allColumnsArr = [];
    tableColumns.forEach((tableColumn) => {
      allColumnsArr.push(tableColumn.title);
    });
    return [...allColumnsArr];
  }, [tableColumns]);

  useEffect(() => {
    setCheckedTableColumns([...allColumns]);
  }, [allColumns, setCheckedTableColumns]);
  useEffect(() => {
    setCheckedAll(allColumns.length === checkedTableColumns.length);
  }, [allColumns, checkedTableColumns]);

  // 필터링 전체 체크
  const onChangeAll = useCallback(
    (e) => {
      const { checked } = e.target;
      if (checked) {
        setCheckedAll(true);
        setCheckedTableColumns([...allColumns]);
      } else {
        setCheckedAll(false);
        setCheckedTableColumns([...defaultColumns]);
      }
    },
    [allColumns, defaultColumns, setCheckedTableColumns]
  );
  // 필터링 개별 체크
  const onChangeEach = useCallback(
    (e) => {
      const { value } = e.target;
      const { checked } = e.target;
      if (checked) {
        setCheckedTableColumns((prevState) => {
          if (prevState.length !== 0) {
            return [...prevState, value];
          }
        });
      } else {
        setCheckedTableColumns((prevState) => {
          if (prevState.length !== 0) {
            const filteredTableColumns = prevState.filter((tableColumn) => {
              return tableColumn !== value;
            });
            return [...filteredTableColumns];
          }
        });
      }
    },
    [setCheckedTableColumns]
  );

  const content = useMemo(
    () => (
      <div>
        <div style={{ margin: '5px 0' }}>
          <input type="checkbox" onChange={onChangeAll} checked={checkedAll} />
          전체
        </div>
        <div>
          {tableColumns.map((tableColumn, index) => {
            if (tableColumn.key === 'button') {
              return;
            }
            return (
              <div key={index}>
                <input
                  type="checkbox"
                  value={tableColumn.title}
                  onChange={onChangeEach}
                  disabled={
                    tableColumn.key === 'code' ||
                    tableColumn.key === 'name' ||
                    tableColumn.key === 'button' ||
                    tableColumn.key === 'username' ||
                    tableColumn.key === 'avatar'
                  }
                  checked={checkedTableColumns.includes(tableColumn.title)}
                />
                {tableColumn.title}
              </div>
            );
          })}
        </div>
      </div>
    ),
    [checkedAll, checkedTableColumns, onChangeAll, onChangeEach, tableColumns]
  );

  // 추가 버튼 텍스트
  const createButtonRouter = useCallback(() => {
    if (createButtonText === '거래처 추가') {
      return (
        <Button
          type={darkMode ? 'primary' : 'default'}
          style={{
            width: '183.28px',
            borderRadius: '0px',
            backgroundColor: !darkMode && '#f7f7f7',
          }}
          onClick={() => history.push('/account/createAccount')}
        >
          <DatabaseFilled style={{ fontSize: '16px' }} />
          <PlusOutlined
            style={{
              fontSize: '12.5px',
              position: 'absolute',
              top: '5px',
              marginLeft: '5px',
            }}
          />
        </Button>
      );
    } else if (createButtonText === '품목 추가') {
      return (
        <Button
          type={darkMode ? 'primary' : 'default'}
          style={{
            width: '183.28px',
            borderRadius: '0px',
            backgroundColor: !darkMode && '#f7f7f7',
          }}
          onClick={() => history.push('/product/createProduct')}
        >
          <DatabaseFilled style={{ fontSize: '16px' }} />
          <PlusOutlined
            style={{
              fontSize: '12.5px',
              position: 'absolute',
              top: '5px',
              marginLeft: '5px',
            }}
          />
        </Button>
      );
    } else if (createButtonText === '판매상품 추가') {
      return (
        <Button
          type={darkMode ? 'primary' : 'default'}
          style={{
            width: '183.28px',
            borderRadius: '0px',
            backgroundColor: !darkMode && '#f7f7f7',
          }}
          onClick={() => history.push('/sale/createSale')}
        >
          <DatabaseFilled style={{ fontSize: '16px' }} />
          <PlusOutlined
            style={{
              fontSize: '12.5px',
              position: 'absolute',
              top: '5px',
              marginLeft: '5px',
            }}
          />
        </Button>
      );
    } else if (createButtonText === '주문서 추가') {
      return (
        <Button
          type={darkMode ? 'primary' : 'default'}
          style={{
            width: '183.28px',
            borderRadius: '0px',
            backgroundColor: !darkMode && '#f7f7f7',
          }}
          onClick={() => history.push('/order/createOrder')}
        >
          <DatabaseFilled style={{ fontSize: '16px' }} />
          <PlusOutlined
            style={{
              fontSize: '12.5px',
              position: 'absolute',
              top: '5px',
              marginLeft: '5px',
            }}
          />
        </Button>
      );
    } else if (createButtonText === '발주 추가') {
      return (
        <Button
          type={darkMode ? 'primary' : 'default'}
          style={{
            width: '183.28px',
            borderRadius: '0px',
            backgroundColor: !darkMode && '#f7f7f7',
          }}
          onClick={() => history.push('/purchase-order/create')}
        >
          <DatabaseFilled style={{ fontSize: '16px' }} />
          <PlusOutlined
            style={{
              fontSize: '12.5px',
              position: 'absolute',
              top: '5px',
              marginLeft: '5px',
            }}
          />
        </Button>
      );
    } else if (
      createButtonText === '판매조회 추가' ||
      createButtonText === '거래처할인 추가' ||
      createButtonText === '견적서 추가' ||
      createButtonText === '구매 추가'
    ) {
      return (
        <Button
          type={darkMode ? 'primary' : 'default'}
          style={{ width: '183.28px', borderRadius: '0px' }}
          disabled
        >
          <DatabaseFilled style={{ fontSize: '16px' }} />
          <PlusOutlined
            style={{
              fontSize: '12.5px',
              position: 'absolute',
              top: '5px',
              marginLeft: '5px',
            }}
          />
        </Button>
      );
    } else {
      return (
        <Button
          type={darkMode ? 'primary' : 'default'}
          style={{
            width: '183.28px',
            borderRadius: '0px',
            backgroundColor: !darkMode && '#f7f7f7',
          }}
          onClick={() => openModal(createButtonModal)}
        >
          <DatabaseFilled style={{ fontSize: '16px' }} />
          <PlusOutlined
            style={{
              fontSize: '12.5px',
              position: 'absolute',
              top: '5px',
              marginLeft: '5px',
            }}
          />
        </Button>
      );
    }
  }, [history, createButtonText, createButtonModal, openModal]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '20px 30px',
        borderBottom: '1px solid #efefef',
        position: 'sticky',
        top: '0',
        zIndex: '1',
      }}
    >
      <Space.Compact>
        <Popover
          placement="bottom"
          content={
            <div>
              총 <span style={{ color: '#ff0000' }}>{tableDatas.length}</span>
              개의 데이터
            </div>
          }
        >
          <ButtonStyle darkMode={darkMode}>
            <DatabaseOutlined />
          </ButtonStyle>
        </Popover>
        <Popover title="항목 선택" placement="bottom" content={content}>
          <ButtonStyle darkMode={darkMode}>
            <FilterOutlined />
          </ButtonStyle>
        </Popover>
        <Popover
          placement="bottom"
          content={
            <div style={{ padding: '5px', fontWeight: '600' }}>
              {isLatest ? '현재: 최신순' : '현재: 오래된순'}
            </div>
          }
        >
          <ButtonStyle
            onClick={isLatest ? sortOldestDatas : sortLatestDatas}
            darkMode={darkMode}
          >
            <UnorderedListOutlined />
          </ButtonStyle>
        </Popover>
        <Popover
          placement="bottom"
          content={
            <div style={{ padding: '5px', fontWeight: '600' }}>새로고침</div>
          }
        >
          <ButtonStyle onClick={() => refreshDatas()} darkMode={darkMode}>
            {isLoadingReadData ? <LoadingOutlined /> : <ReloadOutlined />}
          </ButtonStyle>
        </Popover>
      </Space.Compact>
      <Form
        onFinish={onFinish}
        initialValues={{ type }}
        style={{ width: '75%', display: 'flex' }}
      >
        <Space.Compact block>
          <Form.Item name="type" noStyle>
            <Select
              onChange={(e) => setType(e)}
              className={[
                'table-header-select',
                darkMode ? 'is-dark-mode' : 'is-light-mode',
              ]}
            >
              <Select.Option value="all">전체 / All</Select.Option>
              {items.map((item, index) => {
                if (item.key === 'button') {
                  return;
                }
                return (
                  <Select.Option key={index} value={item.key}>
                    {item.title}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="word" noStyle>
            <Input
              placeholder="검색할 내용을 입력해주세요. *검색시 날짜 범위는 무시됩니다."
              style={{ borderRadius: '0px' }}
            />
          </Form.Item>
          <Form.Item noStyle>
            <Button
              htmlType="submit"
              style={{
                borderRadius: '0px',
                backgroundColor: darkMode
                  ? DARK_MODE_BACKGROUND
                  : LIGHT_MODE_BACKGROUND,
                color: darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR,
                width: '150px',
              }}
              loading={loading}
            >
              <SearchOutlined />
              검색
            </Button>
          </Form.Item>
        </Space.Compact>
      </Form>
      <Popover
        placement="bottom"
        content={
          window.location.pathname === '/estimate' ||
          window.location.pathname === '/sales-inquiry' ||
          window.location.pathname === '/account-discount' ? (
            <div style={{ padding: '5px', fontWeight: '600' }}>
              데이터를 추가할 수 없습니다.
            </div>
          ) : (
            <div style={{ padding: '5px', fontWeight: '600' }}>데이터 추가</div>
          )
        }
      >
        {createButtonRouter()}
      </Popover>
    </div>
  );
};

export default React.memo(TableHeader);

TableHeader.propTypes = {
  onFinish: PropTypes.func.isRequired,
  refreshDatas: PropTypes.func.isRequired,
  sortLatestDatas: PropTypes.func.isRequired,
  sortOldestDatas: PropTypes.func.isRequired,
  createButtonText: PropTypes.string.isRequired,
  createButtonModal: PropTypes.instanceOf(Object),
  tableColumns: PropTypes.instanceOf(Array).isRequired,
  setCheckedTableColumns: PropTypes.func.isRequired,
  checkedTableColumns: PropTypes.instanceOf(Array).isRequired,
  isLatest: PropTypes.bool.isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
  tableDatas: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool.isRequired,
};

const ButtonStyle = styled(Button)`
  border-radius: 0px;
  background-color: ${(props) =>
    props.darkMode ? DARK_MODE_BACKGROUND : LIGHT_MODE_BACKGROUND};
  color: ${(props) =>
    props.darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR};
`;
