import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Table } from 'antd';
import AllDeleteButton from './AllDeleteButton';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const OrderSummary = (props) => {
  const { data, setErrorDatas } = props;
  const dispatch = useDispatch();
  const [summary, setSummary] = useState({
    totalSuccess: 0,
    totalFail: 0,
    totalQuantity: 0,
    totalAmount: 0,
  });

  useEffect(() => {
    const getSummary = async () => {
      let totalSuccess = 0;
      let totalFail = 0;
      let totalQuantity = 0;
      let totalAmount = 0;
      data.forEach((item) => {
        totalSuccess += item.success_count;
        totalFail += item.fail_count;
      });
      setSummary((prev) => ({ ...prev, totalSuccess, totalFail }));
      try {
        // 총액
        const orderIds = data.map((item) => item.target_id);
        const quantityAndAmount = await Promise.all(
          orderIds.map(async (orderId) => {
            const result = await dispatch(
              asyncReadFilteredDataFetch({
                table: 'order_product',
                eqKey: 'order_id',
                eqValue: orderId,
              })
            )
              .unwrap()
              .then((res) => {
                let quantity = 0;
                let amount = 0;
                res.forEach((item) => {
                  quantity += item.product_quantity;
                  amount += item.discounted;
                });
                return { quantity, amount };
              });
            return result;
          })
        );
        quantityAndAmount.forEach((item) => {
          totalQuantity += item.quantity;
          totalAmount += item.amount;
        });
        setSummary((prev) => ({ ...prev, totalQuantity, totalAmount }));
      } catch (error) {
        console.log(error);
      }
    };
    getSummary();
  }, [data, dispatch]);

  return (
    <Table.Summary.Row
      style={{
        textAlign: 'center',
        backgroundColor: 'black',
        color: 'gray',
      }}
    >
      <Table.Summary.Cell />
      <Table.Summary.Cell />
      <Table.Summary.Cell />
      <Table.Summary.Cell />
      <Table.Summary.Cell>{summary.totalSuccess}</Table.Summary.Cell>
      <Table.Summary.Cell>{summary.totalFail}</Table.Summary.Cell>
      <Table.Summary.Cell>
        {summary.totalQuantity}개 / {summary.totalAmount.toLocaleString()}원
      </Table.Summary.Cell>
      <Table.Summary.Cell>
        <AllDeleteButton
          ids={data.map((item) => item.id)}
          table="주문"
          setErrorDatas={setErrorDatas}
        />
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};

export default OrderSummary;

OrderSummary.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  setErrorDatas: PropTypes.func.isRequired,
};
