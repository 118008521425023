import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import styled from 'styled-components';
import OrderDetail from './OrderDetail';
import AccountDetail from './AccountDetail';
import DeleteButton from './DeleteButton';
import OrderSummary from './OrderSummary';

const ErrorTable = (props) => {
  const { errorDatas, setErrorDatas } = props;
  // 컬럼
  const column = [
    { key: 1, title: '번호', dataIndex: 'id', align: 'center', width: '5%' },
    {
      key: 2,
      title: '에러',
      dataIndex: 'target_table',
      align: 'center',
      width: '5%',
    },
    {
      key: 3,
      title: '메시지',
      dataIndex: 'message',
      align: 'center',
      width: '20%',
    },
    {
      key: 4,
      title: '발생시간',
      dataIndex: 'timestamp',
      align: 'center',
      width: '20%',
    },
    {
      key: 5,
      title: '성공',
      dataIndex: 'success_count',
      align: 'center',
      width: '5%',
    },
    {
      key: 6,
      title: '실패',
      dataIndex: 'fail_count',
      align: 'center',
      width: '5%',
    },
    {
      key: 7,
      title: '상세정보',
      dataIndex: 'target_id',
      align: 'center',
      width: '35%',
      render: (value, data) => {
        if (data.target_table === '판매' || data.target_table === '주문') {
          return <OrderDetail value={value} />;
        }
        if (data.target_table === '거래처') {
          return <AccountDetail value={value} />;
        }
      },
    },
    {
      key: 8,
      title: '처리',
      dataIndex: '',
      align: 'center',
      width: '5%',
      render: (_, data) => (
        <DeleteButton
          id={data.id}
          table={data.target_table}
          setErrorDatas={setErrorDatas}
        />
      ),
    },
  ];

  return (
    <div>
      {/* 주문 */}
      <TableTitle>주문</TableTitle>
      <Table
        dataSource={errorDatas.order}
        columns={column}
        pagination={false}
        bordered
        className="error-table"
        summary={(data) => (
          <OrderSummary data={data} setErrorDatas={setErrorDatas} />
        )}
      />
      {/* 판매 */}
      <TableTitle>판매</TableTitle>
      <Table
        dataSource={errorDatas.sales}
        columns={column}
        pagination={false}
        bordered
        className="error-table"
        summary={(data) => (
          <OrderSummary data={data} setErrorDatas={setErrorDatas} />
        )}
      />
      {/* 거래처 */}
      <TableTitle>거래처</TableTitle>
      <Table
        dataSource={errorDatas.account}
        columns={column}
        pagination={false}
        bordered
        className="error-table"
      />
      {/* 품목 */}
      <TableTitle>품목</TableTitle>
      <Table
        // dataSource={errorDatas.account}
        columns={column}
        pagination={false}
        bordered
        className="error-table"
      />
      {/* 구매 */}
      <TableTitle>구매</TableTitle>
      <Table
        // dataSource={errorDatas.account}
        columns={column}
        pagination={false}
        bordered
        className="error-table"
      />
    </div>
  );
};

export default ErrorTable;

ErrorTable.propTypes = {
  errorDatas: PropTypes.instanceOf(Object).isRequired,
  setErrorDatas: PropTypes.func.isRequired,
};

const TableTitle = styled.div`
  background-color: black;
  color: white;
  padding: 15px 25px;
  margin: 1px 0 0 1px;
`;
