import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import supabase from '../../../supabase';

const initialState = {
  isSuccessUpdateUser: false,
  isLoadingUpdateUser: false,
  isErrorUpdateUser: false,
};

const asyncUpdateUserFetch = createAsyncThunk(
  'updateUserSlice/asyncUpdateUserFetch',
  async (values, { rejectWithValue }) => {
    try {
      const { data, error } = await supabase.auth.updateUser({
        password: values.password,
      });
      if (error) throw error;
      if (data) {
        return { message: '다시 로그인 해주세요.' };
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updateUserSlice = createSlice({
  name: 'updateUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncUpdateUserFetch.pending, (state) => {
      state.isLoadingUpdateUser = true;
    });
    builder.addCase(asyncUpdateUserFetch.fulfilled, (state) => {
      state.isLoadingUpdateUser = false;
      state.isSuccessUpdateUser = true;
    });
    builder.addCase(asyncUpdateUserFetch.rejected, (state) => {
      state.isLoadingUpdateUser = false;
      state.isErrorUpdateUser = true;
    });
  },
});

export default updateUserSlice.reducer;
export { asyncUpdateUserFetch };
