import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Space } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';

const EditablePrice = (props) => {
  const { value, setOrderProductDatas, pageType, isDc } = props;

  const [isInput, setIsInput] = useState(false);
  const [priceInput, setPriceInput] = useState(0);

  useEffect(() => {
    const price = value.discounted_price;
    setPriceInput(price);
  }, [value]);

  const handlePrice = (data) => {
    const { discount_rate, product_quantity } = data;

    const price = Number(priceInput);
    const quantity = product_quantity;
    const supply = price * quantity;
    const vat = supply / 10;
    const total = supply + vat;
    const dcPrice = price - (price * discount_rate) / 100;
    const dcSupply = supply - (supply * discount_rate) / 100;
    const dcVat = vat - (vat * discount_rate) / 100;
    const discount = (total * discount_rate) / 100;
    const discounted = total - discount;

    setOrderProductDatas((prev) => {
      const editedDatas = prev.map((prevData) => {
        if (prevData === data) {
          return {
            ...prevData,
            product_price: Math.round(price),
            product_quantity: Math.round(quantity),
            product_supply_price: Math.round(supply),
            product_VAT_price: Math.round(vat),
            discount: Math.round(discount),
            discounted: Math.round(discounted),
            discounted_supply: Math.round(dcSupply),
            discounted_vat: Math.round(dcVat),
            discounted_price: Math.round(dcPrice),
          };
        }
        return { ...prevData };
      });
      return [...editedDatas];
    });
    setIsInput((prev) => !prev);
  };

  const inputRef = useRef(null);

  return (
    <div>
      {!isInput && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            position: 'relative',
          }}
        >
          <Button
            type="link"
            size="small"
            style={{
              height: '23px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0px',
            }}
            onClick={() => {
              if (value.discount_rate && value.discount_rate > 0) {
                alert('할인중인 상품의 단가를 변경할 수 없습니다.');
                return;
              }
              setIsInput((prev) => !prev);
              inputRef.current.focus();
            }}
            disabled={pageType === 'detail'}
          >
            {isDc && <div>{value.discounted_price.toLocaleString()}원</div>}
            {!isDc && <div>{value.product_price.toLocaleString()}원</div>}
          </Button>
        </div>
      )}
      {isInput && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            position: 'relative',
          }}
        >
          <Input
            style={{ width: '100%', borderRadius: '0px', marginBottom: '5px' }}
            value={priceInput}
            type="number"
            onChange={(e) => setPriceInput(e.target.value)}
            ref={inputRef}
            suffix={
              <Space.Compact>
                <Button
                  size="small"
                  style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '0px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => handlePrice(value)}
                  type="primary"
                >
                  <CheckOutlined style={{ fontSize: '10px' }} />
                </Button>
                <Button
                  size="small"
                  style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '0px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setIsInput((prev) => !prev);
                    setPriceInput(value.product_price);
                  }}
                  type="primary"
                  danger
                >
                  <CloseOutlined style={{ fontSize: '10px' }} />
                </Button>
              </Space.Compact>
            }
          />
        </div>
      )}
    </div>
  );
};

export default EditablePrice;

EditablePrice.propTypes = {
  value: PropTypes.instanceOf(Object).isRequired,
  setOrderProductDatas: PropTypes.func.isRequired,
  pageType: PropTypes.string,
  isDc: PropTypes.bool.isRequired,
};
