import { Modal, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import AddDiscount from './AddDiscount';
import ProductDiscountInfo from './ProductDiscountInfo';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const DiscountApplicationModal = (props) => {
  const { setIsOpenModal, accountId, accountCode, accountName } = props;

  const dispatch = useDispatch();

  const [dcProductDatas, setDcProductDatas] = useState([]);
  const [dcProductDatasLoading, setDcProductDatasLoading] = useState(false);

  useEffect(() => {
    const getDcProductDatas = async () => {
      try {
        await setDcProductDatasLoading(true);
        const discountProductDatas = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'discount_account_product',
            eqKey: 'account_id',
            eqValue: accountId,
          })
        )
          .unwrap()
          .then((res) => res);
        if (discountProductDatas.length <= 0) {
          setDcProductDatasLoading(false);
          return;
        } else {
          const productDatas = await Promise.all(
            discountProductDatas.map(async (data) => {
              const { discounted_release_price, is_on } = data;
              const response = await dispatch(
                asyncReadFilteredDataFetch({
                  table: 'product',
                  eqKey: 'id',
                  eqValue: data.product_id,
                })
              )
                .unwrap()
                .then((res) => res[0]);
              const { name, oms_code, code, release_price, id } = response;
              return {
                name,
                oms_code,
                code,
                release_price,
                discounted_release_price,
                is_on,
                product_id: id,
                account_id: accountId,
              };
            })
          );
          await setDcProductDatas([...productDatas]);
          setDcProductDatasLoading(false);
        }
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };
    getDcProductDatas();
  }, [dispatch, accountId]);

  return (
    <Modal
      centered
      open
      onCancel={() => setIsOpenModal(false)}
      footer={null}
      title="거래처할인"
      width={550}
    >
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            marginBottom: '5px',
            fontSize: '15px',
            fontWeight: '500',
            width: 'fit-content',
            color: 'gray',
          }}
        >
          {accountCode} {accountName}
        </div>
      </div>
      <ProductDiscountInfo
        dcProductDatas={dcProductDatas}
        setDcProductDatas={setDcProductDatas}
        dcProductDatasLoading={dcProductDatasLoading}
        accountId={accountId}
      />
      <div
        style={{
          width: '100%',
          margin: '15px 0',
        }}
      />
      <AddDiscount
        setDcProductDatas={setDcProductDatas}
        accountId={accountId}
        accountName={accountName}
      />
    </Modal>
  );
};

export default DiscountApplicationModal;

DiscountApplicationModal.propTypes = {
  setIsOpenModal: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  accountCode: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired,
};
