import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import ProductCodeOrName from './ProductCodeOrName';
import StockValue from './StockValue';
import useSortOrderSetting from '../../hook/useSortOrderSetting';

const InventoryTable = (props) => {
  const { tableDatas, setTableDatas, formData, isLoading } = props;
  const [column, setColumn] = useState([]);

  useEffect(() => {
    const { type, warehouse } = formData;
    const productColumn = [
      {
        title: '품목코드',
        dataIndex: 'product_id',
        key: 'product_code',
        width: '15%',
        render: (value) => (
          <ProductCodeOrName
            productId={value}
            columnName="productCode"
            setTableDatas={setTableDatas}
          />
        ),
        align: 'center',
      },
      {
        title: '품목명 [규격]',
        dataIndex: 'product_id',
        key: 'product_name',
        width: '35%',
        render: (value) => (
          <ProductCodeOrName productId={value} columnName="productName" />
        ),
        align: 'center',
      },
    ];
    if (type === 'status') {
      const stackQtyColumn = {
        title: '재고수량',
        dataIndex: 'stack_quantity',
        key: 'stack_quantity',
        align: 'center',
        width: `${50 / 3}%`,
      };
      const unreceivedColumn = {
        title: '미입고',
        dataIndex: 'unreceived_quantity',
        align: 'center',
        width: `${50 / 3}%`,
      };
      const unsoldColumn = {
        title: '미판매',
        dataIndex: 'unsold_quantity',
        align: 'center',
        width: `${50 / 3}%`,
      };
      const resultColumn = [
        ...productColumn,
        stackQtyColumn,
        unreceivedColumn,
        unsoldColumn,
      ];
      setColumn(resultColumn);
    } else if (type === 'statusByWarehouse') {
      const warehouseColumn = warehouse.map((data) => ({
        title: data.label,
        dataIndex: data.value,
        key: data.value,
        align: 'center',
        width: `${50 / warehouse.length}%`,
      }));
      const resultColumn = [...productColumn, ...warehouseColumn];
      setColumn(resultColumn);
    } else {
      const warehouseColumn = warehouse.map((data) => ({
        title: data.label,
        dataIndex: data.value,
        key: data.value,
        align: 'center',
        width: `${50 / warehouse.length}%`,
        render: (value, record) => (
          <StockValue
            warehouseId={data.value}
            productId={record.product_id}
            stock={value}
          />
        ),
      }));
      const resultColumn = [...productColumn, ...warehouseColumn];
      setColumn(resultColumn);
    }
  }, [formData, setTableDatas]);

  return (
    <div style={{ marginTop: '50px' }}>
      <div
        style={{
          fontSize: '16px',
          borderBottom: '1px solid gray',
          paddingBottom: '10px',
        }}
      >
        {formData.type === 'status' && '재고현황'}
        {formData.type === 'statusByWarehouse' && '창고별재고현황'}
        {formData.type === 'statusBySafetyStock' && '창고별+안전재고현황'}
      </div>
      <Table
        bordered
        dataSource={useSortOrderSetting(tableDatas)}
        columns={column}
        pagination={false}
        loading={isLoading}
        /*
        summary={(data) => {
          let inventoryQuantity = 0;
          let unreceivedQuantity = 0;
          let unsoldQuantity = 0;
          data.forEach(
            ({ stack_quantity, unreceived_quantity, unsold_quantity }) => {
              inventoryQuantity += stack_quantity;
              unreceivedQuantity += unreceived_quantity;
              unsoldQuantity += unsold_quantity;
            }
          );
          return (
            <Table.Summary.Row
              style={{
                backgroundColor: '#fafafa',
                textAlign: 'center',
                fontWeight: '600',
              }}
            >
              <Table.Summary.Cell />
              <Table.Summary.Cell />
              <Table.Summary.Cell>
                {inventoryQuantity.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {unreceivedQuantity.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {unsoldQuantity.toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
        */
      />
    </div>
  );
};

export default InventoryTable;

InventoryTable.propTypes = {
  tableDatas: PropTypes.instanceOf(Array).isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
  setTableDatas: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
