import { Empty, Table } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import useSmsHistoryTable from '../../hook/useSmsHistoryTable';

const SmsHistoryTable = ({ historyData, loading }) => {
  const { tableColumns } = useSmsHistoryTable();

  return (
    <div>
      <Table
        columns={tableColumns}
        dataSource={historyData}
        loading={loading.loading}
        locale={{ emptyText: <Empty description="데이터가 없습니다." /> }}
      />
      {historyData.length > 0 && <span>{historyData.length} 건</span>}
    </div>
  );
};

export default SmsHistoryTable;

SmsHistoryTable.propTypes = {
  historyData: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.instanceOf(Object).isRequired,
};
