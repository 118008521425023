import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ecount from '../../../ecount';
import TableModal from './TableModal';
import useModal from '../../../hook/useModal';
import { asyncCreateDataFetch } from '../../../redux/modules/shared/createData';
import { asyncCreateUserFetch } from '../../../redux/modules/auth/createUser';
import { asyncReadDataFetch } from '../../../redux/modules/shared/readData';

const CreateTable = (props) => {
  const { items } = props;
  const dispatch = useDispatch();
  const { dataInfo, modalInfo } = useSelector((state) => state.modal);
  const { openModal, closeModal } = useModal();
  // const { saveProduct } = ecount();

  const onFinish = useCallback(
    (values) => {
      const datas = {
        table: dataInfo.dataTable,
        state: 0,
        ...values,
      };
      // 테이블이 manager일 때 row추가
      if (modalInfo.modalTitle === '관리자 추가') {
        dispatch(asyncCreateUserFetch(datas))
          .unwrap()
          .then(() => {
            closeModal();
            // result 모달을 띄움.
            openModal({
              type: 'result',
              modalInfo: {
                modalTitle: modalInfo.modalTitle,
                resultStatus: 'success',
                resultTitle: modalInfo.modalTitle,
                resultSubTitle: `데이터에 ${modalInfo.modalTitle} 작업을 완료 했습니다.`,
                buttonText: '확인',
              },
            });
          })
          .catch((err) => {
            console.log(err);
            alert(err.message);
          });
        return;
      }
      // 그 외 테이블에 row추가
      dispatch(asyncCreateDataFetch(datas))
        .unwrap()
        .then(() => {
          closeModal();
          // result 모달을 띄움.
          openModal({
            type: 'result',
            modalInfo: {
              modalTitle: modalInfo.modalTitle,
              resultStatus: 'success',
              resultTitle: modalInfo.modalTitle,
              resultSubTitle: `데이터에 ${modalInfo.modalTitle} 작업을 완료 했습니다.`,
              buttonText: '확인',
            },
          });
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    },
    [closeModal, dataInfo.dataTable, dispatch, openModal, modalInfo.modalTitle]
  );

  return <TableModal items={items} buttonText="추가" onFinish={onFinish} />;
};

export default React.memo(CreateTable);

CreateTable.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};
