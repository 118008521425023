import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../axios';
import useSupabase from '../../../serverConfig';

import supabase from '../../../supabase';

// 등록일 (reg_date) 기준 내림차순으로 정렬
const handleSortData = (arr) => {
  const sortedArr = arr.sort((a, b) => {
    return new Date(b.reg_date) - new Date(a.reg_date);
  });
  return sortedArr;
};

const initialState = {
  isLoadingReadRangeData: false,
  isErrorReadRangeData: false,
  isSuccessReadRangeData: false,
};

const asyncReadRangeDataFetch = createAsyncThunk(
  'readRangeDataSlice/asyncReadRangeDataFetch',
  async (values, { rejectWithValue }) => {
    // values의 필수 값 => table
    const { table, column, startPt, endPt } = values;
    try {
      const isSalesInquiryDatas =
        table === 'order' && window.location.pathname === '/sales-inquiry';
      const isOrderDatas =
        table === 'order' && window.location.pathname === '/order';
      if (isSalesInquiryDatas) {
        const { data, error } = useSupabase
          ? await supabase
              .from(table)
              .select('*')
              .gte(column, startPt)
              .lte(column, endPt)
              .eq('payment_status', '1')
          : await axiosInstance.get(
              `/api/dynamic-crud/${table}/range/1?lteKey=${column}&lteValue=${endPt}&gteKey=${column}&gteValue=${startPt}`
            );

        // payment_status가 '1'인 값 필터링 !
        console.log(data);

        if (error) throw error;
        const result = data.filter((item) => item.state !== 9);
        return handleSortData(result);
      } else if (isOrderDatas) {
        const { data, error } = useSupabase
          ? await supabase
              .from(table)
              .select('*')
              .gte(column, startPt)
              .lte(column, endPt)
              .eq('payment_status', '0')
          : await axiosInstance.get(
              `/api/dynamic-crud/${table}/range/0?lteKey=${column}&lteValue=${endPt}&gteKey=${column}&gteValue=${startPt}`
            );

        // payment_status가 '0'인 값 필터링 !
        console.log(data);

        if (error) throw error;
        const result = data.filter((item) => item.state !== 9);
        return handleSortData(result);
      } else {
        const { data, error } = useSupabase
          ? await supabase
              .from(table)
              .select('*')
              .gte(column, startPt)
              .lte(column, endPt)
          : await axiosInstance.get(
              `/api/dynamic-crud/${table}/range?lteKey=${column}&lteValue=${endPt}&gteKey=${column}&gteValue=${startPt}`
            );

        console.log(data);

        if (error) throw error;
        const result = data.filter((item) => item.state !== 9);
        return result;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const readRangeDataSlice = createSlice({
  name: 'readRangeData',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncReadRangeDataFetch.pending, (state) => {
      state.isLoadingReadRangeData = true;
      state.isSuccessReadRangeData = false;
      state.isErrorReadRangeData = false;
    });
    builder.addCase(asyncReadRangeDataFetch.fulfilled, (state) => {
      state.isLoadingReadRangeData = false;
      state.isSuccessReadRangeData = true;
      state.isErrorReadRangeData = false;
    });
    builder.addCase(asyncReadRangeDataFetch.rejected, (state) => {
      state.isLoadingReadRangeData = false;
      state.isSuccessReadRangeData = false;
      state.isErrorReadRangeData = true;
    });
  },
});

export default readRangeDataSlice.reducer;
export { asyncReadRangeDataFetch };
