import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Skeleton, Spin } from 'antd';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';

const AdminValue = (props) => {
  const { adminId } = props;
  const dispatch = useDispatch();

  const [adminValue, setAdminValue] = useState('');
  const [fetchLoading, setFetchLoading] = useState(false);

  useEffect(() => {
    const getAdminValue = async () => {
      await setFetchLoading(true);
      const adminData = await dispatch(
        asyncReadSpecificDataFetch({ table: 'profiles', id: adminId })
      )
        .unwrap()
        .then((res) => res[0]);
      setAdminValue(adminData.username);
      setFetchLoading(false);
    };
    getAdminValue();
  }, [adminId]);

  return <div>{adminValue}</div>;
};

export default AdminValue;

AdminValue.propTypes = {
  adminId: PropTypes.string.isRequired,
};
