import { Button, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  COUNSELLING_REACTION_BAD,
  COUNSELLING_REACTION_GOOD,
  COUNSELLING_REACTION_NORMAL,
} from '../../constants';

const CommentForm = (props) => {
  const {
    handleCreateComment,
    handleUpdateComment,
    adminInfo,
    setAdminInfo,
    isUpdateComment,
    setIsUpdateComment,
    setCommentIdToUpdate,
    commentId,
    reactionLevel,
    content,
  } = props;
  const myAdminInfo = useSelector((state) => state.signinUser.userInfo);

  const [commentFormData, setCommentFormData] = useState({
    adminId: adminInfo.id,
    reactionLevel: COUNSELLING_REACTION_GOOD,
    content: null,
  });
  const [isMe, setIsMe] = useState(false);

  useEffect(() => {
    if (isUpdateComment)
      setCommentFormData((prev) => ({ ...prev, reactionLevel, content }));
  }, []);

  const getMyAdminInfo = () => {
    if (myAdminInfo.id === adminInfo.id) {
      alert('내가 등록한 댓글입니다.');
      return;
    }
    setIsMe(true);
    setCommentFormData((prev) => ({ ...prev, adminId: myAdminInfo.id }));
  };

  const handleCancelButton = () => {
    setIsMe(false);
    setCommentFormData((prev) => ({ ...prev, adminId: adminInfo.id }));
    setIsUpdateComment(false);
    setCommentIdToUpdate(0);
  };

  return (
    <div style={{ position: 'relative', marginBottom: '13px' }}>
      <Input.TextArea
        style={{ padding: '40px 20px 60px 20px' }}
        autoSize
        placeholder="해당 업체의 이후 상담내용을 입력해주세요."
        onChange={(e) =>
          setCommentFormData((prev) => ({ ...prev, content: e.target.value }))
        }
        value={commentFormData.content}
      />
      <div
        style={{
          position: 'absolute',
          top: '13px',
          left: '20px',
          fontWeight: 'bold',
        }}
      >
        <span>{adminInfo.username}</span>
        <span>
          {isUpdateComment && (
            <Button
              type="link"
              size="small"
              style={{
                marginLeft: '10px',
                color: isMe ? 'white' : 'black',
                backgroundColor: isMe ? 'lightgray' : 'white',
                border: isMe ? '' : '0.5px solid black',
                boxShadow: isMe ? '' : '1.5px 1.5px 1.5px 1.5px lightgray',
              }}
              onClick={getMyAdminInfo}
              disabled={isMe}
            >
              담당자 나로 변경
            </Button>
          )}
        </span>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '15px',
          left: '20px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ReponseLevelButtonStyle
          type={
            commentFormData.reactionLevel === COUNSELLING_REACTION_GOOD
              ? 'primary'
              : 'default'
          }
          onClick={() =>
            setCommentFormData((prev) => ({
              ...prev,
              reactionLevel: COUNSELLING_REACTION_GOOD,
            }))
          }
        >
          {COUNSELLING_REACTION_GOOD}
        </ReponseLevelButtonStyle>
        <ReponseLevelButtonStyle
          type={
            commentFormData.reactionLevel === COUNSELLING_REACTION_NORMAL
              ? 'primary'
              : 'default'
          }
          onClick={() =>
            setCommentFormData((prev) => ({
              ...prev,
              reactionLevel: COUNSELLING_REACTION_NORMAL,
            }))
          }
        >
          {COUNSELLING_REACTION_NORMAL}
        </ReponseLevelButtonStyle>
        <ReponseLevelButtonStyle
          type={
            commentFormData.reactionLevel === COUNSELLING_REACTION_BAD
              ? 'primary'
              : 'default'
          }
          onClick={() =>
            setCommentFormData((prev) => ({
              ...prev,
              reactionLevel: COUNSELLING_REACTION_BAD,
            }))
          }
          danger={commentFormData.reactionLevel === COUNSELLING_REACTION_BAD}
        >
          {COUNSELLING_REACTION_BAD}
        </ReponseLevelButtonStyle>
      </div>
      {isUpdateComment ? (
        <div>
          <Button
            size="small"
            type="link"
            style={{
              position: 'absolute',
              bottom: '15px',
              right: '60px',
              fontSize: '15px',
              color: '#bcbcbc',
              fontWeight: 'bold',
            }}
            onClick={handleCancelButton}
          >
            취소
          </Button>
          <Button
            size="small"
            type="link"
            style={{
              position: 'absolute',
              bottom: '15px',
              right: '20px',
              fontSize: '15px',
              color: '#bcbcbc',
              fontWeight: 'bold',
            }}
            onClick={() => {
              handleUpdateComment(commentId, commentFormData);
              setCommentFormData({
                adminId: adminInfo.id,
                reactionLevel: COUNSELLING_REACTION_GOOD,
                content: null,
              });
            }}
          >
            저장
          </Button>
        </div>
      ) : (
        <Button
          size="small"
          type="link"
          style={{
            position: 'absolute',
            bottom: '15px',
            right: '20px',
            fontSize: '15px',
            color: '#bcbcbc',
            fontWeight: 'bold',
          }}
          onClick={() => {
            handleCreateComment(commentFormData);
            setCommentFormData({
              adminId: adminInfo.id,
              reactionLevel: COUNSELLING_REACTION_GOOD,
              content: null,
            });
          }}
        >
          등록
        </Button>
      )}
    </div>
  );
};

export default CommentForm;

CommentForm.propTypes = {
  isUpdateComment: PropTypes.bool,
  setIsUpdateComment: PropTypes.func,
  handleCreateComment: PropTypes.func,
  handleUpdateComment: PropTypes.func,
  adminInfo: PropTypes.instanceOf(Object).isRequired,
  setAdminInfo: PropTypes.func,
  setCommentIdToUpdate: PropTypes.func,
  commentId: PropTypes.number,
  reactionLevel: PropTypes.string,
  content: PropTypes.string,
};

const ReponseLevelButtonStyle = styled(Button)`
  border-radius: 20px;
  font-weight: bold;
  margin-right: 5px;
  font-size: 12px;
  padding: 5px 10px;
`;
