import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../axios';
import useSupabase from '../../../serverConfig';

import supabase from '../../../supabase';

const initialState = {
  isLoadingReadBulkData: false,
  isErrorReadBulkData: false,
  isSuccessReadBulkData: false,
};

const asyncReadBulkDataFetch = createAsyncThunk(
  'readBulkDataSlice/asyncReadBulkDataFetch',
  async (values, { rejectWithValue }) => {
    // values의 필수 값 => table
    const { table, column, array } = values;
    try {
      const { data } = useSupabase
        ? await supabase.from(table).select('*').in(column, array)
        : await axiosInstance.get(
            `/api/dynamic-crud/${table}/bulk?column=${column}&values=${array.join(
              ', '
            )}`
          );

      console.log(data);

      const result = data.filter((item) => item.state !== 9);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const readBulkDataSlice = createSlice({
  name: 'readBulkData',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncReadBulkDataFetch.pending, (state) => {
      state.isLoadingReadBulkData = true;
      state.isSuccessReadBulkData = false;
      state.isErrorReadBulkData = false;
    });
    builder.addCase(asyncReadBulkDataFetch.fulfilled, (state) => {
      state.isLoadingReadBulkData = false;
      state.isSuccessReadBulkData = true;
      state.isErrorReadBulkData = false;
    });
    builder.addCase(asyncReadBulkDataFetch.rejected, (state) => {
      state.isLoadingReadBulkData = false;
      state.isSuccessReadBulkData = false;
      state.isErrorReadBulkData = true;
    });
  },
});

export default readBulkDataSlice.reducer;
export { asyncReadBulkDataFetch };
