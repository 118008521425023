import React, { useEffect, useState } from 'react';
import { Result, Tag } from 'antd';
import { KeyOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import useModal from '../../hook/useModal';

import { asyncUpdateUserFetch } from '../../redux/modules/auth/updateUser';

import supabase from '../../supabase';
import Center from '../../shared/components/Center';
import Access from '../../access/page/Access';
import PasswordResetForm from '../components/PasswordResetForm';

const PasswordReset = () => {
  const { openModal } = useModal();
  const dispatch = useDispatch();
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'PASSWORD_RECOVERY') {
        setEmail(session.user.email);
        setIsPasswordReset(true);
      }
    });
  }, []);

  const onFinish = (values) => {
    dispatch(asyncUpdateUserFetch(values))
      .unwrap()
      .then(() => {
        openModal({ type: 'passwordUpdate' });
      })
      .catch((err) => alert(err));
  };
  const onFinishFailed = (values) => {};

  return (
    <Center>
      {isPasswordReset ? (
        <Result
          icon={<KeyOutlined />}
          status=""
          title="새로운 비밀번호를 입력해주세요."
          subTitle={
            <div style={{ margin: '50px 0' }}>
              방금 전 <Tag>{email}</Tag> 계정의 비밀번호 변경 요청이
              인증되었습니다.
              <br /> 새로운 비밀번호를 입력하여 비밀번호를 재설정 할 수
              있습니다.
            </div>
          }
          extra={
            <div>
              <PasswordResetForm
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              />
            </div>
          }
        />
      ) : (
        <Access text="현재" button="홈으로" isLoggedIn />
      )}
    </Center>
  );
};

export default PasswordReset;
