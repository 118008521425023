import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DatePicker, Descriptions, Select, ConfigProvider, Button } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

// datepicker 한글화
import 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ko';
import locale from 'antd/lib/locale/ko_KR';

import { asyncReadDataFetch } from '../../redux/modules/shared/readData';

const PurchaseForm = (props) => {
  const {
    formType,
    purchaseForm,
    setPurchaseForm,
    purchaseOrderForm,
    setPurchaseOrderForm,
    pageType,
  } = props;
  const dispatch = useDispatch();

  const [options, setOptions] = useState({
    account: [],
    admin: [],
    warehouse: [],
    dealType: [],
  });
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);

  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [isOpenDlvyDatePicker, setIsOpenDlvyDatePicker] = useState(false);

  const labelStyle = useMemo(
    () => ({
      width: '10%',
      textAlign: 'center',
    }),
    []
  );
  const contentStyle = useMemo(
    () => ({
      width: '40%',
      textAlign: 'start',
    }),
    []
  );

  const getTableDatas = useCallback(
    async (table) => {
      try {
        const response = await dispatch(asyncReadDataFetch({ table }))
          .unwrap()
          .then((res) => res);
        const result = response.map((data) => ({
          label: `${data.code}/${
            table === 'profiles' ? data.username : data.name
          }`,
          value: data.id,
        }));
        return result;
      } catch (error) {
        throw new Error(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const getOptions = async () => {
      await setIsOptionsLoading(true);
      try {
        // 거래처
        const account = await getTableDatas('account');
        // 담당자
        const admin = await getTableDatas('profiles');
        // 창고
        const warehouse = await getTableDatas('warehouse');
        // 거래유형
        const dealType = [
          { label: '부가세율 적용', value: 1 },
          { label: '부가세율 미적용', value: 0 },
        ];
        setOptions({
          account,
          admin,
          warehouse,
          dealType,
        });
      } catch (error) {
        throw new Error(error);
      }
      await setIsOptionsLoading(false);
    };
    getOptions();
  }, [dispatch, getTableDatas]);

  return (
    <>
      <div
        style={{
          fontSize: '16px',
          borderBottom: '1px solid gray',
          paddingBottom: '10px',
        }}
      >
        {formType === 'purchaseOrder' ? '발주 입력' : '구매 입력'}
      </div>
      <Descriptions column={2} bordered className="select-container">
        <Descriptions.Item
          label="일자"
          labelStyle={labelStyle}
          contentStyle={contentStyle}
        >
          <ConfigProvider locale={locale}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <div
                style={{
                  width: '100%',
                  padding: '4px 10px',
                  borderRadius: '0px',
                  marginRight: '10px',
                  border: '1px solid lightgray',
                  backgroundColor: pageType === 'detail' && '#f7f7f7',
                }}
              >
                {formType === 'purchaseOrder'
                  ? purchaseOrderForm.purchaseOrderDate ?? (
                      <div style={{ color: 'lightgray' }}>
                        날짜를 선택해주세요.
                      </div>
                    )
                  : purchaseForm.purchaseDate ?? (
                      <div style={{ color: 'lightgray' }}>
                        날짜를 선택해주세요.
                      </div>
                    )}
              </div>
              <Button
                disabled={pageType === 'detail'}
                style={{ zIndex: '1' }}
                onClick={() => setIsOpenDatePicker((prev) => !prev)}
              >
                <CalendarOutlined />
              </Button>
              {isOpenDatePicker && (
                <DatePicker
                  size="small"
                  disabled={pageType === 'detail'}
                  onChange={
                    formType === 'purchaseOrder'
                      ? (value) => {
                          setPurchaseOrderForm((prev) => ({
                            ...prev,
                            purchaseOrderDate: value?.format('YYYY-MM-DD'),
                          }));
                          setIsOpenDatePicker(false);
                        }
                      : (value) => {
                          setPurchaseForm((prev) => ({
                            ...prev,
                            purchaseDate: value?.format('YYYY-MM-DD'),
                          }));
                          setIsOpenDatePicker(false);
                        }
                  }
                  locale={locale}
                  style={{
                    width: '0px',
                    position: 'absolute',
                    right: '30px',
                  }}
                  open
                />
              )}
            </div>
          </ConfigProvider>
        </Descriptions.Item>
        <Descriptions.Item
          label="거래처"
          labelStyle={labelStyle}
          contentStyle={contentStyle}
        >
          <Select
            disabled={pageType === 'detail'}
            style={{ width: '100%', textAlign: 'start' }}
            options={options.account}
            placeholder="거래처를 선택해주세요."
            showSearch
            filterOption={(input, option) => {
              return option.label && option.label.includes(input);
            }}
            onChange={
              formType === 'purchaseOrder'
                ? (value) =>
                    setPurchaseOrderForm((prev) => ({
                      ...prev,
                      accountId: value,
                    }))
                : (value) =>
                    setPurchaseForm((prev) => ({
                      ...prev,
                      accountId: value,
                    }))
            }
            value={
              !isOptionsLoading
                ? formType === 'purchaseOrder'
                  ? purchaseOrderForm.accountId
                  : purchaseForm.accountId
                : null
            }
          />
        </Descriptions.Item>
        <Descriptions.Item
          label="담당자"
          labelStyle={labelStyle}
          contentStyle={contentStyle}
        >
          <Select
            disabled={pageType === 'detail'}
            style={{ width: '100%', textAlign: 'start' }}
            options={options.admin}
            placeholder="담당자를 선택해주세요."
            showSearch
            filterOption={(input, option) => {
              return option.label && option.label.includes(input);
            }}
            onChange={
              formType === 'purchaseOrder'
                ? (value) =>
                    setPurchaseOrderForm((prev) => ({
                      ...prev,
                      adminId: value,
                    }))
                : (value) =>
                    setPurchaseForm((prev) => ({
                      ...prev,
                      adminId: value,
                    }))
            }
            value={
              !isOptionsLoading
                ? formType === 'purchaseOrder'
                  ? purchaseOrderForm.adminId
                  : purchaseForm.adminId
                : null
            }
          />
        </Descriptions.Item>
        <Descriptions.Item
          label="입고창고"
          labelStyle={labelStyle}
          contentStyle={contentStyle}
        >
          <Select
            disabled={pageType === 'detail'}
            style={{ width: '100%', textAlign: 'start' }}
            options={options.warehouse}
            placeholder="입고창고를 선택해주세요."
            showSearch
            filterOption={(input, option) => {
              return option.label && option.label.includes(input);
            }}
            onChange={
              formType === 'purchaseOrder'
                ? (value) =>
                    setPurchaseOrderForm((prev) => ({
                      ...prev,
                      warehouseId: value,
                    }))
                : (value) =>
                    setPurchaseForm((prev) => ({
                      ...prev,
                      warehouseId: value,
                    }))
            }
            value={
              !isOptionsLoading
                ? formType === 'purchaseOrder'
                  ? purchaseOrderForm.warehouseId
                  : purchaseForm.warehouseId
                : null
            }
          />
        </Descriptions.Item>
        <Descriptions.Item
          label="거래유형"
          labelStyle={labelStyle}
          contentStyle={contentStyle}
        >
          <Select
            style={{
              width: '100%',
              textAlign: 'start',
            }}
            disabled={pageType === 'detail'}
            options={options.dealType}
            onChange={
              formType === 'purchaseOrder'
                ? (value) =>
                    setPurchaseOrderForm((prev) => ({
                      ...prev,
                      dealType: value,
                    }))
                : (value) =>
                    setPurchaseForm((prev) => ({
                      ...prev,
                      dealType: value,
                    }))
            }
            value={
              !isOptionsLoading
                ? formType === 'purchaseOrder'
                  ? purchaseOrderForm.dealType
                  : purchaseForm.dealType
                : null
            }
            placeholder="거래유형을 선택해주세요."
          />
        </Descriptions.Item>
        {formType === 'purchaseOrder' && (
          <Descriptions.Item
            label="납기일자"
            labelStyle={labelStyle}
            contentStyle={contentStyle}
          >
            <ConfigProvider locale={locale}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    padding: '4px 10px',
                    borderRadius: '0px',
                    marginRight: '10px',
                    border: '1px solid lightgray',
                    backgroundColor: pageType === 'detail' && '#f7f7f7',
                  }}
                >
                  {purchaseOrderForm.deliveryDate ?? (
                    <div style={{ color: 'lightgray' }}>
                      날짜를 선택해주세요.
                    </div>
                  )}
                </div>
                <Button
                  disabled={pageType === 'detail'}
                  style={{ zIndex: '1' }}
                  onClick={() => setIsOpenDlvyDatePicker((prev) => !prev)}
                >
                  <CalendarOutlined />
                </Button>
                {isOpenDlvyDatePicker && (
                  <DatePicker
                    size="small"
                    disabled={pageType === 'detail'}
                    onChange={(value) => {
                      setPurchaseOrderForm((prev) => ({
                        ...prev,
                        deliveryDate: value.format('YYYY-MM-DD'),
                      }));
                      setIsOpenDlvyDatePicker(false);
                    }}
                    locale={locale}
                    style={{
                      width: '50px',
                      position: 'absolute',
                      right: '0',
                      opacity: '0',
                    }}
                    open
                  />
                )}
              </div>
            </ConfigProvider>
          </Descriptions.Item>
        )}
      </Descriptions>
    </>
  );
};

export default PurchaseForm;

PurchaseForm.propTypes = {
  formType: PropTypes.string.isRequired,
  purchaseForm: PropTypes.instanceOf(Object),
  setPurchaseForm: PropTypes.func,
  purchaseOrderForm: PropTypes.instanceOf(Object),
  setPurchaseOrderForm: PropTypes.func,
  pageType: PropTypes.string.isRequired,
};
