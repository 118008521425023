import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const UnOrderedValue = (props) => {
  const { item, addCard } = props;

  return (
    <Button
      onClick={() => addCard(item.id)}
      type="text"
      size="small"
      style={{ textAlign: 'start' }}
    >
      {item.name}
    </Button>
  );
};

export default UnOrderedValue;

UnOrderedValue.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  addCard: PropTypes.func.isRequired,
};
