import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../axios';
import useSupabase from '../../../serverConfig';

import supabase from '../../../supabase';
import generateMatchQueryString from '../../../utils/generateMatchQueryString';

const initialState = {
  isLoadingReadMatchBulkData: false,
  isErrorReadMatchBulkData: false,
  isSuccessReadMatchBulkData: false,
};

const asyncReadMatchBulkDataFetch = createAsyncThunk(
  'readMatchBulkDataSlice/asyncReadMatchBulkDataFetch',
  async (values, { rejectWithValue }) => {
    // values의 필수 값 => table
    const { table, match, order } = values;
    try {
      const { data } = useSupabase
        ? await supabase
            .from(table)
            .select('*')
            .match(match)
            .order(order, { ascending: false })
        : await axiosInstance.get(
            `/api/dynamic-crud/${table}/match?order=${order}${generateMatchQueryString(
              match
            )}`
          );

      console.log(data);

      const result = data.filter((item) => item.state !== 9);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const readMatchBulkDataSlice = createSlice({
  name: 'readMatchBulkData',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncReadMatchBulkDataFetch.pending, (state) => {
      state.isLoadingReadMatchBulkData = true;
      state.isSuccessReadMatchBulkData = false;
      state.isErrorReadMatchBulkData = false;
    });
    builder.addCase(asyncReadMatchBulkDataFetch.fulfilled, (state) => {
      state.isLoadingReadMatchBulkData = false;
      state.isSuccessReadMatchBulkData = true;
      state.isErrorReadMatchBulkData = false;
    });
    builder.addCase(asyncReadMatchBulkDataFetch.rejected, (state) => {
      state.isLoadingReadMatchBulkData = false;
      state.isSuccessReadMatchBulkData = false;
      state.isErrorReadMatchBulkData = true;
    });
  },
});

export default readMatchBulkDataSlice.reducer;
export { asyncReadMatchBulkDataFetch };
