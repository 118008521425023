import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { inventoryCouse } from '../../constants';

const InventoryReceiptsTables = ({ receiptsData }) => {
  const checkIsClickable = useCallback((causeOfChange) => {
    return (
      causeOfChange === inventoryCouse.of_change_purchase ||
      causeOfChange === inventoryCouse.of_change_sales
    );
  }, []);

  const getUrl = useCallback(({ causeOfChange, causeId }) => {
    switch (causeOfChange) {
      case inventoryCouse.of_change_purchase:
        return `/purchase/detail/${causeId}`;
      case inventoryCouse.of_change_sales:
        return `/order/${causeId}`;
      default:
        return;
    }
  }, []);

  const getColumns = useMemo(
    () => [
      {
        title: '일자',
        dataIndex: 'inventory_change_date',
        key: 'inventoryChangeDate',
        render: (value, record) => {
          const { cause_of_change: causeOfChange, cause_id: causeId } = record;
          const isClickable = checkIsClickable(causeOfChange);
          if (isClickable) {
            const url = getUrl({ causeOfChange, causeId });
            return (
              <a
                href={`https://hantong-oms.vercel.app${url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {value}
              </a>
            );
          } else {
            return <div>{value}</div>;
          }
        },
        width: '15%',
      },
      {
        title: '담당자명',
        dataIndex: 'admin',
        key: 'admin',
        render: (value) => value?.username,
        width: '15%',
      },
      {
        title: '거래처명',
        dataIndex: 'account',
        key: 'account',
        render: (value) => value?.name,
        width: '20%',
      },
      {
        title: '변동원인',
        dataIndex: 'cause_of_change',
        key: 'causeOfChange',
        width: '10%',
      },
      {
        title: '변동상태',
        dataIndex: 'cause_state',
        key: 'causeState',
        width: '10%',
      },
      {
        title: '입고수량',
        dataIndex: 'incomingQuantity',
        key: 'incomingQuantity',
        width: '10%',
        render: (value) => <div style={{ textAlign: 'right' }}>{value}</div>,
      },
      {
        title: '출고수량',
        dataIndex: 'releaseQuantity',
        key: 'releaseQuantity',
        width: '10%',
        render: (value) => <div style={{ textAlign: 'right' }}>{value}</div>,
      },
      {
        title: '재고수량',
        dataIndex: 'stack_quantity',
        key: 'stackQuantity',
        width: '10%',
        render: (value) => <div style={{ textAlign: 'right' }}>{value}</div>,
      },
    ],
    []
  );

  const getTitle = useCallback(({ product, warehouse, prevStackQuantity }) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {product.name}({product.code}) / {warehouse.name}
        </div>
        <div>
          <span style={{ color: 'red' }}>전일재고</span> {prevStackQuantity}
        </div>
      </div>
    );
  }, []);

  const getTotal = useCallback(
    ({
      incomingQuantity,
      releaseQuantity,
      stackQuantity,
      isTotal = false,
      month = null,
    }) => {
      return (
        <TotalContainer>
          <div style={{ width: '70%', textAlign: 'center' }}>
            {isTotal ? '합' : month}계
          </div>
          <TotalContent marginRight="4px">{incomingQuantity}</TotalContent>
          <TotalContent marginRight="3px">{releaseQuantity}</TotalContent>
          <TotalContent marginRight="0px">{stackQuantity}</TotalContent>
        </TotalContainer>
      );
    },
    []
  );

  return (
    <div>
      {receiptsData.map((data) => {
        const { product, groupedData } = data;

        return groupedData.map((data) => {
          const { warehouse, prevStackQuantity, monthlyTotal, total } = data;

          return (
            <div
              style={{ marginBottom: '20px', border: '1px solid lightgray' }}
            >
              {monthlyTotal.map((monthlyData, index) => {
                const { data: items } = monthlyData;

                return (
                  <Table
                    dataSource={items}
                    pagination={false}
                    bordered
                    showHeader={index === 0}
                    columns={
                      index === 0
                        ? [
                            {
                              title: getTitle({
                                product,
                                warehouse,
                                prevStackQuantity,
                              }),
                              dataIndex: 'prevStackQuantity',
                              key: 'prevStackQuantity',
                              children: getColumns,
                            },
                          ]
                        : getColumns
                    }
                    footer={() => {
                      if (
                        monthlyTotal.length === 1 ||
                        index === monthlyTotal.length - 1
                      ) {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '15px',
                            }}
                          >
                            {getTotal({
                              incomingQuantity: monthlyData.incomingQuantity,
                              releaseQuantity: monthlyData.releaseQuantity,
                              stackQuantity: monthlyData.stackQuantity,
                              month: monthlyData.month,
                            })}
                            <div
                              style={{
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                              }}
                            />
                            {getTotal({
                              incomingQuantity: total.incomingQuantity,
                              releaseQuantity: total.releaseQuantity,
                              stackQuantity: total.stackQuantity,
                              isTotal: true,
                            })}
                          </div>
                        );
                      } else {
                        return getTotal({
                          incomingQuantity: monthlyData.incomingQuantity,
                          releaseQuantity: monthlyData.releaseQuantity,
                          stackQuantity: monthlyData.stackQuantity,
                          month: monthlyData.month,
                        });
                      }
                    }}
                  />
                );
              })}
            </div>
          );
        });
      })}
    </div>
  );
};

const TotalContainer = styled.div`
  width: 100%;
  display: flex;
  font-weight: 600;
`;

const TotalContent = styled.div`
  width: 10%;
  text-align: right;
  margin-right: ${(props) => props.marginRight};
`;

export default InventoryReceiptsTables;

InventoryReceiptsTables.propTypes = {
  receiptsData: PropTypes.instanceOf(Array).isRequired,
};
