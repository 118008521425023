import { ApiOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import DeliveryTracker from '../components/DeliveryTracker';
import HantongDashboard from '../components/HantongDashboard';
import MapDistribution from '../components/MapDistribution';

const ExternalServices = () => {
  const { type } = useParams();

  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <ApiOutlined />,
        breadCrumbNav: ' 외부서비스',
      },
    ],
    []
  );

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      {type === 'map-distribution' && <MapDistribution />}
      {type === 'hantong-dashboard' && <HantongDashboard />}
      {type === 'delivery-tracker' && <DeliveryTracker />}
    </LayoutComponent>
  );
};

export default ExternalServices;

const MoveButtonStyle = styled(Button)`
  position: absolute;
  border-radius: 30px;
  font-weight: 700;
`;
