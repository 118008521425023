import React, { useState } from 'react';
import PropTypes from 'prop-types';

const UnSoldAndReceivedValue = (props) => {
  const { stackQuantity, unSold, unReceived } = props;

  const [isClicked, setIsClicked] = useState(false);

  return (
    <div
      className="inventory-table-cell"
      style={{ backgroundColor: (unSold > 0 || unReceived > 0) && '#ffccc7' }}
      onClick={() => setIsClicked((prev) => !prev)}
    >
      {isClicked ? (
        <div style={{ display: 'flex', fontSize: '11px', gap: '7px' }}>
          <div>
            <div>미판매</div>
            <div>{unSold}</div>
          </div>
          <div>
            <div>미입고</div>
            <div>{unReceived}</div>
          </div>
        </div>
      ) : (
        stackQuantity
      )}
    </div>
  );
};

export default UnSoldAndReceivedValue;

UnSoldAndReceivedValue.propTypes = {
  unSold: PropTypes.number.isRequired,
  unReceived: PropTypes.number.isRequired,
  stackQuantity: PropTypes.number.isRequired,
};
