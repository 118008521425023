import { CloseOutlined, DeleteOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Progress } from 'antd';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const SaleProductDiscount = (props) => {
  const { saleProductTableRows, setSaleProductTableRows, pageType } = props;
  const [existDc, setExistDc] = useState(false);
  const [dcDatas, setDcDatas] = useState([]);

  const dispatch = useDispatch();

  // 할인적용된 품목 유무확인
  useEffect(() => {
    let hasDcRatePd = false;
    for (const data of saleProductTableRows) {
      if (data.discount_rate && data.discount_rate > 0) {
        hasDcRatePd = true;
      }
    }
    setExistDc(hasDcRatePd);
  }, [saleProductTableRows]);

  // 할인적용된 품목 데이터 받기
  useEffect(() => {
    const asyncFunc = async () => {
      try {
        let newDcDatas = [];
        for (const data of saleProductTableRows) {
          if (data.discount_rate && data.discount_rate > 0) {
            const { discount_rate, product_quantity, product_code } = data;
            const dcData = await dispatch(
              asyncReadFilteredDataFetch({
                table: 'product',
                eqKey: 'code',
                eqValue: product_code,
              })
            )
              .unwrap()
              .then((res) => {
                const original_price = res[0].release_price * product_quantity;
                const discount_price = (original_price * discount_rate) / 100;
                const discounted_price = original_price - discount_price;

                return {
                  ...res[0],
                  original_price: Math.round(original_price),
                  discount_price: Math.round(discount_price),
                  discounted_price: Math.round(discounted_price),
                  product_quantity,
                  discount_rate,
                };
              });
            newDcDatas.push(dcData);
          }
        }
        setDcDatas([...newDcDatas]);
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    };
    asyncFunc();
  }, [saleProductTableRows, dispatch]);

  // 할인 품목에서 삭제
  const deleteDcRate = (code) => {
    setSaleProductTableRows((prev) =>
      prev.map((data) =>
        data.product_code === code
          ? {
              product_code: data.product_code,
              product_quantity: data.product_quantity,
              sale_code: data.sale_code,
            }
          : { ...data }
      )
    );
  };

  return (
    <div style={{ marginTop: '0px', width: '100%' }}>
      {!existDc && (
        <div
          style={{
            padding: '30px 0',
            backgroundColor: 'white',
            borderTop: '1px solid #efefef',
            borderBottom: '1px solid #efefef',
            textAlign: 'center',
            fontSize: '15px',
            color: 'gray',
          }}
        >
          <StopOutlined style={{ marginRight: '10px' }} />
          할인 적용된 상품이 없습니다.
        </div>
      )}
      {dcDatas.length > 0 && <div style={{ border: '0.5px solid #efefef' }} />}
      {dcDatas.map((data) => {
        if (data.discount_rate && data.discount_rate > 0) {
          return (
            <div>
              <div
                style={{
                  padding: '50px 0px 50px 50px',
                  borderRadius: '0px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <Progress
                  percent={data.discount_rate}
                  type="circle"
                  style={{ position: 'absolute', left: '20px' }}
                  format={(rate) => <div>{rate}%</div>}
                />
                <div
                  style={{
                    width: '70%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    <div>{data.name}</div>
                    <div style={{ color: 'gray', marginTop: '10px' }}>
                      {data.code}
                    </div>
                  </div>
                  <DivLine />
                  <div>
                    <p>상품개수 </p>
                    <p
                      style={{
                        textAlign: 'center',
                        marginTop: '10px',
                        color: 'gray',
                      }}
                    >
                      {data.product_quantity} 개
                    </p>
                  </div>
                  <DivLine />
                  <div
                    style={{
                      position: 'relative',
                      textAlign: 'center',
                    }}
                  >
                    <p>상품금액</p>
                    <p style={{ marginTop: '10px', color: 'gray' }}>
                      {data.original_price.toLocaleString()} 원
                    </p>
                  </div>
                  <DivLine />
                  <div style={{ textAlign: 'center' }}>
                    <p>할인금액</p>
                    <p style={{ marginTop: '10px', color: 'gray' }}>
                      -{data.discount_price.toLocaleString()}원
                    </p>
                  </div>
                  <DivLine />
                  <div style={{ textAlign: 'center' }}>
                    <p>판매금액</p>
                    <p style={{ marginTop: '10px', color: 'gray' }}>
                      {data.discounted_price.toLocaleString()}원
                    </p>
                  </div>
                </div>
                <Button
                  size="small"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '20px',
                    transform: 'translateY(-50%)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%',
                  }}
                  onClick={() => deleteDcRate(data.code)}
                  disabled={pageType === 'detail'}
                  type="primary"
                >
                  x
                </Button>
              </div>
            </div>
          );
        }
      })}
      {dcDatas.length > 0 && <div style={{ border: '0.5px solid #efefef' }} />}
    </div>
  );
};

export default SaleProductDiscount;

SaleProductDiscount.propTypes = {
  saleProductTableRows: PropTypes.instanceOf(Array).isRequired,
  setSaleProductTableRows: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
};

const DivLine = styled.div`
  width: 0.5px;
  height: 20px;
  border: 0.5px solid lightgray;
`;
