import { Button, Modal, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncReadBulkDataFetch } from '../../redux/modules/shared/readBulkData';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import { asyncDeleteDataFetch } from '../../redux/modules/shared/deleteData';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const SearchDefaultModal = (props) => {
  const { setIsOpenSearchDefaultModal } = props;
  const dispatch = useDispatch();

  const [searchDefaultWhs, setSearchDefaultWhs] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getSearchDefaults = async () => {
      const searchDefaults = await dispatch(
        asyncReadDataFetch({ table: 'inventory_search_default' })
      )
        .unwrap()
        .then((res) => res);
      const warehouseIdArr = await searchDefaults.map(
        (data) => data.warehouse_id
      );
      const warehouses = await dispatch(
        asyncReadBulkDataFetch({
          table: 'warehouse',
          column: 'id',
          array: warehouseIdArr,
        })
      )
        .unwrap()
        .then((res) => res);
      const warehouseNameAndIdArr = await warehouses.map((data) => ({
        label: data.name,
        value: data.id,
      }));
      setSearchDefaultWhs(warehouseNameAndIdArr);
    };
    const getOptions = async () => {
      const warehouses = await dispatch(
        asyncReadDataFetch({ table: 'warehouse' })
      )
        .unwrap()
        .then((res) => res);
      const warehouseNameAndIdArr = await warehouses.map((data) => ({
        label: data.name,
        value: data.id,
      }));
      setOptions(warehouseNameAndIdArr);
    };
    getSearchDefaults();
    getOptions();
  }, [dispatch]);

  const handleSubmit = async (warehouseId) => {
    try {
      // 등록된 창고여부
      let isRegistered = false;
      const registeredWhs = await dispatch(
        asyncReadDataFetch({ table: 'inventory_search_default' })
      )
        .unwrap()
        .then((res) => res);
      for (const registeredWh of registeredWhs) {
        if (warehouseId === registeredWh.warehouse_id) {
          isRegistered = true;
        }
      }
      if (isRegistered) {
        alert('이미 기본값으로 등록된 창고입니다.');
        return;
      }
      // 창고 등록 (db)
      await dispatch(
        asyncCreateDataFetch({
          table: 'inventory_search_default',
          warehouse_id: warehouseId,
        })
      );
      // 창고 등록 (client)
      const warehouseName = await dispatch(
        asyncReadSpecificDataFetch({ table: 'warehouse', id: warehouseId })
      )
        .unwrap()
        .then((res) => res[0].name);
      await setSearchDefaultWhs((prev) => [
        ...prev,
        { label: warehouseName, value: warehouseId },
      ]);
      alert(`(${warehouseName}) 창고가 검색기본값으로 등록되었습니다.`);
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleDelete = async (warehouseId) => {
    try {
      const idToDelete = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'inventory_search_default',
          eqKey: 'warehouse_id',
          eqValue: warehouseId,
        })
      )
        .unwrap()
        .then((res) => res[0].id);
      await dispatch(
        asyncDeleteDataFetch({
          table: 'inventory_search_default',
          id: idToDelete,
        })
      );
      alert('해당 창고를 기본값에서 삭제했습니다.');
      setSearchDefaultWhs((prev) =>
        prev.filter((data) => data.value !== warehouseId)
      );
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <Modal
      centered
      open
      title="재고검색시 기본으로 선택될 창고를 선택해주세요."
      footer={[
        <Button onClick={() => setIsOpenSearchDefaultModal(false)}>
          닫기
        </Button>,
      ]}
      onCancel={() => setIsOpenSearchDefaultModal(false)}
    >
      <div style={{ padding: '20px' }}>
        <div>
          {searchDefaultWhs.length === 0 && (
            <Spin style={{ height: '200px' }} />
          )}
          {searchDefaultWhs.map((item) => (
            <div
              style={{
                marginRight: '5px',
                marginTop: '5px',
                backgroundColor: '#f5f5f5',
                padding: '10px 35px 10px 10px',
                borderRadius: '20px',
                position: 'relative',
                display: 'inline-block',
              }}
            >
              <div>{item.label}</div>
              <Button
                size="small"
                style={{
                  position: 'absolute',
                  right: '2px',
                  bottom: '10px',
                  borderRadius: '20px',
                  width: '25px',
                  height: '25px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => handleDelete(item.value)}
              >
                <CloseOutlined />
              </Button>
            </div>
          ))}
        </div>
        <div style={{ marginTop: '30px' }}>
          <Container>
            <Title>창고</Title>
            <Select
              style={{ width: '90%', borderBottom: '1px solid lightgray' }}
              value={null}
              placeholder="창고를 선택해주세요."
              bordered={false}
              options={options}
              onSelect={(value) => handleSubmit(value)}
            />
          </Container>
        </div>
      </div>
    </Modal>
  );
};

export default SearchDefaultModal;

SearchDefaultModal.propTypes = {
  setIsOpenSearchDefaultModal: PropTypes.func.isRequired,
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;
const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
`;
