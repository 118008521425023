import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import useFilterProductSetup from '../../hook/useFilterProductSetup';
import OrderSettingHandler from './OrderSettingHandler';

const OrderSettingModal = (props) => {
  const { setIsOpenOrderSettingModal } = props;
  const dispatch = useDispatch();
  const [productDatas, setProductDatas] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const fetchedProducts = await dispatch(
          asyncReadDataFetch({ table: 'product' })
        ).unwrap();
        const fetchedOrderSettings = await dispatch(
          asyncReadDataFetch({ table: 'inventory_order_setting' })
        ).unwrap();
        const productsWithOrder = fetchedProducts.map((product) => {
          let order = null;
          fetchedOrderSettings.forEach((orderSetting) => {
            if (product.id === orderSetting.product_id) {
              order = orderSetting.order;
            }
          });
          return { ...product, order };
        });
        setProductDatas(productsWithOrder);
      } catch (error) {
        throw new Error(error);
      }
    };
    getProducts();
  }, []);

  return (
    <Modal
      width={700}
      centered
      open
      title="재고의 출력순번을 설정해주세요."
      footer={[
        <Button onClick={() => setIsOpenOrderSettingModal(false)}>닫기</Button>,
      ]}
      onCancel={() => setIsOpenOrderSettingModal(false)}
    >
      <div
        style={{
          display: 'flex',
          overflow: 'auto',
          maxHeight: '700px',
          minHeight: '700px',
          width: '100%',
          backgroundColor: '#f7f7f7',
          padding: '0 15px',
          borderRadius: '20px',
          margin: '15px 0',
        }}
      >
        <OrderSettingHandler
          productDatas={useFilterProductSetup(productDatas)}
        />
      </div>
    </Modal>
  );
};

export default OrderSettingModal;

OrderSettingModal.propTypes = {
  setIsOpenOrderSettingModal: PropTypes.func.isRequired,
};
