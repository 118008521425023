import React, { useEffect } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';

const ProfileForm = (props) => {
  const { profileData, children, onFinish } = props;

  const [form] = Form.useForm();

  // update와 delete만 들어감.
  useEffect(() => {
    form.setFieldsValue({
      email: profileData.email,
      username: profileData.username,
      intro: profileData.intro,
      phone: profileData.phone,
    });
  }, [
    form,
    profileData.email,
    profileData.username,
    profileData.phone,
    profileData.intro,
  ]);

  return (
    <Form
      form={form}
      name="basic"
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      style={{ padding: '10px', marginBottom: '45px' }}
    >
      {children}
    </Form>
  );
};

export default ProfileForm;

ProfileForm.propTypes = {
  profileData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
  onFinish: PropTypes.func.isRequired,
};
