import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import UpdateTable from '../shared/TableModal/UpdateTable';
import CreateTable from '../shared/TableModal/CreateTable';
import DeleteTable from '../shared/TableModal/DeleteTable';

const ManagerData = (props) => {
  const { items, createItems } = props;
  const { modalInfo } = useSelector((state) => state.modal);

  // modal 종류에 따라 다른 component 리턴.
  // 공유 TableModal로 들어감.
  let manager = null;
  if (modalInfo.modalTitle === '관리자 수정') {
    manager = () => {
      return <UpdateTable items={items} />;
    };
  } else if (modalInfo.modalTitle === '관리자 삭제') {
    manager = () => {
      return <DeleteTable items={items} />;
    };
  } else if (modalInfo.modalTitle === '관리자 추가') {
    manager = () => {
      return <CreateTable items={createItems} />;
    };
  }

  return <>{manager()}</>;
};

export default ManagerData;

ManagerData.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  createItems: PropTypes.instanceOf(Array).isRequired,
};
