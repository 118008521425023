import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Progress, Select, Tag, Timeline } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';

const AddDiscount = (props) => {
  const { accountId, accountName, setDcProductDatas } = props;

  const [productOptions, setProductOptions] = useState([]);
  const [inputDatas, setInputDatas] = useState({
    productId: null,
    discountPrice: null,
    discountPercentage: null,
  });
  const [displayDatas, setDisplayDatas] = useState({
    productName: '',
    productCode: '',
    productOmsCode: '',
    releasePrice: 0,
    discountPrice: 0,
    discountedPrice: 0,
  });

  const dispatch = useDispatch();

  // 품목 옵션
  useEffect(() => {
    const getProductOptions = async () => {
      try {
        const products = await dispatch(
          asyncReadDataFetch({ table: 'product' })
        )
          .unwrap()
          .then((res) => res);
        setProductOptions(() =>
          products.map((data) => ({
            label: `${data.code}/${data.name}`,
            value: data.id,
          }))
        );
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };
    getProductOptions();
  }, [dispatch]);

  const selectProduct = async (productId) => {
    try {
      // 이미 할인중인 품목인지 체크
      const existingDcDatas = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'discount_account_product',
          eqKey: 'account_id',
          eqValue: accountId,
        })
      )
        .unwrap()
        .then((res) => res);
      let duplicated = false;
      for (const data of existingDcDatas) {
        if (data.product_id === productId) {
          duplicated = true;
        }
      }
      if (duplicated) {
        alert('이미 해당품목에 할인이 적용되었습니다.');
        return;
      }
      //
      await setInputDatas((prev) => ({ ...prev, productId }));
      const product = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'product',
          eqKey: 'id',
          eqValue: productId,
        })
      )
        .unwrap()
        .then((res) => res[0]);
      const { release_price, code, name, oms_code } = product;
      setDisplayDatas((prev) => ({
        ...prev,
        productCode: code,
        productName: name,
        productOmsCode: oms_code,
        releasePrice: release_price,
        discountedPrice: release_price,
      }));
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  // 할인금액 또는 할인율을 입력했을 때의 함수
  const inputDiscount = async (value, type) => {
    const numberedValue = Number(value);
    const releasePrice = Number(displayDatas.releasePrice);
    if (numberedValue < 0) {
      alert('마이너스 값을 입력할 수 없습니다.');
      return;
    }
    if (type === 'price') {
      if (numberedValue > releasePrice) {
        alert('상품금액보다 큰 값을 입력할 수 없습니다.');
        return;
      }
      const discountPrice = Math.round(numberedValue);
      const discountPercentage = (numberedValue / releasePrice) * 100;
      const discountedPrice = Math.round(releasePrice - discountPrice);

      setInputDatas((prev) => ({
        ...prev,
        discountPrice,
        discountPercentage,
      }));
      setDisplayDatas((prev) => ({
        ...prev,
        discountPrice,
        discountedPrice,
      }));
    }
    if (type === 'percentage') {
      if (numberedValue > 100) {
        alert('100보다 큰 값을 입력할 수 없습니다.');
        return;
      }

      const discountPercentage = numberedValue;
      const discountPrice = Math.round(
        (releasePrice * discountPercentage) / 100
      );
      const discountedPrice = Math.round(
        releasePrice * (1 - numberedValue / 100)
      );

      setInputDatas((prev) => ({
        ...prev,
        discountPrice,
        discountPercentage,
      }));
      setDisplayDatas((prev) => ({
        ...prev,
        discountPrice,
        discountedPrice,
      }));
    }
  };

  const addDiscountData = async () => {
    try {
      const {
        discountedPrice,
        releasePrice,
        productOmsCode,
        productCode,
        productName,
      } = displayDatas;
      const { productId } = inputDatas;
      const discountPrice = releasePrice - discountedPrice;
      if (discountedPrice <= 0 || !productId) {
        alert('값을 입력한 후 추가해주세요.');
        return;
      }
      // 데이터베이스에 저장
      await dispatch(
        asyncCreateDataFetch({
          table: 'discount_account_product',
          discounted_release_price: discountPrice,
          product_id: productId,
          account_id: accountId,
          is_on: true,
        })
      );
      // 클라이언트 데이터 추가
      await setDcProductDatas((prev) => [
        ...prev,
        {
          account_id: accountId,
          code: productCode,
          discounted_release_price: discountPrice,
          is_on: true,
          name: productName,
          oms_code: productOmsCode,
          product_id: productId,
          release_price: releasePrice,
        },
      ]);
      // 클라이언트 데이터 초기화
      await setInputDatas({
        productId: null,
        discountPrice: 0,
        discountPercentage: 0,
      });
      await setDisplayDatas({
        productName: '',
        productCode: '',
        releasePrice: 0,
        discountPrice: 0,
        discountedPrice: 0,
      });
      alert('할인할 품목을 추가했습니다.');
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return (
    <div className="round-select-container">
      <Container>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <InnerForm style={{ width: '50%' }}>
            <Select
              options={productOptions}
              placeholder="품목을 선택해주세요."
              showSearch
              filterOption={(input, option) => {
                return option.label && option.label.includes(input);
              }}
              onChange={(value) => selectProduct(value)}
              value={inputDatas.productId}
            />
          </InnerForm>
          <InnerForm>
            <Input
              placeholder="할인금액"
              style={{ borderRadius: '20px', textAlign: 'end' }}
              value={inputDatas.discountPrice}
              type="number"
              onChange={(e) => inputDiscount(e.target.value, 'price')}
              disabled={displayDatas.releasePrice === 0}
            />
          </InnerForm>
          <InnerForm>
            <Input
              placeholder="할인율(%)"
              style={{ borderRadius: '20px', textAlign: 'end' }}
              value={inputDatas.discountPercentage}
              type="number"
              onChange={(e) => inputDiscount(e.target.value, 'percentage')}
              disabled={displayDatas.releasePrice === 0}
            />
          </InnerForm>
        </div>
        <div
          style={{
            border: '1px solid lightgray',
            borderRadius: '20px',
            marginTop: '10px',
            textAlign: 'center',
            padding: '20px',
          }}
        >
          {displayDatas.productCode.length > 0 &&
            displayDatas.productName.length > 0 && (
              <div>
                {displayDatas.productCode} / {displayDatas.productName}
              </div>
            )}
          {displayDatas.productCode.length === 0 &&
            displayDatas.productName.length === 0 && (
              <div>
                <Tag>{accountName}</Tag>에 할인해 줄 품목을 선택하세요.
              </div>
            )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              marginTop: '10px',
            }}
          >
            <Progress
              type="circle"
              percent={
                typeof inputDatas.discountPercentage === 'number'
                  ? inputDatas.discountPercentage
                  : 0
              }
            />
            <Timeline style={{ marginTop: '25px', marginLeft: '10px' }}>
              <Timeline.Item>
                <div>
                  상품금액: {displayDatas.releasePrice.toLocaleString()}
                </div>
              </Timeline.Item>
              <Timeline.Item>
                <div>
                  할인금액: -{displayDatas.discountPrice.toLocaleString()}
                </div>
                <div style={{ color: 'gray' }} />
              </Timeline.Item>
              <Timeline.Item>
                <div>
                  판매금액: {displayDatas.discountedPrice.toLocaleString()}
                </div>
              </Timeline.Item>
            </Timeline>
          </div>
        </div>
        <Button
          type="primary"
          style={{ marginTop: '15px', borderRadius: '20px' }}
          onClick={() => addDiscountData()}
        >
          <PlusOutlined />
          할인추가
        </Button>
      </Container>
    </div>
  );
};

export default AddDiscount;

AddDiscount.propTypes = {
  accountId: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired,
  setDcProductDatas: PropTypes.func.isRequired,
};

const Title = styled.div`
  margin-left: 5px;
  font-size: 15px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 5px;
`;
const Container = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
`;
const InnerForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 24%;
`;
const LabelStyle = styled.label`
  margin-bottom: 5px;
`;
const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
