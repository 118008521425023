import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Empty, Tag } from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  LIGHT_MODE_BACKGROUND,
  LIGHT_MODE_FONT_COLOR,
  darkModeColors,
  lightModeColors,
  borderRadiuses,
} from '../../constants';
import AbsoluteLeftText from '../../shared/components/AbsoluteLeftText';
import SalesLevelModal from './SalesLevelModal';

ChartJS.register(ArcElement, Tooltip, Legend);

const SalesLevelChart = (props) => {
  const { salesLevelData, monthValue } = props;
  const { darkMode } = useSelector((state) => state.darkMode);
  const Colors = darkMode ? darkModeColors : lightModeColors;
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (salesLevelData.length === 0) {
      setChartData([]);
      return;
    }
    const getSalesLevelChartData = (value, level, color) => {
      return {
        level: value,
        data: salesLevelData.filter((item) => item === level).length,
        color,
        order: level,
      };
    };
    const salesLevelChartData = [
      getSalesLevelChartData('위험', 1, Colors.armsone_red),
      getSalesLevelChartData('낮음', 2, Colors.armsone_pink),
      getSalesLevelChartData('보통', 3, Colors.armsone_orange),
      getSalesLevelChartData('높음', 4, Colors.armsone_green),
      getSalesLevelChartData('최고', 5, Colors.armsone_blue),
    ].sort((a, b) => a.data - b.data);
    const totalDataLength = salesLevelChartData.reduce(
      (total, item) => total + item.data,
      0
    );
    const result = salesLevelChartData.map((item) => ({
      ...item,
      level: `${item.level} (${((item.data / totalDataLength) * 100).toFixed(
        0
      )})`,
    }));
    setChartData(result);
  }, [salesLevelData]);

  const data = {
    labels: chartData.map((item) => item.level),
    datasets: [
      {
        label: '',
        data: chartData.map((item) => item.data),
        backgroundColor: chartData.map((item) => item.color),
      },
    ],
  };
  /*
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
  };
  */
  const options = {
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div
      style={{
        // border: '1px solid #efefef',
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
        color: darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR,
        width: '100%',
        height: '100%',
        position: 'relative',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: borderRadiuses.primary,
      }}
    >
      <SalesLevelModal>
        <h4
          style={{
            color: 'gray',
            position: 'absolute',
            left: '10px',
            top: '10px',
          }}
        >
          Level
        </h4>
      </SalesLevelModal>
      {chartData.length === 0 ? (
        <div style={{ color: 'gray' }}>차트를 불러올 수 없습니다.</div>
      ) : (
        <Doughnut
          data={data}
          options={options}
          style={{
            zIndex: '1',
            position: 'absolute',
            scale: '0.9',
            bottom: '35%',
          }}
        />
      )}
      <div
        style={{
          fontSize: '12px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          position: 'absolute',
          bottom: '10px',
        }}
      >
        {chartData
          .sort((a, b) => b.order - a.order)
          .map((item) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'gray',
                marginTop: '1.5px',
              }}
            >
              <div
                style={{
                  backgroundColor: `${item.color}`,
                  height: '10px',
                  width: '10px',
                  marginRight: '5px',
                  borderRadius: '10px',
                  border: darkMode && '1px solid gray',
                }}
              />
              <div style={{ fontSize: '11px' }}>{item.level}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default React.memo(SalesLevelChart);

SalesLevelChart.propTypes = {
  salesLevelData: PropTypes.instanceOf(Array).isRequired,
  monthValue: PropTypes.string.isRequired,
};
