import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

const DataLoading = (props) => {
  const { isLoading } = props;

  return (
    <div>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: '10',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#f7f7f7',
            padding: '10px',
            borderRadius: '10px',
          }}
        >
          <Spin />
          <span style={{ marginLeft: '10px', color: 'gray' }}>
            달력 데이터를 불러오는 중입니다...
          </span>
        </div>
      )}
    </div>
  );
};

export default DataLoading;

DataLoading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};
