import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const MonthlySalesTargetCell = (props) => {
  const { id, salesTarget, dateWord, handleSalesTarget, date } = props;
  return (
    <div>
      <Button
        style={{ zIndex: '1' }}
        onClick={() => handleSalesTarget({ id, date })}
        type="link"
      >
        {dateWord}
      </Button>
      <div style={{ fontSize: '12px', color: 'gray' }}>
        {salesTarget ? salesTarget.toLocaleString() : '-'}
      </div>
    </div>
  );
};

export default MonthlySalesTargetCell;

MonthlySalesTargetCell.propTypes = {
  id: PropTypes.number || PropTypes.null,
  salesTarget: PropTypes.number || PropTypes.null,
  dateWord: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  handleSalesTarget: PropTypes.func.isRequired,
};
