import React, { useState, useEffect, useCallback } from 'react';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Button } from 'antd';
import { RedoOutlined } from '@ant-design/icons';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';

import {
  borderRadiuses,
  darkModeColors,
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  lightModeColors,
  LIGHT_MODE_BACKGROUND,
  LIGHT_MODE_FONT_COLOR,
} from '../../constants';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import AbsoluteLeftText from '../../shared/components/AbsoluteLeftText';
import useSelectRange from '../../hook/useSelectRange';
import useHeadcount from '../../hook/useGetHeadcountData';
import useCalcAchievementRate from '../../hook/useCalcAchievementRate';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const localStorageKey = 'monthlyChartRange';

const ThreeYearsMonthlyChart = () => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.darkMode);
  const Colors = darkMode ? darkModeColors : lightModeColors;

  const { getAllSalesTarget, getAchievementRate } = useCalcAchievementRate();

  const [chartData, setChartData] = useState({
    data: [],
    range: { start: null, end: null },
  });
  const [allSalesTarget, setAllSalesTarget] = useState([]);
  const [maxSales, setMaxSales] = useState(400000000);

  useEffect(() => {
    (async () => {
      const newAllSalesTarget = await getAllSalesTarget();
      setAllSalesTarget(newAllSalesTarget);
    })();
  }, []);

  const getInitialData = useCallback(async () => {
    const fetchedChartData = await dispatch(
      asyncReadDataFetch({ table: 'v_monthly_sales_amount' })
    ).unwrap();
    return {
      data: fetchedChartData,
      range: {
        start: fetchedChartData[0].year_month,
        end: fetchedChartData[fetchedChartData.length - 1].year_month,
      },
    };
  }, []);

  const filterData = useCallback((data, selectedRange) => {
    return data.filter(
      (item) =>
        dayjs(item.year_month) >= dayjs(selectedRange.start) &&
        dayjs(item.year_month) <= dayjs(selectedRange.end)
    );
  }, []);

  const handleChartData = useCallback(async (isSelected, selectedRange) => {
    let newChartData;

    if (isSelected) {
      const initialData =
        chartData.data.length > 0 ? chartData.data : await getInitialData();
      newChartData = {
        data: filterData(initialData.data, selectedRange),
        range: selectedRange,
      };
    } else {
      newChartData = await getInitialData();
    }

    setChartData(newChartData);
  }, []);

  const [selectedRange, isSelected, selectRange, initialize] = useSelectRange(
    handleChartData,
    localStorageKey
  );

  useEffect(() => {
    handleChartData(isSelected, selectedRange);
  }, [isSelected]);

  const headcount = useHeadcount(isSelected, chartData.range);

  const getTooltipData = (tooltipItems) => {
    const [year, month] = tooltipItems[0].label.split('.');
    const curChartItem = chartData.data.filter(
      (item) => item.year_month === `${year}-${month}`
    );
    const {
      monthly_sales_amount,
      monthly_sales_store_amount,
      monthly_sales_onsite_amount,
    } = curChartItem[0];

    const total = Number(monthly_sales_amount);
    const online = Number(monthly_sales_store_amount);
    const onsite = Number(monthly_sales_onsite_amount);
    const offline = total - (online + onsite);

    const filteredSalesTarget = allSalesTarget.filter(
      (item) => item.date === `${year}-${month}`
    )[0];

    const salesTarget = filteredSalesTarget?.salesTarget || null;

    let achievementRate = '-';

    if (salesTarget) {
      achievementRate = getAchievementRate({
        salesAmount: total,
        salesTarget,
      });
    }

    const formattedSales = {
      total: Math.round(total / 10000),
      online: Math.round(online / 10000),
      offline: Math.round(offline / 10000),
      onsite: Math.round(onsite / 10000),
    };

    const percentedSales = {
      online: Math.round((online / total) * 100),
      offline: Math.round((offline / total) * 100),
      onsite: Math.round((onsite / total) * 100),
    };

    return {
      formattedSales,
      percentedSales,
      achievementRate,
    };
  };

  useEffect(() => {
    const getMaxSales = () => {
      const sumMonthSales = chartData.data.map((data) =>
        Number(data.monthly_sales_amount)
      );
      const newMaxSales = Math.max(...sumMonthSales);
      setMaxSales(newMaxSales);
    };
    getMaxSales();
  }, [chartData.data]);

  const options = {
    aspectRatio: 10.5,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        // usePointStyle: true,
        footerFont: {
          style: 'normal',
          weight: 'normal',
        },
        callbacks: {
          title: (tooltipItems) => {
            return `${tooltipItems[0].label} [${tooltipItems[0].raw}]`;
          },
          afterTitle: (tooltipItems) => {
            const d = getTooltipData(tooltipItems);
            return `
전체: ${d.formattedSales.total.toLocaleString()} [${d.achievementRate}%]`;
          },
          // labelPointStyle: (tooltipItem) => {
          //   return {
          //     pointStyle: false,
          //   };
          // },
          // label: (tooltipItem) => {
          //   const { label, dataset } = tooltipItem;
          //   if (dataset.label === '직원수') {
          //      return ` 직원수: ${tooltipItem.raw} 명`;
          //   }
          //   const [year, month] = label.split('.');
          //   const curChartItem = chartData.data.filter(
          //     (item) => item.year_month === `${year}-${month}`
          //   );
          //   const {
          //     monthly_sales_amount: total,
          //     monthly_sales_store_amount: online,
          //   } = curChartItem[0];
          //   const offline = total - online;

          //   const filteredSalesTarget = allSalesTarget.filter(
          //     (item) => item.date === `${year}-${month}`
          //   )[0];

          //   const salesTarget = filteredSalesTarget?.salesTarget || null;

          //   let achievementRate = '-';

          //   if (salesTarget) {
          //     achievementRate = getAchievementRate({
          //       salesAmount: Number(total),
          //       salesTarget,
          //     });
          //   }

          //   const formattedSales = {
          //     total: Math.round(Number(total) / 10000),
          //     online: Math.round(Number(online) / 10000),
          //     offline: Math.round(offline / 10000),
          //   };

          //   const percentedSales = {
          //     online: Math.round((online / total) * 100),
          //     offline: Math.round((offline / total) * 100),
          //   };

          //   if (dataset.label === '직원수') {
          //     return ` 전체: ${formattedSales.total.toLocaleString()} [${achievementRate}%]`;
          //   } else if (dataset.label === '온라인') {
          //     return ` 오프: ${formattedSales.offline.toLocaleString()} [${
          //       percentedSales.offline
          //     }%]`;
          //   } else if (dataset.label === '오프라인') {
          //     return ` 온라: ${formattedSales.online.toLocaleString()} [${
          //       percentedSales.online
          //     }%]`;
          //   }
          //   return ``;
          // },
          label: () => {
            return '';
          },
          beforeFooter: (tooltipItems) => {
            const d = getTooltipData(tooltipItems);
            return `오프: ${d.formattedSales.offline.toLocaleString()} [${
              d.percentedSales.offline
            }%]`;
          },
          footer: (tooltipItems) => {
            const d = getTooltipData(tooltipItems);
            return `온라: ${d.formattedSales.online.toLocaleString()} [${
              d.percentedSales.online
            }%]`;
          },
          afterFooter: (tooltipItems) => {
            const d = getTooltipData(tooltipItems);
            return `방판: ${d.formattedSales.onsite.toLocaleString()} [${
              d.percentedSales.onsite
            }%]`;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 20,
        },
        stacked: true,
      },
      y: {
        min: 0,
        max: maxSales,
        ticks: {
          stepSize: 100000000,
          callback: (value) => {
            if (value === 0) {
              return value;
            }
            const stepSize = String(value);
            return `${stepSize.slice(0, 2)}천`;
          },
        },
        stacked: true,
      },
      y1: {
        min: 0,
        max: 20,
        ticks: {
          callback: () => null,
        },
      },
    },
    onClick: (_, el) => {
      if (!el || el.length <= 0 || isSelected) {
        return;
      }
      const selectedIndex = el[0].index;
      const date = chartData.data[selectedIndex].year_month;
      const lastDate = chartData.data[chartData.data.length - 1].year_month;

      selectRange(date, lastDate);
    },
  };

  const getBarBgColor = (type) =>
    chartData.data.map((item) => {
      const { year_month: yearMonth, monthly_sales_store_amount: onlineSales } =
        item;

      const isJan = yearMonth.split('-')[1] === '01';
      const isSeparated = !!Number(onlineSales) || false;
      const isOnline = type === 'online';

      // light, dark 모두 회색 계열
      const janColor = darkMode ? darkModeColors.armsone_grey : '#e1e1e1';
      const offColor = darkMode ? darkModeColors.chart_bg_1 : '#bfbfbf';
      // const onColor = darkMode ? Colors.monthly_chart_store_bg : 'gray';
      const onColor = darkMode ? darkModeColors.chart_border_1 : 'gray';

      /*
      // light는 밝은 형형색색 컬러
      const janColor = Colors.armsone_grey;
      const offColor = Colors.chart_bg_1;
      // const onColor = Colors.monthly_chart_store_bg;
      const onColor = Colors.chart_border_1;
      */

      if (isSeparated && isOnline) {
        return onColor;
      }

      if (isJan) {
        return janColor;
      }

      return offColor;
    });

  const data = {
    labels: chartData.data.map((item) => {
      const [year, month] = item.year_month.split('-');
      return `${year}.${month}`;
    }),
    datasets: [
      {
        type: 'line',
        label: '직원수',
        data: headcount.map((item) => Number(item.headcount)),
        backgroundColor: Colors.armsone_grey,
        borderColor: Colors.armsone_deep_grey,
        yAxisID: 'y1',
      },
      {
        label: '방문판매',
        data: chartData.data.map((item) =>
          Number(item.monthly_sales_onsite_amount)
        ),
        backgroundColor: '#1d2226',
        hoverBackgroundColor: !isSelected && Colors.armsone_blue,
      },
      {
        label: '온라인',
        data: chartData.data.map((item) =>
          Number(item.monthly_sales_store_amount)
        ),
        backgroundColor: getBarBgColor('online'),
        hoverBackgroundColor: !isSelected && Colors.armsone_blue,
      },
      {
        label: '오프라인',
        data: chartData.data.map(
          (item) =>
            Number(item.monthly_sales_amount) -
            (Number(item.monthly_sales_store_amount) +
              Number(item.monthly_sales_onsite_amount))
        ),
        backgroundColor: getBarBgColor('offline'),
        hoverBackgroundColor: !isSelected && Colors.armsone_blue,
      },
    ],
  };

  return (
    <div
      style={{
        padding: '10px',
        // border: '1px solid #efefef',
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
        color: darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR,
        borderRadius: borderRadiuses.primary,
      }}
    >
      <div
        style={{
          color: 'gray',
          marginBottom: '5px',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AbsoluteLeftText
          left="3px"
          text={`범위 ${chartData.range.start}~${chartData.range.end}`}
          color={isSelected && Colors.armsone_blue}
        />
        <h4 style={{ color: 'gray', marginBottom: '5px', textAlign: 'center' }}>
          Sales graph
        </h4>
        <Button
          size="small"
          style={{
            right: '3px',
            position: 'absolute',
            width: '16px',
            height: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
            color: 'white',
            fontSize: '10px',
            backgroundColor: isSelected
              ? Colors.armsone_blue
              : Colors.armsone_grey,
          }}
          disabled={!isSelected}
          onClick={initialize}
        >
          <RedoOutlined />
        </Button>
      </div>
      <Bar options={options} data={data} />
    </div>
  );
};

export default ThreeYearsMonthlyChart;
