import {
  CalendarOutlined,
  PhoneOutlined,
  RightOutlined,
  StarFilled,
  StarOutlined,
  StarTwoTone,
} from '@ant-design/icons';
import { Button, Steps, Tag } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Avvvatars from 'avvvatars-react';
import styled from 'styled-components';
import CounsellingPostComment from './CounsellingPostComment';
import { COUNSELLING_REACTION_BAD } from '../../constants';

const CounsellingPostFields = (props) => {
  const { counsellingData, handleClickPersuadedButton, isPersuaded } = props;
  const history = useHistory();

  const returnStringValue = (data) => {
    return data || '-';
  };

  return (
    <div
      style={{
        border: '1px solid #efefef',
        borderRadius: '5px',
        padding: '29px',
      }}
    >
      <div>
        <Button
          type="link"
          style={{ fontWeight: 'bold', padding: '0', marginBottom: '5px' }}
          onClick={() => history.push('/counselling')}
          size="small"
        >
          신규상담/게시판
          <RightOutlined />
        </Button>
        <div
          style={{
            marginBottom: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ fontSize: '23px' }}>
              {counsellingData.accountName}
            </div>
            <div
              style={{
                backgroundColor: '#efefef',
                padding: '3px 8px',
                borderRadius: '20px',
                marginLeft: '7px',
                fontSize: '15px',
              }}
            >
              <PhoneOutlined style={{ marginRight: '3px' }} />
              <span style={{ margin: '0 5px', color: 'gray' }}>|</span>
              {counsellingData.contact}
            </div>
            <Tag
              color={
                counsellingData.reactionLevel === COUNSELLING_REACTION_BAD
                  ? 'red'
                  : 'blue'
              }
              style={{
                marginLeft: '7px',
                fontSize: '15px',
                borderRadius: '20px',
                padding: '3px 5px',
              }}
            >
              {counsellingData.reactionLevel}
            </Tag>
          </div>
          <Button
            onClick={handleClickPersuadedButton}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <span>
              {isPersuaded ? (
                <StarTwoTone
                  twoToneColor="#ffdd00"
                  style={{
                    color: 'yellow',
                  }}
                />
              ) : (
                <StarOutlined style={{ color: 'gray' }} />
              )}
            </span>
            <span
              style={{
                margin: '0 10px',
              }}
            >
              |
            </span>
            <span>설득 거래처</span>
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '10px' }}>
              <Avvvatars value={counsellingData.admin.name} style="shape" />
            </div>
            <div>
              <div>
                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>
                  {counsellingData.admin.name}
                </span>
                <span
                  style={{
                    fontSize: '13px',
                    color: 'gray',
                    fontWeight: 'bold',
                    marginLeft: '5px',
                  }}
                >
                  ({counsellingData.admin.code})
                </span>
              </div>
              <div
                style={{ marginTop: '5px', fontSize: '13px', color: 'gray' }}
              >
                {counsellingData.admin.email}
              </div>
            </div>
          </div>
          <div style={{ fontSize: '12px', color: 'gray' }}>
            {counsellingData.regDate}
          </div>
        </div>
      </div>
      <LineStyle />
      <div>
        <div style={{ display: 'flex' }}>
          <FormContainer>
            <FormTitle>상담 경로</FormTitle>
            <ButtonStyle type="primary">{counsellingData.route}</ButtonStyle>
          </FormContainer>
          <FormContainer style={{ marginLeft: '20px' }}>
            <FormTitle>상담 방식</FormTitle>
            <ButtonStyle type="primary">
              {counsellingData.counsellingType}
            </ButtonStyle>
          </FormContainer>
          <FormContainer style={{ marginLeft: '20px' }}>
            <FormTitle>구매 의향</FormTitle>
            <ButtonStyle type="primary">
              {counsellingData.purchaseIntention}
            </ButtonStyle>
          </FormContainer>
        </div>
        <div style={{ display: 'flex' }}>
          <FormContainer>
            <FormTitle>상담일</FormTitle>
            <DateContainer>
              <CalendarIconStyle />
              {returnStringValue(counsellingData.counsellingDate)}
            </DateContainer>
          </FormContainer>
          <FormContainer style={{ marginLeft: '30px' }}>
            <FormTitle>샘플발송</FormTitle>
            <DateContainer>
              <CalendarIconStyle />
              {returnStringValue(counsellingData.deliveryDate)}
            </DateContainer>
          </FormContainer>
        </div>
        <div style={{ display: 'flex' }}>
          <FormContainer>
            <FormTitle>등록 업체명</FormTitle>
            <InputContainer>
              {returnStringValue(counsellingData.registrationName)}
            </InputContainer>
          </FormContainer>
          <FormContainer style={{ marginLeft: '30px' }}>
            <FormTitle>구매일</FormTitle>
            <DateContainer>
              <CalendarIconStyle />
              {returnStringValue(counsellingData.purchaseDate)}
            </DateContainer>
          </FormContainer>
        </div>
      </div>
      <LineStyle />
      <ContentContainer>{counsellingData.content}</ContentContainer>
      <LineStyle />
      <CounsellingPostComment />
    </div>
  );
};

export default CounsellingPostFields;

CounsellingPostFields.propTypes = {
  counsellingData: PropTypes.instanceOf(Object).isRequired,
  handleClickPersuadedButton: PropTypes.func.isRequired,
  isPersuaded: PropTypes.bool.isRequired,
};

const LineStyle = styled.div`
  margin: 20px 0;
  width: 100%;
  border: 0.5px solid #efefef;
`;

const FormTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const FormContainer = styled.div`
  margin: 20px 0;
`;
const ButtonStyle = styled(Button)`
  border-radius: 36px;
  margin-right: 10px;
  font-weight: bold;
  width: 124px;
  height: 37px;
  font-size: 15px;
`;
const DateContainer = styled.div`
  background-color: #efefef;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 15px;
  width: 250px;
`;
const CalendarIconStyle = styled(CalendarOutlined)`
  margin-right: 10px;
`;
const InputContainer = styled(DateContainer)``;
const ContentContainer = styled.pre`
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
  font-size: 15px;
  white-space: pre-wrap;
  word-break: break-all;
`;
