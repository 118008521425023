import React, { useState } from 'react';
import { Button, Input, Space } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AuthLinks from './AuthLinks';

const SignupForm = (props) => {
  const { handleSignup } = props;
  const { isLoadingSignupUser } = useSelector((state) => state.signupUser);

  const [values, setValues] = useState({
    email: null,
    password: null,
    passwordCheck: null,
    username: null,
    front: null,
    back: null,
    intro: null,
  });

  const handleChangeValue = (name, value) => {
    if ((name === 'front' || name === 'back') && !/^[0-9]*$/.test(value)) {
      return;
    }

    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickSubmit = (e) => {
    e.preventDefault();

    handleSignup(values);
  };

  return (
    <form onSubmit={handleClickSubmit}>
      <Input
        size="large"
        placeholder="email"
        type="email"
        onChange={(e) => handleChangeValue('email', e.target.value)}
        value={values.email}
      />
      <Input.Password
        size="large"
        placeholder="password"
        style={{ marginTop: '10px' }}
        onChange={(e) => handleChangeValue('password', e.target.value)}
        value={values.password}
        minLength={4}
        maxLength={12}
      />
      <Input.Password
        size="large"
        placeholder="password-check"
        style={{ marginTop: '10px' }}
        onChange={(e) => handleChangeValue('passwordCheck', e.target.value)}
        value={values.passwordCheck}
        minLength={4}
        maxLength={12}
      />
      <Input
        size="large"
        placeholder="username"
        style={{ marginTop: '30px' }}
        onChange={(e) => handleChangeValue('username', e.target.value)}
        value={values.username}
        minLength={2}
        maxLength={4}
      />
      <Space.Compact style={{ marginTop: '10px' }} size="large">
        <Input style={{ width: '20%' }} defaultValue="010" disabled />
        <Input
          style={{ width: '40%' }}
          maxLength={4}
          minLength={4}
          onChange={(e) => handleChangeValue('front', e.target.value)}
          value={values.front}
          placeholder="front"
        />
        <Input
          style={{ width: '40%' }}
          maxLength={4}
          minLength={4}
          onChange={(e) => handleChangeValue('back', e.target.value)}
          value={values.back}
          placeholder="back"
        />
      </Space.Compact>
      <Input.TextArea
        size="large"
        placeholder="intro"
        style={{ marginTop: '10px' }}
        onChange={(e) => handleChangeValue('intro', e.target.value)}
        value={values.intro}
        minLength={5}
        maxLength={50}
        showCount
      />
      <Button
        htmlType="submit"
        loading={isLoadingSignupUser}
        type="primary"
        style={{ marginTop: '30px', width: '100%' }}
        size="large"
        disabled={
          !values.email ||
          !values.password ||
          !values.passwordCheck ||
          !values.username ||
          !values.front ||
          !values.back ||
          !values.intro
        }
      >
        등록
      </Button>
      <AuthLinks />
    </form>
  );
};

export default SignupForm;

SignupForm.propTypes = {
  handleSignup: PropTypes.func.isRequired,
};
