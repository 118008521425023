import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { asyncReadFilteredDataFetch } from '../redux/modules/shared/readFilteredData';

const useCalcAchievementRate = ({ salesAmount, salesMonth }) => {
  const dispatch = useDispatch();

  const [achievementRate, setAchievementRate] = useState(null);

  // 해당 월 매출 목표치 값 가져오기
  const getSalesTarget = useCallback(
    async (salesMonth) => {
      const fetchedSalesTargetData = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'sales_target',
          eqKey: 'date',
          eqValue: salesMonth,
          readMethod: 'same',
        })
      )
        .unwrap()
        .then((res) => res[0]);
      const salesTarget = fetchedSalesTargetData?.sales_target;
      return salesTarget;
    },
    [salesAmount]
  );

  // 해당 월 매출 목표치에 대한 누적 매출액의 달성율 가져오기
  const getAchievementRate = useCallback(
    ({ salesAmount, salesTarget }) => {
      const achievementRate = (salesAmount / salesTarget) * 100;
      const formattedAchievementRate = Math.round(achievementRate);
      return formattedAchievementRate;
    },
    [salesAmount]
  );

  // 위의 두 함수 처리
  const handleAchievementRate = useCallback(
    async ({ salesMonth, salesAmount }) => {
      const salesTarget = await getSalesTarget(salesMonth);
      const isExistSalesTarget = !!salesTarget;
      if (!isExistSalesTarget) {
        setAchievementRate('-');
        return;
      }
      const achievementRate = getAchievementRate({ salesAmount, salesTarget });
      setAchievementRate(achievementRate);
    },
    [salesAmount]
  );

  useEffect(() => {
    handleAchievementRate({ salesMonth, salesAmount });
  }, [salesAmount]);

  return achievementRate;
};

export default useCalcAchievementRate;
