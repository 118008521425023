import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Input, Button, Radio, Select, Divider, Space } from 'antd';
import { useSelector } from 'react-redux';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import Phone from '../../../shared/components/phone/Phone';

const FormStyle = styled(Form)`
  padding: 10px;
`;
const ButtonStyle = styled(Button)`
  font-weight: 700;
  border-radius: 5px;
`;
const LabelStyle = styled.p``;

const TableFormModal = (props) => {
  const { items, formData, buttonText, onFinish } = props;
  const { modalInfo } = useSelector((state) => state.modal);
  const [form] = Form.useForm();

  // form 타입이 삭제 or 수정 or 추가 인지
  // 삭제일 경우 form을 disabled처리하기 위해
  const formType = modalInfo.modalTitle.slice(-2);
  // form span 객체 값
  const spanCol = useMemo(() => {
    return { span: 24 };
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...formData,
    });
    console.log(formData);
  }, [form, formData]);

  return (
    <FormStyle
      form={form}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={(e) => console.log(e)}
      labelCol={spanCol}
      wrapperCol={spanCol}
      style={{ marginBottom: '45px' }}
    >
      {items.map((item) => {
        if (item.type === 'input') {
          return (
            <Form.Item
              key={item.name}
              label={<LabelStyle>{item.label}</LabelStyle>}
              name={item.name}
              rules={[
                {
                  required: true,
                  message: item.message,
                },
              ]}
            >
              <Input
                type={
                  (item.name === 'code' && 'number') ||
                  (item.name === 'phone' && 'tel')
                }
                pattern={item.name === 'phone' && '[0-9]{3}-[0-9]{4}-[0-9]{4}'}
                disabled={
                  formType === '삭제' ||
                  item.disable ||
                  (item.name === 'email' && formType === '수정')
                }
                placeholder={item.message}
                style={{ borderRadius: '0px' }}
              />
            </Form.Item>
          );
        } else if (item.type === 'radioButton') {
          return (
            <Form.Item
              key={item.name}
              label={<LabelStyle>{item.label}</LabelStyle>}
              name={item.name}
              rules={[
                {
                  required: true,
                  message: item.message,
                },
              ]}
            >
              <Radio.Group disabled={formType === '삭제'} buttonStyle="solid">
                {item.options.map((option) => {
                  return (
                    <Radio.Button
                      key={option.value}
                      value={option.value}
                      style={{ padding: '0 30px' }}
                    >
                      {option.label}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          );
        } else if (item.type === 'radio') {
          return (
            <Form.Item
              key={item.name}
              label={<LabelStyle>{item.label}</LabelStyle>}
              name={item.name}
              rules={[
                {
                  required: true,
                  message: item.message,
                },
              ]}
            >
              <Radio.Group
                disabled={formType === '삭제'}
                defaultValue={item.name === 'is_hidden' && false}
              >
                {item.options.map((option) => {
                  return (
                    <Radio value={option.value} key={option.value}>
                      {option.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          );
        } else if (item.type === 'select') {
          return (
            <Form.Item
              key={item.name}
              label={<LabelStyle>{item.label}</LabelStyle>}
              name={item.name}
              className="select-container"
            >
              <Select
                disabled={formType === '삭제'}
                options={item.options}
                placeholder={item.message}
              />
            </Form.Item>
          );
        } else if (item.type === 'phone') {
          <Phone disabled={formType === '삭제'} />;
        }
      })}
      <Form.Item
        style={{
          margin: '0',
          textAlign: 'right',
          position: 'absolute',
          bottom: '0',
          right: '0',
          width: '100%',
          padding: '12px',
          borderTop: '1px solid #e7e7e7',
          backgroundColor: '#fafafa',
        }}
      >
        <ButtonStyle
          htmlType="submit"
          type="primary"
          danger={buttonText === '삭제'}
        >
          {buttonText === '추가' && <PlusOutlined />}
          {buttonText === '수정' && <EditOutlined />}
          {buttonText === '삭제' && <DeleteOutlined />}
          {buttonText}
        </ButtonStyle>
      </Form.Item>
    </FormStyle>
  );
};

export default React.memo(TableFormModal);

TableFormModal.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
  buttonText: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
};
