import React from 'react';
import PropTypes from 'prop-types';
import { Statistic } from 'antd';
import CountUp from 'react-countup';
import { DollarOutlined } from '@ant-design/icons';

import { DARK_MODE_BACKGROUND, LIGHT_MODE_BACKGROUND } from '../../constants';

const AmountOfSalesByCity = (props) => {
  const { darkMode, selectedCity } = props;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '10px 20px',
        borderRadius: '10px',
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
      }}
    >
      <h4>{selectedCity} 매출액</h4>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <div style={{ position: 'absolute', left: '0px' }}>₩</div>
        <h3>0</h3>
      </div>
    </div>
  );
};

export default AmountOfSalesByCity;

AmountOfSalesByCity.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  selectedCity: PropTypes.string.isRequired,
};
