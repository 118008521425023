import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useSupabase from '../../../serverConfig';

import supabase from '../../../supabase';

import axiosInstance from '../../../axios';

const initialState = {
  isLoadingReadData: false,
  isErrorReadData: false,
  isSuccessReadData: false,
};

// 등록일 (reg_date) 기준 내림차순으로 정렬
const handleSortData = (arr) => {
  const sortedArr = arr.sort((a, b) => {
    return new Date(b.reg_date) - new Date(a.reg_date);
  });
  return sortedArr;
};

const asyncReadDataFetch = createAsyncThunk(
  'readDataSlice/asyncReadDataFetch',
  async (values, { rejectWithValue }) => {
    // 데이터 length 리턴
    if (values.reqDataLen) {
      const { table } = values;
      try {
        const { data, error } = useSupabase
          ? await supabase.from(table).select()
          : await axiosInstance.get(`/api/dynamic-crud/${table}/all`);
        if (error) throw error;
        return data.length;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
    // values의 필수 값 => table
    const { table, dataGenYr } = values;
    // order 데이터 (Bulk로 페칭)
    if (table === 'order') {
      try {
        let resultData = null;
        let resultError = null;
        if (window.location.pathname === '/sales-inquiry') {
          const { data, error } = useSupabase
            ? await supabase.from('order').select().eq('payment_status', '1')
            : await axiosInstance.get(
                `/api/dynamic-crud/${table}/key?key=payment_status&value=1`
              );
          resultData = data;
          resultError = error;
        }
        if (window.location.pathname === '/order') {
          const { data, error } = useSupabase
            ? await supabase.from('order').select().eq('payment_status', '0')
            : await axiosInstance.get(
                `/api/dynamic-crud/${table}/key?key=payment_status&value=0`
              );
          resultData = data;
          resultError = error;
        }
        if (window.location.pathname === '/estimate') {
          const { data, error } = useSupabase
            ? await supabase.from('order').select().eq('payment_status', '2')
            : await axiosInstance.get(
                `/api/dynamic-crud/${table}/key?key=payment_status&value=2`
              );
          resultData = data;
          resultError = error;
        }
        const filteredData = resultData.filter((data) => data.state !== 9);
        return handleSortData(filteredData);
        /*
        // 기존 페칭방식
        let newData = null;
        let newError = null;
        if (dataGenYr) {
          const { data, error } = await supabase
            .from('order')
            .select()
            .like('order_date', `%${dataGenYr}%`);
          newData = data;
          newError = error;
        } else {
          const { data, error } = await supabase.from(table).select();
          newData = data;
          newError = error;
        }
        if (newError) throw newError;
        // state가 9(삭제)가 아닌 값 필터링
        let paymentStatusFilter = '0';
        if (window.location.pathname === '/sales-inquiry')
          paymentStatusFilter = '1';
        if (window.location.pathname === '/order') paymentStatusFilter = '0';
        if (window.location.pathname === '/estimate') paymentStatusFilter = '2';
        const filteredData = newData.filter(
          (v) => v.state !== 9 && v.payment_status === paymentStatusFilter
        );
        return handleSortData(filteredData);
        */
      } catch (error) {
        return rejectWithValue(error);
      }
    }
    // hidden_data테이블의 hide컬럼 값에 따라 렌더링
    if (table === 'product' || table === 'sale') {
      try {
        const res = useSupabase
          ? await supabase
              .from('hidden_data')
              .select('hide')
              .eq('table_name', table)
          : await axiosInstance.get(
              `/api/dynamic-crud/hidden_data/key?key=table_name&value=${table}`
            );

        let hide;
        if (res.data[0]) {
          hide = res.data[0].hide;
        }
        const { data, error } = useSupabase
          ? await supabase.from(table).select()
          : await axiosInstance.get(`/api/dynamic-crud/${table}/all`);

        if (error) throw error;
        const filteredData = data.filter((v) => {
          let result = [];
          if (hide) {
            result = v.state !== 9 && !v.is_hidden;
          } else {
            result = v.state !== 9;
          }
          return result;
        });
        return handleSortData(filteredData);
      } catch (error) {
        return rejectWithValue(error);
      }
    }

    // 그 외 데이터
    try {
      const { data, error } = useSupabase
        ? await supabase.from(table).select()
        : await axiosInstance.get(`/api/dynamic-crud/${table}/all`);

      if (error) throw error;
      // state가 9(삭제)가 아닌 값 필터링
      const filteredData = data.filter((v) => {
        let result = [];
        if (table === 'v_account_hidden') {
          result = v.state !== 9 && !v.is_hidden;
        } else {
          result = v.state !== 9;
        }
        return result;
      });
      return handleSortData(filteredData);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const readDataSlice = createSlice({
  name: 'readData',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncReadDataFetch.pending, (state) => {
      state.isLoadingReadData = true;
    });
    builder.addCase(asyncReadDataFetch.fulfilled, (state) => {
      state.isLoadingReadData = false;
      state.isSuccessReadData = true;
    });
    builder.addCase(asyncReadDataFetch.rejected, (state) => {
      state.isLoadingReadData = false;
      state.isErrorReadData = true;
    });
  },
});

export default readDataSlice.reducer;
export { asyncReadDataFetch };
