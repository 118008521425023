import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import supabase from '../../../supabase';

const initialState = {
  isLoadingRefreshSession: false,
  isSuccessRefreshSession: false,
  isErrorRefreshSession: false,
};

const asyncRefreshSessionFetch = createAsyncThunk(
  'RefreshSessionSlice/asyncRefreshSessionFetch',
  async () => {
    try {
      const { data, error } = await supabase.auth.refreshSession();
      if (error) throw error;
      if (data) {
        // reducer로 보내줄 데이터 생성
        let userData = {};
        // user 테이블을 가져옴.
        const { id } = data.session.user;
        const user = await supabase.from('profiles').select().eq('id', id);
        // permission이 false인 user
        if (!user.data[0].permission) {
          // reducer로 보내줄 데이터 payload
          userData = { userInfo: { ...user.data[0] }, isLoggedIn: true };
          // user 테이블의 permission이 false면 permission 페이지로 이동
          // history.push('/permission');
          // redux의 user state를 해당 정보로 업데이트함.
          return userData;
        }
        // reducer로 보내줄 데이터 payload
        userData = { userInfo: { ...user.data[0] }, isLoggedIn: true };
        // redux의 user state를 해당 정보로 업데이트함.
        return userData;
      }
    } catch (_) {
      return { isLoggedIn: false };
    }
  }
);

const refreshSessionSlice = createSlice({
  name: 'refreshSession',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncRefreshSessionFetch.pending, (state) => {
      state.isLoadingRefreshSession = true;
    });
    builder.addCase(asyncRefreshSessionFetch.fulfilled, (state) => {
      state.isLoadingRefreshSession = false;
      state.isSuccessRefreshSession = true;
    });
    builder.addCase(asyncRefreshSessionFetch.rejected, (state) => {
      state.isLoadingRefreshSession = false;
      state.isErrorRefreshSession = true;
    });
  },
});

export default refreshSessionSlice.reducer;
export { asyncRefreshSessionFetch };
