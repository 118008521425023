import { DatabaseOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';

const PurchaseStatus = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <DatabaseOutlined />,
        breadCrumbNav: ' 구매현황',
      },
    ],
    []
  );
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px' }}>구매현황 기능구현중.</div>
    </LayoutComponent>
  );
};

export default PurchaseStatus;
