import { useCallback, useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import firestore from '../firebase';

const useGetFirestoreData = () => {
  const [firestoreData, setFirestoreData] = useState([]);
  const [firestoreId, setFirestoreId] = useState([]);

  const getDocumentDataList = useCallback((querySnapshot) => {
    const documentDataList = [];
    querySnapshot.forEach((document) => {
      const { keys } = document.data();
      keys.forEach((key) => {
        const documentData = document.data()[key];
        documentDataList.push(documentData);
      });
    });
    return documentDataList;
  }, []);

  const getFirestoreData = useCallback(async () => {
    const querySnapshot = await getDocs(collection(firestore, 'ViewData'));
    const documentIdList = querySnapshot.docs.map((doc) => doc.id);
    const documentDataList = getDocumentDataList(querySnapshot);
    setFirestoreId(documentIdList);
    setFirestoreData(documentDataList);
  }, []);

  useEffect(() => {
    getFirestoreData();
  }, []);

  return [firestoreData, firestoreId];
};

export default useGetFirestoreData;
