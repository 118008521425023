import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const useGetRangeText = (dates) => {
  const [rangeText, setRangeText] = useState();

  useEffect(() => {
    if (dates.length === 0 || !dates) return;
    const start = dayjs(dates[0]);
    const end = dayjs(dates[dates.length - 1]);
    const diffDays = dayjs(dayjs(end).diff(start));
    const result = `${start.format('YYYY-MM-DD')}~${end.format(
      'YYYY-MM-DD'
    )}(${diffDays.format('D')}일)`;
    setRangeText(result);
  }, [dates]);

  return rangeText;
};

export default useGetRangeText;
