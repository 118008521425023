import { Skeleton, Table } from 'antd';
import React from 'react';

const EmptyTable = () => {
  const emptyColumn = [
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Skeleton.Input active size="small" style={{ width: '300px' }} />
          <Skeleton.Input active size="small" style={{ width: '300px' }} />
          <Skeleton.Input active size="small" style={{ width: '300px' }} />
        </div>
      ),
      key: 'empty',
      dataIndex: 'skeleton',
      width: '100%',
      align: 'center',
      render: () => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Skeleton.Input active size="small" style={{ width: '300px' }} />
          <Skeleton.Input active size="small" style={{ width: '300px' }} />
          <Skeleton.Input active size="small" style={{ width: '300px' }} />
        </div>
      ),
    },
  ];
  const emptyData = [
    { key: 'emptyData1', skeleton: '' },
    { key: 'emptyData2', skeleton: '' },
    { key: 'emptyData3', skeleton: '' },
    { key: 'emptyData4', skeleton: '' },
    { key: 'emptyData5', skeleton: '' },
    { key: 'emptyData6', skeleton: '' },
    { key: 'emptyData7', skeleton: '' },
    { key: 'emptyData8', skeleton: '' },
    { key: 'emptyData9', skeleton: '' },
    { key: 'emptyData10', skeleton: '' },
  ];
  return (
    <Table
      dataSource={emptyData}
      columns={emptyColumn}
      size="large"
      pagination={{ position: ['bottomRight'] }}
    />
  );
};

export default EmptyTable;
