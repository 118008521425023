import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const ProductValue = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getProducts = async () => {
      try {
        await setLoading(true);
        const products = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'order_product',
            eqKey: 'order_id',
            eqValue: id,
          })
        )
          .unwrap()
          .then((res) => res);
        // const notAcctDcPds = products.filter((pd) => !pd.is_account_dc);
        const result = products.map((data) => ({
          name: data.product_name,
          quantity: data.product_quantity,
        }));
        await setValue(result);
        setLoading(false);
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    };
    getProducts();
  }, [dispatch, id]);

  const getProductText = useCallback(
    (products) => {
      if (products.length <= 0) {
        return <div>없음</div>;
      }

      const onlyProducts = products.filter(
        (product) => !product.name.includes('택배비')
      );

      if (onlyProducts.length <= 0) {
        return <div>출고상품(품목) 없음</div>;
      }

      return onlyProducts.map((product) => (
        <div>
          <span>{product.name}</span>
          <span style={{ marginLeft: '3px' }}>{product.quantity}개</span>
        </div>
      ));
    },
    [value]
  );

  return (
    <div>
      {loading ? (
        <Skeleton.Input active style={{ height: '20px' }} />
      ) : (
        <Link to={`/order/${id}`}>{getProductText(value)}</Link>
      )}
    </div>
  );
};

export default ProductValue;

ProductValue.propTypes = {
  id: PropTypes.string.isRequired,
};
