/* eslint react/style-prop-object: "off" */
import React from 'react';
import Avvvatars from 'avvvatars-react';
import PropTypes from 'prop-types';

const Avvvatar = (props) => {
  const { username } = props;
  return <Avvvatars style="shape" value={username} />;
};

export default Avvvatar;

Avvvatar.propTypes = {
  username: PropTypes.string.isRequired,
};
