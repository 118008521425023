import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import UnOrderedValue from './UnOrderedValue';
import OrderedCard from './OrderedCard';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncDeleteDataFetch } from '../../redux/modules/shared/deleteData';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';

const OrderSettingHandler = (props) => {
  const { productDatas } = props;
  const dispatch = useDispatch();
  const [orderedCards, setOrderedCards] = useState([]);
  const [unOrderedValues, setUnOrderedValues] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    setOrderedCards(
      productDatas
        .filter((item) => item.order)
        .sort((a, b) => a.order - b.order)
        .map((item, index) => ({ ...item, order: index + 1 }))
    );
    setUnOrderedValues(productDatas.filter((item) => !item.order));
  }, [productDatas]);

  const findCard = (order) => {
    const card = orderedCards.filter((item) => item.order === order)[0];
    return { card, index: orderedCards.indexOf(card) };
  };

  const moveCard = (order, atIndex) => {
    const { card, index } = findCard(order);
    const updatedCards = [...orderedCards];
    updatedCards.splice(index, 1);
    updatedCards.splice(atIndex, 0, card);
    setOrderedCards([...updatedCards]);
  };

  const addCard = (id) => {
    const selectedProduct = unOrderedValues.filter((item) => item.id === id)[0];
    setOrderedCards((prev) => [
      ...prev,
      { ...selectedProduct, order: prev.length + 1 },
    ]);
    setUnOrderedValues((prev) => prev.filter((item) => item.id !== id));
  };

  const removeCard = (id) => {
    const selectedProduct = orderedCards.filter((item) => item.id === id)[0];
    setUnOrderedValues((prev) => [
      ...prev,
      { ...selectedProduct, order: null },
    ]);
    setOrderedCards((prev) =>
      prev
        .filter((item) => item.id !== id)
        .map((item, index) => ({ ...item, order: index + 1 }))
    );
  };

  const handleSave = async () => {
    try {
      setSaveLoading(true);
      const fetchedOrderSettings = await dispatch(
        asyncReadDataFetch({ table: 'inventory_order_setting' })
      ).unwrap();
      for (const orderSetting of fetchedOrderSettings) {
        const orderSettingId = orderSetting.id;
        await dispatch(
          asyncDeleteDataFetch({
            id: orderSettingId,
            table: 'inventory_order_setting',
          })
        );
      }
      for (let i = 0; i < orderedCards.length; i += 1) {
        const productId = orderedCards[i].id;
        const order = i + 1;
        await dispatch(
          asyncCreateDataFetch({
            table: 'inventory_order_setting',
            order,
            product_id: productId,
          })
        );
      }
      setSaveLoading(false);
      alert('출력 순번이 저장되었습니다.');
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '50%',
        }}
      >
        <div style={{ margin: '10px 0', fontWeight: '600', fontSize: '15px' }}>
          미순번 품목
        </div>
        <section style={{ display: 'flex', flexDirection: 'column' }}>
          {unOrderedValues.map((item) => (
            <UnOrderedValue addCard={addCard} item={item} />
          ))}
        </section>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '50%',
        }}
      >
        <div style={{ margin: '10px 0', fontWeight: '600', fontSize: '15px' }}>
          순번 품목
        </div>
        <DndProvider backend={HTML5Backend}>
          <section style={{ display: 'flex', flexDirection: 'column' }}>
            {orderedCards.map((card, index) => (
              <OrderedCard
                key={card.id}
                order={card.order}
                cardIdx={index}
                card={card}
                removeCard={removeCard}
                moveCard={moveCard}
                findCard={findCard}
              />
            ))}
          </section>
        </DndProvider>
        <Button
          style={{ marginTop: '10px' }}
          type="primary"
          onClick={handleSave}
          loading={saveLoading}
        >
          <SaveOutlined />
          저장
        </Button>
      </div>
    </>
  );
};

export default OrderSettingHandler;

OrderSettingHandler.propTypes = {
  productDatas: PropTypes.instanceOf(Array).isRequired,
};
