import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const AdminValue = (props) => {
  const { adminCode } = props;
  const dispatch = useDispatch();

  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAdmin = async () => {
      try {
        await setLoading(true);
        const admin = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'profiles',
            eqKey: 'code',
            eqValue: adminCode,
          })
        )
          .unwrap()
          .then((res) => res[0]);
        const { username } = admin || '';
        await setValue(username);
        setLoading(false);
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };
    getAdmin();
  }, [adminCode, dispatch]);
  return (
    <div>
      {loading ? (
        <Skeleton.Input size="small" active style={{ height: '20px' }} />
      ) : (
        <div>{value}</div>
      )}
    </div>
  );
};

export default AdminValue;

AdminValue.propTypes = {
  adminCode: PropTypes.string.isRequired,
};
