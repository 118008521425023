import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { numberToKorean } from 'number-to-korean';
import * as XLSX from 'xlsx';
import { getAccountListByIds } from '../apis/accountApi';
import { sendMailWithFile } from '../apis/fileApi';

export const changeNumToKr = (number) => {
  const krWithNumString = numberToKorean(number);
  const krArray = ['이', '삼', '사', '오', '육', '칠', '팔', '구'];
  const numArray = [2, 3, 4, 5, 6, 7, 8, 9];
  let onlyKrString = '';
  for (const oneLetter of krWithNumString) {
    if (/^[0-9]+$/.test(oneLetter)) {
      const idx = numArray.findIndex((num) => num === Number(oneLetter));
      onlyKrString += krArray[idx];
    } else {
      onlyKrString += oneLetter;
    }
  }
  return onlyKrString;
};

// 거래처리스트 데이터 포맷
const formatAccountList = (accountList) => {
  const accountMap = new Map();

  for (const account of accountList) {
    const { account_id, address, is_representative, is_sample } = account;
    // Map에서 account_id로 해당 객체를 찾음
    if (!accountMap.has(account_id)) {
      // account_id가 없으면 초기화하여 Map에 추가
      accountMap.set(account_id, {
        ...account,
        is_sample: is_sample ? 'O' : 'X',
        mainAdr: is_representative ? address : '',
        subAdr: is_representative ? [] : [address],
      });
    } else {
      // 이미 존재하는 경우 업데이트
      const existingAccount = accountMap.get(account_id);
      if (is_representative) {
        existingAccount.mainAdr = address; // 대표 주소 업데이트
      } else {
        existingAccount.subAdr.push(address); // 서브 주소 추가
      }
    }
  }

  return Array.from(accountMap.values());
};

// 거래처리스트 엑셀 컨텐츠 생성
const generateExcelContents = (columns, accountList, subAdrCount) => {
  const excelSubAdrs = new Array(subAdrCount)
    .fill(null)
    .map((_, idx) => `주소지 ${idx + 1}`);
  const excelColumns = [
    ...columns.map((column) => column.title),
    '기계/비닐',
    '대표 주소지',
    ...excelSubAdrs,
  ];
  const excelRowKeys = [
    ...columns.map((column) => column.dataIndex),
    'machine_vinyl',
    'mainAdr',
    ...excelSubAdrs.map((_, idx) => idx),
  ];
  const excelRows = accountList.map((account) => {
    return excelRowKeys.map((rowKey) => {
      if (typeof rowKey === 'number') {
        return account.subAdr[rowKey];
      } else {
        return account[rowKey];
      }
    });
  });
  return { excelColumns, excelRows };
};

const downloadExcelFile = () => {
  const handleEstimateForm = async (headerData, contentData, footerData) => {
    const filePath = `${process.env.PUBLIC_URL}견적서양식.xlsx`;
    try {
      const response = await fetch(filePath);
      if (!response.ok) {
        console.log(response.status);
        return;
      }
      const blob = await response.blob();
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(blob);
      const worksheet = workbook.getWorksheet('Sheet2');

      // 엑셀form header 데이터 추가
      const dateCell = worksheet.getCell('D2');
      const accountCell = worksheet.getCell('D3');
      const referenceCell = worksheet.getCell('D4');
      const telFaxCell = worksheet.getCell('D5');
      const paymentTermsCell = worksheet.getCell('D6');
      const expirationDateCell = worksheet.getCell('D7');
      const adminCell = worksheet.getCell('K9');
      dateCell.value = headerData.date;
      accountCell.value = headerData.account;
      referenceCell.value = headerData.reference;
      telFaxCell.value = headerData.telFax;
      paymentTermsCell.value = headerData.paymentTerms;
      expirationDateCell.value = headerData.expirationDate;
      adminCell.value = headerData.admin;

      // 엑셀form content 데이터 추가
      for (let i = 0; i < contentData.length; i += 1) {
        worksheet.getCell(`A${i + 15}`).value = contentData[i].code;
        worksheet.getCell(`C${i + 15}`).value = contentData[i].name;
        worksheet.getCell(`L${i + 15}`).value = contentData[i].quantity;
        worksheet.getCell(`M${i + 15}`).value = contentData[i].price;
        worksheet.getCell(`P${i + 15}`).value = contentData[i].supply;
        worksheet.getCell(`Q${i + 15}`).value = contentData[i].vat;
      }

      // 엑셀form footer 데이터 추가
      let quantity = 0;
      let supply = 0;
      let vat = 0;
      let total = 0;
      for (const data of footerData) {
        quantity += data.quantity;
        supply += data.supply;
        vat += data.vat;
        total += data.total;
      }
      worksheet.getCell('B43').value = Math.round(quantity);
      worksheet.getCell('F43').value = Math.round(supply);
      worksheet.getCell('J43').value = Math.round(vat);
      worksheet.getCell('P43').value = Math.round(total);
      worksheet.getCell('D12').value = `${changeNumToKr(Math.round(total))}원`;
      worksheet.getCell('P12').value = Math.round(total);

      const modifiedFileBlob = await workbook.xlsx.writeBuffer();
      const modifiedFile = new File(
        [modifiedFileBlob],
        `${headerData.date}_견적서_${headerData.account}.xlsx`,
        {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }
      );
      saveAs(modifiedFile);
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const handleInvoiceForm = async (invoiceData) => {
    const { headerData, contentData, footerData } = invoiceData;
    const today1 = dayjs().format('YYYY/MM/DD');
    const today2 = dayjs().format('YYYYMMDD');

    try {
      // 엑셀 form 가져오기
      const isShort = contentData && contentData.length <= 12;
      const filePath = isShort
        ? `${process.env.PUBLIC_URL}거래명세서양식_v1.xlsx`
        : `${process.env.PUBLIC_URL}거래명세서양식_v2.xlsx`;

      const response = await fetch(filePath);
      if (!response.ok) {
        console.log(response.status);
        return;
      }
      const blob = await response.blob();
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(blob);
      const sheetName = isShort ? '12줄' : '24줄';
      const worksheet = workbook.getWorksheet(sheetName);

      // 엑셀form header 데이터 추가
      worksheet.getCell('M2').value = today1; // 일련번호
      worksheet.getCell('A4').value = headerData.name; // 거래처명
      worksheet.getCell('A5').value = headerData.address; // 주소
      worksheet.getCell('D6').value = headerData.contact; // 연락처
      worksheet.getCell('D8').value = `${changeNumToKr(
        Math.round(footerData.sum)
      )}원`; // 한글금액
      worksheet.getCell('P8').value = footerData.sum; // 숫자금액

      // 엑셀form content 데이터 추가
      for (let i = 0; i < contentData.length; i += 1) {
        worksheet.getCell(`A${i + 11}`).value = dayjs(
          contentData[i].date
        ).format('MM/DD');
        worksheet.getCell(`C${i + 11}`).value = contentData[i].name;
        worksheet.getCell(`L${i + 11}`).value = contentData[i].quantity;
        worksheet.getCell(`M${i + 11}`).value = contentData[i].price;
        worksheet.getCell(`P${i + 11}`).value = contentData[i].supply;
        worksheet.getCell(`S${i + 11}`).value = contentData[i].vat;
      }

      const footerLineNum = isShort ? '24' : '38';
      // 엑셀form footer 데이터 추가
      worksheet.getCell(`B${footerLineNum}`).value = footerData.quantity; // 수량
      worksheet.getCell(`F${footerLineNum}`).value = footerData.supply; // 공급가액 (총)
      worksheet.getCell(`J${footerLineNum}`).value = footerData.vat; // 부가세 (총)
      worksheet.getCell(`N${footerLineNum}`).value = footerData.sum; // 합계 (총)

      // 엑셀form 덮어쓰기
      const modifiedFileBlob = await workbook.xlsx.writeBuffer();
      const modifiedFile = new File(
        [modifiedFileBlob],
        `${today2}_거래명세서_${headerData.name}.xlsx`,
        {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }
      );
      saveAs(modifiedFile);
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const handleTaxInvoiceTable = async (title, columns, rows, type) => {
    if (rows.length === 0) {
      alert('데이터가 없으므로 파일을 다운로드할 수 없습니다.');
      return;
    }
    try {
      // 시트명 정의
      const sheetName =
        type === 'hometax'
          ? '세금계산서등록양식(홈택스)'
          : '세금계산서등록양식(경리나라)';
      // row 생성
      const content = rows.map((data) => {
        const keys = Object.keys(data);
        const result = keys.map((key) => data[key]);
        return result;
      });
      // 엑셀데이터 생성
      const excelData =
        type === 'hometax'
          ? [[], [], [], [], [], columns, ...content]
          : [columns, ...content];
      // 엑셀파일 생성
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(excelData);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      XLSX.writeFile(workbook, title);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const handleSalesStatusTable = async (title, columns, rows) => {
    if (rows.length === 0) {
      alert('데이터가 없으므로 파일을 다운로드할 수 없습니다.');
      return;
    }
    try {
      // 시트명 정의
      const sheetName = '판매현황';
      // row 생성
      const content = rows.map((data) => {
        const keys = Object.keys(data);
        const result = keys.map((key) => data[key]);
        return result;
      });
      let totalDcQuantity = 0;
      let totalQuantity = 0;
      let totalSupply = 0;
      let totalVat = 0;
      let totalSum = 0;
      for (const row of content) {
        for (let i = 1; i <= row.length; i += 1) {
          if (i === row.length - 5)
            totalDcQuantity += parseInt(row[row.length - 5].replace(/,/g, ''));
          if (i === row.length - 4)
            totalQuantity += parseInt(row[row.length - 4].replace(/,/g, ''));
          if (i === row.length - 3)
            totalSupply += parseInt(row[row.length - 3].replace(/,/g, ''));
          if (i === row.length - 2)
            totalVat += parseInt(row[row.length - 2].replace(/,/g, ''));
          if (i === row.length - 1)
            totalSum += parseInt(row[row.length - 1].replace(/,/g, ''));
        }
      }
      content.push([
        ...new Array(columns.length - 5),
        totalDcQuantity.toLocaleString(),
        totalQuantity.toLocaleString(),
        totalSupply.toLocaleString(),
        totalVat.toLocaleString(),
        totalSum.toLocaleString(),
      ]);
      // 엑셀데이터 생성
      const excelData = [title, columns, ...content];
      // 엑셀파일 생성
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(excelData);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      XLSX.writeFile(workbook, title[0]);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const handleShippingOrderTable = async (title, columns, rows) => {
    if (rows.length === 0) {
      alert('데이터가 없으므로 파일을 다운로드할 수 없습니다.');
      return;
    }
    try {
      // 시트명 정의
      const sheetName = '출하지시서';
      // 내용줄 생성
      const content = rows.map((data) => {
        const keys = Object.keys(data);
        const result = keys.map((key) => data[key]);
        return result;
      });
      // 헤더+내용의 엑셀데이터 생성
      const excelData = [columns, ...content];
      // 엑셀파일 생성
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(excelData);

      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      XLSX.writeFile(workbook, `${title[0]}.xlsx`);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const handleShippingOrderMail = async (title, columns, rows, mailInfo) => {
    if (rows.length === 0) {
      alert('데이터가 없으므로 파일을 다운로드할 수 없습니다.');
      return;
    }
    try {
      // 시트명 정의
      const sheetName = '출하지시서';
      // 내용줄 생성
      const content = rows.map((data) => {
        const keys = Object.keys(data);
        const result = keys.map((key) => data[key]);
        return result;
      });
      // 헤더+내용의 엑셀데이터 생성
      const excelData = [columns, ...content];
      // 엑셀파일 생성
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(excelData);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

      // 💡 엑셀 파일을 Blob으로 변환
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const formData = new FormData();
      formData.append('file', blob, `${encodeURIComponent(title)}.xlsx`);
      formData.append('mailAddress', mailInfo.mailAddress);
      formData.append('subject', mailInfo.subject);
      formData.append('text', mailInfo.text);

      await sendMailWithFile(formData);

      window.alert('메일을 발송했습니다.');
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const handleAccountListTable = async (columns, rows) => {
    const confirm = window.confirm(
      '검색된 거래처리스트 파일을 다운로드 하시겠습니까?'
    );
    if (!confirm) return;
    if (!rows.length) {
      alert('데이터가 없어 파일을 다운로드할 수 없습니다.');
      return;
    }
    const accountIds = rows.map((row) => row.id);
    const postData = { accountIds };
    // 서버에서 검색된 거래처 리스트 데이터 가져옴.
    const accountList = await getAccountListByIds(postData);
    // 거래처 리스트 포맷팅
    const formattedAccountList = formatAccountList(accountList);
    // 서브 주소지 개수
    const subAdrCount = Math.max(
      ...formattedAccountList.map((account) => account.subAdr.length)
    );
    // address 제외. mainAdr, subAdr 따로 들어감.
    const formattedColumns = columns
      // 컬럼명 변경
      .map((column) => {
        if (column.dataIndex === 'account_group_name') {
          return {
            ...column,
            dataIndex: 'account_group1',
          };
        }
        return column;
      })
      // address 제외. mainAdr, subAdr 따로 들어감.
      .filter((column) => column.key !== 'address');
    const { excelColumns, excelRows } = generateExcelContents(
      formattedColumns,
      formattedAccountList,
      subAdrCount
    );
    const excelTitle = window.prompt(
      '파일명을 입력해주세요. (확장자명 .xlsx 제외)'
    );
    if (!excelTitle) {
      alert('파일명이 입력되지 않아 다운로드를 취소합니다.');
      return;
    }
    const fileName = `${excelTitle}.xlsx`;
    const fileData = [excelColumns, ...excelRows];

    // 엑셀파일 생성
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(fileData);
    XLSX.utils.book_append_sheet(workbook, worksheet, '거래처리스트');
    XLSX.writeFile(workbook, fileName);
  };

  const handlePurchaseOrderForm = async (
    headerData,
    contentData,
    footerData
  ) => {
    const filePath = `${process.env.PUBLIC_URL}발주서양식.xlsx`;
    try {
      const response = await fetch(filePath);
      if (!response.ok) {
        console.log(response.status);
        return;
      }
      const blob = await response.blob();
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(blob);
      const worksheet = workbook.getWorksheet('Sheet2');

      // 엑셀form header 데이터 추가
      const accountCell = worksheet.getCell('D2');
      const representativeCell = worksheet.getCell('D3');
      const dateCell = worksheet.getCell('D4');
      accountCell.value = headerData.account;
      representativeCell.value = headerData.representative;
      dateCell.value = headerData.date;

      // 엑셀 form content 데이터 추가
      for (let i = 0; i < contentData.length; i += 1) {
        worksheet.getCell(
          `A${i + 14}`
        ).value = `${contentData[i].name}[${contentData[i].standard}]`;
        worksheet.getCell(
          `J${i + 14}`
        ).value = `${contentData[i].quantity}(${contentData[i].unit})`;
        worksheet.getCell(`M${i + 14}`).value = contentData[i].price;
        worksheet.getCell(`P${i + 14}`).value = contentData[i].supply;
        worksheet.getCell(`Q${i + 14}`).value = contentData[i].vat;
      }

      // 엑셀form footer 데이터 추가
      let quantity = 0;
      let supply = 0;
      let vat = 0;
      let total = 0;
      for (const data of footerData) {
        quantity += data.quantity;
        supply += data.supply;
        vat += data.vat;
        total += data.total;
      }
      worksheet.getCell('B45').value = quantity;
      worksheet.getCell('F45').value = supply;
      worksheet.getCell('J45').value = vat;
      worksheet.getCell('P45').value = total;
      worksheet.getCell('D11').value = `${changeNumToKr(total)}원`;
      worksheet.getCell('O11').value = total;

      const modifiedFileBlob = await workbook.xlsx.writeBuffer();
      const modifiedFile = new File(
        [modifiedFileBlob],
        `${headerData.date}_발주서_${headerData.account}.xlsx`,
        {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }
      );
      saveAs(modifiedFile);
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return {
    handleEstimateForm,
    handleInvoiceForm,
    handleSalesStatusTable,
    handleShippingOrderTable,
    handleShippingOrderMail,
    handleAccountListTable,
    handlePurchaseOrderForm,
    handleTaxInvoiceTable,
  };
};

export default downloadExcelFile;
