import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import ErrorText from './ErrorText';

const InputFormField = ({ name, value, error, blur, onChange, onBlur }) => {
  let placeholder;
  let text;

  switch (name) {
    case 'sender':
      placeholder = '발신번호를 입력해주세요. (하이픈(-) 제거.)';
      text = '발신번호 11자리를 입력해주세요. (하이픈(-) 제거.)';
      break;
    case 'title':
      placeholder = '제목을 입력해주세요. (4자 이상.)';
      text = '제목을 입력해주세요. (4자 이상.)';
      break;
    default:
      break;
  }

  return (
    <>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(name, e.target.value)}
        onBlur={() => onBlur(name)}
      />
      {error && blur && <ErrorText text={text} />}
    </>
  );
};

export default InputFormField;

InputFormField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  blur: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};
