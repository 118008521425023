import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  darkMode: false,
};

const darkModeSlice = createSlice({
  name: 'darkMode',
  initialState,
  reducers: {
    changeDarkMode: (state, action) => {
      const value = action.payload;
      state.darkMode = value;
    },
  },
});

export default darkModeSlice.reducer;
export const { changeDarkMode } = darkModeSlice.actions;
