import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import { useDateRangeButtonContext } from './DateRangeButtonMain';

const DateRangeButtonItem = ({ children, dateType }) => {
  const { handleClickDateRangeButton } = useDateRangeButtonContext();

  return (
    <Button
      style={{ borderRadius: '20px' }}
      onClick={() => handleClickDateRangeButton(dateType)}
    >
      {children}
    </Button>
  );
};

export default DateRangeButtonItem;

DateRangeButtonItem.propTypes = {
  children: PropTypes.node.isRequired,
  dateType: PropTypes.string.isRequired,
};
