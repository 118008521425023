import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';

const AdminColumnValue = (props) => {
  const { adminId } = props;
  const dispatch = useDispatch();

  const [adminName, setAdminName] = useState('');

  useEffect(() => {
    const getAdminName = async () => {
      try {
        const resOfAdminName = await dispatch(
          asyncReadSpecificDataFetch({ table: 'profiles', id: adminId })
        )
          .unwrap()
          .then((res) => res[0].username);
        setAdminName(resOfAdminName);
      } catch (error) {
        throw new Error();
      }
    };
    getAdminName();
  }, [adminId, dispatch]);

  return <div>{adminName}</div>;
};

export default memo(AdminColumnValue);

AdminColumnValue.propTypes = {
  adminId: PropTypes.string.isRequired,
};
