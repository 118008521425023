import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useInventoryStatusCardContext } from './InventoryStatusCardMain';

const InventoryStatusCardContent = ({
  availableDays,
  stackQuantity,
  productName,
  textColor,
}) => {
  // InventoryStatusCardMain(Context Provider)에서 공유하는 함수를 받아온다.
  const { getContent } = useInventoryStatusCardContext();

  const [content, setContent] = useState();

  useEffect(() => {
    const newContent = getContent({ availableDays, stackQuantity });
    setContent(newContent);
  }, [availableDays, stackQuantity]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: textColor,
      }}
    >
      <div>{productName}</div>
      <div>{content}</div>
    </div>
  );
};

export default InventoryStatusCardContent;

InventoryStatusCardContent.propTypes = {
  availableDays: PropTypes.number.isRequired,
  stackQuantity: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};
