import { MessageOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import SmsForm from '../components/SmsForm';

const Sms = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <MessageOutlined />,
        breadCrumbNav: ' SMS발송',
      },
    ],
    []
  );
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px' }}>
        <div
          style={{
            fontSize: '16px',
            width: '100%',
            paddingBottom: '10px',
            borderBottom: '1px solid gray',
          }}
        >
          메시지 발송
        </div>
        <SmsForm />
      </div>
    </LayoutComponent>
  );
};

export default Sms;
