import React from 'react';
import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { asyncSignoutUserFetch } from '../../redux/modules/auth/signoutUser';
import { logoutReducer } from '../../redux/modules/auth/signinUser';

const ButtonStyle = styled(Button)`
  width: 100%;
  padding: 8px;
  height: 40px;
  font-weight: 700;
  font-size: 14.5px;
  background: black;
  color: white;
`;

// permission이 false일 때의 Access 컴포넌트
const AccessPermission = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoadingSignoutUser } = useSelector((state) => state.signoutUser);
  const handler = () => {
    dispatch(asyncSignoutUserFetch()).then((res) => {
      dispatch(logoutReducer(res.payload));
      history.push('/');
    });
  };
  return (
    <Result
      status="403"
      title="403"
      subTitle="접근 권한이 없습니다. 관리자에게 문의하세요."
      extra={
        isLoadingSignoutUser ? (
          <ButtonStyle loading>로그아웃</ButtonStyle>
        ) : (
          <ButtonStyle onClick={handler}>로그아웃</ButtonStyle>
        )
      }
    />
  );
};

export default AccessPermission;
