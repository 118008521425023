import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { HolderOutlined } from '@ant-design/icons';
import { useDrag, useDrop } from 'react-dnd';

const OrderedCard = (props) => {
  const { order, cardIdx, card, removeCard, moveCard, findCard } = props;

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: 'CARD',
      item: { order, cardIdx },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [cardIdx]
  );
  const [, dropRef] = useDrop(
    () => ({
      accept: 'CARD',
      hover({ order: draggedOrder }) {
        if (draggedOrder !== order) {
          const { index: overIndex } = findCard(order);
          moveCard(draggedOrder, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );

  return (
    <Button
      onClick={() => removeCard(card.id)}
      type="text"
      size="small"
      style={{
        textAlign: 'start',
        display: 'flex',
        position: 'relative',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'start',
        opacity: isDragging ? '0.3' : '1',
      }}
      ref={(node) => dragRef(dropRef(node))}
    >
      <div
        style={{
          marginRight: '5px',
          backgroundColor: 'orange',
          width: '15px',
          height: '15px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          color: 'white',
          fontSize: '12px',
          paddingTop: '2px',
        }}
      >
        {cardIdx + 1}
      </div>
      <div style={{ paddingTop: '2px' }}>{card.name}</div>
    </Button>
  );
};

export default OrderedCard;

OrderedCard.propTypes = {
  order: PropTypes.number.isRequired,
  cardIdx: PropTypes.number.isRequired,
  card: PropTypes.instanceOf(Object).isRequired,
  removeCard: PropTypes.func.isRequired,
  moveCard: PropTypes.func.isRequired,
  findCard: PropTypes.func.isRequired,
};
