import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import useModal from '../../hook/useModal';
import ProfileForm from './ProfileForm';
import UpdateProfileForm from './UpdateProfileForm';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import { asyncResetPasswordFetch } from '../../redux/modules/auth/resetPassword';

const UpdateProfile = (props) => {
  const { profileData } = props;
  const dispatch = useDispatch();
  const { dataId } = useSelector((state) => state.modal.dataInfo);
  const { openModal, closeModal } = useModal();

  // 프로필 수정하는 함수
  const onFinish = (values) => {
    const datas = {
      id: dataId,
      table: 'profiles',
      username: values.username,
      intro: values.intro,
      phone: values.phone,
    };
    // 해당 row를 업데이트하는 dispatch
    dispatch(asyncUpdateDataFetch(datas))
      .then(() => {
        closeModal();
        // result 모달을 띄움.
        openModal({
          type: 'result',
          modalInfo: {
            modalTitle: '프로필 수정',
            resultTitle: '프로필 정보를 수정했습니다.',
            resultStatus: 'success',
            buttonText: '확인',
          },
        });
      })
      .catch((err) => alert(err));
  };

  // password를 reset하는 함수
  const handleResetPassword = () => {
    const values = { email: profileData.email };
    dispatch(asyncResetPasswordFetch(values))
      .then(() => {
        closeModal();
        // result 모달을 띄움.
        openModal({
          type: 'result',
          modalInfo: {
            modalTitle: '비밀번호',
            resultTitle: '비밀번호 변경 인증메일을 보냈습니다.',
            resultStatus: 'success',
            resultSubTitle: '이메일을 확인한 후 인증메일 링크를 클릭해주세요.',
            buttonText: '확인',
          },
        });
      })
      .catch((err) => alert(err));
  };

  return (
    <ProfileForm profileData={profileData} onFinish={onFinish}>
      <UpdateProfileForm handleResetPassword={handleResetPassword} />
    </ProfileForm>
  );
};

export default UpdateProfile;

UpdateProfile.propTypes = {
  profileData: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
