import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, Tag } from 'antd';
import { OrderedListOutlined } from '@ant-design/icons';

const TableSortHandler = (props) => {
  const { setSalesStatusDatas, getVisibleColumn } = props;
  const [isHoveredColumns, setIsHoveredColumns] = useState(false);
  const [hoveredColumn, setHoveredColumn] = useState(null);

  const initailizeStates = () => {
    setIsHoveredColumns(false);
    setHoveredColumn(null);
  };

  const handleClickSortButton = (sortingType) => {
    initailizeStates();
    const { key } = hoveredColumn;
    if (sortingType === 'descending') {
      setSalesStatusDatas((prev) =>
        [...prev].sort((a, b) => {
          if (typeof a[key] === 'string' && typeof b[key] === 'string') {
            return b[key].localeCompare(a[key]);
          } else {
            return b[key] - a[key];
          }
        })
      );
    } else if (sortingType === 'ascending') {
      setSalesStatusDatas((prev) =>
        [...prev].sort((a, b) => {
          if (typeof a[key] === 'string' && typeof b[key] === 'string') {
            return a[key].localeCompare(b[key]);
          } else {
            return a[key] - b[key];
          }
        })
      );
    } else {
      return;
    }
  };

  return (
    <Popover
      title={
        <div
          style={{
            backgroundColor: '#efefef',
            padding: '3px',
            borderRadius: '5px',
            textAlign: 'center',
          }}
        >
          정렬
        </div>
      }
      onOpenChange={() => initailizeStates()}
      trigger="click"
      content={
        <div style={{ display: 'flex' }}>
          <div
            style={{
              color: 'black',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {getVisibleColumn().map((item) => (
              <Button
                style={{
                  color: 'black',
                  backgroundColor:
                    hoveredColumn && hoveredColumn.key === item.key
                      ? '#efefef'
                      : 'white',
                }}
                type="link"
                size="small"
                onMouseOver={() => {
                  setIsHoveredColumns(true);
                  setHoveredColumn({ key: item.key, title: item.title });
                }}
              >
                {item.title}
              </Button>
            ))}
          </div>
          {isHoveredColumns && hoveredColumn && (
            <div
              style={{
                marginLeft: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div style={{ marginBottom: '5px' }}>
                <Tag style={{ fontSize: '14px' }}>
                  정렬: {hoveredColumn.title}
                </Tag>
              </div>
              <Button
                style={{ color: 'black' }}
                type="link"
                onClick={() => handleClickSortButton('descending')}
              >
                내림차순
              </Button>
              <Button
                size="small"
                style={{ color: 'black' }}
                type="link"
                onClick={() => handleClickSortButton('ascending')}
              >
                오름차순
              </Button>
            </div>
          )}
        </div>
      }
      placement="rightTop"
    >
      <Button style={{ borderRadius: '20px', marginLeft: '5px' }}>
        <OrderedListOutlined />
      </Button>
    </Popover>
  );
};

export default TableSortHandler;

TableSortHandler.propTypes = {
  setSalesStatusDatas: PropTypes.func.isRequired,
  getVisibleColumn: PropTypes.func.isRequired,
};
