/*
{
  "requestId": "string",
  "statusCode": "string",
  "statusName": "string",
  "messages": [
    {
      "requestId": "string", => 요청ID
      "messageId": "string",
      "requestTime": "string", => 요청일시
      "contentType": "string", 
      "type": "string", => 타입
      "countryCode": "string",
      "from": "string", => 발신번호
      "to": "string", => 수신번호
      "completeTime": "string", => 완료일시
      "telcoCode": "string", => 국가번호
      "status": "string", => 요청상태
      "statusCode": "string", 
      "statusName": "string", => 수신상태
      "statusMessage": "string",
      "subject": "string",
      "content": "string",
      "files": [
        {
          "fileId": "string",
          "name": "string"
        }
      ]
    }
  ]
}
*/

const useSmsHistoryTable = () => {
  const tableColumns = [
    {
      title: '요청일시',
      dataIndex: 'requestTime',
      key: 'requestTime',
      render: (value) => value ?? '-',
    },
    {
      title: '완료일시',
      dataIndex: 'completeTime',
      key: 'completeTime',
      render: (value) => value ?? '-',
    },
    {
      title: '요청상태',
      dataIndex: 'status',
      key: 'status',
      render: (value) => {
        let formattedValue;
        switch (value) {
          case 'fail':
            formattedValue = '실패';
            break;
          case 'success':
            formattedValue = '성공';
            break;
          case 'reserved':
            formattedValue = '예약';
            break;
          default:
            formattedValue = '-';
            break;
        }
        return formattedValue;
      },
    },
    {
      title: '수신상태',
      dataIndex: 'statusName',
      key: 'statusName',
      render: (value) => {
        let formattedValue;
        switch (value) {
          case 'fail':
            formattedValue = '실패';
            break;
          case 'success':
            formattedValue = '성공';
            break;
          default:
            formattedValue = '-';
            break;
        }
        return formattedValue;
      },
    },
    { title: '타입', dataIndex: 'type', key: 'type' },
    { title: '발신번호', dataIndex: 'from', key: 'from' },
    { title: '수신번호', dataIndex: 'to', key: 'to' },
  ];

  return { tableColumns };
};

export default useSmsHistoryTable;
