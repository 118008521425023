import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { editProfileApi } from '../../../apis/authApi';

const initialState = {
  isSuccessUpdateUser: false,
  isLoadingUpdateUser: false,
  isErrorUpdateUser: false,
};

const asyncUpdateUserFetch = createAsyncThunk(
  'updateUserSlice/asyncUpdateUserFetch',
  async (values, { rejectWithValue }) => {
    try {
      await editProfileApi(values);
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message || '서버에러');
    }
  }
);

const updateUserSlice = createSlice({
  name: 'updateUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncUpdateUserFetch.pending, (state) => {
      state.isLoadingUpdateUser = true;
    });
    builder.addCase(asyncUpdateUserFetch.fulfilled, (state) => {
      state.isLoadingUpdateUser = false;
      state.isSuccessUpdateUser = true;
    });
    builder.addCase(asyncUpdateUserFetch.rejected, (state) => {
      state.isLoadingUpdateUser = false;
      state.isErrorUpdateUser = true;
    });
  },
});

export default updateUserSlice.reducer;
export { asyncUpdateUserFetch };
