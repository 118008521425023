import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const ExpirationDateValue = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const [expirationDate, setExpirationDate] = useState(false);
  useEffect(() => {
    const getExpirationDate = async () => {
      const data = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'estimate_sent',
          eqKey: 'estimate_id',
          eqValue: id,
        })
      )
        .unwrap()
        .then((res) => res[0])
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
      setExpirationDate(data.expiration_date);
    };
    getExpirationDate();
  }, [dispatch, id]);

  return <div>{expirationDate}일</div>;
};

export default ExpirationDateValue;

ExpirationDateValue.propTypes = {
  id: PropTypes.string.isRequired,
};
