import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import useModal from '../../hook/useModal';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import PurchaseForm from '../../shared/purchase/PurchaseForm';
import ProductDetails from '../../shared/purchase/ProductDetails';
import FormController from '../../shared/purchase/FormController';
import usePurchaseData from '../../hook/usePurchaseData';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';
import { asyncReadMatchDataFetch } from '../../redux/modules/shared/readMatchData';
import { inventoryCouse } from '../../constants';
import useQueryFunctions from '../../hook/useQueryFunctions';

const DataHandler = (props) => {
  const { pageType } = props;
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    updateJustStatus,
    createPurchase,
    createDupPurchaseOrder,
    createPurchaseOrderProduct,
    updatePurchaseOrderProduct,
  } = usePurchaseData();

  const { fnInsertInventory } = useQueryFunctions();

  const { openModal } = useModal();
  const history = useHistory();

  const [purchaseForm, setPurchaseForm] = useState({
    purchaseDate: null,
    accountId: null,
    adminId: null,
    warehouseId: null,
    dealType: null,
  });
  const [productDetails, setProductDetails] = useState([]);
  const [initialPdDetails, setInitialPdDetails] = useState([]);

  // update, detail form값 불러오기
  useEffect(() => {
    const getFormData = async (table) => {
      try {
        const purchaseData = await dispatch(
          asyncReadSpecificDataFetch({
            table,
            id,
          })
        )
          .unwrap()
          .then((res) => res[0]);
        const productDatas = await dispatch(
          asyncReadFilteredDataFetch({
            table:
              table === 'purchase'
                ? 'purchase_product'
                : 'purchase_order_product',
            eqKey: table === 'purchase' ? 'purchase_id' : 'purchase_order_id',
            eqValue: id,
          })
        )
          .unwrap()
          .then((res) => res);
        setPurchaseForm({
          purchaseDate:
            table === 'purchase'
              ? purchaseData.purchase_date
              : purchaseData.purchase_order_date,
          accountId: purchaseData.account_id,
          adminId: purchaseData.admin_id,
          warehouseId: purchaseData.warehouse_id,
          dealType: purchaseData.deal_type,
        });
        setProductDetails([
          ...productDatas.map((data) => ({
            productId: data.product_id,
            productQuantity: data.product_quantity,
          })),
        ]);
        setInitialPdDetails([
          ...productDatas.map((data) => ({
            productId: data.product_id,
            productQuantity: data.product_quantity,
          })),
        ]);
      } catch (error) {
        throw new Error(error);
      }
    };
    if (pageType === 'create') {
      getFormData('purchase_order');
    } else {
      getFormData('purchase');
    }
  }, [pageType, dispatch, id]);

  const initFormStates = () => {
    setPurchaseForm({
      purchaseDate: null,
      accountId: null,
      adminId: null,
      warehouseId: null,
      dealType: null,
    });
    setProductDetails([]);
  };

  // 구매id 가져오기
  const getPurchaseId = () => {
    const id = window.location.pathname.split('/').pop();
    const formattedId = isNaN(id) ? id : Number(id);
    return formattedId;
  };

  // 최근 재고stack 가져오기
  const getRecentInventoryStack = async ({ warehouseId, productId }) => {
    const result = await dispatch(
      asyncReadMatchDataFetch({
        table: 'inventory',
        match: { warehouse_id: warehouseId, product_id: productId },
        order: 'id',
        limit: 1,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.length <= 0) {
          return false;
        }
        return res[0].stack_quantity;
      });
    return result;
  };

  // 구매데이터 추가기능
  const createHandler = async () => {
    const confirmCreate = window.confirm('구매 데이터를 추가하시겠습니까?');
    if (!confirmCreate) return;
    try {
      const originAndNewPdQty = [];
      for (const initialPd of initialPdDetails) {
        for (const product of productDetails) {
          if (initialPd.productId === product.productId) {
            originAndNewPdQty.push({
              productId: initialPd.productId,
              originQty: initialPd.productQuantity,
              newQty: product.productQuantity,
            });
          }
        }
      }
      if (
        originAndNewPdQty.filter((pd) => pd.originQty !== pd.newQty).length >= 1
      ) {
        await updateJustStatus(id);
        const dupPurchaseOrderId = await createDupPurchaseOrder(id);
        for (const product of originAndNewPdQty) {
          if (product.originQty !== product.newQty) {
            const diffOfQty = product.originQty - product.newQty;
            await updatePurchaseOrderProduct(
              id,
              product.productId,
              product.newQty
            );
            await createPurchaseOrderProduct(
              dupPurchaseOrderId,
              product.productId,
              diffOfQty
            );
          }
        }
      } else {
        await updateJustStatus(id);
      }
      const newPurchase = await createPurchase(purchaseForm, productDetails);
      const causeId = newPurchase.id;
      const causeState = inventoryCouse.state_create;
      const causeOfChange = inventoryCouse.of_change_purchase;

      // 재고 변동일자 오늘 or 구매일자
      const today = dayjs().format('YYYY-MM-DD');
      // const { purchaseData } = purchaseForm;
      for (const product of productDetails) {
        const { warehouseId, accountId, adminId } = purchaseForm;
        const { productId, productQuantity } = product;
        // 재고 변경 함수
        await fnInsertInventory({
          changeQuantity: productQuantity,
          productId,
          warehouseId,
          accountId,
          adminId,
          causeId,
          causeOfChange,
          causeState,
        });
        /*
        // 누적개수 최신화
        const stackQuantity =
          ((await getRecentInventoryStack({
            warehouseId,
            productId,
          })) || 0) + productQuantity;
        // 데이터 업데이트
        dispatch(
          asyncCreateDataFetch({
            table: 'inventory',
            inventory_change_date: today,
            warehouse_id: warehouseId,
            product_id: productId,
            change_quantity: productQuantity,
            stack_quantity: stackQuantity,
            account_id: accountId,
            admin_id: adminId,
            payment_terms: null,
            cause_id: causeId,
            cause_state: causeState,
            cause_of_change: causeOfChange,
          })
        );
        */
      }
      openModal({
        type: 'result',
        modalInfo: {
          modalTitle: '구매 추가',
          resultStatus: 'success',
          resultTitle: '구매 데이터를 추가했습니다.',
          buttonText: '확인',
        },
      });
      history.push('/purchase');
    } catch (error) {
      throw new Error(error);
    }
  };

  // 구매데이터 수정기능
  const updateHandler = async () => {
    const confirmUpdate = window.confirm(
      '정말 구매 데이터를 수정하시겠습니까?'
    );
    if (!confirmUpdate) return;

    const purchaseId = getPurchaseId();
    const today = dayjs().format('YYYY-MM-DD');

    try {
      // 수정전 구매 데이터 가져오기
      const savedPurchaseData = await dispatch(
        asyncReadSpecificDataFetch({ table: 'purchase', id: purchaseId })
      )
        .unwrap()
        .then((res) => res[0]);
      const {
        admin_id: savedAdminId,
        account_id: savedAccountId,
        warehouse_id: savedWarehouseId,
      } = savedPurchaseData;

      // 수정할 구매 데이터 가져오기
      const {
        adminId: newAdminId,
        accountId: newAccountId,
        warehouseId: newWarehouseId,
        dealType: newDealType,
        purchaseDate: newPurchaseDate,
      } = purchaseForm;

      // 구매 수정
      dispatch(
        asyncUpdateDataFetch({
          table: 'purchase',
          id: purchaseId,
          state: 1,
          mod_date: dayjs(),
          admin_id: newAdminId,
          account_id: newAccountId,
          deal_type: newDealType,
          purchase_date: newPurchaseDate,
          warehouse_id: newWarehouseId,
        })
      );

      // 수정 전 구매 품목 가져오기
      const savedProductDatas = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'purchase_product',
          eqKey: 'purchase_id',
          eqValue: purchaseId,
        })
      )
        .unwrap()
        .then((res) => res);

      // 수정할 구매 품목 가져오기
      const newProductDatas = productDetails;

      for (const savedProductData of savedProductDatas) {
        for (const newProductData of newProductDatas) {
          const savedProductId = savedProductData.product_id;
          const newProductId = newProductData.productId;
          // 수정 전 품목과 수정할 품목이 같은 경우
          const equalData = savedProductId === newProductId;
          if (equalData) {
            const savedQuantity = savedProductData.product_quantity;
            const newQuantity = newProductData.productQuantity;

            // 구매 - 품목 수정
            dispatch(
              asyncUpdateDataFetch({
                table: 'purchase_product',
                id: savedProductData.id,
                state: 1,
                mod_date: dayjs(),
                product_quantity: newQuantity,
              })
            );

            // (수정전개수) 재고개수 감소처리
            await fnInsertInventory({
              changeQuantity: -savedQuantity,
              productId: savedProductId,
              warehouseId: savedWarehouseId,
              accountId: savedAccountId,
              adminId: savedAdminId,
              causeId: purchaseId,
              causeOfChange: inventoryCouse.of_change_purchase,
              causeState: inventoryCouse.state_update,
            });
            /*
            const stackQuantityToDecrease =
              ((await getRecentInventoryStack({
                warehouseId: savedWarehouseId,
                productId: savedProductId,
              })) || 0) - savedQuantity;
            const changeQuantityToDecrease = -savedQuantity;
            await dispatch(
              asyncCreateDataFetch({
                table: 'inventory',
                inventory_change_date: today,
                warehouse_id: savedWarehouseId,
                product_id: savedProductId,
                change_quantity: changeQuantityToDecrease,
                stack_quantity: stackQuantityToDecrease,
                account_id: savedAccountId,
                admin_id: savedAdminId,
                payment_terms: null,
                cause_id: purchaseId,
                cause_state: inventoryCouse.state_update,
                cause_of_change: inventoryCouse.of_change_purchase,
              })
            );
            */

            // (수정후개수) 재고개수 증가처리
            await fnInsertInventory({
              changeQuantity: +newQuantity,
              productId: newProductId,
              warehouseId: newWarehouseId,
              accountId: newAccountId,
              adminId: newAdminId,
              causeId: purchaseId,
              causeOfChange: inventoryCouse.of_change_purchase,
              causeState: inventoryCouse.state_update,
            });
            /*
            const stackQuantityToIncrease =
              ((await getRecentInventoryStack({
                warehouseId: newWarehouseId,
                productId: newProductId,
              })) || 0) + newQuantity;
            const changeQuantityToIncrease = +newQuantity;
            await dispatch(
              asyncCreateDataFetch({
                table: 'inventory',
                inventory_change_date: today,
                warehouse_id: newWarehouseId,
                product_id: newProductId,
                change_quantity: changeQuantityToIncrease,
                stack_quantity: stackQuantityToIncrease,
                account_id: newAccountId,
                admin_id: newAdminId,
                payment_terms: null,
                cause_id: purchaseId,
                cause_state: inventoryCouse.state_update,
                cause_of_change: inventoryCouse.of_change_purchase,
              })
            );
            */
          }
        }
      }
      openModal({
        type: 'result',
        modalInfo: {
          modalTitle: '구매 수정',
          resultStatus: 'success',
          resultTitle: '구매 데이터를 수정했습니다.',
          buttonText: '확인',
        },
      });
      history.push('/purchase');
    } catch (error) {
      alert(error);
      console.log(error);
    }
  };

  // 구매데이터 삭제기능
  const deleteHandler = async () => {
    const confirmDelete = window.confirm(
      '정말 구매 데이터를 삭제하시겠습니까?'
    );
    if (!confirmDelete) return;
    const purchaseId = getPurchaseId();
    const today = dayjs().format('YYYY-MM-DD');
    try {
      // 수정전 구매 데이터 가져오기 (재고처리용)
      const savedPurchaseData = await dispatch(
        asyncReadSpecificDataFetch({ table: 'purchase', id: purchaseId })
      )
        .unwrap()
        .then((res) => res[0]);

      // 구매 삭제
      dispatch(
        asyncUpdateDataFetch({
          table: 'purchase',
          id: purchaseId,
          state: 9,
          del_date: dayjs(),
        })
      );
      // 구매 - 품목 삭제
      const savedProductDatas = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'purchase_product',
          eqKey: 'purchase_id',
          eqValue: purchaseId,
        })
      )
        .unwrap()
        .then((res) => res);
      savedProductDatas.forEach((savedProduct) => {
        dispatch(
          asyncUpdateDataFetch({
            table: 'purchase_product',
            id: savedProduct.id,
            state: 9,
            del_date: dayjs(),
          })
        );
      });

      const {
        admin_id: savedAdminId,
        account_id: savedAccountId,
        warehouse_id: savedWarehouseId,
      } = savedPurchaseData;
      const causeState = inventoryCouse.state_delete;
      const causeOfChange = inventoryCouse.of_change_purchase;
      const causeId = purchaseId;

      // 재고 삭제
      for (const savedProductData of savedProductDatas) {
        const savedQuantity = savedProductData.product_quantity;
        const savedProductId = savedProductData.product_id;
        await fnInsertInventory({
          changeQuantity: -savedQuantity,
          productId: savedProductId,
          warehouseId: savedWarehouseId,
          accountId: savedAccountId,
          adminId: savedAdminId,
          causeId,
          causeOfChange,
          causeState,
        });
        /*
        // 계산된 최신 누적 값
        const inventoryStackToOffset =
          ((await getRecentInventoryStack({
            warehouseId: savedWarehouseId,
            productId: savedProductId,
          })) || 0) - savedQuantity;
        // 변동 재고 수량
        const inventoryQuantityToOffset = -savedQuantity;
        // 재고 감소 처리
        dispatch(
          asyncCreateDataFetch({
            table: 'inventory',
            inventory_change_date: today,
            warehouse_id: savedWarehouseId,
            product_id: savedProductId,
            change_quantity: inventoryQuantityToOffset,
            stack_quantity: inventoryStackToOffset,
            account_id: savedAccountId,
            admin_id: savedAdminId,
            payment_terms: null,
            cause_id: causeId,
            cause_state: causeState,
            cause_of_change: causeOfChange,
          })
        );
        */
      }
      openModal({
        type: 'result',
        modalInfo: {
          modalTitle: '구매 삭제',
          resultStatus: 'success',
          resultTitle: '구매 데이터를 삭제했습니다.',
          buttonText: '확인',
        },
      });
      history.push('/purchase');
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <PurchaseForm
        formType="purchase"
        purchaseForm={purchaseForm}
        setPurchaseForm={setPurchaseForm}
        pageType={pageType}
      />
      <ProductDetails
        formType="purchase"
        productDetails={productDetails}
        setProductDetails={setProductDetails}
        pageType={pageType}
      />
      <FormController
        pageType={pageType}
        pathUrl="purchase"
        initFormStates={initFormStates}
        createHandler={createHandler}
        updateHandler={updateHandler}
        deleteHandler={deleteHandler}
      />
    </div>
  );
};

export default DataHandler;

DataHandler.propTypes = {
  pageType: PropTypes.string.isRequired,
};
