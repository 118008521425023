import { Switch } from 'antd';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';

const HiddenToggle = (props) => {
  const { table } = props;
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const getHidden = async () => {
      try {
        const hidden = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'hidden_data',
            eqKey: 'table_name',
            eqValue: table,
          })
        )
          .unwrap()
          .then((res) => res[0]?.hide);
        setIsChecked(hidden);
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    };
    getHidden();
  }, [dispatch, table]);

  const handleSwitch = async (checked) => {
    try {
      await dispatch(
        asyncUpdateDataFetch({
          table: 'hidden_data',
          hide: checked,
          table_name: table,
        })
      );
      setIsChecked(checked);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  return (
    <Switch
      onChange={(checked) => handleSwitch(checked)}
      checked={isChecked}
      checkedChildren="ON"
      unCheckedChildren="OFF"
      size="small"
    />
  );
};

export default HiddenToggle;

HiddenToggle.propTypes = {
  table: PropTypes.string.isRequired,
};
