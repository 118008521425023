import { useState } from 'react';

const useBasicForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  const handleChangeValue = (name, newValue) => {
    setValues((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  return { values, handleChangeValue };
};

export default useBasicForm;
