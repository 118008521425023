import React from 'react';
import PropTypes from 'prop-types';

const EditUserAccessHeader = (props) => {
  const { username } = props;

  return (
    <div style={{ fontSize: '30px', fontWeight: 'bold' }}>
      {username}님의 권한설정
    </div>
  );
};

export default EditUserAccessHeader;

EditUserAccessHeader.propTypes = {
  username: PropTypes.string,
};
