import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import useModal from '../../hook/useModal';
import ComponentForm from './ComponentForm';

import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';

const DeleteComponent = (props) => {
  const { items } = props;
  const dispatch = useDispatch();
  const { dataInfo, modalInfo } = useSelector((state) => state.modal);
  const { openModal, closeModal } = useModal();

  const onFinish = useCallback(() => {
    const values = {
      id: dataInfo.dataId,
      table: dataInfo.dataTable,
      state: 9,
    };
    // 해당 row를 삭제하는 dispatch
    dispatch(asyncUpdateDataFetch(values))
      .unwrap()
      .then(() => {
        closeModal();
        // result 모달을 띄움.
        openModal({
          type: 'result',
          modalInfo: {
            modalTitle: modalInfo.modalTitle,
            resultStatus: 'success',
            resultTitle: modalInfo.modalTitle,
            resultSubTitle: `데이터에서 ${modalInfo.modalTitle} 작업을 완료 했습니다.`,
            buttonText: '확인',
          },
        });
      })
      .catch((err) => alert(err));
  }, [
    closeModal,
    openModal,
    dataInfo.dataId,
    dataInfo.dataTable,
    dispatch,
    modalInfo.modalTitle,
  ]);

  return <ComponentForm items={items} buttonText="삭제" onFinish={onFinish} />;
};

export default React.memo(DeleteComponent);

DeleteComponent.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};
