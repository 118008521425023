import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import { Button, Descriptions, Form, Input, Select, Space, Tag } from 'antd';
import { useDispatch } from 'react-redux';
import { CalculatorOutlined } from '@ant-design/icons';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import useSupabase from '../../serverConfig';
import { borderRadiuses } from '../../constants';

const isHiddenValue = useSupabase
  ? { ON: true, OFF: false }
  : { ON: 1, OFF: 0 };

const SaleFormComponent = forwardRef((props, ref) => {
  const { saleItems, saleData, pageType } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [saleFormInputs, setSaleFormInputs] = useState({
    code: '',
    name: '',
    sort_order: null,
    is_hidden: false,
  });
  const [sortOrderLoading, setSortOrderLoading] = useState(false);

  // 계산기 마지막순서 부여하는 로직
  const getSortOrder = () => {
    const asyncFunc = async () => {
      await setSortOrderLoading(true);
      let lastValue = 0;
      const sales = await dispatch(asyncReadDataFetch({ table: 'sale' }))
        .unwrap()
        .then((res) => res)
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
      const salesHasSortOrder = sales.filter((data) => data.sort_order);
      if (salesHasSortOrder.length >= 1) {
        for (const sale of salesHasSortOrder) {
          const { sort_order } = sale;
          if (typeof sort_order === 'number' && lastValue < sort_order) {
            lastValue = sort_order;
          }
        }
      }
      setSaleFormInputs((prev) => ({ ...prev, sort_order: lastValue + 1 }));
      setSortOrderLoading(false);
    };
    asyncFunc();
  };

  // 계산기 순서 삭제하는 로직
  const deleteSortOrder = () => {
    setSaleFormInputs((prev) => ({ ...prev, sort_order: null }));
  };

  useEffect(() => {
    // pageType이 detail 또는 update 일때
    if (pageType === 'detail' || pageType === 'update') {
      // sale form UI에 saleData값 넣기
      form.setFieldsValue({
        ...saleData,
      });
      // sale input에 saleData값 넣기
      setSaleFormInputs(() => {
        return {
          code: saleData.code,
          name: saleData.name,
          is_hidden: saleData.is_hidden,
          sort_order: saleData.sort_order,
        };
      });
    }
    // pageType이 create일 때
    if (pageType === 'create') {
      const asyncFunc = async () => {
        try {
          const len = await dispatch(
            asyncReadDataFetch({ table: 'sale', reqDataLen: true })
          )
            .unwrap()
            .then((res) => res + 1)
            .catch((err) => {
              console.log(err);
              alert(err.message);
            });
          const saleCode = `S${len.toString().padStart(6, 0)}`;
          // sale form UI에 saleData의 code값 넣기
          form.setFieldsValue({
            code: saleCode,
          });
          // sale input에 saleData의 code값 넣기
          setSaleFormInputs((prev) => {
            return {
              ...prev,
              code: saleCode,
            };
          });
        } catch (err) {
          console.log(err);
          alert(err.message);
        }
      };
      asyncFunc();
    }
  }, [saleData, form, dispatch, pageType]);

  useImperativeHandle(ref, () => ({
    getSaleFormInputs: () => {
      return saleFormInputs;
    },
  }));

  return (
    <Form
      form={form}
      name="basic"
      onFinishFailed={(e) => console.log(e)}
      labelCol={{
        span: 24,
      }}
    >
      <Descriptions
        bordered
        labelStyle={{ width: '140px', textAlign: 'center' }}
      >
        <Descriptions.Item label={saleItems[0].label} span={3}>
          <Form.Item
            name={saleItems[0].name}
            style={{ marginBottom: '0px' }}
            rules={[
              {
                required: true,
                message: saleItems[0].message,
              },
            ]}
          >
            <Input style={{ borderRadius: '0px' }} disabled />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={saleItems[1].label} span={3}>
          <Form.Item
            name={saleItems[1].name}
            style={{ marginBottom: '0px' }}
            rules={[
              {
                required: true,
                message: saleItems[1].message,
              },
            ]}
          >
            <Input
              style={{ borderRadius: '0px' }}
              placeholder={saleItems[1].message}
              disabled={pageType === 'detail'}
              onChange={(e) =>
                setSaleFormInputs((prev) => {
                  return {
                    ...prev,
                    name: e.target.value,
                  };
                })
              }
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label="판매상품옵션" span={3}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                width: '49%',
                backgroundColor: '#f9f9f9',
                padding: '10px 15px',
                borderRadius: borderRadiuses.primary,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 5px',
                }}
              >
                <div style={{ marginBottom: '5px' }}>계산기 - 판매상품</div>
              </div>
              {saleFormInputs.sort_order ? (
                <Button
                  style={{
                    borderRadius: '20px',
                    width: '100%',
                  }}
                  disabled={pageType === 'detail'}
                  onClick={deleteSortOrder}
                  danger
                >
                  <CalculatorOutlined />
                  계산기에서 판매상품 삭제하기
                </Button>
              ) : (
                <Button
                  style={{
                    borderRadius: '20px',
                    width: '100%',
                  }}
                  disabled={pageType === 'detail'}
                  onClick={getSortOrder}
                  loading={sortOrderLoading}
                >
                  <CalculatorOutlined />
                  계산기에 판매상품 추가하기
                </Button>
              )}
            </div>
            <div
              style={{
                width: '49%',
                backgroundColor: '#f9f9f9',
                padding: '10px 15px',
                borderRadius: borderRadiuses.primary,
              }}
            >
              <div style={{ marginBottom: '5px' }}>판매상품 숨김</div>
              <div className="select-container">
                <Select
                  style={{ width: '100%' }}
                  options={[
                    { label: 'OFF', value: isHiddenValue.OFF },
                    { label: 'ON', value: isHiddenValue.ON },
                  ]}
                  value={saleFormInputs.is_hidden}
                  disabled={pageType === 'detail'}
                  onSelect={(e) =>
                    setSaleFormInputs((prev) => ({ ...prev, is_hidden: e }))
                  }
                />
              </div>
            </div>
          </div>
        </Descriptions.Item>
      </Descriptions>
    </Form>
  );
});

export default SaleFormComponent;

SaleFormComponent.propTypes = {
  saleItems: PropTypes.instanceOf(Array).isRequired,
  saleData: PropTypes.instanceOf(Object).isRequired,
  pageType: PropTypes.string.isRequired,
};
