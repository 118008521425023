import React from 'react';
import { Input, Form, Space, Select } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import './Phone.css';

const InputStyle = styled(Input)`
  text-align: center;
  border: 0.1;
`;
const PhoneContainer = styled.div`
  display: ${(props) => (props.visibility ? 'block' : 'none')};
`;

const Phone = (props) => {
  const { disabled, visibility } = props;

  console.log(visibility);

  return (
    <PhoneContainer visibility={visibility}>
      <p style={{ padding: '0 0 12px 0', fontWeight: 700 }}>
        <span style={{ color: 'red' }}>*</span> 전화번호
      </p>
      <Space.Compact size="large">
        <Form.Item style={{ width: '20%' }}>
          <InputStyle placeholder="010" disabled />
        </Form.Item>
        <Form.Item
          name="frontNumber"
          rules={[{ required: true, message: '앞 자리 입력' }]}
        >
          {disabled ? (
            <InputStyle maxLength="4" disabled />
          ) : (
            <InputStyle maxLength="4" />
          )}
        </Form.Item>
        <Form.Item style={{ width: '20%' }}>
          <InputStyle placeholder="-" disabled />
        </Form.Item>
        <Form.Item
          name="backNumber"
          rules={[{ required: true, message: '뒷 자리 입력' }]}
        >
          {disabled ? (
            <InputStyle disabled maxLength="4" />
          ) : (
            <InputStyle maxLength="4" />
          )}
        </Form.Item>
      </Space.Compact>
      {/*
      <p style={{ padding: '0 0 12px 0', fontWeight: 700 }}>
        <span style={{ color: 'red' }}>*</span> 전화번호
      </p>
      <Input.Group compact size="large">
        <Input
          style={{ width: 85, textAlign: 'center', pointerEvents: 'none' }}
          placeholder="010"
          disabled
        />
        <Form.Item
          name="frontNumber"
          rules={[{ required: true, message: '앞 자리 입력' }]}
        >
          {disabled ? (
            <Input
              style={{ width: 130, textAlign: 'center', borderRadius: 'none' }}
              maxLength="4"
              disabled
            />
          ) : (
            <Input
              style={{ width: 130, textAlign: 'center', borderRadius: 'none' }}
              maxLength="4"
            />
          )}
        </Form.Item>
        <Input
          style={{
            width: 80,
            textAlign: 'center',
            pointerEvents: 'none',
          }}
          placeholder="-"
          disabled
        />
        <Form.Item
          name="backNumber"
          rules={[{ required: true, message: '뒷 자리 입력' }]}
        >
          {disabled ? (
            <Input
              style={{ width: 120, textAlign: 'center' }}
              disabled
              maxLength="4"
            />
          ) : (
            <Input style={{ width: 120, textAlign: 'center' }} maxLength="4" />
          )}
        </Form.Item>
      </Input.Group>
       */}
    </PhoneContainer>
  );
};

export default Phone;

Phone.propTypes = {
  disabled: PropTypes.bool,
  visibility: PropTypes.bool.isRequired,
};
