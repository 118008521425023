import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const AccountGroupColumn = (props) => {
  const { value } = props;
  const dispatch = useDispatch();
  const [acctGroupName, setAcctGroupName] = useState('');

  useEffect(() => {
    const getAcctGroup = async () => {
      try {
        const acctGroup = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'account_group',
            eqKey: 'code',
            eqValue: value,
          })
        )
          .unwrap()
          .then((res) => res[0]);
        if (acctGroup) {
          setAcctGroupName(acctGroup.name);
        }
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    };
    getAcctGroup();
  }, [dispatch, value]);

  return <div>{acctGroupName}</div>;
};

export default AccountGroupColumn;

AccountGroupColumn.propTypes = {
  value: PropTypes.string,
};
