import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const StyledButton = styled.button`
  padding: 10px 15px;
  font-size: 16px;
  margin-right: 25px;
  border-radius: 5px;
  border: 1px solid white;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  opacity: 1;
  &:hover {
    background-color: white;
    cursor: pointer;
    opacity: 0.9;
  }
`;

const DashboardButton = () => {
  const history = useHistory();
  return (
    <StyledButton onClick={() => history.push('/counselling')}>
      게시판
    </StyledButton>
  );
};

export default DashboardButton;
