import React from 'react';
import { Form, Input } from 'antd';
import Phone from '../../shared/components/phone/Phone';

const ProfileForm = () => {
  return (
    <>
      <div style={{ marginTop: '16px' }}>이름</div>
      <Form.Item
        noStyle
        name="username"
        rules={[{ required: true, message: '이름을 입력해주세요.' }]}
      >
        <Input
          size="large"
          placeholder="실제 이름을 입력해주세요."
          style={{ borderBottom: '1px solid lightGray', borderRadius: '0px' }}
          bordered={false}
        />
      </Form.Item>
      <div style={{ marginTop: '16px' }}>전화번호</div>
      <Form.Item
        noStyle
        name="phone"
        rules={[{ required: true, message: '전화번호를 입력해주세요.' }]}
      >
        <Input
          size="large"
          type="tel"
          pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
          placeholder="예) 010-1234-5678"
          style={{ borderBottom: '1px solid lightGray', borderRadius: '0px' }}
          bordered={false}
        />
      </Form.Item>
    </>
  );
};

export default ProfileForm;
