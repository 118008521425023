import { useState, useEffect, useCallback } from 'react';
import uuid from 'react-uuid';
import { getAccountGroupApi, getAllAccountsApi } from '../apis/accountApi';

const useSmsReceiver = () => {
  const [allAccounts, setAllAccounts] = useState([]);
  const [receivers, setReceivers] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [receiverInfo, setReceiverInfo] = useState({
    mobile: null,
  });

  // 수신자 입력하기
  const handleReceiverInfo = (name, newValue) => {
    setReceiverInfo((prev) => ({ ...prev, [name]: newValue }));
  };

  // 입력된 수신자 추가하기
  const addReceiverInfo = () => {
    const { mobile } = receiverInfo;
    if (!mobile || mobile.length !== 11 || !/^\d+$/.test(mobile)) {
      alert('수신번호 11자리를 입력해주세요. (하이픈(-) 제거.)');
      return;
    }
    const formattedMobile = mobile.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    const isExistReceiver = receivers.some(
      (receiver) => receiver.mobile === formattedMobile
    );
    if (isExistReceiver) {
      alert('이미 입력된 수신번호입니다.');
      return;
    }

    const newReceiver = {
      id: uuid(),
      groupCode: null,
      checked: false,
      mobile: formattedMobile,
      name: '거래처없음',
    };

    setReceivers((prev) => [...prev, newReceiver]);

    setReceiverInfo({
      mobile: null,
    });
  };

  // 거래처 구분 데이터 가져오기
  const getGroupData = async () => {
    const fetchedGroupData = await getAccountGroupApi();
    const newGroupData = fetchedGroupData.map((group) => ({
      id: group.id,
      code: group.code,
      name: group.name,
      category: group.category,
    }));
    setGroupData([...newGroupData]);
  };

  // 모든 거래처 데이터 가져오기
  const getAllAccounts = async () => {
    const fetchedAllAccounts = await getAllAccountsApi();
    const newAllAccounts = fetchedAllAccounts
      .filter((account) => account.mobile)
      .map((account) => ({
        id: account.id,
        mobile: account.mobile,
        groupCode: account.account_group1,
        name: account.name,
      }));
    setAllAccounts([...newAllAccounts]);
  };

  // 전체 거래처로 수신자 가져오기
  const handleReceiversByAllAccounts = () => {
    if (receivers.length) {
      const confirm = window.confirm(
        '선택된 수신번호가 대체됩니다. 정말 진행하시겠습니까?'
      );
      if (!confirm) return;
    }
    const newReceivers = allAccounts.map((account) => ({
      ...account,
      checked: false,
    }));

    // useRef로 수정
    setReceivers([...newReceivers]);
  };

  // 선택된 거래처 구분으로 수신자 가져오기
  const handleReceiversByGroupCode = (groupCode) => {
    if (receivers.length) {
      const confirm = window.confirm(
        '선택된 수신번호가 대체됩니다. 정말 진행하시겠습니까?'
      );
      if (!confirm) return;
    }
    const groupedAccounts = allAccounts.filter(
      (account) => account.groupCode === groupCode
    );
    const newReceivers = groupedAccounts.map((groupedAccount) => ({
      ...groupedAccount,
      checked: false,
    }));

    // useRef로 수정
    setReceivers([...newReceivers]);
  };

  // 모든 수신자 체크하기
  const handleAllCheckReceivers = () => {
    // useRef로 수정
    setReceivers((receivers) => [
      ...receivers.map((receiver) => ({ ...receiver, checked: true })),
    ]);
  };

  // 모든 수신자 체크풀기
  const handleAllUnCheckReceivers = () => {
    // useRef로 수정
    setReceivers((receivers) => [
      ...receivers.map((receiver) => ({ ...receiver, checked: false })),
    ]);
  };

  // 특정 수신자 체크하기 / 체크풀기
  const handleCheckReceiver = (receiverId) => {
    // useRef로 수정
    setReceivers((receivers) => [
      ...receivers.map((receiver) =>
        receiver.id === receiverId
          ? { ...receiver, checked: !receiver.checked }
          : receiver
      ),
    ]);
  };

  // 특정 수신자 삭제하기
  const removeReceiver = (receiverId) => {
    // useRef로 수정
    setReceivers((receivers) => [
      ...receivers.filter((receiver) => receiver.id !== receiverId),
    ]);
  };

  // 특정 수신자 추가하기
  const addReceiver = (mobile) => {
    // 수신자가 이미 존재하는지 확인
    const isExistReceiver = receivers.some(
      (receiver) => receiver.mobile === mobile
    );
    if (isExistReceiver) {
      alert('이미 선택된 수신번호입니다.');
      return;
    }
    const selectedAccount = allAccounts.filter(
      (account) => account.mobile === mobile
    )[0];
    if (!selectedAccount) return;
    const newReceiver = { ...selectedAccount, checked: false };
    // useRef로 수정
    setReceivers((receivers) => [...receivers, newReceiver]);
  };

  // 체크된 수신자 삭제하기
  const removeCheckedReceivers = () => {
    // useRef로 수정
    setReceivers((receivers) => [
      ...receivers.filter((receiver) => !receiver.checked),
    ]);
  };

  useEffect(() => {
    getGroupData();
    getAllAccounts();
  }, []);

  return {
    allAccounts,
    receivers,
    groupData,
    receiverInfo,
    handleReceiverInfo,
    addReceiverInfo,
    handleReceiversByAllAccounts,
    handleReceiversByGroupCode,
    handleAllCheckReceivers,
    handleAllUnCheckReceivers,
    handleCheckReceiver,
    removeReceiver,
    addReceiver,
    removeCheckedReceivers,
  };
};

export default useSmsReceiver;
