import React, { useMemo } from 'react';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import ProductFormItems from '../components/ProductFormItems';

const CreateProduct = () => {
  // 해당 page 타입 선언하기
  const pageType = 'create';

  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <InboxOutlined />,
        breadCrumbNav: ' 출고상품(품목)',
      },
      {
        breadCrumbIcon: <PlusOutlined />,
        breadCrumbNav: ' 추가',
      },
    ],
    []
  );

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px' }}>
        <ProductFormItems pageType={pageType} />
      </div>
    </LayoutComponent>
  );
};

export default CreateProduct;
