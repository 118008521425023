import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

const SelectFormField = ({ name, value, onChangeValue }) => {
  let items;
  let placeholder;

  switch (name) {
    case 'status':
      items = [
        { label: '대기중', value: 'READY' },
        { label: '처리중', value: 'PROCESSING' },
        { label: '완료', value: 'COMPLETED' },
      ];
      placeholder = '요청상태를 선택해주세요.';
      break;
    case 'statusName':
      items = [
        { label: '실패', value: 'fail' },
        { label: '성공', value: 'success' },
      ];
      placeholder = '수신상태를 선택해주세요.';
      break;
    case 'type':
      items = [
        { label: 'SMS', value: 'SMS' },
        { label: 'LMS', value: 'LMS' },
        { label: 'MMS', value: 'MMS' },
      ];
      placeholder = '타입을 선택해주세요.';
      break;
    case 'contentType':
      items = [
        { label: '일반용', value: 'COMM' },
        { label: '광고용', value: 'AD' },
      ];
      placeholder = '컨텐츠타입을 선택해주세요.';
      break;
    case 'rangeType':
      items = [
        {
          label: '요청일시',
          value: 'request',
        },
        {
          label: '완료일시',
          value: 'complete',
        },
      ];
      break;
    default:
      break;
  }

  return (
    <Select
      value={value}
      onSelect={(newValue) => onChangeValue(name, newValue)}
      style={{ width: '100%' }}
      placeholder={placeholder}
    >
      {items.map((item, index) => (
        <Select.Option key={index} value={item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectFormField;

SelectFormField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string || PropTypes.null,
  onChangeValue: PropTypes.func.isRequired,
};
