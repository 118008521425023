import React from 'react';
import { Button, Popover } from 'antd';
import {
  ApiFilled,
  ApiOutlined,
  BarChartOutlined,
  BarsOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ExternalServicesPopover = () => {
  const content = (
    <div style={{ width: '250px' }}>
      <div
        style={{
          fontSize: '16px',
          fontWeight: '600',
          width: '100%',
          borderBottom: '1px solid lightgray',
          padding: '10px',
        }}
      >
        외부서비스
      </div>
      <ContentContainer>
        <Link
          to="/external-services/map-distribution"
          style={{ color: '#3a3a3a', width: '100%' }}
          type="link"
          size="small"
        >
          <IconContainer>
            <EnvironmentOutlined />
          </IconContainer>
          <span>지도분포도</span>
        </Link>
      </ContentContainer>
      <ContentContainer>
        <Link
          to="/external-services/hantong-dashboard"
          style={{ color: '#3a3a3a', width: '100%' }}
          type="link"
          size="small"
        >
          <IconContainer>
            <BarChartOutlined />
          </IconContainer>
          <span>한통대시보드</span>
        </Link>
      </ContentContainer>
      <ContentContainer>
        <Link
          to="/external-services/delivery-tracker"
          style={{ color: '#3a3a3a', width: '100%' }}
          type="link"
          size="small"
        >
          <IconContainer>
            <BarsOutlined />
          </IconContainer>
          <span>택배배송현황판</span>
        </Link>
      </ContentContainer>
    </div>
  );

  return (
    <div style={{ marginRight: '10px' }}>
      <Popover
        content={content}
        placement="bottomLeft"
        overlayInnerStyle={{
          padding: '0px',
          borderRadius: '5px',
        }}
      >
        <HoverIconStyle>
          <ApiFilled />
        </HoverIconStyle>
      </Popover>
    </div>
  );
};

export default ExternalServicesPopover;

const HoverIconStyle = styled.div`
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  opacity: 1;
  :hover {
    opacity: 0.9;
  }
  border: 1px solid lightgray;
`;
const ContentContainer = styled.div`
  padding: 10px;
  width: 100%;
  :hover {
    background-color: #efefef;
  }
`;
const IconContainer = styled.span`
  margin-right: 15px;
  font-weight: 600;
`;
