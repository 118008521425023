import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import {
  getAccountInfoByCodeApi,
  getInvoiceByAccountApi,
} from '../apis/accountApi';
import getOrderProductPriceInfo from '../utils/getOrderProductPriceInfo';
import downloadExcelFile from '../utils/downloadExcelFile';

const { handleInvoiceForm } = downloadExcelFile();
const writeComma = (value) => value.toLocaleString();

const invoiceColumns = [
  { title: '일자', dataIndex: 'date', key: 'date', align: 'center' },
  { title: '품목', dataIndex: 'name', key: 'name', align: 'center' },
  { title: '수량', dataIndex: 'quantity', key: 'quantity', align: 'center' },
  {
    title: '단가',
    dataIndex: 'price',
    key: 'price',
    align: 'center',
    render: (value) => writeComma(value),
  },
  {
    title: '공급가액',
    dataIndex: 'supply',
    key: 'supply',
    align: 'center',
    render: (value) => writeComma(value),
  },
  {
    title: '부가세',
    dataIndex: 'vat',
    key: 'vat',
    align: 'center',
    render: (value) => writeComma(value),
  },
  {
    title: '합계',
    dataIndex: 'sum',
    key: 'sum',
    align: 'center',
    render: (value) => writeComma(value),
  },
];

const useInvoiceHook = () => {
  const [invoices, setInvoices] = useState([]);
  const [accountInfo, setAccountInfo] = useState({
    name: null,
    address: null,
    contact: null,
  });

  // 서버에서 거래명세서 데이터 가져오기
  const fetchInvoices = async (accountCode, dateRange) => {
    const postData = { accountCode, dateRange };
    const fetchedDatas = await getInvoiceByAccountApi(postData);
    return fetchedDatas;
  };

  // 거래명세서 데이터 포맷팅
  const formatInvoices = (invoices) => {
    const formattedDatas = invoices.map((data) => {
      const { id, product_name: name, product_quantity: quantity } = data;

      // state가 '8'이면 이카운트에서 옮긴 데이터이므로
      // salesDate가 NULL.
      const { state, sales_date: salesDate, order_date: orderDate } = data;
      const date =
        state === '8'
          ? dayjs(orderDate).format('YYYY/MM/DD')
          : dayjs(salesDate).format('YYYY/MM/DD');

      // 알맞은 단가, 공급가액, 부가세, 합계
      const { price, supply, vat, sum } = getOrderProductPriceInfo(data);

      const selected = false;

      return { id, name, quantity, date, price, vat, supply, sum, selected };
    });

    return formattedDatas;
  };

  // 거래명세서 데이터 정렬 (일자)
  const sortInvoices = (invoices) => {
    const sortedDatas = invoices.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    return sortedDatas;
  };

  const generateInvoices = async (accountCode, dateRange) => {
    const fetchedInvoices = await fetchInvoices(accountCode, dateRange);
    if (!fetchedInvoices.length) {
      setInvoices([]);
      return alert('해당 거래처의 데이터가 존재하지 않습니다.');
    }
    const formattedInvoices = formatInvoices(fetchedInvoices);
    const sortedInvoices = sortInvoices(formattedInvoices);

    setInvoices(sortedInvoices);
  };

  const fetchAccountInfo = async (accountCode) => {
    const postData = { accountCode };
    const fetchedData = await getAccountInfoByCodeApi(postData);
    return fetchedData[0];
  };

  const generateAccountInfo = async (accountCode) => {
    if (!accountCode) {
      return alert('거래처를 선택해주세요.');
    }
    const fetchedAccountInfo = await fetchAccountInfo(accountCode);
    const { mobile, address, account_name } = fetchedAccountInfo;
    setAccountInfo({ name: account_name, contact: mobile, address });
  };

  // 체크된 (선택된) 거래명세서
  const selectedInvoices = useMemo(() => {
    return invoices.filter((invoice) => invoice.selected);
  }, [invoices]);

  // 동일 일자, 동일 품목별로 합산된 거래명세서
  const groupedInvoices = useMemo(() => {
    return selectedInvoices.reduce((groupedInvoices, currentInvoice) => {
      // 동일 일자, 동일 품목인 거래명세서 단건 찾기
      const existTarget = groupedInvoices.filter(
        (groupedInvoice) =>
          groupedInvoice.date === currentInvoice.date &&
          groupedInvoice.name === currentInvoice.name
      )[0];
      if (!existTarget) {
        groupedInvoices.push({
          date: currentInvoice.date,
          name: currentInvoice.name,
          quantity: currentInvoice.quantity,
          price: currentInvoice.price,
          vat: currentInvoice.vat,
          supply: currentInvoice.supply,
          sum: currentInvoice.sum,
        });
      } else {
        existTarget.quantity += currentInvoice.quantity;
        existTarget.vat += currentInvoice.vat;
        existTarget.supply += currentInvoice.supply;
        existTarget.sum += currentInvoice.sum;
      }
      return groupedInvoices;
    }, []);
  }, [selectedInvoices]);

  // 거래명세서 총합
  const summaryInvoices = useMemo(() => {
    return groupedInvoices.reduce(
      (sumInvoice, curInvoice) => {
        sumInvoice.quantity += curInvoice.quantity;
        sumInvoice.price += curInvoice.price;
        sumInvoice.vat += curInvoice.vat;
        sumInvoice.supply += curInvoice.supply;
        sumInvoice.sum += curInvoice.sum;

        return sumInvoice;
      },
      { quantity: 0, price: 0, vat: 0, supply: 0, sum: 0 }
    );
  }, [groupedInvoices]);

  // 체크리스트에서 단건 선택
  const checkInvoice = (invoiceId, isChecked) => {
    setInvoices((prev) =>
      prev.map((invoice) =>
        invoice.id === invoiceId ? { ...invoice, selected: isChecked } : invoice
      )
    );
  };

  // 체크리스트 전체 선택
  const checkAll = (isChecked) => {
    setInvoices((prev) =>
      prev.map((invoice) => ({ ...invoice, selected: isChecked }))
    );
  };

  // 거래명세서 파일 다운로드
  const downloadInvoiceFile = () => {
    if (!groupedInvoices.length) {
      return alert(
        '데이터가 존재하지 않아 거래명세서 파일을 생성할 수 없습니다.'
      );
    }

    handleInvoiceForm({
      headerData: accountInfo,
      contentData: groupedInvoices,
      footerData: summaryInvoices,
    });
  };

  return {
    invoices,
    accountInfo,
    groupedInvoices,
    summaryInvoices,
    invoiceColumns,
    generateInvoices,
    generateAccountInfo,
    checkInvoice,
    checkAll,
    downloadInvoiceFile,
  };
};

export default useInvoiceHook;
