import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const IsSentValue = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const [isSent, setIsSent] = useState(false);
  useEffect(() => {
    const getIsSent = async () => {
      const data = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'estimate_sent',
          eqKey: 'estimate_id',
          eqValue: id,
        })
      )
        .unwrap()
        .then((res) => res[0])
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
      setIsSent(data.is_sent);
    };
    getIsSent();
  }, [dispatch, id]);

  return <div>{isSent ? <div>발송</div> : <div>미발송</div>}</div>;
};

export default IsSentValue;

IsSentValue.propTypes = {
  id: PropTypes.string.isRequired,
};
