import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Skeleton, Spin } from 'antd';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const AddressColumn = (props) => {
  const { code } = props;
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const asyncLoadingHandler = async () => {
      await setLoading(true);
      await dispatch(
        asyncReadFilteredDataFetch({
          table: 'account_address',
          eqKey: 'account_code',
          eqValue: code,
        })
      )
        .unwrap()
        .then((res) => {
          res.forEach((data) => {
            if (data.is_representative) {
              setAddress(data.address);
            }
          });
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
      setLoading(false);
    };
    asyncLoadingHandler();
  }, [dispatch, code]);

  return loading ? (
    <Skeleton.Input active style={{ height: '20px' }} />
  ) : (
    <div>{address}</div>
  );
};

export default AddressColumn;

AddressColumn.propTypes = {
  code: PropTypes.string.isRequired,
};
