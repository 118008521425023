import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { refreshApi } from '../../../apis/authApi';

const initialState = {
  isLoadingRefreshSession: false,
  isSuccessRefreshSession: false,
  isErrorRefreshSession: false,
  sessionChecked: false,
};

const asyncRefreshSessionFetch = createAsyncThunk(
  'RefreshSessionSlice/asyncRefreshSessionFetch',
  async () => {
    try {
      const userInfo = await refreshApi();
      return { userInfo, isLoggedIn: true };
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message || '서버에러');
    }
  }
);

const refreshSessionSlice = createSlice({
  name: 'refreshSession',
  initialState,
  reducers: {
    endRefreshSession: (state) => {
      state.sessionChecked = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncRefreshSessionFetch.pending, (state) => {
      state.isLoadingRefreshSession = true;
    });
    builder.addCase(asyncRefreshSessionFetch.fulfilled, (state) => {
      state.isLoadingRefreshSession = false;
      state.isSuccessRefreshSession = true;
    });
    builder.addCase(asyncRefreshSessionFetch.rejected, (state) => {
      state.isLoadingRefreshSession = false;
      state.isErrorRefreshSession = true;
    });
  },
});

export default refreshSessionSlice.reducer;
export { asyncRefreshSessionFetch };
export const { endRefreshSession } = refreshSessionSlice.actions;
