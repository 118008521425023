import React from 'react';
import { Form, Input, Button } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { UserOutlined, KeyOutlined } from '@ant-design/icons';

const ButtonStyleContainer = styled.div`
  width: 100%;
`;
const LabelStyle = styled.p``;

const UpdateProfileForm = (props) => {
  const { handleResetPassword } = props;
  const { isLoadingResetPassword } = useSelector(
    (state) => state.resetPassword
  );

  return (
    <>
      <Form.Item label={<LabelStyle>이메일</LabelStyle>} name="email">
        <Input disabled style={{ borderRadius: '0px' }} />
      </Form.Item>
      <Form.Item
        label={<LabelStyle>이름</LabelStyle>}
        name="username"
        rules={[{ required: true, message: '이름을 입력해주세요.' }]}
      >
        <Input
          placeholder="실제 이름을 입력해주세요."
          style={{ borderRadius: '0px' }}
        />
      </Form.Item>
      <Form.Item
        label={<LabelStyle>전화번호</LabelStyle>}
        name="phone"
        rules={[{ required: true, message: '전화번호를 입력해주세요.' }]}
      >
        <Input
          type="tel"
          placeholder="예) 010-1234-5678"
          pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
          style={{ borderRadius: '0px' }}
        />
      </Form.Item>
      <Form.Item label={<LabelStyle>자기소개</LabelStyle>} name="intro">
        <Input
          placeholder="간략한 자기소개를 입력해주세요."
          style={{ borderRadius: '0px' }}
        />
      </Form.Item>
      <Form.Item
        style={{
          margin: '0',
          textAlign: 'right',
          position: 'absolute',
          bottom: '0',
          right: '0',
          width: '100%',
          padding: '12px',
          borderTop: '1px solid #e7e7e7',
          backgroundColor: '#fafafa',
        }}
      >
        <Button
          loading={isLoadingResetPassword}
          onClick={isLoadingResetPassword ? false : handleResetPassword}
        >
          <KeyOutlined />
          비밀번호 변경
        </Button>
        <Button htmlType="submit" type="primary" style={{ marginLeft: '5px' }}>
          <UserOutlined />
          프로필 저장
        </Button>
      </Form.Item>
    </>
  );
};

export default UpdateProfileForm;

UpdateProfileForm.propTypes = {
  handleResetPassword: PropTypes.func.isRequired,
};
