import React, { useMemo } from 'react';
import { AuditOutlined, EditOutlined } from '@ant-design/icons';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Items from '../components/Items';

const UpdateOrder = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <AuditOutlined />,
        breadCrumbNav: ' 주문',
      },
      {
        breadCrumbIcon: <EditOutlined />,
        breadCrumbNav: ' 수정',
      },
    ],
    []
  );
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px' }}>
        <Items pageType="update" />
      </div>
    </LayoutComponent>
  );
};

export default UpdateOrder;
