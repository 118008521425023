import { Button } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { asyncReadPaginatedDataFetch } from '../../redux/modules/shared/readPaginatedData';
import axiosInstance from '../../axios';

const batchSize = 10000;

const PartMigrator = ({ table }) => {
  const dispatch = useDispatch();

  const [migrationStatus, setMigrationStatus] = useState('대기');
  const [isButtonInValid, setIsButtonInValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [migratedRowCount, setMigratedRowCount] = useState(0);
  const [migratedPartInfos, setMigratedPartInfos] = useState([]);
  const [currentPartRange, setCurrentPartRange] = useState({
    from: 0,
    to: batchSize - 1,
  });

  const handleMigration = async (table) => {
    setIsButtonInValid(true);
    setMigrationStatus('마이그레이션 진행 중...');
    setIsLoading(true);

    const { from, to } = currentPartRange;

    try {
      const migrationData = await dispatch(
        asyncReadPaginatedDataFetch({ table, from, to })
      ).unwrap();
      const newMigratedRowCount = migrationData.length;

      // 서버 migration api 호출
      await axiosInstance.post(`/api/migration/${table}`, migrationData);

      setMigratedRowCount((prev) => prev + newMigratedRowCount);
      setMigratedPartInfos((prev) => [
        ...prev,
        { label: `part ${prev.length + 1}`, value: newMigratedRowCount },
      ]);
      const isLastBatch = newMigratedRowCount !== batchSize;
      setIsButtonInValid(isLastBatch);
      setMigrationStatus(isLastBatch ? '완료' : '대기');
      setCurrentPartRange({ from: from + batchSize, to: to + batchSize });
    } catch (error) {
      window.alert(error);
      console.error('마이그레이션 오류', error);

      setIsButtonInValid(false);
      setMigrationStatus('오류');
      setIsLoading(false);

      return;
    }

    setIsLoading(false);
  };

  const handleDelete = async (table) => {
    try {
      await axiosInstance.delete(`/api/migration/${table}`);
      window.alert(`테이블: ${table} \n삭제 완료`);
    } catch (error) {
      window.alert(`삭제 오류 \n${error}`);
      console.error('삭제 오류', error);
    }
  };

  return (
    <div
      style={{
        margin: '5px 0',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          margin: '5px 0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3>{table}</h3>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <div>successed: {migratedRowCount.toLocaleString()}</div>
          <div>{migrationStatus}</div>
          <Button
            disabled={isButtonInValid}
            type="primary"
            onClick={() => handleMigration(table)}
            loading={isLoading}
          >
            MIGRATE
          </Button>
          <Button danger type="primary" onClick={() => handleDelete(table)}>
            DELETE
          </Button>
        </div>
      </div>
      {migratedPartInfos.map((part, index) => (
        <div key={index}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '5px 0',
            }}
          >
            <div>{part.label}</div>
            <div>{part.value}</div>
          </div>
        </div>
      ))}
      <hr />
    </div>
  );
};

export default PartMigrator;

PartMigrator.propTypes = {
  table: PropTypes.string.isRequired,
};
