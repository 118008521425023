import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { passwordResetApi } from '../../../apis/authApi';

const initialState = {
  isLoadingResetPassword: false,
  isSuccessResetPassword: false,
  isErrorResetPassword: false,
};

const asyncResetPasswordFetch = createAsyncThunk(
  'resetPasswordSlice/asyncResetPasswordFetch',
  async (values, { rejectWithValue }) => {
    try {
      await passwordResetApi(values);
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message || '서버에러');
    }
  }
);

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncResetPasswordFetch.pending, (state) => {
      state.isLoadingResetPassword = true;
    });
    builder.addCase(asyncResetPasswordFetch.fulfilled, (state) => {
      state.isLoadingResetPassword = false;
      state.isSuccessResetPassword = true;
    });
    builder.addCase(asyncResetPasswordFetch.rejected, (state) => {
      state.isLoadingResetPassword = false;
      state.isErrorResetPassword = true;
    });
  },
});

export default resetPasswordSlice.reducer;
export { asyncResetPasswordFetch };
