import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SettingOutlined } from '@ant-design/icons';
import DraggableContainer from './DraggableContainer';

const SortOrderSetting = (props) => {
  const { saleDatas, setSaleDatas } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Button
        style={{
          position: 'absolute',
          top: '15px',
          right: '20px',
          borderRadius: '20px',
        }}
        onClick={openModal}
      >
        <SettingOutlined />
        설정
      </Button>
      {isModalOpen && (
        <Modal
          open
          centered
          footer={null}
          onCancel={closeModal}
          width={700}
          title="계산기 설정"
        >
          <DndProvider backend={HTML5Backend}>
            <DraggableContainer
              saleDatas={saleDatas}
              setSaleDatas={setSaleDatas}
            />
          </DndProvider>
        </Modal>
      )}
    </div>
  );
};

export default SortOrderSetting;

SortOrderSetting.propTypes = {
  saleDatas: PropTypes.instanceOf(Array).isRequired,
  setSaleDatas: PropTypes.func.isRequired,
};
