import { AlignCenterOutlined } from '@ant-design/icons';
import React, { useContext, createContext, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  borderRadiuses,
  DARK_MODE_BACKGROUND,
  LIGHT_MODE_BACKGROUND,
} from '../../constants';

const InventoryStatusCardContext = createContext({
  getContent: () => {},
  darkMode: false,
});

const InventoryStatusCardMain = ({ children, title, updatedAt }) => {
  // darkMode state를 불러옴.
  const { darkMode } = useSelector((state) => state.darkMode);

  // 컴파운드 컴포넌트에서 공유할 함수 생성.
  const getContent = useCallback(({ availableDays, stackQuantity }) => {
    let formattedAvailableDays;
    if (availableDays >= 100) {
      formattedAvailableDays = `${String(availableDays).slice(0, 1)}H`;
    } else if (availableDays >= 1000) {
      formattedAvailableDays = `${String(availableDays).slice(0, 1)}K`;
    } else {
      formattedAvailableDays = String(availableDays);
    }
    return (
      <div>
        <span>{stackQuantity}</span>
        <span style={{ marginRight: '3px' }}>Ξ</span>
        <span>{formattedAvailableDays}일</span>
      </div>
    );
  }, []);

  // useMemo로 공유할 것들을 묶어줌.
  const value = useMemo(
    () => ({ getContent, darkMode }),
    [getContent, darkMode]
  );

  return (
    <InventoryStatusCardContext.Provider value={value}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px',
          color: 'gray',
          borderRadius: borderRadiuses.primary,
          height: '100%',
          backgroundColor: darkMode
            ? DARK_MODE_BACKGROUND
            : LIGHT_MODE_BACKGROUND,
          position: 'relative',
        }}
      >
        {updatedAt && (
          <div
            style={{
              fontSize: '10px',
              position: 'absolute',
              left: '12px',
              top: '12px',
            }}
          >
            최신화 {updatedAt}
          </div>
        )}
        <h4>{title}</h4>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
            gap: '20px',
            padding: '10px',
          }}
        >
          {children}
        </div>
      </div>
    </InventoryStatusCardContext.Provider>
  );
};

export const useInventoryStatusCardContext = () =>
  useContext(InventoryStatusCardContext);
export default InventoryStatusCardMain;

InventoryStatusCardMain.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
};
