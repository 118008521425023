import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import InventoryStatusCardMain from '../../shared/components/InventoryStatusCardMain';
import InventoryStatusCardContent from '../../shared/components/InventoryStatusCardContent';
import InventoryStatusCardContainer from '../../shared/components/InventoryStatusCardContainer';
import InventoryStatusCardDivider from '../../shared/components/InventoryStatusCardDivider';
import { productCodes } from '../../constants';

// 각 컴포넌트 모듈을 컴파운드화 (객체화) 시킴.
const InventoryStatusCard = Object.assign(InventoryStatusCardMain, {
  Content: InventoryStatusCardContent,
  Container: InventoryStatusCardContainer,
  Divider: InventoryStatusCardDivider,
});

const InventoryStatus = (props) => {
  const { forecastData, forecastUpdatedAt } = props;

  const getCardContentProps = useCallback(
    (key) => {
      const props = {
        availableDays: 0,
        stackQuantity: 0,
        productName: key,
        textColor: 'gray',
      };
      if (forecastData[key]) {
        const { availableDays, stackQuantity, productName, textColor } =
          forecastData[key];
        Object.assign(props, {
          availableDays,
          stackQuantity,
          productName,
          textColor,
        });
      }
      return props;
    },
    [forecastData]
  );

  return (
    <InventoryStatusCard title="일반 재고" updatedAt={null}>
      <InventoryStatusCard.Container>
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.eps_003)}
        />
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.eps_006)}
        />
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.pp_11)}
        />
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.eps_004)}
        />
      </InventoryStatusCard.Container>
      <InventoryStatusCard.Divider />
      <InventoryStatusCard.Container>
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.pbox_03)}
        />
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.pbox_04)}
        />
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.pp_05)}
        />
        <InventoryStatusCard.Content
          {...getCardContentProps(productCodes.pp_06)}
        />
      </InventoryStatusCard.Container>
    </InventoryStatusCard>
  );
};

export default InventoryStatus;

InventoryStatus.propTypes = {
  forecastData: PropTypes.instanceOf(Object).isRequired,
  forecastUpdatedAt: PropTypes.string.isRequired,
};
