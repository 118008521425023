import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../axios';
import useSupabase from '../../../serverConfig';

import supabase from '../../../supabase';

const initialState = {
  isLoadingReadLikeData: false,
  isErrorReadLikeData: false,
  isSuccessReadLikeData: false,
};

const asyncReadLikeDataFetch = createAsyncThunk(
  'readLikeDataSlice/asyncReadLikeDataFetch',
  async (values, { rejectWithValue }) => {
    // values의 필수 값 => table
    const { table, likeKey, likeValue } = values;
    try {
      const { data } = useSupabase
        ? await supabase.from(table).select().like(likeKey, `%${likeValue}%`)
        : await axiosInstance.get(
            `/api/dynamic-crud/${table}/like?key=${likeKey}&value=${likeValue}`
          );

      console.log(data);

      const result = data.filter((item) => item.state !== 9);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const readLikeDataSlice = createSlice({
  name: 'readLikeData',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncReadLikeDataFetch.pending, (state) => {
      state.isLoadingReadLikeData = true;
      state.isSuccessReadLikeData = false;
      state.isErrorReadLikeData = false;
    });
    builder.addCase(asyncReadLikeDataFetch.fulfilled, (state) => {
      state.isLoadingReadLikeData = false;
      state.isSuccessReadLikeData = true;
      state.isErrorReadLikeData = false;
    });
    builder.addCase(asyncReadLikeDataFetch.rejected, (state) => {
      state.isLoadingReadLikeData = false;
      state.isSuccessReadLikeData = false;
      state.isErrorReadLikeData = true;
    });
  },
});

export default readLikeDataSlice.reducer;
export { asyncReadLikeDataFetch };
