import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import supabase from '../../../supabase';

const initialState = {
  isLoadingResetPassword: false,
  isSuccessResetPassword: false,
  isErrorResetPassword: false,
};

const asyncResetPasswordFetch = createAsyncThunk(
  'resetPasswordSlice/asyncResetPasswordFetch',
  async (values, { rejectWithValue }) => {
    try {
      const { data, error } = await supabase.auth.resetPasswordForEmail(
        values.email,
        {
          // 경로 바꿔야 함.
          redirectTo: 'https://hantong-oms.vercel.app/reset-password',
        }
      );
      if (error) throw error;
      if (data) {
        return data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncResetPasswordFetch.pending, (state) => {
      state.isLoadingResetPassword = true;
    });
    builder.addCase(asyncResetPasswordFetch.fulfilled, (state) => {
      state.isLoadingResetPassword = false;
      state.isSuccessResetPassword = true;
    });
    builder.addCase(asyncResetPasswordFetch.rejected, (state) => {
      state.isLoadingResetPassword = false;
      state.isErrorResetPassword = true;
    });
  },
});

export default resetPasswordSlice.reducer;
export { asyncResetPasswordFetch };
