import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import useSupabase from '../../../serverConfig';
import axiosInstance from '../../../axios';
import supabase from '../../../supabase';

const initialState = {
  isLoadingReadFilteredData: false,
  isErrorReadFilteredData: false,
  isSuccessReadFilteredData: false,
};

const asyncReadFilteredDataFetch = createAsyncThunk(
  'readFilteredDataSlice/asyncReadFilteredDataFetch',
  async (values, { rejectWithValue }) => {
    if (window.location.pathname === '/data-calendar') {
      // values의 필수 값 => table, eqKey, eqValue
      const { table, eqKey, eqValue, readMethod } = values;
      try {
        if (readMethod === 'include') {
          const { data, error } = useSupabase
            ? await supabase
                .from(table)
                .select()
                .filter(eqKey, 'like', `${eqValue}%`)
            : await axiosInstance.get(
                `/api/dynamic-crud/${table}/like?key=${eqKey}&value=${eqValue}`
              );

          console.log(data);

          if (error) throw error;
          const result = data.filter((item) => item.state !== 9);
          return result;
        }
        if (readMethod === 'same') {
          const { data, error } = useSupabase
            ? await supabase.from(table).select().eq(eqKey, eqValue)
            : await axiosInstance.get(
                `/api/dynamic-crud/${table}/key?key=${eqKey}&value=${eqValue}`
              );

          console.log(data);

          if (error) throw error;
          const result = data.filter((item) => item.state !== 9);
          return result;
        }
      } catch (error) {
        return rejectWithValue(error);
      }
    } else {
      // values의 필수 값 => table, eqKey, eqValue
      const { table, eqKey, eqValue } = values;
      try {
        const { data, error } = useSupabase
          ? await supabase.from(table).select().eq(eqKey, eqValue)
          : await axiosInstance.get(
              `/api/dynamic-crud/${table}/key?key=${eqKey}&value=${eqValue}`
            );

        console.log(data);

        if (error) throw error;
        const result = data.filter((item) => item.state !== 9);
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  }
);

const readFilteredDataSlice = createSlice({
  name: 'readFilteredData',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncReadFilteredDataFetch.pending, (state) => {
      state.isLoadingReadFilteredData = true;
    });
    builder.addCase(asyncReadFilteredDataFetch.fulfilled, (state) => {
      state.isLoadingReadFilteredData = false;
      state.isSuccessReadFilteredData = true;
    });
    builder.addCase(asyncReadFilteredDataFetch.rejected, (state) => {
      state.isLoadingReadFilteredData = false;
      state.isErrorReadFilteredData = true;
    });
  },
});

export default readFilteredDataSlice.reducer;
export { asyncReadFilteredDataFetch };
