import React, { useState, useEffect, useMemo } from 'react';
import { Button, Descriptions, Divider, Modal, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ArrowDownOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import DiscountProduct from './DiscountProduct';

const ProductDetail = (props) => {
  const { productCode, setSaleProductTableRows, productQty, pageType } = props;
  const dispatch = useDispatch();
  const [productData, setProductData] = useState({});
  const [pdInfoModalOpen, setPdInfoModalOpen] = useState(false);

  useEffect(() => {
    dispatch(
      asyncReadFilteredDataFetch({
        table: 'product',
        eqKey: 'code',
        eqValue: productCode,
      })
    ).then((res) => {
      setProductData({ ...res.payload[0] });
    });
    return () => {
      setProductData({});
    };
  }, [dispatch, productCode]);

  const items = useMemo(
    () => [
      {
        key: 1,
        label: '출고상품코드',
        name: 'code',
        value: productData.code,
      },
      {
        key: 2,
        label: '출고상품명',
        name: 'name',
        value: productData.name,
      },
      {
        key: 3,
        label: '규격타입',
        name: 'standard_type',
        value: productData.standard_type,
      },
      {
        key: 4,
        label: '규격',
        name: 'standard',
        value: productData.standard,
      },
      {
        key: 5,
        label: '단위',
        name: 'unit',
        value: productData.unit,
      },
      {
        key: 6,
        label: '품목구분',
        name: 'category',
        value: productData.category,
      },
      {
        key: 7,
        label: '세트여부',
        name: 'set',
        value: productData.set,
      },
      {
        key: 8,
        label: '재고수량관리',
        name: 'quantity',
        value: productData.quantity,
      },
      {
        key: 9,
        label: '입고단가',
        name: 'incoming_price',
        value: productData.incoming_price,
      },
      {
        key: 10,
        label: '입고단가 VAT',
        name: 'incoming_price_VAT',
        value: productData.incoming_price_VAT,
      },
      {
        key: 11,
        label: '출고단가',
        name: 'release_price',
        value: productData.release_price,
      },
      {
        key: 12,
        label: '출고단가 VAT',
        name: 'release_price_VAT',
        value: productData.release_price_VAT,
      },
      {
        key: 13,
        label: '구매처',
        name: 'purchase',
        value: productData.purchase,
      },
      {
        key: 14,
        label: '구메처',
        name: 'pruchase_code',
        value: productData.purchase_code,
      },
      {
        key: 15,
        lable: '택배비',
        name: 'delivery_code',
        value: productData.delivery_code,
      },
    ],
    [productData]
  );

  const pdInfoContent = (
    <div style={{ padding: '15px 20px 20px 20px' }}>
      <div
        style={{
          fontSize: '18px',
          paddingBottom: '10px',
          borderBottom: '1px solid gray',
        }}
      >
        출고상품 정보
      </div>
      <Descriptions
        bordered
        labelStyle={{ width: '150px', textAlign: 'center' }}
        contentStyle={{ width: '250px' }}
      >
        {items.map((item) => {
          if (item.name === 'standard_type') {
            return (
              <Descriptions.Item label={item.label} key={item.key}>
                {item.value === '1' && '규격명'}
                {item.value === '2' && '규격그룹'}
                {item.value === '3' && '규격계산'}
                {item.value === '4' && '규격계산그룹'}
              </Descriptions.Item>
            );
          }
          if (item.name === 'category') {
            return (
              <Descriptions.Item label={item.label} key={item.key}>
                {item.value === '0' && '원재료'}
                {item.value === '4' && '부재료'}
                {item.value === '1' && '제품'}
                {item.value === '2' && '반제품'}
                {item.value === '3' && '상품'}
                {item.value === '7' && '무형상품'}
              </Descriptions.Item>
            );
          }
          if (item.name === 'set') {
            return (
              <Descriptions.Item label={item.label} key={item.key}>
                {item.value === '1' && '사용'}
                {item.value === '0' && '미사용'}
              </Descriptions.Item>
            );
          }
          if (item.name === 'quantity') {
            return (
              <Descriptions.Item label={item.label} key={item.key}>
                {item.value === '1' && '수량관리대상'}
                {item.value === '0' && '수량관리제외'}
              </Descriptions.Item>
            );
          }
          if (
            item.name === 'incoming_price_VAT' ||
            item.name === 'release_price_VAT'
          ) {
            return (
              <Descriptions.Item label={item.label} key={item.key}>
                {item.value === '1' && '포함'}
                {item.value === '0' && '미포함'}
              </Descriptions.Item>
            );
          }
          if (item.name === 'incoming_price' || item.name === 'release_price') {
            return (
              <Descriptions.Item label={item.label} key={item.key}>
                {item.value && item.value.toLocaleString()}
              </Descriptions.Item>
            );
          }
          if (item.name === 'delivery_code') {
            return (
              <Descriptions.Item label={item.lable} key={item.key}>
                {item.value === 'parcel1' && '택배비5,000'}
                {item.value === 'parcel2' && '택배비8,000'}
                {item.value === 'parcel3' && '퀵배송45,000'}
                {item.value === 'parcel4' && '택배비4,000'}
                {item.value === 'parcel5' && '택배비3,000'}
              </Descriptions.Item>
            );
          }
          return (
            <Descriptions.Item label={item.label} key={item.key}>
              {item.value}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    </div>
  );

  return (
    <div>
      <Popover
        placement="bottom"
        trigger="focus"
        content={
          <div style={{ textAlign: 'center' }}>
            <div style={{ color: 'gray', marginBottom: '10px' }}>
              <InfoCircleOutlined /> 원하는 기능을 선택해주세요.
            </div>
            <Button
              onClick={() => setPdInfoModalOpen(true)}
              type="primary"
              size="small"
              style={{ width: '100%', marginBottom: '5px' }}
            >
              <SearchOutlined /> 상품정보
            </Button>
            <DiscountProduct
              productCode={productCode}
              setSaleProductTableRows={setSaleProductTableRows}
              productQty={productQty}
              pageType={pageType}
            />
          </div>
        }
      >
        <Button size="small">{productCode}</Button>
      </Popover>
      {pdInfoModalOpen && (
        <Modal
          open
          width={1000}
          centered
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          onCancel={() => setPdInfoModalOpen(false)}
        >
          {pdInfoContent}
        </Modal>
      )}
    </div>
  );
};

export default ProductDetail;

ProductDetail.propTypes = {
  productCode: PropTypes.string.isRequired,
  setSaleProductTableRows: PropTypes.func.isRequired,
  productQty: PropTypes.number.isRequired,
  pageType: PropTypes.string.isRequired,
};
