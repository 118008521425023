import { DatabaseOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import { Button } from 'antd';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Table from '../../shared/table/Table';
import AccountColumnValue from '../../shared/purchase/AccountColumnValue';
import ProductColumnValue from '../../shared/purchase/ProductColumnValue';
import AdminColumnValue from '../../shared/purchase/AdminColumnValue';
import WarehouseColumnValue from '../../shared/purchase/WarehouseColumnValue';
import TotalPriceColumnValue from '../../shared/purchase/TotalPriceColumnValue';
import StatusColumnValue from '../../shared/purchase/StatusColumnValue';

const PurchaseOrder = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <DatabaseOutlined />,
        breadCrumbNav: ' 발주조회',
      },
    ],
    []
  );
  // 불러올 table이 뭔지
  const table = 'purchase_order';

  // TableHeader에 들어갈 create 버튼 텍스트
  const createButtonText = '발주 추가';

  // table header에 들어갈 create 버튼의 Modal객체 (Modal 속성으로 넣어줄 객체)
  const createButtonModal = {
    type: 'purchase_order',
    modalInfo: {
      modalTitle: '발주 추가',
      resultStatus: '',
      resultTitle: '',
      resultSubTitle: '',
      buttonText: '',
    },
    dataInfo: {
      dataId: '',
      dataTable: 'purchase_order',
      datas: {},
    },
  };

  // table에 넣을 columns. table View를 위한 배열.
  const tableColumns = [
    {
      title: '일자',
      dataIndex: 'purchase_order_date',
      key: 'purchase_date',
      width: '10%',
      align: 'center',
    },
    {
      title: '납기일자',
      dataIndex: 'delivery_date',
      key: 'purchase_date',
      width: '10%',
      align: 'center',
    },
    {
      title: '담당자명',
      dataIndex: 'admin_id',
      key: 'admin_id',
      width: '10%',
      align: 'center',
      render: (value) => <AdminColumnValue adminId={value} />,
    },
    {
      title: '거래처명',
      dataIndex: 'account_id',
      key: 'account_id',
      width: '20%',
      align: 'center',
      render: (value) => <AccountColumnValue accountId={value} />,
    },
    {
      title: '품목명',
      dataIndex: 'id',
      key: 'id',
      width: '20%',
      align: 'center',
      render: (value) => (
        <ProductColumnValue id={value} importIdValue="purchaseOrderId" />
      ),
    },
    {
      title: '발주금액합계',
      dataIndex: 'purchase_total',
      key: 'purchase_total',
      width: '10%',
      align: 'center',
      render: (_, record) => (
        <TotalPriceColumnValue id={record.id} importIdValue="purchaseOrderId" />
      ),
    },
    {
      title: '창고명',
      dataIndex: 'warehouse_id',
      key: 'warehouse_id',
      width: '10%',
      align: 'center',
      render: (value) => <WarehouseColumnValue warehouseId={value} />,
    },
    {
      title: '진행상태',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      align: 'center',
      render: (value, record) => (
        <StatusColumnValue purchaseOrderId={record.id} status={value} />
      ),
    },
  ];
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <Table
        tableColumns={tableColumns}
        table={table}
        createButtonText={createButtonText}
        createButtonModal={createButtonModal}
        items={tableColumns}
      />
    </LayoutComponent>
  );
};

export default PurchaseOrder;
