import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Empty, Input, Popover, Tag } from 'antd';
import { RightOutlined, SortAscendingOutlined } from '@ant-design/icons';

const WordShortHandler = (props) => {
  const { salesStatusDatas, setSalesStatusDatas, getVisibleColumn } = props;
  const [isClickedColumn, setIsClickedColumn] = useState(false);
  const [clickedColumn, setClickedColumn] = useState(null);
  const [beforeWords, setBeforeWords] = useState([]);
  const [afterWord, setAfterWord] = useState(null);
  const [selectedWord, setSelectedWord] = useState(null);
  const [shortenWords, setShortenWords] = useState([]);

  const initiallizeAllStates = () => {
    setIsClickedColumn(false);
    setClickedColumn(null);
    setBeforeWords([]);
    setAfterWord(null);
    setSelectedWord(null);
    setShortenWords([]);
  };

  const initiallizeTwoStates = () => {
    setSelectedWord(null);
    setAfterWord(null);
  };

  const getBeforeShortenWords = (column) => {
    const onlyClickedColumnsValues = salesStatusDatas.map(
      (item) => item[column]
    );
    const deduplicatedValues = [...new Set(onlyClickedColumnsValues)];
    const result = deduplicatedValues.filter((item) => item && item.length > 0);
    setBeforeWords(result);
  };

  const submitShortenWord = () => {
    if (!selectedWord) {
      alert('축약할 대상(문자)을 선택해주세요.');
      return;
    }
    const shortenSalesStatusDatas = salesStatusDatas.map((item) => {
      let newItem = null;
      if (item[clickedColumn] === selectedWord) {
        newItem = { ...item, [clickedColumn]: afterWord };
      } else {
        newItem = item;
      }
      return newItem;
    });
    setSalesStatusDatas([...shortenSalesStatusDatas]);

    setShortenWords((prev) => [
      ...prev,
      { before: selectedWord, after: afterWord },
    ]);

    initiallizeTwoStates();
  };

  return (
    <Popover
      title={
        <div
          style={{
            backgroundColor: '#efefef',
            padding: '3px',
            borderRadius: '5px',
            textAlign: 'center',
          }}
        >
          단어 변환
        </div>
      }
      trigger="click"
      placement="rightTop"
      onOpenChange={initiallizeAllStates}
      content={
        <div
          style={{
            display: 'flex',
            maxHeight: '500px',
            overflow: 'auto',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {getVisibleColumn().map((item) => (
              <Button
                style={{
                  color: 'black',
                  backgroundColor:
                    clickedColumn && clickedColumn === item.key
                      ? '#efefef'
                      : 'white',
                }}
                type="link"
                size="small"
                onClick={() => {
                  setIsClickedColumn(true);
                  setClickedColumn(item.key);
                  getBeforeShortenWords(item.key);
                  initiallizeTwoStates();
                }}
              >
                {item.title}
              </Button>
            ))}
          </div>
          {isClickedColumn && (
            <div style={{ display: 'flex', marginLeft: '20px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                <div style={{ marginBottom: '10px' }}>축약 전 (선택)</div>
                {beforeWords.length <= 0 && (
                  <Empty
                    description={
                      <div style={{ color: 'gray' }}>
                        선택할 대상이 없습니다.
                      </div>
                    }
                  />
                )}
                {beforeWords.map((item) => (
                  <Button
                    style={{
                      color: 'black',
                      backgroundColor:
                        selectedWord && selectedWord === item
                          ? '#efefef'
                          : 'white',
                    }}
                    size="small"
                    type="link"
                    onClick={() => {
                      setSelectedWord(item);
                      setAfterWord(null);
                    }}
                  >
                    <div>{item}</div>
                  </Button>
                ))}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '5px',
                  textAlign: 'center',
                }}
              >
                <div style={{ marginBottom: '10px' }}>축약 후 (입력)</div>
                <Input
                  style={{ width: '120px' }}
                  size="small"
                  placeholder="Enter를 눌러주세요"
                  value={afterWord}
                  onChange={(e) => setAfterWord(e.target.value)}
                  onPressEnter={submitShortenWord}
                  disabled={!selectedWord}
                />
              </div>
              {shortenWords.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '20px',
                    textAlign: 'center',
                  }}
                >
                  <div style={{ marginBottom: '10px' }}>축약 결과</div>
                  {shortenWords.map((item) => (
                    <Tag style={{ fontSize: '14px' }}>
                      <span>{item.before}</span>
                      <span style={{ margin: '0 5px' }}>
                        <RightOutlined />
                      </span>
                      <span>{item.after}</span>
                    </Tag>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      }
    >
      <Button style={{ borderRadius: '20px', marginLeft: '5px' }}>
        <SortAscendingOutlined />
      </Button>
    </Popover>
  );
};

export default WordShortHandler;

WordShortHandler.propTypes = {
  salesStatusDatas: PropTypes.instanceOf(Array).isRequired,
  setSalesStatusDatas: PropTypes.func.isRequired,
  getVisibleColumn: PropTypes.func.isRequired,
};
