import genBeginAndEndDate from './genBeginAndEndDate';

// 버튼으로 해당기준일 생성하는 유틸함수 => genBeginAndEndDate 함수 참조
const getDateByBtn = (dateBtnVal) => {
  const {
    genTodayDate,
    genYesterdayDate,
    genThisWeekDate,
    genLastWeekDate,
    genThisMonthDate,
    genLastMonthDate,
    genThisYearDate,
    genLastYearDate,
  } = genBeginAndEndDate();
  // dateBtnVal이 금일 (today)일 때
  if (dateBtnVal === 'today') {
    return genTodayDate();
  }
  // dateBtnVal이 어제 (yesterday)일 때
  if (dateBtnVal === 'yesterday') {
    return genYesterdayDate();
  }
  // dateBtnVal이 금주 (thisWeek)일 때
  if (dateBtnVal === 'thisWeek') {
    return genThisWeekDate();
  }
  // dateBtnVal이 전주 (lastWeek)일 때
  if (dateBtnVal === 'lastWeek') {
    return genLastWeekDate();
  }
  // dateBtnVal이 금월 (thisMonth)일 때
  if (dateBtnVal === 'thisMonth') {
    return genThisMonthDate();
  }
  // dateBtnVal이 전월 (lastMonth)일 때
  if (dateBtnVal === 'lastMonth') {
    return genLastMonthDate();
  }
  // dateBtnVal이 올해 (thisYear)일 때
  if (dateBtnVal === 'thisYear') {
    return genThisYearDate();
  }
  // dateBtnVal이 작년 (lastYear)일 때
  if (dateBtnVal === 'lastYear') {
    return genLastYearDate();
  }
};
// 기준일자가 선택되지 않을 때 => false 리턴
// 기준일자가 선택되었을 때 => formInputs에 입력된 해당 기준일자 (formInputs.date) 리턴
// 기준일자버튼이 선택되었을 때 => genBeginAndEndDate로 해당 기준일자 생성 후 리턴
const getBeginAndEndDate = (isDateBtn, dateBtnVal, formInputs) => {
  let beginAndEndDate = null;
  if (isDateBtn) {
    beginAndEndDate = getDateByBtn(dateBtnVal);
  } else {
    beginAndEndDate = formInputs.date;
  }
  if (!beginAndEndDate) {
    alert('기준일자를 선택해주세요.');
    return false;
  }
  return beginAndEndDate;
};

export default getBeginAndEndDate;
