import React, { useMemo } from 'react';
import { ReconciliationOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Popconfirm } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Table from '../../shared/table/Table';
import AccountValue from '../../order/components/AccountValue';
import AdminValue from '../../order/components/AdminValue';
import IsSentValue from '../components/IsSentValue';
import ExpirationDateValue from '../components/ExpirationDateValue';
import TotalEstimateValue from '../components/TotalEstimateValue';
import EstimateProductValue from '../components/EstimateProductValue';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import { asyncDeleteDataFetch } from '../../redux/modules/shared/deleteData';

const Estimate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // breadCrumb 데이터 생성
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <ReconciliationOutlined />,
        breadCrumbNav: ' 견적서',
      },
    ],
    []
  );

  // 불러올 table이 뭔지
  const table = 'order';

  const createButtonText = '견적서 추가';

  const changeToOrder = async (id) => {
    try {
      await dispatch(
        asyncUpdateDataFetch({ table: 'order', id, payment_status: 0 })
      )
        .unwrap()
        .then(async () => {
          await dispatch(
            asyncDeleteDataFetch({ table: 'estimate_sent', estimateId: id })
          );
          const goToOrderDetail = window.confirm(
            '견적서가 주문서로 전환되었습니다. 전환된 주문서를 수정하시겠습니까?'
          );
          if (goToOrderDetail) {
            history.push(`/order/updateOrder/${id}`);
          }
          return;
        });
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const goToOrder = (id) => {
    history.push(`/order/createOrder/estimateToOrder/${id}`);
  };

  // table에 넣을 columns. table View를 위한 배열.
  const tableColumns = [
    {
      title: '주문일',
      dataIndex: 'order_date',
      render: (data) => <div>{data}</div>,
      width: '12.5%',
      align: 'center',
    },
    {
      title: '거래처명',
      dataIndex: 'account_code',
      key: 'account',
      render: (data) => <AccountValue accountCode={data} />,
      width: '12.5%',
      align: 'center',
    },
    {
      title: '담당자명',
      dataIndex: 'admin_code',
      key: 'admin_code',
      render: (data) => {
        return <AdminValue adminCode={data} />;
      },
      width: '12.5%',
      align: 'center',
    },
    {
      title: '품목명',
      key: 'products',
      render: (data) => {
        return <EstimateProductValue id={data.id} />;
      },
      width: '12.5%',
      align: 'center',
    },
    {
      title: '유효기간',
      key: 'expiration_date',
      render: (data) => {
        return <ExpirationDateValue id={data.id} />;
      },
      width: '12.5%',
      align: 'center',
    },
    {
      title: '견적금액합계',
      key: 'total',
      width: '12.5%',
      align: 'center',
      render: (data) => {
        return <TotalEstimateValue id={data.id} />;
      },
    },
    {
      title: '발송여부',
      key: 'is_sent',
      render: (data) => {
        return <IsSentValue id={data.id} />;
      },
      width: '12.5%',
      align: 'center',
    },
    {
      title: '주문서 전환',
      key: 'payment_status',
      dataIndex: 'payment_status',
      render: (data, record) => {
        if (data === '2') {
          return (
            <Popconfirm
              title={
                <div>
                  <h4>주문서로 전환하시겠습니까?</h4>
                  <div style={{ marginTop: '10px' }}>
                    <p>주문서전환 페이지로 이동합니다.</p>
                  </div>
                </div>
              }
              okText="네"
              cancelText="아니요"
              onConfirm={() => goToOrder(record.id)}
            >
              <Link to>주문지연</Link>
            </Popconfirm>
          );
        }
      },
      align: 'center',
      width: '12.5%',
    },
  ];
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <Table
        tableColumns={tableColumns}
        table={table}
        items={tableColumns}
        createButtonText={createButtonText}
      />
    </LayoutComponent>
  );
};

export default Estimate;
