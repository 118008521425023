import React from 'react';
import PropTypes from 'prop-types';

const AccountDetail = (props) => {
  const { value } = props;
  console.log(value);
};

export default AccountDetail;

AccountDetail.propTypes = {
  value: PropTypes.string.isRequired,
};
