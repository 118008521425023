import dayjs from 'dayjs';

const divideDirBySt = (st, salesDate) => {
  const curTime = dayjs(salesDate)
    .subtract(9, 'hour')
    .format('YYYY-MM-DD HH:mm:ss');
  const isMonday = dayjs().day() === 1;
  const isTuesday = dayjs().day() === 2;

  let start;
  let end;

  switch (st) {
    // 고려 1차
    case '1':
      if (isMonday) {
        start = `${dayjs().subtract(3, 'day').format('YYYY-MM-DD')} 15:55:00`;
        end = `${dayjs().format('YYYY-MM-DD')} 14:24:59`;
        break;
      }
      if (isTuesday) {
        start = `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')} 15:25:00`;
        end = `${dayjs().format('YYYY-MM-DD')} 14:54:59`;
      }
      start = `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')} 15:55:00`;
      end = `${dayjs().format('YYYY-MM-DD')} 14:49:59`;
      break;
    // 고려 2차
    case '2':
      if (isMonday) {
        start = `${dayjs().format('YYYY-MM-DD')} 14:25:00`;
        end = `${dayjs().format('YYYY-MM-DD')} 15:24:59`;
        break;
      }
      start = `${dayjs().format('YYYY-MM-DD')} 14:55:00`;
      end = `${dayjs().format('YYYY-MM-DD')} 15:54:59`;
      break;
    // 준테크 1차
    case '3':
      start = `${dayjs()
        .subtract(isMonday ? 3 : 1, 'day')
        .format('YYYY-MM-DD')} 15:20:00`;
      end = `${dayjs().format('YYYY-MM-DD')} 13:49:59`;
      break;
    // 준테크 2차
    case '4':
      start = `${dayjs().format('YYYY-MM-DD')} 13:50:00`;
      end = `${dayjs().format('YYYY-MM-DD')} 15:19:59`;
      break;
    default:
      break;
  }

  const time = start <= curTime && curTime <= end;
  const standard = `${start} ~ ${end}`;

  return { time, standard };
};

const divideDirByLocation = (location) => {
  switch (location) {
    // 고려
    case '1':
      return [{ value: '00009' }];
    // 준테크
    case '2':
      return [{ value: '00004' }];
    default:
      return;
  }
};

const divideDirName = (st) => {
  let name = null;

  switch (st) {
    case '1':
      name = '고려_1차';
      break;
    case '2':
      name = '고려_2차';
      break;
    case '3':
      name = '준테크_1차';
      break;
    case '4':
      name = '준테크_2차';
      break;
    default:
      break;
  }

  return name;
};

export { divideDirBySt, divideDirByLocation, divideDirName };
