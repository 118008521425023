import React from 'react';
import PropTypes from 'prop-types';
import ProductFormData from './ProductFormData';
import useSupabase from '../../serverConfig';

const isHiddenValue = useSupabase
  ? { ON: true, OFF: false }
  : { ON: 1, OFF: 0 };

const ProductFormItems = (props) => {
  const { pageType } = props;

  const formItems = [
    {
      type: 'input',
      name: 'code',
      label: '',
      message: '품목코드를 입력해주세요.',
      inputType: '',
      inputPattern: '',
      require: true,
      isComma: false,
      span: 3,
    },
    {
      type: 'input',
      name: 'oms_code',
      label: '품목코드',
      message: '품목코드를 입력해주세요.',
      inputType: '',
      inputPattern: '',
      require: true,
      isComma: false,
      span: 3,
    },
    {
      type: 'input',
      name: 'name',
      label: '품목명',
      message: '품목명을 입력해주세요.',
      inputType: '',
      inputPattern: '',
      require: true,
      isComma: false,
      span: 3,
    },
    {
      type: 'select',
      label: '규격타입',
      name: 'standard_type',
      message: '규격타입을 입력해주세요.',
      inputType: '',
      inputPattern: '',
      reqire: false,
      isComma: false,
      options: [
        { label: '규격명', value: '1' },
        { label: '규격그룹', value: '2' },
        { label: '규격계산', value: '3' },
        { label: '규격계산 그룹', value: '4' },
      ],
      span: 1,
    },
    {
      type: 'input',
      name: 'standard',
      label: '규격',
      message: '규격을 입력해주세요.',
      inputType: '',
      inputPattern: '',
      reqire: false,
      isComma: false,
      span: 2,
    },
    /*
    {
      type: 'select',
      label: '품목구분',
      name: 'category',
      message: '품목구분을 입력해주세요.',
      inputType: '',
      inputPattern: '',
      reqire: false,
      isComma: false,
      options: [
        { label: '[원재료]', value: '0' },
        { label: '[부재료]', value: '4' },
        { label: '[제품]', value: '1' },
        { label: '[반제품]', value: '2' },
        { label: '[상품]', value: '3' },
        { label: '[무형상품]', value: '7' },
      ],
      span: 2,
    },
    {
      type: 'select',
      label: '재고수량관리',
      name: 'quantity',
      message: '재고수량관리 여부를 입력해주세요.',
      placeholder: '',
      inputType: '',
      inputPattern: '',
      reqire: false,
      isComma: false,
      options: [
        { label: '수량관리제외', value: '0' },
        { label: '수량관리대상', value: '1' },
      ],
      span: 1,
    },
    {
      type: 'select',
      label: '세트여부',
      name: 'set',
      message: '세트여부를 입력해주세요.',
      inputType: '',
      inputPattern: '',
      reqire: false,
      isComma: false,
      options: [
        { label: '사용', value: '1' },
        { label: '미사용', value: '0' },
      ],
      span: 2,
    },
    */
    {
      type: 'input',
      name: 'incoming_price',
      label: '입고단가',
      message: '입고단가를 입력해주세요. *숫자만 입력',
      inputType: '',
      inputPattern: '',
      reqire: false,
      isComma: false,
      span: 1,
    },
    {
      type: 'select',
      label: '입고단가 VAT',
      name: 'incoming_price_VAT',
      message: '입고단가 VAT를 입력해주세요.',
      inputType: '',
      inputPattern: '',
      reqire: false,
      isComma: false,
      options: [
        { label: 'N', value: '0' },
        { label: 'Y', value: '1' },
      ],
      span: 2,
    },
    {
      type: 'input',
      name: 'release_price',
      label: '출고단가',
      message: '출고단가를 입력해주세요. *숫자만 입력',
      placeholder: '',
      inputType: '',
      inputPattern: '',
      reqire: false,
      isComma: false,
      span: 1,
    },
    {
      type: 'select',
      label: '출고단가 VAT',
      name: 'release_price_VAT',
      message: '출고단가 VAT를 입력해주세요.',
      inputType: '',
      inputPattern: '',
      reqire: false,
      isComma: false,
      options: [
        { label: 'N', value: '0' },
        { label: 'Y', value: '1' },
      ],
      span: 2,
    },
    {
      type: 'input',
      name: 'purchase',
      label: '구매처',
      message: '구매처를 입력해주세요.',
      inputType: '',
      inputPattern: '',
      reqire: false,
      isComma: false,
      span: 1,
    },
    /*
    {
      type: 'input',
      name: 'purchase_code',
      label: '구매처 코드',
      message: '구매처 코드를 입력해주세요.',
      inputType: '',
      inputPattern: '',
      reqire: false,
      isComma: false,
      span: 1,
    },
    */
    {
      type: 'select',
      name: 'warehouse_code',
      label: '출하창고',
      message: '출하창고를 선택해주세요.',
      inputType: '',
      inputPattern: '',
      reqire: false,
      isComma: false,
      span: 2,
    },
    {
      type: 'input',
      name: 'unit',
      label: '단위',
      message: '단위를 입력해주세요.',
      inputType: '',
      inputPattern: '',
      reqire: false,
      isComma: false,
      span: 1,
    },
    {
      type: 'select',
      name: 'is_hidden',
      label: '품목 숨김',
      message: '숨김여부를 선택해주세요.',
      inputType: '',
      inputPattern: '',
      require: true,
      isComma: false,
      options: [
        { label: 'ON', value: isHiddenValue.ON },
        { label: 'OFF', value: isHiddenValue.OFF },
      ],
      span: 2,
    },
    /*
    {
      type: 'select',
      name: 'delivery_type',
      label: '배송방법',
      message: '배송방법을 선택해주세요.',
      inputType: '',
      inputPattern: '',
      reqire: false,
      isComma: false,
      options: [
        { label: '택배', value: '1' },
        { label: '화물차', value: '2' },
        { label: '직접수령', values: '3' },
      ],
      span: 2,
    },
    {
      type: 'select',
      name: 'delivery_code',
      label: '택배비선택',
      message: '택배비를 선택해주세요.',
      inputType: '',
      inputPattern: '',
      require: false,
      isComma: false,
      options: [
        { label: '택배비5,000', value: 'parcel1' },
        { label: '택배비8,000', value: 'parcel2' },
        { label: '택배비45,000', value: 'parcel3' },
        { label: '택배비4,000', value: 'parcel4' },
        { label: '택배비3,000', value: 'parcel5' },
      ],
    },
    */
  ];
  return <ProductFormData pageType={pageType} formItems={formItems} />;
};

export default ProductFormItems;

ProductFormItems.propTypes = {
  pageType: PropTypes.string.isRequired,
};
