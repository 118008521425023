const generateMatchQueryString = (match) => {
  const matchKeys = Object.keys(match);
  const matchQueryArray = matchKeys.map(
    (key, index) =>
      `&matchKey${index + 1}=${key}&matchValue${index + 1}=${match[key]}`
  );
  const matchQueryString = matchQueryArray.join('');
  return matchQueryString;
};

export default generateMatchQueryString;
