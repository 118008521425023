import React from 'react';
import { Button, Form, Input } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const ButtonStyle = styled(Button)`
  width: 100%;
  margin-top: 20px;
  background-color: #001528;
  color: white;
  height: 100%;
  padding: 8px 0;
`;

const PasswordLinkForm = (props) => {
  const { onFinish, onFinishFailed } = props;
  const { isLoadingResetPassword } = useSelector(
    (state) => state.resetPassword
  );

  return (
    <Form
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="on"
    >
      <div style={{ textAlign: 'left' }}>이메일</div>
      <Form.Item
        noStyle
        name="email"
        rules={[
          { required: true, message: '이메일을 입력해주세요' },
          { type: 'email', message: '이메일 양식으로 입력해주세요.' },
        ]}
      >
        <Input
          size="large"
          id="email"
          style={{ borderRadius: '0px', borderBottom: '1px solid lightGray' }}
          bordered={false}
          placeholder="가입된 이메일 입력"
        />
      </Form.Item>
      <Form.Item>
        {isLoadingResetPassword ? (
          <ButtonStyle loading>보내기</ButtonStyle>
        ) : (
          <ButtonStyle htmlType="submit">보내기</ButtonStyle>
        )}
      </Form.Item>
    </Form>
  );
};

export default PasswordLinkForm;

PasswordLinkForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onFinishFailed: PropTypes.func.isRequired,
};
