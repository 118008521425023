import React from 'react';
import { Descriptions, Select, DatePicker, Button, Tag } from 'antd';
import PropTypes from 'prop-types';

import useInventoryReceiptsSelectOptions from '../../hook/useInventoryReceiptsSelectOptions';
import useDateRange from '../../hook/useDateRange';
import DateRangeButtonMain from '../../shared/components/DateRangeButtonMain';
import DateRangeButtonItem from '../../shared/components/DateRangeButtonItem';
import { dateTypes } from '../../constants';
import SelectedOptionContainer from '../../shared/components/SelectedOptionContanier';
import SelectedOptionCard from '../../shared/components/SelectedOptionCard';
import CalendarTranslator from '../../shared/components/CalendarTranslator';

const InventoryReceiptsSearch = ({ getReceiptsData }) => {
  const [options, selectedOptions, selectOption, removeOption] =
    useInventoryReceiptsSelectOptions({
      warehouse: [],
      product: [],
    });
  const [dateRange, handleDateRange, formatDate, unFormatDate] = useDateRange();

  const DateRangeButton = Object.assign(DateRangeButtonMain, {
    Item: DateRangeButtonItem,
  });

  const checkIsExistDateRange = (dateRange) => {
    const { startDate, endDate } = dateRange;

    return startDate !== null && endDate !== null;
  };

  return (
    <>
      <Descriptions
        bordered
        column={1}
        labelStyle={{ width: '10%', textAlign: 'center' }}
      >
        <Descriptions.Item label="기준일자">
          <CalendarTranslator>
            <DatePicker.RangePicker
              style={{ width: '100%', borderRadius: '0' }}
              onChange={(dateRangeArr) => {
                const startDate = formatDate(dateRangeArr[0]);
                const endDate = formatDate(dateRangeArr[1]);
                handleDateRange({ startDate, endDate });
              }}
              value={
                dateRange.startDate && dateRange.endDate
                  ? [
                      unFormatDate(dateRange.startDate),
                      unFormatDate(dateRange.endDate),
                    ]
                  : null
              }
            />
          </CalendarTranslator>
        </Descriptions.Item>
        <Descriptions.Item label="창고">
          {selectedOptions?.warehouse && (
            <SelectedOptionContainer>
              {selectedOptions.warehouse.map((item) => (
                <SelectedOptionCard
                  id={item.id}
                  title={item.name}
                  name="warehouse"
                  removeOption={removeOption}
                />
              ))}
            </SelectedOptionContainer>
          )}
          <Select
            options={options.warehouse.map((item) => ({
              label: `${item.code} / ${item.name}`,
              value: item.id,
            }))}
            onChange={(optionDataId) =>
              selectOption({
                optionKey: 'warehouse',
                optionDataId,
              })
            }
            value={null}
            placeholder="창고"
            style={{ width: '100%' }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="품목">
          <div>
            {selectedOptions?.product && (
              <SelectedOptionContainer>
                {selectedOptions.product.map((item) => (
                  <SelectedOptionCard
                    id={item.id}
                    title={item.name}
                    name="product"
                    removeOption={removeOption}
                  />
                ))}
              </SelectedOptionContainer>
            )}
          </div>
          <Select
            options={options.product.map((item) => ({
              label: `${item.code} / ${item.name}`,
              value: item.id,
            }))}
            onChange={(optionDataId) =>
              selectOption({
                optionKey: 'product',
                optionDataId,
              })
            }
            value={null}
            placeholder="품목"
            style={{ width: '100%' }}
          />
        </Descriptions.Item>
      </Descriptions>
      <div
        style={{
          backgroundColor: '#f9f9f9',
          padding: '15px',
          marginTop: '10px',
          border: '1px solid #efefef',
          borderRadius: '20px',
          display: 'flex',
        }}
      >
        <Button
          style={{ borderRadius: '20px', marginRight: '5px' }}
          type="primary"
          onClick={() => {
            const isExistDateRange = checkIsExistDateRange(dateRange);
            if (!isExistDateRange) {
              window.alert('기준일자를 선택해주세요');
              return;
            }
            getReceiptsData({
              formData: selectedOptions,
              optionData: options,
              dateRange,
            });
          }}
        >
          검색
        </Button>
        <DateRangeButton
          handleDateRange={handleDateRange}
          formData={selectedOptions}
          optionData={options}
          getReceiptsData={getReceiptsData}
        >
          <DateRangeButton.Item dateType={dateTypes.today}>
            오늘
          </DateRangeButton.Item>
          <DateRangeButton.Item dateType={dateTypes.yesterday}>
            어제
          </DateRangeButton.Item>
          <DateRangeButton.Item dateType={dateTypes.this_week}>
            금주(~오늘)
          </DateRangeButton.Item>
          <DateRangeButton.Item dateType={dateTypes.one_week_ago}>
            전주
          </DateRangeButton.Item>
          <DateRangeButton.Item dateType={dateTypes.this_month}>
            금월(~오늘)
          </DateRangeButton.Item>
          <DateRangeButton.Item dateType={dateTypes.one_month_ago}>
            전월
          </DateRangeButton.Item>
          <DateRangeButton.Item
            dateType={dateTypes.one_month_ago_and_this_month}
          >
            전월+금월
          </DateRangeButton.Item>
        </DateRangeButton>
      </div>
    </>
  );
};

export default InventoryReceiptsSearch;

InventoryReceiptsSearch.propTypes = {
  getReceiptsData: PropTypes.func.isRequired,
};
