import dayjs from 'dayjs';
import { useState } from 'react';
import {
  saveSmsFeedbackApi,
  saveSmsHistoryApi,
  sendSmsApi,
  updateRequestedSmsHistoryApi,
} from '../apis/smsApi';

const useSmsSubmit = () => {
  const [loadingInfo, setLoadingInfo] = useState({
    loading: false,
    text: null,
  });

  const validateSubmit = (formErrors, receiverCount) => {
    let isValid = true;
    let errorMessage = null;

    // 해당 속성의 에러 여부 boolean값
    const { sender, title, content, sendDate, sendTime } = formErrors;
    // 수신번호 존재여부
    const existReceiver = !!receiverCount;

    // 발신번호 미유효
    if (sender) {
      isValid = false;
      errorMessage = '발신번호 11자리를 입력해주세요. (하이픈(-) 제거.)';
      // 제목 미유효
    } else if (title) {
      isValid = false;
      errorMessage = '제목을 입력해주세요. (4자 이상.)';
      // 내용 미유효
    } else if (content) {
      isValid = false;
      errorMessage = '내용을 입력해주세요. (10자 이상, 제한된 글자수 이하.)';
      // 에약발송 날짜 미유효
    } else if (sendDate) {
      isValid = false;
      errorMessage = '예약발송할 날짜를 선택해주세요';
      // 예약발송 시간 미유효
    } else if (sendTime) {
      isValid = false;
      errorMessage = '예약발송할 시간을 선택해주세요.';
    } else if (!existReceiver) {
      isValid = false;
      errorMessage = '수신번호가 없습니다.';
    }

    return { isValid, errorMessage };
  };

  const checkValidReserve = (formValues) => {
    const { isReserve, sendDate, sendTime } = formValues;
    if (!isReserve) {
      return true;
    }

    const reserveTime = `${sendDate} ${sendTime}`;
    const curTime = dayjs().format('YYYY-MM-DD HH:mm');
    const isMoreThan15Minutes =
      dayjs(reserveTime).diff(dayjs(curTime), 'minute') >= 15;
    return isMoreThan15Minutes;
  };

  const createFormData = (formValues) => {
    const {
      type,
      contentType,
      nationalNo,
      sender,
      title,
      content,
      isReserve,
      sendDate,
      sendTime,
    } = formValues;

    const baseFormData = {
      type,
      contentType,
      countryCode: nationalNo,
      from: sender,
      content,
    };

    const subject = { subject: title };
    const reserveTime = { reserveTime: `${sendDate} ${sendTime}` };
    const adContent = {
      content: `(광고) (주)한통 ${content} [무료 수신거부]08012345678`,
    };

    // SMS - 일반메시지
    if (type === 'SMS' && contentType === 'COMM' && !isReserve) {
      return baseFormData;
    }
    // SMS - 예약메시지
    if (type === 'SMS' && contentType === 'COMM' && isReserve) {
      return Object.assign(baseFormData, reserveTime);
    }
    // SMS - 광고메시지 (일반)
    if (type === 'SMS' && contentType === 'AD' && !isReserve) {
      return Object.assign(baseFormData, adContent);
    }
    // SMS - 광고메시지 (예약)
    if (type === 'SMS' && contentType === 'AD' && isReserve) {
      return Object.assign(baseFormData, { ...adContent, ...reserveTime });
    }
    // LMS / MMS - 일반메시지
    if (type !== 'SMS' && contentType === 'COMM' && !isReserve) {
      return Object.assign(baseFormData, subject);
    }
    // LMS / MMS - 예약메시지
    if (type !== 'SMS' && contentType === 'COMM' && isReserve) {
      return Object.assign(baseFormData, { ...subject, ...reserveTime });
    }
    // LMS / MMS - 광고메시지 (일반)
    if (type !== 'SMS' && contentType === 'AD' && !isReserve) {
      return Object.assign(baseFormData, { ...subject, ...adContent });
    }
    // LMS / MMS - 광고메시지 (예약)
    if (type !== 'SMS' && contentType === 'AD' && isReserve) {
      return Object.assign(baseFormData, {
        ...subject,
        ...adContent,
        ...reserveTime,
      });
    }

    return new Error('타입이 지정되지 않았습니다.');
  };

  const createMessages = (receivers) => {
    // mobile 포맷팅 (하이픈 제거.)
    return receivers.map((receiver) => ({
      to: receiver.mobile.replace(/-/g, ''),
    }));
  };

  const createPostDataArray = (formData, messages) => {
    const chunkSize = 100;
    const result = [];
    // 100건 씩 끊기.
    for (let i = 0; i < messages.length; i += chunkSize) {
      result.push({ ...formData, messages: messages.slice(i, i + chunkSize) });
    }
    return result;
  };

  const handleSmsSubmit = async (formErrors, formValues, receivers) => {
    const confirm = window.confirm(
      '[메시지 발송 확인]\n정말 메시지를 발송하시겠습니까?'
    );
    if (!confirm) return;
    try {
      setLoadingInfo({
        loading: true,
        text: '메시지를 발송중입니다...',
      });
      // form 유효성검사
      const validInfo = validateSubmit(formErrors, receivers.length);
      if (!validInfo.isValid) {
        return window.alert(`[메시지 발송 불가]\n${validInfo.errorMessage}`);
      }
      // 예약시간 유효성검사
      const isValidReserve = checkValidReserve(formValues);
      if (!isValidReserve) {
        return window.alert(
          '[메시지 발송 불가]\n예약시간 간격을 15분 이상 두고 지정해주세요.'
        );
      }
      // postData 생성 (100개씩 나눈 배열)
      const formData = createFormData(formValues);
      const messages = createMessages(receivers);
      const postDataArray = createPostDataArray(formData, messages);

      // 100건씩 나눠진 postData 처리
      for (const postData of postDataArray) {
        console.log('SMS Send PostData: ', postData);

        // TODO: 메시지 발송 전 DB sms_history에 INSERT.
        const { messages, ...messageInfo } = postData;
        const historyData = messages.map((message) => ({
          ...message,
          ...messageInfo,
        }));
        console.log('SMS History Data: ', historyData);
        const { idList: historyIdList } = await saveSmsHistoryApi({
          messages: historyData,
        });
        console.log('SMS History historyIdList: ', historyIdList);

        // 메시지 발송
        const sendReponse = await sendSmsApi(postData);
        console.log('SMS Send Response: ', sendReponse);

        // TODO: 메시지 발송 후 DB sms_feedback에 INSERT.
        await saveSmsFeedbackApi(sendReponse);

        // TODO: 메시지 발송 후 DB sms_history에 requestId, requestTime, statusName UPDATE.
        await updateRequestedSmsHistoryApi({ ...sendReponse, historyIdList });
      }
      window.alert('[메시지 발송 확인]\n메시지를 발송했습니다.');
    } catch (error) {
      window.alert(
        `[메시지 발송 오류]\n메시지 발송 처리중 오류가 발생했습니다.\n${error}`
      );
      console.error('SMS Send Error: ', error);
    } finally {
      setLoadingInfo({
        loading: false,
        text: null,
      });
    }
  };

  return { handleSmsSubmit, loadingInfo };
};

export default useSmsSubmit;
