import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Empty, Form, Input, List, Space, Tag } from 'antd';
import styled from 'styled-components';
import { DeleteFilled, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import uuid from 'react-uuid';

const RespForm = (props) => {
  const { respDatas, setRespDatas, pageType } = props;
  const [respInputs, setRespInputs] = useState({
    key: '',
    name: '',
    phone: '',
    email: '',
    isRepresentative: false,
  });
  const [hasRepData, setHasRepData] = useState(false);

  const checkRespInputs = () => {
    const phoneRegex = /^\d{3}-\d{3,4}-\d{4}$/;
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const nameCheck = respInputs.name.length > 0;
    const phoneCheck = phoneRegex.test(respInputs.phone);
    const emailCheck = emailRegex.test(respInputs.email);
    if (!nameCheck) {
      alert('담당자명을 입력해주세요.');
      return nameCheck;
    }
    if (!phoneCheck) {
      alert('담당자 전화번호를 다시 입력해주세요.');
      return phoneCheck;
    }
    if (respInputs.email.length > 0 && !emailCheck) {
      alert('담당자 이메일을 다시 입력해주세요.');
      return emailCheck;
    }
    return true;
  };

  const addRespData = () => {
    const checkResult = checkRespInputs();
    if (!checkResult) return;
    const newKey = uuid();
    // respDatas의 길이가 0이면 newRespData의 isRepresentative 값을 true로 줌.
    const respDatasLen = respDatas.length;
    let newRespData = {};
    if (respDatasLen === 0) {
      newRespData = { ...respInputs, key: newKey, isRepresentative: true };
    } else {
      newRespData = { ...respInputs, key: newKey };
    }
    setRespDatas((prev) => [...prev, newRespData]);
    // input값 초기화
    setRespInputs({
      key: '',
      name: '',
      phone: '',
      email: '',
      isRepresentative: false,
    });
  };

  const deleteRespData = (key) => {
    setRespDatas((prev) => prev.filter((data) => data.key !== key));
  };

  const checkRespData = (key, checked) => {
    setRespDatas((prev) =>
      prev.map((data) =>
        data.key === key ? { ...data, isRepresentative: checked } : { ...data }
      )
    );
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <List
        style={{
          border: '1px solid #efefef',
          boxShadow: '5px 5px 5px #f7f7f7',
        }}
        bordered
        locale={{
          emptyText: <Empty description="" />,
        }}
        dataSource={respDatas}
        itemLayout="horizontal"
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                size="small"
                onClick={() => deleteRespData(item.key)}
                disabled={pageType === 'detail'}
                style={{ borderRadius: '10px' }}
              >
                <DeleteOutlined />
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Checkbox
                  checked={item.isRepresentative}
                  onChange={(e) => checkRespData(item.key, e.target.checked)}
                  disabled={pageType === 'detail'}
                />
              }
              title={
                <div>
                  <span>{item.name}</span>
                  <span style={{ fontWeight: '500', marginLeft: '10px' }}>
                    ({item.email.length > 0 ? item.email : '-'})
                  </span>
                </div>
              }
              description={item.phone}
            />
          </List.Item>
        )}
      />
      <div
        style={{
          display: 'flex',
          backgroundColor: '#f9f9f9',
          padding: '15px',
          borderRadius: '10px',
          justifyContent: 'space-between',
          marginTop: '15px',
        }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '10px' }}>
            <label>담당자명</label>
            <Input
              disabled={pageType === 'detail'}
              style={{ borderRadius: '0px' }}
              placeholder="담당자명을 입력해주세요."
              value={respInputs.name}
              onChange={(e) =>
                setRespInputs((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </div>
          <div style={{ marginRight: '10px' }}>
            <label>전화번호</label>
            <Input
              disabled={pageType === 'detail'}
              style={{ borderRadius: '0px' }}
              type="tel"
              placeholder="ex) 010-0000-0000"
              value={respInputs.phone}
              onChange={(e) =>
                setRespInputs((prev) => ({ ...prev, phone: e.target.value }))
              }
            />
          </div>
          <div>
            <label>이메일</label>
            <Input
              disabled={pageType === 'detail'}
              style={{ borderRadius: '0px' }}
              type="email"
              placeholder="ex) email@naver.com"
              value={respInputs.email}
              onChange={(e) =>
                setRespInputs((prev) => ({ ...prev, email: e.target.value }))
              }
            />
          </div>
        </div>
        <Button
          type="primary"
          style={{ marginTop: '13px', width: '180px' }}
          onClick={addRespData}
          disabled={pageType === 'detail'}
        >
          <SaveOutlined />
          담당자 저장
        </Button>
      </div>
    </div>
  );
};

export default RespForm;

RespForm.propTypes = {
  respDatas: PropTypes.instanceOf(Array).isRequired,
  setRespDatas: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
};

const ThStyle = styled.th`
  border-top: 2px solid #efefef;
  border-bottom: 2px solid #efefef;
  padding: 15px;
`;
const TdStyle = styled.td`
  border-bottom: 1px solid #efefef;
  text-align: center;
  padding: 10px;
`;
