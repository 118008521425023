import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import ProductFormFinish from './ProductFormFinish';

const ProductFormData = (props) => {
  const { pageType, formItems } = props;
  const dispatch = useDispatch();
  const { id } = useParams();

  const [formData, setFormData] = useState({});
  // product 업데이트 -> sale_product 업데이트를 위함
  const [originalProductCode, setOriginalProductCode] = useState('');

  useEffect(() => {
    if (pageType === 'update' || pageType === 'detail') {
      const values = {
        table: 'product',
        id,
      };
      dispatch(asyncReadSpecificDataFetch(values))
        .unwrap()
        .then((res) => {
          setFormData({ ...res[0] });
          setOriginalProductCode(res[0].code);
        });
    }
  }, [dispatch, id, pageType]);

  return (
    <ProductFormFinish
      pageType={pageType}
      formItems={formItems}
      formData={formData}
      originalProductCode={originalProductCode}
    />
  );
};

export default ProductFormData;

ProductFormData.propTypes = {
  pageType: PropTypes.string.isRequired,
  formItems: PropTypes.instanceOf(Array).isRequired,
};
