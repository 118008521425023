import { useEffect, useState } from 'react';

const initialValues = {
  type: 'LMS',
  contentType: 'COMM',
  nationalNo: '82',
  sender: '01092773322',
  title: null,
  content: null,
  isReserve: false,
  sendDate: null,
  sendTime: null,
};

const useSmsForm = () => {
  const [values, setValues] = useState(initialValues);
  const [blurs, setBlurs] = useState({});
  const [errors, setErrors] = useState({});

  const handleChangeValue = (name, newValue) => {
    // 컨텐츠 타입을 선택할 경우 예약발송 여부, 날짜, 시간 값들을 초기화시킴.
    if (name === 'contentType') {
      setValues((prev) => ({
        ...prev,
        contentType: newValue,
        isReserve: false,
        sendDate: null,
        sendTime: null,
      }));
      return;
    }

    // 예약 여부를 바꾸면 예약발송 날짜, 시간 값들을 초기화시킴.
    if (name === 'isReserve') {
      setValues((prev) => ({
        ...prev,
        isReserve: newValue,
        sendDate: null,
        sendTime: null,
      }));
    }

    setValues((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const handleChangeBlur = (name) => {
    setBlurs((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const validateError = () => {
    const newErrors = { ...errors };

    newErrors.title = !values.title || values.title.length <= 3;

    newErrors.content =
      !values.content ||
      values.content.length <= 9 ||
      // SMS 타입일 때 내용이 40자 이상,
      // 그외 타입일 때 내용이 1000자 이상일 경우 에러.
      // 삼항연산자가 바로 Length값을 참조하는 것 방어 ().
      (values.type === 'SMS'
        ? values.content.length > 40
        : values.content.length > 1000);

    newErrors.sender =
      !values.sender ||
      values.sender.length !== 11 ||
      !/^\d+$/.test(values.sender);

    // 발송구분이 "예약"일 때 날짜, 시간 값이 없을 경우 에러.
    newErrors.sendDate = values.isReserve && !values.sendDate;
    newErrors.sendTime = values.isReserve && !values.sendTime;

    setErrors(newErrors);
  };

  useEffect(() => {
    validateError();
  }, [values, blurs]);

  return {
    values,
    blurs,
    errors,
    handleChangeValue,
    handleChangeBlur,
  };
};

export default useSmsForm;
