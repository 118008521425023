import React, { useState } from 'react';
import { Button, Input, Modal, Popover } from 'antd';
import PropTypes from 'prop-types';
import { FileExcelOutlined, MailOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import DirectionsTable from './DirectionsTable';
import downloadExcelFile from '../../utils/downloadExcelFile';
import { mailAddresses, mailInfoTemplates } from '../../constants';

const DividedDirModal = ({
  modalDatas,
  setOpenModal,
  modalTitle,
  modalSubTitle,
}) => {
  const { handleShippingOrderTable, handleShippingOrderMail } =
    downloadExcelFile();

  const [mailInfo, setMailInfo] = useState({
    mailAddress: '',
    subject: mailInfoTemplates.subject,
    text: mailInfoTemplates.text,
  });
  const [submitted, setSubmitted] = useState(false);

  const divideDatas = () => {
    const dividedDatas = [];
    for (const data of modalDatas) {
      if (data.product_quantity > 1) {
        const objToArrByQuantity = Array(data.product_quantity).fill({
          ...data,
          product_quantity: 1,
        });
        dividedDatas.push(...objToArrByQuantity);
      } else {
        dividedDatas.push(data);
      }
    }
    return dividedDatas;
  };

  const createExcelData = async () => {
    try {
      // 고려일 경우 수량1개씩 분할
      const dividedDatas = modalTitle.includes('고려')
        ? divideDatas()
        : modalDatas;
      const excelTitle = `${dayjs().format('YYYYMMDD')}_${modalTitle}`;
      const excelColumns = [
        '주문번호',
        '상품명(옵션포함)',
        '주문상품수량',
        '주문자이름',
        '주문자번호',
        '주문자핸드폰',
        '수취인이름',
        '수취인전화',
        '수취인핸드폰',
        '신)수취인우편번호',
        '수취인주소',
        '송장번호',
        '배송메세지',
      ];
      const excelRows = dividedDatas.map((data) => ({
        order_number: data.order_number,
        product_name: data.product_name,
        product_quantity: data.product_quantity,
        orderer_name: '',
        orderer_phone: data.naver_product_order_id,
        orderer_mobile: '',
        account_name: data.account_name,
        phone: data.phone,
        mobile: data.mobile,
        post_code: data.post_code,
        address: data.address,
        invoice_number: '',
        invoice_message: data.memo,
        marked: data.marked,
      }));
      return { excelTitle, excelColumns, excelRows };
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const handleClickFile = async () => {
    const { excelTitle, excelColumns, excelRows } = await createExcelData();
    await handleShippingOrderTable(excelTitle, excelColumns, excelRows);
  };

  const handleClickMail = async (e) => {
    e.preventDefault();
    try {
      // Excel 데이터 생성
      const { excelTitle, excelColumns, excelRows } = await createExcelData();
      // Excel 파일 생성 -> 메일 발송
      await handleShippingOrderMail(
        excelTitle,
        excelColumns,
        excelRows,
        mailInfo
      );
    } catch (err) {
      console.log(err);
      alert(err);
    } finally {
      setSubmitted(true);
    }
  };

  const handleChangeMailInfo = (name, value) => {
    setMailInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const mailPopup = (button) => {
    return (
      <Popover
        style={{ margin: '15px' }}
        content={
          <form onSubmit={handleClickMail}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '500px',
                gap: '10px',
              }}
            >
              <div>
                <Button
                  size="small"
                  type="primary"
                  onClick={() =>
                    handleChangeMailInfo('mailAddress', mailAddresses.goryeo)
                  }
                >
                  고려
                </Button>
                <Button
                  size="small"
                  type="primary"
                  style={{ marginLeft: '2px' }}
                  onClick={() =>
                    handleChangeMailInfo('mailAddress', mailAddresses.joontech)
                  }
                >
                  준테크
                </Button>
                <Input
                  placeholder="수신 주소를 입력하세요."
                  value={mailInfo.mailAddress}
                  onChange={(e) =>
                    handleChangeMailInfo('mailAddress', e.target.value)
                  }
                  style={{ marginTop: '5px' }}
                />
              </div>
              <Input
                placeholder="제목을 입력하세요."
                value={mailInfo.subject}
                onChange={(e) =>
                  handleChangeMailInfo('subject', e.target.value)
                }
              />
              <Input.TextArea
                rows={8}
                placeholder="내용을 입력하세요."
                value={mailInfo.text}
                onChange={(e) => handleChangeMailInfo('text', e.target.value)}
              />
            </div>
            <button
              style={{ width: '100%', marginTop: '10px', padding: '5px' }}
              type="submit"
              disabled={submitted}
            >
              제출
            </button>
          </form>
        }
        trigger="click"
      >
        {button}
      </Popover>
    );
  };

  return (
    <Modal
      open
      centered
      width="80%"
      onCancel={() => setOpenModal(false)}
      title={modalTitle}
      footer={[
        <Button onClick={handleClickFile} style={{ marginTop: '5px' }}>
          <FileExcelOutlined />
          출하지시서
        </Button>,
        mailPopup(
          <Button style={{ marginTop: '5px' }} onClick={() => handleClickMail}>
            <MailOutlined />
            메일 발송
          </Button>
        ),
      ]}
    >
      <p style={{ margin: '10px 0' }}>날짜기준: {modalSubTitle}</p>
      <div style={{ border: '1px solid lightgray' }}>
        <DirectionsTable dirDatas={modalDatas} />
      </div>
    </Modal>
  );
};

export default DividedDirModal;

DividedDirModal.propTypes = {
  modalDatas: PropTypes.instanceOf(Array).isRequired,
  setOpenModal: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired || PropTypes.null.isRequired,
  modalSubTitle: PropTypes.string.isRequired || PropTypes.null.isRequired,
};
