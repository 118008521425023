import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import DiscountApplicationModal from './DiscountApplicationModal';

const DiscountProductValue = (props) => {
  const { accountId, accountCode, accountName } = props;
  const dispatch = useDispatch();

  const [discountInfo, setDiscountInfo] = useState({
    repsProduct: '',
    quantity: 0,
    isDiscounted: false,
  });
  const [discountInfoLoading, setDiscountInfoLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    const getDiscountProduct = async () => {
      try {
        setDiscountInfoLoading(true);
        const discountProductDatas = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'discount_account_product',
            eqKey: 'account_id',
            eqValue: accountId,
          })
        )
          .unwrap()
          .then((res) => res);
        if (discountProductDatas.length <= 0) {
          setDiscountInfoLoading(false);
          return;
        } else {
          const productDatas = await Promise.all(
            discountProductDatas.map(async (data) => {
              const response = await dispatch(
                asyncReadFilteredDataFetch({
                  table: 'product',
                  eqKey: 'id',
                  eqValue: data.product_id,
                })
              )
                .unwrap()
                .then((res) => ({ ...res[0] }));
              return response;
            })
          );
          await setDiscountInfo(() => ({
            repsProduct: productDatas[0].name,
            quantity: productDatas.length - 1,
            isDiscounted: true,
          }));
          setDiscountInfoLoading(false);
        }
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };
    getDiscountProduct();
  }, [accountId, dispatch]);

  return (
    <div>
      {discountInfo.isDiscounted ? (
        <Button type="link" onClick={() => setIsOpenModal(true)}>
          <span>{discountInfo.repsProduct}</span>
          {discountInfo.quantity > 0 && (
            <>
              <span style={{ color: 'black' }}> 외 </span>
              <span>{discountInfo.quantity}</span>
              <span style={{ color: 'black' }}>건</span>
            </>
          )}
        </Button>
      ) : (
        <Button type="link" onClick={() => setIsOpenModal(true)}>
          없음
        </Button>
      )}
      {isOpenModal && (
        <DiscountApplicationModal
          setIsOpenModal={setIsOpenModal}
          accountId={accountId}
          accountCode={accountCode}
          accountName={accountName}
        />
      )}
    </div>
  );
};

export default DiscountProductValue;

DiscountProductValue.propTypes = {
  accountId: PropTypes.string.isRequired,
  accountCode: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired,
};
