import React from 'react';
import { ConfigProvider, DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

// datepicker 한글화
import 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ko';
import locale from 'antd/lib/locale/ko_KR';

const RangePicker = ({ values, onChange }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        width: '100%',
      }}
    >
      <ConfigProvider locale={locale}>
        <DatePicker
          placeholder="연-월-일"
          value={dayjs(values.startDate, 'YYYY-MM-DD')}
          onChange={(_, date) => onChange('startDate', date)}
          style={{ flex: '1' }}
        />
        <TimePicker
          format="HH:mm:ss"
          placeholder="시:분:초"
          value={dayjs(values.startTime, 'HH:mm')}
          onChange={(_, time) => onChange('startTime', time)}
          style={{ flex: '1' }}
        />
        <span style={{ fontSize: '16px' }}>~</span>
        <DatePicker
          placeholder="연-월-일"
          value={dayjs(values.endDate, 'YYYY-MM-DD')}
          onChange={(_, date) => onChange('endDate', date)}
          style={{ flex: '1' }}
        />
        <TimePicker
          format="HH:mm:ss"
          placeholder="시:분:초"
          value={dayjs(values.endTime, 'HH:mm')}
          onChange={(_, time) => onChange('endTime', time)}
          style={{ flex: '1' }}
        />
      </ConfigProvider>
    </div>
  );
};

export default RangePicker;

RangePicker.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
};
