import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { smsTemplates } from '../../constants';

const buttonItems = [
  {
    label: '구정연휴',
    value: smsTemplates.koreanNewYear,
  },
  {
    label: '추석연휴',
    value: smsTemplates.chuseok,
  },
  {
    label: '택배휴무',
    value: smsTemplates.deliveryOff,
  },
  {
    label: '근로자의날',
    value: smsTemplates.laborDay,
  },
  {
    label: '석가탄신일',
    value: smsTemplates.vesak,
  },
  {
    label: '대체휴무',
    value: smsTemplates.substituteHoliday,
  },
];

const TemplateButtons = ({ onChange }) => {
  return (
    <div style={{ display: 'flex', gap: '5px' }}>
      {buttonItems.map((item, index) => (
        <Button
          key={index}
          onClick={() => {
            onChange('title', item.value.title);
            onChange('content', item.value.content);
          }}
        >
          {item.label}
        </Button>
      ))}
    </div>
  );
};

export default TemplateButtons;

TemplateButtons.propTypes = {
  onChange: PropTypes.func.isRequired,
};
