import {
  CloseOutlined,
  ExclamationCircleFilled,
  SaveOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { Button, Input, Popconfirm, Select, Tag, Timeline } from 'antd';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

// 분할기능 테이블데이터 관련
export const DivisionQuantity = (props) => {
  const {
    value,
    quantity1,
    quantity2,
    inputQtyDiv,
    isQtyCorrect,
    setIsQtyCorrect,
    data,
  } = props;

  useEffect(() => {
    const sum = quantity1 + quantity2;
    if (sum === value) {
      setIsQtyCorrect(true);
    }
    if (sum !== value) {
      setIsQtyCorrect(false);
    }
  }, [quantity1, quantity2, value, setIsQtyCorrect]);

  return (
    <TimelineContainer>
      <TimelineItem color="red">
        <Input
          suffix={
            isQtyCorrect ? (
              false
            ) : (
              <ExclamationCircleFilled style={{ color: 'red' }} />
            )
          }
          size="small"
          type="number"
          style={{
            borderRadius: '0px',
          }}
          onChange={(e) => inputQtyDiv(Number(e.target.value), 1, data)}
          value={quantity1}
        />
      </TimelineItem>
      <TimelineItem color="red">
        <Input
          suffix={
            isQtyCorrect ? (
              false
            ) : (
              <ExclamationCircleFilled style={{ color: 'red' }} />
            )
          }
          type="number"
          size="small"
          style={{
            borderRadius: '0px',
          }}
          onChange={(e) => inputQtyDiv(Number(e.target.value), 2, data)}
          value={quantity2}
        />
      </TimelineItem>
    </TimelineContainer>
  );
};
export const DivisionSupply = (props) => {
  const { supply1, supply2 } = props;
  return (
    <TimelineContainer>
      <TimelineItem color="red">
        <TimelineItemStr>{supply1.toLocaleString()}원</TimelineItemStr>
      </TimelineItem>
      <TimelineItem color="red">
        <TimelineItemStr>{supply2.toLocaleString()}원 </TimelineItemStr>
      </TimelineItem>
    </TimelineContainer>
  );
};
export const DivisionVat = (props) => {
  const { vat1, vat2 } = props;
  return (
    <TimelineContainer>
      <TimelineItem color="red">
        <TimelineItemStr>{vat1.toLocaleString()}원</TimelineItemStr>
      </TimelineItem>
      <TimelineItem color="red">
        <TimelineItemStr>{vat2.toLocaleString()}원</TimelineItemStr>
      </TimelineItem>
    </TimelineContainer>
  );
};
export const DivisionTotal = (props) => {
  const { total1, total2 } = props;
  return (
    <TimelineContainer>
      <TimelineItem color="red">
        <TimelineItemStr>{total1.toLocaleString()}원</TimelineItemStr>
      </TimelineItem>
      <TimelineItem color="red">
        <TimelineItemStr>{total2.toLocaleString()}원</TimelineItemStr>
      </TimelineItem>
    </TimelineContainer>
  );
};
export const DivisionWarehouse = (props) => {
  const {
    options,
    data,
    selectWhDiv,
    warehouse1,
    warehouse2,
    isWhEqual,
    setIsWhEqual,
  } = props;
  const dispatch = useDispatch();
  const [originWarehouse, setOriginWarehouse] = useState('');
  // 기존 창고명 출력
  useEffect(() => {
    dispatch(
      asyncReadFilteredDataFetch({
        table: 'warehouse',
        eqKey: 'code',
        eqValue: data.product_warehouse,
      })
    )
      .unwrap()
      .then((res) => {
        const { name } = res[0];
        setOriginWarehouse(name);
      })
      .catch();
  }, [dispatch, data.product_warehouse]);
  // 두개의 창고가 같은지 체크
  useEffect(() => {
    if (warehouse1 === warehouse2) {
      setIsWhEqual(true);
    } else {
      setIsWhEqual(false);
    }
  }, [warehouse1, warehouse2, setIsWhEqual]);

  return (
    <TimelineContainer>
      <div style={{ textDecoration: 'line-through', color: 'gray' }}>
        {originWarehouse}
      </div>
      <TimelineItem color="red">
        <div className="select-container">
          <Select
            size="small"
            options={options}
            style={{ width: '100%' }}
            onChange={(value) => selectWhDiv(value, 1)}
            suffixIcon={
              isWhEqual ? (
                <ExclamationCircleFilled style={{ color: 'red' }} />
              ) : (
                false
              )
            }
            placeholder="창고를 선택해주세요."
          />
        </div>
      </TimelineItem>
      <TimelineItem color="red">
        <div className="select-container">
          <Select
            size="small"
            options={options}
            style={{ width: '100%' }}
            onChange={(value) => selectWhDiv(value, 2)}
            suffixIcon={
              isWhEqual ? (
                <ExclamationCircleFilled style={{ color: 'red' }} />
              ) : (
                false
              )
            }
            placeholder="창고를 선택해주세요."
          />
        </div>
      </TimelineItem>
    </TimelineContainer>
  );
};

// 분할기능 버튼 관련
export const DivisionOpenButton = (props) => {
  const { setDivision, value, data, pageType } = props;
  const handleDivision = () => {
    if (pageType === 'detail') {
      alert('분할할 수 없습니다. 수정버튼을 클릭해주세요.');
      return;
    }
    if (data.product_quantity <= 1) {
      alert('2개 이상의 출고상품만 분할할 수 있습니다.');
      return;
    }
    setDivision({
      isDivision: true,
      divisionCode: value,
      productData: data,
    });
  };
  return (
    <Button
      size="small"
      onClick={handleDivision}
      style={{ width: '100%', marginBottom: '5px' }}
      type="primary"
      disabled={pageType === 'detail' || pageType === 'update'}
    >
      <ShareAltOutlined />
      품목분할
    </Button>
  );
};

export const DivisionSaveButton = (props) => {
  const { setDivision, value, divideProduct, setDivisionData } = props;
  const cancelDivision = () => {
    setDivision({
      isDivision: false,
      divisionCode: '',
      productData: {},
    });
    setDivisionData({
      division1: {
        discount: 0,
        discounted: 0,
        dcPrice: 0,
        dcSupply: 0,
        dcVat: 0,
        quantity: 0,
        supply: 0,
        vat: 0,
        total: 0,
        warehouse: '',
      },
      division2: {
        discount: 0,
        discounted: 0,
        dcPrice: 0,
        dcSupply: 0,
        dcVat: 0,
        quantity: 0,
        supply: 0,
        vat: 0,
        total: 0,
        warehouse: '',
      },
    });
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Popconfirm
        placement="bottom"
        title="분할한 데이터를 저장하시겠습니까?"
        okText={
          <div>
            <SaveOutlined style={{ marginRight: '5px' }} />
            저장
          </div>
        }
        cancelText={
          <div>
            <CloseOutlined style={{ marginRight: '5px' }} />
            취소
          </div>
        }
        onConfirm={divideProduct}
      >
        <Button size="small">
          <div>{value}</div>
        </Button>
      </Popconfirm>
      <Button
        type="primary"
        danger
        size="small"
        style={{ marginTop: '10px', fontSize: '12px', borderRadius: '20px' }}
        onClick={cancelDivision}
      >
        분할취소
      </Button>
    </div>
  );
};

// prop-types
DivisionOpenButton.propTypes = {
  setDivision: PropTypes.func,
  value: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  pageType: PropTypes.string,
};
DivisionSaveButton.propTypes = {
  setDivision: PropTypes.func,
  value: PropTypes.string,
  divideProduct: PropTypes.func,
  setDivisionData: PropTypes.func,
};
DivisionQuantity.propTypes = {
  value: PropTypes.number,
  quantity1: PropTypes.number,
  quantity2: PropTypes.number,
  inputQtyDiv: PropTypes.func,
  isQtyCorrect: PropTypes.bool,
  setIsQtyCorrect: PropTypes.func,
  data: PropTypes.instanceOf(Object),
};
DivisionSupply.propTypes = {
  supply1: PropTypes.number,
  supply2: PropTypes.number,
};
DivisionVat.propTypes = {
  vat1: PropTypes.number,
  vat2: PropTypes.number,
};
DivisionTotal.propTypes = {
  total1: PropTypes.number,
  total2: PropTypes.number,
};
DivisionWarehouse.propTypes = {
  options: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  selectWhDiv: PropTypes.func,
  warehouse1: PropTypes.string,
  warehouse2: PropTypes.string,
  isWhEqual: PropTypes.bool,
  setIsWhEqual: PropTypes.func,
};

// styled-component
const Icon = styled(Tag)`
  border-radius: 50px;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;
const TimelineContainer = styled(Timeline)`
  position: relative;
`;
const TimelineItem = styled(Timeline.Item)`
  position: absolute;
  top: 37px;
`;
const TimelineItemStr = styled.div`
  border: 1px solid lightgray;
`;
