import React, { createContext, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

export const OrderProductsContext = createContext({});

export const OrderProductsProvider = (props) => {
  const { children } = props;

  const history = useHistory();

  const [orderProducts, setOrderProducts] = useState([]);
  const [orderSales, setOrderSales] = useState([]);
  const [loading, setLoading] = useState(false);

  const wrappedState = useMemo(
    () => ({
      orderProducts,
      setOrderProducts,
      orderSales,
      setOrderSales,
      loading,
      setLoading,
    }),
    [orderProducts, orderSales, loading]
  );

  const resetData = () => {
    setOrderProducts([]);
    setOrderSales([]);
    setLoading(false);
  };

  useEffect(() => {
    const unlisten = history.listen((location) => {
      // URL이 변경될 때마다 데이터를 초기화
      if (location.pathname !== '/order/createOrder') {
        resetData();
      }
    });
    return () => {
      // 컴포넌트가 언마운트될 때 리스너 해제
      unlisten();
    };
  }, [history]);

  return (
    <OrderProductsContext.Provider value={wrappedState}>
      {children}
    </OrderProductsContext.Provider>
  );
};

OrderProductsProvider.propTypes = {
  children: PropTypes.node,
};
