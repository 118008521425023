import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Tabs, Tag } from 'antd';
import styled from 'styled-components';
import EstimateButtons from './EstimateButtons';
import SelectOrderForm from './SelectOrderForm';

const calculateSums = (productDatas) => {
  const sums = {
    quantity: 0,
    supply: 0,
    vat: 0,
    discount: 0,
    total: 0,
  };
  productDatas.forEach((data) => {
    sums.quantity += data.product_quantity || 0;
    sums.supply += data.product_supply_price || 0;
    sums.vat += data.product_VAT_price || 0;
    sums.discount += data.discount || 0;
    sums.total += data.discounted || 0;
  });
  return sums;
};

const EstimateProductInfo = (props) => {
  const { productInfoDatas, productInfoLoading, saleInfoDatas, basicInfoData } =
    props;

  const [isEditSaleAndPdInfo, setIsEditSaleAndPdInfo] = useState(false);
  const [sums, setSums] = useState({
    quantity: 0,
    supply: 0,
    vat: 0,
    discount: 0,
    total: 0,
  });

  const tableColumn = [
    {
      title: '품목코드',
      dataIndex: 'product_code',
      key: 'product_code',
      width: '13%',
      align: 'center',
    },
    {
      title: '품목명',
      dataIndex: 'product_name',
      key: 'product_name',
      width: '13%',
      align: 'center',
    },
    {
      title: '수량',
      dataIndex: 'product_quantity',
      key: 'product_quantity',
      width: '10%',
      align: 'center',
    },
    {
      title: '단가',
      dataIndex: 'product_price',
      key: 'price',
      width: '13%',
      align: 'center',
      render: (value) => <div>{value.toLocaleString()}</div>,
    },
    {
      title: '공급가액',
      dataIndex: 'product_supply_price',
      key: 'supply',
      width: '13%',
      align: 'center',
      render: (value) => <div>{value.toLocaleString()}</div>,
    },
    {
      title: '부가세',
      dataIndex: 'product_VAT_price',
      key: 'vat',
      width: '13%',
      align: 'center',
      render: (value) => <div>{value.toLocaleString()}</div>,
    },
    {
      title: '할인',
      key: 'dc',
      width: '13%',
      align: 'center',
      render: (data) => {
        if (data.discount_rate === 0) {
          return <div>-0(0%)</div>;
        } else {
          return (
            <div>
              -{data.discount.toLocaleString()}({data.discount_rate}%)
            </div>
          );
        }
      },
    },
    {
      title: '합계',
      key: 'total',
      width: '13%',
      align: 'center',
      render: (data) => {
        if (data.discount_rate === 0) {
          return (
            <div>
              {(
                data.product_supply_price + data.product_VAT_price
              ).toLocaleString()}
            </div>
          );
        } else {
          return <div>{data.discounted.toLocaleString()}</div>;
        }
      },
    },
  ];

  useEffect(() => {
    const result = calculateSums(productInfoDatas);
    setSums({ ...result });
  }, [productInfoDatas]);

  return (
    <div className="tabs-container">
      {/*
      <Tabs
        type="card"
        items={[
          { key: 'show', label: '견적보기' },
          { key: 'edit', label: '견적수정' },
        ]}
        onChange={(e) => {
          if (e === 'edit') setIsEditSaleAndPdInfo(true);
          if (e === 'show') setIsEditSaleAndPdInfo(false);
        }}
      />
      */}
      <div
        style={{
          paddingTop: '20px',
          // padding: '10px',
          // borderLeft: '1px solid #efefef',
          // borderRight: '1px solid #efefef',
          // borderBottom: '1px solid #efefef',
          // backgroundColor: "#f9f9f9",
        }}
      >
        {isEditSaleAndPdInfo ? (
          <SelectOrderForm />
        ) : (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '5px',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  padding: '5px 10px',
                  backgroundColor: '#f7f7f7',
                  borderRadius: '10px',
                }}
              >
                판매상품:
                {saleInfoDatas.map((data) => (
                  <span style={{ marginLeft: '5px', color: 'gray' }}>
                    {data.sale_name}
                  </span>
                ))}
              </div>
              <EstimateButtons
                basicInfoData={basicInfoData}
                productInfoDatas={productInfoDatas}
              />
            </div>
            <Table
              columns={tableColumn}
              dataSource={productInfoDatas}
              size="small"
              pagination={false}
              loading={productInfoLoading}
              bordered
              summary={() => (
                <Table.Summary.Row
                  style={{ textAlign: 'center', backgroundColor: '#f9f9f9' }}
                >
                  <Table.Summary.Cell />
                  <Table.Summary.Cell />
                  <Table.Summary.Cell>
                    {sums.quantity.toLocaleString()}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell />
                  <Table.Summary.Cell>
                    {sums.supply.toLocaleString()}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {sums.vat.toLocaleString()}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    -{sums.discount.toLocaleString()}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {sums.total.toLocaleString()}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EstimateProductInfo;

EstimateProductInfo.propTypes = {
  productInfoDatas: PropTypes.instanceOf(Array).isRequired,
  saleInfoDatas: PropTypes.instanceOf(Array).isRequired,
  productInfoLoading: PropTypes.bool.isRequired,
  basicInfoData: PropTypes.instanceOf(Object).isRequired,
};
