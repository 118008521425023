import React, { useEffect, useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';

const AccountColumnValue = (props) => {
  const { accountId } = props;
  const dispatch = useDispatch();

  const [accountName, setAccountName] = useState('');

  useEffect(() => {
    const getAccountName = async () => {
      try {
        const resOfAcctName = await dispatch(
          asyncReadSpecificDataFetch({
            table: 'account',
            id: accountId,
          })
        )
          .unwrap()
          .then((res) => res[0].name);
        setAccountName(resOfAcctName);
      } catch (error) {
        throw new Error();
      }
    };
    getAccountName();
  }, [dispatch, accountId]);

  return <div>{accountName}</div>;
};

export default memo(AccountColumnValue);

AccountColumnValue.propTypes = {
  accountId: PropTypes.string.isRequired,
};
