import React from 'react';
import PropTypes from 'prop-types';
import SaleFormDatas from './SaleFormDatas';

const SaleFormItems = (props) => {
  const { pageType } = props;

  // 판매상품 items
  const saleItems = [
    {
      type: 'input',
      name: 'code',
      label: '판매상품코드',
      message: '판매상품코드를 입력해주세요.',
      placeholder: '',
      inputType: '',
      inputPattern: '',
      require: true,
      isComma: false,
    },
    {
      type: 'input',
      name: 'name',
      label: '판매상품명',
      message: '판매상품명을 입력해주세요.',
      placeholder: '',
      inputType: '',
      inputPattern: '',
      require: true,
      isComma: false,
    },
    {
      type: 'input',
      name: 'sort_order',
      label: '옵션',
      message: '계산기정렬순서를 입력해주세요.',
      placeholder: '',
      inputType: '',
      inputPattern: '',
      require: false,
      isComma: false,
    },
  ];

  // 판매-출고상품 items
  const saleProductItems = [
    {
      type: 'input',
      name: 'sale_code',
      label: '판매상품코드',
      message: '판매상품코드를 입력해주세요.',
      placeholder: '',
      inputType: '',
      inputPattern: '',
      require: true,
      isComma: false,
    },
    {
      type: 'input',
      name: 'product_code',
      label: '출고상품코드',
      message: '출고상품코드를 입력해주세요.',
      placeholder: '존재하는 출고상품코드를 입력해주세요.',
      inputType: '',
      inputPattern: '',
      require: true,
      isComma: false,
    },
    {
      type: 'input',
      name: 'product_quantity',
      label: '출고상품개수',
      message: '출고상품개수를 입력해주세요.',
      placeholder: '',
      inputType: 'number',
      inputPattern: '',
      require: true,
      isComma: false,
    },
  ];

  return (
    <SaleFormDatas
      pageType={pageType}
      saleItems={saleItems}
      saleProductItems={saleProductItems}
    />
  );
};

export default SaleFormItems;

SaleFormItems.propTypes = {
  pageType: PropTypes.string.isRequired,
};
