import axiosInstance from '../axios';

const signinApi = async (postData) => {
  const response = await axiosInstance.post('/api/auth/signin', postData);
  return response.data;
};

const signupApi = async (postData) => {
  const response = await axiosInstance.post('/api/auth/signup', postData);
  return response.data;
};

const refreshApi = async () => {
  const response = await axiosInstance.post('/api/auth/refresh');
  return response.data;
};

const passwordResetApi = async (postData) => {
  const response = await axiosInstance.post(
    '/api/auth/password-reset',
    postData
  );
  return response.data;
};

const myPasswordResetApi = async (postData) => {
  const response = await axiosInstance.post(
    '/api/auth/my-password-reset',
    postData
  );
  return response.data;
};

const editProfileApi = async (postData) => {
  const response = await axiosInstance.post('/api/auth/edit-profile', postData);
  return response.data;
};

const editUserAccessApi = async (postData) => {
  const response = await axiosInstance.post(
    '/api/auth/edit-user-access',
    postData
  );
  return response.data;
};

const getUserInfoByIdApi = async (postData) => {
  const { id } = postData;
  const response = await axiosInstance.get(`/api/auth/user-info/${id}`);
  return response.data;
};

const getUserListApi = async () => {
  const response = await axiosInstance.get(`/api/auth/user-list`);
  return response.data;
};

const deleteUserByIdApi = async (postData) => {
  const response = await axiosInstance.post('/api/auth/delete-user', postData);
  return response.data;
};

const updateHeadcount = async () => {
  const response = await axiosInstance.post('/api/auth/update-headcount');
  return response.data;
};

export {
  signinApi,
  signupApi,
  refreshApi,
  passwordResetApi,
  myPasswordResetApi,
  editProfileApi,
  editUserAccessApi,
  getUserInfoByIdApi,
  getUserListApi,
  deleteUserByIdApi,
  updateHeadcount,
};
