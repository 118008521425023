import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import UpdateTable from '../shared/TableModal/UpdateTable';
import CreateTable from '../shared/TableModal/CreateTable';
import DeleteTable from '../shared/TableModal/DeleteTable';

const WarehouseData = (props) => {
  const { items } = props;
  const { modalInfo } = useSelector((state) => state.modal);

  // modal 종류에 따라 다른 component 리턴.
  // 공유 TableModal로 들어감.
  const warehouse = useCallback(() => {
    if (modalInfo.modalTitle === '창고 수정') {
      return <UpdateTable items={items} />;
    } else if (modalInfo.modalTitle === '창고 삭제') {
      return <DeleteTable items={items} />;
    } else if (modalInfo.modalTitle === '창고 추가') {
      return <CreateTable items={items} />;
    }
  }, [items, modalInfo.modalTitle]);

  return warehouse();
};

export default WarehouseData;

WarehouseData.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};
