import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs, Select } from 'antd';

import UpdateTable from '../shared/TableModal/UpdateTable';
import CreateTable from '../shared/TableModal/CreateTable';
import DeleteTable from '../shared/TableModal/DeleteTable';

const ProductData = (props) => {
  const { items } = props;
  const { modalInfo } = useSelector((state) => state.modal);

  const [selectedItems, setSelectedItems] = useState([]);
  const [formCategory, setFormCategory] = useState('품목');

  // tab에 선택된 formCategory에 따라 visibility속성을 true로 변경.
  useEffect(() => {
    if (formCategory === '품목') {
      const newItems = items.map((item) => {
        if (
          item.name === 'code' ||
          item.name === 'name' ||
          item.name === 'category'
        ) {
          return {
            ...item,
            visibility: true,
          };
        }
        return { ...item };
      });
      setSelectedItems([...newItems]);
    } else if (formCategory === '규격') {
      const newItems = items.map((item) => {
        if (
          item.name === 'standard_type' ||
          item.name === 'standard' ||
          item.name === 'unit'
        ) {
          return {
            ...item,
            visibility: true,
          };
        }
        return { ...item };
      });
      setSelectedItems([...newItems]);
    } else if (formCategory === '단가') {
      const newItems = items.map((item) => {
        if (
          item.name === 'incoming_price' ||
          item.name === 'incoming_price_VAT' ||
          item.name === 'release_price' ||
          item.name === 'release_price_VAT'
        ) {
          return {
            ...item,
            visibility: true,
          };
        }
        return { ...item };
      });
      setSelectedItems([...newItems]);
    } else if (formCategory === '기타') {
      const newItems = items.map((item) => {
        if (
          item.name === 'set' ||
          item.name === 'quantity' ||
          item.name === 'purchase'
        ) {
          return {
            ...item,
            visibility: true,
          };
        }
        return { ...item };
      });
      setSelectedItems([...newItems]);
    }
  }, [formCategory, items]);

  // modal 종류에 따라 다른 component 리턴.
  // 공유 TableModal로 들어감.
  const product = useCallback(() => {
    if (modalInfo.modalTitle === '품목 수정') {
      return <UpdateTable items={selectedItems} />;
    } else if (modalInfo.modalTitle === '품목 삭제') {
      return <DeleteTable items={selectedItems} />;
    } else if (modalInfo.modalTitle === '품목 추가') {
      return <CreateTable items={selectedItems} />;
    }
  }, [modalInfo.modalTitle, selectedItems]);

  const onChange = useCallback((key) => {
    setFormCategory(key);
  }, []);

  const tabItems = useCallback(
    () => [
      {
        key: '품목',
        label: '품목',
      },
      {
        key: '규격',
        label: '규격',
      },
      {
        key: '단가',
        label: '단가',
      },
      {
        key: '기타',
        label: '기타',
      },
    ],
    []
  );

  // 얘 때매 안되는거 같음...
  return (
    <>
      <Tabs
        size="large"
        type="card"
        items={tabItems()}
        onChange={onChange}
        style={{ marginBottom: '-16px' }}
      />
      {product()}
    </>
  );
};

export default React.memo(ProductData);

ProductData.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};
