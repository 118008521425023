/* eslint prefer-const: "off" */
import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './App.css';
import {
  asyncRefreshSessionFetch,
  endRefreshSession,
} from './redux/modules/auth/refreshSession';
import {
  notLoggedInRefreshSessionReducer,
  loggedInRefreshSessionReducer,
} from './redux/modules/auth/signinUser';

import { OrderProductsProvider } from './providers/OrderProductsProvider';

import Login from './auth/pages/Login';
import Signup from './auth/pages/Signup';
import PrivateRoute from './auth/components/PrivateRoute';
import PublicRoute from './auth/components/PublicRoute';
import Loading from './shared/components/Loading';
import ModalContainer from './modal/ModalContainer';
import DataCalendar from './dataCalendar/page/DataCalendar';
import PasswordReset from './auth/pages/PasswordReset';
import Account from './account/page/Account';
import Accountgroup from './accountgroup/page/Accountgroup';
import Product from './product/page/Product';
import Warehouse from './warehouse/page/Warehouse';
import AccountDetail from './account/page/AccountDetail';
import Manager from './managers/page/Manager';
import CreateAccount from './account/page/CreateAccount';
import Sale from './sale/page/Sale';
import SaleDetail from './sale/page/SaleDetail';
import CreateProduct from './product/page/CreateProduct';
import ProductDetail from './product/page/ProductDetail';
import UpdateProduct from './product/page/UpdateProduct';
import UpdateSale from './sale/page/UpdateSale';
import CreateSale from './sale/page/CreateSale';
import UpdateAccount from './account/page/UpdateAccount';
import Order from './order/page/Order';
import CreateOrder from './order/page/CreateOrder';
import OrderDetail from './order/page/OrderDetail';
import UpdateOrder from './order/page/UpdateOrder';
import SalesInquiry from './order/page/SalesInquiry';
import Calculator from './calculator/page/Calculator';
import Directions from './directions/page/Directions';
import Delivery from './delivery/page/Delivery';
import Propose from './propose/page/Propose';
import SalesStatus from './salesStatus/page/SalesStatus';
import ExternalServices from './externalServices/page/ExternalServices';
import Estimate from './estimate/page/Estimate';
import AccountDiscount from './accountDiscount/page/AccountDiscount';
import Purchase from './purchase/page/Purchase';
import PurchaseOrder from './purchaseOrder/page/PurchaseOrder';
import PurchaseDetail from './purchase/page/PurchaseDetail';
import PurchaseUpdate from './purchase/page/PurchaseUpdate';
import PurchaseCreate from './purchase/page/PurchaseCreate';
import PurchaseOrderDetail from './purchaseOrder/page/PurchaseOrderDetail';
import PurchaseOrderUpdate from './purchaseOrder/page/PurchaseOrderUpdate';
import PurchaseOrderCreate from './purchaseOrder/page/PurchaseOrderCreate';
import Inventory from './inventory/page/Inventory';
import InventoryReceipts from './inventory/page/InventoryReceipts';
import SyncError from './syncError/page/SyncError';
import CounsellingCreate from './counselling/page/CounsellingCreate';
import CounsellingDetails from './counselling/page/CounsellingDetails';
import CounsellingUpdate from './counselling/page/CounsellingUpdate';
import CounsellingBoard from './counselling/page/CounsellingBoard';
import PurchaseStatus from './purchaseStatus/page/PurchaseStatus';
import DataMap from './dataMap/page/DataMap';
import Migration from './migration/page/Migration';
import Sms from './sms/pages/Sms';
import SmsHistory from './sms/pages/SmsHistory';
import Invoice from './invoice/pages/Invoice';
import Profile from './auth/pages/Profile';
import EditUserAccess from './auth/pages/EditUserAccess';

function App() {
  return (
    <>
      <OrderProductsProvider>
        <Switch>
          <Route component={Loading} path="/loading" exact />
          <PublicRoute component={Login} path="/" exact />
          <PublicRoute component={Signup} path="/signup" exact />
          <PrivateRoute component={Profile} path="/profile" exact />
          <PrivateRoute
            component={PasswordReset}
            path="/reset-password"
            exact
          />
          <PrivateRoute
            component={EditUserAccess}
            path="/user-info/:id"
            exact
          />
          <PrivateRoute component={DataCalendar} path="/data-calendar" exact />
          <PrivateRoute
            component={DataCalendar}
            path="/data-calendar/browse-here"
            exact
          />
          <PrivateRoute component={DataMap} path="/data-map" exact />
          <PrivateRoute component={Account} path="/account" exact />
          <PrivateRoute
            component={CreateAccount}
            path="/account/createAccount"
            exact
          />
          <PrivateRoute
            component={UpdateAccount}
            path="/account/updateAccount/:id"
            exact
          />
          <PrivateRoute
            component={AccountDiscount}
            path="/account-discount"
            exact
          />
          <PrivateRoute component={AccountDetail} path="/account/:id" exact />
          <PrivateRoute component={Accountgroup} path="/accountgroup" exact />
          <PrivateRoute component={Product} path="/product" exact />
          <PrivateRoute
            component={CreateProduct}
            path="/product/createProduct"
            exact
          />
          <PrivateRoute
            component={UpdateProduct}
            path="/product/updateProduct/:id"
            exact
          />
          <PrivateRoute component={ProductDetail} path="/product/:id" exact />
          <PrivateRoute component={Warehouse} path="/warehouse" exact />
          <PrivateRoute component={Manager} path="/manager" exact />
          <PrivateRoute component={Sale} path="/sale" exact />
          <PrivateRoute component={CreateSale} path="/sale/createSale" exact />
          <PrivateRoute
            component={UpdateSale}
            path="/sale/updateSale/:code"
            exact
          />
          <PrivateRoute component={SaleDetail} path="/sale/:code" exact />
          <PrivateRoute component={Directions} path="/directions" exact />
          <PrivateRoute component={Delivery} path="/delivery" exact />
          <PrivateRoute component={Propose} path="/propose" exact />
          <PrivateRoute component={Purchase} path="/purchase" exact />
          <PrivateRoute
            component={PurchaseCreate}
            path="/purchase/create/purchase-order-id/:id"
            exact
          />
          <PrivateRoute
            component={PurchaseDetail}
            path="/purchase/detail/:id"
            exact
          />
          <PrivateRoute
            component={PurchaseUpdate}
            path="/purchase/update/:id"
            exact
          />
          <PrivateRoute
            component={PurchaseOrder}
            path="/purchase-order"
            exact
          />
          <PrivateRoute
            component={PurchaseOrderCreate}
            path="/purchase-order/create"
            exact
          />
          <PrivateRoute
            component={PurchaseOrderDetail}
            path="/purchase-order/detail/:id"
            exact
          />
          <PrivateRoute
            component={PurchaseOrderUpdate}
            path="/purchase-order/update/:id"
            exact
          />
          <PrivateRoute component={Inventory} path="/inventory" exact />
          <PrivateRoute
            component={InventoryReceipts}
            path="/inventory-receipts"
            exact
          />
          <PrivateRoute component={SalesStatus} path="/sales-status" exact />
          <PrivateRoute
            component={ExternalServices}
            path="/external-services/:type"
            exact
          />
          <PrivateRoute component={Estimate} path="/estimate" exact />
          <PrivateRoute component={Calculator} path="/Calculator" exact />
          <PrivateRoute component={Order} path="/order" exact />
          <PrivateRoute
            component={CreateOrder}
            path="/order/createOrder"
            exact
          />
          <PrivateRoute
            component={CreateOrder}
            path="/order/createOrder/estimateToOrder/:id"
            exact
          />
          <PrivateRoute
            component={UpdateOrder}
            path="/order/updateOrder/:id"
            exact
          />
          <PrivateRoute component={OrderDetail} path="/order/:id" exact />
          <PrivateRoute component={SalesInquiry} path="/sales-inquiry" exact />
          <PrivateRoute component={SyncError} path="/sync-error" exact />
          <PrivateRoute
            component={CounsellingBoard}
            path="/counselling"
            exact
          />
          <PrivateRoute
            component={CounsellingCreate}
            path="/counselling/create"
            exact
          />
          <PrivateRoute
            component={CounsellingDetails}
            path="/counselling/details/:id"
            exact
          />
          <PrivateRoute
            component={CounsellingUpdate}
            path="/counselling/update/:id"
            exact
          />
          <PrivateRoute
            component={PurchaseStatus}
            path="/purchase-status"
            exact
          />
          <PrivateRoute component={Migration} path="/migration" exact />
          <PrivateRoute component={Sms} path="/sms" exact />
          <PrivateRoute component={SmsHistory} path="/sms-history" exact />
          <PrivateRoute component={Invoice} path="/invoice" exact />
        </Switch>
      </OrderProductsProvider>
      <ModalContainer />
    </>
  );
}

export default App;
