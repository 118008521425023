import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  LIGHT_MODE_BACKGROUND,
  LIGHT_MODE_FONT_COLOR,
} from '../../constants';

const SalesAverageStatistic = (props) => {
  const { salesAverageData, isLargeScreen } = props;

  const { darkMode } = useSelector((state) => state.darkMode);
  const bgColor = darkMode ? DARK_MODE_BACKGROUND : LIGHT_MODE_BACKGROUND;
  const fontColor = darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR;

  return (
    <div
      style={{
        flexDirection: isLargeScreen ? 'row' : 'column',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        padding: '10px',
        color: fontColor,
        backgroundColor: bgColor,
      }}
    >
      <h4 style={{ color: 'gray' }}>Month AVG</h4>
      <div
        style={{
          color: 'gray',
        }}
      >
        {Math.round(salesAverageData).toLocaleString()}원
      </div>
    </div>
  );
};

export default SalesAverageStatistic;

SalesAverageStatistic.propTypes = {
  salesAverageData: PropTypes.number.isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
};
