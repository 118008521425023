import axiosInstance from '../axios';

const sendSmsApi = async (postData) => {
  const url = 'api/third-party/ncloud-send-sms';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const getSmsRequestApi = async (postData) => {
  const url = `api/third-party/ncloud-get-sms/request`;
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const getSmsResultApi = async (postData) => {
  const { messageId } = postData;
  const url = `api/third-party/ncloud-get-sms/result/${messageId}`;
  const response = await axiosInstance.get(url);
  return response.data;
};

const saveSmsFeedbackApi = async (postData) => {
  const url = 'api/sms/feedback';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const saveSmsHistoryApi = async (postData) => {
  const url = 'api/sms/history';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const getSmsFeedbackApi = async () => {
  const url = 'api/sms/feedback';
  const response = await axiosInstance.get(url);
  return response.data;
};

const getSmsHistoryApi = async (postData) => {
  const { queryString } = postData;
  const url = `api/sms/history?${queryString}`;
  const response = await axiosInstance.get(url);
  return response.data;
};

const updateRequestedSmsHistoryApi = async (postData) => {
  const url = 'api/sms/history-requested';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const updateCompletedSmsHistoryApi = async (postData) => {
  const url = 'api/sms/history-completed';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

export {
  sendSmsApi,
  getSmsRequestApi,
  getSmsResultApi,
  saveSmsFeedbackApi,
  saveSmsHistoryApi,
  getSmsFeedbackApi,
  updateRequestedSmsHistoryApi,
  updateCompletedSmsHistoryApi,
  getSmsHistoryApi,
};
