import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { asyncReadRangeDataFetch } from '../../redux/modules/shared/readRangeData';
import {
  getThirtyDaysHourlyChartData,
  getTheDayHourlyChartData,
} from '../../utils/getChartDatas';

import {
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  LIGHT_MODE_BACKGROUND,
  LIGHT_MODE_FONT_COLOR,
  darkModeColors,
  lightModeColors,
} from '../../constants';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ThirtyDaysHourlyChart = (props) => {
  const { selectedDate } = props;

  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.darkMode);
  const Colors = darkMode ? darkModeColors : lightModeColors;

  const [chartData, setChartData] = useState({
    thirtyDays: [],
    theDay: [],
  });

  useEffect(() => {
    const getThirtyDaysChartData = async () => {
      try {
        const startDate = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
        const endDate = dayjs().format('YYYY-MM-DD');
        const fetchedSalesData = await dispatch(
          asyncReadRangeDataFetch({
            table: 'v_sales_status',
            column: 'delivery_date',
            startPt: startDate,
            endPt: endDate,
          })
        )
          .unwrap()
          .then((res) => res);
        const thirtyDaysHourlyChartData = getThirtyDaysHourlyChartData(
          fetchedSalesData,
          startDate,
          endDate
        );
        const resultChartData = thirtyDaysHourlyChartData.map((item) => ({
          ...item,
          hour: `${item.hour}h`,
        }));
        const resultChartDataWithEdgeDays = [
          { hour: '전일', sales: 0 },
          ...resultChartData,
          { hour: '익일', sales: 0 },
        ];
        setChartData((prev) => ({
          ...prev,
          thirtyDays: resultChartDataWithEdgeDays,
        }));
      } catch (error) {
        throw new Error(error);
      }
    };
    getThirtyDaysChartData();
  }, []);

  useEffect(() => {
    const getTheDayChartData = async () => {
      try {
        const fetchedSalesData = await dispatch(
          asyncReadRangeDataFetch({
            table: 'v_sales_status',
            column: 'delivery_date',
            startPt: selectedDate,
            endPt: selectedDate,
          })
        )
          .unwrap()
          .then((res) => res);
        const theDayHourlyChartData = getTheDayHourlyChartData(
          fetchedSalesData,
          selectedDate
        );
        const resultChartData = theDayHourlyChartData.map((item) => {
          const formattedDate = Number(item.hour) ? `${item.hour}h` : item.hour;
          return { ...item, hour: formattedDate };
        });
        setChartData((prev) => ({ ...prev, theDay: resultChartData }));
      } catch (error) {
        throw new Error(error);
      }
    };
    getTheDayChartData();
  }, [selectedDate]);

  const options = {
    aspectRatio: 3,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        min: 0,
        max: 80000000,
        ticks: {
          stepSize: 20000000,
          callback: (value) => {
            if (value === 0) {
              return value;
            }
            const stepSize = String(value);
            return `${stepSize.slice(0, 1)}천만`;
          },
        },
      },
      x: {
        ticks: {
          maxTicksLimit: 6,
        },
      },
    },
  };
  const data = {
    labels: chartData.thirtyDays.map((item) => item.hour),
    datasets: [
      {
        label: '30일 시간당 매출액',
        data: chartData.thirtyDays.map((item) => item.sales),
        borderColor: Colors.chart_tdh_1_border,
        backgroundColor: Colors.chart_tdh_1_bg,
      },
      {
        label: '일일 시간당 매출액(10배)',
        data: chartData.theDay.map((item) => item.sales * 10),
        borderColor: Colors.armsone_orange,
        backgroundColor: Colors.armsone_orange,
      },
    ],
  };

  return (
    <div
      style={{
        padding: '10px',
        // border: '1px solid #efefef',
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
        color: darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR,
        borderRadius: '15px',
      }}
    >
      <h4 style={{ color: 'gray', marginBottom: '5px', textAlign: 'center' }}>
        Day
      </h4>
      <Line options={options} data={data} />
    </div>
  );
};

export default ThirtyDaysHourlyChart;

ThirtyDaysHourlyChart.propTypes = {
  selectedDate: PropTypes.string.isRequired,
};
