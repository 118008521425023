import React, { useMemo, useState } from 'react';
import { SolutionOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { Link } from 'react-router-dom';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Table from '../../shared/table/Table';
import DiscountProductValue from '../components/DiscountProductValue';

const AccountDiscount = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <SolutionOutlined />,
        breadCrumbNav: ' 거래처할인',
      },
    ],
    []
  );

  // 불러올 table이 뭔지
  const table = 'account';

  // TableHeader에 들어갈 create 버튼 텍스트
  const createButtonText = '거래처할인 추가';

  // table에 넣을 columns. table View를 위한 배열.
  const tableColumns = [
    {
      title: '거래처코드',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      width: '33.3%',
    },
    {
      title: '거래처명',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: '33.3%',
    },
    {
      title: '할인품목',
      width: '33.3%',
      key: 'accountId',
      dataIndex: 'id',
      align: 'center',
      render: (value, data) => {
        return (
          <DiscountProductValue
            accountId={value}
            accountName={data.name}
            accountCode={data.code}
          />
        );
      },
    },
  ];

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <Table
        tableColumns={tableColumns}
        table={table}
        createButtonText={createButtonText}
        items={tableColumns}
      />
    </LayoutComponent>
  );
};

export default AccountDiscount;
