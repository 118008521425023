import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import useModal from '../../hook/useModal';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';
import ProductForm from './ProductForm';

import ecount from '../../ecount';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import { asyncDeleteDataFetch } from '../../redux/modules/shared/deleteData';

const ProductFormFinish = (props) => {
  const { pageType, formItems, formData, originalProductCode } = props;
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const history = useHistory();
  const { id } = useParams();
  // const { saveProduct } = ecount();

  const handleValidation = (values) => {
    const reg = /\s/g;
    if (
      !values.code ||
      (typeof values.code === 'string' && values.code.match(reg))
    ) {
      alert(
        '품목코드를 입력하지 않았거나 품목코드에 공백이 포함되어 있습니다.'
      );
      return true;
    }
    if (
      !values.name ||
      (typeof values.name === 'string' && values.name.match(reg))
    ) {
      alert('품목명을 입력하지 않았거나 품목명에 공백이 포함되어 있습니다.');
      return true;
    }
  };

  const onFinish = (values) => {
    // console.log(values);
    if (pageType === 'create') {
      const check = handleValidation(values);
      if (check) {
        return;
      }
      const createValues = {
        table: 'product',
        ...values,
      };
      dispatch(asyncCreateDataFetch(createValues))
        .unwrap()
        .then(() => {
          openModal({
            type: 'result',
            modalInfo: {
              modalTitle: '출고상품(품목)',
              resultStatus: 'success',
              resultTitle: '출고상품(품목) 추가',
              resultSubTitle: `출고상품(품목)에 '${values.code}(${values.name})'을(를) 저장했습니다.`,
              buttonText: '확인',
            },
          });
          history.push('/product');
          // ecount에 출고상품(품목) 데이터 추가
          // saveProduct(values);
          // 판매상품, 판매-출고상품 자동 등록
          const asyncHandleSale = async () => {
            // 판매상품 코드생성
            let saleCode = null;
            await dispatch(
              asyncReadDataFetch({ table: 'sale', reqDataLen: true })
            )
              .unwrap()
              .then((res) => {
                saleCode = `S${String(res + 1).padStart(6, '0')}`;
              });
            // 판매상품 등록
            await dispatch(
              asyncCreateDataFetch({
                table: 'sale',
                code: saleCode,
                name: values.name,
              })
            )
              .unwrap()
              .then()
              .catch((err) => {
                console.log(err);
                alert(err.message);
              });
            // 판매-출고상품 등록
            await dispatch(
              asyncCreateDataFetch({
                table: 'sale_product',
                product_code: values.code,
                sale_code: saleCode,
                product_quantity: 1,
              })
            )
              .unwrap()
              .then(() => {
                // alert('판매상품을 자동으로 등록했습니다.');
              })
              .catch((err) => {
                console.log(err);
                alert(err.message);
              });
          };
          asyncHandleSale();
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    }
    // 출고상품 수정
    else if (pageType === 'update') {
      const updateProduct = async () => {
        try {
          const check = handleValidation(values);
          if (check) {
            return;
          }
          const updateValues = {
            table: 'product',
            id,
            state: 1,
            ...values,
          };
          // product 테이블 변경
          await dispatch(asyncUpdateDataFetch(updateValues))
            .unwrap()
            .then(() => {
              openModal({
                type: 'result',
                modalInfo: {
                  modalTitle: '출고상품(품목)',
                  resultStatus: 'success',
                  resultTitle: '출고상품(품목) 수정',
                  resultSubTitle: `출고상품(품목) '${values.code}(${values.name})'을(를) 수정했습니다.`,
                  buttonText: '확인',
                },
              });
              history.push(`/product/${id}`);
            });
        } catch (error) {
          console.log(error);
          alert(error.message);
        }
      };
      updateProduct();
    }
    // 출고상품 삭제
    else if (pageType === 'detail') {
      const confirm = window.confirm('정말 삭제하시겠습니까?');
      if (!confirm) {
        return;
      }
      const updateValues = {
        id,
        table: 'product',
        state: 9,
        code: `deleted)${values.code}`,
        oms_code: `deleted)${values.oms_code}`,
        name: `deleted)${values.name}`,
      };
      dispatch(asyncUpdateDataFetch(updateValues))
        .unwrap()
        .then(() => {
          openModal({
            type: 'result',
            modalInfo: {
              modalTitle: '출고상품(품목)',
              resultStatus: 'success',
              resultTitle: '출고상품(품목) 삭제',
              resultSubTitle: '출고상품(품목)을 삭제했습니다.',
              buttonText: '확인',
            },
          });
          history.push('/product');
        });
      // 판매-출고 삭제
      const asyncDeleteSalePd = async () => {
        const salePdId = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'sale_product',
            eqKey: 'product_code',
            eqValue: values.code,
          })
        )
          .unwrap()
          .then((res) => res[0].id);
        dispatch(asyncDeleteDataFetch({ table: 'sale_product', id: salePdId }));
      };
      asyncDeleteSalePd();
    }
  };

  return (
    <ProductForm
      pageType={pageType}
      formItems={formItems}
      formData={formData}
      onFinish={onFinish}
    />
  );
};

export default React.memo(ProductFormFinish);

ProductFormFinish.propTypes = {
  pageType: PropTypes.string.isRequired,
  formItems: PropTypes.instanceOf(Array).isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
  originalProductCode: PropTypes.string.isRequired,
};
