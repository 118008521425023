import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const OrderDetail = (props) => {
  const { value } = props;
  const dispatch = useDispatch();
  const [order, setOrder] = useState({});
  const [orderProducts, setOrderProducts] = useState([]);
  useEffect(() => {
    const getDetail = async () => {
      try {
        await dispatch(
          asyncReadFilteredDataFetch({
            table: 'order',
            eqKey: 'id',
            eqValue: value,
          })
        )
          .unwrap()
          .then((res) => {
            if (res[0]) setOrder(res[0]);
          });
        await dispatch(
          asyncReadFilteredDataFetch({
            table: 'order_product',
            eqKey: 'order_id',
            eqValue: value,
          })
        )
          .unwrap()
          .then((res) => setOrderProducts(res));
      } catch (error) {
        console.log(error);
      }
    };
    getDetail();
  }, [dispatch, value]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div style={{ width: '50%' }}>
        {order.account_name ? (
          <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
            {order.account_name}
          </div>
        ) : (
          <div style={{ color: 'red', marginBottom: '5px' }}>삭제된 데이터</div>
        )}
      </div>
      <div style={{ width: '50%' }}>
        {orderProducts.map((item) => (
          <div>
            {item.product_name} {item.product_quantity}개 /{' '}
            {item.discounted.toLocaleString()}원
          </div>
        ))}
        {order[0]}
      </div>
    </div>
  );
};

export default OrderDetail;

OrderDetail.propTypes = {
  value: PropTypes.string.isRequired,
};
