import React from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

import useModal from '../../hook/useModal';
import ManagerData from './ManagerData';

const ManagerModal = () => {
  // 모달 정보 가져오기.
  const { closeModal } = useModal();
  const { modalInfo } = useSelector((state) => state.modal);

  // form에 들어갈 각 item 항목들을 배열로 만듬.
  // TableFormModal에서 form형식으로 매핑됨.
  const createItems = [
    {
      type: 'input',
      name: 'email',
      label: '이메일',
      message: '예) example@gmail.com',
    },
    {
      type: 'input',
      name: 'password',
      label: '비밀번호',
      message: '비밀번호를 입력해주세요.',
    },
    {
      type: 'input',
      name: 'username',
      label: '이름',
      message: '이름을 입력해주세요.',
    },
    {
      type: 'input',
      name: 'phone',
      label: '전화번호',
      message: '예) 010-1234-5678',
    },
    {
      type: 'select',
      label: '등급',
      name: 'tier',
      message: '등급을 선택해주세요.',
      options: [
        { label: '1등급', value: 1 },
        { label: '2등급', value: 2 },
        { label: '3등급', value: 3 },
      ],
    },
    {
      type: 'select',
      label: '허가',
      name: 'permission',
      message: '허가를 선택해주세요.',
      options: [
        { label: 'Y', value: 1 },
        { label: 'N', value: 0 },
      ],
    },
  ];

  const items = [
    {
      type: 'select',
      label: '등급',
      name: 'tier',
      message: '등급을 선택해주세요.',
      options: [
        { label: '1등급', value: 1 },
        { label: '2등급', value: 2 },
        { label: '3등급', value: 3 },
      ],
    },
    {
      type: 'select',
      label: '허가',
      name: 'permission',
      message: '허가를 선택해주세요.',
      options: [
        { label: 'Y', value: 1 },
        { label: 'N', value: 0 },
      ],
    },
  ];

  return (
    <Modal
      open
      onCancel={() => closeModal()}
      title={modalInfo.modalTitle}
      centered
      footer={[<div key="modal" />]}
    >
      <ManagerData items={items} createItems={createItems} />
    </Modal>
  );
};

export default ManagerModal;
