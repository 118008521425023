import React, { useEffect, useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';

const ProductColumnValue = (props) => {
  const { id, importIdValue } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const [productName, setProductName] = useState('');

  useEffect(() => {
    const getProductName = async () => {
      try {
        const productIdArr = await dispatch(
          asyncReadFilteredDataFetch({
            table:
              importIdValue === 'purchaseId'
                ? 'purchase_product'
                : 'purchase_order_product',
            eqKey:
              importIdValue === 'purchaseId'
                ? 'purchase_id'
                : 'purchase_order_id',
            eqValue: id,
          })
        )
          .unwrap()
          .then((res) => res.map((pd) => pd.product_id));
        const productNameArr = await Promise.all(
          productIdArr.map(async (productId) => {
            const resOfPdName = await dispatch(
              asyncReadSpecificDataFetch({ table: 'product', id: productId })
            )
              .unwrap()
              .then((res) => res[0].name);
            return resOfPdName;
          })
        );
        if (productNameArr.length <= 1) {
          setProductName(productNameArr[0]);
        } else {
          setProductName(
            `${productNameArr[0]} 외 ${productNameArr.length - 1}개`
          );
        }
      } catch (error) {
        throw new Error();
      }
    };
    getProductName();
  }, [dispatch, importIdValue, id]);

  const goToDetailPage = () => {
    const formType =
      importIdValue === 'purchaseId' ? 'purchase' : 'purchase-order';
    history.push(`${formType}/detail/${id}`);
  };

  return (
    <Button type="link" onClick={() => goToDetailPage()}>
      {productName}
    </Button>
  );
};

export default memo(ProductColumnValue);

ProductColumnValue.propTypes = {
  id: PropTypes.number.isRequired,
  importIdValue: PropTypes.string.isRequired,
};
