import React from 'react';
import TextArea from 'antd/es/input/TextArea';
import PropTypes from 'prop-types';
import ErrorText from './ErrorText';

const ContentField = ({ name, value, error, blur, onChange, onBlur, type }) => {
  const isSms = type === 'SMS';
  const curLength = value ? value.length : 0;
  const maxLength = isSms ? 40 : 1000;
  const isOverMax = curLength > maxLength;

  return (
    <div style={{ width: '100%' }}>
      <TextArea
        maxLength={maxLength}
        rows={12}
        placeholder="내용을 입력해주세요. (10자 이상.)"
        value={value}
        onChange={(e) => onChange(name, e.target.value)}
        onBlur={() => onBlur(name)}
      />
      <div style={{ textAlign: 'right', color: isOverMax ? 'red' : 'gray' }}>
        {isSms ? `${curLength}/40자` : `${curLength}/1000자`}
      </div>
      {error && blur && (
        <ErrorText text="내용을 입력해주세요. (10자 이상, 제한된 글자수 이하.)" />
      )}
    </div>
  );
};

export default ContentField;

ContentField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  blur: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};
