import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';
import styled from 'styled-components';

const ButtonStyle = styled(Button)`
  width: 100%;
  margin-top: 20px;
  background-color: black;
  color: white;
  height: 100%;
  padding: 8px 0;
`;

const PasswordResetForm = (props) => {
  const { onFinish, onFinishFailed } = props;
  return (
    <Form
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label={<p style={{ fontWeight: '700' }}>새 비밀번호</p>}
        name="password"
        rules={[
          {
            required: true,
            message: '새 비밀번호를 입력해주세요.',
          },
        ]}
      >
        <Input.Password size="large" />
      </Form.Item>
      <Form.Item
        label={<p style={{ fontWeight: '700' }}>새 비밀번호 확인</p>}
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: '새 비밀번호 확인을 입력해주세요.',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('비밀번호와 동일하지 않습니다.'));
            },
          }),
        ]}
      >
        <Input.Password size="large" />
      </Form.Item>
      <Form.Item>
        <ButtonStyle htmlType="submit">완료</ButtonStyle>
      </Form.Item>
    </Form>
  );
};

export default PasswordResetForm;

PasswordResetForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onFinishFailed: PropTypes.func.isRequired,
};
