import React from 'react';
import PropTypes from 'prop-types';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { DARK_MODE_BACKGROUND, LIGHT_MODE_BACKGROUND } from '../../constants';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BarAndLineGraphByCity = (props) => {
  const { darkMode, numberOfAccountDatasByCity } = props;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels: numberOfAccountDatasByCity.map((item) => item.value),
    datasets: [
      {
        labels: '거래처수',
        data: numberOfAccountDatasByCity.map((item) => item.amount),
        borderColor: '#1677ff',
        backgroundColor: '#e6f4ff',
      },
    ],
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '10px',
        borderRadius: '15px',
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
      }}
    >
      <h4 style={{ marginBottom: '15px' }}>
        시군구별 거래처수 / 매출액 그래프
      </h4>
      <Line options={options} data={data} height={75} />
    </div>
  );
};

export default BarAndLineGraphByCity;

BarAndLineGraphByCity.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  numberOfAccountDatasByCity: PropTypes.instanceOf(Array).isRequired,
};
