import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Popconfirm, Radio, Switch } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

const SmsInfo = (props) => {
  const {
    setOrderData,
    setSmsType,
    setMobile,
    setIsDisable,
    getAccountMobile,
    handlePayapp,
    mobile,
    isDisable,
    pageType,
    smsType,
    orderData,
    reqSmsInput,
    setReqSmsInput,
    conSmsInput,
    setConSmsInput,
    genLinkLoading,
    setIsOnDlvyText,
    isOnDlvyText,
    isOnPackageText,
    setIsOnPackageText,
  } = props;

  const mobileRef = useRef(null);

  const handleSmsPhoneSaveButton = () => {
    setOrderData((prev) => ({ ...prev, sms_phone: mobile }));
    setIsDisable(true);
  };

  return (
    <div
      style={{
        border: '1px solid lightGray',
        padding: '30px',
        marginRight: '100px',
        borderRadius: '20px',
        backgroundColor: '#fafafa',
      }}
    >
      <div>
        <p style={{ marginBottom: '5px' }}>전송할 SMS를 선택해주세요.</p>
        <Radio.Group
          defaultValue="request"
          onChange={(e) => setSmsType(e.target.value)}
        >
          <Radio value="request">결제요청</Radio>
          <Radio
            value="confirm"
            disabled={
              orderData.payment_terms === '신용카드' ||
              orderData.payment_terms === '법인카드'
            }
          >
            입금확인
          </Radio>
        </Radio.Group>
      </div>
      <div style={{ marginTop: '25px' }}>
        <span>SMS를 전송할 거래처 전화번호를 입력해주세요.</span>
        <span>(-를 제외하고 입력.)</span>
        <div style={{ marginTop: '5px' }}>
          <Button
            size="small"
            onClick={getAccountMobile}
            disabled={pageType === 'detail'}
            style={{ borderRadius: '20px', marginRight: '2px' }}
          >
            불러오기
          </Button>
          <Button
            size="small"
            onClick={() => {
              setIsDisable(false);
              setMobile('');
              mobileRef.current.focus();
            }}
            disabled={pageType === 'detail'}
            style={{ borderRadius: '20px' }}
          >
            직접입력
          </Button>
        </div>
        <div style={{ marginTop: '5px' }}>
          <Input
            onChange={(e) => setMobile(e.target.value)}
            value={mobile}
            disabled={isDisable || pageType === 'detail'}
            style={{ borderRadius: '20px' }}
            ref={mobileRef}
            suffix={
              pageType === 'update' && (
                <Button
                  size="small"
                  onClick={handleSmsPhoneSaveButton}
                  type="primary"
                  style={{ borderRadius: '20px' }}
                >
                  <SaveOutlined />
                </Button>
              )
            }
          />
        </div>
      </div>
      <div style={{ marginTop: '25px' }}>
        <p>전송할 SMS내용을 입력해주세요.</p>
        <div style={{ marginTop: '5px' }}>
          {smsType === 'request' && (
            <Input.TextArea
              rows={8}
              value={reqSmsInput}
              onChange={(e) => setReqSmsInput(e.target.value)}
              disabled={pageType === 'detail'}
              style={{ borderRadius: '20px' }}
            />
          )}
          {smsType === 'confirm' && (
            <Input.TextArea
              rows={8}
              value={conSmsInput}
              onChange={(e) => setConSmsInput(e.target.value)}
              disabled={pageType === 'detail'}
            />
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '25px',
          backgroundColor: 'white',
          borderRadius: '20px',
          justifyContent: 'center',
          padding: '15px',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <p style={{ marginBottom: '10px' }}>패키지 텍스트 ON/OFF</p>
          <Switch
            onChange={(checked) => setIsOnPackageText(checked)}
            checked={isOnPackageText}
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            marginLeft: '20px',
          }}
        >
          <p style={{ marginBottom: '10px' }}>배송비 텍스트 ON/OFF</p>
          <Switch
            onChange={(checked) => setIsOnDlvyText(checked)}
            checked={isOnDlvyText}
          />
        </div>
      </div>
      <div style={{ marginTop: '25px', textAlign: 'center' }}>
        <Popconfirm
          title="PAYAPP 결제링크를 생성하시겠습니까?"
          onConfirm={handlePayapp}
          okText="네"
          cancelText="아니요"
          disabled={
            !(
              orderData.payment_terms === '신용카드' ||
              orderData.payment_terms === '법인카드'
            )
          }
        >
          <Button
            style={{ borderRadius: '20px', width: '100%' }}
            loading={genLinkLoading}
            type="primary"
            disabled={
              !(
                orderData.payment_terms === '신용카드' ||
                orderData.payment_terms === '법인카드'
              )
            }
          >
            PAYAPP 결제링크 생성하기
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};

export default SmsInfo;

SmsInfo.propTypes = {
  setOrderData: PropTypes.func.isRequired,
  setSmsType: PropTypes.func.isRequired,
  setMobile: PropTypes.func.isRequired,
  setIsDisable: PropTypes.func.isRequired,
  getAccountMobile: PropTypes.func.isRequired,
  handlePayapp: PropTypes.func.isRequired,
  mobile: PropTypes.string.isRequired,
  isDisable: PropTypes.bool.isRequired,
  pageType: PropTypes.string.isRequired,
  smsType: PropTypes.string.isRequired,
  orderData: PropTypes.instanceOf(Object).isRequired,
  reqSmsInput: PropTypes.string.isRequired,
  setReqSmsInput: PropTypes.func.isRequired,
  conSmsInput: PropTypes.string.isRequired,
  setConSmsInput: PropTypes.func.isRequired,
  genLinkLoading: PropTypes.bool.isRequired,
  isOnDlvyText: PropTypes.bool.isRequired,
  setIsOnDlvyText: PropTypes.func.isRequired,
  isOnPackageText: PropTypes.bool.isRequired,
  setIsOnPackageText: PropTypes.func.isRequired,
};
