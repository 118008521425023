import React from 'react';
import { useDispatch } from 'react-redux';
import { asyncReadFilteredDataFetch } from '../redux/modules/shared/readFilteredData';

const useDcAccountProduct = () => {
  const dispatch = useDispatch();
  const createDcAccountProductDatas = async (
    orderProductDatas,
    accountCode
  ) => {
    try {
      const accountId = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'account',
          eqKey: 'code',
          eqValue: accountCode,
        })
      )
        .unwrap()
        .then((res) => res[0].id);
      const dcAccountProductDatas = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'discount_account_product',
          eqKey: 'account_id',
          eqValue: accountId,
        })
      )
        .unwrap()
        .then((res) => {
          const result = [];
          for (const dcDataOfAcct of res) {
            for (const selectedDataInOrder of orderProductDatas) {
              if (
                dcDataOfAcct.product_id === selectedDataInOrder.product_id &&
                dcDataOfAcct.is_on
              ) {
                result.push(dcDataOfAcct);
              }
            }
          }
          return result;
        });
      return dcAccountProductDatas;
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return { createDcAccountProductDatas };
};

export default useDcAccountProduct;
