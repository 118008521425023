import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';

import {
  darkModeColors,
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  LIGHT_MODE_BACKGROUND,
  LIGHT_MODE_FONT_COLOR,
} from '../../constants';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MonthlySalesChart = (props) => {
  const { monthValue } = props;

  const dispatch = useDispatch();

  const { darkMode } = useSelector((state) => state.darkMode);
  const bgColor = darkMode ? DARK_MODE_BACKGROUND : LIGHT_MODE_BACKGROUND;
  const fontColor = darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR;

  const isLargeScreen = false;

  const initialChartData = {
    year_month: '',
    monthly_sales_amount: 0,
    monthly_sales_store_amount: 0,
  };

  const [monthlySalesDatas, setMonthlySalesDatas] = useState([]);
  const [chartData, setChartData] = useState(initialChartData);

  useEffect(() => {
    const fetchMonthSalesData = async () => {
      const fetchedMonthSalesData = await dispatch(
        asyncReadDataFetch({
          table: 'v_monthly_sales_amount',
        })
      ).unwrap();
      setMonthlySalesDatas(fetchedMonthSalesData);
    };
    fetchMonthSalesData();
  }, []);

  useEffect(() => {
    if (!monthlySalesDatas.length) return;
    const curChartData = monthlySalesDatas.filter(
      (data) => data.year_month === monthValue
    );
    const formattedCurChartData = curChartData[0]
      ? {
          year_month: curChartData[0].year_month,
          monthly_sales_amount: Number(curChartData[0].monthly_sales_amount),
          monthly_sales_store_amount: Number(
            curChartData[0].monthly_sales_store_amount
          ),
        }
      : initialChartData;
    setChartData(formattedCurChartData);
  }, [monthValue, monthlySalesDatas]);

  const options = {
    aspectRatio: 5.6,
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        min: 0,
        max: 400000000,
        ticks: {
          stepSize: 100000000,
          callback: (value) => {
            if (value === 0) {
              return value;
            }
            const stepSize = String(value);
            return `${stepSize.slice(0, 2)}천만`;
          },
        },
        stacked: true,
      },
      y: {
        ticks: {
          display: false, // Y축 레이블 숨김
        },
        grid: {
          drawTicks: false, // Y축 눈금 숨김
        },
        stacked: true,
      },
    },
  };

  const data = {
    labels: [chartData.year_month],
    datasets: [
      {
        label: '온라인',
        data: [chartData.monthly_sales_store_amount],
        backgroundColor: darkMode ? darkModeColors.chart_border_1 : 'gray',
      },
      {
        label: '오프라인',
        data: [
          chartData.monthly_sales_amount - chartData.monthly_sales_store_amount,
        ],
        backgroundColor: darkMode ? darkModeColors.chart_bg_1 : '#bfbfbf',
      },
    ],
  };

  return (
    <div
      style={{
        flexDirection: isLargeScreen ? 'row' : 'column',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        padding: '10px',
        color: fontColor,
        backgroundColor: bgColor,
      }}
    >
      <Bar
        options={options}
        data={data}
        style={{
          position: 'absolute',
          bottom: '7px',
        }}
      />
    </div>
  );
};

export default MonthlySalesChart;

MonthlySalesChart.propTypes = {
  monthValue: PropTypes.string.isRequired,
};
