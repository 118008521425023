import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Descriptions, Input, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';

const ProductInput = (props) => {
  const { productDetails, setProductDetails, pageType, formType } = props;
  const dispatch = useDispatch();

  const [productOptions, setProductOptions] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState({
    isSelected: false,
    productId: null,
    productQuantity: 0,
  });

  useEffect(() => {
    const getProductOptions = async () => {
      try {
        const products = await dispatch(
          asyncReadDataFetch({ table: 'product' })
        )
          .unwrap()
          .then((res) =>
            res.map((pd) => ({ label: `${pd.code}/${pd.name}`, value: pd.id }))
          );
        setProductOptions(products);
      } catch (error) {
        throw new Error(error);
      }
    };
    getProductOptions();
  }, [dispatch]);

  const selectProduct = (id) => {
    setSelectedProduct({
      productQuantity: 0,
      isSelected: true,
      productId: id,
    });
  };

  const inputQuantity = (e) => {
    const { value } = e.target;
    const quantity = Number(value);
    if (isNaN(quantity)) {
      return;
    }
    setSelectedProduct((prev) => ({ ...prev, productQuantity: quantity }));
  };

  const handleClick = () => {
    const { productId, productQuantity, isSelected } = selectedProduct;
    // 선택 검사
    if (!isSelected) {
      alert('품목을 선택해주세요.');
      return;
    }
    // 수량 검사
    if (productQuantity <= 0) {
      alert('수량을 입력해주세요.');
      return;
    }
    // 중복 검사
    let isDup = false;
    productDetails.forEach((data) => {
      if (data.productId === productId) {
        alert('이미 선택된 품목입니다.');
        isDup = true;
      }
    });
    if (isDup) {
      return;
    }
    setProductDetails((prev) => [...prev, { productId, productQuantity }]);
    setSelectedProduct({
      isSelected: false,
      productId: null,
      productQuantity: 0,
    });
  };

  return (
    <Descriptions
      column={1}
      labelStyle={{ width: '10%', textAlign: 'center' }}
      contentStyle={{ width: '90%', textAlign: 'center' }}
      bordered
      className="select-container"
    >
      <Descriptions.Item label="품목선택" style={{ position: 'relative' }}>
        <div style={{ display: 'flex' }}>
          <Select
            disabled={pageType === 'detail' || formType === 'purchase'}
            style={{ width: '100%', textAlign: 'start' }}
            options={productOptions}
            placeholder="품목을 선택해주세요."
            showSearch
            filterOption={(input, option) => {
              return option.label && option.label.includes(input);
            }}
            onChange={(value) => selectProduct(value)}
            value={selectedProduct.productId}
          />
          {selectedProduct.isSelected && (
            <Input
              prefix={<div style={{ color: 'gray' }}>수량: </div>}
              style={{
                marginLeft: '0px',
                borderRadius: '20px',
                width: '100px',
                borderLeft: '0px',
                position: 'absolute',
                right: '35px',
                backgroundColor: '#f7f7f7',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                padding: '0 5px',
                display: 'flex',
              }}
              size="small"
              bordered={false}
              value={selectedProduct.productQuantity}
              onChange={(e) => inputQuantity(e)}
              disabled={pageType === 'detail' || formType === 'purchase'}
            />
          )}
          <Button
            style={{ marginLeft: '10px' }}
            type="primary"
            onClick={handleClick}
            disabled={pageType === 'detail' || formType === 'purchase'}
          >
            <PlusOutlined />
          </Button>
        </div>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default ProductInput;

ProductInput.propTypes = {
  productDetails: PropTypes.instanceOf(Array).isRequired,
  setProductDetails: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
  formType: PropTypes.string.isRequired,
};
