import dayjs from 'dayjs';

const groupDatasByHourly = (datas) => {
  const groupedData = datas.reduce((result, item) => {
    const key = item.sales_date && item.sales_date.slice(0, 13);
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {});
  return Object.values(groupedData);
};
const groupDatasByDaily = (datas) => {
  const groupedData = datas.reduce((result, item) => {
    const key = item.delivery_date;
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {});
  return Object.values(groupedData);
};
const groupDatasByMonthly = (datas) => {
  const groupedData = datas.reduce((result, item) => {
    const deliveryDate = new Date(item.delivery_date);
    const yearMonth = `${deliveryDate.getFullYear()}-${
      deliveryDate.getMonth() + 1
    }`;
    const key = yearMonth;
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {});
  return Object.values(groupedData);
};
const groupDatasByOrder = (datas) => {
  const groupedData = datas.reduce((result, item) => {
    const key = item.id;
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);

    return result;
  }, {});
  return Object.values(groupedData);
};
const groupDatasByProduct = (datas) => {
  const groupedData = datas.reduce((result, item) => {
    const key = item.product_code;
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {});
  return Object.values(groupedData);
};
const groupDatasByAccount = (datas) => {
  const groupedData = datas.reduce((result, item) => {
    const key = item.account_code;
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {});
  return Object.values(groupedData);
};
const groupDatasByAdmin = (datas) => {
  const groupedData = datas.reduce((result, item) => {
    const key = item.admin_code;
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);

    return result;
  }, {});
  return Object.values(groupedData);
};
const combineDatasByHourly = (datas) => {
  const combinedDatas = datas.map((groupedDatas) => {
    const salesDate =
      (groupedDatas[0].sales_date && groupedDatas[0].sales_date.slice(0, 13)) ||
      null;
    const { sales_date } = groupedDatas[0];
    let supplyPrice = 0;
    let vatPrice = 0;
    let sumPrice = 0;
    let quantity = 0;
    let dcQuantity = 0;
    groupedDatas.forEach((item) => {
      if (item.discount_rate > 0) {
        supplyPrice += item.discounted_supply;
        vatPrice += item.discounted_vat;
        sumPrice += item.price_sum;
        quantity += item.product_quantity;
        dcQuantity += item.product_quantity;
      } else {
        supplyPrice += item.product_supply_price;
        vatPrice += item.product_VAT_price;
        sumPrice += item.price_sum;
        quantity += item.product_quantity;
      }
    });
    return {
      sales_date,
      salesDate,
      supplyPrice,
      vatPrice,
      sumPrice,
      quantity,
      dcQuantity,
    };
  });
  return combinedDatas;
};
const combineDatasByDaily = (datas) => {
  const combinedDatas = datas.map((groupedDatas) => {
    const { delivery_date } = groupedDatas[0];
    let supplyPrice = 0;
    let vatPrice = 0;
    let sumPrice = 0;
    let quantity = 0;
    let dcQuantity = 0;
    groupedDatas.forEach((item) => {
      if (item.discount_rate > 0) {
        supplyPrice += item.discounted_supply;
        vatPrice += item.discounted_vat;
        sumPrice += item.price_sum;
        quantity += item.product_quantity;
        dcQuantity += item.product_quantity;
      } else {
        supplyPrice += item.product_supply_price;
        vatPrice += item.product_VAT_price;
        sumPrice += item.price_sum;
        quantity += item.product_quantity;
      }
    });
    return {
      deliveryDate: delivery_date,
      supplyPrice,
      vatPrice,
      sumPrice,
      quantity,
      dcQuantity,
    };
  });
  return combinedDatas;
};
const combineDatasByMonthly = (datas) => {
  const combinedDatas = datas.map((groupedDatas) => {
    const deliveryDate = new Date(groupedDatas[0].delivery_date);
    const yearMonthDate = `${deliveryDate.getFullYear()}-${
      deliveryDate.getMonth() + 1
    }`;
    let supplyPrice = 0;
    let vatPrice = 0;
    let sumPrice = 0;
    let quantity = 0;
    let dcQuantity = 0;
    groupedDatas.forEach((item) => {
      if (item.discount_rate > 0) {
        supplyPrice += item.discounted_supply;
        vatPrice += item.discounted_vat;
        sumPrice += item.price_sum;
        quantity += item.product_quantity;
        dcQuantity += item.product_quantity;
      } else {
        supplyPrice += item.product_supply_price;
        vatPrice += item.product_VAT_price;
        sumPrice += item.price_sum;
        quantity += item.product_quantity;
      }
    });
    return {
      yearMonthDate,
      supplyPrice,
      vatPrice,
      sumPrice,
      quantity,
      dcQuantity,
    };
  });
  return combinedDatas;
};
const combineDatasByOrder = (datas) => {
  const combinedDatas = datas.map((groupedDatas) => {
    const {
      delivery_date,
      first_sale,
      account_code,
      account_name,
      business_number,
      region,
      briefs,
      memo,
      mobile,
      search,
      payment_terms,
      deal_type,
      reg_date,
    } = groupedDatas[0];
    let supplyPrice = 0;
    let vatPrice = 0;
    let sumPrice = 0;
    let quantity = 0;
    let dcQuantity = 0;
    groupedDatas.forEach((item) => {
      if (item.discount_rate > 0) {
        supplyPrice += item.discounted_supply;
        vatPrice += item.discounted_vat;
        sumPrice += item.price_sum;
        quantity += item.product_quantity;
        dcQuantity += item.product_quantity;
      } else {
        supplyPrice += item.product_supply_price;
        vatPrice += item.product_VAT_price;
        sumPrice += item.price_sum;
        quantity += item.product_quantity;
      }
    });
    return {
      deliveryDate: delivery_date,
      firstSale: first_sale,
      accountCode: account_code,
      accountName: account_name,
      businessNumber: business_number,
      region,
      briefs,
      memo,
      mobile,
      search,
      paymentTerms: payment_terms,
      dealType: deal_type,
      regDate: reg_date,
      supplyPrice,
      vatPrice,
      sumPrice,
      quantity,
      dcQuantity,
    };
  });
  return combinedDatas;
};
const combineDatasByProduct = (datas) => {
  const combinedDatas = datas.map((groupedDatas) => {
    const { product_code, product_name } = groupedDatas[0];
    let supplyPrice = 0;
    let vatPrice = 0;
    let sumPrice = 0;
    let quantity = 0;
    let dcQuantity = 0;
    groupedDatas.forEach((item) => {
      if (item.discount_rate > 0) {
        supplyPrice += item.discounted_supply;
        vatPrice += item.discounted_vat;
        sumPrice += item.price_sum;
        quantity += item.product_quantity;
        dcQuantity += item.product_quantity;
      } else {
        supplyPrice += item.product_supply_price;
        vatPrice += item.product_VAT_price;
        sumPrice += item.price_sum;
        quantity += item.product_quantity;
      }
    });
    return {
      productName: product_name,
      productCode: product_code,
      supplyPrice,
      vatPrice,
      sumPrice,
      quantity,
      dcQuantity,
    };
  });
  return combinedDatas;
};
const combineDatasByAccount = (datas) => {
  const combinedDatas = datas.map((groupedDatas) => {
    const {
      account_code,
      account_name,
      first_sale,
      business_number,
      region,
      briefs,
      mobile,
      search,
      product_name,
    } = groupedDatas[0];
    let supplyPrice = 0;
    let vatPrice = 0;
    let sumPrice = 0;
    let quantity = 0;
    let dcQuantity = 0;
    groupedDatas.forEach((item) => {
      if (item.discount_rate > 0) {
        supplyPrice += item.discounted_supply;
        vatPrice += item.discounted_vat;
        sumPrice += item.price_sum;
        quantity += item.product_quantity;
        dcQuantity += item.product_quantity;
      } else {
        supplyPrice += item.product_supply_price;
        vatPrice += item.product_VAT_price;
        sumPrice += item.price_sum;
        quantity += item.product_quantity;
      }
    });
    return {
      accountCode: account_code,
      accountName: account_name,
      firstSale: first_sale,
      businessNumber: business_number,
      productName: product_name,
      region,
      briefs,
      mobile,
      search,
      supplyPrice,
      vatPrice,
      sumPrice,
      quantity,
      dcQuantity,
    };
  });
  return combinedDatas;
};
const combineDatasByadmin = (datas) => {
  const combinedDatas = datas.map((groupedDatas) => {
    const { admin_code } = groupedDatas[0];
    let supplyPrice = 0;
    let vatPrice = 0;
    let sumPrice = 0;
    let quantity = 0;
    let dcQuantity = 0;
    groupedDatas.forEach((item) => {
      if (item.discount_rate > 0) {
        supplyPrice += item.discounted_supply;
        vatPrice += item.discounted_vat;
        sumPrice += item.price_sum;
        quantity += item.product_quantity;
        dcQuantity += item.product_quantity;
      } else {
        supplyPrice += item.product_supply_price;
        vatPrice += item.product_VAT_price;
        sumPrice += item.price_sum;
        quantity += item.product_quantity;
      }
    });
    return {
      adminCode: admin_code,
      supplyPrice,
      vatPrice,
      sumPrice,
      quantity,
      dcQuantity,
    };
  });
  return combinedDatas;
};

const resultDatasByLine = (datas) => {
  const resultDatas = datas.map((data) => {
    const {
      sales_date,
      delivery_date,
      first_sale,
      account_code,
      business_number,
      account_name,
      region,
      warehouse_code,
      warehouse_name,
      briefs,
      memo,
      mobile,
      search,
      payment_terms,
      deal_type,
      reg_date,
      product_name,
    } = data;
    const supplyPrice =
      data.discount_rate > 0
        ? data.discounted_supply
        : data.product_supply_price;
    const vatPrice =
      data.discount_rate > 0 ? data.discounted_vat : data.product_VAT_price;
    const sumPrice = data.price_sum;
    const quantity = data.product_quantity;
    const dcQuantity = data.discount_rate > 0 ? data.product_quantity : 0;
    return {
      salesDate: sales_date,
      deliveryDate: delivery_date,
      firstSale: first_sale,
      accountCode: account_code,
      accountName: account_name,
      businessNumber: business_number,
      region,
      warehouseCode: warehouse_code,
      warehouseName: warehouse_name,
      briefs,
      memo,
      mobile,
      search,
      paymentTerms: payment_terms,
      dealType: deal_type,
      regDate: reg_date,
      supplyPrice,
      vatPrice,
      sumPrice,
      quantity,
      dcQuantity,
      productName: product_name,
    };
  });
  return resultDatas;
};

const resultDatasByTaxInvoice = (datas) => {
  // 날짜 변환 함수(20240203)
  const formatDate = (date) => {
    return dayjs(date).format('YYYYMMDD');
  };

  // 현금으로 필터링
  const filteredDatasByCash = datas.filter(
    (data) => data.payment_terms === '현금(세금계산서)'
  );

  // 데이터 포맷
  const formattedDatas = filteredDatasByCash.map((data) => {
    const {
      delivery_date,
      business_number,
      account_name,
      reg_date,
      product_name,
      sales_date,
      representative,
      conditions,
      category,
      email,
      address,
      product_quantity,
      product_price,
    } = data;
    const supplyPrice =
      data.discount_rate > 0
        ? data.discounted_supply
        : data.product_supply_price;
    const vatPrice =
      data.discount_rate > 0 ? data.discounted_vat : data.product_VAT_price;
    const sumPrice = data.price_sum;
    const formattedSalesDate = formatDate(sales_date);
    const formattedDeliveryDate = formatDate(delivery_date);
    const formattedCurrentDate = formatDate(dayjs());
    return {
      accountName: account_name,
      businessNumber: business_number,
      regDate: reg_date,
      supplyPrice,
      vatPrice,
      sumPrice,
      productName: product_name,
      salesDate: formattedSalesDate,
      currentDate: formattedCurrentDate,
      deliveryDate: formattedDeliveryDate,
      representative,
      conditions,
      category,
      email,
      address,
      price: product_price,
      quantity: product_quantity,
    };
  });

  // 거래처로 정렬
  const sortedDatasByAccount = formattedDatas.sort(
    (a, b) => a.accountName - b.accountName
  );

  // 0원인 경우 필터링
  const filteredDatasByFree = sortedDatasByAccount.filter(
    (data) => data.sumPrice > 0
  );

  // 같은 거래처인 경우 isDuplicatedAcct를 true로 줌.
  const resultDatas = [];
  for (let i = 0; i < filteredDatasByFree.length; i += 1) {
    const isFirstData = i === 0;
    const currentData = filteredDatasByFree[i];
    const { sumPrice, supplyPrice, vatPrice } = currentData;
    if (isFirstData) {
      resultDatas.push({
        ...currentData,
        isDuplicatedAcct: false,
        totalSumPrice: sumPrice,
        totalSupplyPrice: supplyPrice,
        totalVatPrice: vatPrice,
      });
    } else {
      const isEqualAccount =
        resultDatas[i - 1].accountName === currentData.accountName;
      if (isEqualAccount) {
        const idxOfEqualAccouont = resultDatas.findIndex(
          (item) => item.accountName === currentData.accountName
        );
        resultDatas[idxOfEqualAccouont].totalSumPrice += sumPrice;
        resultDatas[idxOfEqualAccouont].totalSupplyPrice += supplyPrice;
        resultDatas[idxOfEqualAccouont].totalVatPrice += vatPrice;
        resultDatas.push({ ...currentData, isDuplicatedAcct: true });
      } else {
        resultDatas.push({
          ...currentData,
          isDuplicatedAcct: false,
          totalSumPrice: sumPrice,
          totalSupplyPrice: supplyPrice,
          totalVatPrice: vatPrice,
        });
      }
    }
  }

  return resultDatas;
};

const getSalesStatusDatasByCategory = () => {
  // 라인별
  const getSalesStatusDatasByLine = (filteredDatas) => {
    const result = resultDatasByLine(filteredDatas);

    return result;
  };
  // 시간별
  const getSalesStatusDatasByHourly = (filteredDatas) => {
    const groupedDatas = groupDatasByHourly(filteredDatas);
    const combinedDatas = combineDatasByHourly(groupedDatas);
    const result = combinedDatas.sort(
      (a, b) => new Date(a.sales_date) - new Date(b.sales_date)
    );

    return result;
  };
  // 일별
  const getSalesStatusDatasByDaily = (filteredDatas) => {
    const groupedDatas = groupDatasByDaily(filteredDatas);
    const combinedDatas = combineDatasByDaily(groupedDatas);
    const result = combinedDatas.sort(
      (a, b) => new Date(a.deliveryDate) - new Date(b.deliveryDate)
    );

    return result;
  };
  // 월별
  const getSalesStatusDatasByMonthly = (filteredDatas) => {
    const groupedDatas = groupDatasByMonthly(filteredDatas);
    const combinedDatas = combineDatasByMonthly(groupedDatas);
    const result = combinedDatas.sort(
      (a, b) => new Date(a.yearMonthDate) - new Date(b.yearMonthDate)
    );

    return result;
  };
  // 주문서별
  const getSalesStatusDatasByOrder = (filteredDatas) => {
    const groupedDatas = groupDatasByOrder(filteredDatas);
    const combinedDatas = combineDatasByOrder(groupedDatas);
    const result = combinedDatas.sort((a, b) => a.regDate - b.regDate);

    return result;
  };
  // 품목별
  const getSalesStatusDatasByProduct = (filteredDatas) => {
    const groupedDatas = groupDatasByProduct(filteredDatas);
    const combinedDatas = combineDatasByProduct(groupedDatas);
    const result = combinedDatas;

    return result;
  };
  // 거래처별
  const getSalesStatusDatasByAccount = (filteredDatas) => {
    const groupedDatas = groupDatasByAccount(filteredDatas);
    const combinedDatas = combineDatasByAccount(groupedDatas);
    const result = combinedDatas;

    return result;
  };
  // 담당자별
  const getSalesStatusDatasByManager = (filteredDatas) => {
    const groupedDatas = groupDatasByAdmin(filteredDatas);
    const combinedDatas = combineDatasByadmin(groupedDatas);
    const result = combinedDatas;

    return result;
  };
  // 세금계산서 출력용
  const getSalesStatusDatasByTaxInvoice = (filteredDatas) => {
    const result = resultDatasByTaxInvoice(filteredDatas);
    return result;
  };
  return {
    getSalesStatusDatasByLine,
    getSalesStatusDatasByHourly,
    getSalesStatusDatasByDaily,
    getSalesStatusDatasByMonthly,
    getSalesStatusDatasByOrder,
    getSalesStatusDatasByProduct,
    getSalesStatusDatasByAccount,
    getSalesStatusDatasByManager,
    getSalesStatusDatasByTaxInvoice,
  };
};

export default getSalesStatusDatasByCategory;
