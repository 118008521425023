import React, { useEffect, useState } from 'react';
import { ReactComponent as SouthKorea } from '@svg-maps/south-korea/south-korea.svg';
import { Select, Button, Space } from 'antd';
import {
  ArrowRightOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import admDist from '../../db/admDist';
import { DARK_MODE_BACKGROUND, LIGHT_MODE_BACKGROUND } from '../../constants';
import RegionLabelBox from './RegionLabelBox';

const NationalMap = (props) => {
  const { darkMode, selectedValue, setSelectedValue } = props;

  const [selectOptions, setSelectOptions] = useState({
    regions: [],
    cities: [],
  });

  useEffect(() => {
    const regions = [];
    for (const regionAndCity of admDist) {
      const prevRegion = regions[regions.length - 1];
      const currentRegion = regionAndCity.split(' ')[0];
      if (prevRegion !== currentRegion) {
        regions.push(currentRegion);
      }
    }
    const formattedRegions = regions.map((region) => ({
      label: region,
      value: region,
    }));
    setSelectOptions((prev) => ({ ...prev, regions: formattedRegions }));
  }, []);

  useEffect(() => {
    const cities = admDist.filter((regionAndCity) => {
      const selectedRegion = selectedValue.region;
      const currentRegion = regionAndCity.split(' ')[0];
      const isSameRegion = selectedRegion === currentRegion;
      return isSameRegion;
    });
    const formattedCities = cities.map((regionAndCity) => {
      const city = regionAndCity.split(' ')[1];
      return { label: city, value: city };
    });
    const selectedCity = formattedCities[0].value || '선택없음';
    setSelectOptions((prev) => ({ ...prev, cities: formattedCities }));
    setSelectedValue((prev) => ({ ...prev, city: selectedCity }));
  }, [selectedValue.region]);

  const handleSelectRegion = (region) => {
    setSelectedValue((prev) => ({ ...prev, region }));
  };

  const handleSelectCity = (city) => {
    setSelectedValue((prev) => ({ ...prev, city }));
  };

  const handleClickLabelBox = (region) => {
    setSelectedValue((prev) => ({ ...prev, region }));
  };

  return (
    <div
      style={{
        position: 'relative',
        padding: '20px 30px',
        // border: '1px solid #efefef',
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '20px',
      }}
    >
      {/*
      <Space.Compact
        size="small"
        style={{
          position: 'absolute',
          top: '20px',
          left: '30px',
        }}
      >
        <Button
          style={{
            color: 'gray',
            border: darkMode ? 'none' : '1px solid lightgray',
            backgroundColor: darkMode
              ? DARK_MODE_BACKGROUND
              : LIGHT_MODE_BACKGROUND,
          }}
        >
          <LeftOutlined />
        </Button>
        <Button
          style={{
            color: 'gray',
            border: darkMode ? 'none' : '1px solid lightgray',
            backgroundColor: darkMode
              ? DARK_MODE_BACKGROUND
              : LIGHT_MODE_BACKGROUND,
          }}
        >
          <RightOutlined />
        </Button>
      </Space.Compact>
       */}
      <div
        style={{
          position: 'absolute',
          top: '10px',
          right: '0px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Select
          bordered={false}
          size="large"
          suffixIcon={null}
          style={{
            textAlign: 'center',
            width: '120px',
            color: 'gray',
            backgroundColor: darkMode
              ? DARK_MODE_BACKGROUND
              : LIGHT_MODE_BACKGROUND,
          }}
          options={selectOptions.regions}
          value={selectedValue.region}
          onSelect={(e) => handleSelectRegion(e)}
        />
        <RightOutlined />
        <Select
          bordered={false}
          size="large"
          suffixIcon={null}
          style={{
            textAlign: 'center',
            width: '120px',
            color: 'gray',
            backgroundColor: darkMode
              ? DARK_MODE_BACKGROUND
              : LIGHT_MODE_BACKGROUND,
          }}
          options={selectOptions.cities}
          value={selectedValue.city}
          onSelect={(e) => handleSelectCity(e)}
        />
      </div>
      <SouthKorea style={{ maxWidth: '65.5%' }} className={['national-map']} />
      {/*
      <div style={{ fontSize: '9px', fontWeight: 'bold' }}>
        <RegionLabel top="21.5%" left="37%">
          서울
        </RegionLabel>
        <RegionLabel top="18.5%" left="33.5%">
          경기도
        </RegionLabel>
        <RegionLabel top="23.5%" left="28.5%">
          인천
        </RegionLabel>
        <RegionLabel top="18.5%" left="50.5%">
          강원도
        </RegionLabel>
        <RegionLabel top="33.5%" left="43.5%">
          충청북도
        </RegionLabel>
        <RegionLabel top="38.5%" left="39.5%">
          세종
        </RegionLabel>
        <RegionLabel top="42.5%" left="40.5%">
          대전
        </RegionLabel>
        <RegionLabel top="35.5%" left="33%">
          충청남도
        </RegionLabel>
        <RegionLabel top="40.5%" left="52.5%">
          경상북도
        </RegionLabel>
        <RegionLabel top="51.5%" left="53%">
          대구
        </RegionLabel>
        <RegionLabel top="59.5%" left="49.5%">
          경상남도
        </RegionLabel>
        <RegionLabel top="56.5%" left="59.5%">
          울산
        </RegionLabel>
        <RegionLabel top="63.5%" left="56.5%">
          부산
        </RegionLabel>
        <RegionLabel top="53.5%" left="37.5%">
          전라북도
        </RegionLabel>
        <RegionLabel top="67.5%" left="35%">
          전라남도
        </RegionLabel>
        <RegionLabel top="63%" left="35.5%">
          광주
        </RegionLabel>
        <RegionLabel top="92.5%" left="32%">
          제주도
        </RegionLabel>
      </div>
      */}
      <div>
        <RegionLabelBox
          top="15.5%"
          left="50%"
          darkMode={darkMode}
          label="강원도"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="강원도"
        />
        <RegionLabelBox
          top="28.5%"
          left="54%"
          darkMode={darkMode}
          label="경상북도"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="경상북도"
        />
        <RegionLabelBox
          top="41.5%"
          left="50%"
          darkMode={darkMode}
          label="대구"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="대구광역시"
        />
        <RegionLabelBox
          top="41.4%"
          left="69.5%"
          darkMode={darkMode}
          label="울산"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="울산광역시"
        />
        <RegionLabelBox
          top="54.5%"
          left="40%"
          darkMode={darkMode}
          label="경상남도"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="경상남도"
        />
        <RegionLabelBox
          top="54.5%"
          left="59.5%"
          darkMode={darkMode}
          label="부산"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="부산광역시"
        />
        <RegionLabelBox
          top="2.5%"
          left="20.5%"
          darkMode={darkMode}
          label="경기도"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="경기도"
        />
        <RegionLabelBox
          top="15.5%"
          left="30.5%"
          darkMode={darkMode}
          label="서울"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="서울특별시"
        />
        <RegionLabelBox
          top="15.5%"
          left="11%"
          darkMode={darkMode}
          label="인천"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="인천광역시"
        />
        <RegionLabelBox
          top="28.5%"
          left="34.5%"
          darkMode={darkMode}
          label="충청북도"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="충청북도"
        />
        <RegionLabelBox
          top="28.5%"
          left="15%"
          darkMode={darkMode}
          label="충청남도"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="충청남도"
        />
        <RegionLabelBox
          top="41.5%"
          left="30.5%"
          darkMode={darkMode}
          label="대전"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="대전광역시"
        />
        <RegionLabelBox
          top="41.5%"
          left="11%"
          darkMode={darkMode}
          label="세종"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="세종특별자치시"
        />
        <RegionLabelBox
          top="54.5%"
          left="20.5%"
          darkMode={darkMode}
          label="전라북도"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="전라북도"
        />
        <RegionLabelBox
          top="67.5%"
          left="17.5%"
          darkMode={darkMode}
          label="광주"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="광주광역시"
        />
        <RegionLabelBox
          top="67.5%"
          left="37%"
          darkMode={darkMode}
          label="전라남도"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="전라남도"
        />
        <RegionLabelBox
          top="84.5%"
          left="23%"
          darkMode={darkMode}
          label="제주도"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="제주특별자치도"
        />
        <RegionLabelBox
          top="86.5%"
          left="80%"
          darkMode={darkMode}
          label="해외"
          point={0}
          handleClickLabelBox={handleClickLabelBox}
          region="해외"
        />
      </div>
    </div>
  );
};

export default NationalMap;

NationalMap.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  selectedValue: PropTypes.instanceOf(Object).isRequired,
  setSelectedValue: PropTypes.func.isRequired,
};

const RegionLabel = styled.div`
  position: absolute;
  pointer-events: none;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
`;
