import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../axios';
import useSupabase from '../../../serverConfig';

import supabase from '../../../supabase';
import generateMatchQueryString from '../../../utils/generateMatchQueryString';

const initialState = {
  isLoadingReadMatchLteData: false,
  isErrorReadMatchLteData: false,
  isSuccessReadMatchLteData: false,
};

const asyncReadMatchLteDataFetch = createAsyncThunk(
  'readMatchLteDataSlice/asyncReadMatchLteDataFetch',
  async (values, { rejectWithValue }) => {
    // values의 필수 값 => table
    const { table, match, lteKey, lteValue, order, limit } = values;
    try {
      const { data } = useSupabase
        ? await supabase
            .from(table)
            .select('*')
            .match(match)
            .lte(lteKey, lteValue)
            .order(order, { ascending: false })
            .limit(limit)
        : await axiosInstance.get(
            `/api/dynamic-crud/${table}/match-limit-lte/${limit}?order=${order}&lteKey=${lteKey}&lteValue=${lteValue}${generateMatchQueryString(
              match
            )}`
          );

      console.log(data);

      const result = data.filter((item) => item.state !== 9);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const readMatchLteDataSlice = createSlice({
  name: 'readMatchLteData',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncReadMatchLteDataFetch.pending, (state) => {
      state.isLoadingReadMatchLteData = true;
      state.isSuccessReadMatchLteData = false;
      state.isErrorReadMatchLteData = false;
    });
    builder.addCase(asyncReadMatchLteDataFetch.fulfilled, (state) => {
      state.isLoadingReadMatchLteData = false;
      state.isSuccessReadMatchLteData = true;
      state.isErrorReadMatchLteData = false;
    });
    builder.addCase(asyncReadMatchLteDataFetch.rejected, (state) => {
      state.isLoadingReadMatchLteData = false;
      state.isSuccessReadMatchLteData = false;
      state.isErrorReadMatchLteData = true;
    });
  },
});

export default readMatchLteDataSlice.reducer;
export { asyncReadMatchLteDataFetch };
