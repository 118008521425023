import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { darkModeColors, lightModeColors } from '../constants';
import { asyncReadDataFetch } from '../redux/modules/shared/readData';

const useGetInventoryForecastData = () => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode);

  const colors = darkMode ? darkModeColors : lightModeColors;

  const [forecastData, setForecastData] = useState({});
  const [forecastUpdatedAt, setForecastUpdatedAt] = useState('');

  useEffect(() => {
    const fetchForecastData = async () => {
      const response = await dispatch(
        asyncReadDataFetch({ table: 'hantong_batch_daily_sales_quantity' })
      ).unwrap();
      return response;
    };

    const fetchInventoryStatusData = async () => {
      const response = await dispatch(
        asyncReadDataFetch({ table: 'v_inventory_status' })
      ).unwrap();
      return response;
    };

    const getStackQuantity = ({ inventoryStatusData, productCode }) => {
      return inventoryStatusData.filter((data) => data.code === productCode)[0]
        ?.stack_quantity;
    };

    const getAvailableDays = ({ stackQuantity, dailySalesQuantity }) => {
      return Math.round(stackQuantity / dailySalesQuantity);
    };

    const getTextColor = (availableDays) => {
      return availableDays < 10 ? colors.armsone_red : 'gray';
    };

    const getUpToDate = () => {};

    const processForcastData = async () => {
      const fetchedForecastData = await fetchForecastData();
      const fetchedInventoryStatusData = await fetchInventoryStatusData();

      const newForecastData = {};

      for (const data of fetchedForecastData) {
        const {
          product_code: productCode,
          product_name: productName,
          daily_sales_quantity: dailySalesQuantity,
        } = data;

        const stackQuantity = getStackQuantity({
          inventoryStatusData: fetchedInventoryStatusData,
          productCode,
        });

        if (stackQuantity) {
          const availableDays = getAvailableDays({
            stackQuantity,
            dailySalesQuantity,
          });
          const textColor = getTextColor(availableDays);

          Object.assign(newForecastData, {
            [productCode]: {
              productName,
              availableDays,
              stackQuantity,
              textColor,
            },
          });
        }
      }

      setForecastData(newForecastData);
      setForecastUpdatedAt(fetchedForecastData[0]?.mod_date);
    };

    processForcastData();
  }, []);

  return [forecastData, forecastUpdatedAt];
};

export default useGetInventoryForecastData;
