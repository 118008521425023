// 신규상담 경로
export const ROUTE_INTERNET = '인터넷광고';
export const ROUTE_FRIEND = '지인';
export const ROUTE_COMPETITOR_DELIVERY = '타업체배달차량';
export const ROUTE_PAST_USER = '과거사용자';
export const ROUTE_OTHER = '기타';
// 신규상담 상담방식
export const COUNSELLING_TYPE_PHONE = '유선전화';
export const COUNSELLING_TYPE_DASHBOARD = '게시판';
export const COUNSELLING_TYPE_TALKTALK = '네이버톡톡';
export const COUNSELLING_TYPE_MESSAGE = '문자';
export const COUNSELLING_TYPE_MOBILE = '휴대폰';
export const COUNSELLING_TYPE_QNA = '네이버QnA';
export const COUNSELLING_TYPE_OTHER = '기타';
// 신규상담 구매의향
export const COUNSELLING_PURCHASE_PLAN = '구매예정';
export const COUNSELLING_PURCHASE_CONSIDERING = '고민중';
export const COUNSELLING_PURCHASE_HOLD = '보류';
export const COUNSELLING_PURCHASE_OTHER = '기타';

// 신규상담 게시판 검색옵션
export const COUNSELLING_SEARCH_ACCOUNT = '업체명';
export const COUNSELLING_SEARCH_CONTENT = '내용';
export const COUNSELLING_SEARCH_DATE = '상담일자';
export const COUNSELLING_SEARCH_CONTACT = '연락처';

// 신규상담 반응정도
export const COUNSELLING_REACTION_GOOD = '좋음';
export const COUNSELLING_REACTION_NORMAL = '보통';
export const COUNSELLING_REACTION_BAD = '나쁨';

// 다크모드 색상 관련
export const DARK_MODE_BACKGROUND = '#212332';
export const DARK_MODE_FONT_COLOR = 'white';
export const LIGHT_MODE_BACKGROUND = 'white';
export const LIGHT_MODE_FONT_COLOR = 'black';
export const DARK_MODE_LAYOUT_COLOR = 'black';
export const LIGHT_MODE_LAYOUT_COLOR = '#efefef';

export const borderRadiuses = {
  primary: '15px',
};

export const darkModeColors = {
  text_color: 'gray',
  danger_bg: '#DC2265AA',
  safety_bg: '#1677ff88',
  trophy_bg: '#ffffffCC',
  trophy_icon: 'gray',
  chart_percent_blue: '#1677ff',
  chart_percent_red: '#DC2265FF',
  chart_first_day_border: '#8A0055FF',
  chart_first_day_bg: '#8A0055FF',
  chart_avg_border: '#313A42',
  chart_avg_bg: '#ffffffff',
  chart_border_1: '#313A42',
  chart_border_2: '#313A42',
  chart_bg_1: '#484A57FF',
  chart_bg_2: '#484A57FF',
  chart_bg_3: '#000000FF',
  // data-calendar : DataCell
  holiday_text: '#DC2265FF',
  cell_level_1: '#a8071aff',
  cell_level_2: '#ff4d4fAA',
  cell_level_3: '#ffec3d77',
  cell_level_4: '#4096ffAA',
  cell_level_5: '#003eb3ff',
  // data-calendar : SalesLevelChart
  sales_level_1: '#a8071aff',
  sales_level_2: '#ff4d4fff',
  sales_level_3: '#ad8b00ff',
  sales_level_4: '#4096ffff',
  sales_level_5: '#003eb3ff',
  // data-calendar : SalesByProductChart
  sales_index_0: '#5b8c00',
  sales_index_1: '#ad4e00',
  sales_index_2: '#006d75',
  sales_index_3: '#391085',
  sales_index_4: '#9e1068',
  sales_index_default: 'gray',
  // data-calendar : ThirtyDaysHourlyChart
  chart_tdh_1_border: '#313A42',
  chart_tdh_1_bg: '#484A57FF',
  chart_tdh_2_border: '#ad4e00',
  chart_tdh_2_d_bg: '#e6f4ff',
  // Armsone Style
  armsone_bg: '#',
  armsone_primary: '#',
  armsone_secondary: '#',
  armsone_text: '#',
  armsone_text_200: '#',
  armsone_grey: '#949495AF',
  armsone_deep_grey: '#484A57FF',
  armsone_blue: '#4181EFAF',
  armsone_green: '#48A44DAF',
  armsone_orange: '#C87B11AF',
  armsone_pink: '#C2386AAF',
  armsone_red: '#E94E4FAF',
  sales_chart_bar_bg: {
    separated: {
      jan_month: {
        // very deep grey
        online_bar: '#494949',
        // black
        offline_bar: '#282828',
      },
      other_month: {
        // grey
        online_bar: '#8e8e8e',
        // deep grey
        offline_bar: '#6b6b6b',
      },
    },
    combined: {
      // light grey
      jan_month: {
        bar: '#9e9e9e',
      },
      //  very light grey
      other_month: {
        bar: '#c1c1c1',
      },
    },
  },
  monthly_chart_store_bg: '#343131AF',
};

export const lightModeColors = {
  text_color: 'gray',
  danger_bg: 'red',
  safety_bg: 'blue',
  trophy_bg: 'white',
  trophy_icon: 'gray',
  chart_percent_blue: '#1677ff',
  chart_percent_red: '#f5222d',
  chart_first_day_border: '#eb2f96',
  chart_first_day_bg: '#eb2f96',
  chart_avg_border: 'gray',
  chart_avg_bg: '#fff1f0',
  chart_border_1: '#5b8c00',
  chart_border_2: '#006d75',
  chart_bg_1: '#ffa5a5',
  chart_bg_2: '#ffa5a5',
  chart_bg_3: '#ffc2bf',
  // data-calendar : DataCell
  holiday_text: 'red',
  cell_level_1: '#a8071aff',
  cell_level_2: '#ff4d4fff',
  cell_level_3: '#ad8b00ff',
  cell_level_4: '#4096ffff',
  cell_level_5: '#003eb3ff',
  // data-calendar : SalesLevelChart
  sales_level_1: '#a8071aff',
  sales_level_2: '#ff4d4fff',
  sales_level_3: '#ad8b00ff',
  sales_level_4: '#4096ffff',
  sales_level_5: '#003eb3ff',
  // data-calendar : SalesByProductChart
  sales_index_0: '#5b8c00',
  sales_index_1: '#ad4e00',
  sales_index_2: '#006d75',
  sales_index_3: '#391085',
  sales_index_4: '#9e1068',
  sales_index_default: 'gray',
  // data-calendar : ThirtyDaysHourlyChart
  chart_tdh_1_border: '#ad4e00',
  chart_tdh_1_bg: '#e6f4ff',
  chart_tdh_2_border: '#391085',
  chart_tdh_2_d_bg: '#fff1f0',
  // Armsone Style
  armsone_bg: '#',
  armsone_primary: '#',
  armsone_secondary: '#',
  armsone_text: '#',
  armsone_text_200: '#',
  armsone_grey: '#949495AF',
  armsone_deep_grey: '#484A57FF',
  armsone_blue: '#4181EFAF',
  armsone_green: '#48A44DAF',
  armsone_orange: '#C87B11AF',
  armsone_pink: '#C2386AAF',
  armsone_red: '#E94E4FAF',
  sales_chart_bar_bg: {
    separated: {
      jan_month: {
        // very deep pink
        online_bar: '#ff3535',
        // almost red
        offline_bar: '#ff1111',
      },
      other_month: {
        // pink
        online_bar: '#ff8282',
        // deep pink
        offline_bar: '#ff5454',
      },
    },
    combined: {
      // light pink
      jan_month: {
        bar: '#ffadad',
      },
      //  very light pink
      other_month: {
        bar: '#ffdbdb',
      },
    },
  },
  monthly_chart_store_bg: '#C87B11AF',
};

// 재고변동 원인 관련
export const inventoryCouse = {
  state_create: '등록',
  state_update: '수정',
  state_delete: '삭제',

  of_change_purchase: '구매',
  of_change_sales: '판매',
  of_change_adjustment: '재고조정',
};

// 날짜 타입 (날짜 버튼)
export const dateTypes = {
  today: 'today', // 금일
  yesterday: 'yesterday', // 전일
  this_week: 'thisWeek', // 금주(~오늘)
  one_week_ago: 'oneWeekAgo', // 전주
  this_month: 'thisMonth', // 금월(~오늘)
  one_month_ago: 'oneMonthAgo', // 전월
  one_month_ago_and_this_month: 'oneMonthAgoAndThisMonth', // 전월+금월
};

// 사이드바 접는 유저 id
export const collapseSidebarConditionsByUser = {
  user_id_1: '640bfe25-137b-4f74-b21d-abcb162eeff8',
  user_id_2: '39d2a039-4034-40ac-acbf-e7d67e033384',
};

// 한통 봇 이름
export const botNames = {
  headOffice: 'HBot-0 : 장항',
  ceoHome: 'HBot-1 : 삼송',
  hapjeongOffice: 'HBot-2 : 인천',
};

// 품목코드(데이터달력 재고현황 데이터 key)
export const productCodes = {
  pp_01: 'PP01',
  pp_02: 'PP02',
  pp_03: 'PP03',
  pp_04: 'PP04',
  pp_05: 'PP05',
  pp_06: 'PP06',
  pp_11: 'PP11',
  epp_01: 'EPP01',
  epp_03: 'EPP03',
  epl_001: 'EPL001',
  epl_007: 'EPL007',
  eps_003: 'EPS003',
  eps_004: 'EPS004',
  eps_006: 'EPS006',
  pbox_03: 'PBOX03',
  pbox_04: 'PBOX04',
  seal_03: 'SEAL03',
};

// 테이블명
export const tables = {
  ACCOUNT: 'account',
  ACCOUNT_ADDRESS: 'account_address',
  ACCOUNT_GROUP: 'account_group',
  ACCOUNT_RESP: 'account_resp',
  CALENDAR_HOLIDAYS: 'calendar_holidays',
  CALENDAR_SALES_LEVEL: 'calendar_sales_level',
  COUNSELLING: 'counselling',
  COUNSELLING_COMMENT: 'counselling_comment',
  DISCOUNT_ACCOUNT_PRODUCT: 'discount_account_product',
  ECOUNT_SYNC_ERROR_RECORD: 'ecount_sync_error_record',
  ESTIMATE_SENT: 'estimate_sent',
  HANTONG_BATCH_DAILY_SALES_QUANTITY: 'hantong_batch_daily_sales_quantity',
  HANTONG_BOT_PING: 'hantong_bot_ping',
  HIDDEN_DATA: 'hidden_data',
  INVENTORY: 'inventory',
  INVENTORY_ERROR_BY_PAYAPP: 'inventory_error_by_payapp',
  INVENTORY_ORDER_SETTING: 'inventory_order_setting',
  INVENTORY_PRODUCT_SETUP: 'inventory_product_setup',
  INVENTORY_SAFETY_STOCK: 'inventory_safety_stock',
  INVENTORY_SEARCH_DEFAULT: 'inventory_search_default',
  MONTHLY_HEADCOUNT: 'monthly_headcount',
  ORDER: 'order',
  ORDER_PRODUCT: 'order_product',
  ORDER_SALE: 'order_sale',
  PRODUCT: 'product',
  PROFILES: 'profiles',
  PROPOSE_DATA: 'propose_data',
  PROPOSE_REJECT: 'propose_reject',
  PURCHASE: 'purchase',
  PURCHASE_ORDER: 'purchase_order',
  PURCHASE_PRODUCT: 'purchase_product',
  PURCHASE_ORDER_PRODUCT: 'purchase_order_product',
  RESULT: 'result',
  SALE: 'sale',
  SALE_PRODUCT: 'sale_product',
  SALES_TARGET: 'sales_target',
  SESSION_ERROR_RECORD: 'session_error_record',
  SYNC_ERROR_RECORD: 'sync_error_record',
  WAREHOUSE: 'warehouse',
};

// supabase 일 경우의 컬럼, 테이블 등의 키
export const supabaseModeKeys = {
  ORDER_OR_ORDERS: 'order',
  YEAR_MONTH_OR_YEAR_MONTH_TXT: 'year_month',
  ORDER_OR_ORDER_INDEX: 'order',
  DATE_OR_TARGET_DATE: 'date',
};

// dynamicCrud 일 경우의 컬럼, 테이블 등의 키
export const dynamicCrudModeKeys = {
  ORDER_OR_ORDERS: 'orders',
  YEAR_MONTH_OR_YEAR_MONTH_TXT: 'year_month_txt',
  ORDER_OR_ORDER_INDEX: 'order_index',
  DATE_OR_TARGET_DATE: 'target_date',
};
