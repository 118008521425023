import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SalesStatusForm from './SalesStatusForm';
import SalesStatusTable from './SalesStatusTable';
import getDateByBtn from '../../utils/getDateByBtn';
import { asyncReadRangeDataFetch } from '../../redux/modules/shared/readRangeData';
import shortenSpecificWord from '../../utils/shortenSpecificWord';
import getSalesStatusDatasByCategory from '../../utils/getSalesStatusDatasByCategory';
import filterBySpecificInputs from '../../utils/filterBySpecificInputs';
import TaxInvoiceExistenceIndicator from './TaxInvoiceExistenceIndicator';

const getTotalSalesSatusData = (salesStatusDatas) => {
  const totalSalesStatusData = salesStatusDatas.reduce(
    (acc, cur) => {
      acc.totalDcQuantity += cur.dcQuantity;
      acc.totalQuantity += cur.quantity;
      acc.totalSupply += cur.supplyPrice;
      acc.totalVat += cur.vatPrice;
      acc.totalSum += cur.sumPrice;
      acc.totalCount += 1;
      return acc;
    },
    {
      totalDcQuantity: 0,
      totalQuantity: 0,
      totalSupply: 0,
      totalVat: 0,
      totalSum: 0,
      totalCount: 0,
    }
  );
  return totalSalesStatusData;
};

const SalesStatusDatas = () => {
  // 필터링기능 외부함수
  const {
    filterByDealType,
    filterByAccount,
    filterByManager,
    filterByWarehouse,
    filterByProduct,
    filterByQuantity,
    filterByPrice,
    filterBySupplyPrice,
    filterByVatPrice,
  } = filterBySpecificInputs();
  // 구분별 데이터생성 외부함수
  const {
    getSalesStatusDatasByLine,
    getSalesStatusDatasByDaily,
    getSalesStatusDatasByMonthly,
    getSalesStatusDatasByOrder,
    getSalesStatusDatasByProduct,
    getSalesStatusDatasByAccount,
    getSalesStatusDatasByManager,
    getSalesStatusDatasByTaxInvoice,
  } = getSalesStatusDatasByCategory();
  // 특정단어 축약 외부함수
  const { shortenWarehouseName } = shortenSpecificWord();

  const dispatch = useDispatch();

  const [formInputs, setFormInputs] = useState({
    category: 'line',
    date: null,
    dealType: null,
    warehouse: [],
    account: [],
    product: [],
    manager: [],
    quantity: { min: null, max: null },
    price: { min: null, max: null },
    supplyPrice: { min: null, max: null },
    vatPrice: { min: null, max: null },
  });
  const [loading, setLoading] = useState({
    tableDatas: false,
    totalDatas: false,
  });
  const [salesStatusDatas, setSalesStatusDatas] = useState([]);
  const [totalSalesStatusData, setTotalSalesStatusData] = useState({
    totalDcQuantity: 0,
    totalQuantity: 0,
    totalSupply: 0,
    totalVat: 0,
    totalSum: 0,
    totalCount: 0,
  });

  // 필터링된 판매현황 데이터 생성
  const genSalesStatusDatas = async (isDateBtn, dateBtnVal) => {
    try {
      await setLoading({ tableDatas: true, totalDatas: true });
      // 기준일자 받기
      const beginAndEndDate = getDateByBtn(isDateBtn, dateBtnVal, formInputs);
      if (!beginAndEndDate) {
        setLoading({ tableDatas: false, totalDatas: false });
        return;
      }
      setFormInputs((prev) => ({ ...prev, date: beginAndEndDate }));
      // 기준일자로 필터링
      const filteredByDate = await dispatch(
        asyncReadRangeDataFetch({
          table:
            formInputs.category === 'taxInvoice'
              ? 'v_tax_invoice'
              : 'v_sales_status',
          column: 'delivery_date',
          startPt: beginAndEndDate.begin,
          endPt: beginAndEndDate.end,
        })
      )
        .unwrap()
        .then((res) => res);
      // view 테이블에서 미리 필터링하기
      // 판매완료된 상품만 필터링
      const filteredByDateAndSold = filteredByDate.filter(
        (data) => data.payment_status === '1'
      );
      // 거래유형으로 필터링
      const filteredByDealType = filterByDealType(
        formInputs.dealType,
        filteredByDateAndSold
      );
      // 창고로 필터링
      const filteredByWarehouse = filterByWarehouse(
        formInputs.warehouse,
        filteredByDealType
      );
      // 거래처로 필터링
      const filteredByAccount = filterByAccount(
        formInputs.account,
        filteredByWarehouse
      );
      // 담당자로 필터링
      const filteredByManager = filterByManager(
        formInputs.manager,
        filteredByAccount
      );
      // 품목으로 필터링
      const filteredByProduct = filterByProduct(
        formInputs.product,
        filteredByManager
      );
      // 수량으로 필터링
      const filteredByQuantity = filterByQuantity(
        formInputs.quantity,
        filteredByProduct
      );
      if (!filteredByQuantity) {
        alert('수량의 최소값과 최대값 중 하나만 입력하셨습니다.');
        setLoading({ tableDatas: false, totalDatas: false });
        return;
      }
      // 단가로 필터링
      const filteredByPrice = filterByPrice(
        formInputs.price,
        filteredByQuantity
      );
      if (!filteredByQuantity) {
        alert('단가의 최소값과 최대값 중 하나만 입력하셨습니다.');
        setLoading({ tableDatas: false, totalDatas: false });
        return;
      }
      // 공급가액으로 필터링
      const filteredBySupplyPrice = filterBySupplyPrice(
        formInputs.supplyPrice,
        filteredByPrice
      );
      if (!filteredBySupplyPrice) {
        alert('공급가액의 최소값과 최대값 중 하나만 입력하셨습니다.');
        setLoading({ tableDatas: false, totalDatas: false });
        return;
      }
      // 부가세로 필터링
      const filteredByVatPrice = filterByVatPrice(
        formInputs.vatPrice,
        filteredBySupplyPrice
      );
      if (!filteredByVatPrice) {
        alert('부가세의 최소값과 최대값 중 하나만 입력하셨습니다.');
        setLoading({ tableDatas: false, totalDatas: false });
        return;
      }
      const filteredDatas = filteredByVatPrice
        .sort((a, b) => new Date(a.sales_date) - new Date(b.sales_date))
        // 기타배송 필터
        .filter((data) => !data.product_code?.includes('COD'));

      // 구분(category)으로 데이터 생성
      let salesStatusDataArr = [];
      if (formInputs.category === 'line') {
        salesStatusDataArr = [...getSalesStatusDatasByLine(filteredDatas)];
      }
      if (formInputs.category === 'daily') {
        salesStatusDataArr = [...getSalesStatusDatasByDaily(filteredDatas)];
      }
      if (formInputs.category === 'monthly') {
        salesStatusDataArr = [...getSalesStatusDatasByMonthly(filteredDatas)];
      }
      if (formInputs.category === 'order') {
        salesStatusDataArr = [...getSalesStatusDatasByOrder(filteredDatas)];
      }
      if (formInputs.category === 'product') {
        salesStatusDataArr = [...getSalesStatusDatasByProduct(filteredDatas)];
      }
      if (formInputs.category === 'account') {
        salesStatusDataArr = [...getSalesStatusDatasByAccount(filteredDatas)];
      }
      if (formInputs.category === 'admin') {
        salesStatusDataArr = [...getSalesStatusDatasByManager(filteredDatas)];
      }
      if (formInputs.category === 'taxInvoice') {
        salesStatusDataArr = [
          ...getSalesStatusDatasByTaxInvoice(filteredDatas),
        ];
      }
      setSalesStatusDatas(salesStatusDataArr);
      await setLoading({ tableDatas: false, totalDatas: true });

      const newTotalSalesStatusData =
        getTotalSalesSatusData(salesStatusDataArr);
      setTotalSalesStatusData({ ...newTotalSalesStatusData });

      setLoading({ tableDatas: false, totalDatas: false });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  return (
    <div>
      <div
        style={{
          paddingBottom: '10px',
          fontSize: '16px',
          borderBottom: '1px solid #6d6d6d',
        }}
      >
        판매검색
      </div>
      <SalesStatusForm
        setFormInputs={setFormInputs}
        formInputs={formInputs}
        genSalesStatusDatas={genSalesStatusDatas}
        salesStatusDatas={salesStatusDatas}
        setSalesStatusDatas={setSalesStatusDatas}
        setTotalSalesStatusData={setTotalSalesStatusData}
      />
      {formInputs.category === 'taxInvoice' ? (
        <div
          style={{
            border: '1px solid #efefef',
            borderRadius: '20px',
            marginTop: '20px',
          }}
        >
          <TaxInvoiceExistenceIndicator
            isExistTaxInvoiceData={salesStatusDatas.length >= 1}
            isLoadingTaxInvoiceData={loading.tableDatas}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              paddingBottom: '10px',
              fontSize: '16px',
              marginTop: '50px',
              borderBottom: '1px solid gray',
            }}
          >
            판매현황
          </div>
          <SalesStatusTable
            loading={loading}
            date={formInputs.date}
            category={formInputs.category}
            salesStatusDatas={salesStatusDatas}
            setSalesStatusDatas={setSalesStatusDatas}
            totalSalesStatusData={totalSalesStatusData}
          />
        </>
      )}
    </div>
  );
};

export default SalesStatusDatas;
