import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: null,
  modalInfo: {
    modalTitle: '',
    resultStatus: '',
    resultTitle: '',
    resultSubTitle: '',
    buttonText: '',
  },
  dataInfo: {
    dataId: '',
    dataTable: '',
    datas: {},
  },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { type, modalInfo, dataInfo } = action.payload;
      state.type = type;
      state.modalInfo = modalInfo;
      state.dataInfo = dataInfo;
    },
    closeModal: () => {
      return initialState;
    },
  },
});

export default modalSlice.reducer;
export const { openModal, closeModal } = modalSlice.actions;
