import axiosInstance from '../axios';

const getAccountListByIds = async (postData) => {
  const url = 'api/account/list';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const getAllAccountsApi = async () => {
  const url = 'api/account/all';
  const response = await axiosInstance.get(url);
  return response.data;
};

const getAccountGroupApi = async () => {
  const url = 'api/account/group';
  const response = await axiosInstance.get(url);
  return response.data;
};

const getInvoiceByAccountApi = async (postData) => {
  const url = 'api/account/invoice';
  const response = await axiosInstance.post(url, postData);
  return response.data;
};

const getAccountInfoByCodeApi = async (postData) => {
  const { accountCode } = postData;
  const url = `api/account/info/${accountCode}`;
  const response = await axiosInstance.get(url);
  return response.data;
};

export {
  getAccountListByIds,
  getAllAccountsApi,
  getAccountGroupApi,
  getInvoiceByAccountApi,
  getAccountInfoByCodeApi,
};
