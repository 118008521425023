import { StockOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import DataHandler from '../components/DataHandler';

const Inventory = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <StockOutlined />,
        breadCrumbNav: ' 재고현황',
      },
    ],
    []
  );
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <DataHandler />
    </LayoutComponent>
  );
};

export default Inventory;
