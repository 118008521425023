import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const ResponseDateValue = (props) => {
  const { displayValueOrDash, counsellingId } = props;
  const dispatch = useDispatch();

  const [responseDate, setResponseDate] = useState(null);

  useEffect(() => {
    const getResponseDate = async () => {
      const comments = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'counselling_comment',
          eqKey: 'counselling_id',
          eqValue: counsellingId,
        })
      ).unwrap();

      const firstComment = comments.sort(
        (a, b) => new Date(a.reg_date) - new Date(b.reg_date)
      )[0];

      setResponseDate(
        displayValueOrDash(
          firstComment ? firstComment.reg_date.slice(0, 10) : firstComment
        )
      );
    };

    getResponseDate();
  }, [counsellingId]);

  return (
    <div style={{ fontSize: '11.5px', color: 'gray' }}>{responseDate}</div>
  );
};

export default ResponseDateValue;

ResponseDateValue.propTypes = {
  displayValueOrDash: PropTypes.func.isRequired,
  counsellingId: PropTypes.number.isRequired,
};
