import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  gap: 30px;
  padding: 32px;
  border-radius: 30px 0 30px 0px;
  box-shadow: 0px 5px 5px 0px gray;
  color: black;
`;

function AuthContainer(props) {
  const { children } = props;
  return <Container style={{ width: '500px' }}>{children}</Container>;
}

export default AuthContainer;

AuthContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
