import React from 'react';
import { useSelector } from 'react-redux';

import Avvvatar from '../../shared/components/Avvvatar';

function ProfileHeader() {
  const { userInfo } = useSelector((state) => state.signinUser);

  return (
    <>
      <div style={{ fontSize: '30px', fontWeight: 'bold' }}>
        {userInfo.username}님의 프로필
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        <Avvvatar username={userInfo.username} />
        <div style={{ color: 'gray', fontSize: '12px' }}>
          <p>가입일: {userInfo.reg_date}</p>
        </div>
      </div>
    </>
  );
}

export default ProfileHeader;
