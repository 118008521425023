import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import HiddenToggle from '../components/HiddenToggle';
import { asyncReadRangeDataFetch } from '../../redux/modules/shared/readRangeData';
import { getUserListApi } from '../../apis/authApi';

const Table = (props) => {
  const {
    table,
    tableColumns,
    createButtonText,
    createButtonModal,
    items,
    dateRange,
  } = props;
  const dispatch = useDispatch();
  const { isSuccessCreateData } = useSelector((state) => state.createData);
  const { isSuccessUpdateData } = useSelector((state) => state.updateData);
  const { isSuccessCreateUser } = useSelector((state) => state.createUser);

  const [tableDatas, setTableDatas] = useState([]);
  const [loading, setLoading] = useState(false);

  // 테이블에서 필터링된 리스트
  const [checkedTableColumns, setCheckedTableColumns] = useState([]);
  // 테이블에서 필터링된 columns
  const [filteredTableColumns, setFilteredTableColumns] = useState([]);
  // 테이블의 현재 정렬 상태
  const [isLatest, setIsLatest] = useState(true);

  // 데이터를 최신 순으로 정렬하는 함수
  const sortLatestDatas = useCallback(() => {
    const sortedDatas = tableDatas.sort((a, b) => {
      return new Date(b.reg_date) - new Date(a.reg_date);
    });
    setTableDatas(() => [...sortedDatas]);
    setIsLatest(true);
  }, [tableDatas]);

  // 데이터를 오래된 순으로 정렬하는 함수
  const sortOldestDatas = useCallback(() => {
    const sortedDatas = tableDatas.sort((a, b) => {
      return new Date(a.reg_date) - new Date(b.reg_date);
    });
    setTableDatas(() => [...sortedDatas]);
    setIsLatest(false);
  }, [tableDatas]);

  /*
  // 새로고침 시 데이터를 read하는 함수
  const handleGetTableDatas = useCallback(() => {
    let values = {};
    if (dataGenYr) {
      values = { table, dataGenYr };
    } else {
      values = { table };
    }
    dispatch(asyncReadDataFetch(values)).then(async (res) => {
      // tableDatas를 양식에 맞게 설정.
      const modifiedTableDatas = await res.payload.map((data) => {
        return {
          key: data.id,
          ...data,
        };
      });
      setTableDatas(() => {
        return [...modifiedTableDatas];
      });
      setIsLatest(true);
    });
  }, [dispatch, table, dataGenYr]);

  // 마운트 및 create, update, delete시 데이터를 read하는 함수
  useEffect(() => {
    const asyncMount = async () => {
      let values = {};
      if (dataGenYr) {
        values = { table, dataGenYr };
      } else {
        values = { table };
      }
      await dispatch(asyncReadDataFetch(values)).then(async (res) => {
        // tableDatas배열에 key속성을 추가함.
        const modifiedTableDatas = await res.payload.map((data) => {
          return {
            key: data.id,
            ...data,
          };
        });
        setTableDatas(() => {
          return [...modifiedTableDatas];
        });
      });
      setLoading(false);
    };
    asyncMount();
  }, [
    dispatch,
    table,
    isSuccessCreateData,
    isSuccessUpdateData,
    isSuccessCreateUser,
    dataGenYr,
  ]);
  */

  // 새로고침 시 데이터를 read하는 함수
  const refreshDatas = useCallback(async () => {
    setLoading(true);
    const isHasDateRange = !!dateRange;
    let refreshedDatas;
    if (isHasDateRange) {
      const startDate = dateRange.start.format('YYYY-MM-DD');
      const endDate = dateRange.end.format('YYYY-MM-DD');
      const fetchedDatas = await dispatch(
        asyncReadRangeDataFetch({
          table,
          column: 'order_date',
          startPt: startDate,
          endPt: endDate,
        })
      ).unwrap();
      refreshedDatas = fetchedDatas.map((data) => ({ key: data.id, ...data }));
    } else {
      const fetchedDatas = await dispatch(
        asyncReadDataFetch({ table })
      ).unwrap();
      refreshedDatas = fetchedDatas.map((data) => ({ key: data.id, ...data }));
    }
    setTableDatas(refreshedDatas);
    setIsLatest(true);
    setLoading(false);
  }, [dispatch, table, dateRange]);

  // 마운트 및 create, update, delete시 데이터를 read하는 함수
  useEffect(() => {
    const getTableDatas = async () => {
      setLoading(true);
      const isHasDateRange = !!dateRange;
      let newTableDatas;
      if (isHasDateRange) {
        const startDate = dateRange.start.format('YYYY-MM-DD');
        const endDate = dateRange.end.format('YYYY-MM-DD');
        const column =
          window.location.pathname === '/sales_inquiry'
            ? 'delivery_date'
            : 'order_date';
        const fetchedDatas = await dispatch(
          asyncReadRangeDataFetch({
            table,
            column,
            startPt: startDate,
            endPt: endDate,
          })
        ).unwrap();
        newTableDatas = fetchedDatas.map((data) => ({ key: data.id, ...data }));
      } else {
        const fetchedDatas =
          table === 'profiles' // profiles일 경우 api 호출 (권한검사, password 보안 로직)
            ? await getUserListApi()
            : await dispatch(asyncReadDataFetch({ table })).unwrap();
        newTableDatas = fetchedDatas.map((data) => ({ key: data.id, ...data }));
      }
      setTableDatas(newTableDatas);
      setLoading(false);
    };
    getTableDatas();
  }, [
    dispatch,
    table,
    isSuccessCreateData,
    isSuccessUpdateData,
    isSuccessCreateUser,
    dateRange,
  ]);

  // 검색기능 구현
  const onFinish = useCallback(
    async (search) => {
      const { word } = search;
      const isEmptySearchWord = word === undefined || word.trim().length === 0;
      if (isEmptySearchWord) {
        alert('검색어를 1글자 이상 입력해주세요.');
        return;
      }
      if (search.type === 'all') {
        await setLoading(true);
        const values = { table };
        // 해당 table의 Data를 갖고옴.
        dispatch(asyncReadDataFetch(values)).then(async (res) => {
          // tableDatas를 양식에 맞게 설정.
          const modifiedTableDatas = await res.payload.map((data) => {
            return {
              key: data.id,
              ...data,
            };
          });
          let searchedDatas = [];
          modifiedTableDatas.forEach((data) => {
            const arr = Object.values(data);
            arr.forEach((value) => {
              if (typeof value === 'string') {
                if (value.includes(search.word)) {
                  if (!searchedDatas.includes(data)) {
                    searchedDatas.push(data);
                  }
                }
              }
            });
          });
          setTableDatas(() => {
            return [...searchedDatas];
          });
          setLoading(false);
        });
      } else {
        const values = { table };
        // 해당 table의 Data를 갖고옴.
        dispatch(asyncReadDataFetch(values)).then(async (res) => {
          // tableDatas를 양식에 맞게 설정.
          const modifiedTableDatas = await res.payload.map((data) => {
            return {
              key: data.id,
              ...data,
            };
          });
          let searchedDatas = [];
          await modifiedTableDatas.forEach((data) => {
            const value = data[search.type];
            if (value.includes(search.word)) {
              searchedDatas.push(data);
            }
          });
          setTableDatas(() => {
            return [...searchedDatas];
          });
        });
      }
    },
    [dispatch, table]
  );

  // 테이블 필터 기능 구현
  useEffect(() => {
    let columns = [];
    tableColumns.forEach((tableColumn) => {
      checkedTableColumns.forEach((checkedTableColumn) => {
        if (checkedTableColumn === tableColumn.title) {
          columns.push(tableColumn);
        }
      });
    });
    setFilteredTableColumns([...columns]);
  }, [checkedTableColumns, tableColumns]);

  return (
    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
      {(table === 'product' || table === 'sale') && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#f7f7f7',
            width: '100%',
            paddingBottom: '10px',
            paddingLeft: '5px',
          }}
        >
          <p style={{ marginRight: '5px', fontSize: '13px', color: 'gray' }}>
            {table === 'product' ? '출고' : '판매'}상품 숨김처리
          </p>
          <HiddenToggle table={table} />
        </div>
      )}
      <TableHeader
        refreshDatas={refreshDatas}
        createButtonText={createButtonText}
        createButtonModal={createButtonModal}
        onFinish={onFinish}
        tableColumns={tableColumns}
        checkedTableColumns={checkedTableColumns}
        setCheckedTableColumns={setCheckedTableColumns}
        sortOldestDatas={sortOldestDatas}
        sortLatestDatas={sortLatestDatas}
        isLatest={isLatest}
        items={items}
        tableDatas={tableDatas}
        loading={loading}
      />
      <TableBody
        tableDatas={tableDatas}
        tableColumns={filteredTableColumns}
        loading={loading}
      />
    </div>
  );
};

export default React.memo(Table);

Table.propTypes = {
  table: PropTypes.string.isRequired,
  tableColumns: PropTypes.instanceOf(Array).isRequired,
  createButtonText: PropTypes.string.isRequired,
  createButtonModal: PropTypes.instanceOf(Object),
  items: PropTypes.instanceOf(Array),
  dateRange: PropTypes.instanceOf(Object),
};
