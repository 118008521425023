import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Empty, Input, Modal, Space, Spin } from 'antd';
import {
  DeleteOutlined,
  EnvironmentFilled,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import DaumPostcode from 'react-daum-postcode';
import uuid from 'react-uuid';
import direction from '../../direction';

const Container = styled.div`
  margin-bottom: 35px;
  align-items: center;
`;

const AddressForm = (props) => {
  const { addressDatas, setAddressDatas, pageType, addressLoading } = props;

  const { getGeocodeForAddress } = direction();

  // 우편번호 모달 열고 닫기
  const [openPostcode, setOpenPostcode] = useState(false);
  const [addressData, setAddressData] = useState({
    key: uuid(),
    name: '',
    postCode: '',
    address: '',
    isRepresentative: false,
    longitude: '',
    latitude: '',
  });
  const [isChecked, setIsChecked] = useState(null);
  const [showAdrInput, setShowAdrInput] = useState(false);
  const [getGeocodeLoading, setGetGeocodeLoading] = useState(false);

  // 대표주소지에 체크가 되어있는지 확인
  useEffect(() => {
    const asyncFunc = async () => {
      let isReturn = null;
      if (addressDatas.length === 0) {
        setIsChecked(true);
        isReturn = true;
      }
      await addressDatas.forEach((data) => {
        if (data.isRepresentative) {
          setIsChecked(true);
          isReturn = true;
        }
      });
      if (isReturn) return;
      setIsChecked(false);
      return;
    };
    asyncFunc();
  }, [addressDatas]);

  useEffect(() => {
    if (addressDatas.length === 1) {
      setAddressDatas((prev) => {
        return prev.map((data) => ({ ...data, isRepresentative: true }));
      });
    }
  }, [addressDatas.length, setAddressDatas]);

  const addAddressData = () => {
    if (
      addressData.name.trim().length === 0 ||
      addressData.postCode.trim().length === 0 ||
      addressData.address.trim().length === 0
    ) {
      alert('주소지의 모든 항목을 입력해주세요.');
      return;
    }
    // 배송지 목록에 추가하려는 addressData가 있을 때
    let isDuplicated = false;
    addressDatas.forEach((data) => {
      if (addressData.key === data.key) {
        isDuplicated = true;
        const newAddressDatas = [...addressDatas];
        const index = newAddressDatas.indexOf(data);
        newAddressDatas[index] = { ...addressData };
        setAddressDatas(newAddressDatas);
        setAddressData({
          key: uuid(),
          name: '',
          postCode: '',
          address: '',
          isRepresentative: false,
        });
      }
    });
    if (isDuplicated) return;
    setAddressDatas((prev) => [...prev, addressData]);
    setAddressData({
      key: uuid(),
      name: '',
      postCode: '',
      address: '',
      isRepresentative: false,
    });
    setShowAdrInput(false);
    setOpenPostcode(false);
  };
  const deleteAddressData = (data) => {
    setAddressDatas((prev) => {
      return prev.filter((prevData) => prevData !== data);
    });
  };

  /*
  주소지 수정
  const editAddressData = (data) => {
    setAddressData(() => ({
      key: data.key,
      name: data.name,
      postCode: data.postCode,
      address: data.address,
      isRepresentative: data.isRepresentative,
    }));
  };
  */

  // DaumPostCode 라이브러리 함수
  const handleAddress = async (values) => {
    await setGetGeocodeLoading(true);
    const geocode = await getGeocodeForAddress(values.address);
    await setGetGeocodeLoading(false);
    // 하나만 있으면 선택 되도록.
    setAddressData((prev) => ({
      ...prev,
      postCode: values.zonecode,
      address: values.address,
      longitude: geocode.longitude,
      latitude: geocode.latitude,
    }));
    setShowAdrInput(true);
    setOpenPostcode(true);
  };

  // 체크박스 하나만 선택하도록 하는 함수
  const handleCheckOnly = (check) => {
    const checkboxes = document.getElementsByName('checkbox');
    for (let i = 0; i < checkboxes.length; i += 1) {
      if (checkboxes[i] !== check) {
        checkboxes[i].checked = false;
      }
    }
    // 기존 addressDatas 값 수정
    const { value } = check;
    const { checked } = check;
    const newAddressDatas = addressDatas.map((data) =>
      String(data.key) === value
        ? { ...data, isRepresentative: checked }
        : { ...data, isRepresentative: false }
    );
    setAddressDatas(() => [...newAddressDatas]);
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            fontWeight: 'bold',
            marginBottom: '15px',
            marginRight: '15px',
          }}
        >
          주소지 정보
        </div>
        {addressDatas.length === 0 ? (
          <div style={{ color: 'gray' }}>등록된 주소지 없음</div>
        ) : (
          <div style={{ color: 'gray' }}>
            등록된 주소지 {addressDatas.length}개
          </div>
        )}
      </div>
      {addressDatas.length === 0 && (
        <div
          style={{
            border: '1px solid #efefef',
            marginBottom: '5px',
            borderRadius: '10px',
            padding: '15px',
          }}
        >
          <Empty description="" />
        </div>
      )}
      {addressLoading ? (
        <Space style={{ height: '180px', width: '100%', paddingLeft: '30px' }}>
          <Spin />
        </Space>
      ) : (
        addressDatas.map((data, index) => {
          return (
            <div
              key={index}
              style={{
                border: '1px solid #eaeaea',
                borderRadius: '10px',
                marginBottom: '10px',
                padding: '10px 30px',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: pageType === 'detail' ? '#fafafa' : 'white',
                boxShadow: '5px 5px 5px 0 #f7f7f7',
              }}
            >
              <input
                type="checkbox"
                name="checkbox"
                value={data.key}
                checked={data.isRepresentative}
                onChange={(e) => handleCheckOnly(e.target)}
                disabled={pageType === 'detail'}
              />
              <div
                style={{
                  height: '30px',
                  border: '0.1px solid #dfdfdf',
                  margin: '0 30px',
                }}
              />
              <div>
                <p style={{ fontWeight: '600', fontSize: '15px' }}>
                  <EnvironmentFilled
                    style={{ color: '#1677ff', marginRight: '5px' }}
                  />
                  {data.name}
                </p>
                <div style={{ display: 'flex' }}>
                  <p style={{ color: 'gray', marginRight: '10px' }}>
                    {data.postCode}
                  </p>
                  <p>{data.address}</p>
                </div>
              </div>
              {/* 
              <Button
                size="small"
                type="link"
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '45px',
                  fontSize: '12px',
                }}
                onClick={() => editAddressData(data)}
                disabled={pageType === 'detail'}
              >
                <EditOutlined />
              </Button>
              <div
                style={{
                  backgroundColor: 'lightgray',
                  width: '1px',
                  height: '15px',
                  position: 'absolute',
                  top: '10px',
                  right: '38px',
                  color: 'gray',
                }}
              />
              */}
              <Button
                size="small"
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  fontSize: '12px',
                  borderRadius: '10px',
                }}
                onClick={() => deleteAddressData(data)}
                disabled={pageType === 'detail'}
              >
                <DeleteOutlined />
              </Button>
            </div>
          );
        })
      )}
      <Button
        style={{
          marginBottom: '10px',
          width: '100%',
          color: '#1677ff',
          fontSize: '15px',
          height: '40px',
          boxShadow: '5px 5px 5px 0 #f7f7f7',
          marginTop: '10px',
        }}
        value={addressData.postCode}
        onClick={() => setOpenPostcode(true)}
        disabled={pageType === 'detail'}
      >
        <PlusOutlined /> 주소지 추가
      </Button>
      {isChecked === false && (
        <div style={{ marginTop: '10px', paddingLeft: '2px', color: 'red' }}>
          대표주소지를 선택해주세요.
        </div>
      )}
      {openPostcode && (
        <Modal
          title={showAdrInput ? '주소지 입력' : '우편번호 찾기'}
          open
          centered
          onCancel={() => {
            setOpenPostcode(false);
            setShowAdrInput(false);
          }}
          onOk={addAddressData}
          disabled={!showAdrInput}
          okText={
            <>
              <SaveOutlined style={{ marginRight: '5px' }} /> 주소지 저장
            </>
          }
          cancelText="닫기"
        >
          <DaumPostcode onComplete={handleAddress} />
          {getGeocodeLoading && (
            <div
              style={{
                height: '400px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Spin size="large" />
              <div style={{ marginTop: '10px', color: 'gray' }}>
                좌표값을 가져오는 중입니다...
              </div>
            </div>
          )}
          {showAdrInput && (
            <div
              style={{
                height: '400px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Container>
                <div
                  style={{
                    marginBottom: '5px',
                    fontWeight: '600',
                    fontSize: '15px',
                  }}
                >
                  <EnvironmentFilled
                    style={{ marginRight: '5px', color: '#1677ff' }}
                  />
                  주소지명
                </div>
                <Input
                  bordered={false}
                  style={{
                    borderRadius: '0px',
                    borderBottom: '1px solid lightgray',
                  }}
                  placeholder="주소지명을 입력해주세요."
                  value={addressData.name}
                  onChange={(e) =>
                    setAddressData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                  disabled={pageType === 'detail'}
                />
              </Container>
              <Container>
                <div
                  style={{
                    marginBottom: '5px',
                    fontWeight: '600',
                    fontSize: '15px',
                  }}
                >
                  우편번호
                </div>
                <Input
                  bordered={false}
                  style={{
                    borderRadius: '0px',
                    borderBottom: '1px solid lightgray',
                  }}
                  placeholder="우편번호를 입력해주세요."
                  value={addressData.postCode}
                  onChange={(e) =>
                    setAddressData((prev) => ({
                      ...prev,
                      postCode: e.target.value,
                    }))
                  }
                  disabled={pageType === 'detail'}
                />
              </Container>
              <Container>
                <div
                  style={{
                    marginBottom: '5px',
                    fontWeight: '600',
                    fontSize: '15px',
                  }}
                >
                  상세주소
                </div>
                <Input
                  bordered={false}
                  style={{
                    borderRadius: '0px',
                    borderBottom: '1px solid lightgray',
                  }}
                  placeholder="주소를 상세하게 입력해주세요."
                  value={addressData.address}
                  onChange={(e) =>
                    setAddressData((prev) => ({
                      ...prev,
                      address: e.target.value,
                    }))
                  }
                  disabled={pageType === 'detail'}
                />
              </Container>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default AddressForm;

AddressForm.propTypes = {
  addressDatas: PropTypes.instanceOf(Array).isRequired,
  setAddressDatas: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
  addressLoading: PropTypes.bool.isRequired,
};
