import React, { useMemo } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import useModal from '../../hook/useModal';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Table from '../../shared/table/Table';

const Product = () => {
  const { openModal } = useModal();

  // breadCrumb 데이터 생성
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <InboxOutlined />,
        breadCrumbNav: ' 출고상품(품목)',
      },
    ],
    []
  );

  // 불러올 table이 뭔지
  const table = 'product';

  // TableHeader에 들어갈 create 버튼 텍스트
  const createButtonText = '품목 추가';

  // table header에 들어갈 create 버튼의 Modal객체 (Modal 속성으로 넣어줄 객체)
  const createButtonModal = {
    type: 'product',
    modalInfo: {
      modalTitle: '품목 추가',
      resultStatus: '',
      resultTitle: '',
      resultSubTitle: '',
      buttonText: '',
    },
    dataInfo: {
      dataId: '',
      dataTable: 'product',
      datas: {},
    },
  };

  // table에 넣을 columns. table View를 위한 배열.
  const tableColumns = [
    {
      title: '품목코드',
      dataIndex: 'oms_code',
      key: 'oms_code',
      width: '20%',
      align: 'center',
    },
    {
      title: '품목명',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <Link to={`/product/${record.id}`}>{record.name}</Link>
      ),
      width: '20%',
      align: 'center',
    },
    {
      title: '품목구분',
      dataIndex: 'category',
      key: 'category',
      render: (value) => {
        if (value === '0') {
          return <div>원재료</div>;
        }
        if (value === '4') {
          return <div>부재료</div>;
        }
        if (value === '1') {
          return <div>제품</div>;
        }
        if (value === '2') {
          return <div>반제품</div>;
        }
        if (value === '3') {
          return <div>상품</div>;
        }
        if (value === '7') {
          return <div>무형상품</div>;
        }
      },
      width: '20%',
      align: 'center',
    },
    {
      title: '구매처',
      dataIndex: 'purchase',
      key: 'purchase',
      width: '20%',
      render: (value) => {
        if (value?.length === 0 || value === null) {
          return <div>미입력</div>;
        }
        return <div>{value}</div>;
      },
      align: 'center',
    },
    {
      title: '등록일자',
      dataIndex: 'reg_date',
      key: 'reg_date',
      width: '20%',
      render: (value) => {
        const result = value.slice(0, 10);
        return <div>{result}</div>;
      },
      align: 'center',
    },
  ];
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <Table
        tableColumns={tableColumns}
        table={table}
        createButtonText={createButtonText}
        createButtonModal={createButtonModal}
        items={tableColumns}
      />
    </LayoutComponent>
  );
};

export default Product;
