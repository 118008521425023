import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Button,
  Col,
  Empty,
  Modal,
  Row,
  Skeleton,
  Spin,
  Statistic,
  Timeline,
} from 'antd';
import styled from 'styled-components';
import {
  AuditOutlined,
  CalendarFilled,
  DollarCircleFilled,
  GoldFilled,
  InfoCircleOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import { asyncReadBulkDataFetch } from '../../redux/modules/shared/readBulkData';

const AccountStackInfo = (props) => {
  const { accountCode, handleCancel, accountName } = props;
  const dispatch = useDispatch();

  const [stackDatas, setStackDatas] = useState({
    firstDate: { data: { date: '', diffDays: 0 }, loading: true },
    ordersQty: { data: 0, loading: true },
    productsQty: { data: 0, loading: true },
    productsPrice: { data: 0, loading: true },
    memos: { data: [], loading: true },
  });

  const [isInfo, setIsInfo] = useState(true);
  const [activeButton, setActiveButton] = useState('button1');

  useEffect(() => {
    const getAccStackAmount = async () => {
      try {
        // 주문서
        const orders = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'order',
            eqKey: 'account_code',
            eqValue: accountCode,
          })
        )
          .unwrap()
          .then((res) => res);
        // 메모 받기
        const memos = orders
          .filter((order) => order.memo.length > 0)
          .map((order) => ({ date: order.order_date, memo: order.memo }));
        setStackDatas((prev) => ({
          ...prev,
          memos: { data: [...memos], loading: false },
        }));
        // 누적주문횟수
        const ordersQty = orders.length;
        setStackDatas((prev) => ({
          ...prev,
          ordersQty: { data: ordersQty, loading: false },
        }));
        // 첫 거래일자
        const firstDate = orders.map((order) => order.order_date).sort()[0];
        // 일 수 계산
        const startDate = new Date(firstDate);
        const today = new Date();
        const oneDay = 24 * 60 * 60 * 1000;
        const diffDays = Math.round(Math.abs(startDate - today) / oneDay);
        setStackDatas((prev) => ({
          ...prev,
          firstDate: { data: { date: firstDate, diffDays }, loading: false },
        }));
        const ordersArr = orders.map((order) => order.id);
        // 주문출고상품
        const products = await dispatch(
          asyncReadBulkDataFetch({
            table: 'order_product',
            column: 'order_id',
            array: ordersArr,
          })
        )
          .unwrap()
          .then((res) => res);
        // 누적판매개수
        const productsQty = products
          .map((product) => product.product_quantity)
          .reduce((acc, cur) => acc + cur, 0);
        setStackDatas((prev) => ({
          ...prev,
          productsQty: { data: productsQty, loading: false },
        }));
        // 누적판매금액
        const productsPrice = products
          .map((product) => product.product_VAT_price + product.product_price)
          .reduce((acc, cur) => acc + cur, 0);
        setStackDatas((prev) => ({
          ...prev,
          productsPrice: { data: productsPrice, loading: false },
        }));
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    };
    getAccStackAmount();
  }, [dispatch, accountCode]);

  return (
    <div>
      <Modal
        title="거래처 판매정보"
        open
        onCancel={handleCancel}
        centered
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '30px 0',
          }}
        >
          <div style={{ marginBottom: '30px', textAlign: 'center' }}>
            <div style={{ fontSize: '18px' }}>{accountName}</div>
            <Button
              size="small"
              type="primary"
              style={{ marginTop: '5px', fontSize: '12px' }}
            >
              {accountCode}
            </Button>
          </div>
          <div style={{ display: 'flex', marginBottom: '50px' }}>
            <ButtonS
              size="large"
              type="link"
              onClick={() => {
                setIsInfo(true);
                setActiveButton('button1');
              }}
              isActive={activeButton === 'button1'}
              style={{ borderRadius: '0' }}
            >
              <InfoCircleOutlined />
              INFO
            </ButtonS>
            <ButtonS
              size="large"
              type="link"
              onClick={() => {
                setIsInfo(false);
                setActiveButton('button2');
              }}
              isActive={activeButton === 'button2'}
              style={{ borderRadius: '0' }}
            >
              <MessageOutlined />
              MEMO
            </ButtonS>
          </div>
          {isInfo && (
            <Timeline>
              <Timeline.Item dot={<CalendarFilled />}>
                <div style={{ fontSize: '15px' }}>첫 판매</div>
                <div style={{ color: 'gray' }}>
                  {stackDatas.firstDate.data.date} / +
                  {stackDatas.firstDate.data.diffDays}day
                </div>
              </Timeline.Item>
              <Timeline.Item dot={<AuditOutlined />}>
                <div style={{ fontSize: '15px' }}>누적주문횟수</div>
                <div style={{ color: 'gray' }}>
                  {stackDatas.ordersQty.loading && <Spin />}
                  {!stackDatas.ordersQty.loading && stackDatas.ordersQty.data}건
                </div>
              </Timeline.Item>
              <Timeline.Item dot={<GoldFilled />}>
                <div style={{ fontSize: '15px' }}>누적판매개수</div>
                <div style={{ color: 'gray' }}>
                  {stackDatas.productsQty.loading && <Spin />}
                  {!stackDatas.productsQty.loading &&
                    stackDatas.productsQty.data}
                  개
                </div>
              </Timeline.Item>
              <Timeline.Item dot={<DollarCircleFilled />}>
                <div style={{ fontSize: '15px' }}>누적판매금액</div>
                <div style={{ color: 'gray' }}>
                  {stackDatas.productsPrice.loading && <Spin />}
                  {!stackDatas.productsPrice.loading &&
                    stackDatas.productsPrice.data.toLocaleString()}
                  원
                </div>
              </Timeline.Item>
            </Timeline>
          )}
          {!isInfo && (
            <div>
              <div style={{ overflow: 'auto' }}>
                <Timeline>
                  {stackDatas.memos.data.length > 0 &&
                    stackDatas.memos.data.map((item) => {
                      <Timeline.Item>
                        <div style={{ fontSize: '15px' }}>{item.memo}</div>
                        <div style={{ color: 'gray' }}>{item.date}</div>
                      </Timeline.Item>;
                    })}
                </Timeline>
              </div>
              {stackDatas.memos.data.length === 0 && (
                <Empty
                  style={{ height: '265px' }}
                  description={
                    <div style={{ color: 'gray' }}>
                      {accountName}의 판매메모 데이터가 없습니다.
                    </div>
                  }
                />
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AccountStackInfo;

AccountStackInfo.propTypes = {
  accountCode: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  accountName: PropTypes.string.isRequired,
};

const ButtonS = styled(Button)`
  color: ${({ isActive }) => (isActive ? '#1677ff' : 'gray')};
  border-bottom: ${({ isActive }) => (isActive ? '2px solid #1677ff' : 'none')};
  padding: 0 25px;
  font-weight: 600;
`;
