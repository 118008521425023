import React, { useState, useMemo } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

import useModal from '../../hook/useModal';
import ProductData from './ProductData';

const ProductModal = () => {
  // 모달 정보 가져오기.
  const { closeModal } = useModal();
  const { modalInfo } = useSelector((state) => state.modal);

  // form에 들어갈 각 item 항목들을 배열로 만듬.
  // TableFormModal에서 form형식으로 매핑됨.
  const items = useMemo(
    () => [
      {
        type: 'input',
        name: 'code',
        label: '품목코드',
        message: '품목코드를 입력해주세요.',
        visibility: false,
      },
      {
        type: 'input',
        name: 'name',
        label: '품목명',
        message: '품목명을 입력해주세요.',
        visibility: false,
      },
      {
        type: 'radio',
        label: '규격타입',
        name: 'standard_type',
        message: '규격타입을 입력해주세요.',
        visibility: false,
        options: [
          { label: '규격명', value: '규격명' },
          { label: '규격그룹', value: '규격그룹' },
          { label: '규격계산', value: '규격계산' },
          { label: '규격계산 그룹', value: '규격계산 그룹' },
        ],
      },
      {
        type: 'input',
        name: 'standard',
        label: '규격',
        message: '규격을 입력해주세요.',
        visibility: false,
      },
      {
        type: 'input',
        name: 'unit',
        label: '단위',
        message: '단위를 입력해주세요.',
        visibility: false,
      },
      {
        type: 'radio',
        label: '품목구분',
        name: 'category',
        message: '품목구분을 입력해주세요.',
        visibility: false,
        options: [
          { label: '원재료', value: '원재료' },
          { label: '부재료', value: '부재료' },
          { label: '제품', value: '제품' },
          { label: '반제품', value: '반제품' },
          { label: '상품', value: '상품' },
          { label: '무형상품', value: '무형상품' },
        ],
      },
      {
        type: 'radioButton',
        label: '세트여부',
        name: 'set',
        message: '세트여부를 입력해주세요.',
        visibility: false,
        options: [
          { label: 'Y', value: 'Y' },
          { label: 'N', value: 'N' },
        ],
      },
      {
        type: 'radioButton',
        label: '재고수량관리',
        name: 'quantity',
        message: '재고수량관리를 입력해주세요.',
        visibility: false,
        options: [
          { label: 'Y', value: 'Y' },
          { label: 'N', value: 'N' },
        ],
      },
      {
        type: 'input',
        name: 'incoming_price',
        label: '입고단가',
        message: '입고단가를 입력해주세요.',
        visibility: false,
      },
      {
        type: 'radioButton',
        label: '입고단가 VAT',
        name: 'incoming_price_VAT',
        message: '입고단가 VAT를 입력해주세요.',
        visibility: false,
        options: [
          { label: 'Y', value: 'Y' },
          { label: 'N', value: 'N' },
        ],
      },
      {
        type: 'input',
        name: 'release_price',
        label: '출고단가',
        message: '출고단가를 입력해주세요.',
        visibility: false,
      },
      {
        type: 'radioButton',
        label: '출고단가 VAT',
        name: 'release_price_VAT',
        message: '출고단가 VAT를 입력해주세요.',
        visibility: false,
        options: [
          { label: 'Y', value: 'Y' },
          { label: 'N', value: 'N' },
        ],
      },
      {
        type: 'input',
        name: 'purchase',
        label: '구매처',
        message: '구매처를 입력해주세요.',
        visibility: false,
      },
    ],
    []
  );

  return (
    <Modal
      open
      onCancel={() => closeModal()}
      title={modalInfo.modalTitle}
      centered
      footer={[<div key="modal" />]}
    >
      <ProductData items={items} />
    </Modal>
  );
};

export default ProductModal;
