import React from 'react';
import PropTypes from 'prop-types';

import Center from '../../shared/components/Center';
import AccessComponent from '../components/AccessComponent';
import AccessPermission from '../components/AccessPermission';

const Access = (props) => {
  const { text, button, isLoggedIn, falsePermission } = props;
  // 화면 1초 뒤에 띄우기

  return (
    <Center>
      {falsePermission ? (
        <AccessPermission />
      ) : (
        <AccessComponent text={text} button={button} isLoggedIn={isLoggedIn} />
      )}
    </Center>
  );
};

export default Access;

Access.propTypes = {
  text: PropTypes.string,
  button: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  falsePermission: PropTypes.bool,
};
