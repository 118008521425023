import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TeamOutlined } from '@ant-design/icons';

import { DARK_MODE_BACKGROUND, LIGHT_MODE_BACKGROUND } from '../../constants';

const NumberOfAccountByCity = (props) => {
  const { darkMode, selectedCity, numberOfAccountDatasByCity } = props;

  const [numberOfAccount, setNumberOfAccount] = useState(0);

  useEffect(() => {
    const filteredNumberOfAccountDataByCity = numberOfAccountDatasByCity.filter(
      (item) => item.value === selectedCity
    );
    const newNumberOfAccount =
      filteredNumberOfAccountDataByCity[0]?.amount || 0;
    setNumberOfAccount(newNumberOfAccount);
  }, [numberOfAccountDatasByCity]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '10px 20px',
        borderRadius: '10px',
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
      }}
    >
      <h4>{selectedCity} 거래처수</h4>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <TeamOutlined style={{ position: 'absolute', left: '0px' }} />
        <h3>{numberOfAccount}</h3>
      </div>
    </div>
  );
};

export default NumberOfAccountByCity;

NumberOfAccountByCity.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  selectedCity: PropTypes.string.isRequired,
  numberOfAccountDatasByCity: PropTypes.instanceOf(Array).isRequired,
};
