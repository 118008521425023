import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import ProductCodeOrName from './ProductCodeOrName';
import StockValue from './StockValue';
import useSortOrderSetting from '../../hook/useSortOrderSetting';
import SafetyStockValue from './SafetyStockValue';
import UnSoldAndReceivedValue from './UnSoldAndUnReceivedValue';
import ChangedStockValue from './ChangedStockValue';

const DataTable = (props) => {
  const { inventoryDatas, setInventoryDatas, formData, isDatasLoading } = props;
  const [columns, setColumns] = useState([]);

  const getSelectedWarehouseData = useCallback(
    ({ currentInventoryData, selectedWarehouse }) => {
      const currentWarehouseDatas = currentInventoryData.warehouse;
      const selectedWarehouseId = selectedWarehouse.value;
      const selectedWarehouseData = currentWarehouseDatas.filter(
        (currentWarehouseData) => {
          const isEqualWarehouseId =
            selectedWarehouseId === currentWarehouseData.id;
          return isEqualWarehouseId;
        }
      )[0];
      return selectedWarehouseData;
    },
    [formData]
  );

  const returnValueByType = ({ type, warehouseData }) => {
    switch (type) {
      case 'basic':
        return warehouseData.stackQuantity;
      case 'safetyStock':
        return (
          <SafetyStockValue
            safetyStock={warehouseData.safetyStock}
            stackQuantity={warehouseData.stackQuantity}
          />
        );
      case 'changedStock':
        return (
          <ChangedStockValue
            changedStockByAdd={warehouseData.changedStock.add}
            changedStockByAbstract={warehouseData.changedStock.abstract}
            stackQuantity={warehouseData.stackQuantity}
          />
        );
      case 'unSoldAndUnReceived':
        return (
          <UnSoldAndReceivedValue
            stackQuantity={warehouseData.stackQuantity}
            unSold={warehouseData.unSold}
            unReceived={warehouseData.unReceived}
          />
        );
      default:
        return null;
    }
  };

  const getWarehouseColumns = useCallback(() => {
    const { type } = formData;
    const warehousesOfFormData = formData.warehouse;
    const warehouseColumns = warehousesOfFormData.map(
      (selectedWarehouse, index) => ({
        title: selectedWarehouse.label,
        key: `warehouse_${index}`,
        width: `${50 / warehousesOfFormData.length}%`,
        align: 'center',
        render: (currentInventoryData) => {
          const selectedWarehouseData = getSelectedWarehouseData({
            currentInventoryData,
            selectedWarehouse,
          });
          return returnValueByType({
            type,
            warehouseData: selectedWarehouseData,
          });
        },
      })
    );
    return warehouseColumns;
  }, [formData]);

  useEffect(() => {
    const newColumns = [
      {
        title: '품목코드',
        dataIndex: 'product',
        key: 'product_code',
        width: '20%',
        render: (value) => value.code,
        align: 'center',
      },
      {
        title: '품목명[규격]',
        dataIndex: 'product',
        key: 'product_name',
        width: '30%',
        render: (value) => `${value.name}[${value.standard}]`,
        align: 'center',
      },
      ...getWarehouseColumns(),
    ];
    setColumns(newColumns);
  }, [formData]);

  return (
    <div style={{ marginTop: '50px' }}>
      <div
        style={{
          fontSize: '16px',
          borderBottom: '1px solid gray',
          paddingBottom: '10px',
        }}
      >
        {formData.type === 'safetyStock' && '재고현황 + 안전재고'}
        {formData.type === 'changedStock' && '재고현황 + 변동재고'}
        {formData.type === 'unSoldAndUnReceived' && '재고현황 + 미판매/미입고'}
        {formData.type === 'basic' && '재고현황(기본)'}
      </div>
      <Table
        bordered
        dataSource={inventoryDatas}
        columns={columns}
        pagination={false}
        loading={isDatasLoading}
      />
    </div>
  );
};

export default DataTable;

DataTable.propTypes = {
  inventoryDatas: PropTypes.instanceOf(Array).isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
  setInventoryDatas: PropTypes.func.isRequired,
  isDatasLoading: PropTypes.bool.isRequired,
};
