import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
  SaveOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const FormController = (props) => {
  const {
    pageType,
    pathUrl,
    initFormStates,
    createHandler,
    updateHandler,
    deleteHandler,
  } = props;
  const history = useHistory();

  const pathConvertor = () => {
    const originalStr = history.location.pathname;
    const updatedStr = originalStr.replace('detail', 'update');
    return updatedStr;
  };

  const controlButtons = () => {
    if (pageType === 'create') {
      return (
        <div>
          <Button type="primary" onClick={() => createHandler()}>
            <PlusOutlined />
            추가
          </Button>
        </div>
      );
    }
    if (pageType === 'detail') {
      return (
        <div>
          <Button type="primary" onClick={() => history.push(pathConvertor())}>
            <EditOutlined />
            수정
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => deleteHandler()}
            style={{ marginLeft: '5px' }}
          >
            <DeleteOutlined />
            삭제
          </Button>
        </div>
      );
    }
    if (pageType === 'update') {
      return (
        <div>
          <Button type="primary" onClick={() => updateHandler()}>
            <SaveOutlined />
            저장
          </Button>
        </div>
      );
    }
  };

  return (
    <div
      style={{
        marginTop: '50px',
        padding: '15px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
      }}
    >
      <div>
        <Button
          style={{ marginRight: '5px' }}
          onClick={() => history.push(`/${pathUrl}`)}
        >
          <UnorderedListOutlined />
          목록
        </Button>
        <Button onClick={() => initFormStates()}>
          <ReloadOutlined />
          취소
        </Button>
      </div>
      {controlButtons()}
    </div>
  );
};

export default FormController;

FormController.propTypes = {
  pageType: PropTypes.string.isRequired,
  pathUrl: PropTypes.string.isRequired,
  initFormStates: PropTypes.func.isRequired,
  createHandler: PropTypes.func,
  updateHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
};
