import React, { useMemo } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

import useModal from '../../hook/useModal';
import WarehouseData from './WarehouseData';

const WarehouseModal = () => {
  const { closeModal } = useModal();
  const { modalInfo } = useSelector((state) => state.modal);

  // form에 들어갈 각 item 항목들을 배열로 만듬.
  // TableFormModal에서 form형식으로 매핑됨.
  const items = useMemo(
    () => [
      {
        type: 'input',
        name: 'code',
        label: '창고코드',
        message: '창고코드를 입력해주세요.',
      },
      {
        type: 'input',
        name: 'name',
        label: '창고명',
        message: '창고명을 입력해주세요.',
      },
      {
        type: 'radio',
        label: '구분',
        name: 'category',
        message: '구분을 입력해주세요.',
        options: [
          { label: '창고', value: '창고' },
          { label: '공장', value: '공장' },
          { label: '공장(외주비관리)', value: '공장(외주비관리)' },
        ],
      },
    ],
    []
  );

  return (
    <Modal
      open
      onCancel={() => closeModal()}
      title={modalInfo.modalTitle}
      centered
      footer={[<div key="modal" />]}
      style={{ position: 'relative' }}
    >
      <WarehouseData items={items} />
    </Modal>
  );
};

export default WarehouseModal;
