import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

import SaleFormFinish from './SaleFormFinish';

const SaleFormDatas = (props) => {
  const { pageType, saleItems, saleProductItems } = props;
  const dispatch = useDispatch();
  const { code } = useParams();

  const [saleData, setSaleData] = useState({});
  const [productDatas, setProductDatas] = useState([]);
  const [saleProductDatas, setSaleProductDatas] = useState([]);

  // create일 때 데이터 받으면 안됨
  useEffect(() => {
    // saleData 받기
    const saleValues = {
      table: 'sale',
      eqKey: 'code',
      eqValue: code,
    };
    dispatch(asyncReadFilteredDataFetch(saleValues)).then((res) =>
      setSaleData({ ...res.payload[0] })
    );

    // saleProductDatas 받기
    const saleProductValues = {
      table: 'sale_product',
      eqKey: 'sale_code',
      eqValue: code,
    };
    dispatch(asyncReadFilteredDataFetch(saleProductValues)).then((res) =>
      setSaleProductDatas([...res.payload])
    );

    // productDatas 받기
    const getProductDataArr = async () => {
      try {
        let productDataArr = [];
        const values = {
          table: 'sale_product',
          eqKey: 'sale_code',
          eqValue: code,
        };
        const saleProducts = await dispatch(asyncReadFilteredDataFetch(values));
        for (const saleProduct of saleProducts.payload) {
          const productValues = {
            table: 'product',
            eqKey: 'code',
            eqValue: saleProduct.product_code,
          };
          const product = await dispatch(
            asyncReadFilteredDataFetch(productValues)
          );
          productDataArr.push(product.payload[0]);
        }
        return productDataArr;
      } catch (error) {
        return alert(error);
      }
    };
    getProductDataArr().then((res) => setProductDatas([...res]));
  }, [dispatch, code]);

  return (
    <SaleFormFinish
      pageType={pageType}
      saleItems={saleItems}
      saleProductItems={saleProductItems}
      saleData={saleData}
      productDatas={productDatas}
      saleProductDatas={saleProductDatas}
    />
  );
};

export default SaleFormDatas;

SaleFormDatas.propTypes = {
  pageType: PropTypes.string.isRequired,
  saleItems: PropTypes.instanceOf(Array).isRequired,
  saleProductItems: PropTypes.instanceOf(Array).isRequired,
};
