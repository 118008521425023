import React, { useState, useEffect } from 'react';
import { DeleteOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Empty } from 'antd';
import PropTypes from 'prop-types';

const Receivers = ({
  receivers,
  handleAllCheckReceivers,
  handleAllUnCheckReceivers,
  handleCheckReceiver,
  removeReceiver,
  removeCheckedReceivers,
}) => {
  return (
    <div
      style={{
        border: '1px solid lightgray',
        backgroundColor: 'white',
        borderRadius: '5px',
        marginTop: '10px',
      }}
    >
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
          borderBottom: '1px solid lightgray',
        }}
      >
        <Checkbox
          onChange={(e) =>
            e.target.checked
              ? handleAllCheckReceivers()
              : handleAllUnCheckReceivers()
          }
        />
        <Button size="small" onClick={removeCheckedReceivers}>
          <DeleteOutlined />
        </Button>
      </header>
      <div style={{ maxHeight: '800px', overflow: 'auto' }}>
        {receivers?.map((receiver, index) => (
          <div
            key={receiver.id}
            style={{
              borderBottom: '1px solid lightgray',
              padding: '8px 10px',
              backgroundColor: receiver.checked ? '#e1e1e1' : '#fafafa',
              display: 'flex',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
            onClick={() => handleCheckReceiver(receiver.id)}
          >
            <div>
              {index + 1}. {receiver.mobile} | {receiver.name}
            </div>
          </div>
        ))}
        {!receivers?.length && (
          <Empty
            style={{
              padding: '10px',
              borderBottom: '1px solid lightgray',
            }}
            description="데이터가 없습니다."
          />
        )}
      </div>
      <footer style={{ padding: '10px' }}>
        <span style={{ color: 'gray' }}>
          {receivers.length.toLocaleString()}/5,000
        </span>
      </footer>
    </div>
  );
};

export default Receivers;

Receivers.propTypes = {
  receivers: PropTypes.instanceOf(Array).isRequired,
  handleAllCheckReceivers: PropTypes.func.isRequired,
  handleAllUnCheckReceivers: PropTypes.func.isRequired,
  handleCheckReceiver: PropTypes.func.isRequired,
  removeReceiver: PropTypes.func.isRequired,
  removeCheckedReceivers: PropTypes.func.isRequired,
};
