import { useDispatch } from 'react-redux';
import { asyncReadDataFetch } from '../redux/modules/shared/readData';
import { asyncReadFilteredDataFetch } from '../redux/modules/shared/readFilteredData';

const useCalcAchievementRate = () => {
  const dispatch = useDispatch();

  // 해당 월 매출 목표치 값 가져오기
  const getSalesTarget = async (salesMonth) => {
    const fetchedSalesTargetData = await dispatch(
      asyncReadFilteredDataFetch({
        table: 'sales_target',
        eqKey: 'date',
        eqValue: salesMonth,
        readMethod: 'same',
      })
    )
      .unwrap()
      .then((res) => res[0]);
    const salesTarget = fetchedSalesTargetData?.sales_target;
    return salesTarget;
  };

  // 모든 월 매출 목표치 값 가져오기
  const getAllSalesTarget = async () => {
    const fetchedAllSalesTargetData = await dispatch(
      asyncReadDataFetch({ table: 'sales_target' })
    ).unwrap();
    const allSalesTarget = fetchedAllSalesTargetData?.map((data) => ({
      date: data.date,
      salesTarget: data.sales_target,
    }));
    return allSalesTarget;
  };

  // 해당 월 매출 목표치에 대한 누적 매출액의 달성율 가져오기
  const getAchievementRate = ({ salesAmount, salesTarget }) => {
    const achievementRate = (salesAmount / salesTarget) * 100;
    const formattedAchievementRate = Math.round(achievementRate);
    return formattedAchievementRate;
  };

  // 위의 두 함수 처리
  const calcAchievementRate = async ({ salesMonth, salesAmount }) => {
    const salesTarget = await getSalesTarget(salesMonth);
    const isExistSalesTarget = !!salesTarget;
    if (!isExistSalesTarget) {
      return;
    }
    const achievementRate = getAchievementRate({ salesAmount, salesTarget });
    return achievementRate;
  };

  return { calcAchievementRate, getAllSalesTarget, getAchievementRate };
};

export default useCalcAchievementRate;
