import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import useModal from '../../hook/useModal';
import ComponentForm from './ComponentForm';

import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';

const UpdateComponent = (props) => {
  const { items } = props;
  const dispatch = useDispatch();
  const { dataInfo, modalInfo } = useSelector((state) => state.modal);
  const { openModal, closeModal } = useModal();

  const [merchandiseCodeCheck, setMerchandiseCodeCheck] = useState(false);
  const [productCodeCheck, setProductCodeCheck] = useState(false);

  // disabled 처리
  useEffect(() => {
    items.map((item) => {
      return {
        ...item,
        disabled: true,
      };
    });
  }, [items]);

  const onFinish = useCallback(
    (values) => {
      const datas = {
        id: dataInfo.dataId,
        table: dataInfo.dataTable,
        state: 1,
        ...values,
      };
      // 해당 row를 업데이트하는 dispatch
      dispatch(asyncUpdateDataFetch(datas))
        .unwrap()
        .then(() => {
          closeModal();
          // result 모달을 띄움.
          openModal({
            type: 'result',
            modalInfo: {
              modalTitle: modalInfo.modalTitle,
              resultStatus: 'success',
              resultTitle: modalInfo.modalTitle,
              resultSubTitle: `데이터 ${modalInfo.modalTitle} 작업을 완료 했습니다.`,
              buttonText: '확인',
            },
          });
        })
        .catch((err) => alert(err.messege));
    },
    [
      closeModal,
      openModal,
      dataInfo.dataId,
      dataInfo.dataTable,
      dispatch,
      modalInfo.modalTitle,
    ]
  );

  return (
    <ComponentForm
      items={items}
      buttonText="수정"
      onFinish={onFinish}
      merchandiseCodeCheck={merchandiseCodeCheck}
      productCodeCheck={productCodeCheck}
      setMerchandiseCodeCheck={setMerchandiseCodeCheck}
      setProductCodeCheck={setProductCodeCheck}
    />
  );
};

export default React.memo(UpdateComponent);

UpdateComponent.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};
