import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { asyncReadFilteredDataFetch } from '../redux/modules/shared/readFilteredData';

const calcOrderProductValue = (price, quantity, discountRate) => {
  const discounted_price = price - (price * discountRate) / 100;
  const supply = quantity * price;
  const discounted_supply = supply - (supply * discountRate) / 100;
  const vat = supply / 10;
  const discounted_vat = vat - (vat * discountRate) / 100;
  const total = supply + vat;
  const discount = (total * discountRate) / 100;
  const discounted = total - discount;
  return {
    product_supply_price: Math.round(supply),
    discounted_supply: Math.round(discounted_supply),
    product_VAT_price: Math.round(vat),
    discounted_vat: Math.round(discounted_vat),
    product_price: Math.round(price),
    discounted_price: Math.round(discounted_price),
    discount: Math.round(discount),
    discounted: Math.round(discounted),
  };
};
const calcDcOrderProductValue = (total, quantity) => {
  const minusTotal = -total * quantity;
  const vat = (minusTotal * 10) / 110;
  const supply = minusTotal - vat;
  const price = supply / quantity;
  return {
    discount: 0,
    discount_rate: 0,
    discounted: Math.round(minusTotal),
    discounted_price: Math.round(price),
    discounted_supply: Math.round(supply),
    discounted_vat: Math.round(vat),
    product_VAT_price: Math.round(vat),
    product_price: Math.round(price),
    product_supply_price: Math.round(supply),
  };
};

const useOrderProductData = () => {
  const dispatch = useDispatch();
  const createOrderProductData = async (orderSales) => {
    try {
      // 판매-출고상품 데이터가 있는 배열
      let saleProductsArr = [];
      for (const orderSale of orderSales) {
        const { sale_code, sale_quantity, delivery } = orderSale;
        const saleProducts = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'sale_product',
            eqKey: 'sale_code',
            eqValue: sale_code,
          })
        )
          .unwrap()
          .then((res) => res);
        saleProductsArr.push({ saleProducts, sale_quantity, delivery });
      }
      // 주문-출고상품 데이터가 있는 배열
      let orderProductArr = [];
      // product 데이터
      for (const saleProductsObj of saleProductsArr) {
        const { saleProducts, sale_quantity, delivery } = saleProductsObj;
        // delivery 값 바꿔치기
        let changedDlvySaleProducts = [];
        // 택배비가 0원 일때
        if (delivery === 'parcel0') {
          // 택배비 출고상품 제거
          const arr = await saleProducts.filter(
            (saleProduct) => !saleProduct.product_code.includes('parcel')
          );
          changedDlvySaleProducts = [...arr];
        } else {
          const arr = await saleProducts.map((saleProduct) => {
            if (saleProduct.product_code.includes('parcel') && delivery) {
              return { ...saleProduct, product_code: delivery };
            }
            return { ...saleProduct };
          });
          changedDlvySaleProducts = [...arr];
        }

        for (const saleProduct of changedDlvySaleProducts) {
          const { sale_code, product_quantity, discount_rate } = saleProduct;
          const product = await dispatch(
            asyncReadFilteredDataFetch({
              table: 'product',
              eqKey: 'code',
              eqValue: saleProduct.product_code,
            })
          )
            .unwrap()
            .then((res) => res[0]);
          const price = product.release_price / 1.1;
          const quantity = sale_quantity * product_quantity;
          calcOrderProductValue(price, quantity, discount_rate);
          const orderProduct = {
            key: uuid(),
            is_account_dc: false,
            product_id: product.id,
            product_code: product.code,
            product_name: product.name,
            product_quantity: quantity,
            product_warehouse: product.warehouse_code,
            sale_code,
            discount_rate,
            ...calcOrderProductValue(price, quantity, discount_rate),
          };
          orderProductArr.push(orderProduct);
        }
      }
      return orderProductArr;
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const createOrderProductWithDc = (
    orderProductDatas,
    dcAccountProductDatas
  ) => {
    const result = [...orderProductDatas];
    for (const pdData of orderProductDatas) {
      for (const dcData of dcAccountProductDatas) {
        if (pdData.product_id === dcData.product_id) {
          const acctPdDcPrice = dcData.discounted_release_price;
          const pdDataWithDc = {
            key: uuid(),
            product_warehouse: '',
            is_account_dc: true,
            product_code: pdData.product_code,
            product_id: pdData.product_id,
            product_name: `거래처할인(${pdData.product_name})`,
            product_quantity: pdData.product_quantity,
            sale_code: pdData.sale_code,
            ...calcDcOrderProductValue(acctPdDcPrice, pdData.product_quantity),
          };
          result.push(pdDataWithDc);
        }
      }
    }
    return result;
  };

  return { createOrderProductData, createOrderProductWithDc };
};

export default useOrderProductData;
