import React from 'react';
import styled from 'styled-components';
import { Modal, Result, Button } from 'antd';
import { useSelector } from 'react-redux';

import useModal from '../hook/useModal';

const ButtonStyle = styled(Button)`
  width: 60%;
  padding: 8px;
  height: 40px;
  font-weight: 700;
  font-size: 14.5px;
  background: black;
  color: white;
`;

const ResultModal = () => {
  const { closeModal } = useModal();
  const { modalInfo } = useSelector((state) => state.modal);

  return (
    <Modal
      open
      onCancel={() => closeModal()}
      title={modalInfo.modalTitle}
      centered
      footer={[<div key="modal" />]}
    >
      <Result
        status={modalInfo.resultStatus}
        title={modalInfo.resultTitle}
        subTitle={modalInfo.resultSubTitle}
        extra={[
          <ButtonStyle key="result" onClick={() => closeModal()}>
            {modalInfo.buttonText}
          </ButtonStyle>,
        ]}
      />
    </Modal>
  );
};

export default ResultModal;
