import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
  UnorderedListOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import useModal from '../../hook/useModal';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import { asyncDeleteDataFetch } from '../../redux/modules/shared/deleteData';
import SaleFormComponent from './SaleFormComponent';
import SaleProductFormComponent from './SaleProductFormComponent';

const SaleForm = (props) => {
  const {
    pageType,
    saleItems,
    saleProductItems,
    saleData,
    productDatas,
    saleProductDatas,
  } = props;

  const { code } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { openModal } = useModal();

  const saleStateRef = useRef();
  const saleProductStateRef = useRef();

  const handleModal = (modalInfo) => {
    openModal({
      type: 'result',
      modalInfo: {
        modalTitle: '판매상품',
        resultStatus: 'success',
        resultTitle: `판매상품 ${modalInfo.status}`,
        resultSubTitle: `'${modalInfo.code}(${modalInfo.name})'을(를) 판매상품에(서) ${modalInfo.status}했습니다.`,
        buttonText: '확인',
      },
    });
    history.push('/sale');
  };

  const getSaleState = () => {
    // 입력 값 가져오기.
    const saleState = saleStateRef.current.getSaleFormInputs();
    const saleProductState =
      saleProductStateRef.current.getSaleProductTableRows();
    // 필수입력이 안되었을 때 예외처리
    if (!saleState.name) {
      alert('판매상품명을 입력해주세요.');
      return;
    }
    if (pageType !== 'detail' && saleProductState.length === 0) {
      alert('출고상품을 등록해주세요.');
      return;
    }
    // 추가 (create) 처리
    if (pageType === 'create') {
      // 판매상품 (sale) 추가
      dispatch(asyncCreateDataFetch({ table: 'sale', ...saleState }))
        .unwrap()
        .then()
        .catch((err) => {
          alert(err.message);
          console.log(err);
        });
      // 판매-출고상품 (saleProduct) 추가
      saleProductState.forEach((saleProduct) => {
        dispatch(
          asyncCreateDataFetch({ table: 'sale_product', ...saleProduct })
        )
          .unwrap()
          .then()
          .catch((err) => {
            alert(err.message);
            console.log(err);
          });
      });
      handleModal({
        status: '추가',
        code: saleState.code,
        name: saleState.name,
      });
      // 수정 (update) 처리
    } else if (pageType === 'update') {
      const asyncUpdateFunc = async () => {
        // 판매상품 (sale) 수정
        await dispatch(
          asyncUpdateDataFetch({
            code: saleState.code,
            table: 'sale',
            ...saleState,
          })
        );
        // 판매-출고상품 (saleProduct) 수정
        // 우선 판매-출고상품 (saleProduct) 목록 일괄 삭제처리
        await dispatch(
          asyncDeleteDataFetch({
            table: 'sale_product',
            saleCode: saleState.code,
            isBatchDelete: true,
          })
        )
          .unwrap()
          .then()
          .catch((err) => {
            alert(err.message);
            console.log(err);
          });
        // 이후 판매-출고상품 (saleProduct) 목록 생성
        await saleProductState.forEach((saleProduct) => {
          const { id, reg_date, mod_date, del_date, ...saleProductItem } =
            saleProduct;
          dispatch(
            asyncCreateDataFetch({
              table: 'sale_product',
              ...saleProductItem,
            })
          )
            .unwrap()
            .then()
            .catch((err) => {
              alert(err.message);
              console.log(err);
            });
        });
        await handleModal({
          status: '수정',
          code: saleState.code,
          name: saleState.name,
        });
      };
      asyncUpdateFunc();
      // 삭제 (delete) 처리
    } else if (pageType === 'detail') {
      // 판매상품 (sale) 삭제
      dispatch(
        asyncUpdateDataFetch({
          code: saleState.code,
          table: 'sale',
          state: 9,
          ...saleState,
        })
      )
        .unwrap()
        .then()
        .catch((err) => {
          alert(err.message);
          console.log(err);
        });
      // 판매-출고상품 (saleProduct) 목록 일괄 삭제처리
      dispatch(
        asyncDeleteDataFetch({
          table: 'sale_product',
          saleCode: saleState.code,
          isBatchDelete: true,
        })
      )
        .unwrap()
        .then()
        .catch((err) => {
          alert(err.message);
          console.log(err);
        });
      handleModal({
        status: '삭제',
        code: saleState.code,
        name: saleState.name,
      });
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            paddingBottom: '10px',
            fontSize: '16px',
            borderBottom: '1px solid gray',
            width: '100%',
          }}
        >
          판매상품 입력
        </div>
      </div>
      <SaleFormComponent
        saleItems={saleItems}
        saleData={saleData}
        pageType={pageType}
        ref={saleStateRef}
      />
      <div
        style={{
          paddingTop: '40px',
          paddingBottom: '10px',
          fontSize: '16px',
          borderBottom: '1px solid gray',
          width: '100%',
        }}
      >
        판매-출고 정보
      </div>
      <SaleProductFormComponent
        saleProductItems={saleProductItems}
        saleProductDatas={saleProductDatas}
        productDatas={productDatas}
        pageType={pageType}
        ref={saleProductStateRef}
        saleCode={saleData.code}
      />
      <div
        style={{
          backgroundColor: '#fafafa',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: '8px',
          border: '1px solid rgb(240, 239, 239)',
          marginTop: '50px',
          padding: '20px 15px',
        }}
      >
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => history.push('/sale')}
              style={{ marginRight: '5px' }}
            >
              <UnorderedListOutlined />
              목록
            </Button>
            <Button onClick={() => window.location.reload()}>
              <ReloadOutlined />
              취소
            </Button>
          </div>
        </div>
        <div>
          {pageType === 'detail' && (
            <div>
              <Button
                type="primary"
                onClick={() => history.push(`/sale/updateSale/${code}`)}
                style={{ marginRight: '5px' }}
              >
                <EditOutlined />
                수정
              </Button>
              <Popconfirm
                placement="top"
                title="정말 삭제하시겠습니까?"
                okText="삭제"
                cancelText="취소"
                onConfirm={getSaleState}
              >
                <Button danger type="primary">
                  <DeleteOutlined />
                  삭제
                </Button>
              </Popconfirm>
            </div>
          )}
          {pageType === 'create' && (
            <Button type="primary" onClick={getSaleState}>
              <PlusOutlined />
              추가
            </Button>
          )}
          {pageType === 'update' && (
            <Button type="primary" onClick={getSaleState}>
              <SaveOutlined />
              저장
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default SaleForm;

SaleForm.propTypes = {
  pageType: PropTypes.string.isRequired,
  saleItems: PropTypes.instanceOf(Array).isRequired,
  saleProductItems: PropTypes.instanceOf(Array).isRequired,
  saleData: PropTypes.instanceOf(Object).isRequired,
  productDatas: PropTypes.instanceOf(Array).isRequired,
  saleProductDatas: PropTypes.instanceOf(Array).isRequired,
};
