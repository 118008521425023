import React, { useMemo } from 'react';
import { InboxOutlined, SearchOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import ProductFormItems from '../components/ProductFormItems';

const ProductDetail = () => {
  // 해당 page 타입 선언하기
  const pageType = 'detail';

  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <InboxOutlined />,
        breadCrumbNav: ' 출고상품(품목)',
      },
      {
        breadCrumbIcon: <SearchOutlined />,
        breadCrumbNav: ' 상세',
      },
    ],
    []
  );
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px' }}>
        <ProductFormItems pageType={pageType} />
      </div>
    </LayoutComponent>
  );
};

export default ProductDetail;
