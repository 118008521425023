import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const ManagerTier = (props) => {
  const { tier } = props;
  return <Tag>{tier}등급</Tag>;
};

export default ManagerTier;

ManagerTier.propTypes = {
  tier: PropTypes.number.isRequired,
};
