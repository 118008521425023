import dayjs from 'dayjs';

const genBeginAndEndDate = () => {
  // 오늘
  const genTodayDate = () => {
    const date = dayjs().format('YYYY-MM-DD');
    return { begin: date, end: date };
  };

  // 어제
  const genYesterdayDate = () => {
    const date = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
    return { begin: date, end: date };
  };

  // 이번주
  const genThisWeekDate = () => {
    const begin = dayjs().startOf('week').add(1, 'day').format('YYYY-MM-DD');
    const end = dayjs().format('YYYY-MM-DD');
    return { begin, end };
  };

  // 저번주
  const genLastWeekDate = () => {
    const begin = dayjs()
      .subtract(1, 'week')
      .startOf('week')
      .format('YYYY-MM-DD');
    const end = dayjs().subtract(1, 'week').endOf('week').format('YYYY-MM-DD');
    return { begin, end };
  };

  // 이번달
  const genThisMonthDate = () => {
    const begin = dayjs().startOf('month').format('YYYY-MM-DD');
    const end = dayjs().format('YYYY-MM-DD');
    return { begin, end };
  };

  // 저번달
  const genLastMonthDate = () => {
    const begin = dayjs()
      .subtract(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');
    const end = dayjs()
      .subtract(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD');

    return { begin, end };
  };

  // 올해
  const genThisYearDate = () => {
    const begin = dayjs().startOf('year').format('YYYY-MM-DD');
    const end = dayjs().format('YYYY-MM-DD');
    return { begin, end };
  };

  // 전년
  const genLastYearDate = () => {
    const begin = dayjs()
      .subtract(1, 'year')
      .startOf('year')
      .format('YYYY-MM-DD');
    const end = dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
    return { begin, end };
  };

  return {
    genTodayDate,
    genYesterdayDate,
    genThisWeekDate,
    genLastWeekDate,
    genThisMonthDate,
    genLastMonthDate,
    genThisYearDate,
    genLastYearDate,
  };
};

export default genBeginAndEndDate;
