import React, { useMemo } from 'react';
import { GiftOutlined, SearchOutlined } from '@ant-design/icons';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import SaleFormItems from '../components/SaleFormItems';

const SaleDetail = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <GiftOutlined />,
        breadCrumbNav: ' 판매상품',
      },
      {
        breadCrumbIcon: <SearchOutlined />,
        breadCrumbNav: ' 상세',
      },
    ],
    []
  );
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px' }}>
        <SaleFormItems pageType="detail" />
      </div>
    </LayoutComponent>
  );
};

export default SaleDetail;
