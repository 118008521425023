import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  LIGHT_MODE_BACKGROUND,
  LIGHT_MODE_FONT_COLOR,
} from '../../constants';

const Center = (props) => {
  const { children } = props;
  const bgColor =
    window.localStorage.getItem('darkMode') === 'true'
      ? DARK_MODE_BACKGROUND
      : LIGHT_MODE_BACKGROUND;
  const fontColor =
    window.localStorage.getItem('darkMode') === 'true'
      ? DARK_MODE_FONT_COLOR
      : LIGHT_MODE_FONT_COLOR;

  return (
    <Container bgColor={bgColor} fontColor={fontColor}>
      {children}
    </Container>
  );
};

export default Center;

Center.propTypes = {
  children: PropTypes.node.isRequired,
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.fontColor};
`;
