import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  asyncSigninUserFetch,
  logoutReducer,
} from '../../redux/modules/auth/signinUser';
import { asyncSignoutUserFetch } from '../../redux/modules/auth/signoutUser';

import useModal from '../../hook/useModal';
import Center from '../../shared/components/Center';
import AuthContainer from '../components/AuthContainer';
import AuthFooter from '../components/AuthFooter';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { openModal } = useModal();

  const handleSubmit = async (values) => {
    try {
      await dispatch(asyncSigninUserFetch(values))
        .unwrap()
        .then((res) => {
          const isSBLoginSuccess = !!res === true;
          if (isSBLoginSuccess) {
            console.log(values);
            // 서버측 migrateUser api호출
            history.push('/data-calendar');
          }
        })
        .catch(() => {
          openModal({
            type: 'result',
            modalInfo: {
              modalTitle: '로그인',
              resultTitle: '로그인할 수 없습니다.',
              resultStatus: 'error',
              resultSubTitle:
                '이메일 또는 비밀번호를 확인한 후 다시 입력해주세요.',
              buttonText: '확인',
            },
          });
        });
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Center>
      <div style={{ fontSize: '32px', fontFamily: 'Jua', color: '#001528' }}>
        한통 OMS
      </div>
      <AuthContainer handleSubmit={handleSubmit} signup={false} />
      <AuthFooter />
    </Center>
  );
};

export default Login;
