import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { asyncReadSpecificDataFetch } from '../../../redux/modules/shared/readSpecificData';
import { asyncReadDataFetch } from '../../../redux/modules/shared/readData';
import TableFormModal from './TableFormModal';

const TableModal = (props) => {
  const { items, buttonText, onFinish } = props;
  const dispatch = useDispatch();
  const { dataInfo } = useSelector((state) => state.modal);
  const { modalInfo } = useSelector((state) => state.modal);

  const [formData, setFormData] = useState({});

  useEffect(() => {
    // 기존 수정 및 삭제시 데이터 form을 가져오는 함수
    if (dataInfo.dataId) {
      const values = {
        id: dataInfo.dataId,
        table: dataInfo.dataTable,
      };
      dispatch(asyncReadSpecificDataFetch(values))
        .unwrap()
        .then((res) => {
          // 거래처그룹 수정 및 삭제시 상위계층 그룹 코드 부분
          // account_group의 id를 code (name)형태로 바꿔서 formData에 넣어주는 함수
          if ('upper_code' in res[0]) {
            const upperCodeId = res[0].upper_code;
            // 해당 upper_Code의 id의 데이터를 read한다.
            const upperCodeValues = {
              id: upperCodeId,
              table: dataInfo.dataTable,
            };
            dispatch(asyncReadSpecificDataFetch(upperCodeValues))
              .unwrap()
              .then((upperCodeRes) => {
                // formData에 upper_code의 형태를 바꿔준다.
                setFormData({
                  ...res[0],
                  upper_code: `${upperCodeRes[0].code} (${upperCodeRes[0].name})`,
                });
              });
          }
          setFormData({
            ...res[0],
          });
        })
        .catch((err) => alert(err.message));
    }
    // 거래처그룹 추가시 그룹 코드 생성하는 함수(00000 꼴)
    if (modalInfo.modalTitle === '거래처그룹 추가') {
      // 거래처그룹 갯수 가져오기
      const values = {
        table: dataInfo.dataTable,
      };
      dispatch(asyncReadDataFetch(values))
        .unwrap()
        .then((res) => {
          // 그룹 코드 만들기
          const len = res.length + 1;
          const accountGroupLen = len.toString();
          const accountGroupCode = accountGroupLen.padStart(5, '0');
          setFormData((prevState) => {
            return {
              ...prevState,
              code: accountGroupCode,
            };
          });
        })
        .catch((err) => alert(err.message));
    }
  }, [dataInfo, modalInfo, dispatch]);

  return (
    <TableFormModal
      items={items}
      formData={formData}
      buttonText={buttonText}
      onFinish={onFinish}
    />
  );
};

export default React.memo(TableModal);

TableModal.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  buttonText: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
};
