import { useCallback, useEffect, useState } from 'react';

const useGetDeliveryStatusData = (firestoreData) => {
  const [deliveryStatusData, setDeliveryStatusData] = useState({
    received: 0,
    atPickup: 0,
    inTransit: 0,
    delivery: 0,
    delivered: 0,
    errorUnTrackId: 0,
    errorUnDelivery: 0,
    errorUnable: 0,
    etc: 0,
    sum: 0,
  });

  const addEachStatusData = useCallback(
    ({ statusData, statusId, statusText }) => {
      switch (statusId) {
        case 'information_received':
          statusData.received += 1;
          break;
        case 'at_pickup':
          statusData.atPickup += 1;
          break;
        case 'in_transit':
          statusData.inTransit += 1;
          break;
        case 'out_for_delivery':
          statusData.delivery += 1;
          break;
        case 'delivered':
          if (statusText.includes('배송불가')) {
            statusData.errorUnable += 1;
            break;
          }
          statusData.delivered += 1;
          break;
        case 'track_error':
          if (statusText.includes('미배달')) {
            statusData.errorUnDelivery += 1;
          } else if (statusText.includes('배송불가')) {
            statusData.errorUnable += 1;
          } else {
            statusData.errorUnTrackId += 1;
          }
          break;
        default:
          if (
            statusText.includes('용달신용') ||
            statusText.includes('용달착불') ||
            statusText.includes('직접수령') ||
            statusText.includes('쿠팡')
          ) {
            statusData.delivered += 1;
          } else {
            statusData.etc += 1;
          }
          break;
      }
    },
    []
  );

  const calcStatusSum = useCallback((statusData) => {
    const statusKeys = Object.keys(statusData);
    return statusKeys.reduce((acc, key) => {
      const statusValue = statusData[key];
      return acc + statusValue;
    }, 0);
  }, []);

  const handleStatusData = (firestoreData) => {
    const newStatusData = { ...deliveryStatusData };
    firestoreData.forEach(({ statusId, statusText }) => {
      addEachStatusData({ statusData: newStatusData, statusId, statusText });
    });
    const sum = calcStatusSum(newStatusData);
    setDeliveryStatusData({ ...newStatusData, sum });
  };

  useEffect(() => {
    handleStatusData(firestoreData);
  }, [firestoreData]);

  return deliveryStatusData;
};

export default useGetDeliveryStatusData;
