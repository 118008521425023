import { DatabaseOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import DataHandler from '../components/DataHandler';

const PurchaseCreate = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <DatabaseOutlined />,
        breadCrumbNav: ' 구매조회',
      },
      {
        breadCrumbIcon: <PlusOutlined />,
        breadCrumbNav: ' 추가',
      },
    ],
    []
  );

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <DataHandler pageType="create" />
    </LayoutComponent>
  );
};

export default PurchaseCreate;
