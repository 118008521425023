import React from 'react';
import { Modal, Result } from 'antd';

import useModal from '../../hook/useModal';

const UserListInfo = () => {
  const { closeModal } = useModal();
  return (
    <Modal
      open
      onCancel={() => closeModal()}
      title="유저 테이블"
      centered
      footer={[<div />]}
    >
      <Result
        type="info"
        title="유저 테이블 상세정보"
        subTitle={
          <div style={{ marginTop: '20px' }}>
            <p>1등급 유저는 관리자의 수정 및 삭제를 할 수 있습니다.</p>
            <p>1,2등급 유저는 관리자리스트에 접근할 수 있습니다.</p>
            <p>1 등급 유저의 정보를 수정할 수 없습니다.</p>
          </div>
        }
      />
    </Modal>
  );
};

export default UserListInfo;
