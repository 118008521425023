import { FileExcelOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import downloadExcelFile from '../../utils/downloadExcelFile';

const ExcelDirDownloader = (props) => {
  const { dirDatas, date } = props;
  const { handleShippingOrderTable } = downloadExcelFile();

  const [excelLoading, setExcelLoading] = useState(false);

  const handleClickExcelButton = async () => {
    try {
      await setExcelLoading(true);
      // 수량1개씩 분할
      const dividedDatas = [];
      for (const data of dirDatas) {
        if (data.product_quantity > 1) {
          const objToArrByQuantity = Array(data.product_quantity).fill({
            ...data,
            product_quantity: 1,
          });
          dividedDatas.push(...objToArrByQuantity);
        } else {
          dividedDatas.push(data);
        }
      }
      const excelTitle = [
        `${date && date.begin}~${date && date.end}_출하지시서_(주)한통.xlsx`,
      ];
      const excelColumns = [
        '주문번호',
        '상품명(옵션포함)',
        '주문상품수량',
        '주문자이름',
        '주문자번호',
        '주문자핸드폰',
        '수취인이름',
        '수취인전화',
        '수취인핸드폰',
        '신)수취인우편번호',
        '수취인주소',
        '송장번호',
        '배송메세지',
      ];
      const excelRows = dividedDatas.map((data) => ({
        order_number: data.order_number,
        product_name: data.product_name,
        product_quantity: data.product_quantity,
        orderer_name: '',
        orderer_phone: data.naver_product_order_id,
        orderer_mobile: '',
        account_name: data.account_name,
        phone: data.phone,
        mobile: data.mobile,
        post_code: data.post_code,
        address: data.address,
        invoice_number: '',
        invoice_message: data.memo,
        marked: data.marked,
      }));
      await handleShippingOrderTable(excelTitle, excelColumns, excelRows);
      setExcelLoading(false);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  return (
    <Button
      style={{
        borderRadius: '20px',
        backgroundColor: '#1d6f42',
        color: 'white',
      }}
      onClick={handleClickExcelButton}
      loading={excelLoading}
    >
      <FileExcelOutlined />
      출하지시서
    </Button>
  );
};

export default ExcelDirDownloader;

ExcelDirDownloader.propTypes = {
  dirDatas: PropTypes.instanceOf(Array).isRequired,
  date: PropTypes.instanceOf(Object),
};
