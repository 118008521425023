import React, { useMemo } from 'react';
import { AuditOutlined, PlusOutlined, SwapOutlined } from '@ant-design/icons';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Items from '../components/Items';

const CreateOrder = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <AuditOutlined />,
        breadCrumbNav: ' 주문',
      },
      {
        breadCrumbIcon: !window.location.pathname.includes(
          'estimateToOrder'
        ) ? (
          <PlusOutlined />
        ) : (
          <SwapOutlined />
        ),
        breadCrumbNav: !window.location.pathname.includes('estimate')
          ? ' 추가'
          : ' 전환',
      },
    ],
    []
  );

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px' }}>
        <Items pageType="create" />
      </div>
    </LayoutComponent>
  );
};

export default CreateOrder;
