import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import Event from './Event';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const Datas = (props) => {
  const { pageType, orderItems, orderSaleItems } = props;
  const dispatch = useDispatch();
  const { id } = useParams();

  const [datas, setDatas] = useState({
    orderData: {},
    orderSaleDatas: [],
    orderProductDatas: [],
  });

  // 렌더링 할 때, 페이지가 detail 또는 update일 때 기존 데이터를 받아옴.
  useEffect(() => {
    if (
      pageType === 'detail' ||
      pageType === 'update' ||
      window.location.pathname.includes('estimateToOrder')
    ) {
      // orderData 값 받기
      dispatch(asyncReadSpecificDataFetch({ table: 'order', id })).then((res) =>
        setDatas((prev) => {
          return {
            ...prev,
            orderData: res.payload[0],
          };
        })
      );
      // orderSaleDatas 값 받기
      dispatch(
        asyncReadFilteredDataFetch({
          table: 'order_sale',
          eqKey: 'order_id',
          eqValue: id,
        })
      ).then((res) => {
        setDatas((prev) => {
          return {
            ...prev,
            orderSaleDatas: res.payload,
          };
        });
      });
      // orderProductDatas 값 받기
      dispatch(
        asyncReadFilteredDataFetch({
          table: 'order_product',
          eqKey: 'order_id',
          eqValue: id,
        })
      ).then((res) => {
        setDatas((prev) => {
          return {
            ...prev,
            orderProductDatas: res.payload,
          };
        });
      });
    }
  }, [dispatch, id, pageType]);

  /*
  // 견적서를 주문서로 전환하는 경우
  useEffect(() => {
    if (window.location.pathname.includes('estimateToOrder')) {
      const putEstimateData = async () => {
        try {
          const [order, orderSale, orderProduct] = await Promise.all([
            dispatch(
              asyncReadFilteredDataFetch({
                table: 'order',
                eqKey: 'id',
                eqValue: id,
              })
            )
              .unwrap()
              .then((res) => res[0]),
            dispatch(
              asyncReadFilteredDataFetch({
                table: 'order_sale',
                eqKey: 'order_id',
                eqValue: id,
              })
            )
              .unwrap()
              .then((res) => res),
            dispatch(
              asyncReadFilteredDataFetch({
                table: 'order_product',
                eqKey: 'order_id',
                eqValue: id,
              })
            )
              .unwrap()
              .then((res) => res),
          ]);
          setDatas(() => ({
            orderData: { ...order },
            orderSaleDatas: [...orderSale],
            orderProductDatas: [...orderProduct],
          }));
        } catch (error) {
          console.log(error);
          alert(error.message);
        }
      };
      putEstimateData();
    }
  }, [dispatch, id]);
  */

  return (
    <Event
      pageType={pageType}
      orderItems={orderItems}
      orderSaleItems={orderSaleItems}
      datas={datas}
    />
  );
};

export default React.memo(Datas);

Datas.propTypes = {
  pageType: PropTypes.string.isRequired,
  orderItems: PropTypes.instanceOf(Array).isRequired,
  orderSaleItems: PropTypes.instanceOf(Array).isRequired,
};
