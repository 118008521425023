import React, { useEffect, useState } from 'react';
import { Button, Empty, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { MessageOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { COUNSELLING_REACTION_GOOD } from '../../constants';
import CommentItem from './CommentItem';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import CommentForm from './CommentForm';

const CounsellingPostComment = () => {
  const adminInfo = useSelector((state) => state.signinUser.userInfo);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [commentDatas, setCommentDatas] = useState([]);
  const [sortingType, setSortinType] = useState('earliest');
  const [isUpdateComment, setIsUpdateComment] = useState(false);
  const [commentIdToUpdate, setCommentIdToUpdate] = useState(0);

  useEffect(() => {
    const getCommentDatas = async () => {
      try {
        const fetchedCommentDatas = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'counselling_comment',
            eqKey: 'counselling_id',
            eqValue: id,
          })
        )
          .unwrap()
          .then((res) =>
            res.sort((a, b) => new Date(a.reg_date) - new Date(b.reg_date))
          );
        setCommentDatas(
          fetchedCommentDatas.filter((item) => item.counselling_id === id)
        );
      } catch (error) {
        throw new Error(error);
      }
    };
    getCommentDatas();
  }, []);

  const sortCommentDatas = (newSortingType) => {
    if (newSortingType === 'earliest') {
      setCommentDatas((prev) =>
        [...prev].sort((a, b) => new Date(a.reg_date) - new Date(b.reg_date))
      );
    } else if (newSortingType === 'latest') {
      setCommentDatas((prev) =>
        [...prev].sort((a, b) => new Date(b.reg_date) - new Date(a.reg_date))
      );
    } else {
      return;
    }
  };

  const validationCheck = (value) => {
    return !!(value && value.replace(/\s/g, '').length > 0);
  };

  const handleCreateComment = async (commentFormData) => {
    const { adminId, reactionLevel, content } = commentFormData;
    if (!validationCheck(content)) {
      alert('내용을 입력해주세요.');
      return;
    }
    try {
      const postData = {
        counselling_id: id,
        admin_id: adminId,
        reaction_level: reactionLevel,
        content,
      };
      const newCommentData = await dispatch(
        asyncCreateDataFetch({ table: 'counselling_comment', ...postData })
      )
        .unwrap()
        .then((res) => res[0]);
      if (sortingType === 'earliest') {
        setCommentDatas((prev) => [...prev, newCommentData]);
      } else if (sortingType === 'latest') {
        setCommentDatas((prev) => [newCommentData, ...prev]);
      } else {
        return;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleUpdateComment = async (commentId, commentFormData) => {
    const { adminId, reactionLevel, content } = commentFormData;
    const isConfirmUpdate = window.confirm(
      '정말 수정된 댓글을 저장하시겠습니까?'
    );
    if (!isConfirmUpdate) return;
    if (!validationCheck(content)) {
      alert('내용을 입력해주세요.');
      return;
    }
    try {
      const postData = {
        id: commentId,
        state: 1,
        mod_date: dayjs(),
        admin_id: adminId,
        reaction_level: reactionLevel,
        content,
      };
      await dispatch(
        asyncUpdateDataFetch({
          table: 'counselling_comment',
          ...postData,
        })
      );
    } catch (error) {
      throw new Error(error);
    } finally {
      setCommentDatas((prev) =>
        prev.map((item) =>
          item.id === commentId
            ? {
                ...item,
                admin_id: adminId,
                reaction_level: reactionLevel,
                content,
              }
            : item
        )
      );
      setIsUpdateComment(false);
      setCommentIdToUpdate(0);
    }
  };

  const handleDeleteComment = async (commentId) => {
    const isConfirmDelete = window.confirm('정말 댓글을 삭제하시겠습니까?');
    if (!isConfirmDelete) return;
    try {
      await dispatch(
        asyncUpdateDataFetch({
          table: 'counselling_comment',
          id: commentId,
          state: 9,
          del_date: dayjs(),
        })
      );
    } catch (error) {
      throw new Error(error);
    } finally {
      setCommentDatas((prev) => prev.filter((item) => item.id !== commentId));
    }
  };

  return (
    <>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '17px' }}
      >
        <div>
          <MessageOutlined style={{ fontSize: '17px', marginRight: '8px' }} />
        </div>
        <div>
          추후 상담{' '}
          <span style={{ fontWeight: 'bold' }}>{commentDatas.length}건</span>
        </div>
      </div>
      {commentDatas.length === 0 && (
        <Empty
          style={{ marginBottom: '17px' }}
          description={
            <div style={{ color: 'gray' }}>추후 상담 내용이 없습니다.</div>
          }
        />
      )}
      {commentDatas.length > 0 && (
        <div>
          <div
            style={{
              marginBottom: '17px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontSize: '17px',
                fontWeight: 'bold',
                marginRight: '12px',
              }}
            >
              추후 상담
            </div>
            <Button
              size="small"
              style={{
                color: 'black',
                padding: '0 2px',
                backgroundColor:
                  sortingType === 'earliest' ? '#efefef' : 'white',
              }}
              type="link"
              onClick={() => {
                setSortinType('earliest');
                sortCommentDatas('earliest');
              }}
            >
              등록순
            </Button>
            <Button
              size="small"
              style={{
                color: 'black',
                padding: '0 2px',
                marginLeft: '3px',
                backgroundColor: sortingType === 'latest' ? '#efefef' : 'white',
              }}
              type="link"
              onClick={() => {
                setSortinType('latest');
                sortCommentDatas('latest');
              }}
            >
              최신순
            </Button>
          </div>
          {commentDatas.map((commentData) => (
            <CommentItem
              commentId={commentData.id}
              adminId={commentData.admin_id}
              reactionLevel={commentData.reaction_level}
              content={commentData.content}
              regDate={commentData.reg_date}
              handleUpdateComment={handleUpdateComment}
              handleDeleteComment={handleDeleteComment}
              isUpdateComment={isUpdateComment}
              setIsUpdateComment={setIsUpdateComment}
              commentIdToUpdate={commentIdToUpdate}
              setCommentIdToUpdate={setCommentIdToUpdate}
            />
          ))}
        </div>
      )}
      <LineStyle />
      <CommentForm
        handleCreateComment={handleCreateComment}
        adminInfo={adminInfo}
      />
    </>
  );
};

export default CounsellingPostComment;

const LineStyle = styled.div`
  margin: 20px 0;
  width: 100%;
  border: 0.5px solid #efefef;
`;
