import { Checkbox, Table } from 'antd';
import React from 'react';
import { useInvoiceContext } from '../../contexts/InvoiceContext';
import EmptyBox from './EmptyBox';

const InvoiceCheckList = () => {
  const { invoices, checkInvoice, checkAll, invoiceColumns } =
    useInvoiceContext();

  const selectColumn = {
    title: (
      <Checkbox
        onChange={(e) => {
          const isChecked = e.target.checked;
          checkAll(isChecked);
        }}
      />
    ),
    dataIndex: 'checkbox',
    render: (_, record) => (
      <Checkbox
        checked={record.selected}
        onChange={(e) => {
          const invoiceId = record.id;
          const isChecked = e.target.checked;
          checkInvoice(invoiceId, isChecked);
        }}
      />
    ),
  };

  if (!invoices.length) {
    return <EmptyBox />;
  }

  return (
    <Table dataSource={invoices} columns={[selectColumn, ...invoiceColumns]} />
  );
};

export default InvoiceCheckList;
