import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Center from '../../shared/components/Center';
import PasswordResetForm from '../components/PasswordResetForm';
import AuthContainer from '../components/AuthContainer';
import AuthFooter from '../components/AuthFooter';
import PasswordResetHeader from '../components/PasswordResetHeader';
import { asyncResetPasswordFetch } from '../../redux/modules/auth/resetPassword';

const PasswordReset = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleResetPassword = async (values) => {
    const confirm = window.confirm('정말 비밀번호를 재생성하시겠습니까?');
    if (!confirm) return;

    try {
      await dispatch(asyncResetPasswordFetch(values)).unwrap();
      alert('비밀번호를 재생성했습니다.');
      history.push('/');
    } catch (error) {
      alert(error);
      throw error;
    }
  };

  return (
    <Center>
      <AuthContainer>
        <PasswordResetHeader />
        <PasswordResetForm handleResetPassword={handleResetPassword} />
      </AuthContainer>
      <AuthFooter />
    </Center>
  );
};

export default PasswordReset;
