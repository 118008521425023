import React, {
  useEffect,
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, Collapse, Popover, Select, Table, Tag } from 'antd';
import uuid from 'react-uuid';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import OrderSummary from './OrderSummary';
import OrderMessenger from './OrderMessenger';
import EditablePrice from './EditablePrice';
import {
  DivisionQuantity,
  DivisionSupply,
  DivisionVat,
  DivisionWarehouse,
  DivisionTotal,
  DivisionOpenButton,
  DivisionSaveButton,
} from './Division';
import DiscountInfo from './DiscountInfo';
import useOrderProductData from '../../hook/useOrderProductData';
import useDcAccountProduct from '../../hook/useDcAccountProductDatas';

const OrderProductForm = forwardRef((props, ref) => {
  const {
    pageType,
    orderProductDatas,
    setOrderProductDatas,
    orderSaleDatas,
    orderData,
    setSmsDone,
    setOrderData,
    handleCreate,
    smsDone,
    dcAccountProductDatas,
    setDcAccountProductDatas,
  } = props;
  const dispatch = useDispatch();
  const { createOrderProductData, createOrderProductWithDc } =
    useOrderProductData();
  const { createDcAccountProductDatas } = useDcAccountProduct();

  const [loading, setLoading] = useState(false);
  const [warehouseOptions, setWarehouseOptions] = useState([]);

  const [division, setDivision] = useState({
    isDivision: false,
    divisionCode: '',
    productData: {},
  });
  const [divisionData, setDivisionData] = useState({
    division1: {
      discount: 0,
      discounted: 0,
      dcPrice: 0,
      dcSupply: 0,
      dcVat: 0,
      quantity: 0,
      supply: 0,
      vat: 0,
      total: 0,
      warehouse: '',
    },
    division2: {
      discount: 0,
      discounted: 0,
      dcPrice: 0,
      dcSupply: 0,
      dcVat: 0,
      quantity: 0,
      supply: 0,
      vat: 0,
      total: 0,
      warehouse: '',
    },
  });

  // 분할된 창고가 같은지 여부
  const [isWhEqual, setIsWhEqual] = useState(false);
  // 분할된 수량의 합이 맞는지 여부
  const [isQtyCorrect, setIsQtyCorrect] = useState(false);

  // 분할 수량 입력
  const inputQtyDiv = useCallback(
    async (quantity, divNum, data) => {
      const { discount_rate, product_quantity } = data;
      const thisQty = quantity;
      const anotherQty = product_quantity - quantity;
      // 출고상품 출고가격
      const relPrice = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'product',
          eqKey: 'code',
          eqValue: division.productData.product_code,
        })
      )
        .unwrap()
        .then((res) => res[0].release_price);
      // 수량으로 각각의 값을 계산하는 함수
      const calcDatasByQty = (qty) => {
        const price = relPrice / 1.1;
        const supply = price * qty;
        const vat = supply / 10;
        const total = supply + vat;
        const dcPrice = price - (price * discount_rate) / 100;
        const dcSupply = supply - (supply * discount_rate) / 100;
        const dcVat = vat - (vat * discount_rate) / 100;
        const discount = (total * discount_rate) / 100;
        const discounted = total - discount;
        return {
          supply: Math.round(supply),
          vat: Math.round(vat),
          total: Math.round(total),
          discount: Math.round(discount),
          discounted: Math.round(discounted),
          dcPrice: Math.round(dcPrice),
          dcSupply: Math.round(dcSupply),
          dcVat: Math.round(dcVat),
        };
      };
      if (divNum === 1) {
        setDivisionData((prev) => ({
          division1: {
            ...prev.division1,
            quantity: thisQty,
            ...calcDatasByQty(thisQty),
          },
          division2: {
            ...prev.division2,
            quantity: anotherQty,
            ...calcDatasByQty(anotherQty),
          },
        }));
      }
      if (divNum === 2) {
        setDivisionData((prev) => ({
          division1: {
            ...prev.division1,
            quantity: anotherQty,
            ...calcDatasByQty(anotherQty),
          },
          division2: {
            ...prev.division2,
            quantity: thisQty,
            ...calcDatasByQty(thisQty),
          },
        }));
      }
    },
    [dispatch, division.productData.product_code]
  );
  // 분할 출하창고 선택
  const selectWhDiv = (value, divNum) => {
    if (divNum === 1) {
      setDivisionData((prev) => ({
        ...prev,
        division1: { ...prev.division1, warehouse: value },
      }));
    }
    if (divNum === 2) {
      setDivisionData((prev) => ({
        ...prev,
        division2: { ...prev.division2, warehouse: value },
      }));
    }
  };

  // 상품 분할하기
  const divideProduct = useCallback(() => {
    const { productData } = division;
    const { division1, division2 } = divisionData;
    const {
      sale_code,
      product_code,
      product_name,
      product_price,
      discount_rate,
    } = productData;
    // 유효성검사
    if (!isQtyCorrect) {
      alert('분할시킬 출고상품의 수량의 합이 기존 수량과 같아야합니다.');
      return;
    }
    if (isWhEqual) {
      alert('분할시킬 출고상품의 출하창고를 다르게 선택해주세요.');
      return;
    }
    if (division1.warehouse.length === 0 || division2.warehouse.length === 0) {
      alert('분할시킬 출고상품의 출하창고를 선택해주세요.');
      return;
    }
    // 분할 선택된 출고상품 제거
    const productArr = [...orderProductDatas];
    const deleteIdx = productArr.findIndex(
      (data) => data.key === productData.key
    );
    productArr.splice(deleteIdx, 1);
    // 분할된 데이터를 형식에 맞게 변환
    const newPd1 = {
      sale_code,
      product_code,
      product_name,
      product_price,
      product_VAT_price: division1.vat,
      product_supply_price: division1.supply,
      product_quantity: division1.quantity,
      product_warehouse: division1.warehouse,
      discount: division1.discount,
      discounted: division1.discounted,
      discounted_price: division1.dcPrice,
      discounted_supply: division1.dcSupply,
      discounted_vat: division1.dcVat,
      key: uuid(),
      discount_rate,
    };
    const newPd2 = {
      sale_code,
      product_code,
      product_name,
      product_price,
      product_VAT_price: division2.vat,
      product_supply_price: division2.supply,
      product_quantity: division2.quantity,
      product_warehouse: division2.warehouse,
      discount: division2.discount,
      discounted: division2.discounted,
      discounted_price: division2.dcPrice,
      discounted_supply: division2.dcSupply,
      discounted_vat: division2.dcVat,
      key: uuid(),
      discount_rate,
    };
    // 삭제된 자리에 변환된 분할 데이터 삽입
    productArr.splice(deleteIdx, 0, newPd1);
    productArr.splice(deleteIdx + 1, 0, newPd2);
    setOrderProductDatas([...productArr]);
    // 원래대로 초기화
    setDivision({
      isDivision: false,
      divisionCode: '',
      productData: {},
    });
    setDivisionData({
      division1: {
        discount: 0,
        discounted: 0,
        dcPrice: 0,
        dcSupply: 0,
        dcVat: 0,
        quantity: 0,
        supply: 0,
        vat: 0,
        total: 0,
        warehouse: '',
      },
      division2: {
        discount: 0,
        discounted: 0,
        dcPrice: 0,
        dcSupply: 0,
        dcVat: 0,
        quantity: 0,
        supply: 0,
        vat: 0,
        total: 0,
        warehouse: '',
      },
    });
  }, [
    orderProductDatas,
    division,
    divisionData,
    setOrderProductDatas,
    isQtyCorrect,
    isWhEqual,
  ]);

  const handleOrderProductDatas = (newDatas) => {
    const asyncFunc = async () => {
      await setLoading(true);
      await setOrderProductDatas([]);
      if (newDatas.length === 0) {
        setLoading(false);
      }
      // 주문-출고상품 데이터생성 Hooks함수
      const newOrderProductDatas = await createOrderProductData(newDatas);
      if (orderData.account_code) {
        // 거래처별할인 데이터생성 Hooks함수
        const newDcAccountProductDatas = await createDcAccountProductDatas(
          newOrderProductDatas,
          orderData.account_code
        );
        await setDcAccountProductDatas(newDcAccountProductDatas);
        // 주문-출고상품에 거래처별할인 데이터접목 Hooks함수
        const newOrderProductDatasWithDc = await createOrderProductWithDc(
          newOrderProductDatas,
          newDcAccountProductDatas
        );
        await setOrderProductDatas(newOrderProductDatasWithDc);
      } else {
        await setOrderProductDatas(newOrderProductDatas);
      }
      setLoading(false);
    };
    asyncFunc();
  };

  // 최신주문반영 기능
  const getRecentDatas = (accountCode) => {
    dispatch(
      asyncReadFilteredDataFetch({
        table: 'order',
        eqKey: 'account_code',
        eqValue: accountCode,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.length === 0) {
          alert(
            '해당 거래처의 주문내역이 없으므로 주문-출고정보를 가져올 수 없습니다.'
          );
          return;
        }
        // 주문서 데이터 중 가장 최근 것 가져오기
        let latestDate = null;
        let latestData = null;
        res.forEach((data) => {
          const date = new Date(data.reg_date);
          if (date > latestDate || latestDate === null) {
            latestDate = date;
            latestData = data;
          }
        });
        // 해당 주문서 데이터의 orderProduct 정보 불러오기
        dispatch(
          asyncReadFilteredDataFetch({
            table: 'order_product',
            eqKey: 'order_id',
            eqValue: latestData.id,
          })
        )
          .unwrap()
          .then((datas) => {
            setOrderProductDatas([...datas]);
          })
          .catch((err) => {
            console.log(err);
            alert(err.message);
          });
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };
  // 출하창고 (warehouse) select 폼을 만든다.
  useEffect(() => {
    dispatch(asyncReadDataFetch({ table: 'warehouse' }))
      .unwrap()
      .then((datas) => {
        const options = datas.map((data) => {
          return { label: data.name, value: data.code };
        });
        setWarehouseOptions([...options]);
      })
      .catch((err) => {
        alert(err.message);
        console.log(err);
      });
  }, [dispatch]);

  // 출하창고 (warehouse) select 폼 함수
  const handleProductWarehouse = useCallback(
    (newValue, record) => {
      const newOrderProductDatas = orderProductDatas.map((data) => {
        if (data.key === record.key) {
          return { ...data, product_warehouse: newValue };
        }
        return data;
      });
      setOrderProductDatas([...newOrderProductDatas]);
    },
    [orderProductDatas, setOrderProductDatas]
  );

  const orderProductColumns = useMemo(
    () => [
      {
        title: '품목코드',
        dataIndex: 'product_code',
        key: 'product_code',
        width: '12.5%',
        align: 'center',
        render: (value, data) => {
          let isDivision = false;
          if (data.key) {
            isDivision = data.key === division.productData.key;
          }
          if (data.id) {
            isDivision = data.id === division.productData.id;
          }
          if (data.is_account_dc) {
            return <div />;
          }
          return (
            <div>
              {!division.isDivision && (
                <Popover
                  trigger="click"
                  placement="bottom"
                  content={
                    <div style={{ width: '180px' }}>
                      <p
                        style={{
                          textAlign: 'center',
                          marginBottom: '10px',
                          color: 'gray',
                        }}
                      >
                        <InfoCircleOutlined style={{ marginRight: '5px' }} />
                        원하는 기능을 클릭해주세요.
                      </p>
                      <DivisionOpenButton
                        pageType={pageType}
                        value={value}
                        setDivision={setDivision}
                        data={data}
                      />
                      <DiscountInfo data={data} />
                      <Button
                        style={{ width: '100%', marginTop: '5px' }}
                        size="small"
                        type="primary"
                        danger
                        onClick={() =>
                          setOrderProductDatas((prev) =>
                            prev.filter((product) => product.key !== data.key)
                          )
                        }
                      >
                        <DeleteOutlined /> 품목삭제
                      </Button>
                    </div>
                  }
                >
                  <Button size="small">{value}</Button>
                </Popover>
              )}
              {isDivision && (
                <div>
                  <DivisionSaveButton
                    setDivision={setDivision}
                    setDivisionData={setDivisionData}
                    value={value}
                    divideProduct={divideProduct}
                  />
                </div>
              )}
              {division.isDivision && !isDivision && <div>{value}</div>}
            </div>
          );
        },
      },
      {
        title: '품목명',
        dataIndex: 'product_name',
        key: 'product_name',
        width: '12.5%',
        align: 'center',
      },
      {
        title: '단가',
        key: 'product_price',
        dataIndex: 'product_price',
        width: '12.5%',
        align: 'center',
        render: (value, data) => {
          if (data.is_account_dc) {
            return <div />;
          }
          return (
            <div>
              {/* 분할 상태가 아닐 때 */}
              {!division.isDivision && data.discount_rate > 0 && (
                <>
                  <EditablePrice
                    value={data}
                    setOrderProductDatas={setOrderProductDatas}
                    pageType={pageType}
                    isDc
                  />
                  <NotDcVal>{data.product_price.toLocaleString()}원</NotDcVal>
                </>
              )}
              {!division.isDivision &&
                (data.discount_rate === 0 || !data.discount_rate) && (
                  <>
                    <EditablePrice
                      value={data}
                      setOrderProductDatas={setOrderProductDatas}
                      pageType={pageType}
                      isDc={false}
                    />
                    {data.discount_rate > 0 && (
                      <NotDcVal>
                        {data.product_price.toLocaleString()}원
                      </NotDcVal>
                    )}
                  </>
                )}
              {/* 분할 상태일 때 */}
              {division.isDivision && data.discount_rate > 0 && (
                <>
                  <div>{data.discounted_price.toLocaleString()}원</div>
                  {data.discount_rate > 0 && (
                    <NotDcVal>{data.product_price.toLocaleString()}원</NotDcVal>
                  )}
                </>
              )}
              {division.isDivision &&
                (data.discount_rate === 0 || !data.discount_rate) && (
                  <OriginVal>{data.product_price.toLocaleString()}원</OriginVal>
                )}
            </div>
          );
        },
      },
      {
        title: '수량',
        dataIndex: 'product_quantity',
        key: 'product_quantity',
        width: '12.5%',
        align: 'center',
        render: (value, data) => {
          let isDivision = false;
          if (data.key) {
            isDivision = data.key === division.productData.key;
          }
          if (data.id) {
            isDivision = data.id === division.productData.id;
          }
          if (data.is_account_dc) {
            return <div />;
          }
          return (
            <div>
              <OriginVal isDivision={isDivision}>{value}개</OriginVal>
              {isDivision && (
                <DivisionQuantity
                  value={value}
                  quantity1={divisionData.division1.quantity}
                  quantity2={divisionData.division2.quantity}
                  inputQtyDiv={inputQtyDiv}
                  isQtyCorrect={isQtyCorrect}
                  setIsQtyCorrect={setIsQtyCorrect}
                  data={data}
                />
              )}
            </div>
          );
        },
      },
      {
        title: '공급가액',
        dataIndex: 'product_supply_price',
        key: 'product_supply_price',
        width: '12.5%',
        align: 'center',
        render: (value, data) => {
          let isDivision = false;
          if (data.key) {
            isDivision = data.key === division.productData.key;
          }
          if (data.id) {
            isDivision = data.id === division.productData.id;
          }
          if (data.is_account_dc) {
            return <div />;
          }
          return (
            <div style={{ position: 'relative' }}>
              {/* 분할상태가 아닐 때 */}
              {!isDivision && data.discount_rate > 0 && (
                <>
                  <OriginVal isDivision={isDivision}>
                    {data.discounted_supply.toLocaleString()}원
                  </OriginVal>
                  <NotDcVal>{value.toLocaleString()}원</NotDcVal>
                </>
              )}
              {!isDivision &&
                (data.discount_rate === 0 || !data.discount_rate) && (
                  <OriginVal>{value.toLocaleString()}원</OriginVal>
                )}
              {/* 분할상태 일 때 */}
              {isDivision && data.discount_rate > 0 && (
                <div style={{ textDecoration: 'line-through', color: 'gray' }}>
                  {data.discounted_supply.toLocaleString()}원
                </div>
              )}
              {isDivision && data.discount_rate === 0 && (
                <div style={{ textDecoration: 'line-through', color: 'gray' }}>
                  {data.product_supply_price.toLocaleString()}원
                </div>
              )}
              {isDivision && (
                <div>
                  <DivisionSupply
                    supply1={divisionData.division1.supply}
                    supply2={divisionData.division2.supply}
                  />
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: '부가세',
        dataIndex: 'product_VAT_price',
        key: 'product_VAT_price',
        width: '12.5%',
        align: 'center',
        render: (value, data) => {
          let isDivision = false;
          if (data.key) {
            isDivision = data.key === division.productData.key;
          }
          if (data.id) {
            isDivision = data.id === division.productData.id;
          }
          if (data.is_account_dc) {
            return <div />;
          }
          return (
            <div>
              {/* 분할 상태가 아닐 때 */}
              {!isDivision && data.discount_rate > 0 && (
                <>
                  <OriginVal isDivision={isDivision}>
                    {data.discounted_vat.toLocaleString()}원
                  </OriginVal>
                  <NotDcVal>{value.toLocaleString()}원</NotDcVal>
                </>
              )}
              {!isDivision &&
                (data.discount_rate === 0 || !data.discount_rate) && (
                  <OriginVal>{value.toLocaleString()}원</OriginVal>
                )}
              {/* 분할 상태일 때 */}
              {isDivision && data.discount_rate > 0 && (
                <div style={{ textDecoration: 'line-through', color: 'gray' }}>
                  {data.discounted_vat.toLocaleString()}원
                </div>
              )}
              {isDivision && data.discount_rate === 0 && (
                <div style={{ textDecoration: 'line-through', color: 'gray' }}>
                  {data.product_VAT_price.toLocaleString()}원
                </div>
              )}
              {isDivision && (
                <DivisionVat
                  vat1={divisionData.division1.vat}
                  vat2={divisionData.division2.vat}
                />
              )}
            </div>
          );
        },
      },
      {
        title: '합계',
        key: 'total',
        width: '12.5%',
        align: 'center',
        render: (value, data) => {
          const {
            product_supply_price,
            product_VAT_price,
            discounted_supply,
            discounted_vat,
          } = value;
          const total = product_supply_price + product_VAT_price;
          const dcTotal = discounted_supply + discounted_vat;
          let isDivision = false;
          if (data.key) {
            isDivision = data.key === division.productData.key;
          }
          if (data.id) {
            isDivision = data.id === division.productData.id;
          }
          return (
            <div>
              {/* 분할 상태가 아닐 때 */}
              {!isDivision && data.discount_rate > 0 && (
                <>
                  <OriginVal isDivision={isDivision}>
                    {dcTotal.toLocaleString()}원
                  </OriginVal>
                  <NotDcVal>{total.toLocaleString()}원</NotDcVal>
                </>
              )}
              {!isDivision &&
                (data.discount_rate === 0 || !data.discount_rate) && (
                  <OriginVal>{total.toLocaleString()}원</OriginVal>
                )}
              {/* 분할 상태일 때 */}
              {isDivision && data.discount_rate > 0 && (
                <div style={{ textDecoration: 'line-through', color: 'gray' }}>
                  {dcTotal.toLocaleString()}원
                </div>
              )}
              {isDivision && data.discount_rate === 0 && (
                <div style={{ textDecoration: 'line-through', color: 'gray' }}>
                  {total.toLocaleString()}원
                </div>
              )}
              {isDivision && (
                <DivisionTotal
                  total1={divisionData.division1.total}
                  total2={divisionData.division2.total}
                />
              )}
            </div>
          );
        },
      },
      {
        title: '출하창고',
        dataIndex: 'product_warehouse',
        key: 'product_warehouse',
        align: 'center',
        width: '12.5%',
        render: (value, record) => {
          let isDivision = false;
          if (record.key) {
            isDivision = record.key === division.productData.key;
          }
          if (record.id) {
            isDivision = record.id === division.productData.id;
          }
          if (record.is_account_dc) {
            return <div />;
          }
          return (
            <div>
              {division.isDivision && !isDivision && (
                <div className="select-container">
                  <Select
                    disabled={pageType === 'detail'}
                    options={warehouseOptions}
                    onChange={(newValue) =>
                      handleProductWarehouse(newValue, record)
                    }
                    value={value}
                    style={{ width: '100%' }}
                  />
                </div>
              )}
              {!division.isDivision && (
                <div className="select-container">
                  <Select
                    disabled={pageType === 'detail'}
                    options={warehouseOptions}
                    onChange={(newValue) =>
                      handleProductWarehouse(newValue, record)
                    }
                    value={value}
                    style={{ width: '100%' }}
                  />
                </div>
              )}
              {isDivision && (
                <DivisionWarehouse
                  options={warehouseOptions}
                  data={record}
                  selectWhDiv={selectWhDiv}
                  warehouse1={divisionData.division1.warehouse}
                  warehouse2={divisionData.division2.warehouse}
                  isWhEqual={isWhEqual}
                  setIsWhEqual={setIsWhEqual}
                />
              )}
            </div>
          );
        },
      },
    ],
    [
      warehouseOptions,
      handleProductWarehouse,
      pageType,
      setOrderProductDatas,
      division,
      divisionData,
      inputQtyDiv,
      divideProduct,
      isQtyCorrect,
      isWhEqual,
    ]
  );

  useImperativeHandle(ref, () => ({
    handleCreate(newDatas) {
      handleOrderProductDatas(newDatas);
    },
    handleDelete(newDatas) {
      handleOrderProductDatas(newDatas);
    },
    handleQuantity(newDatas) {
      handleOrderProductDatas(newDatas);
    },
    handleRecentOrder(accountCode) {
      getRecentDatas(accountCode);
    },
  }));

  return (
    <>
      <div style={{ position: 'relative' }}>
        {/* 견적서 -> 주문서 전환상태일 때 블라인드 처리 */}
        {window.location.pathname.includes('estimateToOrder') && (
          <Popover content="주문서 전환상태일 때 주문-출고 정보를 수정할 수 없습니다.">
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: '1',
                cursor: 'not-allowed',
              }}
            />
          </Popover>
        )}
        <Table
          columns={orderProductColumns}
          dataSource={orderProductDatas}
          pagination={false}
          loading={loading === true}
          locale={loading === true && { emptyText: '.' }}
          footer={() => (
            <div style={{ textAlign: 'center', margin: '10px 0' }}>
              <span>
                선택된 출고상품 중{' '}
                <Tag>
                  {
                    orderProductDatas.filter((data) => data.is_account_dc)
                      .length
                  }
                </Tag>
                개의 품목이 거래처 할인품목으로 등록되어 있습니다.
              </span>
            </div>
          )}
        />
      </div>
      <div
        style={{
          paddingBottom: '10px',
          fontSize: '16px',
          borderBottom: '1px solid #6d6d6d',
          marginTop: '40px',
        }}
      >
        주문요약
      </div>
      <OrderSummary
        orderProductDatas={orderProductDatas}
        setOrderProductDatas={setOrderProductDatas}
        handleCreate={handleCreate}
        pageType={pageType}
      />
      <div
        style={{
          paddingBottom: '10px',
          fontSize: '16px',
          borderBottom: '1px solid #6d6d6d',
          marginTop: '40px',
        }}
      >
        SMS 전송
      </div>
      <OrderMessenger
        orderProductDatas={orderProductDatas}
        orderData={orderData}
        pageType={pageType}
        setSmsDone={setSmsDone}
        smsDone={smsDone}
        setOrderData={setOrderData}
        handleCreate={handleCreate}
        orderSaleDatas={orderSaleDatas}
      />
    </>
  );
});

export default OrderProductForm;

OrderProductForm.propTypes = {
  pageType: PropTypes.string.isRequired,
  orderProductDatas: PropTypes.instanceOf(Array).isRequired,
  setOrderProductDatas: PropTypes.node.isRequired,
  orderSaleDatas: PropTypes.instanceOf(Array).isRequired,
  orderData: PropTypes.instanceOf(Object).isRequired,
  setSmsDone: PropTypes.bool.isRequired,
  setOrderData: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  smsDone: PropTypes.bool.isRequired,
  dcAccountProductDatas: PropTypes.instanceOf(Array).isRequired,
  setDcAccountProductDatas: PropTypes.func.isRequired,
};

const OriginVal = styled.div`
  text-decoration: ${(props) => (props.isDivision ? 'line-through' : 'none')};
  color: ${(props) => (props.isDivision ? 'gray' : 'black')};
`;
const NotDcVal = styled.div`
  display: ${(props) => (props.isDivision ? 'none' : '')};
  color: gray;
  font-size: 12px;
  left: 10px;
  text-decoration: line-through;
`;
