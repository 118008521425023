import React from 'react';

const DeliveryTracker = () => {
  return (
    <iframe
      src="https://hantondeliverytrack.web.app/#/"
      title="택배배송현황판"
      width="100%"
      height="100%"
      frameBorder="0"
    />
  );
};

export default DeliveryTracker;
