import { Button, Checkbox, Col, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SaveOutlined, SettingOutlined } from '@ant-design/icons';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';
import { asyncDeleteDataFetch } from '../../redux/modules/shared/deleteData';

const ProductSetupModal = (props) => {
  const { setIsOpenProductSetupModal, matchOptionsToUpdatedSetup } = props;
  const dispatch = useDispatch();

  const [setupProducts, setSetupProducts] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  // 취급 여부가 체크된 품목 가져오기
  useEffect(() => {
    const getProductSetupData = async () => {
      const allProduct = await dispatch(
        asyncReadDataFetch({ table: 'product' })
      )
        .unwrap()
        .then((res) => res);
      const savedSetupProduct = await dispatch(
        asyncReadDataFetch({ table: 'inventory_product_setup' })
      )
        .unwrap()
        .then((res) => res);
      const datasWithsetupProperty = allProduct.map((item) => {
        let isSetup = false;
        savedSetupProduct.forEach((setupItem) => {
          const isEqualProductId = item.id === setupItem.product_id;
          if (isEqualProductId) isSetup = true;
        });
        return {
          productId: item.id,
          productCode: item.code,
          isSetup,
          productName: item.name,
        };
      });
      setSetupProducts(datasWithsetupProperty);
    };
    getProductSetupData();
  }, [dispatch]);

  // 취급, 미취급여부 변경하기
  const changeProductSetup = (productId) => {
    const changedDatas = setupProducts.map((item) => {
      const isEqualProductId = item.productId === productId;
      if (isEqualProductId) {
        return { ...item, isSetup: !item.isSetup };
      } else {
        return { ...item };
      }
    });
    setSetupProducts(changedDatas);
  };

  // 취급, 미취급여부 전체 변경하기
  const allChangeProductSetup = (e) => {
    const { name } = e.target;
    let allChangedDatas = [];
    if (name === 'toAllSetup') {
      allChangedDatas = setupProducts.map((item) => ({
        ...item,
        isSetup: true,
      }));
    } else {
      allChangedDatas = setupProducts.map((item) => ({
        ...item,
        isSetup: false,
      }));
    }
    setSetupProducts(allChangedDatas);
  };

  // 변경된 취급, 미취급여부 저장하기
  const submitChangedSetup = async () => {
    const confirmSubmit = window.confirm(
      '변경하신 취급품목을 저장하시겠습니까?'
    );
    if (!confirmSubmit) return;

    try {
      await setIsSubmitLoading(true);
      const savedSetupProducts = await dispatch(
        asyncReadDataFetch({ table: 'inventory_product_setup' })
      )
        .unwrap()
        .then((res) => res);
      // 현재 isSetup이 true인데 db에 저장되어있지 않은 경우
      const isSetupTrueButNotSavedArr = setupProducts
        .filter((newItem) => newItem.isSetup)
        .filter((newItem) => {
          let isSetupTrueButNotSaved = true;
          savedSetupProducts.forEach((savedItem) => {
            if (newItem.productId === savedItem.product_id)
              isSetupTrueButNotSaved = false;
          });
          return isSetupTrueButNotSaved;
        });
      for (const itemToCreate of isSetupTrueButNotSavedArr) {
        await dispatch(
          asyncCreateDataFetch({
            table: 'inventory_product_setup',
            product_id: itemToCreate.productId,
          })
        );
      }
      // 현재 isSetup이 false이면서 db에 저장되어 있는 경우
      const isSetupFalseButSavedArr = savedSetupProducts.filter((savedItem) => {
        let isSetupFalseButSaved = false;
        setupProducts
          .filter((newItem) => !newItem.isSetup)
          .forEach((newItem) => {
            if (savedItem.product_id === newItem.productId)
              isSetupFalseButSaved = true;
          });
        return isSetupFalseButSaved;
      });
      for (const itemToDelete of isSetupFalseButSavedArr) {
        await dispatch(
          asyncDeleteDataFetch({
            table: 'inventory_product_setup',
            id: itemToDelete.id,
          })
        );
      }
      await setIsSubmitLoading(false);
      alert('취급품목의 변경사항이 저장되었습니다.');
      matchOptionsToUpdatedSetup(setupProducts.filter((item) => item.isSetup));
    } catch (error) {
      await setIsSubmitLoading(false);
      console.log(error);
      alert(error);
    }
  };

  return (
    <Modal
      width={700}
      centered
      open
      title="재고에서 취급할 품목을 선택해주세요."
      footer={[
        <Button onClick={() => setIsOpenProductSetupModal(false)}>닫기</Button>,
        <Button
          type="primary"
          onClick={submitChangedSetup}
          loading={isSubmitLoading}
        >
          <SaveOutlined /> 저장
        </Button>,
      ]}
      onCancel={() => setIsOpenProductSetupModal(false)}
    >
      <div
        style={{
          display: 'flex',
          overflow: 'auto',
          maxHeight: '700px',
          width: '100%',
          backgroundColor: '#f7f7f7',
          padding: '0 15px',
          borderRadius: '20px',
          margin: '15px 0',
        }}
      >
        {setupProducts.length === 0 && (
          <Spin
            size="large"
            style={{
              height: '680px',
            }}
          />
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
          }}
        >
          <div
            style={{ margin: '10px 0', fontWeight: '600', fontSize: '15px' }}
          >
            미취급 품목
          </div>
          <Checkbox
            name="toAllSetup"
            checked={false}
            onChange={(e) => allChangeProductSetup(e)}
          >
            ALL / 전체
          </Checkbox>
          {setupProducts
            .filter((item) => !item.isSetup)
            .map((item) => (
              <Checkbox
                checked={item.isSetup}
                key={item.productId}
                onChange={() => changeProductSetup(item.productId)}
              >
                {item.productCode} / {item.productName}
              </Checkbox>
            ))}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          <div
            style={{ margin: '10px 0', fontWeight: '600', fontSize: '15px' }}
          >
            취급 품목
          </div>
          <Checkbox
            name="toAllNotSetup"
            checked
            onChange={(e) => allChangeProductSetup(e)}
          >
            ALL / 전체
          </Checkbox>
          {setupProducts
            .filter((item) => item.isSetup)
            .map((item) => (
              <Checkbox
                checked={item.isSetup}
                key={item.productId}
                onChange={() => changeProductSetup(item.productId)}
              >
                {item.productCode} / {item.productName}
              </Checkbox>
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default ProductSetupModal;

ProductSetupModal.propTypes = {
  setIsOpenProductSetupModal: PropTypes.func.isRequired,
  matchOptionsToUpdatedSetup: PropTypes.func.isRequired,
};
