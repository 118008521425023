import React, { useMemo } from 'react';
import {
  SolutionOutlined,
  FileAddOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Description from '../components/Description';

const CreateAccount = () => {
  // 해당 table 선언하기
  const table = 'account';
  // 해당 pageType
  const pageType = 'create';

  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <SolutionOutlined />,
        breadCrumbNav: ' 거래처',
      },
      {
        breadCrumbIcon: <PlusOutlined />,
        breadCrumbNav: ' 추가',
      },
    ],
    []
  );

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px' }}>
        <Description table={table} pageType={pageType} />
      </div>
    </LayoutComponent>
  );
};

export default CreateAccount;
