import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Empty, Modal, Progress, Timeline } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const DiscountInfo = (props) => {
  const { data } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDiscounted, setIsDiscounted] = useState(false);

  useEffect(() => {
    if (data.discount_rate && data.discount_rate > 0) {
      setIsDiscounted(true);
    }
  }, [data]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        onClick={showModal}
        size="small"
        style={{ width: '100%' }}
        type="primary"
      >
        <SearchOutlined />
        할인정보
      </Button>
      <Modal
        title="품목별 할인정보"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        {isDiscounted ? (
          <div
            style={{
              margin: '40px 0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div style={{ fontSize: '18px', marginBottom: '30px' }}>
              {data.product_name} / {data.product_code}
            </div>
            <div style={{ display: 'flex', marginTop: '30px' }}>
              <Progress
                type="circle"
                percent={data.discount_rate}
                style={{ marginTop: '15px' }}
              />
              <Timeline style={{ marginLeft: '50px' }}>
                <Timeline.Item>
                  <div style={{ fontSize: '16px' }}>상품금액</div>
                  <div style={{ color: 'gray' }}>
                    {(
                      data.product_supply_price + data.product_VAT_price
                    ).toLocaleString()}
                    원
                  </div>
                </Timeline.Item>
                <Timeline.Item>
                  <div style={{ fontSize: '16px' }}>할인금액</div>
                  <div style={{ color: 'gray' }}>
                    -{data.discount.toLocaleString()}원
                  </div>
                </Timeline.Item>
                <Timeline.Item>
                  <div style={{ fontSize: '16px' }}>판매금액</div>
                  <div style={{ color: 'gray' }}>
                    {data.discounted.toLocaleString()}원
                  </div>
                </Timeline.Item>
              </Timeline>
            </div>
          </div>
        ) : (
          <div>
            <Empty
              description={
                <div style={{ color: 'gray' }}>
                  할인이 적용되지 않은 품목입니다.
                </div>
              }
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default DiscountInfo;

DiscountInfo.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};
