import React from 'react';

import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { useInvoiceContext } from '../../contexts/InvoiceContext';
import EmptyBox from './EmptyBox';

const InvoiceStatus = () => {
  const {
    invoiceColumns,
    groupedInvoices,
    summaryInvoices,
    accountInfo,
    downloadInvoiceFile,
  } = useInvoiceContext();

  if (!groupedInvoices.length) {
    return <EmptyBox />;
  }

  return (
    <Table
      dataSource={groupedInvoices}
      columns={invoiceColumns}
      footer={() => (
        <div
          style={{
            color: 'gray',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <p>
              거래처: {accountInfo.name} ({accountInfo.contact})
            </p>
            <p>주소: {accountInfo.address}</p>
          </div>
          <p>
            <Button
              style={{
                borderRadius: '20px',
                backgroundColor: '#1d6f42',
                color: 'white',
              }}
              onClick={() => {
                downloadInvoiceFile();
              }}
            >
              <FileExcelOutlined />
              거래명세서
            </Button>
          </p>
        </div>
      )}
      summary={() => {
        const emptyCell = Array.from({ length: 2 });
        return (
          <Table.Summary.Row
            style={{ backgroundColor: '#f7f7f7', textAlign: 'center' }}
          >
            {emptyCell.map((_) => (
              <Table.Summary.Cell />
            ))}
            <Table.Summary.Cell>
              {summaryInvoices.quantity.toLocaleString()}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {summaryInvoices.price.toLocaleString()}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {summaryInvoices.supply.toLocaleString()}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {summaryInvoices.vat.toLocaleString()}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {summaryInvoices.sum.toLocaleString()}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
    />
  );
};

export default InvoiceStatus;
