import React from 'react';
import PropTypes from 'prop-types';

// datepicker 한글화
import 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ko';
import locale from 'antd/lib/locale/ko_KR';
import { ConfigProvider } from 'antd';

const CalendarTranslator = ({ children }) => {
  return <ConfigProvider locale={locale}>{children}</ConfigProvider>;
};

export default CalendarTranslator;

CalendarTranslator.propTypes = {
  children: PropTypes.node.isRequired,
};
