import React, { useState, useEffect, useCallback } from 'react';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Button } from 'antd';
import { RedoOutlined } from '@ant-design/icons';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';

import {
  darkModeColors,
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  lightModeColors,
  LIGHT_MODE_BACKGROUND,
  LIGHT_MODE_FONT_COLOR,
} from '../../constants';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import AbsoluteLeftText from '../../shared/components/AbsoluteLeftText';
import useSelectRange from '../../hook/useSelectRange';
import useHeadcount from '../../hook/useGetHeadcountData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const localStorageKey = 'monthlyChartRange';

const ThreeYearsMonthlyChart = () => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.darkMode);
  const Colors = darkMode ? darkModeColors : lightModeColors;

  const [chartData, setChartData] = useState({
    data: [],
    range: { start: null, end: null },
  });

  const getInitialData = useCallback(async () => {
    const fetchedChartData = await dispatch(
      asyncReadDataFetch({ table: 'v_monthly_sales_amount' })
    ).unwrap();
    return {
      data: fetchedChartData,
      range: {
        start: fetchedChartData[0].year_month,
        end: fetchedChartData[fetchedChartData.length - 1].year_month,
      },
    };
  }, []);

  const filterData = useCallback((data, selectedRange) => {
    return data.filter(
      (item) =>
        dayjs(item.year_month) >= dayjs(selectedRange.start) &&
        dayjs(item.year_month) <= dayjs(selectedRange.end)
    );
  }, []);

  const handleChartData = useCallback(async (isSelected, selectedRange) => {
    let newChartData;

    if (isSelected) {
      const initialData =
        chartData.data.length > 0 ? chartData.data : await getInitialData();
      newChartData = {
        data: filterData(initialData.data, selectedRange),
        range: selectedRange,
      };
    } else {
      newChartData = await getInitialData();
    }

    setChartData(newChartData);
  }, []);

  const [selectedRange, isSelected, selectRange, initialize] = useSelectRange(
    handleChartData,
    localStorageKey
  );

  useEffect(() => {
    handleChartData(isSelected, selectedRange);
  }, [isSelected]);

  const headcount = useHeadcount(isSelected, chartData.range);

  const options = {
    aspectRatio: 10.5,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 20,
        },
      },
      y: {
        min: 0,
        max: 400000000,
        ticks: {
          stepSize: 100000000,
          callback: (value) => {
            if (value === 0) {
              return value;
            }
            const stepSize = String(value);
            return `${stepSize.slice(0, 2)}천만`;
          },
        },
      },
      y1: {
        min: 0,
        max: 20,
        ticks: {
          callback: () => null,
        },
      },
    },
    onClick: (_, el) => {
      if (!el || el.length <= 0 || isSelected) {
        return;
      }
      const selectedIndex = el[0].index;
      const date = chartData.data[selectedIndex].year_month;
      selectRange(date, localStorageKey);
    },
  };

  const data = {
    labels: chartData.data.map((item) => {
      const [year] = item.year_month.split('-');
      return `${dayjs(item.year_month).format('MMMM').slice(0, 3)} ${year}`;
    }),
    datasets: [
      {
        type: 'line',
        label: '월별 직원수',
        data: headcount.map((item) => item.headcount),
        backgroundColor: Colors.armsone_grey,
        borderColor: Colors.chart_bg_1,
        yAxisID: 'y1',
      },
      {
        label: '월별 매출액',
        data: chartData.data.map((item) => item.monthly_sales_amount),
        backgroundColor: () =>
          chartData.data.map((item) => {
            const yearMonth = item.year_month;

            const isSelectedYearMonth =
              selectedRange.start === yearMonth ||
              selectedRange.end === yearMonth;

            if (isSelectedYearMonth && !isSelected) {
              return Colors.armsone_blue;
            }

            const [_, month] = yearMonth.split('-');
            return month === '01' ? Colors.armsone_grey : Colors.chart_bg_1;
          }),
        hoverBackgroundColor: !isSelected && Colors.armsone_blue,
      },
    ],
  };

  return (
    <div
      style={{
        padding: '10px',
        // border: '1px solid #efefef',
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
        color: darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR,
        borderRadius: '15px',
      }}
    >
      <div
        style={{
          color: 'gray',
          marginBottom: '5px',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AbsoluteLeftText
          left="3px"
          text={`범위 ${chartData.range.start}~${chartData.range.end}`}
          color={isSelected && Colors.armsone_blue}
        />
        <h4 style={{ color: 'gray', marginBottom: '5px', textAlign: 'center' }}>
          Sales graph
        </h4>
        <Button
          size="small"
          style={{
            right: '3px',
            position: 'absolute',
            width: '16px',
            height: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
            color: 'white',
            fontSize: '10px',
            backgroundColor: isSelected
              ? Colors.armsone_blue
              : Colors.armsone_grey,
          }}
          disabled={!isSelected}
          onClick={initialize}
        >
          <RedoOutlined />
        </Button>
      </div>
      <Bar options={options} data={data} />
    </div>
  );
};

export default ThreeYearsMonthlyChart;
