import { ArrowRightOutlined, CalculatorOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import CalcDatas from '../components/CalcDatas';
import CalcResult from '../components/CalcResult';

const Calculator = () => {
  const history = useHistory();
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <CalculatorOutlined />,
        breadCrumbNav: ' 계산기',
      },
    ],
    []
  );
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px', position: 'relative' }}>
        <div
          style={{
            fontSize: '16px',
            width: '100%',
            paddingBottom: '10px',
            borderBottom: '1px solid gray',
          }}
        >
          계산기 입력
        </div>
        <CalcDatas />
        <div
          style={{
            fontSize: '16px',
            width: '100%',
            paddingBottom: '10px',
            borderBottom: '1px solid gray',
            marginTop: '50px',
          }}
        >
          계산 결과
        </div>
        <CalcResult />
      </div>
    </LayoutComponent>
  );
};

export default Calculator;
