import React, { useMemo, useState } from 'react';
import { AlertOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { useDispatch } from 'react-redux';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import ErrorDatas from '../components/ErrorDatas';
import ecount from '../../ecount';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';

const SyncError = () => {
  const dispatch = useDispatch();

  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <AlertOutlined />,
        breadCrumbNav: ' 싱크에러현황',
      },
    ],
    []
  );

  const { saveSalesInquiry } = ecount();

  const [isShowStatusData, setIsShowStatusData] = useState(false);
  const [orderId, setOrderId] = useState('');

  const handleSaveEcountData = async () => {
    try {
      const order = await dispatch(
        asyncReadSpecificDataFetch({ table: 'order', id: orderId })
      )
        .unwrap()
        .then((res) => res[0]);

      const {
        account_code,
        order_date,
        account_name,
        admin_code,
        warehouse_code,
        deal_type,
      } = order;

      const values = {
        id: orderId,
        account_code,
        order_date,
        account_name,
        admin_code,
        warehouse_code,
        deal_type,
      };

      await saveSalesInquiry(values);

      setOrderId('');
    } catch (error) {
      window.alert(error);
    }
  };

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div
        style={{
          padding: '30px',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          gap: 10,
          borderRadius: '20px',
        }}
      >
        <Input
          placeholder="인증 id값 입력"
          onChange={(e) => setOrderId(e.target.value)}
          value={orderId}
        />
        <Button onClick={handleSaveEcountData}>등록</Button>
      </div>
      <Button
        style={{ textAlign: 'center', margin: '20px 0', width: '100%' }}
        onClick={() => setIsShowStatusData((prev) => !prev)}
      >
        <ArrowDownOutlined />
      </Button>
      {isShowStatusData && <ErrorDatas />}
    </LayoutComponent>
  );
};
export default SyncError;
