import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useInvoiceHook from '../hook/useInvoiceHook';

// Context 생성
const InvoiceContext = createContext(undefined);

// Provider 컴포넌트
export const InvoiceProvider = ({ children }) => {
  const InvoiceState = useInvoiceHook(); // Hook 사용하여 상태 관리

  return (
    <InvoiceContext.Provider value={InvoiceState}>
      {children}
    </InvoiceContext.Provider>
  );
};

// Context를 가져오는 Hook (Context를 사용할 때만 호출)
export const useInvoiceContext = () => {
  const context = useContext(InvoiceContext);
  return context;
};

InvoiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
