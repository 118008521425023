import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Input, Select, Spin } from 'antd';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';

const EstimateBasicInfo = (props) => {
  const { basicInfoLoading, formValue, setFormValue } = props;
  const dispatch = useDispatch();

  const [adminOption, setAdminOption] = useState([]);
  const [accountOption, setAccountOption] = useState([]);
  const [warehouseOption, setWarehouseOption] = useState([]);

  // 견적서 form option 생성하기
  useEffect(() => {
    const getFormOption = async () => {
      try {
        // 담당자 option
        await dispatch(asyncReadDataFetch({ table: 'profiles' }))
          .unwrap()
          .then((res) =>
            res.map((data) => {
              const { username, code } = data;
              return { label: `${code} / ${username}`, value: code };
            })
          )
          .then((res) => setAdminOption(res));
        // 창고 option
        await dispatch(asyncReadDataFetch({ table: 'warehouse' }))
          .unwrap()
          .then((res) =>
            res.map((data) => {
              const { name, code } = data;
              return { label: `${code} / ${name}`, value: code };
            })
          )
          .then((res) => setWarehouseOption(res));
        // 거래처 option
        await dispatch(asyncReadDataFetch({ table: 'account' }))
          .unwrap()
          .then((res) =>
            res.map((data) => {
              const { name, code } = data;
              return { label: `${code} / ${name}`, value: code };
            })
          )
          .then((res) => setAccountOption(res));
      } catch (error) {
        console.log(error);
        alert(error);
      }
    };
    getFormOption();
  }, [dispatch]);

  return (
    <div
      style={{
        borderRadius: '10px',
        paddingTop: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingBottom: '15px',
        border: '1px solid lightgray',
        backgroundColor: '#fafafa',
        marginBottom: '10px',
        position: 'relative',
        minHeight: '155px',
      }}
    >
      {basicInfoLoading ? (
        <Spin />
      ) : (
        <form
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            margin: 'auto',
            justifyContent: 'space-between',
          }}
          className="select-container"
        >
          {/* 일자 */}
          <FormRow>
            <FormLabel>일자</FormLabel>
            <DatePicker
              style={{ borderRadius: '0', width: '80%' }}
              value={dayjs(formValue.date)}
              onChange={(value) =>
                setFormValue((prev) => ({
                  ...prev,
                  date: value.format('YYYY-MM-DD'),
                }))
              }
              size="small"
            />
          </FormRow>
          {/* 담당자 */}
          <FormRow>
            <FormLabel>담당자</FormLabel>
            <Select
              style={{ borderRadius: '0', width: '80%' }}
              options={adminOption}
              value={formValue.admin}
              onChange={(value) =>
                setFormValue((prev) => ({ ...prev, admin: value }))
              }
              size="small"
            />
          </FormRow>
          {/* 거래처 */}
          <FormRow>
            <FormLabel>거래처</FormLabel>
            <Select
              style={{
                borderRadius: '0',
                width: '80%',
              }}
              showSearch
              filterOption={(input, option) => {
                return option.label && option.label.includes(input);
              }}
              options={accountOption}
              onChange={(value) =>
                setFormValue((prev) => ({ ...prev, account: value }))
              }
              value={formValue.account}
              size="small"
            />
          </FormRow>
          {/* 거래유형 */}
          <FormRow>
            <FormLabel>거래유형</FormLabel>
            <Select
              style={{ borderRadius: '0', width: '80%' }}
              options={[
                { label: '부가세율 적용', value: '11' },
                { label: '부가세율 미적용', value: '22' },
              ]}
              value={formValue.dealType}
              onChange={(value) =>
                setFormValue((prev) => ({ ...prev, dealType: value }))
              }
              size="small"
            />
          </FormRow>
          {/* 결제조건 */}
          <FormRow>
            <FormLabel>결제조건</FormLabel>
            <Input
              style={{ borderRadius: '0', width: '80%' }}
              value={formValue.paymentTerms}
              onChange={(e) =>
                setFormValue((prev) => ({
                  ...prev,
                  paymentTerms: e.target.value,
                }))
              }
              size="small"
            />
          </FormRow>
          {/* 출하창고 */}
          <FormRow>
            <FormLabel>출하창고</FormLabel>
            <Select
              style={{ borderRadius: '0', width: '80%' }}
              options={warehouseOption}
              value={formValue.warehouse}
              onChange={(value) =>
                setFormValue((prev) => ({ ...prev, warehouse: value }))
              }
              size="small"
            />
          </FormRow>
          {/* 유효기간 */}
          <FormRow>
            <FormLabel>유효기간</FormLabel>
            <Input
              type="number"
              style={{ borderRadius: '0', width: '80%' }}
              value={formValue.expirationDate}
              onChange={(e) => {
                const value = Number(e.target.value);
                setFormValue((prev) => ({ ...prev, expirationDate: value }));
              }}
              size="small"
            />
          </FormRow>
        </form>
      )}
    </div>
  );
};

export default EstimateBasicInfo;

EstimateBasicInfo.propTypes = {
  basicInfoLoading: PropTypes.bool.isRequired,
  formValue: PropTypes.instanceOf(Object).isRequired,
  setFormValue: PropTypes.func.isRequired,
};

const FormRow = styled.div`
  width: 48.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;
const FormLabel = styled.label`
  font-weight: 500;
  color: #545454;
  width: 20%;
`;
