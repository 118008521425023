import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  ArrowDownOutlined,
  DollarCircleOutlined,
  ExportOutlined,
  InboxOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { OrderProductsContext } from '../../providers/OrderProductsProvider';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const ListContainer = styled.div`
  border: 1px solid lightgray;
  width: 200px;
  height: 150px;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 20px;
  box-shadow: 3px 3px 3px 3px #efefef;
  position: relative;
`;
const ExportButton = styled(Button)`
  border: 1px solid lightgray;
  width: 200px;
  height: 150px;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 20px;
  box-shadow: 3px 3px 3px 3px #efefef;
  position: relative;
  background-color: #001528;
`;

const CalcResult = () => {
  const history = useHistory();

  // Context API 사용
  const { orderProducts, loading } = useContext(OrderProductsContext);

  const [resultData, setResultData] = useState({
    supply: 0,
    vat: 0,
    delivery: 0,
    total: 0,
    product: '',
    totalWithDlvy: 0,
    discount: 0,
    discounted: 0,
  });

  useEffect(() => {
    // 출고상품목록 문자열 생성
    let product = '출고상품 없음';
    const datas = orderProducts;
    if (datas.length !== 0) {
      let str = '';
      for (const data of datas) {
        if (
          data.product_code.includes('parcel') &&
          data.product_code.length === 7
        ) {
          str += '';
        } else {
          str += `${data.product_name} / `;
        }
      }
      product = str.slice(0, -2);
    }
    let supply = 0;
    let vat = 0;
    let delivery = 0;
    let discount = 0;
    for (const data of datas) {
      if (
        data.product_code.includes('parcel') &&
        data.product_code.length === 7
      ) {
        const newDelivery = data.product_supply_price + data.product_VAT_price;
        delivery += newDelivery;
      } else {
        supply += data.product_supply_price;
        vat += data.product_VAT_price;
      }
      // 할인 처리
      if (data.discount_rate && data.discount_rate > 0) {
        const { product_VAT_price, product_supply_price, discount_rate } = data;
        const relPrice = product_VAT_price + product_supply_price;
        const dcPrice = (relPrice * discount_rate) / 100;
        discount += dcPrice;
      }
    }
    const total = supply + vat;
    const totalWithDlvy = supply + vat + delivery;
    const discounted = totalWithDlvy - discount;

    setResultData({
      supply: Math.round(supply),
      vat: Math.round(vat),
      delivery: Math.round(delivery),
      total: Math.round(total),
      product,
      totalWithDlvy: Math.round(totalWithDlvy),
      discount: Math.round(discount),
      discounted: Math.round(discounted),
    });
  }, [orderProducts]);

  return (
    <div
      style={{
        borderBottom: '1px solid #efefef',
        padding: '10px',
        position: 'relative',
      }}
    >
      {loading && (
        <Spin
          style={{ position: 'absolute', top: '-37px', left: '70px' }}
          size="large"
        />
      )}
      <Container>
        <ListContainer>
          <div
            style={{
              width: '30px',
              height: '30px',
              backgroundColor: '#f7f7f7',
              borderRadius: '50%',
              zIndex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <InboxOutlined
              style={{ fontSize: '24px', zIndex: '2', color: '#1890ff' }}
            />
          </div>
          <p>출고상품</p>
          <p>{resultData.product}</p>
        </ListContainer>
        <ListContainer>
          <div
            style={{
              width: '30px',
              height: '30px',
              backgroundColor: '#f7f7f7',
              borderRadius: '50%',
              zIndex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <DollarCircleOutlined
              style={{ fontSize: '20px', zIndex: '2', color: '#1890ff' }}
            />
          </div>
          <p>공급가액</p>
          <p>{resultData.supply.toLocaleString()}원</p>
        </ListContainer>
        <ListContainer>
          <div
            style={{
              width: '30px',
              height: '30px',
              backgroundColor: '#f7f7f7',
              borderRadius: '50%',
              zIndex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <DollarCircleOutlined
              style={{ fontSize: '20px', zIndex: '2', color: '#1890ff' }}
            />
          </div>
          <p>부가세</p>
          <p>{resultData.vat.toLocaleString()}원</p>
        </ListContainer>
        <ListContainer style={{ backgroundColor: '#1677ff' }}>
          <div
            style={{
              width: '30px',
              height: '30px',
              backgroundColor: '#f7f7f7',
              borderRadius: '50%',
              zIndex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <DollarCircleOutlined
              style={{ fontSize: '20px', zIndex: '2', color: '#1677ff' }}
            />
          </div>
          <p style={{ fontWeight: 'bold', color: 'white' }}>합계금액</p>
          <p style={{ fontWeight: 'bold', color: 'white' }}>
            {(resultData.supply + resultData.vat).toLocaleString()}원
          </p>
        </ListContainer>
        <ListContainer>
          <div
            style={{
              width: '30px',
              height: '30px',
              backgroundColor: '#f7f7f7',
              borderRadius: '50%',
              zIndex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ShoppingCartOutlined
              style={{ fontSize: '24px', zIndex: '2', color: '#1677ff' }}
            />
          </div>
          <p>택배비</p>
          <p>{resultData.delivery.toLocaleString()}원</p>
        </ListContainer>
        <ListContainer>
          <div
            style={{
              width: '30px',
              height: '30px',
              backgroundColor: '#f7f7f7',
              borderRadius: '50%',
              zIndex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ArrowDownOutlined
              style={{ fontSize: '20px', zIndex: '2', color: '#1677ff' }}
            />
          </div>
          <p style={{ color: 'gray' }}>할인금액</p>
          <p style={{ color: 'gray' }}>
            -{resultData.discount.toLocaleString()}원
          </p>
        </ListContainer>
        <ListContainer style={{ backgroundColor: '#1677ff' }}>
          <div
            style={{
              width: '30px',
              height: '30px',
              backgroundColor: '#f7f7f7',
              borderRadius: '50%',
              zIndex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <DollarCircleOutlined
              style={{ fontSize: '20px', zIndex: '2', color: '#1677ff' }}
            />
          </div>
          <p style={{ fontWeight: 'bold', color: 'white' }}>총액</p>
          <p style={{ fontWeight: 'bold', color: 'white' }}>
            {resultData.discounted.toLocaleString()}원
          </p>
        </ListContainer>
        <ExportButton
          type="primary"
          onClick={() => history.push('/order/createOrder')}
        >
          <div
            style={{
              width: '30px',
              height: '30px',
              backgroundColor: '#f7f7f7',
              borderRadius: '50%',
              zIndex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ExportOutlined
              style={{ fontSize: '20px', zIndex: '2', color: '#001528' }}
            />
          </div>
          <p style={{ fontWeight: 'bold', color: 'white' }}>주문서등록</p>
          <p style={{ fontWeight: 'bold', color: 'white' }}> </p>
        </ExportButton>
      </Container>
    </div>
  );
};

export default CalcResult;
