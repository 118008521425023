import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import { OrderProductsContext } from '../../providers/OrderProductsProvider';
import CalcButtons from './CalcButtons';
import SortOrderSetting from './SortOrderSetting';

const CalcDatas = () => {
  // Context API 사용
  const { setOrderProducts, setOrderSales, setLoading } =
    useContext(OrderProductsContext);

  const dispatch = useDispatch();
  const [saleDatas, setSaleDatas] = useState([]);
  const [selectedDatas, setSelectedDatas] = useState([]);
  const [isReset, setIsReset] = useState(false);

  /*
  // 첫 마운트시 초기화
  useEffect(() => {
    setOrderProducts([]);
    setOrderSales([]);
  }, [setOrderProducts, setOrderSales]);
  */

  // 판매상품 데이터 불러오기
  useEffect(() => {
    const asyncFunc = async () => {
      try {
        const sales = await dispatch(asyncReadDataFetch({ table: 'sale' }))
          .unwrap()
          .then((res) => res);
        const filtered = sales.filter((sale) => sale.sort_order);
        const sorted = filtered.sort((a, b) => {
          return a.sort_order - b.sort_order;
        });
        const newDatas = await Promise.all(
          sorted.map(async (data, index) => {
            const { code, name, sort_order } = data;
            const delivery = await dispatch(
              asyncReadFilteredDataFetch({
                table: 'sale_product',
                eqKey: 'sale_code',
                eqValue: code,
              })
            )
              .unwrap()
              .then((res) => {
                for (const sale of res) {
                  if (sale.product_code.includes('parcel')) {
                    return sale.product_code;
                  }
                }
              });
            return {
              delivery,
              sale_code: code,
              sale_name: name,
              sale_quantity: 0,
              sort_order,
            };
          })
        );
        setSaleDatas([...newDatas]);
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    };
    asyncFunc();
  }, [dispatch]);

  // 판매상품 데이터 선택하기
  const selectDatas = useCallback(
    (value, type, count) => {
      setIsReset(false);
      // count값 다루기
      let newCount = count;
      if (type === 'up') {
        newCount += 1;
      }
      if (type === 'down') {
        newCount -= 1;
      }
      // 판매상품 데이터가 이미 존재하는지 체크
      let isDup = false;
      for (const data of selectedDatas) {
        if (data.sale_code === value.sale_code) {
          isDup = true;
        }
      }
      // 존재하지 않으면 추가
      if (!isDup) {
        setSelectedDatas([
          ...selectedDatas,
          { ...value, sale_quantity: newCount },
        ]);
      }
      // 존재하면 sale_quantity 값 변경 또는 삭제
      if (isDup) {
        // 값 삭제
        if (newCount === 0) {
          const codeArr = selectedDatas.map((data) => data.sale_code);
          const index = codeArr.indexOf(value.sale_code);
          if (index !== -1) {
            selectedDatas.splice(index, 1);
            setSelectedDatas([...selectedDatas]);
          }
          // 값 변경
        } else {
          const newDatas = selectedDatas.map((data) => {
            if (data.sale_code === value.sale_code) {
              return { ...value, sale_quantity: newCount };
            }
            return { ...data };
          });
          setSelectedDatas([...newDatas]);
        }
      }
    },
    [selectedDatas]
  );

  // 선택된 판매상품 데이터 리셋하기
  const resetDatas = useCallback(() => {
    setSelectedDatas([]);
    setOrderProducts([]);
    setIsReset(true);
  }, [setOrderProducts]);

  useEffect(() => {
    const asyncGetOrderProducts = async () => {
      try {
        await setLoading(true);
        await setOrderSales([]);
        await setOrderProducts([]);
        // selectedDatas -> sale_product 변환
        const orderSales = selectedDatas;
        const saleProductsArr = await Promise.all(
          orderSales.map(async (orderSale) => {
            const { sale_code, delivery, sale_quantity } = orderSale;
            const saleProducts = await dispatch(
              asyncReadFilteredDataFetch({
                table: 'sale_product',
                eqKey: 'sale_code',
                eqValue: sale_code,
              })
            )
              .unwrap()
              .then((res) => ({
                saleProducts: res,
                orderSaleQuantity: sale_quantity,
                delivery,
              }));
            return saleProducts;
          })
        );
        // sale_product 데이터 -> order_product 데이터 변환
        const orderProductsArr = [];
        for (const saleProductDatas of saleProductsArr) {
          const { orderSaleQuantity, delivery, saleProducts } =
            saleProductDatas;
          // delivery값 제거? => 일단 필요없는 코드라 구현안함.
          const orderProducts = await Promise.all(
            saleProducts.map(async (saleProduct) => {
              const { sale_code, product_quantity, discount_rate } =
                saleProduct;
              const orderProduct = await dispatch(
                asyncReadFilteredDataFetch({
                  table: 'product',
                  eqKey: 'code',
                  eqValue: saleProduct.product_code,
                })
              )
                .unwrap()
                .then((res) => {
                  const product = res[0];
                  // 개수
                  const quantity = orderSaleQuantity * product_quantity;
                  // 단가
                  const price = product.release_price / 1.1;
                  // 할인된 단가
                  const discounted_price =
                    price - (price * discount_rate) / 100;
                  // 공급가액
                  const supply = quantity * price;
                  // 할인된 공급가액
                  const discounted_supply =
                    supply - (supply * discount_rate) / 100;
                  // 부가세
                  const vat = supply / 10;
                  // 할인된 부가세
                  const discounted_vat = vat - (vat * discount_rate) / 100;
                  // 합계
                  const total = supply + vat;
                  // 할인금액
                  const discount = (total * discount_rate) / 100;
                  // 할인된 금액
                  const discounted = total - discount;
                  return {
                    product_id: product.id,
                    product_code: product.code,
                    product_name: product.name,
                    product_quantity: quantity,
                    product_supply_price: Math.round(supply),
                    discounted_supply: Math.round(discounted_supply),
                    product_VAT_price: Math.round(vat),
                    discounted_vat: Math.round(discounted_vat),
                    product_price: Math.round(price),
                    discounted_price: Math.round(discounted_price),
                    product_warehouse: product.warehouse_code,
                    sale_code,
                    key: uuid(),
                    is_account_dc: false,
                    total: Math.round(total),
                    discount_rate,
                    discount: Math.round(discount),
                    discounted: Math.round(discounted),
                  };
                });
              return orderProduct;
            })
          );
          orderProductsArr.push(...orderProducts);
        }
        // Context API 상태값인 orderProducts 업데이트
        await setOrderProducts([...orderProductsArr]);
        // Context API 상태값인 orderSales 업데이트
        await setOrderSales([...selectedDatas]);
        await setLoading(false);
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    };
    asyncGetOrderProducts();
  }, [selectedDatas, setOrderProducts, dispatch, setOrderSales, setLoading]);

  return (
    <div>
      <SortOrderSetting saleDatas={saleDatas} setSaleDatas={setSaleDatas} />
      <CalcButtons
        saleDatas={saleDatas}
        selectDatas={selectDatas}
        resetDatas={resetDatas}
        isReset={isReset}
        setSaleDatas={setSaleDatas}
      />
    </div>
  );
};

export default CalcDatas;
