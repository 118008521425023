import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { asyncUpdateUserFetch } from '../../redux/modules/auth/updateUser';
import Center from '../../shared/components/Center';
import AuthContainer from '../components/AuthContainer';
import AuthFooter from '../components/AuthFooter';
import ProfileHeader from '../components/ProfileHeader';
import ProfileForm from '../components/ProfileForm';
import MyPasswordResetForm from '../components/MyPasswordResetForm';
import { myPasswordResetApi } from '../../apis/authApi';

const Profile = () => {
  const dispatch = useDispatch();
  const [showResetForm, setShowResetForm] = useState(false);

  const handleEditProfile = async (values) => {
    const confirm = window.confirm('프로필을 수정 하시겠습니까?');
    if (!confirm) return;

    const { front, back, ...formValues } = values;
    const phone = `010-${front}-${back}`;

    const postData = {
      ...formValues,
      phone,
    };

    try {
      await dispatch(asyncUpdateUserFetch(postData)).unwrap();
      alert('프로필을 수정했습니다. 새로고침 해주세요.');
    } catch (error) {
      alert(error);
      throw error;
    }
  };

  const handleResetMyPassword = async (values) => {
    const confirm = window.confirm('정말 비밀번호를 변경 하시겠습니까?');
    if (!confirm) return;

    try {
      await myPasswordResetApi(values);
      alert('비밀번호를 변경했습니다.');
    } catch (error) {
      alert(error);
      throw error;
    }
  };

  return (
    <Center>
      <AuthContainer>
        <ProfileHeader />
        <ProfileForm handleEditProfile={handleEditProfile} />
        <Button
          style={{ width: '100%' }}
          onClick={() => setShowResetForm((prev) => !prev)}
        >
          {!showResetForm ? (
            <p>
              <DownOutlined style={{ marginRight: '10px' }} />
              비밀번호 변경
            </p>
          ) : (
            <UpOutlined />
          )}
        </Button>
        {showResetForm && (
          <MyPasswordResetForm handleResetMyPassword={handleResetMyPassword} />
        )}
      </AuthContainer>
      <AuthFooter />
    </Center>
  );
};

export default Profile;
