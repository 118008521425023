import React from 'react';
import PropTypes from 'prop-types';
import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const ButtonStyle = styled(Button)`
  width: 100%;
  padding: 8px;
  height: 40px;
  font-weight: 700;
  font-size: 14.5px;
  background: black;
  color: white;
`;

const AccessComponent = (props) => {
  const { text, button, isLoggedIn } = props;
  const history = useHistory();
  return (
    <Result
      status="403"
      title="403"
      subTitle={`${text} 상태에서는 접근할 수 없습니다.`}
      extra={
        isLoggedIn ? (
          <ButtonStyle
            type="primary"
            onClick={() => history.push('/data-calendar')}
          >
            {button}
          </ButtonStyle>
        ) : (
          <ButtonStyle type="primary" onClick={() => history.push('/')}>
            {button}
          </ButtonStyle>
        )
      }
    />
  );
};

export default AccessComponent;

AccessComponent.propTypes = {
  text: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};
