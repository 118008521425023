import dayjs from 'dayjs';
import { useState } from 'react';

const useDateRange = () => {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const formatDate = (date) => {
    return date.format('YYYY-MM-DD');
  };

  const unFormatDate = (formattedDate) => {
    return dayjs(formattedDate);
  };

  const handleDateRange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  return [dateRange, handleDateRange, formatDate, unFormatDate];
};

export default useDateRange;
