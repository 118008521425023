import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Statistic } from 'antd';
import CountUp from 'react-countup';
import { TeamOutlined } from '@ant-design/icons';

import { DARK_MODE_BACKGROUND, LIGHT_MODE_BACKGROUND } from '../../constants';

const NumberOfAccountByRegion = (props) => {
  const { darkMode, selectedRegion, numberOfAccountDatasByRegion } = props;

  const [numberOfAccount, setNumberOfAccount] = useState(0);

  useEffect(() => {
    const filteredNumberOfAccountDataByRegion =
      numberOfAccountDatasByRegion.filter(
        (item) => item.value === selectedRegion
      );
    const newNumberOfAccount =
      filteredNumberOfAccountDataByRegion[0]?.amount || 0;
    setNumberOfAccount(newNumberOfAccount);
  }, [numberOfAccountDatasByRegion]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '10px 20px',
        borderRadius: '10px',
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
      }}
    >
      <h4>{selectedRegion} 거래처수</h4>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <TeamOutlined style={{ position: 'absolute', left: '0px' }} />
        <h3>{numberOfAccount}</h3>
      </div>
    </div>
  );
};

export default NumberOfAccountByRegion;

NumberOfAccountByRegion.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  selectedRegion: PropTypes.string.isRequired,
  numberOfAccountDatasByRegion: PropTypes.instanceOf(Array).isRequired,
};
