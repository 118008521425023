import { useCallback, useEffect, useState } from 'react';

const useGetCourierStatusData = (firestoreData) => {
  const [courierStatusData, setCourierStatusData] = useState({
    hanjin: 0,
    hanjinDone: 0,
    cjlogistics: 0,
    cjlogisticsDone: 0,
    kunyoung: 0,
    kunyoungDone: 0,
    logen: 0,
    logenDone: 0,
    kdexp: 0,
    kdexpDone: 0,
  });

  const generateCourierStatusData = useCallback(
    (firestoreData) => {
      const statusData = {
        hanjin: 0,
        hanjinDone: 0,
        cjlogistics: 0,
        cjlogisticsDone: 0,
        kunyoung: 0,
        kunyoungDone: 0,
        logen: 0,
        logenDone: 0,
        kdexp: 0,
        kdexpDone: 0,
      };

      firestoreData.forEach(({ carrierId, statusId }) => {
        switch (carrierId) {
          case 'kr.hanjin':
            statusData.hanjin += 1;
            if (statusId === 'delivered') statusData.hanjinDone += 1;
            break;
          case 'kr.cjlogistics':
            statusData.cjlogistics += 1;
            if (statusId === 'delivered') statusData.cjlogisticsDone += 1;
            break;
          case 'kr.kunyoung':
            statusData.kunyoung += 1;
            if (statusId === 'delivered') statusData.kunyoungDone += 1;
            break;
          case 'kr.logen':
            statusData.logen += 1;
            if (statusId === 'delivered') statusData.logenDone += 1;
            break;
          case 'kr.kdexp':
          case 'kr.honamlogis':
            statusData.kdexp += 1;
            if (statusId === 'delivered') statusData.kdexpDone += 1;
            break;
          default:
            break;
        }
      });

      setCourierStatusData(statusData);
    },
    [firestoreData]
  );

  useEffect(() => {
    generateCourierStatusData(firestoreData);
  }, [firestoreData]);

  return courierStatusData;
};

export default useGetCourierStatusData;
