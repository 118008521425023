import React from 'react';
import { Modal, Result, Button } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useModal from '../../hook/useModal';

import { logoutReducer } from '../../redux/modules/auth/signinUser';
import { asyncSignoutUserFetch } from '../../redux/modules/auth/signoutUser';

const ButtonStyle = styled(Button)`
  width: 60%;
  padding: 8px;
  height: 40px;
  font-weight: 700;
  font-size: 14.5px;
  background: black;
  color: white;
`;

const LogoutModal = () => {
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoadingSignoutUser } = useSelector((state) => state.signoutUser);

  const handleSubmit = async () => {
    dispatch(asyncSignoutUserFetch()).then((res) => {
      closeModal();
      dispatch(logoutReducer(res.payload));
      history.push('/');
    });
  };

  return (
    <Modal
      title="로그아웃"
      centered
      open
      onCancel={() => closeModal()}
      footer={[<div />]}
    >
      <Result
        title="로그아웃 하시겠습니까?"
        extra={[
          isLoadingSignoutUser ? (
            <ButtonStyle key="password" loading disabled>
              네
            </ButtonStyle>
          ) : (
            <ButtonStyle key="password" onClick={() => handleSubmit()}>
              네
            </ButtonStyle>
          ),
        ]}
      />
    </Modal>
  );
};

export default LogoutModal;
