import axiosInstance from '../axios';
import useSupabase from '../serverConfig';
import supabase from '../supabase';

const useQueryFunctions = () => {
  // 재고
  const fnInsertInventory = async ({
    changeQuantity,
    productId,
    warehouseId,
    accountId,
    adminId,
    causeId,
    causeOfChange,
    causeState,
  }) => {
    const body = {
      quantity: changeQuantity,
      productid: productId,
      warehouseid: warehouseId,
      accountid: accountId,
      adminid: adminId,
      causeid: causeId,
      causeofchange: causeOfChange,
      causestate: causeState,
    };

    const { data } = useSupabase
      ? await supabase.rpc('fn_insert_inventory_v7', body)
      : await axiosInstance.post('/api/query-function', body);

    console.log(data);
  };

  // 재고 조정
  const fnInsertInventoryAdjustment = async ({
    nextStackQuantity,
    productId,
    warehouseId,
    adminId,
    causeOfChange,
  }) => {
    const body = {
      nextstackquantity: nextStackQuantity,
      productid: productId,
      warehouseid: warehouseId,
      adminid: adminId,
      causeofchange: causeOfChange,
    };

    const { data } = useSupabase
      ? await supabase.rpc('fn_insert_inventory_adjustment_v2', body)
      : await axiosInstance.post('/api/query-function/adjustment', body);

    console.log(data);
  };

  return {
    fnInsertInventory,
    fnInsertInventoryAdjustment,
  };
};

export default useQueryFunctions;
