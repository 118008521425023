import { DashboardOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import CounsellingForm from '../components/CounsellingForm';

const CounsellingCreate = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <DashboardOutlined />,
        breadCrumbNav: ' 신규상담 게시판',
      },
    ],
    []
  );

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <CounsellingForm pageType="create" />
    </LayoutComponent>
  );
};

export default CounsellingCreate;
