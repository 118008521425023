import { useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { asyncReadRangeOrDataFetch } from '../redux/modules/shared/readRangeOrData';
import { asyncReadDataFetch } from '../redux/modules/shared/readData';
import { asyncReadMatchLteDataFetch } from '../redux/modules/shared/readMatchLteData';

const useGetInventoryReceiptsData = () => {
  const dispatch = useDispatch();

  const [receiptsData, setReceiptsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const generateOrConditions = ({ products, warehouses }) => {
    return products.map((product) =>
      warehouses
        .map(
          (warehouse) =>
            `and(product_id.eq.${product.id},warehouse_id.eq.${warehouse.id})`
        )
        .join()
    );
  };

  const getOrConditions = ({
    formProducts,
    formWarehouses,
    optionProducts,
    optionWarehouses,
  }) => {
    if (!formProducts.length && formWarehouses.length) {
      return generateOrConditions({
        products: optionProducts,
        warehouses: formWarehouses,
      });
    }
    if (formProducts.length && !formWarehouses.length) {
      return generateOrConditions({
        products: formProducts,
        warehouses: optionWarehouses,
      });
    }
    if (!formProducts.length && !formWarehouses.length) {
      return generateOrConditions({
        products: optionProducts,
        warehouses: optionWarehouses,
      });
    }
    if (formProducts.length && formWarehouses.length) {
      return generateOrConditions({
        products: formProducts,
        warehouses: formWarehouses,
      });
    }
  };

  const fetchInventoryData = async ({ orConditions, dateRange }) => {
    const fetchedData = [];

    const requestData = {
      table: 'inventory',
      gteKey: 'inventory_change_date',
      lteKey: 'inventory_change_date',
      gteValue: dateRange.startDate,
      lteValue: dateRange.endDate,
    };
    for (const orCondition of orConditions) {
      const inventoryData = await dispatch(
        asyncReadRangeOrDataFetch({ ...requestData, orString: orCondition })
      ).unwrap();

      if (inventoryData.length) {
        const productId = inventoryData[0].product_id;
        fetchedData.push({ productId, inventoryData });
      }
    }

    return fetchedData;
  };

  const groupByWarehouseId = (inventoryData) => {
    return inventoryData.map((data) => {
      const { productId, inventoryData } = data;

      const groupedData = [];

      for (const currentData of inventoryData) {
        const warehouseId = currentData.warehouse_id;
        const equalIdx = groupedData.findIndex(
          (item) => item.warehouseId === warehouseId
        );
        if (equalIdx > -1) {
          groupedData[equalIdx].data.push(currentData);
        } else {
          groupedData.push({ warehouseId, data: [currentData] });
        }
      }

      return { productId, groupedData };
    });
  };

  const fetchTableDataById = async (table) => {
    return dispatch(asyncReadDataFetch({ table })).unwrap();
  };

  const filterTableDataById = ({ tableData, id }) => {
    return tableData.find((data) => data.id === id) || null;
  };

  const getTableDataById = async () => {
    const productData = await fetchTableDataById('product');
    const adminData = await fetchTableDataById('profiles');
    const accountData = await fetchTableDataById('account');
    const warehouseData = await fetchTableDataById('warehouse');
    return { productData, adminData, accountData, warehouseData };
  };

  const getPrevStackQuantity = async ({
    warehouseId,
    productId,
    startDate,
  }) => {
    const requestData = {
      table: 'inventory',
      match: {
        warehouse_id: warehouseId,
        product_id: productId,
      },
      lteKey: 'inventory_change_date',
      lteValue: dayjs(startDate).subtract(1, 'day').format('YYYY-MM-DD'),
      order: 'reg_date',
      limit: 1,
    };

    const prevData = await dispatch(
      asyncReadMatchLteDataFetch(requestData)
    ).unwrap();

    return prevData[0]?.stack_quantity || 0;
  };

  const mapInventoryData = async ({ inventoryData, dateRange }) => {
    const { productData, adminData, accountData, warehouseData } =
      await getTableDataById();
    const { startDate } = dateRange;

    return Promise.all(
      inventoryData.map(async ({ productId, groupedData }) => {
        const mappedProduct = filterTableDataById({
          tableData: productData,
          id: productId,
        });

        const mappedGroupedData = await Promise.all(
          groupedData.map(async ({ warehouseId, data: items }) => {
            const mappedWarehouse = filterTableDataById({
              tableData: warehouseData,
              id: warehouseId,
            });

            const prevStackQuantity = await getPrevStackQuantity({
              warehouseId,
              productId,
              startDate,
            });

            const mappedData = items.map((item) => {
              const { account_id: accountId, admin_id: adminId } = item;

              const mapedAccount = filterTableDataById({
                tableData: accountData,
                id: accountId,
              });
              const mapedAdmin = filterTableDataById({
                tableData: adminData,
                id: adminId,
              });

              const incomingQuantity =
                item.change_quantity > 0 ? item.change_quantity : 0;
              const releaseQuantity =
                item.change_quantity < 0 ? item.change_quantity : 0;

              return {
                ...item,
                account: mapedAccount,
                admin: mapedAdmin,
                incomingQuantity,
                releaseQuantity,
              };
            });
            return {
              warehouse: mappedWarehouse,
              data: mappedData,
              prevStackQuantity,
            };
          }),
          []
        );

        return {
          product: mappedProduct,
          groupedData: mappedGroupedData,
        };
      }),
      []
    );
  };

  const sortItemsByRegDate = (items) => {
    return items.sort((a, b) => new Date(a.reg_date) - new Date(b.reg_date));
  };

  const calculateTotal = (items, initialStackQuantity) => {
    return items.reduce(
      (acc, cur) => {
        acc.incomingQuantity += cur.incomingQuantity || 0;
        acc.releaseQuantity += cur.releaseQuantity || 0;
        acc.stackQuantity +=
          cur.incomingQuantity || 0 + cur.releaseQuantity || 0;
        return acc;
      },
      {
        incomingQuantity: 0,
        releaseQuantity: 0,
        stackQuantity: initialStackQuantity,
      }
    );
  };

  const calculateMonthlyTotal = (items, initialStackQuantity) => {
    const monthlyTotals = {};

    items.forEach((item) => {
      const { incomingQuantity = 0, releaseQuantity = 0 } = item;
      const currentMonth = dayjs(item.inventory_change_date).format('YYYY-MM');
      const prevMonth = dayjs(item.inventory_change_date)
        .subtract(1, 'month')
        .format('YYYY-MM');
      const currentMonthData = monthlyTotals[currentMonth];
      const prevMonthData = monthlyTotals[prevMonth];

      if (currentMonthData) {
        currentMonthData.incomingQuantity += incomingQuantity;
        currentMonthData.releaseQuantity += releaseQuantity;
        currentMonthData.stackQuantity += incomingQuantity + releaseQuantity;
        currentMonthData.data.push(item);
      } else {
        let stackQuantity;
        if (prevMonthData) {
          stackQuantity =
            prevMonthData.stackQuantity + (incomingQuantity + releaseQuantity);
        } else {
          stackQuantity =
            initialStackQuantity + (incomingQuantity + releaseQuantity);
        }
        monthlyTotals[currentMonth] = {
          month: currentMonth,
          incomingQuantity,
          releaseQuantity,
          stackQuantity,
          data: [item],
        };
      }
    });

    return Object.values(monthlyTotals);
  };

  const addTotalInventoryData = (inventoryData) => {
    return inventoryData.map((inventoryData) => {
      const { product, groupedData } = inventoryData;

      const groupedDataWithTotal = groupedData.map((data) => {
        const { data: items, prevStackQuantity } = data;
        const sortedItems = sortItemsByRegDate(items);
        const total = calculateTotal(sortedItems, prevStackQuantity);
        const monthlyTotal = calculateMonthlyTotal(
          sortedItems,
          prevStackQuantity
        );

        return { ...data, monthlyTotal, total };
      });
      return { product, groupedData: groupedDataWithTotal };
    });
  };

  const getReceiptsData = async ({ formData, optionData, dateRange }) => {
    const { product: formProducts, warehouse: formWarehouses } = formData;
    const { product: optionProducts, warehouse: optionWarehouses } = optionData;

    setIsLoading(true);

    const orConditions = getOrConditions({
      formProducts,
      formWarehouses,
      optionProducts,
      optionWarehouses,
    });

    const inventoryData = await fetchInventoryData({
      orConditions,
      dateRange,
    });

    const groupedInventoryData = groupByWarehouseId(inventoryData);

    const mappedInventoryData = await mapInventoryData({
      inventoryData: groupedInventoryData,
      dateRange,
    });

    const inventoryDataWithTotal = addTotalInventoryData(mappedInventoryData);

    const receiptsData = inventoryDataWithTotal;

    setReceiptsData(receiptsData);
    setIsLoading(false);

    return receiptsData;
  };

  return [receiptsData, isLoading, getReceiptsData];
};

export default useGetInventoryReceiptsData;
