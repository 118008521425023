const calcTrailingNum = ({ dcVat, dcSupply, vat, supply }) => {
  const strDcSum = String(dcVat + dcSupply);
  const strSum = String(vat + supply);

  const newDcSum = Number(`${strDcSum.slice(0, -1)}0`);
  const newSum = Number(`${strSum.slice(0, -1)}0`);

  const newDcSupply = Math.round(newDcSum / 1.1);
  const newDcVat = Math.round(newDcSum - newDcSupply);

  const newSupply = Math.round(newSum / 1.1);
  const newVat = Math.round(newSum - newSupply);

  const newDc = newSum - newDcSum;

  return {
    newDcSum,
    newDcSupply,
    newDcVat,
    newSupply,
    newVat,
    newDc,
  };
};

export default calcTrailingNum;
