import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Descriptions,
  Form,
  Input,
  Select,
  DatePicker,
  ConfigProvider,
  Button,
  Modal,
  List,
  Spin,
  Empty,
  Timeline,
  Checkbox,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

// datepicker 한글화
import 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ko';
import locale from 'antd/lib/locale/ko_KR';

import styled from 'styled-components';
import { CalendarOutlined } from '@ant-design/icons';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import AccountBasicInfo from './AccountBasicInfo';
import useDcAccountProduct from '../../hook/useDcAccountProductDatas';
import useOrderProductData from '../../hook/useOrderProductData';

const OrderForm = (props) => {
  const {
    pageType,
    orderItems,
    orderData,
    setOrderData,
    recentOrderTrigger,
    selectedAddress,
    setSelectedAddress,
    setOrderSaleDatas,
    setOrderProductDatas,
    addressIdx,
    setAddressIdx,
    orderProductDatas,
    setDcAccountProductDatas,
    orderId,
    isNewAccount,
    setIsNewAccount,
  } = props;

  const { createDcAccountProductDatas } = useDcAccountProduct();
  const { createOrderProductWithDc } = useOrderProductData();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const adminCode = useSelector((state) => state.signinUser.userInfo.code);

  const [adminOptions, setAdminOptions] = useState([]);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [dealTypeOptions, setDealTypeOptions] = useState([]);
  const [paymentTermsOptions, setPaymentTermsOptions] = useState([]);

  // 주소지 옵션
  const [addressOptions, setAddressOptions] = useState([]);

  // 메모내역 관련
  const [memoList, setMemoList] = useState([]);
  const [memoLoading, setMemoLoading] = useState(false);
  const [isMemoModalOpen, setIsMemoModalOpen] = useState(false);

  // 주문내역 관련
  const [orderHistory, setOrderHistory] = useState([]);
  const [orderHistoryLoading, setOrderHistoryLoading] = useState(false);
  const [isOrderHistoryModalOpen, setIsOrderHistoryModalOpen] = useState(false);

  // 주문서 수정시 거래처 변경 여부 (주문서 수정시 거래처가 바뀌면 해당 거래처 주소에 맞게 front 데이터가 바뀌도록 하기 위함)
  const [isAccountChanged, setIsAccountChanged] = useState(false);

  // 빈객체 체크 함수
  const isEmptyObj = (obj) => {
    if (obj.constructor === Object && Object.keys(obj).length === 0) {
      return true;
    }
    return false;
  };

  // Input의 default value 구현
  useEffect(() => {
    if (pageType === 'create' && Object.keys(orderData).length === 0) {
      form.setFieldsValue({
        admin_code: adminCode,
        warehouse_code: '00009',
        deal_type: '11',
        order_date: dayjs().format('YYYY-MM-DD'),
        delivery_date: dayjs().format('YYYY-MM-DD'),
      });
      setOrderData({ ...form.getFieldsValue() });
    }
    if (
      pageType === 'detail' ||
      pageType === 'update' ||
      window.location.pathname.includes('estimateToOrder')
    ) {
      form.setFieldsValue({
        admin_code: orderData.admin_code,
        warehouse_code: orderData.warehouse_code,
        account_code: orderData.account_code,
        account_name: orderData.account_name,
        account_phone: orderData.account_phone,
        memo: orderData.memo,
        order_date: orderData.order_date,
        delivery_date: orderData.delivery_date,
        naver_product_order_id: orderData.naver_product_order_id,
      });
    }
  }, [form, orderData, pageType, setOrderData, adminCode]);

  useEffect(() => {
    // 담당자 옵션생성
    dispatch(asyncReadDataFetch({ table: 'profiles' })).then((res) => {
      setAdminOptions([
        ...res.payload.map((data) => ({
          value: data.code,
          label: `${data.code} / ${data.username}`,
        })),
      ]);
    });
    // 출하창고 옵션생성
    dispatch(asyncReadDataFetch({ table: 'warehouse' })).then((res) => {
      setWarehouseOptions([
        ...res.payload.map((data) => ({
          value: data.code,
          label: `${data.code} / ${data.name}`,
        })),
      ]);
    });
    // 거래처 옵션생성
    dispatch(asyncReadDataFetch({ table: 'account' })).then((res) => {
      // 거래처명에 '종료거래처' 텍스트가 포함될 경우 필터링
      const filteredRes = res.payload.filter(
        (data) => !data.name.includes('종료거래처')
      );
      setAccountOptions([
        ...filteredRes.map((data) => ({
          value: data.code,
          label: `${data.code} / ${data.name} ${
            data.business_number ? `/ ${data.business_number}` : ''
          }`,
        })),
      ]);
    });
    // 거래유형 옵션생성
    setDealTypeOptions([
      { value: '11', label: '부가세율 적용' },
      { value: '12', label: '부가세율 미적용' },
    ]);
    // 결제조건 옵션생성
    setPaymentTermsOptions([
      { value: '현금(현금영수증)', label: '현금(현금영수증)' },
      { value: '현금(세금계산서)', label: '현금(세금계산서)' },
      { value: '현금(세금계산서 선발행)', label: '현금(세금계산서 선발행)' },
      // { value: '법인카드', label: '법인카드' },
      { value: '신용카드', label: '신용카드' },
      { value: '스토어', label: '스토어' },
      { value: '소매', label: '소매' },
      { value: '외상', label: '외상대' },
      { value: '없음', label: '없음' },
    ]);
  }, [dispatch, form, pageType]);

  // 거래처주소 옵션생성
  useEffect(() => {
    // 해당 거래처의 주소지 불러오기
    const fetchAccontAddresses = async (accountCode) => {
      return dispatch(
        asyncReadFilteredDataFetch({
          table: 'account_address',
          eqKey: 'account_code',
          eqValue: accountCode,
        })
      ).unwrap();
    };
    // 주소지 옵션 생성하기
    const getAddressOptions = (addresses) => {
      return addresses.map((address) => ({
        key: address.id,
        isRepresentative: address.is_representative,
        label: address.name,
        value: address.id,
      }));
    };
    // 주소지 옵션 대표주소지 id찾기
    const getRepresentativeValue = (addressOptions) => {
      return addressOptions.find((address) => address.isRepresentative)?.key;
    };
    // addressId로 주문서에 주소값 찍기
    const selectAddressFromId = async (addressId) => {
      const addressData = await dispatch(
        asyncReadSpecificDataFetch({ table: 'account_address', id: addressId })
      )
        .unwrap()
        .then((res) => res[0]);
      await setSelectedAddress({
        address: addressData.address,
        postCode: addressData.post_code,
      });
    };
    // 저장된 주소지 정보가져오기
    const getSavedAddressInfo = async (orderId) => {
      return dispatch(
        asyncReadSpecificDataFetch({ table: 'order', id: orderId })
      )
        .unwrap()
        .then((res) => res[0]);
    };
    // 주문서 데이터에 addressId값이 존재하는 데이터인지 조회
    const checkIsExistSavedAddressId = async (id) => {
      const result = await dispatch(
        asyncReadSpecificDataFetch({ table: 'account_address', id })
      )
        .unwrap()
        .then((res) => res[0]);
      return !!result;
    };

    const handleAddress = async () => {
      const { account_code: accountCode } = orderData;

      const fetchedAccountAddresses = await fetchAccontAddresses(accountCode);

      const newAddressOptions = getAddressOptions(fetchedAccountAddresses);
      setAddressOptions(newAddressOptions);

      const representativeValue = getRepresentativeValue(newAddressOptions);

      if (pageType === 'create') {
        await setAddressIdx(representativeValue);
        await selectAddressFromId(representativeValue);
        return;
      }

      const savedAddressInfo = await getSavedAddressInfo(orderId);
      const savedAddressId = savedAddressInfo?.id;
      const savedAddress = savedAddressInfo?.address;

      if (savedAddressId && !isAccountChanged) {
        const isExistSavedAddressId = await checkIsExistSavedAddressId(
          savedAddressId
        );
        if (!isExistSavedAddressId && pageType === 'detail') {
          const newAddressInfo = fetchedAccountAddresses.filter(
            (address) => address.address === savedAddress
          )[0];

          if (!newAddressInfo) {
            alert(
              `주문서에 저장된 거래처의 주소지를 불러올 수 없습니다.\n다른 주소지를 선택하고 저장해주세요.`
            );
            return;
          }

          await setAddressIdx(newAddressInfo.id);
          await selectAddressFromId(newAddressInfo.id);
          return;
        }
        if (!isExistSavedAddressId && pageType === 'update') {
          alert(
            '주문서에 저장된 거래처의 주소지 정보가 변경되었습니다.\n대표주소지를 선택합니다.'
          );
        }
      }

      await setAddressIdx(representativeValue);
      await selectAddressFromId(representativeValue);
    };

    if (!orderData.account_code) {
      return;
    }
    handleAddress();
  }, [
    orderData.account_code,
    dispatch,
    setAddressIdx,
    setSelectedAddress,
    pageType,
    orderId,
    isAccountChanged,
  ]);

  // 담당자 함수
  const handleAdminSearch = useCallback(
    (value) => {
      // 담당자 (admin_code) select-search form에 value삽입
      form.setFieldsValue({ ...orderData, admin_code: value });
      setOrderData({ ...form.getFieldsValue() });
    },
    [form, setOrderData, orderData]
  );
  // 출하창고 함수
  const handleWarehouseSearch = useCallback(
    (value) => {
      // 출하창고 (warehouse_code) select-search form에 value삽입
      form.setFieldsValue({ ...orderData, warehouse_code: value });
      setOrderData({ ...form.getFieldsValue() });
    },
    [form, setOrderData, orderData]
  );
  // 거래처 함수
  const handleAccountSearch = useCallback(
    async (value) => {
      // 거래처와 관련된 form value 삽입
      dispatch(
        asyncReadFilteredDataFetch({
          table: 'account',
          eqKey: 'code',
          eqValue: value,
        })
      ).then((res) => {
        form.setFieldsValue({
          ...orderData,
          account_name: res.payload[0].name,
          account_phone: res.payload[0].mobile,
          account_code: value,
        });
        setOrderData({ ...form.getFieldsValue() });
      });
      // 거래처별 할인 hooks함수 작동
      if (orderProductDatas.length > 0) {
        const newDcAccountProductDatas = await createDcAccountProductDatas(
          orderProductDatas,
          value
        );
        await setDcAccountProductDatas(newDcAccountProductDatas);
        const newOrderProductDatasWithDc = createOrderProductWithDc(
          orderProductDatas,
          newDcAccountProductDatas
        );
        setOrderProductDatas(newOrderProductDatasWithDc);
      }
      // 주문서 수정시 거래처 수정 여부
      if (pageType === 'update') {
        setIsAccountChanged(true);
      }
    },
    [
      dispatch,
      form,
      setOrderData,
      orderData,
      orderProductDatas,
      createDcAccountProductDatas,
      setDcAccountProductDatas,
      createOrderProductWithDc,
      setOrderProductDatas,
    ]
  );
  // 거래유형 함수
  const handleDealTypeSelect = useCallback(
    (value) => {
      form.setFieldsValue({ ...orderData, deal_type: value });
      setOrderData({ ...form.getFieldsValue() });
    },
    [form, setOrderData, orderData]
  );
  // 결제조건 함수
  const handlePaymentTermsSelect = useCallback(
    (value) => {
      form.setFieldsValue({ ...orderData, payment_terms: value });
      setOrderData({ ...form.getFieldsValue() });
    },
    [form, setOrderData, orderData]
  );
  // 납기일자 함수
  const handleDeliveryDate = useCallback(
    (_, value) => {
      form.setFieldsValue({ ...orderData, delivery_date: value });
      setOrderData({ ...form.getFieldsValue() });
    },
    [form, setOrderData, orderData]
  );
  // 주문일자 함수
  const handleOrderDate = useCallback(
    (_, value) => {
      form.setFieldsValue({ ...orderData, order_date: value });
      setOrderData({ ...form.getFieldsValue() });
    },
    [form, setOrderData, orderData]
  );
  // 거래처명 함수
  const handleAccountNameInput = useCallback(
    (e) => {
      form.setFieldsValue({ ...orderData, account_name: e.target.value });
      setOrderData({ ...form.getFieldsValue() });
    },
    [form, setOrderData, orderData]
  );
  // 거래처전화 함수
  const handleAccountPhoneInput = useCallback(
    (e) => {
      form.setFieldsValue({ ...orderData, account_phone: e.target.value });
      setOrderData({ ...form.getFieldsValue() });
    },
    [form, setOrderData, orderData]
  );
  // 메모 함수
  const handleMemoInput = useCallback(
    (e) => {
      form.setFieldsValue({ ...orderData, memo: e.target.value });
      setOrderData({ ...form.getFieldsValue() });
    },
    [form, setOrderData, orderData]
  );
  // 상품주문번호 함수
  const handleNaverProductOrderId = useCallback(
    (e) => {
      form.setFieldsValue({
        ...orderData,
        naver_product_order_id: e.target.value,
      });
      setOrderData({ ...form.getFieldsValue() });
    },
    [form, setOrderData, orderData]
  );

  // 메모내역 확인 함수
  const openMemoModal = useCallback(async () => {
    try {
      // 메모 모달 띄우기
      await setIsMemoModalOpen(true);
      await setMemoLoading(true);
      // 메모내역 정보 memoList에 넣기
      const orders = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'order',
          eqKey: 'account_code',
          eqValue: orderData.account_code,
        })
      ).unwrap();
      const resultDatas = await Promise.all(
        orders.map(async (order) => {
          const admin = await dispatch(
            asyncReadFilteredDataFetch({
              table: 'profiles',
              eqKey: 'code',
              eqValue: order.admin_code,
            })
          ).unwrap();
          return { order, admin: admin[0] };
        })
      );
      const sortedByDate = await Promise.all(
        resultDatas.sort(
          (a, b) => new Date(b.order.order_date) - new Date(a.order.order_date)
        )
      );
      await setMemoList([...sortedByDate]);
      setMemoLoading(false);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  }, [dispatch, orderData]);

  // 메모내역 팝업 닫는 함수
  const closeMemoList = useCallback(() => {
    setIsMemoModalOpen(false);
    setMemoList([]);
  }, []);

  // 이전주문내역
  const handleOrderHistory = useCallback(
    async (code) => {
      try {
        await setOrderHistoryLoading(true);
        await setIsOrderHistoryModalOpen(true);
        // 주문서 데이터
        const orders = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'order',
            eqKey: 'account_code',
            eqValue: code,
          })
        )
          .unwrap()
          .then((res) => res);
        // 주문서 데이터 => 판매상품, 출고상품, 어드민 데이터 추가하여 맵핑
        const dataForPdAndSale = await Promise.all(
          orders.map(async (order) => {
            const { id, admin_code } = order;
            const [sales, products, admin] = await Promise.all([
              dispatch(
                asyncReadFilteredDataFetch({
                  table: 'order_sale',
                  eqKey: 'order_id',
                  eqValue: id,
                })
              ).unwrap(),
              dispatch(
                asyncReadFilteredDataFetch({
                  table: 'order_product',
                  eqKey: 'order_id',
                  eqValue: id,
                })
              ).unwrap(),
              dispatch(
                asyncReadFilteredDataFetch({
                  table: 'profiles',
                  eqKey: 'code',
                  eqValue: admin_code,
                })
              ).unwrap(),
            ]);
            // 결과 리턴
            return {
              order,
              orderSales: sales,
              orderProducts: products,
              admin: admin[0],
            };
          })
        );
        // 주문자, 판매금액 데이터 추가하여 맵핑
        const resultDatas = await Promise.all(
          dataForPdAndSale.map(async (data) => {
            // 총 할인금액 및 판매금액 정보
            const totalDc = data.orderProducts.reduce((acc, cur) => {
              const { discounted } = cur;
              return acc + discounted;
            }, 0);
            const totalPrice = data.orderProducts.reduce((acc, cur) => {
              const price = cur.product_VAT_price + cur.product_supply_price;
              return acc + price;
            }, 0);
            const priceInfo = { totalDc, totalPrice };
            return { ...data, priceInfo };
          })
        );
        // 필터링
        const sortedByDate = await Promise.all(
          resultDatas.sort(
            (a, b) =>
              new Date(b.order.order_date) - new Date(a.order.order_date)
          )
        );
        setOrderHistory([...sortedByDate]);
        setOrderHistoryLoading(false);
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    },
    [dispatch]
  );

  // 주문내역 팝업 닫는 함수
  const closeOrderHistory = useCallback(() => {
    setIsOrderHistoryModalOpen(false);
    setOrderHistory([]);
  }, []);

  // 해당 주문내역 아이템을 form에 반영
  const handleOrderHistoryItem = useCallback(
    (item) => {
      // 주문서 반영
      setOrderData({ ...item.order });
      form.setFieldsValue({ ...item.order });
      // 주문판매 반영
      setOrderSaleDatas([...item.orderSales]);
      // 주문출고 반영
      setOrderProductDatas([...item.orderProducts]);
      // 모달 닫기
      closeOrderHistory();
    },
    [
      closeOrderHistory,
      setOrderSaleDatas,
      setOrderProductDatas,
      setOrderData,
      form,
    ]
  );

  // 최신주문반영 기능
  const getRecentData = useCallback(
    (accountCode) => {
      dispatch(
        asyncReadFilteredDataFetch({
          table: 'order',
          eqKey: 'account_code',
          eqValue: accountCode,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.length === 0) {
            alert('해당 거래처의 등록된 주문서 데이터가 없습니다.');
            return;
          }
          // 주문서 데이터 중 가장 최근 것 가져오기
          let latestDate = null;
          let latestData = null;
          res.forEach((data) => {
            const date = new Date(data.reg_date);
            if (date > latestDate || latestDate === null) {
              latestDate = date;
              latestData = data;
            }
          });
          const item = latestData;
          setOrderData({ ...item });
          form.setFieldsValue({ ...item });
        })
        .catch();
    },
    [dispatch, form, setOrderData]
  );

  const handleAddress = useCallback(
    async (value) => {
      setAddressIdx(value);
      const addressData = await dispatch(
        asyncReadSpecificDataFetch({ table: 'account_address', id: value })
      )
        .unwrap()
        .then((res) => {
          return res[0];
        });
      const { address, post_code, is_representative, id } = addressData;
      // 주소 업데이트
      setSelectedAddress({
        address,
        postCode: post_code,
      });
      setOrderData((prev) => ({
        ...prev,
        address,
        address_id: id,
        is_representative,
        post_code,
      }));
    },
    [setAddressIdx, dispatch, setSelectedAddress, setOrderData]
  );

  return (
    <>
      <Form form={form} disabled={pageType === 'detail'}>
        <Descriptions
          bordered
          labelStyle={{ textAlign: 'center', width: '150px' }}
        >
          {orderItems.map((orderItem) => {
            if (orderItem.type === 'input') {
              return (
                <Descriptions.Item
                  span={orderItem.span}
                  label={orderItem.label}
                  key={orderItem.name}
                >
                  <div
                    style={orderItem.name === 'memo' ? { display: 'flex' } : {}}
                  >
                    <Form.Item noStyle name={orderItem.name}>
                      <Input
                        style={{ width: '100%', borderRadius: '0px' }}
                        placeholder={orderItem.placeholder}
                        onChange={
                          (orderItem.name === 'account_name' &&
                            handleAccountNameInput) ||
                          (orderItem.name === 'account_phone' &&
                            handleAccountPhoneInput) ||
                          (orderItem.name === 'memo' && handleMemoInput) ||
                          (orderItem.name === 'naver_product_order_id' &&
                            handleNaverProductOrderId)
                        }
                        disabled={
                          ((orderItem.name === 'account_name' ||
                            orderItem.name === 'account_phone') &&
                            isNewAccount) ||
                          pageType === 'detail'
                        }
                      />
                    </Form.Item>
                    {orderItem.name === 'memo' && (
                      <Button
                        disabled={!orderData.account_code}
                        style={{
                          marginLeft: '20px',
                          borderRadius: '20px',
                        }}
                        onClick={openMemoModal}
                        type="primary"
                      >
                        메모내역
                      </Button>
                    )}
                  </div>
                </Descriptions.Item>
              );
            }
            if (orderItem.type === 'selectSearch') {
              return (
                <Descriptions.Item
                  span={orderItem.span}
                  label={orderItem.label}
                  key={orderItem.name}
                >
                  {pageType === 'create' &&
                    orderItem.name === 'account_code' && (
                      <Checkbox
                        onClick={() => setIsNewAccount((prev) => !prev)}
                        value={isNewAccount}
                        disabled={orderData.account_code}
                        style={{ marginBottom: '5px' }}
                      >
                        거래처 미선택
                      </Checkbox>
                    )}
                  <div
                    className="select-container"
                    style={
                      orderItem.name === 'account_code'
                        ? {
                            display: 'flex',
                            justifyContent: 'space-between',
                          }
                        : {}
                    }
                  >
                    <Form.Item noStyle name={orderItem.name}>
                      <Select
                        style={{ width: '100%', borderRadius: '0px' }}
                        placeholder={orderItem.placeholder}
                        showSearch
                        disabled={
                          (orderItem.name === 'account_code' && isNewAccount) ||
                          pageType === 'detail'
                        }
                        filterOption={(input, option) => {
                          return option.label && option.label.includes(input);
                        }}
                        /* Select의 default value 구현 */
                        value={
                          (orderItem.name === 'admin_code' &&
                            orderData.admin_code) ||
                          (orderItem.name === 'warehouse_code' &&
                            orderData.warehouse_code) ||
                          (orderItem.name === 'account_code' &&
                            orderData.account_code) ||
                          undefined
                        }
                        onChange={
                          (orderItem.name === 'admin_code' &&
                            handleAdminSearch) ||
                          (orderItem.name === 'warehouse_code' &&
                            handleWarehouseSearch) ||
                          (orderItem.name === 'account_code' &&
                            handleAccountSearch)
                        }
                        options={
                          (orderItem.name === 'admin_code' && adminOptions) ||
                          (orderItem.name === 'warehouse_code' &&
                            warehouseOptions) ||
                          (orderItem.name === 'account_code' && accountOptions)
                        }
                      />
                    </Form.Item>
                    {orderItem.name === 'account_code' && (
                      <div style={{ display: 'flex', marginLeft: '15px' }}>
                        <Button
                          disabled={
                            !orderData.account_code || pageType === 'detail'
                          }
                          style={{
                            marginLeft: '5px',
                            borderRadius: '20px',
                          }}
                          onClick={() =>
                            handleOrderHistory(orderData.account_code)
                          }
                          type="primary"
                        >
                          이전주문
                        </Button>
                        <Button
                          style={{
                            marginLeft: '3px',
                            borderRadius: '20px',
                          }}
                          disabled={
                            !orderData.account_code || pageType === 'detail'
                          }
                          onClick={() => {
                            recentOrderTrigger(orderData.account_code);
                            getRecentData(orderData.account_code);
                          }}
                          type="primary"
                        >
                          최신주문
                        </Button>
                      </div>
                    )}
                  </div>
                </Descriptions.Item>
              );
            }
            if (orderItem.type === 'select') {
              return (
                <Descriptions.Item
                  span={orderItem.span}
                  label={orderItem.label}
                  key={orderItem.name}
                >
                  <Form.Item noStyle name={orderItem.name}>
                    <div className="select-container">
                      <Select
                        style={{ width: '100%', borderRadius: '0px' }}
                        placeholder={orderItem.placeholder}
                        /* Select Search의 default value 구현 */
                        value={
                          (orderItem.name === 'deal_type' &&
                            orderData.deal_type) ||
                          (orderItem.name === 'payment_terms' &&
                            orderData.payment_terms) ||
                          undefined
                        }
                        options={
                          (orderItem.name === 'deal_type' && dealTypeOptions) ||
                          (orderItem.name === 'payment_terms' &&
                            paymentTermsOptions)
                        }
                        onChange={
                          (orderItem.name === 'deal_type' &&
                            handleDealTypeSelect) ||
                          (orderItem.name === 'payment_terms' &&
                            handlePaymentTermsSelect)
                        }
                      />
                    </div>
                  </Form.Item>
                </Descriptions.Item>
              );
            }
            if (orderItem.type === 'date') {
              return (
                <Descriptions.Item
                  span={orderItem.span}
                  label={orderItem.label}
                  key={orderItem.name}
                >
                  <Form.Item noStyle name={orderItem.name}>
                    <ConfigProvider locale={locale}>
                      <DatePicker
                        style={{ width: '100%', borderRadius: '0px' }}
                        placeholder={orderItem.placeholder}
                        onChange={
                          (orderItem.name === 'delivery_date' &&
                            handleDeliveryDate) ||
                          (orderItem.name === 'order_date' && handleOrderDate)
                        }
                        locale={locale}
                        /*
                        defaultValue={
                          (orderItem.name === 'order_date' &&
                            dayjs(orderData.order_date)) ||
                          (orderItem.name === 'delivery_date' &&
                            dayjs(orderData.delivery_date))
                        }
                        */
                        value={
                          (orderItem.name === 'order_date' &&
                            dayjs(orderData.order_date)) ||
                          (orderItem.name === 'delivery_date' &&
                            dayjs(orderData.delivery_date))
                        }
                      />
                    </ConfigProvider>
                  </Form.Item>
                </Descriptions.Item>
              );
            }
            if (orderItem.type === 'show') {
              if (orderData.account_code) {
                return (
                  <Descriptions.Item
                    span={orderItem.span}
                    label={orderItem.label}
                    key={orderItem.name}
                  >
                    <AccountBasicInfo orderData={orderData} />
                  </Descriptions.Item>
                );
              } else {
                return null;
              }
            }
            if (orderItem.type === 'radio') {
              return (
                <Descriptions.Item
                  span={orderItem.span}
                  label={orderItem.label}
                  key={orderItem.name}
                >
                  <div style={{ display: 'flex' }}>
                    {addressOptions.map((option) => (
                      <div
                        key={option.key}
                        style={{
                          marginRight: '15px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <input
                          style={{ marginRight: '5px' }}
                          type="radio"
                          value={option.value}
                          checked={addressIdx === option.value}
                          // checked={console.log(addressIdx)}
                          onChange={(e) => handleAddress(e.target.value)}
                          disabled={pageType === 'detail'}
                        />
                        {option.isRepresentative && (
                          <span
                            style={{
                              border: '0.1px solid lightgray',
                              padding: '0px 6px',
                              marginRight: '5px',
                              borderRadius: '5px',
                              backgroundColor: '#efefef',
                              color: 'gray',
                              fontSize: '12px',
                            }}
                          >
                            대표주소지
                          </span>
                        )}
                        <label>{option.label}</label>
                      </div>
                    ))}
                  </div>
                  {!isEmptyObj(selectedAddress) ? (
                    <div
                      style={{
                        width: '100%',
                        border: '1px solid #dfdfdf',
                        marginTop: '10px',
                        backgroundColor:
                          pageType === 'detail' ? '#f7f7f7' : 'white',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          padding: '5px 0',
                          borderBottom: '1px solid lightgray',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                            width: '80px',
                            padding: '0 10px',
                          }}
                        >
                          우편번호
                        </span>
                        <span>{selectedAddress.postCode}</span>
                      </div>
                      <div style={{ display: 'flex', padding: '5px 0' }}>
                        <span
                          style={{
                            fontWeight: 'bold',
                            width: '80px',
                            padding: '0 10px',
                          }}
                        >
                          주소
                        </span>
                        <span>{selectedAddress.address}</span>
                      </div>
                    </div>
                  ) : (
                    <Empty description="거래처주소가 없습니다." />
                  )}
                </Descriptions.Item>
              );
            }
          })}
        </Descriptions>
      </Form>
      <Modal
        title="메모내역"
        open={isMemoModalOpen}
        onOk={closeMemoList}
        onCancel={closeMemoList}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        centered
      >
        {memoLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '570px',
            }}
          >
            <Spin size="large" />
            <div
              style={{ marginTop: '10px', fontSize: '16px', color: '#1677ff' }}
            >
              이전주문 데이터를 불러오는 중...
            </div>
          </div>
        ) : (
          <>
            <div style={{ marginTop: '20px' }} />
            <List
              pagination={{
                pageSize: 5,
              }}
              dataSource={memoList}
              renderItem={(item) => (
                <OHContainer>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div style={{ fontSize: '16px' }}>
                      {item.admin.username}
                    </div>
                    <div style={{ color: 'gray' }}>
                      <CalendarOutlined /> {item.order.order_date}
                    </div>
                  </div>
                  <div style={{ color: 'gray' }}>{item.admin.email}</div>
                  <div style={{ marginTop: '10px' }}>
                    {item.order.memo.length > 0 ? (
                      <div>{item.order.memo}</div>
                    ) : (
                      <div>메모 없음</div>
                    )}
                  </div>
                </OHContainer>
              )}
            />
          </>
        )}
      </Modal>
      <Modal
        open={isOrderHistoryModalOpen}
        onOk={closeOrderHistory}
        onCancel={closeOrderHistory}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        centered
        title="이전주문 리스트"
      >
        {orderHistoryLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '570px',
            }}
          >
            <Spin size="large" />
            <div
              style={{ marginTop: '10px', fontSize: '16px', color: '#1677ff' }}
            >
              이전주문 데이터를 불러오는 중...
            </div>
          </div>
        ) : (
          <>
            <div style={{ marginTop: '20px' }} />
            <List
              style={{ height: '550px', overflow: 'auto' }}
              pagination={{
                pageSize: 5,
              }}
              dataSource={orderHistory}
              renderItem={(item) => (
                <OHContainer key={item.order.id}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>{item.admin?.username || ''}</div>
                    <div style={{ color: 'gray', fontSize: '12px' }}>
                      <CalendarOutlined /> {item.order.order_date}
                    </div>
                  </div>
                  <div style={{ color: 'gray', fontSize: '12px' }}>
                    {item.admin?.email || ''}
                  </div>
                  <Timeline style={{ color: 'gray', marginTop: '35px' }}>
                    {item.orderProducts.map((data) => (
                      <Timeline.Item style={{ padding: '0px' }}>
                        {data.product_name} / {data.product_quantity}EA
                      </Timeline.Item>
                    ))}
                  </Timeline>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <div
                      style={{
                        marginRight: '5px',
                      }}
                    >
                      <div style={{ color: 'gray', fontSize: '12px' }}>
                        판매금액
                      </div>
                      {item.priceInfo.totalPrice.toLocaleString()}
                    </div>
                  </div>
                  <Button
                    type="primary"
                    style={{
                      position: 'absolute',
                      bottom: '5px',
                      right: '5px',
                    }}
                    onClick={() => handleOrderHistoryItem(item)}
                  >
                    주문반영
                  </Button>
                </OHContainer>
              )}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default React.memo(OrderForm);

OrderForm.propTypes = {
  pageType: PropTypes.string.isRequired,
  orderItems: PropTypes.instanceOf(Array).isRequired,
  orderData: PropTypes.instanceOf(Object).isRequired,
  setOrderData: PropTypes.node.isRequired,
  recentOrderTrigger: PropTypes.func.isRequired,
  selectedAddress: PropTypes.instanceOf(Object).isRequired,
  setSelectedAddress: PropTypes.func.isRequired,
  setOrderProductDatas: PropTypes.func.isRequired,
  setOrderSaleDatas: PropTypes.func.isRequired,
  addressIdx: PropTypes.string.isRequired,
  setAddressIdx: PropTypes.func.isRequired,
  orderProductDatas: PropTypes.instanceOf(Array).isRequired,
  setDcAccountProductDatas: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  isNewAccount: PropTypes.bool.isRequired,
  setIsNewAccount: PropTypes.func.isRequired,
};

const OHContainer = styled.div`
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px 10px;
  position: relative;
  margin-bottom: 15px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
`;
