// 최소값과 최대값이 둘 다 입력되었는지 확인하는 함수
const verifyMinAndMaxEndtered = (min, max) => {
  let result = '';
  // 수량의 최소값과 최대값 모두 입력이 되지 않았을때
  if (!(typeof min === 'number') && !(typeof max === 'number')) {
    result = 'neither';
  }
  // 수량의 최소값과 최대값 둘 중 하나만 입력되었을때
  if (
    (!(typeof min === 'number') && typeof max === 'number') ||
    (typeof min === 'number' && !(typeof max === 'number'))
  ) {
    result = 'onlyOne';
  }
  // 수량의 최소값과 최대값 둘 다 입력되었을때
  if (typeof min === 'number' && typeof max === 'number') {
    result = 'both';
  }
  return result;
};

const filterBySpecificInputs = () => {
  // 거래유형으로 필터링
  const filterByDealType = (input, beforeFiltered) => {
    let filteredByDealType = [];
    if (input) {
      filteredByDealType = beforeFiltered.filter(
        (data) => data.deal_type === input
      );
    } else {
      filteredByDealType = beforeFiltered;
    }
    return filteredByDealType;
  };
  // 창고로 필터링
  const filterByWarehouse = (input, beforeFiltered) => {
    let filteredByWarehouse = [];
    if (input.length > 0) {
      filteredByWarehouse = beforeFiltered.filter((data) => {
        let isEqual = false;
        for (const inputData of input) {
          if (data.product_warehouse === inputData.value) isEqual = true;
        }
        return isEqual;
      });
    } else {
      filteredByWarehouse = beforeFiltered;
    }
    return filteredByWarehouse;
  };
  // 거래처로 필터링
  const filterByAccount = (input, beforeFiltered) => {
    let filteredByAccount = [];
    if (input.length > 0) {
      filteredByAccount = beforeFiltered.filter((data) => {
        let isEqual = false;
        for (const inputData of input) {
          if (data.account_code === inputData.value) isEqual = true;
        }
        return isEqual;
      });
    } else {
      filteredByAccount = beforeFiltered;
    }
    return filteredByAccount;
  };
  // 담당자로 필터링
  const filterByManager = (input, beforeFiltered) => {
    let filteredByManager = [];
    if (input.length > 0) {
      filteredByManager = beforeFiltered.filter((data) => {
        let isEqual = false;
        for (const inputData of input) {
          if (data.admin_code === inputData.value) isEqual = true;
        }
        return isEqual;
      });
    } else {
      filteredByManager = beforeFiltered;
    }
    return filteredByManager;
  };
  // 품목으로 필터링
  const filterByProduct = (input, beforeFiltered) => {
    let filteredByProduct = [];
    if (input.length > 0) {
      filteredByProduct = beforeFiltered.filter((data) => {
        let isEqual = false;
        for (const inputData of input) {
          if (data.product_code === inputData.value) {
            isEqual = true;
          }
        }
        return isEqual;
      });
    } else {
      filteredByProduct = beforeFiltered;
    }
    return filteredByProduct;
  };
  // 수량으로 필터링
  const filterByQuantity = (input, beforeFiltered) => {
    const { min, max } = input;
    const verifiedValue = verifyMinAndMaxEndtered(min, max);
    let filteredByQuantity = [];
    if (verifiedValue === 'both') {
      filteredByQuantity = beforeFiltered.filter(
        (data) => data.product_quantity >= min && data.product_quantity <= max
      );
    }
    if (verifiedValue === 'neither') {
      filteredByQuantity = beforeFiltered;
    }
    if (verifiedValue === 'onlyOne') {
      filteredByQuantity = false;
    }
    return filteredByQuantity;
  };
  // 단가로 필터링
  const filterByPrice = (input, beforeFiltered) => {
    const { min, max } = input;
    const verifiedValue = verifyMinAndMaxEndtered(min, max);
    let filteredByPrice = [];
    if (verifiedValue === 'both') {
      filteredByPrice = beforeFiltered.filter(
        (data) => data.product_price >= min && data.product_price <= max
      );
    }
    if (verifiedValue === 'neither') {
      filteredByPrice = beforeFiltered;
    }
    if (verifiedValue === 'onlyOne') {
      filteredByPrice = false;
    }
    return filteredByPrice;
  };
  // 공급가액으로 필터링
  const filterBySupplyPrice = (input, beforeFiltered) => {
    const { min, max } = input;
    const verifiedValue = verifyMinAndMaxEndtered(min, max);
    let filteredBySupplyPrice = [];
    if (verifiedValue === 'both') {
      filteredBySupplyPrice = beforeFiltered.filter(
        (data) =>
          data.product_supply_price >= min && data.product_supply_price <= max
      );
    }
    if (verifiedValue === 'neither') {
      filteredBySupplyPrice = beforeFiltered;
    }
    if (verifiedValue === 'onlyOne') {
      filteredBySupplyPrice = false;
    }
    return filteredBySupplyPrice;
  };
  // 부가세로 필터링
  const filterByVatPrice = (input, beforeFiltered) => {
    const { min, max } = input;
    const verifiedValue = verifyMinAndMaxEndtered(min, max);
    let filteredByVatPrice = [];
    if (verifiedValue === 'both') {
      filteredByVatPrice = beforeFiltered.filter(
        (data) => data.product_VAT_price >= min && data.product_VAT_price <= max
      );
    }
    if (verifiedValue === 'neither') {
      filteredByVatPrice = beforeFiltered;
    }
    if (verifiedValue === 'onlyOne') {
      filteredByVatPrice = false;
    }
    return filteredByVatPrice;
  };
  // 교환/반품건 포함/제외 필터링
  const filterByExchangeOrReturn = (input, beforeFiltered) => {
    if (input === 'include') {
      return beforeFiltered;
    } else {
      const result = beforeFiltered.filter(
        (item) => item.product_quantity >= 0
      );
      return result;
    }
  };
  // 배송 방식으로 필터링
  const filterByDlvyType = (input, beforeFiltered) => {
    const getFilteredDatas = (dlvyType) => {
      const otherDlvyTypeDatas = beforeFiltered.filter((data) =>
        data.product_code.includes('COD')
      );
      const idOfOtherDlvyTypeDatas = otherDlvyTypeDatas.map((data) => data.id);
      if (dlvyType === 'all') {
        // 기타배송일 경우 marked를 '기타배송'으로 찍기
        const markedDatas = beforeFiltered.map((data) => {
          let isOtherDlvyData = false;
          for (const orderId of idOfOtherDlvyTypeDatas) {
            if (data.id === orderId) {
              isOtherDlvyData = true;
            }
          }
          if (isOtherDlvyData) {
            return { ...data, marked: '기타배송' };
          } else {
            return data;
          }
        });
        return markedDatas;
      } else {
        const filteredDatas = beforeFiltered.filter((data) => {
          let isOtherDlvyData = false;
          for (const orderId of idOfOtherDlvyTypeDatas) {
            if (data.id === orderId) {
              isOtherDlvyData = true;
            }
          }
          if (isOtherDlvyData) {
            if (dlvyType === 'other') {
              return data;
            }
            if (dlvyType === 'basic') {
              return;
            }
          } else {
            if (dlvyType === 'other') {
              return;
            }
            if (dlvyType === 'basic') {
              return data;
            }
          }
        });
        return filteredDatas;
      }
    };
    const filterCodeOfCOD = (filteredDatas) => {
      const result = filteredDatas.filter(
        (data) => !data.product_code.includes('COD')
      );
      return result;
    };

    const dlvyType = input;
    if (dlvyType === 'all') {
      const marked = getFilteredDatas('all');
      return filterCodeOfCOD(marked);
    } else if (dlvyType === 'other') {
      const filtered = getFilteredDatas('other');
      return filterCodeOfCOD(filtered);
    } else if (dlvyType === 'basic') {
      const filtered = getFilteredDatas('basic');
      return filterCodeOfCOD(filtered);
    }
  };
  // 함수 리턴
  return {
    filterByDealType,
    filterByWarehouse,
    filterByAccount,
    filterByManager,
    filterByProduct,
    filterByQuantity,
    filterByPrice,
    filterBySupplyPrice,
    filterByVatPrice,
    filterByDlvyType,
    filterByExchangeOrReturn,
  };
};

export default filterBySpecificInputs;
