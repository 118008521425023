import React from 'react';

const HantongDashboard = () => {
  return (
    <iframe
      src="https://hantongdashboard.web.app/#/"
      title="배송현황판"
      width="100%"
      height="100%"
      frameBorder="0"
    />
  );
};

export default HantongDashboard;
