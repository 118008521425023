import { SendOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import ProposeDatas from '../components/ProposeDatas';

const Propose = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <SendOutlined />,
        breadCrumbNav: ' 주문제안',
      },
    ],
    []
  );
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px' }}>
        <ProposeDatas />
      </div>
    </LayoutComponent>
  );
};

export default Propose;
