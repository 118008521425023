import { DatabaseOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import DataHandler from '../components/DataHandler';

const PurchaseOrderDetail = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <DatabaseOutlined />,
        breadCrumbNav: ' 발주조회',
      },
      {
        breadCrumbIcon: <SearchOutlined />,
        breadCrumbNav: ' 상세',
      },
    ],
    []
  );

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <DataHandler pageType="detail" />
    </LayoutComponent>
  );
};

export default PurchaseOrderDetail;
