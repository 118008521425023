import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Calendar, Space } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import DataCell from './DataCell';
import DataLoading from './DataLoading';
import {
  DARK_MODE_BACKGROUND,
  LIGHT_MODE_BACKGROUND,
  borderRadiuses,
} from '../../constants';

const checkIsALotOfCellsCalendar = (monthValue) => {
  const firstDay = dayjs(monthValue).startOf('month');
  const lastDay = dayjs(monthValue).endOf('month');

  const isFirstFriday = firstDay.day() === 5;
  const isFirstSaturday = firstDay.day() === 6;

  if ((isFirstFriday && lastDay >= 31) || (isFirstSaturday && lastDay >= 30)) {
    return true;
  } else {
    return false;
  }
};

const MonthlyCalendar = (props) => {
  const {
    handlePanel,
    handleArrow,
    calendarData,
    dataLoading,
    calendarRenderVal,
    handleSelect,
    setSelectedDate,
    monthValue,
    handleToday,
    holidaysData,
    isLargeScreen,
    screenSizeLevel,
  } = props;

  const { darkMode } = useSelector((state) => state.darkMode);

  const isALotOfCellsCalendar = checkIsALotOfCellsCalendar(monthValue);

  const getCellClassName = ({ isALotOfCellsCalendar, screenSizeLevel }) => {
    if (isALotOfCellsCalendar && screenSizeLevel === 3) {
      return 'is-a-lot-of-cells-and-tv-screen';
    } else if (isALotOfCellsCalendar && screenSizeLevel === 2) {
      return 'is-a-lot-of-cells-and-large-screen';
    } else if (isALotOfCellsCalendar && screenSizeLevel === 1) {
      return 'is-a-lot-of-cells-and-small-screen';
    } else if (!isALotOfCellsCalendar && screenSizeLevel === 3) {
      return 'is-few-of-cells-and-tv-screen';
    } else if (!isALotOfCellsCalendar && screenSizeLevel === 2) {
      return 'is-few-of-cells-and-large-screen';
    } else if (!isALotOfCellsCalendar && screenSizeLevel === 1) {
      return 'is-few-of-cells-and-small-screen';
    } else {
      return '';
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        padding: '10px 20px',
        borderRadius: borderRadiuses.primary,
        // border: '1px solid #efefef',
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
      }}
    >
      <Space.Compact
        style={{
          position: 'absolute',
          right: '190px',
          top: '10px',
          zIndex: '1',
        }}
      >
        <ArrowButtonStyle
          onClick={() => handleArrow('left')}
          darkMode={darkMode}
        >
          <LeftOutlined />
        </ArrowButtonStyle>
        <Button
          style={{
            color: 'gray',
            backgroundColor: darkMode
              ? DARK_MODE_BACKGROUND
              : LIGHT_MODE_BACKGROUND,
            border: darkMode && 'none',
          }}
          onClick={() => handleToday()}
        >
          Today
        </Button>
        <ArrowButtonStyle
          onClick={() => handleArrow('right')}
          darkMode={darkMode}
        >
          <RightOutlined />
        </ArrowButtonStyle>
      </Space.Compact>
      <Calendar
        mode="month"
        onPanelChange={(value) => handleSelect(value)}
        dateCellRender={(value) => {
          const date = value.format('YYYY-MM-DD');
          const holiday = holidaysData.filter((item) => item.date === date)[0]
            ?.title;
          return (
            <div
              className={getCellClassName({
                isALotOfCellsCalendar,
                screenSizeLevel,
              })}
              style={{
                width: '100%',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <DataCell
                holiday={holiday}
                cellDate={value}
                calendarData={calendarData}
                monthValue={monthValue}
              />
            </div>
          );
        }}
        value={calendarRenderVal}
        fullscreen
        onSelect={(e) => {
          const isCurMonth = monthValue === dayjs(e).format('YYYY-MM');
          if (isCurMonth) {
            setSelectedDate(dayjs(e).format('YYYY-MM-DD'));
          }
        }}
        className={[
          'home-monthly-calendar',
          darkMode ? 'is-dark-mode' : 'is-light-mode',
        ]}
      />
      <DataLoading isLoading={dataLoading} />
    </div>
  );
};

export default MonthlyCalendar;

MonthlyCalendar.propTypes = {
  handlePanel: PropTypes.func.isRequired,
  handleArrow: PropTypes.func.isRequired,
  calendarData: PropTypes.instanceOf(Array).isRequired,
  dataLoading: PropTypes.bool.isRequired,
  calendarRenderVal: PropTypes.instanceOf(Object).isRequired,
  handleSelect: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  monthValue: PropTypes.string.isRequired,
  handleToday: PropTypes.func.isRequired,
  holidaysData: PropTypes.instanceOf(Array).isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
  screenSizeLevel: PropTypes.number.isRequired,
};

const ArrowButtonStyle = styled(Button)`
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  background-color: ${(props) =>
    props.darkMode ? DARK_MODE_BACKGROUND : LIGHT_MODE_BACKGROUND};
  border-radius: 15px;
  border: ${(props) => props.darkMode && 'none'};
`;
