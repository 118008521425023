import React, { useMemo } from 'react';
import { BarChartOutlined } from '@ant-design/icons';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Map from '../components/Map';

const DataMap = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <BarChartOutlined />,
        breadCrumbNav: ' 데이터맵',
      },
    ],
    []
  );

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <Map />
    </LayoutComponent>
  );
};

export default DataMap;
