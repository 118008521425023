import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';

const WarehouseColumnValue = (props) => {
  const { warehouseId } = props;
  const dispatch = useDispatch();

  const [warehouseName, setWarehouseName] = useState('');

  useEffect(() => {
    const getWarehouseName = async () => {
      try {
        const resOfWarehouseName = await dispatch(
          asyncReadSpecificDataFetch({ table: 'warehouse', id: warehouseId })
        )
          .unwrap()
          .then((res) => res[0].name);
        setWarehouseName(resOfWarehouseName);
      } catch (error) {
        throw new Error();
      }
    };
    getWarehouseName();
  }, [warehouseId, dispatch]);

  return <div>{warehouseName}</div>;
};

export default memo(WarehouseColumnValue);

WarehouseColumnValue.propTypes = {
  warehouseId: PropTypes.string.isRequired,
};
