import React from 'react';
import { Result } from 'antd';
import PropTypes from 'prop-types';

const TaxInvoiceExistenceIndicator = (props) => {
  const { isExistTaxInvoiceData, isLoadingTaxInvoiceData } = props;

  if (isLoadingTaxInvoiceData) {
    return <Result status="error" subTitle="데이터를 로딩중입니다..." />;
  }

  if (isExistTaxInvoiceData) {
    return (
      <Result
        status="success"
        subTitle="세금계산서등록양식 엑셀파일을 출력할 수 있습니다."
      />
    );
  } else {
    return (
      <Result
        status="error"
        subTitle="데이터가 존재하지 않아 세금계산서등록양식 엑셀파일을 출력할 수 없습니다."
      />
    );
  }
};

TaxInvoiceExistenceIndicator.propTypes = {
  isExistTaxInvoiceData: PropTypes.bool.isRequired,
  isLoadingTaxInvoiceData: PropTypes.bool.isRequired,
};

export default TaxInvoiceExistenceIndicator;
