import { DatabaseOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import SalesStatusDatas from '../components/SalesStatusDatas';

const SalesStatus = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <DatabaseOutlined />,
        breadCrumbNav: ' 판매현황',
      },
    ],
    []
  );
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px' }}>
        <SalesStatusDatas />
      </div>
    </LayoutComponent>
  );
};

export default SalesStatus;
