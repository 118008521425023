/* eslint consistent-return: off */
/* eslint no-else-return: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loading from '../../shared/components/Loading';
import Access from '../../access/page/Access';

// 로그인 되지 않았을 때 접근 가능한 컴포넌트
const PublicRoute = (props) => {
  const history = useHistory();
  const { component, path, exact } = props;
  const { isLoggedIn, isLoadingSigninUser } = useSelector(
    (state) => state.signinUser
  );

  // permission이 false일 경우의 access페이지 처리
  if (isLoadingSigninUser === true) {
    return <Loading />;
  }

  if (isLoggedIn === true) {
    // return <Access text="로그인" button="홈으로" isLoggedIn={isLoggedIn} />;
    // 로그인 상태일 때 home 페이지로 넘어감.
    return history.push('/data-calendar');
  } else if (isLoggedIn === false) {
    return <Route component={component} path={path} exact={exact} />;
  } else {
    return <Loading />;
  }
};

export default PublicRoute;

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
};
