import { StockOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import InventoryReceiptsHandler from '../components/InventoryReceiptsHandler';

const InventoryReceipts = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <StockOutlined />,
        breadCrumbNav: ' 재고수불부',
      },
    ],
    []
  );
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <InventoryReceiptsHandler />
    </LayoutComponent>
  );
};

export default InventoryReceipts;
