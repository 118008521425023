import { ConfigProvider } from 'antd';
import React from 'react';

// datepicker 한글화
import 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ko';
import locale from 'antd/lib/locale/ko_KR';

import MonthlyData from './MonthlyData';

const Calendar = () => {
  return (
    <div>
      <ConfigProvider locale={locale}>
        <div style={{ position: 'relative' }}>
          <MonthlyData />
        </div>
      </ConfigProvider>
    </div>
  );
};

export default Calendar;
