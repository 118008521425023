import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ArrowDownOutlined,
  DollarCircleOutlined,
  InboxOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  border-bottom: 1px solid #efefef;
`;
const ListContainer = styled.div`
  border: 1px solid lightgray;
  width: 200px;
  height: 150px;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 20px;
  box-shadow: 3px 3px 3px 3px #efefef;
  position: relative;
`;

const OrderSummary = (props) => {
  const { orderProductDatas, handleCreate, pageType } = props;
  const [orderSummaryData, setOrderSummaryData] = useState({
    supply: 0,
    vat: 0,
    delivery: 0,
    total: 0,
    totalWithDlvy: 0,
    discount: 0,
    discounted: 0,
    product: '',
  });

  useEffect(() => {
    let product = '출고상품 없음';
    const datas = orderProductDatas;
    if (datas.length !== 0) {
      let str = '';
      for (const data of datas) {
        if (data.product_code.includes('parcel')) {
          str += '';
        } else {
          str += `${data.product_name} / `;
        }
      }
      product = str.slice(0, -2);
    }
    let supply = 0;
    let vat = 0;
    let delivery = 0;
    let discount = 0;
    for (const data of datas) {
      // 택배비 처리
      if (data.product_code.includes('parcel')) {
        const newDelivery = data.product_supply_price + data.product_VAT_price;
        delivery += newDelivery;
        // 공급가액, 부가세 처리
      } else {
        supply += data.product_supply_price;
        vat += data.product_VAT_price;
      }
      // 할인 처리
      if (data.discount_rate && data.discount_rate > 0) {
        discount += data.discount;
      }
    }
    const total = supply + vat;
    const totalWithDlvy = supply + vat + delivery;
    const discounted = totalWithDlvy - discount;

    setOrderSummaryData({
      supply: Math.round(supply),
      vat: Math.round(vat),
      delivery: Math.round(delivery),
      total: Math.round(total),
      product,
      totalWithDlvy: Math.round(totalWithDlvy),
      discount: Math.round(discount),
      discounted: Math.round(discounted),
    });
  }, [orderProductDatas]);

  return (
    <Container>
      <ListContainer>
        <div
          style={{
            width: '30px',
            height: '30px',
            backgroundColor: '#f7f7f7',
            borderRadius: '50%',
            zIndex: '1',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <InboxOutlined
            style={{ fontSize: '24px', zIndex: '2', color: '#1890ff' }}
          />
        </div>
        <p>출고상품</p>
        <p>{orderSummaryData.product}</p>
      </ListContainer>
      <ListContainer>
        <div
          style={{
            width: '30px',
            height: '30px',
            backgroundColor: '#f7f7f7',
            borderRadius: '50%',
            zIndex: '1',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <DollarCircleOutlined
            style={{ fontSize: '20px', zIndex: '2', color: '#1890ff' }}
          />
        </div>
        <p>공급가액</p>
        <p>{orderSummaryData.supply.toLocaleString()}원</p>
      </ListContainer>
      <ListContainer>
        <div
          style={{
            width: '30px',
            height: '30px',
            backgroundColor: '#f7f7f7',
            borderRadius: '50%',
            zIndex: '1',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <DollarCircleOutlined
            style={{ fontSize: '20px', zIndex: '2', color: '#1890ff' }}
          />
        </div>
        <p>부가세</p>
        <p>{orderSummaryData.vat.toLocaleString()}원</p>
      </ListContainer>
      <ListContainer style={{ backgroundColor: '#1890ff' }}>
        <div
          style={{
            width: '30px',
            height: '30px',
            backgroundColor: '#f7f7f7',
            borderRadius: '50%',
            zIndex: '1',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <DollarCircleOutlined
            style={{ fontSize: '20px', zIndex: '2', color: '#1890ff' }}
          />
        </div>
        <p style={{ fontWeight: 'bold', color: 'white' }}>합계금액</p>
        <p style={{ fontWeight: 'bold', color: 'white' }}>
          {orderSummaryData.total.toLocaleString()}원
        </p>
      </ListContainer>
      <ListContainer>
        <div
          style={{
            width: '30px',
            height: '30px',
            backgroundColor: '#f7f7f7',
            borderRadius: '50%',
            zIndex: '1',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ShoppingCartOutlined
            style={{ fontSize: '24px', zIndex: '2', color: '#1890ff' }}
          />
        </div>
        <p>택배비</p>
        <p>{orderSummaryData.delivery.toLocaleString()}원</p>
      </ListContainer>
      <ListContainer>
        <div
          style={{
            width: '30px',
            height: '30px',
            backgroundColor: '#f7f7f7',
            borderRadius: '50%',
            zIndex: '1',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ArrowDownOutlined
            style={{ fontSize: '20px', zIndex: '2', color: '#1890ff' }}
          />
        </div>
        <p style={{ color: 'gray' }}>할인금액</p>
        <p style={{ color: 'gray' }}>
          -{orderSummaryData.discount.toLocaleString()}원
        </p>
      </ListContainer>
      <ListContainer
        style={{ backgroundColor: '#001528', position: 'relative' }}
      >
        <div
          style={{
            width: '30px',
            height: '30px',
            backgroundColor: '#f7f7f7',
            borderRadius: '50%',
            zIndex: '1',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <DollarCircleOutlined
            style={{ fontSize: '20px', zIndex: '2', color: '#001528' }}
          />
        </div>
        <p style={{ fontWeight: 'bold', color: 'white' }}>총액</p>
        <p style={{ fontWeight: 'bold', color: 'white' }}>
          {orderSummaryData.discounted.toLocaleString()}원
        </p>
        {pageType === 'create' &&
          !window.location.pathname.includes('estimateToOrder') && (
            <Popconfirm
              title="해당 데이터로 견적서를 추가하시겠습니까?"
              onConfirm={() => handleCreate('estimate')}
              cancelText="취소"
              okText="추가"
            >
              <Button
                type="primary"
                style={{
                  position: 'absolute',
                  top: '18px',
                  right: '18px',
                  borderRadius: '20px',
                }}
              >
                견적서
              </Button>
            </Popconfirm>
          )}
      </ListContainer>
    </Container>
  );
};

export default OrderSummary;

OrderSummary.propTypes = {
  orderProductDatas: PropTypes.instanceOf(Array).isRequired,
  handleCreate: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
};
