import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { asyncSignupUserFetch } from '../../redux/modules/auth/signupUser';

import Center from '../../shared/components/Center';
import useModal from '../../hook/useModal';
import SignupForm from '../components/SignupForm';
import SignupHeader from '../components/SignupHeader';
import useSupabase from '../../serverConfig';

const Container = styled.div`
  width: 400px;
  border-radius: 5px;
  overflow: hidden;
  margin: 30px 0px 50px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: 1px solid lightGray;
  background-color: white;
  padding: 32px;
  height: 605px;
`;

const Signup = () => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const history = useHistory();
  const onFinish = async (values) => {
    const phone = `${values.frontNumber}-${values.backNumber}`;
    const data = {
      email: values.email,
      password: values.password,
      username: values.username,
      phone: '010-'.concat(phone),
    };
    try {
      dispatch(asyncSignupUserFetch(data))
        .unwrap()
        .then(() => {
          openModal({
            type: 'result',
            modalInfo: {
              modalTitle: '회원가입',
              resultStatus: 'success',
              resultTitle: '회원가입을 완료했습니다.',
              resultSubTitle: useSupabase
                ? '이메일을 확인한 후 인증 링크를 클릭해주세요.'
                : '로그인해주세요.',
              buttonText: '확인',
            },
          });
          history.push('/');
        })
        .catch((error) => alert(error.message));
    } catch (error) {
      alert(error);
    }
  };
  const onFinishFailed = (error) => {
    console.log(error);
    alert(error.message);
  };

  return (
    <Center>
      <Container>
        <SignupHeader />
        <SignupForm onFinish={onFinish} onFinishFailed={onFinishFailed} />
      </Container>
    </Center>
  );
};

export default Signup;
