import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import InventoryTable from './InventoryTable';
import SearchForm from './SearchForm';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import useGetInventoryData from '../../hook/useGetInventoryData';
import DataTable from './DataTable';

const DataHandler = () => {
  const dispatch = useDispatch();
  const {
    getBasicInventoryDatas,
    getInventoryDatasWithUnSoldAndUnReceived,
    getInventoryDatasWithSafetyStock,
    getInventoryDatasWithChangedStock,
  } = useGetInventoryData();

  const [formData, setFormData] = useState({
    inventoryChangeDate: null,
    warehouse: [],
    product: [],
    type: 'safetyStock',
  });

  const [inventoryDatas, setInventoryDatas] = useState([]);
  const [isDatasLoading, setIsDatasLoading] = useState(false);

  useEffect(() => {
    const getSearchDefaults = async () => {
      const today = dayjs().format('YYYY-MM-DD');
      const whSearchDefaults = await dispatch(
        asyncReadDataFetch({ table: 'inventory_search_default' })
      )
        .unwrap()
        .then((res) => res);
      const result = await Promise.all(
        whSearchDefaults.map(async (data) => {
          const warehouse = await dispatch(
            asyncReadSpecificDataFetch({
              table: 'warehouse',
              id: data.warehouse_id,
            })
          )
            .unwrap()
            .then((res) => res[0]);
          return { label: warehouse.name, value: warehouse.id };
        })
      );
      setFormData((prev) => ({
        ...prev,
        inventoryChangeDate: today,
        warehouse: result,
      }));
    };
    getSearchDefaults();
  }, [dispatch]);

  const getInventoryDatas = async (dateBtnValue) => {
    const { inventoryChangeDate, warehouse, type } = formData;
    // 유효성검사
    if (!inventoryChangeDate) {
      alert('기준일자를 선택해주세요.');
      return;
    }
    if (warehouse <= 0) {
      alert('창고를 선택해주세요.');
      return;
    }

    // form 받기
    let newFormData;
    if (dateBtnValue === 'today') {
      newFormData = {
        ...formData,
        inventoryChangeDate: dayjs().format('YYYY-MM-DD'),
      };
    } else if (dateBtnValue === 'yesterday') {
      newFormData = {
        ...formData,
        inventoryChangeDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      };
    } else {
      newFormData = { ...formData };
    }

    // inventoryDatas 받기
    try {
      setIsDatasLoading(true);
      let newInventoryDatas;
      switch (type) {
        case 'safetyStock':
          newInventoryDatas = await getInventoryDatasWithSafetyStock(
            newFormData
          );
          break;
        case 'changedStock':
          newInventoryDatas = await getInventoryDatasWithChangedStock(
            newFormData
          );
          break;
        case 'unSoldAndUnReceived':
          newInventoryDatas = await getInventoryDatasWithUnSoldAndUnReceived(
            newFormData
          );
          break;
        case 'basic':
          newInventoryDatas = await getBasicInventoryDatas(newFormData);
          break;
        default:
          newInventoryDatas = [];
          break;
      }
      setInventoryDatas(newInventoryDatas);
      setIsDatasLoading(false);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <SearchForm
        formData={formData}
        setFormData={setFormData}
        setInventoryDatas={setInventoryDatas}
        getInventoryDatas={getInventoryDatas}
        isDatasLoading={isDatasLoading}
      />
      <DataTable
        inventoryDatas={inventoryDatas}
        setInventoryDatas={setInventoryDatas}
        formData={formData}
        isDatasLoading={isDatasLoading}
      />
    </div>
  );
};

export default DataHandler;
