import { Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { asyncDeleteDataFetch } from '../../redux/modules/shared/deleteData';

const AllDeleteButton = (props) => {
  const { ids, table, setErrorDatas } = props;
  const dispatch = useDispatch();

  const handleAllDelete = async () => {
    const checkAllDelete = confirm(
      '정말 전체삭제 하시겠습니까? 삭제된 에러 데이터는 복구할 수 없습니다.'
    );
    if (!checkAllDelete) return;
    console.log(ids, table, setErrorDatas);
  };

  return (
    <Button size="small" type="link" danger onClick={handleAllDelete}>
      전체삭제
    </Button>
  );
};

export default AllDeleteButton;

AllDeleteButton.propTypes = {
  ids: PropTypes.number.isRequired,
  table: PropTypes.string.isRequired,
  setErrorDatas: PropTypes.func.isRequired,
};
