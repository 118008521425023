import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { signinApi } from '../../../apis/authApi';

const initialState = {
  userInfo: {
    id: '',
    reg_date: '',
    state: null,
    mod_data: '',
    username: '',
    phone: '',
    permission: null,
    tier: null,
    intro: '',
    email: '',
  },
  isLoggedIn: null,
  isLoadingSigninUser: false,
  isErrorSigninUser: false,
  isSuccessSigninUser: false,
};

// 토큰 저장
const saveTokenToLocalStorage = (token) => {
  localStorage.setItem('token', token);
};

// 비동기인 fetch 함수 작성 (로그인)
const asyncSigninUserFetch = createAsyncThunk(
  'signinUserSlice/asyncSigninUserFetch',
  async (values, { rejectWithValue }) => {
    try {
      const result = await signinApi(values);
      const { access_token, user } = result;

      // 토큰 저장
      saveTokenToLocalStorage(access_token);
      // user 저장 (브라우저 메모리)
      return user;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message || '서버에러');
    }
  }
);

const signinUserSlice = createSlice({
  name: 'signinUser',
  initialState,
  // 비동기가 아닌 reducer 작성
  reducers: {
    // 로그아웃 reducer
    logoutReducer: (state) => {
      state.userInfo = { ...initialState.userInfo };
      state.isLoggedIn = false;
    },
    // 로그인 된 상태의 refresh reducer
    loggedInRefreshSessionReducer: (state, action) => {
      state.userInfo = { ...action.payload.userInfo };
      state.isLoggedIn = action.payload.isLoggedIn;
    },
    // 로그인 안된 상태의 refresh reducer
    notLoggedInRefreshSessionReducer: (state) => {
      state.isLoggedIn = false;
    },
  },
  // 비동기인 reducer 작성
  extraReducers: (builder) => {
    builder.addCase(asyncSigninUserFetch.pending, (state) => {
      state.isLoadingSigninUser = true;
    });
    builder.addCase(asyncSigninUserFetch.fulfilled, (state, action) => {
      state.userInfo = { ...action.payload };
      state.isLoadingSigninUser = false;
      state.isSuccessSigninUser = true;
      state.isLoggedIn = true;
    });
    builder.addCase(asyncSigninUserFetch.rejected, (state) => {
      state.isLoadingSigninUser = false;
      state.isErrorSigninUser = true;
    });
  },
});

export default signinUserSlice.reducer;

export const { logoutReducer } = signinUserSlice.actions;
export const {
  loggedInRefreshSessionReducer,
  notLoggedInRefreshSessionReducer,
} = signinUserSlice.actions;
export { asyncSigninUserFetch };
