import { AuditOutlined } from '@ant-design/icons';
import { Button, Descriptions, Popover } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TotalDataViewer = (props) => {
  const { totalSalesStatusData, category } = props;

  return (
    <Popover
      trigger="click"
      content={
        <Descriptions
          layout="vertical"
          bordered
          column={5}
          style={{ border: '1px solid #efefef' }}
        >
          <Descriptions.Item
            label={<div style={{ textAlign: 'start' }}>주문건수</div>}
            style={{ width: '20%', textAlign: 'end' }}
          >
            {category === 'line' ? (
              totalSalesStatusData.totalCount.toLocaleString()
            ) : (
              <div>-</div>
            )}
          </Descriptions.Item>
          <Descriptions.Item
            label={<div style={{ textAlign: 'start' }}>할인수량</div>}
            style={{ width: '20%', textAlign: 'end' }}
          >
            {totalSalesStatusData.totalDcQuantity.toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item
            label={<div style={{ textAlign: 'start' }}>수량</div>}
            style={{ width: '20%', textAlign: 'end' }}
          >
            {totalSalesStatusData.totalQuantity.toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item
            label={<div style={{ textAlign: 'start' }}>공급가액</div>}
            style={{ width: '20%', textAlign: 'end' }}
          >
            {totalSalesStatusData.totalSupply.toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item
            label={<div style={{ textAlign: 'start' }}>부가세</div>}
            style={{ width: '20%', textAlign: 'end' }}
          >
            {totalSalesStatusData.totalVat.toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item
            label={<div style={{ textAlign: 'start' }}>합계</div>}
            style={{ width: '100%', textAlign: 'end' }}
          >
            {totalSalesStatusData.totalSum.toLocaleString()}
          </Descriptions.Item>
        </Descriptions>
      }
      placement="rightTop"
    >
      <Button style={{ borderRadius: '20px', marginLeft: '5px' }}>TOTAL</Button>
    </Popover>
  );
};

export default TotalDataViewer;

TotalDataViewer.propTypes = {
  totalSalesStatusData: PropTypes.instanceOf(Object).isRequired,
  category: PropTypes.string.isRequired,
};
