import dayjs from 'dayjs';
import React from 'react';
import { useDispatch } from 'react-redux';
import { asyncCreateDataFetch } from '../redux/modules/shared/createData';
import { asyncReadFilteredDataFetch } from '../redux/modules/shared/readFilteredData';
import { asyncReadMatchDataFetch } from '../redux/modules/shared/readMatchData';
import { asyncReadSpecificDataFetch } from '../redux/modules/shared/readSpecificData';

const useSalesInventory = () => {
  const dispatch = useDispatch();

  const changeInventory = async (
    inventoryChangeDate,
    warehouseId,
    productId,
    productQuantity
  ) => {
    console.log(
      '!!!재고!!! (판매 삭제 or 등록)',
      {
        inventoryChangeDate,
        warehouseId,
        productId,
        productQuantity,
      },
      `현재 시각: ${new Date()}`
    );
    try {
      const recentData = await dispatch(
        asyncReadMatchDataFetch({
          table: 'inventory',
          match: {
            product_id: productId,
            warehouse_id: warehouseId,
          },
          order: 'id',
          limit: 1,
        })
      )
        .unwrap()
        .then((res) => res);
      if (recentData.length <= 0) {
        await dispatch(
          asyncCreateDataFetch({
            table: 'inventory',
            inventory_change_date: inventoryChangeDate,
            warehouse_id: warehouseId,
            product_id: productId,
            change_quantity: productQuantity,
            stack_quantity: productQuantity,
          })
        );
      } else {
        const newStackQty = recentData[0].stack_quantity + productQuantity;
        await dispatch(
          asyncCreateDataFetch({
            table: 'inventory',
            inventory_change_date: inventoryChangeDate,
            warehouse_id: warehouseId,
            product_id: productId,
            change_quantity: productQuantity,
            stack_quantity: newStackQty,
          })
        );
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const getOrderProducts = async (orderId) => {
    try {
      const orderProducts = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'order_product',
          eqKey: 'order_id',
          eqValue: orderId,
        })
      )
        .unwrap()
        .then((res) => res);
      return orderProducts;
    } catch (error) {
      throw new Error(error);
    }
  };

  const getWarehouseId = async (warehouseCode) => {
    const warehouseId = await dispatch(
      asyncReadFilteredDataFetch({
        table: 'warehouse',
        eqKey: 'code',
        eqValue: warehouseCode,
      })
    )
      .unwrap()
      .then((res) => res[0].id);
    return warehouseId;
  };

  const getProductId = async (productCode) => {
    const productId = await dispatch(
      asyncReadFilteredDataFetch({
        table: 'product',
        eqKey: 'code',
        eqValue: productCode,
      })
    )
      .unwrap()
      .then((res) => res[0].id);
    return productId;
  };

  const getPaymentStatus = async (orderId) => {
    const paymentStatus = await dispatch(
      asyncReadSpecificDataFetch({ table: 'order', id: orderId })
    )
      .unwrap()
      .then((res) => res[0].payment_status);
    return paymentStatus === '1';
  };

  // 최근 재고stack 가져오기
  const getRecentInventoryStack = async ({ warehouseId, productId }) => {
    const result = await dispatch(
      asyncReadMatchDataFetch({
        table: 'inventory',
        match: { warehouse_id: warehouseId, product_id: productId },
        order: 'id',
        limit: 1,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.length <= 0) {
          return false;
        }
        return res[0].stack_quantity;
      });
    return result;
  };

  // 재고 추가기능 (구매 수정시)
  const createInventoryData = async ({
    changeQuantity,
    stackQuantity,
    warehouseId,
    productId,
  }) => {
    console.log(
      '!!!재고!!! (판매 수정)!',
      {
        changeQuantity,
        stackQuantity,
        warehouseId,
        productId,
      },
      `현재 시각: ${new Date()}`
    );
    await dispatch(
      asyncCreateDataFetch({
        table: 'inventory',
        state: 1,
        change_quantity: changeQuantity,
        stack_quantity: stackQuantity,
        warehouse_id: warehouseId,
        product_id: productId,
      })
    );
  };

  const checkIsHandleInventory = (orderProducts) => {
    let isHandleInventory = true;
    for (const orderProduct of orderProducts) {
      const { product_warehouse, product_code } = orderProduct;
      if (!product_code || !product_warehouse) isHandleInventory = false;
    }
    return isHandleInventory;
  };

  // 판매 수정 (재고 변동)
  const adjustInventory = async (newProductDatas, orderId) => {
    try {
      const isSalesData = await getPaymentStatus(orderId);
      if (!isSalesData) return;
      const savedProductDatas = await getOrderProducts(orderId);
      const isHandleInventory = checkIsHandleInventory(savedProductDatas);
      if (!isHandleInventory) {
        alert('재고를 조정할 수 없는 데이터입니다.');
        return;
      }
      const treatedDatas = [];
      // 품목이 같을 경우 처리
      for (const savedProductData of savedProductDatas) {
        for (const newProductData of newProductDatas) {
          const savedProductCode = savedProductData.product_code;
          const newProductCode = newProductData.product_code;
          const savedWarehouseCode = savedProductData.product_warehouse;
          const newWarehouseCode = newProductData.product_warehouse;
          const equalProduct = newProductCode === savedProductCode;
          const equalWarehouse = savedWarehouseCode === newWarehouseCode;
          if (equalProduct) {
            treatedDatas.push(savedProductCode);
            const savedQuantity = savedProductData.product_quantity;
            const newQuantity = newProductData.product_quantity;
            const savedProductId = await getProductId(savedProductCode);
            const newProductId = await getProductId(newProductCode);
            const savedWarehouseId = await getWarehouseId(savedWarehouseCode);
            const newWarehouseId = await getWarehouseId(newWarehouseCode);
            // 창고가 같을 경우
            if (equalWarehouse) {
              const changeQuantity = savedQuantity - newQuantity;
              const stackQuantity =
                (await getRecentInventoryStack({
                  warehouseId: newWarehouseId,
                  productId: newProductId,
                })) + changeQuantity;
              await createInventoryData({
                changeQuantity,
                stackQuantity,
                warehouseId: newWarehouseId,
                productId: newProductId,
              });
            } else {
              // 창고가 다를 경우
              const stackQuantityToOffset =
                (await getRecentInventoryStack({
                  warehouseId: savedWarehouseId,
                  productId: savedProductId,
                })) + savedQuantity;
              const changeQuantityToOffset = +savedQuantity;
              await createInventoryData({
                changeQuantity: changeQuantityToOffset,
                stackQuantity: stackQuantityToOffset,
                warehouseId: savedWarehouseId,
                productId: savedProductId,
              });
              const isExistingInventoryData = await getRecentInventoryStack({
                warehouseId: newWarehouseId,
                productId: newProductId,
              });
              let stackQuantityToReduce = 0;
              if (!isExistingInventoryData) {
                stackQuantityToReduce = -newQuantity;
              } else {
                stackQuantityToReduce =
                  (await getRecentInventoryStack({
                    warehouseId: newWarehouseId,
                    productId: newProductId,
                  })) - newQuantity;
              }
              const changeQuantityToReduce = -newQuantity;
              await createInventoryData({
                changeQuantity: changeQuantityToReduce,
                stackQuantity: stackQuantityToReduce,
                warehouseId: newWarehouseId,
                productId: newProductId,
              });
            }
          }
        }
      }
      // 품목이 다를 경우 처리
      const toOffsetDatas = savedProductDatas.filter((savedProductData) => {
        let isTreated = false;
        treatedDatas.forEach((treatedData) => {
          if (savedProductData.product_code === treatedData) isTreated = true;
        });
        return !isTreated;
      });
      const toReduceDatas = newProductDatas.filter((newProductData) => {
        let isTreated = false;
        treatedDatas.forEach((treatedData) => {
          if (newProductData.product_code === treatedData) isTreated = true;
        });
        return !isTreated;
      });
      // 재고 상쇄하기
      for (const data of toOffsetDatas) {
        const productId = await getProductId(data.product_code);
        const warehouseId = await getWarehouseId(data.product_warehouse);
        const productQuantity = data.product_quantity;
        const stackQuantity =
          (await getRecentInventoryStack({
            warehouseId,
            productId,
          })) + productQuantity;
        const changeQuantity = +productQuantity;
        await createInventoryData({
          changeQuantity,
          stackQuantity,
          warehouseId,
          productId,
        });
      }
      // 재고 감소하기
      for (const data of toReduceDatas) {
        const productId = await getProductId(data.product_code);
        const warehouseId = await getWarehouseId(data.product_warehouse);
        const productQuantity = data.product_quantity;
        const stackQuantity =
          (await getRecentInventoryStack({
            warehouseId,
            productId,
          })) - productQuantity;
        const changeQuantity = -productQuantity;
        await createInventoryData({
          changeQuantity,
          stackQuantity,
          warehouseId,
          productId,
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  // 판매 추가 (재고 감소)
  const decreaseInventory = async (orderId) => {
    try {
      const orderProducts = await getOrderProducts(orderId);
      const isHandleInventory = checkIsHandleInventory(orderProducts);
      if (!isHandleInventory) {
        alert('재고를 조정할 수 없는 데이터입니다.');
        return;
      }
      const inventoryDatas = await Promise.all(
        orderProducts.map(async (orderProduct) => {
          const warehouseCode = orderProduct.product_warehouse;
          const productCode = orderProduct.product_code;
          const warehouseId = await getWarehouseId(warehouseCode);
          const productId = await getProductId(productCode);
          return {
            inventoryChangeDate: dayjs().format('YYYY-MM-DD'),
            warehouseId,
            productId,
            productQuantity: orderProduct.product_quantity,
          };
        })
      );
      for (const inventory of inventoryDatas) {
        const { inventoryChangeDate, warehouseId, productId, productQuantity } =
          inventory;
        await changeInventory(
          inventoryChangeDate,
          warehouseId,
          productId,
          -productQuantity
        );
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  // 판매 삭제 (재고 증가)
  const increaseInventory = async (orderId) => {
    try {
      const isSalesData = await getPaymentStatus(orderId);
      if (!isSalesData) return;
      const orderProducts = await getOrderProducts(orderId);
      const isHandleInventory = checkIsHandleInventory(orderProducts);
      if (!isHandleInventory) {
        alert('재고를 조정할 수 없는 데이터입니다.');
        return;
      }
      const inventoryDatas = await Promise.all(
        orderProducts.map(async (orderProduct) => {
          const warehouseCode = orderProduct.product_warehouse;
          const productCode = orderProduct.product_code;
          const warehouseId = await getWarehouseId(warehouseCode);
          const productId = await getProductId(productCode);
          if (!warehouseId || !productId) return;
          return {
            inventoryChangeDate: dayjs().format('YYYY-MM-DD'),
            warehouseId,
            productId,
            productQuantity: orderProduct.product_quantity,
          };
        })
      );
      for (const inventory of inventoryDatas) {
        const { inventoryChangeDate, warehouseId, productId, productQuantity } =
          inventory;
        await changeInventory(
          inventoryChangeDate,
          warehouseId,
          productId,
          +productQuantity
        );
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return { decreaseInventory, increaseInventory, adjustInventory };
};

export default useSalesInventory;
