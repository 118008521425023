import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

const RegionLabelBox = (props) => {
  const { darkMode, top, left, label, point, handleClickLabelBox, region } =
    props;

  return (
    <Button
      style={{
        display: 'flex',
        padding: '20px',
        borderRadius: '5px',
        width: '150px',
        height: '75px',
        position: 'absolute',
        top,
        left,
        color: 'gray',
        alignItems: 'center',
        opacity: '0.85',
        backgroundColor: darkMode ? '#212332' : 'white',
      }}
      onClick={() => handleClickLabelBox(region)}
    >
      <div
        style={{
          marginRight: '15px',
          fontSize: '20px',
          border: '1px solid gray',
          padding: '5px',
          borderRadius: '5px',
          width: '40px',
          height: '40px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SmileOutlined />
      </div>
      <div style={{ textAlign: 'start' }}>
        <div>{label}</div>
        <div>{point} Point</div>
      </div>
    </Button>
  );
};

export default RegionLabelBox;

RegionLabelBox.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  top: PropTypes.string.isRequired,
  left: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  point: PropTypes.number.isRequired,
  handleClickLabelBox: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
};
