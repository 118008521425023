import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Chart, Doughnut } from 'react-chartjs-2';
import { Empty } from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import getSalesStatusDatasByCategory from '../../utils/getSalesStatusDatasByCategory';
import {
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  LIGHT_MODE_BACKGROUND,
  LIGHT_MODE_FONT_COLOR,
  darkModeColors,
  lightModeColors,
  borderRadiuses,
} from '../../constants';
import AbsoluteLeftText from '../../shared/components/AbsoluteLeftText';

ChartJS.register(ArcElement, Tooltip, Legend);

const SalesByProductChart = (props) => {
  const { curMonthSalesData, monthValue } = props;

  const { darkMode } = useSelector((state) => state.darkMode);
  const Colors = darkMode ? darkModeColors : lightModeColors;

  const { getSalesStatusDatasByProduct } = getSalesStatusDatasByCategory();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const salesByProductChartData = getSalesStatusDatasByProduct(
      curMonthSalesData
    )
      .filter((item) => item.sumPrice > 0)
      .sort((a, b) => b.sumPrice - a.sumPrice);

    const totalSumPrice = salesByProductChartData.reduce(
      (total, item) => total + item.sumPrice,
      0
    );

    const getColorByIndex = (index) => {
      if (index === 0) {
        return Colors.armsone_blue;
      } else if (index === 1) {
        return Colors.armsone_green;
      } else if (index === 2) {
        return Colors.armsone_orange;
      } else if (index === 3) {
        return Colors.armsone_pink;
      } else if (index === 4) {
        return Colors.armsone_red;
      } else {
        return Colors.armsone_grey;
      }
    };

    const result = salesByProductChartData.map((item, index) => ({
      ...item,
      productName: `${item.productName} (${(
        (item.sumPrice / totalSumPrice) *
        100
      ).toFixed(1)})`,
      color: getColorByIndex(index),
    }));

    setChartData(result);
  }, [curMonthSalesData, darkMode]);

  const data = {
    labels: chartData.map((item) => item.productName),
    datasets: [
      {
        label: '',
        data: chartData.map((item) => item.sumPrice),
        backgroundColor: chartData.map((item) => item.color),
      },
    ],
  };

  const options = {
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div
      style={{
        // border: '1px solid #efefef',
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
        color: darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR,
        width: '100%',
        height: '100%',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: borderRadiuses.primary,
        position: 'relative',
      }}
    >
      <h4
        style={{
          color: 'gray',
          position: 'absolute',
          left: '10px',
          fontSize: '12px',
        }}
      >
        Category
      </h4>
      {chartData.length === 0 ? (
        <div style={{ color: 'gray' }}>차트를 불러올 수 없습니다.</div>
      ) : (
        <Doughnut
          data={data}
          options={options}
          style={{
            zIndex: '1',
            position: 'absolute',
            scale: '0.85',
            bottom: '35%',
          }}
        />
      )}
      <div
        style={{
          fontSize: '12px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          position: 'absolute',
          bottom: '10px',
        }}
      >
        {chartData
          .sort((a, b) => b.sumPrice - a.sumPrice)
          .slice(0, 5)
          .map((item) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'gray',
                marginTop: '1.5px',
              }}
            >
              <div
                style={{
                  backgroundColor: `${item.color}`,
                  height: '10px',
                  width: '10px',
                  marginRight: '5px',
                  borderRadius: '10px',
                  border: darkMode && '1px solid gray',
                }}
              />
              <div style={{ fontSize: '11px' }}>{item.productName}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SalesByProductChart;

SalesByProductChart.propTypes = {
  curMonthSalesData: PropTypes.instanceOf(Array).isRequired,
  monthValue: PropTypes.string.isRequired,
};
