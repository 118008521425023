import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Popover } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

const TableFilterHandler = (props) => {
  const { setColumns, category, getVisibleColumn, immutableColumns } = props;

  const getVisibleImmutableColumn = () => {
    if (category === 'line') {
      return immutableColumns.lineColumn;
    } else if (category === 'daily') {
      return immutableColumns.dailyColumn;
    } else if (category === 'monthly') {
      return immutableColumns.monthlyColumn;
    } else if (category === 'order') {
      return immutableColumns.orderColumn;
    } else if (category === 'product') {
      return immutableColumns.productColumn;
    } else if (category === 'account') {
      return immutableColumns.accountColumn;
    } else if (category === 'admin') {
      return immutableColumns.adminColumn;
    } else {
      return;
    }
  };

  const updateColumns = (prevColumns, newColumn) => {
    if (category === 'line') {
      return { ...prevColumns, lineColumn: newColumn };
    } else if (category === 'daily') {
      return { ...prevColumns, dailyColumn: newColumn };
    } else if (category === 'monthly') {
      return { ...prevColumns, monthlyColumn: newColumn };
    } else if (category === 'order') {
      return { ...prevColumns, orderColumn: newColumn };
    } else if (category === 'product') {
      return { ...prevColumns, productColumn: newColumn };
    } else if (category === 'account') {
      return { ...prevColumns, accountColumn: newColumn };
    } else if (category === 'admin') {
      return { ...prevColumns, adminColumn: newColumn };
    } else {
      return;
    }
  };

  const handleColumnCheckbox = (newColumnTitles) => {
    const newColumn = getVisibleImmutableColumn().filter((immutableColumn) => {
      const immutableColumnTitle = immutableColumn.title;
      let isEqualColumnTitle = false;
      newColumnTitles.forEach((newColumnTitle) => {
        if (immutableColumnTitle === newColumnTitle) isEqualColumnTitle = true;
      });
      return isEqualColumnTitle;
    });
    setColumns((prev) => updateColumns(prev, newColumn));
  };

  return (
    <Popover
      title={
        <div
          style={{
            backgroundColor: '#efefef',
            padding: '3px',
            borderRadius: '5px',
            textAlign: 'center',
          }}
        >
          필터링
        </div>
      }
      trigger="click"
      placement="rightTop"
      content={
        <Checkbox.Group
          options={getVisibleImmutableColumn().map((item) => item.title)}
          value={getVisibleColumn().map((item) => item.title)}
          onChange={(newColumnTitles) => handleColumnCheckbox(newColumnTitles)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      }
    >
      <Button
        style={{
          borderRadius: '20px',
        }}
      >
        <FilterOutlined />
      </Button>
    </Popover>
  );
};

export default TableFilterHandler;

TableFilterHandler.propTypes = {
  setColumns: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  getVisibleColumn: PropTypes.func.isRequired,
  immutableColumns: PropTypes.instanceOf(Object).isRequired,
};
