import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { HistoryOutlined, RedoOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import {
  darkModeColors,
  DARK_MODE_BACKGROUND,
  lightModeColors,
  LIGHT_MODE_BACKGROUND,
} from '../../constants';

const RefreshTimer = (props) => {
  const { isLargeScreen } = props;

  const { darkMode } = useSelector((state) => state.darkMode);

  const bgColor = darkMode ? DARK_MODE_BACKGROUND : LIGHT_MODE_BACKGROUND;
  const colors = darkMode ? darkModeColors : lightModeColors;

  const [count, setCount] = useState(600);
  const [formattedCount, setFormattedCount] = useState('10:00');

  useEffect(() => {
    const refreshTimer = setTimeout(() => {
      if (count > 0) {
        const curCount = count - 1;
        const curFormattedCount = dayjs()
          .startOf('day')
          .add(curCount, 'minute')
          .format('HH:mm');
        setCount(curCount);
        setFormattedCount(curFormattedCount);
      } else {
        window.location.reload();
      }
    }, 1000);
    return () => clearTimeout(refreshTimer);
  }, [count]);

  const initializeCount = useCallback(() => {
    setCount(600);
    setFormattedCount('10:00');
  }, []);

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div
      style={{
        // border: '1px solid #efefef',
        backgroundColor: bgColor,
        color: 'gray',
        width: '100%',
        height: '100%',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '10px',
        position: 'relative',
      }}
    >
      <h4 style={{ color: 'gray' }}>Refresh</h4>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            gap: '2px',
            right: !isLargeScreen && '10px',
            top: !isLargeScreen && '10px',
            bottom: isLargeScreen && '35%',
          }}
        >
          <Button
            size="small"
            style={{
              opacity: '0.7',
              width: '15px',
              height: '15px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: colors.armsone_green,
            }}
            type="primary"
          >
            <HistoryOutlined
              style={{
                color: 'white',
                cursor: 'pointer',
                fontSize: '10px',
              }}
              onClick={() => initializeCount()}
            />
          </Button>
          <Button
            size="small"
            style={{
              opacity: '0.7',
              width: '15px',
              height: '15px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: colors.armsone_orange,
            }}
            type="primary"
            danger
          >
            <RedoOutlined
              style={{
                color: 'white',
                cursor: 'pointer',
                fontSize: '10px',
              }}
              onClick={() => refreshPage()}
            />
          </Button>
        </div>
        <div
          style={{
            color: 'gray',
            fontWeight: count === 600 ? 'bold' : '500',
          }}
        >
          {formattedCount}
        </div>
      </div>
    </div>
  );
};

export default RefreshTimer;

RefreshTimer.propTypes = {
  isLargeScreen: PropTypes.bool.isRequired,
};
