import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../axios';
import useSupabase from '../../../serverConfig';

import supabase from '../../../supabase';
import isUserMigrated from '../../../userMigrationConfig';

const initialState = {
  isLoadingSignupUser: false,
  isErrorSignupUser: false,
  isSuccessSignupUser: false,
};

// 수파베이스 연결
const signupBySupabase = async (email, password, username, phone) => {
  const { data } = await supabase.auth.signUp({
    email,
    password,
    options: {
      data: {
        username,
        phone,
      },
      emailRedirectTo: `${window.location.origin}/data-calendar`,
    },
  });

  console.log(data);

  return data.user.id;
};
// 자체서버 연결 (유저 이식 이후 부터)
const signupByApi = async (id, email, password, username, phone) => {
  const { data } = await axiosInstance.post('/api/auth/signup', {
    id,
    email,
    password,
    username,
    phone,
  });

  return data;
};
// 자체서버 연결 (유저 이식 이전)
const signupWithMigrateUser = async (email, password, username, phone) => {
  const id = await signupBySupabase(email, password, username, phone);
  const data = await signupByApi(id, email, password, username, phone);
  console.log(data);
  return { data };
};

const asyncSignupUserFetch = createAsyncThunk(
  'signupUserSlice/asyncSignupUserFetch',
  // 회원가입 관련 fetch 로직 작성
  async (values, { rejectWithValue }) => {
    const { email, password, username, phone } = values;
    try {
      const result = useSupabase
        ? await signupBySupabase(email, password, username, phone)
        : isUserMigrated
        ? await signupByApi(email, password, username, phone)
        : await signupWithMigrateUser(email, password, username, phone);

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const signupUserSlice = createSlice({
  name: 'signupUser',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncSignupUserFetch.pending, (state) => {
      state.isLoadingSignupUser = true;
    });
    builder.addCase(asyncSignupUserFetch.fulfilled, (state) => {
      state.isLoadingSignupUser = false;
      state.isSuccessSignupUser = true;
    });
    builder.addCase(asyncSignupUserFetch.rejected, (state) => {
      state.isLoadingSignupUser = false;
      state.isErrorSignupUser = true;
    });
  },
});

export default signupUserSlice.reducer;
export { asyncSignupUserFetch };
