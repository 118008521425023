import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { Progress } from 'antd';

import {
  borderRadiuses,
  DARK_MODE_BACKGROUND,
  DARK_MODE_LAYOUT_COLOR,
  LIGHT_MODE_BACKGROUND,
  LIGHT_MODE_LAYOUT_COLOR,
} from '../../constants';

const PercentageOfAccountByCity = (props) => {
  const { darkMode, selectedCity, numberOfAccountDatasByCity } = props;

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    /*
    const _getBackgroundColor = (index) => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i += 1) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };
    */
    const getBackgroundColor = (index) => {
      if (index === 0) {
        return 'red';
      } else if (index === 1) {
        return 'orange';
      } else if (index === 2) {
        return 'yellow';
      } else if (index === 3) {
        return 'green';
      } else if (index === 4) {
        return 'blue';
      } else {
        return 'gray';
      }
    };
    const newChartData = numberOfAccountDatasByCity
      .sort((a, b) => b.amount - a.amount)
      .map((item, index) => {
        if (item.value === selectedCity) {
          return {
            ...item,
            isSelectedCity: true,
            color: getBackgroundColor(index),
          };
        } else {
          return {
            ...item,
            isSelectedCity: false,
            color: getBackgroundColor(index),
          };
        }
      });
    setChartData(newChartData);
  }, [numberOfAccountDatasByCity, selectedCity]);

  const data = {
    labels: chartData.map((item) => item.value),
    datasets: [
      {
        label: '거래처수',
        data: chartData.map((item) => item.amount),
        backgroundColor: chartData.map((item) => item.color),
        hoverOffset: 5,
      },
    ],
  };

  const options = {
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        padding: '10px',
        borderRadius: borderRadiuses.primary,
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
        position: 'relative',
      }}
    >
      <h4>시군구별 거래처수 백분율</h4>
      <Doughnut options={options} data={data} style={{ scale: '0.95' }} />
      <div
        style={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
          position: 'absolute',
          top: '50px',
          left: '20px',
        }}
      >
        {chartData.slice(0, 5).map((item) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                gap: '2px',
              }}
            >
              <div
                style={{
                  borderRadius: '10px',
                  width: '14px',
                  height: '14px',
                  backgroundColor: item.color,
                }}
              />
              <div
                style={{
                  fontWeight: item.isSelectedCity && 'bold',
                  color: item.isSelectedCity && 'red',
                  fontSize: '12px',
                }}
              >
                {item.value}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PercentageOfAccountByCity;

PercentageOfAccountByCity.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  selectedCity: PropTypes.string.isRequired,
  numberOfAccountDatasByCity: PropTypes.instanceOf(Array).isRequired,
};
