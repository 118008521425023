import dayjs from 'dayjs';
import { useEffect } from 'react';

const useDateRangeFromLocalStorage = (setDateRange) => {
  useEffect(() => {
    const startDate = window.localStorage.getItem('startDate');
    const endDate = window.localStorage.getItem('endDate');
    const isExistDateRangeInLocalStorage = !!startDate && !!endDate;
    if (isExistDateRangeInLocalStorage) {
      setDateRange((prev) => ({
        ...prev,
        start: dayjs(startDate),
        end: dayjs(endDate),
      }));
    }
  }, []);
};

export default useDateRangeFromLocalStorage;
