import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SafetyCertificateOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { BsBoxes } from 'react-icons/bs';

import { asyncReadMatchDataFetch } from '../../redux/modules/shared/readMatchData';

const StockValue = (props) => {
  const { productId, warehouseId, stock } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const [safetyStockValue, setSafetyStockValue] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const getSafetyStock = async () => {
      try {
        const fetchedSafetyStock = await dispatch(
          asyncReadMatchDataFetch({
            table: 'inventory_safety_stock',
            match: {
              warehouse_id: warehouseId,
              product_id: productId,
            },
            order: 'id',
            limit: 1,
          })
        ).unwrap();
        if (fetchedSafetyStock[0]) {
          setSafetyStockValue(fetchedSafetyStock[0].safety_stock);
        } else {
          return;
        }
      } catch (error) {
        throw new Error(error);
      }
    };
    getSafetyStock();
  }, []);

  if (typeof safetyStockValue === 'number' && safetyStockValue > stock) {
    return (
      <div
        className="under-safety-stock"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => history.push('/purchase-order/create')}
      >
        {!isHovered && stock}
        {isHovered && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <div style={{ fontSize: '10px' }}>
                <BsBoxes />
              </div>
              <div style={{ fontWeight: 'bold' }}>{stock}</div>
            </div>
            <div style={{ marginLeft: '10px' }}>
              <div style={{ fontSize: '10px' }}>
                <SafetyCertificateOutlined />
              </div>
              <div style={{ fontWeight: '600' }}>{safetyStockValue}</div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return <div>{stock}</div>;
};

export default StockValue;

StockValue.propTypes = {
  productId: PropTypes.string.isRequired,
  warehouseId: PropTypes.string.isRequired,
  stock: PropTypes.number,
};
