import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

import {
  borderRadiuses,
  DARK_MODE_BACKGROUND,
  LIGHT_MODE_BACKGROUND,
} from '../../constants';

const PercentageOfSalesByRegion = (props) => {
  const { darkMode } = props;

  const data = {
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        backgroundColor: [],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '10px',
        borderRadius: borderRadiuses.primary,
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
      }}
    >
      <h4>시도별 매출액 백분율</h4>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: '1',
        }}
      >
        <div>차트</div>
      </div>
      <div
        style={{
          display: 'flex',
          grap: '5px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        라벨
      </div>
    </div>
  );
};

export default PercentageOfSalesByRegion;

PercentageOfSalesByRegion.propTypes = {
  darkMode: PropTypes.bool.isRequired,
};
