import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import CounsellingPostFields from './CounsellingPostFields';

const CounsellingPost = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const adminInfo = useSelector((state) => state.signinUser.userInfo);

  const [counsellingData, setCounsellingData] = useState({
    accountName: null,
    contact: null,
    content: null,
    counsellingDate: null,
    counsellingType: null,
    deliveryDate: null,
    purchaseDate: null,
    registrationName: null,
    route: null,
    regDate: null,
    reactionLevel: null,
    admin: {
      code: null,
      name: null,
      email: null,
    },
  });
  const [fetchLoading, setFetchLoading] = useState(false);
  const [isPersuaded, setIsPersuaded] = useState(false);

  useEffect(() => {
    const getCounsellingData = async () => {
      try {
        await setFetchLoading(true);
        const fetchedCounsellingData = await dispatch(
          asyncReadSpecificDataFetch({ table: 'counselling', id })
        )
          .unwrap()
          .then((res) => res[0]);
        const fetchedAdminData = await dispatch(
          asyncReadSpecificDataFetch({
            table: 'profiles',
            id: fetchedCounsellingData.admin_id,
          })
        )
          .unwrap()
          .then((res) => res[0]);
        const newRegDate = fetchedCounsellingData.reg_date
          .slice(0, 19)
          .replace('T', '');
        await setCounsellingData({
          accountName: fetchedCounsellingData.account_name,
          contact: fetchedCounsellingData.contact,
          content: fetchedCounsellingData.content,
          counsellingDate: fetchedCounsellingData.counselling_date,
          counsellingType: fetchedCounsellingData.counselling_type,
          deliveryDate: fetchedCounsellingData.delivery_date,
          purchaseDate: fetchedCounsellingData.purchase_date,
          registrationName: fetchedCounsellingData.registration_name,
          route: fetchedCounsellingData.route,
          regDate: newRegDate,
          reactionLevel: fetchedCounsellingData.reaction_level,
          purchaseIntention: fetchedCounsellingData.purchase_intention,
          admin: {
            id: fetchedAdminData.id,
            code: fetchedAdminData.code,
            name: fetchedAdminData.username,
            email: fetchedAdminData.email,
          },
        });
        await setIsPersuaded(fetchedCounsellingData.is_persuaded);
        setFetchLoading(false);
      } catch (error) {
        throw new Error(error);
      }
    };
    getCounsellingData();
  }, []);

  const handleDelete = async () => {
    const isConfirmDelete = window.confirm(
      '정말 신규상담/게시글을 삭제하시겠습니까?'
    );
    if (!isConfirmDelete) return;

    const { regDate } = counsellingData;
    const formatted = `${regDate.slice(0, 10)} ${regDate.slice(10)}`;

    try {
      await dispatch(
        asyncUpdateDataFetch({
          table: 'counselling',
          id,
          state: 9,
          del_date: dayjs(),
          regDate: formatted,
        })
      )
        .unwrap()
        .then(() => {
          alert('신규상담/게시글을 삭제했습니다.');
          history.push('/counselling');
        })
        .catch((err) => window.alert(err));
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleClickPersuadedButton = async () => {
    if (adminInfo.tier > 1) {
      alert('관리자만 설득 거래처 여부를 지정할 수 있습니다.');
      return;
    }
    if (!counsellingData.purchaseDate) {
      alert(
        '구매일이 입력되지 않았습니다. 구매일이 입력되어야 설득 거래처 여부를 지정할 수 있습니다.'
      );
      return;
    }
    try {
      await dispatch(
        asyncUpdateDataFetch({
          table: 'counselling',
          id,
          is_persuaded: !isPersuaded,
        })
      );
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsPersuaded((prev) => !prev);
    }
  };

  return (
    <div
      style={{
        maxWidth: '1080px',
        margin: '0 auto',
        marginBottom: '30px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          padding: '20px 0px 12px 0px',
        }}
      >
        <SubmitButtonStyle onClick={handleDelete}>삭제</SubmitButtonStyle>
        <SubmitButtonStyle
          onClick={() => history.push(`/counselling/update/${id}`)}
        >
          수정
        </SubmitButtonStyle>
        <SubmitButtonStyle onClick={() => history.push('/counselling')}>
          목록
        </SubmitButtonStyle>
      </div>
      <CounsellingPostFields
        counsellingData={counsellingData}
        isPersuaded={isPersuaded}
        handleClickPersuadedButton={handleClickPersuadedButton}
      />
    </div>
  );
};

export default CounsellingPost;

const SubmitButtonStyle = styled(Button)`
  font-weight: bold;
  background-color: #efefef;
  border: none;
  margin-left: 10px;
`;
