import { FileExcelOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import downloadExcelFile from '../../utils/downloadExcelFile';

const SalesStatusExcelHandler = (props) => {
  const { salesStatusDatas, date, category } = props;
  const { handleSalesStatusTable } = downloadExcelFile();

  const getSalesStatusExcelDatas = () => {
    const baseExcelColumns = ['할인수량', '수량', '공급가액', '부가세', '합계'];
    const getBaseExcelRows = (item) => {
      return {
        dcQuantity: item.dcQuantity.toLocaleString(),
        quantity: item.quantity.toLocaleString(),
        supplyPrice: item.supplyPrice.toLocaleString(),
        vatPrice: item.vatPrice.toLocaleString(),
        sumPrice: item.sumPrice.toLocaleString(),
      };
    };
    let excelColumns = [];
    let excelRows = [];
    if (category === 'line') {
      excelColumns = [
        '판매일',
        '첫 판매',
        '거래처코드',
        '사업자번호',
        '판매처명',
        '지역',
        '출하창고',
        '적요',
        '메모',
        '모바일',
        '품목명',
        '검색창내용',
        '결제유형',
        '결제조건',
        ...baseExcelColumns,
      ];
      excelRows = [
        ...salesStatusDatas.map((item) => ({
          deliveryDate: item.deliveryDate,
          firstSale: item.firstSale,
          accountCode: item.accountCode,
          businessNumber: item.businessNumber,
          accountName: item.accountName,
          region: item.region,
          warehouseName: item.warehouseName,
          briefs: item.briefs,
          memo: item.memo,
          mobile: item.mobile,
          productName: item.productName,
          search: item.search,
          dealType: item.dealType === '11' ? '부가세율적용' : '부가세율미적용',
          paymentTerms: item.paymentTerms,
          ...getBaseExcelRows(item),
        })),
      ];
    } else if (category === 'daily') {
      excelColumns = ['판매일', ...baseExcelColumns];
      excelRows = [
        ...salesStatusDatas.map((item) => ({
          deliveryDate: item.deliveryDate,
          ...getBaseExcelRows(item),
        })),
      ];
    } else if (category === 'monthly') {
      excelColumns = ['판매월', ...baseExcelColumns];
      excelRows = [
        ...salesStatusDatas.map((item) => ({
          yearMonthDate: item.yearMonthDate,
          ...getBaseExcelRows(item),
        })),
      ];
    } else if (category === 'order') {
      excelColumns = [
        '주문일',
        '판매일',
        '첫 판매',
        '거래처코드',
        '사업자번호',
        '판매처명',
        '지역',
        '적요',
        '메모',
        '모바일',
        '검색창내용',
        '기존결제유형',
        '결제조건',
        ...baseExcelColumns,
      ];
      excelRows = [
        ...salesStatusDatas.map((item) => ({
          regDate: item.regDate,
          deliveryDate: item.deliveryDate,
          firstSale: item.firstSale,
          accountCode: item.accountCode,
          businessNumber: item.businessNumber,
          accountName: item.accountName,
          region: item.region,
          briefs: item.briefs,
          memo: item.memo,
          mobile: item.mobile,
          search: item.search,
          dealType: item.dealType === '11' ? '부가세율적용' : '부가세율미적용',
          paymentTerms: item.paymentTerms,
          ...getBaseExcelRows(item),
        })),
      ];
    } else if (category === 'product') {
      excelColumns = ['품목코드', '품목명', ...baseExcelColumns];
      excelRows = [
        ...salesStatusDatas.map((item) => ({
          productCode: item.productCode,
          productName: item.productName,
          ...getBaseExcelRows(item),
        })),
      ];
    } else if (category === 'account') {
      excelColumns = [
        '거래처코드',
        '거래처명',
        '첫 판매',
        '사업자번호',
        '지역',
        '적요',
        '모바일',
        '품목명',
        '검색창내용',
        ...baseExcelColumns,
      ];
      excelRows = [
        ...salesStatusDatas.map((item) => ({
          accountCode: item.accountCode,
          accountName: item.accountName,
          firstSale: item.firstSale,
          businessNumber: item.businessNumber,
          region: item.region,
          briefs: item.briefs,
          mobile: item.mobile,
          productName: item.productName,
          search: item.search,
          ...getBaseExcelRows(item),
        })),
      ];
    } else if (category === 'admin') {
      excelColumns = ['담당자', ...baseExcelColumns];
      excelRows = [
        ...salesStatusDatas.map((item) => ({
          adminCode: item.adminCode,
          ...getBaseExcelRows(item),
        })),
      ];
    } else {
      return;
    }
    return { excelColumns, excelRows };
  };

  const handleClickExcelButton = async () => {
    try {
      // 엑셀 타이틀(제목)
      const excelTitle = [
        `${date && date.begin}~${date && date.end}_판매현황_(주)한통.xlsx`,
      ];
      // 엑셀 컬럼값, 로우값
      const { excelColumns, excelRows } = getSalesStatusExcelDatas();
      // 엑셀파일 다운로드 유틸함수 불러옴
      await handleSalesStatusTable(excelTitle, excelColumns, excelRows);
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return (
    <Button
      onClick={handleClickExcelButton}
      style={{
        borderRadius: '20px',
        backgroundColor: '#1d6f42',
        color: 'white',
      }}
    >
      <FileExcelOutlined />
      판매현황
    </Button>
  );
};

export default SalesStatusExcelHandler;

SalesStatusExcelHandler.propTypes = {
  salesStatusDatas: PropTypes.instanceOf(Array).isRequired,
  date: PropTypes.instanceOf(Object),
  category: PropTypes.string.isRequired,
};
