import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import UpdateTable from '../shared/TableModal/UpdateTable';
import CreateTable from '../shared/TableModal/CreateTable';
import DeleteTable from '../shared/TableModal/DeleteTable';

const AccountgroupData = (props) => {
  const { items } = props;
  const { modalInfo } = useSelector((state) => state.modal);

  // modal 종류에 따라 다른 component 리턴.
  // 공유 TableModal로 들어감.
  let accountgroup = null;
  if (modalInfo.modalTitle === '거래처그룹 수정') {
    accountgroup = () => {
      return <UpdateTable items={items} />;
    };
  } else if (modalInfo.modalTitle === '거래처그룹 삭제') {
    accountgroup = () => {
      return <DeleteTable items={items} />;
    };
  } else if (modalInfo.modalTitle === '거래처그룹 추가') {
    accountgroup = () => {
      return <CreateTable items={items} />;
    };
  }

  return accountgroup();
};

export default AccountgroupData;

AccountgroupData.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};
