import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { InvoiceProvider } from '../../contexts/InvoiceContext';
import InvoiceSearch from './InvoiceSearch';
import InvoiceCheckList from './InvoiceCheckList';
import InvoiceStatus from './InvoiceStatus';

const headerlineStyle = (marginTop = '0px') => ({
  marginTop,
  width: '100%',
  paddingBottom: '10px',
  borderBottom: '1px solid gray',
});

const headerSize = { fontSize: '16px' };

const InvoiceMain = () => {
  return (
    <InvoiceProvider>
      <div style={{ padding: '20px' }}>
        <div>
          <div style={headerlineStyle()}>
            <span style={headerSize}>단건 검색</span>
          </div>
          <InvoiceSearch />
        </div>
        <div>
          <div style={headerlineStyle('50px')}>
            <span style={headerSize}>체크리스트</span>
          </div>
          <InvoiceCheckList />
        </div>
        <div>
          <div
            style={{
              ...headerlineStyle('50px'),
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
            }}
          >
            <span style={headerSize}>거래명세서 현황</span>
            <span style={{ ...headerSize, color: 'gray', fontSize: '12px' }}>
              <InfoCircleOutlined style={{ marginRight: '5px' }} />
              동일 일자, 동일 품목(개별 출고건)의 경우 합산됩니다.
            </span>
          </div>
          <InvoiceStatus />
        </div>
      </div>
    </InvoiceProvider>
  );
};

export default InvoiceMain;
