import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';
import PurchaseForm from '../../shared/purchase/PurchaseForm';
import ProductDetails from '../../shared/purchase/ProductDetails';
import FormController from '../../shared/purchase/FormController';
import useModal from '../../hook/useModal';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import { asyncDeleteDataFetch } from '../../redux/modules/shared/deleteData';

const DataHandler = (props) => {
  const { pageType } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const history = useHistory();
  const signinAdminId = useSelector((state) => state.signinUser.userInfo.id);

  const [purchaseOrderForm, setPurchaseOrderForm] = useState({
    purchaseOrderDate: null,
    deliveryDate: null,
    accountId: null,
    adminId: null,
    warehouseId: null,
    dealType: null,
  });
  const [productDetails, setProductDetails] = useState([]);

  // form값 불러오기
  useEffect(() => {
    // update, detail
    if (pageType === 'update' || pageType === 'detail') {
      const getFormData = async () => {
        const purchaseOrderData = await dispatch(
          asyncReadSpecificDataFetch({
            table: 'purchase_order',
            id,
          })
        )
          .unwrap()
          .then((res) => res[0]);
        const productDatas = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'purchase_order_product',
            eqKey: 'purchase_order_id',
            eqValue: id,
          })
        )
          .unwrap()
          .then((res) => res);
        setPurchaseOrderForm({
          deliveryDate: purchaseOrderData.delivery_date,
          purchaseOrderDate: purchaseOrderData.purchase_order_date,
          accountId: purchaseOrderData.account_id,
          adminId: purchaseOrderData.admin_id,
          warehouseId: purchaseOrderData.warehouse_id,
          dealType: purchaseOrderData.deal_type,
        });
        setProductDetails([
          ...productDatas.map((data) => ({
            productId: data.product_id,
            productQuantity: data.product_quantity,
          })),
        ]);
      };
      getFormData();
      // create
    } else {
      const getInitialFormData = () => {
        const today = dayjs().format('YYYY-MM-DD');
        const adminId = signinAdminId;
        setPurchaseOrderForm((prev) => ({
          ...prev,
          deliveryDate: today,
          purchaseOrderDate: today,
          adminId,
        }));
      };
      getInitialFormData();
    }
  }, [pageType, dispatch, id, signinAdminId]);

  // 취소 기능 (초기화)
  const initFormStates = () => {
    setPurchaseOrderForm({
      purchaseOrderDate: null,
      deliveryDate: null,
      accountId: null,
      adminId: null,
      warehouseId: null,
      dealType: null,
    });
    setProductDetails([]);
  };

  // 발주서 추가기능
  const createHandler = async () => {
    // 발주 - 품목 개수 1개 이상만 추가 가능
    const isNumberOfProductOverOne = productDetails.length > 0;
    if (!isNumberOfProductOverOne) {
      alert('품목이 존재하지 않습니다. 1개 이상 선택해주세요.');
      return;
    }
    const confirmCreate = window.confirm('발주 데이터를 추가하시겠습니까?');
    if (!confirmCreate) return;
    try {
      // 발주 (purchase_order)
      const purchaseOrderId = await dispatch(
        asyncCreateDataFetch({
          table: 'purchase_order',
          purchase_order_date: purchaseOrderForm.purchaseOrderDate,
          delivery_date: purchaseOrderForm.deliveryDate,
          admin_id: purchaseOrderForm.adminId,
          account_id: purchaseOrderForm.accountId,
          warehouse_id: purchaseOrderForm.warehouseId,
          deal_type: purchaseOrderForm.dealType,
        })
      )
        .unwrap()
        .then((res) => res[0].id);
      // 발주-품목 (purchase_order_product)
      for (const product of productDetails) {
        await dispatch(
          asyncCreateDataFetch({
            table: 'purchase_order_product',
            purchase_order_id: purchaseOrderId,
            product_id: product.productId,
            product_quantity: product.productQuantity,
          })
        );
      }
      openModal({
        type: 'result',
        modalInfo: {
          modalTitle: '발주서 추가',
          resultStatus: 'success',
          resultTitle: '발주서 데이터를 추가했습니다.',
          resultSubTitle: '',
          buttonText: '확인',
        },
      });
      history.push('/purchase-order');
      // ecount 연동
    } catch (error) {
      throw new Error(error);
    }
  };

  // 발주서 수정기능
  const updateHandler = async () => {
    // 발주 - 품목 개수 1개 이상만 수정 가능
    const isNumberOfProductOverOne = productDetails.length > 0;
    if (!isNumberOfProductOverOne) {
      alert('품목이 존재하지 않습니다. 1개 이상 선택해주세요.');
      return;
    }
    const confirmUpdate = window.confirm(
      '정말 발주 데이터를 수정하시겠습니까?'
    );
    if (!confirmUpdate) return;
    try {
      // 이미 구매로 넘긴 데이터 수정 불가
      const isAlreadyPurchased = await dispatch(
        asyncReadSpecificDataFetch({ table: 'purchase_order', id })
      )
        .unwrap()
        .then((res) => res[0].status === 1);
      if (isAlreadyPurchased) {
        alert('이미 구매로 넘긴 발주서는 수정할 수 없습니다.');
        return;
      }
      // 발주 (purchase_order)
      await dispatch(
        asyncUpdateDataFetch({
          table: 'purchase_order',
          id,
          purchase_order_date: purchaseOrderForm.purchaseOrderDate,
          delivery_date: purchaseOrderForm.deliveryDate,
          admin_id: purchaseOrderForm.adminId,
          account_id: purchaseOrderForm.accountId,
          warehouse_id: purchaseOrderForm.warehouseId,
          deal_type: purchaseOrderForm.dealType,
        })
      );
      // 발주-품목 (purchase_order_product)
      // 기존 purchase_order_product 가져오기
      const newPurchaseOrderProducts = productDetails;
      const savedPurchaseOrderProducts = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'purchase_order_product',
          eqKey: 'purchase_order_id',
          eqValue: id,
        })
      )
        .unwrap()
        .then((res) => res);
      // 기존에 저장되어 있으면서 수정시에도 존재하는 데이터 -> 수량 수정처리
      const existingAndModifiedDataArr = [];
      await savedPurchaseOrderProducts.forEach((savedItem) => {
        newPurchaseOrderProducts.forEach((newItem) => {
          const isEqualProductId = savedItem.product_id === newItem.productId;
          if (isEqualProductId)
            existingAndModifiedDataArr.push({
              ...newItem,
              purchaseOrderProductId: savedItem.id,
            });
        });
      });
      if (existingAndModifiedDataArr.length > 0) {
        for (const itemToUpdate of existingAndModifiedDataArr) {
          const { productQuantity, purchaseOrderProductId } = itemToUpdate;
          await dispatch(
            asyncUpdateDataFetch({
              table: 'purchase_order_product',
              id: purchaseOrderProductId,
              product_quantity: productQuantity,
              state: 1,
              mod_date: dayjs(),
            })
          );
        }
      }
      // 기존에 저장되어 있지만 수정시에 삭제된 데이터 -> 삭제처리
      const existingButDeletedDataArr = savedPurchaseOrderProducts.filter(
        (savedItem) => {
          let isEqualProductId = false;
          newPurchaseOrderProducts.forEach((newItem) => {
            if (savedItem.product_id === newItem.productId)
              isEqualProductId = true;
          });
          return !isEqualProductId;
        }
      );
      if (existingButDeletedDataArr.length > 0) {
        for (const itemToDelete of existingButDeletedDataArr) {
          const purchaseOrderProductId = itemToDelete.id;
          await dispatch(
            asyncDeleteDataFetch({
              table: 'purchase_order_product',
              id: purchaseOrderProductId,
            })
          );
        }
      }
      // 기존에 저장되지 않았지만 수정시에 추가된 데이터 -> 생성처리
      const newAddedDataArr = newPurchaseOrderProducts.filter((newItem) => {
        let isEqualProductId = false;
        savedPurchaseOrderProducts.forEach((savedItem) => {
          if (savedItem.product_id === newItem.productId)
            isEqualProductId = true;
        });
        return !isEqualProductId;
      });
      if (newAddedDataArr.length > 0) {
        for (const itemToCreate of newAddedDataArr) {
          const purchaseOrderId = id;
          const { productId, productQuantity } = itemToCreate;
          await dispatch(
            asyncCreateDataFetch({
              table: 'purchase_order_product',
              state: 1,
              product_id: productId,
              product_quantity: productQuantity,
              purchase_order_id: purchaseOrderId,
            })
          );
        }
      }
      openModal({
        type: 'result',
        modalInfo: {
          modalTitle: '발주서 수정',
          resultStatus: 'success',
          resultTitle: '발주서 데이터를 수정했습니다.',
          resultSubTitle: '',
          buttonText: '확인',
        },
      });
      history.push('/purchase-order');
    } catch (error) {
      throw new Error(error);
    }
  };

  // 발주서 삭제기능
  const deleteHandler = async () => {
    const confirmDelete = window.confirm(
      '정말 발주 데이터를 삭제하시겠습니까?'
    );
    if (!confirmDelete) return;
    try {
      // 발주 삭제
      await dispatch(
        asyncUpdateDataFetch({
          table: 'purchase_order',
          id,
          state: 9,
          del_date: dayjs(),
        })
      );
      // 발주 - 품목 삭제
      const purchaseOrderProductIds = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'purchase_order_product',
          eqKey: 'purchase_order_id',
          eqValue: id,
        })
      )
        .unwrap()
        .then((res) => res.map((item) => item.id));
      for (const purchaseOrderProductId of purchaseOrderProductIds) {
        await dispatch(
          asyncUpdateDataFetch({
            table: 'purchase_order_product',
            id: purchaseOrderProductId,
            state: 9,
            del_date: dayjs(),
          })
        );
      }
      openModal({
        type: 'result',
        modalInfo: {
          modalTitle: '발주서 삭제',
          resultStatus: 'success',
          resultTitle: '발주서 데이터를 삭제했습니다.',
          resultSubTitle: '',
          buttonText: '확인',
        },
      });
      history.push('/purchase-order');
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <PurchaseForm
        formType="purchaseOrder"
        purchaseOrderForm={purchaseOrderForm}
        setPurchaseOrderForm={setPurchaseOrderForm}
        pageType={pageType}
      />
      <ProductDetails
        formType="purchaseOrder"
        productDetails={productDetails}
        setProductDetails={setProductDetails}
        pageType={pageType}
      />
      <FormController
        pageType={pageType}
        pathUrl="purchase-order"
        initFormStates={initFormStates}
        createHandler={createHandler}
        updateHandler={updateHandler}
        deleteHandler={deleteHandler}
      />
    </div>
  );
};

export default DataHandler;

DataHandler.propTypes = {
  pageType: PropTypes.string.isRequired,
};
