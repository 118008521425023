import React from 'react';
import PropTypes from 'prop-types';

const SelectedOptionContainer = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
        gap: '5px',
      }}
    >
      {children}
    </div>
  );
};

export default SelectedOptionContainer;

SelectedOptionContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
