import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes, { number } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DescriptionForm from './DescriptionForm';
import Postcode from '../../shared/address/Postcode';
import useModal from '../../hook/useModal';
import { asyncCreateDataFetch } from '../../redux/modules/shared/createData';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import { asyncDeleteDataFetch } from '../../redux/modules/shared/deleteData';
import ecount from '../../ecount';
import admDist from '../../db/admDist';
import useSupabase from '../../serverConfig';

const DescriptionData = (props) => {
  const {
    datas,
    setDatas,
    dataType,
    accountGroups,
    id,
    pageType,
    addressDatas,
    setAddressDatas,
    addressLoading,
    respDatas,
    setRespDatas,
  } = props;

  const dispatch = useDispatch();
  const { openModal } = useModal();
  const history = useHistory();
  const { saveAccount } = ecount();

  const items = useMemo(
    () => [
      {
        name: 'code',
        value: datas.code,
        label: '거래처코드',
        type: 'input',
        span: 1,
        placeholder: '*필수입력',
        inputType: '',
      },
      {
        name: 'name',
        value: datas.name,
        label: '상호(이름)',
        type: 'input',
        span: 1,
        placeholder: '상호를 입력해주세요. *필수입력 ',
        inputType: '',
      },
      {
        name: 'is_sample',
        value: datas.is_sample,
        label: '샘플거래처',
        type: 'select',
        options: [
          { label: 'ON', value: useSupabase ? true : 1 },
          { label: 'OFF', value: useSupabase ? false : 0 },
        ],
        span: 1,
        placeholder: '샘플거래처 여부를 선택해주세요.',
      },
      {
        name: 'ecount_code',
        values: datas.ecount_code,
        label: '이카운트코드',
        type: 'input',
        span: 1,
        placeholder: '이카운트 코드 (자동 입력)',
        inputType: '',
      },
      {
        name: 'business_number',
        values: datas.business_number,
        label: '사업자번호',
        type: 'input',
        span: 2,
        placeholder: '사업자번호가 있으면 입력해주세요.',
        inputType: 'number',
      },
      {
        name: 'representative',
        value: datas.representative,
        label: '대표자명',
        type: 'input',
        span: '1',
        placeholder: '대표자명을 입력해주세요.',
        inputType: '',
      },
      {
        name: 'conditions',
        value: datas.conditions,
        label: '업태',
        type: 'input',
        span: 1,
        placeholder: '업태를 입력해주세요.',
        inputType: '',
      },
      {
        name: 'category',
        value: datas.category,
        label: '종목',
        type: 'input',
        span: 1,
        placeholder: '종목을 입력해주세요.',
        inputType: '',
      },
      {
        name: 'resp',
        value: '',
        label: '담당자목록',
        type: 'resp',
        span: 3,
        inputType: '',
      },
      {
        name: 'phone',
        value: datas.phone,
        label: '전화/안심번호',
        type: 'input',
        span: 1,
        placeholder: '예) 031-1234-5678',
        inputType: 'tel',
      },
      {
        name: 'mobile',
        value: datas.mobile,
        label: '모바일',
        type: 'input',
        span: 1,
        placeholder: '예) 010-1234-5678',
        inputType: 'tel',
      },
      {
        name: 'email',
        value: datas.email,
        label: '이메일',
        type: 'input',
        span: 1,
        placeholder: '예) email@gmail.com',
        inputType: 'email',
      },
      {
        name: 'payment_type',
        value: datas.payment_type,
        label: '기본결제유형',
        type: 'select',
        span: 1,
        dataTypeNum: 3,
        placeholder: '기본결제유형을 입력해주세요.',
        inputType: '',
        options: [
          { label: '현금', value: '현금' },
          { label: '법인카드', value: '법인카드' },
          { label: '카드', value: '카드' },
          { label: '카드/한통', value: '카드/한통' },
          { label: '한통', value: '한통' },
          { label: '스토어', value: '스토어' },
          { label: '쿠팡', value: '쿠팡' },
          { label: '부가세미적용', value: '부가세미적용' },
          { label: '포함', value: '포함' },
        ],
      },
      {
        name: 'region',
        value: datas.region,
        label: '지역',
        type: 'select',
        span: 1,
        placeholder: '지역을 입력해주세요.',
        inputType: '',
        options: admDist.map((data) => ({
          label: data,
          value: data,
        })),
      },
      {
        name: 'machine_vinyl',
        value: datas.machine_vinyl,
        label: '기계/비닐(제품유형)',
        type: 'input',
        span: 1,
        placeholder: '기계/비닐을 입력해주세요.',
        inputType: '',
      },
      {
        name: 'account_group1',
        value: datas.account_group1,
        label: '거래처그룹1',
        type: 'select',
        span: 1,
        placeholder: '거래처그룹을 선택해주세요.',
        inputType: '',
        options: accountGroups,
      },
      {
        name: 'account_group2',
        value: datas.account_group2,
        label: '거래처그룹2',
        type: 'select',
        span: 1,
        placeholder: '거래처그룹을 선택해주세요.',
        inputType: '',
        options: accountGroups,
      },
      {
        name: 'first_sale',
        value: datas.first_sale,
        label: '첫판매',
        type: 'input',
        span: 3,
        placeholder: '예) YYYY-MM-DD',
        inputType: '',
      },
      {
        name: 'briefs',
        value: datas.briefs,
        label: '적요',
        type: 'input',
        span: 3,
        placeholder: '적요를 입력해주세요.',
        inputType: '',
      },
      {
        name: 'address',
        value: '',
        label: '주소지관리',
        type: 'address',
        span: 3,
        inputType: '',
      },
      {
        name: 'code_sortation',
        value: datas.code_sortation,
        label: '거래처코드구분',
        type: 'radio',
        span: 3,
        placeholder: '거래처코드구분을 선택해주세요.',
        inputType: '',
        options: [
          { label: '사업자등록번호', value: '사업자등록번호' },
          { label: '비사업자(내국인)', value: '비사업자(내국인)' },
          { label: '비사업자(외국인)', value: '비사업자(외국인)' },
        ],
      },
      {
        name: 'tex_reg_number',
        value: datas.tex_reg_number,
        label: '종사업장번호',
        type: 'input',
        span: 1,
        placeholder: '4자리를 입력해주세요.',
        inputType: number,
      },
      {
        name: 'fax',
        value: datas.fax,
        label: 'FAX',
        type: 'input',
        span: 1,
        placeholder: '예) 031-1234-5678',
        inputType: 'tel',
      },
      {
        name: 'search',
        value: datas.search,
        label: '검색창내용',
        type: 'input',
        span: 1,
        placeholder: '검색창내용을 입력해주세요.',
        inputType: '',
      },
      {
        name: 'file',
        value: datas.file,
        label: '파일관리',
        type: 'file',
        span: 2,
        placeholder: '',
        inputType: '',
      },
      {
        name: 'homepage',
        value: datas.homepage,
        label: '홈페이지',
        type: 'input',
        span: 1,
        placeholder: '예) https://example.com',
        inputType: 'url',
      },
    ],
    [datas, accountGroups]
  );

  // form의 거래처 추가 or 수정 or 삭제 버튼 클릭시 처리하는 함수
  const onFinish = useCallback(
    (inputValues) => {
      console.log(inputValues);
      // 첫판매 형식 검사
      if (
        typeof inputValues.first_sale === 'string' &&
        inputValues.first_sale.length > 0 &&
        !/^\d{4}-\d{2}-\d{2}$/.test(inputValues.first_sale)
      ) {
        return alert('첫판매 날짜 형식을 맞춰주세요. 예)YYYY-MM-DD');
      }
      // 주소지 없는 상태에서 유효성 검사 알림 띄우기
      if (addressDatas.length === 0) {
        return alert('주소지를 1개 이상 추가해주세요.');
      }
      // 거래처 입력 유효성 검사 알림 띄우기
      // 샘플거래처 none select 검사
      if (!inputValues.is_sample === undefined) {
        return alert('샘플거래처를 선택해주세요.');
      }
      if (
        (inputValues.code && inputValues.code.length === 0) ||
        inputValues.code === undefined
      ) {
        // 거래처 코드 빈 문자열 검사
        return alert('(기본정보) 거래처 코드를 입력해주세요.');
      }
      // 상호(이름) 빈 문자열 검사
      if (
        (inputValues.name && inputValues.name.length === 0) ||
        inputValues.name === undefined
      ) {
        return alert('(기본정보) 상호(이름)을 입력해주세요.');
      }
      // 전화 (phone) 유효성 검사
      if (
        typeof inputValues.phone === 'string' &&
        inputValues.phone &&
        inputValues.phone.length !== 0 &&
        !/^[0-9]{2,4}-[0-9]{3,4}-[0-9]{4}/.test(inputValues.phone)
      ) {
        return alert(
          '(기본정보) 전화번호 또는 안심번호 형식에 맞게 입력해주세요. ex)031-000-0000'
        );
      }
      // 이메일 (email) 유효성 검사
      if (
        typeof inputValues.email === 'string' &&
        inputValues.email &&
        inputValues.email.length !== 0 &&
        !/^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/.test(
          inputValues.email
        )
      ) {
        return alert(
          '(기본정보) 이메일 형식에 맞게 입력해주세요. ex)email@gmail.com'
        );
      }
      // 팩스 (fax) 유효성 검사
      if (
        typeof inputValues.fax === 'string' &&
        inputValues.fax &&
        inputValues.fax.length !== 0 &&
        !/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(inputValues.fax)
      ) {
        return alert(
          '(거래처 정보) FAX 형식에 맞게 입력해주세요. ex)031-000-0000'
        );
      }
      // 모바일 (mobile) 유효성 검사
      if (
        typeof inputValues.mobile === 'string' &&
        inputValues.mobile &&
        inputValues.mobile.length !== 0 &&
        !/^[0-9]{3}-[0-9]{4}-[0-9]{4}/.test(inputValues.mobile)
      ) {
        return alert(
          '(거래처 정보) 모바일번호 형식에 맞게 입력해주세요. ex)010-0000-0000'
        );
      }
      // 홈페이지 (homepage) 유효성 검사
      if (
        typeof inputValues.homepage === 'string' &&
        inputValues.homepage &&
        inputValues.homepage.length !== 0 &&
        !/(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(
          inputValues.homepage
        )
      ) {
        return alert(
          '(거래처 정보) 홈페이지 url 형식에 맞게 입력해주세요. ex)https://google.com'
        );
      }
      // 종사업장번호 유효성검사
      if (
        typeof inputValues.tex_reg_number === 'string' &&
        inputValues.tex_reg_number &&
        inputValues.tex_reg_number.length !== 4
      ) {
        return alert('종사업장번호를 4자리 입력해주세요.');
      }
      const isSample = inputValues.is_sample;
      const businessNumber = inputValues.business_number;
      // ecount_code 처리하기
      let ecountCode = null;
      // 샘플거래처 true면서, 사업자번호가 입력되지 않으면, S~로 시작하는 코드 생성 후 ecount_code에 넣기
      if (isSample) {
        ecountCode = inputValues.code.replace('A', 'S');
        // 샘플거래처 false 면서, 사업자번호가 입력되면 ecount_code에 사업자번호 넣기
      } else if (!isSample && businessNumber?.length >= 1) {
        ecountCode = businessNumber;
        // 그외엔 ecount_code에 거래처 code 넣기
      } else {
        ecountCode = inputValues.code;
      }
      const newInputValues = { ...inputValues, ecount_code: ecountCode };

      const { code } = inputValues;
      if (pageType === 'create') {
        const values = {
          table: 'account',
          ...newInputValues,
        };
        dispatch(asyncCreateDataFetch(values))
          .unwrap()
          .then((res) => {
            // 거래처주소 (account_address) 데이터 등록하기
            addressDatas.forEach((data) => {
              dispatch(
                asyncCreateDataFetch({
                  table: 'account_address',
                  name: data.name,
                  post_code: data.postCode,
                  is_representative: data.isRepresentative,
                  account_code: code,
                  address: data.address,
                  longitude: data.longitude,
                  latitude: data.latitude,
                })
              )
                .unwrap()
                .then()
                .catch((err) => {
                  console.log(err);
                  alert(err.message);
                });
            });
            // 담당자 (account_resp) 데이터 등록하기
            respDatas.forEach((data) => {
              dispatch(
                asyncCreateDataFetch({
                  table: 'account_resp',
                  name: data.name,
                  phone: data.phone,
                  email: data.email,
                  is_representative: data.isRepresentative,
                  account_code: code,
                })
              )
                .unwrap()
                .then()
                .catch((err) => {
                  console.log(err);
                  alert(err.message);
                });
            });
            // 성공 모달 띄우기
            openModal({
              type: 'result',
              modalInfo: {
                modalTitle: '거래처 추가',
                resultStatus: 'success',
                resultTitle: '거래처를 추가했습니다.',
                resultSubTitle: '',
                buttonText: '확인',
              },
            });
            // 목록으로 넘어가기
            history.push('/account');
            // ecountERP 로 연동
            saveAccount({
              accountId: res[0]?.id,
              newInputValues,
              addressDatas,
              accountGroups,
              respDatas,
            });
          })
          .catch((err) => {
            alert(err.message);
            console.log(err);
          });
      }
      if (pageType === 'update') {
        const values = {
          table: 'account',
          id,
          state: 1,
          ...inputValues,
        };
        dispatch(asyncUpdateDataFetch(values))
          .unwrap()
          .then(() => {
            // 거래처주소 (account_address) 데이터 수정하기
            const asyncUpdateAddress = async () => {
              // 거래처주소 일괄 삭제
              await dispatch(
                asyncDeleteDataFetch({
                  table: 'account_address',
                  accountCode: code,
                })
              )
                .unwrap()
                .then()
                .catch((err) => {
                  console.log(err);
                  alert(err.message);
                });
              // 거래처주소 등록
              await addressDatas.forEach((data) => {
                dispatch(
                  asyncCreateDataFetch({
                    table: 'account_address',
                    name: data.name,
                    post_code: data.postCode,
                    is_representative: data.isRepresentative,
                    account_code: code,
                    address: data.address,
                    longitude: data.longitude,
                    latitude: data.latitude,
                  })
                )
                  .unwrap()
                  .then()
                  .catch((err) => {
                    console.log(err);
                    alert(err.message);
                  });
              });
            };
            // 담당자목록 데이터 (account_resp) 수정하기
            const asyncUpdateResp = async () => {
              // 거래처담당자 일괄 삭제
              await dispatch(
                asyncDeleteDataFetch({
                  table: 'account_resp',
                  accountCode: code,
                })
              )
                .unwrap()
                .then()
                .catch((err) => {
                  console.log(err);
                  alert(err.message);
                });
              // 거래처담당자 등록
              await respDatas.forEach((data) => {
                dispatch(
                  asyncCreateDataFetch({
                    table: 'account_resp',
                    name: data.name,
                    phone: data.phone,
                    email: data.email,
                    is_representative: data.isRepresentative,
                    account_code: code,
                  })
                )
                  .unwrap()
                  .then()
                  .catch((err) => {
                    console.log(err);
                    alert(err.message);
                  });
              });
            };
            asyncUpdateAddress();
            asyncUpdateResp();
            // 성공 모달 띄우기
            openModal({
              type: 'result',
              modalInfo: {
                modalTitle: '거래처 수정',
                resultStatus: 'success',
                resultTitle: '거래처를 수정했습니다.',
                resultSubTitle: `거래처 코드 *${code}* 거래처를 수정했습니다.`,
                buttonText: '확인',
              },
            });
            // 목록으로 넘어가기
            history.push('/account');
          })
          .catch((err) => alert(err.message));
      }
      if (pageType === 'detail') {
        const result = window.confirm('정말 삭제하시겠습니까?');
        if (!result) return;
        const values = {
          table: 'account',
          id,
          state: 9,
        };
        dispatch(asyncUpdateDataFetch(values))
          .unwrap()
          .then(() => {
            // 성공 모달 띄우기
            openModal({
              type: 'result',
              modalInfo: {
                modalTitle: '거래처 삭제',
                resultStatus: 'success',
                resultTitle: '거래처를 삭제했습니다.',
                resultSubTitle: '',
                buttonText: '확인',
              },
            });
            // 목록으로 넘어가기
            history.push('/account');
          })
          .catch((err) => alert(err.message));
      }
    },
    [
      id,
      dispatch,
      openModal,
      history,
      saveAccount,
      pageType,
      addressDatas,
      respDatas,
      accountGroups,
    ]
  );

  return (
    <>
      <DescriptionForm
        onFinish={onFinish}
        datas={datas}
        setDatas={setDatas}
        descriptionDatas={items}
        pageType={pageType}
        addressDatas={addressDatas}
        setAddressDatas={setAddressDatas}
        addressLoading={addressLoading}
        respDatas={respDatas}
        setRespDatas={setRespDatas}
      />
      <Postcode />
    </>
  );
};

export default DescriptionData;

DescriptionData.propTypes = {
  datas: PropTypes.instanceOf(Object).isRequired,
  setDatas: PropTypes.func.isRequired,
  accountGroups: PropTypes.instanceOf(Object).isRequired,
  dataType: PropTypes.string,
  id: PropTypes.string,
  pageType: PropTypes.string.isRequired,
  addressDatas: PropTypes.instanceOf(Array).isRequired,
  setAddressDatas: PropTypes.func.isRequired,
  addressLoading: PropTypes.bool.isRequired,
  respDatas: PropTypes.instanceOf(Array).isRequired,
  setRespDatas: PropTypes.func.isRequired,
};
