import { DeliveredProcedureOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Datas from '../components/Datas';

const Directions = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <DeliveredProcedureOutlined />,
        breadCrumbNav: ' 출하지시서',
      },
    ],
    []
  );
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px' }}>
        <Datas />
      </div>
    </LayoutComponent>
  );
};

export default Directions;
