import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../axios';
import useSupabase from '../../../serverConfig';

import supabase from '../../../supabase';
import generateMatchQueryString from '../../../utils/generateMatchQueryString';

const initialState = {
  isLoadingReadMatchData: false,
  isErrorReadMatchData: false,
  isSuccessReadMatchData: false,
};

const asyncReadMatchDataFetch = createAsyncThunk(
  'readMatchDataSlice/asyncReadMatchDataFetch',
  async (values, { rejectWithValue }) => {
    // values의 필수 값 => table
    const { table, match, order, limit } = values;
    try {
      const { data } = useSupabase
        ? await supabase
            .from(table)
            .select('*')
            .match(match)
            .order(order, { ascending: false })
            .limit(limit)
        : await axiosInstance.get(
            `/api/dynamic-crud/${table}/match-limit/${limit}?order=${order}${generateMatchQueryString(
              match
            )}`
          );

      console.log(data);

      const result = data.filter((item) => item.state !== 9);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const readMatchDataSlice = createSlice({
  name: 'readMatchData',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncReadMatchDataFetch.pending, (state) => {
      state.isLoadingReadMatchData = true;
      state.isSuccessReadMatchData = false;
      state.isErrorReadMatchData = false;
    });
    builder.addCase(asyncReadMatchDataFetch.fulfilled, (state) => {
      state.isLoadingReadMatchData = false;
      state.isSuccessReadMatchData = true;
      state.isErrorReadMatchData = false;
    });
    builder.addCase(asyncReadMatchDataFetch.rejected, (state) => {
      state.isLoadingReadMatchData = false;
      state.isSuccessReadMatchData = false;
      state.isErrorReadMatchData = true;
    });
  },
});

export default readMatchDataSlice.reducer;
export { asyncReadMatchDataFetch };
