import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Loading from '../../shared/components/Loading';
import {
  asyncRefreshSessionFetch,
  endRefreshSession,
} from '../../redux/modules/auth/refreshSession';
import {
  loggedInRefreshSessionReducer,
  notLoggedInRefreshSessionReducer,
} from '../../redux/modules/auth/signinUser';
import useRefreshHook from '../../hook/useRefreshHook';

// 로그인 되었을 때 접근 가능한 컴포넌트
const PrivateRoute = (props) => {
  const { component, path, exact } = props;
  const { isLoggedIn } = useSelector((state) => state.signinUser);
  const { sessionChecked } = useSelector((state) => state.refreshSession);

  useRefreshHook();

  if (!sessionChecked) {
    return <Loading />;
  }

  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return <Route component={component} path={path} exact={exact} />;
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
};
