import axios from 'axios';
import { useDispatch } from 'react-redux';
import { asyncCreateDataFetch } from './redux/modules/shared/createData';
import { asyncReadFilteredDataFetch } from './redux/modules/shared/readFilteredData';
import { asyncReadSpecificDataFetch } from './redux/modules/shared/readSpecificData';

const ecount = () => {
  const dispatch = useDispatch();

  // 세션에러 재시도시 alert
  const alertWhenRetried = (zoneTryCount, sessionIdTryCount) => {
    const isRetried = zoneTryCount > 1 || sessionIdTryCount > 1;
    if (isRetried) {
      const alertMessage = `
      세션 에러가 발생하여 자동으로 재시도 하였습니다.
      재시도 횟수는 아래와 같습니다.
    
      -zone: ${zoneTryCount - 1}회, sessionId: ${sessionIdTryCount - 1}회
      `;
      alert(alertMessage);
    } else {
      return;
    }
  };

  // 세션에러 최대10회 재시도 (zone, sessionId)
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const retryUntilNotNull = async (fn, maxRetries) => {
    let tryCount = 0;
    let result = null;

    for (let i = 1; i <= maxRetries; i += 1) {
      result = await fn();
      tryCount = i;
      if (result !== null) {
        break;
      }
      await delay(300);
    }

    return [result, tryCount];
  };

  /* !제거 문의!
  // 세션에러 핸들러 (zone, sessionId)
  const handleSessionError = async (errInfo) => {
    // code 코드
    const code = errInfo.code;
    // config 요청 구성정보
    const config = errInfo.config.data;
    // method 메서드
    const method = errInfo.config.method;
    // url 요청 주소
    const url = errInfo.config.url;
    // headers 요청 헤더
    const headers = errInfo.config.headers.Accept;
    // message 에러 메시지
    const message = errInfo.message;
    // stack 에러 스택
    const stack = errInfo.stack;

    const postData = {
      code,
      config,
      method,
      url,
      headers,
      message,
      stack,
    };

    try {
      await dispatch(
        asyncCreateDataFetch({ table: 'session_error_record', ...postData })
      )
        .unwrap()
        .then((res) => console.log(res));
      alert(
        `(Session Error) EcountERP에 저장 중 세션에러가 발생했습니다. ${message}`
      );
      console.log('세션에러 DB저장 완료');
    } catch (error) {
      console.log(error, '세션에러 DB저장 실패');
    }
  };
  */

  // 세션에러 10회 초과시 핸들러
  const handleOverSessionError = async (errorTable, errorId) => {
    try {
      dispatch(
        asyncCreateDataFetch({
          table: 'ecount_sync_error_record',
          target_table: errorTable,
          target_id: errorId,
          message: '세션에러 10회 초과',
        })
      );
      const alertMessage = `
      세션에러가 발생하여 자동으로 재시도 하였으나 10회를 초과하여
      EcountERP에 해당 데이터를 저장하지 못했습니다.
      `;
      alert(alertMessage);
    } catch (error) {
      throw new Error(error);
    }
  };

  // 싱크에러 핸들러
  const handleEcountSyncError = async (errorInfo, errorTable, errorId) => {
    // target_id 에러 해당 OMS id
    const targetId = errorId;
    // target_table 에러 해당 OMS 테이블 종류
    const targetTable = errorTable;
    // fail_count 실패횟수
    const failCount = errorInfo.data.Data.FailCnt;
    // success_count 성공횟수
    const successCount = errorInfo.data.Data.SuccessCnt;
    // quantity_info 갯수제한 정보
    const quantityInfo = errorInfo.data.Data.QUANTITY_INFO;
    // trace_id 추적 id
    const traceId = errorInfo.data.Data.TRACE_ID;
    // json_data json데이터
    const jsonData = errorInfo.config.data;
    // url 에러주소
    const url = errorInfo.config.url;
    // method 에러메서드
    const method = errorInfo.config.method;
    // timestamp 에러시간
    const timestamp = errorInfo.data.Timestamp;
    // message 에러 메시지
    let message = '';

    // message 에러 메시지 중 가장 긴 메시지 뽑아내기
    for (const detail of errorInfo.data.Data.ResultDetails) {
      const detailMessage = detail.TotalError;
      if (message.length < detailMessage.length) {
        message = detailMessage;
      }
    }

    const postData = {
      target_id: targetId,
      fail_count: failCount,
      success_count: successCount,
      quantity_info: quantityInfo,
      trace_id: traceId,
      json_data: jsonData,
      url,
      method,
      timestamp,
      target_table: targetTable,
      message,
    };

    try {
      dispatch(
        asyncCreateDataFetch({ table: 'ecount_sync_error_record', ...postData })
      );
      const alertMessage = `
      싱크에러가 발생하여 해당 데이터를 EcountERP에 저장하지 못했습니다.
      싱크에러 원인은 아래와 같습니다.
      
      -error: ${message}
      `;
      alert(alertMessage);
    } catch (error) {
      throw new Error(error);
    }
  };

  // 싱크에러 체크
  const checkEcountSyncError = async (response, table, id) => {
    let isSyncError = false;
    for (const detail of response.data.Data.ResultDetails) {
      const syncResult = detail.IsSuccess;
      if (!syncResult) {
        isSyncError = true;
      }
    }
    // 싱크 성공시
    if (!isSyncError) {
      return;
      // 싱크 실패시
    } else {
      handleEcountSyncError(response, table, id);
    }
  };

  // ZONE 받는 함수
  const getZone = async () => {
    const postData = { COM_CODE: '147270' };
    const url = 'https://oapi.ecount.com/OAPI/V2/Zone';
    try {
      const response = await axios({
        method: 'POST',
        url,
        data: postData,
      });
      return response.data.Data.ZONE;
    } catch (error) {
      // console.error(error);
      // handleSessionError(error);
      return null;
    }
  };

  // 로그인 SESSION_ID 받는 함수
  const getSessionId = async (ZONE) => {
    const postData = {
      COM_CODE: '147270',
      USER_ID: 'MASTER01',
      API_CERT_KEY: '37b8cd91c26a44f51bd97476417d28de32',
      LAN_TYPE: 'ko-KR',
      ZONE,
    };

    const url = `https://oapi${ZONE}.ecount.com/OAPI/V2/OAPILogin`;
    try {
      const response = await axios({
        method: 'POST',
        url,
        data: postData,
      });
      return response.data.Data.Datas.SESSION_ID;
    } catch (error) {
      // console.error(error);
      // handleSessionError(error);
      return null;
    }
  };

  // 품목 등록 하는 함수
  const saveProduct = async (values) => {
    // 이카운트 연동 처리
    // ZONE 받는 함수 호출
    const [zone, zoneTryCount] = await retryUntilNotNull(getZone, 10);
    // SESSION_ID 받는 함수 호출
    const [sessionId, sessionIdTryCount] = await retryUntilNotNull(
      () => getSessionId(zone),
      10
    );
    if (zone === null || sessionId === null) {
      handleOverSessionError('품목', values.id);
    }

    // OMS에 데이터 저장
    // 규격그룹 또는 규격계산그룹일 경우 '규격코드'를 입력해야함
    let standardVal = '';
    if (values.standard_type === '1') {
      standardVal = values.standard;
    }
    const postData = {
      ProductList: [
        {
          Line: '0',
          BulkDatas: {
            PROD_CD: values.code,
            PROD_DES: values.name,
            SIZE_FLAG: values.standard_type,
            SIZE_DES: standardVal,
            UNIT: values.unit,
            PROD_TYPE: values.category,
            // 규격그룹이 제품 또는 상품일 경우에만 세트여부를 '사용'할 수 있음
            // SET_FLAG: values.set,
            WH_CD: '',
            IN_PRICE: Number(values.incoming_price),
            IN_PRICE_VAT: values.incoming_price_VAT,
            OUT_PRICE: Number(values.release_price),
            OUT_PRICE_VAT: values.release_price_VAT,
            // '구매처 코드'를 입력해야함
            // CUST: values.purchase,
          },
        },
      ],
    };
    const url = `https://oapi${zone}.ecount.com/OAPI/V2/InventoryBasic/SaveBasicProduct?SESSION_ID=${sessionId}`;
    axios({
      method: 'POST',
      url,
      data: postData,
    })
      .then((response) => {
        checkEcountSyncError(response, '품목', values.id);
        alertWhenRetried(zoneTryCount, sessionIdTryCount);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  // 거래처 등록 하는 함수
  const saveAccount = async (values) => {
    // 이카운트 연동 처리
    // ZONE 받는 함수 호출
    const [zone, zoneTryCount] = await retryUntilNotNull(getZone, 10);
    // SESSION_ID 받는 함수 호출
    const [sessionId, sessionIdTryCount] = await retryUntilNotNull(
      () => getSessionId(zone),
      10
    );
    if (zone === null || sessionId === null) {
      handleOverSessionError('거래처', values.id);
    }

    // OMS에 데이터 저장
    // 주소지 선정
    const { addressDatas, newInputValues } = values;
    const addressData = await addressDatas.filter(
      (data) => data.isRepresentative
    );
    const dmAddressData = await addressDatas.filter(
      (data) => !data.isRepresentative
    );
    let address = '';
    let postCode = '';
    let dmAddress = '';
    let dmPostCode = '';
    if (addressData.length > 0) {
      address = addressData[0].address;
      postCode = addressData[0].postCode;
    }
    if (dmAddressData.length > 0) {
      dmAddress = dmAddressData[0].address;
      dmPostCode = dmAddressData[0].postCode;
    }
    // 거래처코드구분 값
    let modifiedCodeSortation = '';
    if (newInputValues.code_sortation === '사업자등록번호') {
      modifiedCodeSortation = '01';
    }
    if (newInputValues.code_sortation === '비사업자(내국인)') {
      modifiedCodeSortation = '02';
    }
    if (newInputValues.code_sortation === '비사업자(외국인)') {
      modifiedCodeSortation = '03';
    }
    const custList = [
      {
        Line: '0',
        BulkDatas: {
          BUSINESS_NO: newInputValues.ecount_code,
          CUST_NAME: newInputValues.name,
          BOSS_NAME: newInputValues.representative,
          UPTAE: newInputValues.conditions,
          JONGMOK: newInputValues.category,
          TEL: newInputValues.phone,
          EMAIL: newInputValues.email,
          // 안들어가는 부분
          POST_NO: postCode,
          ADDR: address,
          DM_POST: dmPostCode,
          DM_ADDR: dmAddress,
          CUST_GROUP1: newInputValues.account_group1,
          CUST_GROUP2: newInputValues.account_group2,
          G_GUBUN: modifiedCodeSortation,
          TAX_REG_ID: newInputValues.tex_reg_number,
          FAX: newInputValues.fax,
          HP_NO: newInputValues.mobile,
          REMARKS_WIN: newInputValues.search,
          URL_PATH: newInputValues.homepage,
          REMARKS: newInputValues.briefs,
        },
      },
    ];
    const postData = {
      CustList: custList,
    };
    const url = `https://oapi${zone}.ecount.com/OAPI/V2/AccountBasic/SaveBasicCust?SESSION_ID=${sessionId}`;
    axios({
      method: 'POST',
      url,
      data: postData,
    })
      .then((response) => {
        checkEcountSyncError(response, '거래처', values.accountId);
        alertWhenRetried(zoneTryCount, sessionIdTryCount);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  // 주문서 등록하는 함수
  const saveOrder = async (values) => {
    // 이카운트 연동 처리
    // ZONE 받는 함수 호출
    const [zone, zoneTryCount] = await retryUntilNotNull(getZone, 10);
    // SESSION_ID 받는 함수 호출
    const [sessionId, sessionIdTryCount] = await retryUntilNotNull(
      () => getSessionId(zone),
      10
    );
    if (zone === null || sessionId === null) {
      handleOverSessionError('주문', values.id);
    }

    // OMS에 데이터 저장
    // order 데이터 호출
    const order = await dispatch(
      asyncReadSpecificDataFetch({ table: 'order', id: values.id })
    )
      .unwrap()
      .then((res) => {
        return res[0];
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
    // account 데이터 호출 -> ecount_code 데이터 호출
    const ecount_code = await dispatch(
      asyncReadFilteredDataFetch({
        table: 'account',
        eqKey: 'code',
        eqValue: order.account_code,
      })
    )
      .unwrap()
      .then((res) => {
        return res[0].ecount_code;
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
    // order_product 데이터 호출
    const orderProducts = await dispatch(
      asyncReadFilteredDataFetch({
        table: 'order_product',
        eqKey: 'order_id',
        eqValue: values.id,
      })
    )
      .unwrap()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
    const orderList = [];
    for (let i = 0; i < orderProducts.length; i += 1) {
      const date = order.order_date.replace(/-/g, '');
      const code = orderProducts[i].product_code;
      const name = orderProducts[i].product_name;
      const quantity = orderProducts[i].product_quantity;
      const price = orderProducts[i].discounted_price;
      const vat = orderProducts[i].discounted_vat;
      const supply = orderProducts[i].discounted_supply;
      const result = {
        Line: String(i),
        BulkDatas: {
          IO_DATE: date,
          UPLOAD_SER_NO: '',
          CUST: ecount_code,
          CUST_DES: order.account_name,
          EMP_CD: order.admin_code,
          WH_CD: order.warehouse_code,
          IO_TYPE: order.deal_type,
          COLL_TERM: order.payment_terms,
          TIME_DATE: order.delivery_date,
          PROD_CD: code,
          PROD_DES: name,
          QTY: quantity,
          PRICE: price,
          USER_PRICE_VAT: price + vat,
          SUPPLY_AMT: supply,
          VAT_AMT: vat,
        },
      };
      orderList.push(result);
    }
    const postData = {
      SaleOrderList: orderList,
    };
    const url = `https://oapi${zone}.ecount.com/OAPI/V2/SaleOrder/SaveSaleOrder?SESSION_ID=${sessionId}`;
    axios({
      method: 'POST',
      url,
      data: postData,
    })
      .then((response) => {
        checkEcountSyncError(response, '주문', values.id);
        alertWhenRetried(zoneTryCount, sessionIdTryCount);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  // 판매입력 등록하는 함수
  const saveSalesInquiry = async (values) => {
    // 이카운트 연동
    // ZONE 받는 함수 호출
    const [zone, zoneTryCount] = await retryUntilNotNull(getZone, 10);
    // SESSION_ID 받는 함수 호출
    const [sessionId, sessionIdTryCount] = await retryUntilNotNull(
      () => getSessionId(zone),
      10
    );
    if (zone === null || sessionId === null) {
      handleOverSessionError('판매', values.id);
    }

    // OMS에 데이터 저장
    // 판매한 거래처 코드 oms DB에서 호출
    const ecount_code = await dispatch(
      asyncReadFilteredDataFetch({
        table: 'account',
        eqKey: 'code',
        eqValue: values.account_code,
      })
    )
      .unwrap()
      .then((res) => {
        return res[0].ecount_code;
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
    // 출고상품 데이터 oms DB에서 호출
    const orderProducts = await dispatch(
      asyncReadFilteredDataFetch({
        table: 'order_product',
        eqKey: 'order_id',
        eqValue: values.id,
      })
    )
      .unwrap()
      .then((res) => {
        return res;
      })
      .catch();
    // ecount api에 보내줄 Datas
    const saleList = [];
    // 출고상품 데이터를 ecount api 포맷에 맞게 변환
    for (let i = 0; i < orderProducts.length; i += 1) {
      // 날짜
      const date = values.order_date.replace(/-/g, '');
      // 상품코드
      const code = orderProducts[i].product_code;
      // 상품명
      const name = orderProducts[i].product_name;
      // 개수
      const quantity = orderProducts[i].product_quantity;
      // 단가
      const price = orderProducts[i].discounted_price;
      // 부가세
      const vat = orderProducts[i].discounted_vat;
      // 공급가액
      const supply = orderProducts[i].discounted_supply;
      const result = {
        Line: String(i),
        BulkDatas: {
          IO_DATE: date,
          UPLOAD_SER_NO: '',
          CUST: ecount_code,
          CUST_DES: values.account_name,
          EMP_CD: values.admin_code,
          WH_CD: values.warehouse_code,
          IO_TYPE: values.deal_type,
          PROD_CD: code,
          PROD_DES: name,
          QTY: quantity,
          PRICE: price,
          USER_PRICE_VAT: price + vat,
          SUPPLY_AMT: supply,
          VAT_AMT: vat,
        },
      };
      saleList.push(result);
    }
    // ecount api에서 요구하는대로 패키징
    const postData = {
      SaleList: saleList,
    };
    // ecount api 호출
    const url = `https://oapi${zone}.ecount.com/OAPI/V2/Sale/SaveSale?SESSION_ID=${sessionId}`;
    axios({
      method: 'POST',
      url,
      data: postData,
    })
      .then((response) => {
        checkEcountSyncError(response, '판매', values.id);
        alertWhenRetried(zoneTryCount, sessionIdTryCount);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  const savePurchase = async (values) => {
    try {
      // 이카운트 연동
      // ZONE 받는 함수 호출
      const [zone, zoneTryCount] = await retryUntilNotNull(getZone, 10);
      // SESSION_ID 받는 함수 호출
      const [sessionId, sessionIdTryCount] = await retryUntilNotNull(
        () => getSessionId(zone),
        10
      );
      if (zone === null || sessionId === null) {
        handleOverSessionError('구매', values.id);
      }

      // OMS에 데이터 저장
      // purchase
      const { id, account_id, warehouse_id, admin_id, purchase_date } = values;
      // 일자, 거래처코드, 거래처명, 담당자코드, 창고코드, 품목코드, 품목명, 품목규격, 품목수량, 단가, 공급가액, 부가세
      const account = await dispatch(
        asyncReadSpecificDataFetch({ table: 'account', id: account_id })
      )
        .unwrap()
        .then((res) => res[0]);
      // 거래처코드
      const accountCode = account.ecount_code;
      // 거래처명
      const accountName = account.name;
      // 담당자코드
      const adminCode = await dispatch(
        asyncReadSpecificDataFetch({ table: 'profiles', id: admin_id })
      )
        .unwrap()
        .then((res) => res[0].code);
      // 창고코드
      const warehouseCode = await dispatch(
        asyncReadSpecificDataFetch({ table: 'warehouse', id: warehouse_id })
      )
        .unwrap()
        .then((res) => res[0].code);
      const purchaseProducts = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'purchase_product',
          eqKey: 'purchase_id',
          eqValue: id,
        })
      )
        .unwrap()
        .then((res) => res);
      let purchaseList = [];
      for (let i = 0; i < purchaseProducts.length; i += 1) {
        const purchaseProduct = purchaseProducts[i];
        const product = await dispatch(
          asyncReadSpecificDataFetch({
            table: 'product',
            id: purchaseProduct.product_id,
          })
        )
          .unwrap()
          .then((res) => res[0]);
        // 품목 코드, 품목명, 품목규격, 품목수량, 단가, 공급가액, 부가세
        const productCode = product.code;
        const productName = product.name;
        const productStandard = product.standard;
        const productQuantity = purchaseProduct.product_quantity;
        const productPrice = product.incoming_price / 1.1;
        const productSupply = productPrice * productQuantity;
        const productVat = productSupply / 10;
        const result = {
          Line: String(i),
          BulkDatas: {
            IO_DATE: purchase_date.replace(/-/g, ''),
            UPLOAD_SER_NO: '',
            CUST: accountCode,
            CUST_DES: accountName,
            WH_CD: warehouseCode,
            EMP_CD: adminCode,
            PROD_CD: productCode,
            PROD_DES: productName,
            QTY: productQuantity,
            SIZE_DES: productStandard,
            PRICE: productPrice,
            SUPPLY_AMT: productSupply,
            VAT_AMT: productVat,
          },
        };
        purchaseList.push(result);
        const postData = {
          PurchaseList: purchaseList,
        };
        // ecount api 호출
        const url = `https://sboapi${zone}.ecount.com/OAPI/V2/Purchases/SavePurchases?SESSION_ID=${sessionId}`;
        axios({
          method: 'POST',
          url,
          data: postData,
        })
          // 성공
          .then((response) => {
            checkEcountSyncError(response, '구매', values.id);
            alertWhenRetried(zoneTryCount, sessionIdTryCount);
          });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return {
    saveAccount,
    saveProduct,
    saveOrder,
    saveSalesInquiry,
    savePurchase,
  };
};

export default ecount;
