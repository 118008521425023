import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../axios';
import useSupabase from '../../../serverConfig';

import supabase from '../../../supabase';

const initialState = {
  isLoadingReadRangeOrData: false,
  isErrorReadRangeOrData: false,
  isSuccessReadRangeOrData: false,
};

const asyncReadRangeOrDataFetch = createAsyncThunk(
  'readRangeOrDataSlice/asyncReadRangeOrDataFetch',
  async (values, { rejectWithValue }) => {
    // values의 필수 값 => table
    const { table, gteKey, gteValue, lteKey, lteValue, orString } = values;
    try {
      const { data, error } = useSupabase
        ? await supabase
            .from(table)
            .select('*')
            .or(orString)
            .gte(gteKey, gteValue)
            .lte(lteKey, lteValue)
        : await axiosInstance.get(
            `/api/dynamic-crud/${table}/range-or?lteKey=${lteKey}&lteValue=${lteValue}&gteKey=${gteKey}&gteValue=${gteValue}&orString=${orString}`
          );

      console.log(data);

      if (error) throw error;
      const result = data.filter((item) => item.state !== 9);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const readRangeOrDataSlice = createSlice({
  name: 'readRangeOrData',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncReadRangeOrDataFetch.pending, (state) => {
      state.isLoadingReadRangeOrData = true;
      state.isSuccessReadRangeOrData = false;
      state.isErrorReadRangeOrData = false;
    });
    builder.addCase(asyncReadRangeOrDataFetch.fulfilled, (state) => {
      state.isLoadingReadRangeOrData = false;
      state.isSuccessReadRangeOrData = true;
      state.isErrorReadRangeOrData = false;
    });
    builder.addCase(asyncReadRangeOrDataFetch.rejected, (state) => {
      state.isLoadingReadRangeOrData = false;
      state.isSuccessReadRangeOrData = false;
      state.isErrorReadRangeOrData = true;
    });
  },
});

export default readRangeOrDataSlice.reducer;
export { asyncReadRangeOrDataFetch };
