import React from 'react';
import PropTypes from 'prop-types';

const AbsoluteLeftText = ({
  text,
  left = '10px',
  fontSize = '10px',
  color = 'gray',
}) => {
  return (
    <div
      style={{
        position: 'absolute',
        left,
        fontSize,
        color,
      }}
    >
      {text}
    </div>
  );
};

export default AbsoluteLeftText;

AbsoluteLeftText.propTypes = {
  text: PropTypes.string.isRequired,
  left: PropTypes.string,
  fontSize: PropTypes.string,
  color: PropTypes.string,
};
