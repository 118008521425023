const getOrderProductPriceInfo = (orderProduct) => {
  const {
    product_price: price,
    discount_rate: dcRate,
    product_supply_price: supply,
    product_VAT_price: vat,
    discounted_supply: dcSupply,
    discounted_vat: dcVat,
    discounted: dcSum,
  } = orderProduct;

  const isDcData = dcRate > 0;
  return {
    price,
    supply: isDcData ? dcSupply : supply,
    vat: isDcData ? dcVat : vat,
    sum: isDcData ? dcSum : supply + vat,
  };
};

export default getOrderProductPriceInfo;
