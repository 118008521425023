import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FallOutlined, RiseOutlined, TrophyOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { darkModeColors, lightModeColors } from '../../constants';

const checkIsWeekend = (cellDate) => {
  return cellDate.day() === 0 || cellDate.day() === 6;
};
const checkIsOtherMonth = (curMonth, cellDate) => {
  const cellMonth = cellDate.format('YYYY-MM-DD').slice(0, -3);
  return curMonth !== cellMonth;
};
const getCellData = (calendarData, cellDate) => {
  const formattedCellDate = cellDate.format('YYYY-MM-DD');
  const cellData = calendarData.filter(
    (item) => item.date === formattedCellDate
  );
  return cellData[0];
};
const getSalesChangeIcon = (salesChange) => {
  switch (salesChange) {
    case -1:
      return <FallOutlined />;
    case 1:
      return <RiseOutlined />;
    case undefined:
      return <RiseOutlined />;
    default:
      return;
  }
};
const getSalesLevelWord = (salesLevel) => {
  switch (salesLevel) {
    case 1:
      return '위험';
    case 2:
      return '낮음';
    case 3:
      return '보통';
    case 4:
      return '높음';
    case 5:
      return '최고';
    default:
      return;
  }
};

const DataCell = (props) => {
  const { darkMode } = useSelector((state) => state.darkMode);
  const Colors = darkMode ? darkModeColors : lightModeColors;
  const { cellDate, calendarData, monthValue, holiday } = props;
  // 다른 월 cell hide처리
  const isOtherMonth = checkIsOtherMonth(monthValue, cellDate);
  if (isOtherMonth) {
    return <div className="is-hide-cell" />;
  }
  // 주말인지 확인
  const isWeekend = checkIsWeekend(cellDate);

  // cell 내 데이터 받기
  const cellData = getCellData(calendarData, cellDate);
  return (
    <div className={isWeekend ? 'is-weekend-cell' : ''}>
      <div className={holiday ? 'is-holiday-cell' : ''}>
        {holiday && (
          <div style={{ color: Colors.armsone_red, textAlign: 'center' }}>
            {holiday}
          </div>
        )}
        {cellData && (
          <div style={{ textAlign: 'center' }}>
            <Tag
              color={
                (cellData.level === 1 && Colors.armsone_red) ||
                (cellData.level === 2 && Colors.armsone_pink) ||
                (cellData.level === 3 && Colors.armsone_orange) ||
                (cellData.level === 4 && Colors.armsone_green) ||
                (cellData.level === 5 && Colors.armsone_blue)
              }
            >
              {getSalesLevelWord(cellData.level)}
            </Tag>
            <span style={{ color: 'gray' }}>
              {Math.round(cellData.sales / 1000).toLocaleString()}
            </span>
            {/* 
              <span style={{ marginRight: '5px' }}>
                <Button
                  size="small"
                  type="primary"
                  // danger={cellData.salesChange === -1}
                  style={{
                    backgroundColor:
                      cellData.salesChange === -1
                        ? Colors.armsone_red
                        : Colors.armsone_blue,
                  }}
                >
                  {getSalesChangeIcon(cellData.salesChange)}
                </Button>
              </span>
              */}
          </div>
        )}
      </div>
    </div>
  );
};

export default DataCell;

DataCell.propTypes = {
  cellDate: PropTypes.instanceOf(Object).isRequired,
  calendarData: PropTypes.instanceOf(Array).isRequired,
  monthValue: PropTypes.string.isRequired,

  holiday: PropTypes.string,
};
