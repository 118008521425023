import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const SelectedOptionCard = ({ id, title, name, removeOption }) => {
  return (
    <Tag
      color="blue"
      style={{
        margin: '0px',
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center',
        padding: '5px',
        paddingLeft: '10px',
      }}
    >
      <div>{title}</div>
      <Button
        style={{
          height: '22px',
          width: '22px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '5px',
        }}
        type="primary"
        onClick={() => removeOption({ optionKey: name, optionDataId: id })}
        size="small"
      >
        <CloseOutlined style={{ fontSize: '12px' }} />
      </Button>
    </Tag>
  );
};

export default SelectedOptionCard;

SelectedOptionCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  removeOption: PropTypes.func.isRequired,
};
