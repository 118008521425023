import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import {
  ArrowRightOutlined,
  CameraFilled,
  InfoCircleOutlined,
  MailOutlined,
  MessageFilled,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm, Popover } from 'antd';

const BezelContainer = styled.div`
  width: 300px;
  height: 590px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 30px;
  background: linear-gradient(90deg, #737373, #404040, black);
  position: relative;
  box-shadow: 5px 5px 5px lightgray;
`;
const BezelHeader = styled.div`
  position: absolute;
  width: 50px;
  height: 10px;
  border-radius: 20px;
  background-color: gray;
  top: 25px;
`;
const BezelBottom = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(360deg, #d7d7d7, black);
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BackgroundContainer = styled.div`
  position: relative;
  width: 260px;
  height: 470px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const MessengerHeader = styled.div`
  position: absolute;
  top: 0;
  background-color: #f7f7f7;
  width: 100%;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;
const MessengerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 15px;
`;
const MessageContainer = styled.div`
  padding: 10px;
  background-color: #f7f7f7;
  width: 210px;
  font-size: 12px;
  border-radius: 20px;
  overflow: auto;
  height: 300px;
  position: relative;
`;
const FakeInput = styled.div`
  border: 1px solid gray;
  width: 85%;
  padding: 3px 3px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const floatAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-2.5px)
  }
  50% {
    transform: translateX(2.5px)
  }
  100% {
    transform: translateX(0)
  }
`;
const ButtonStyle = styled(Button)`
  position: relative;
  border-radius: 50%;
  animation: ${floatAnimation} 3s infinite ease-in-out;
`;

const SmsDisplay = (props) => {
  const {
    smsType,
    pageType,
    confirmInput,
    handleSms,
    reqSmsDone,
    conSmsDone,
    reqSmsInput,
    conSmsInput,
    smsData,
    reqSmsResult,
    conSmsResult,
  } = props;

  return (
    <div>
      {smsType === 'request' && (
        <BezelContainer>
          <BezelHeader />
          <BackgroundContainer>
            <MessengerHeader>
              <div
                style={{
                  border: '1px solid black',
                  borderRadius: '50%',
                  backgroundColor: '#e5e5e5',
                  padding: '2px',
                  width: '20px',
                  height: '20px',
                  textAlign: 'center',
                }}
              >
                <UserOutlined />
              </div>
              <div style={{ marginTop: '5px' }}>+82 10-9277-3322</div>
            </MessengerHeader>
            <MessageContainer>
              <Button
                style={{
                  position: 'absolute',
                  padding: '0',
                  top: '5px',
                  right: '5px',
                }}
                type="link"
                size="small"
              >
                <Popover
                  content={
                    <div style={{ color: 'gray' }}>
                      코드가 DC, COD, parcel로 시작되는 품목의 경우
                      <br />
                      주문상품 텍스트에 노출되지 않습니다.
                    </div>
                  }
                  placement="left"
                >
                  <InfoCircleOutlined />
                </Popover>
              </Button>
              <pre style={{ whiteSpace: 'pre-wrap' }}>{reqSmsResult}</pre>
            </MessageContainer>
            <MessengerFooter>
              <div style={{ fontSize: '22px', color: 'gray' }}>
                <CameraFilled style={{ marginRight: '5px' }} />
              </div>
              <FakeInput>
                <div style={{ marginLeft: '5px' }}>
                  결제요청 SMS 전송 <ArrowRightOutlined />
                </div>
                <div>
                  <Popconfirm
                    title="해당 번호로 결제요청 SMS를 전송하시겠습니까?"
                    onConfirm={() => handleSms('messenger')}
                    okText="네"
                    cancelText="아니요"
                  >
                    <ButtonStyle
                      disabled={pageType === 'detail' || reqSmsDone}
                      type="primary"
                    >
                      <MailOutlined
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          fontSize: '20px',
                          fontWeight: 'bold',
                        }}
                      />
                    </ButtonStyle>
                  </Popconfirm>
                </div>
              </FakeInput>
            </MessengerFooter>
          </BackgroundContainer>
          <BezelBottom>
            <div
              style={{
                width: '20px',
                height: '20px',
                border: '1px solid white',
                borderRadius: '5px',
              }}
            />
          </BezelBottom>
        </BezelContainer>
      )}
      {smsType === 'confirm' && (
        <BezelContainer>
          <BezelHeader />
          <BackgroundContainer>
            <MessengerHeader>
              <div
                style={{
                  border: '1px solid black',
                  borderRadius: '50%',
                  backgroundColor: '#e5e5e5',
                  padding: '2px',
                  width: '20px',
                  height: '20px',
                  textAlign: 'center',
                }}
              >
                <UserOutlined />
              </div>
              <div style={{ marginTop: '5px' }}>+82 10-9277-3322</div>
            </MessengerHeader>
            <MessageContainer>
              <pre style={{ whiteSpace: 'pre-wrap' }}>{conSmsResult}</pre>
            </MessageContainer>
            <MessengerFooter>
              <div style={{ fontSize: '22px', color: 'gray' }}>
                <CameraFilled style={{ marginRight: '5px' }} />
              </div>
              <FakeInput>
                <div style={{ marginLeft: '5px' }}>
                  입금확인 SMS 전송 <ArrowRightOutlined />
                </div>
                <Popconfirm
                  title="해당 번호로 입금확인 SMS를 전송하시겠습니까?"
                  onConfirm={() => handleSms('confirm')}
                  okText="네"
                  cancelText="아니요"
                >
                  <ButtonStyle
                    disabled={pageType === 'detail' || conSmsDone}
                    type="primary"
                  >
                    <MailOutlined
                      style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    />
                  </ButtonStyle>
                </Popconfirm>
              </FakeInput>
            </MessengerFooter>
          </BackgroundContainer>
          <BezelBottom>
            <div
              style={{
                width: '20px',
                height: '20px',
                border: '1px solid white',
                borderRadius: '5px',
              }}
            />
          </BezelBottom>
        </BezelContainer>
      )}
    </div>
  );
};

export default SmsDisplay;

SmsDisplay.propTypes = {
  smsType: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
  confirmInput: PropTypes.string,
  handleSms: PropTypes.func.isRequired,
  reqSmsDone: PropTypes.bool.isRequired,
  conSmsDone: PropTypes.bool.isRequired,
  reqSmsInput: PropTypes.string.isRequired,
  conSmsInput: PropTypes.string.isRequired,
  smsData: PropTypes.instanceOf(Object).isRequired,
  reqSmsResult: PropTypes.string.isRequired,
  conSmsResult: PropTypes.string.isRequired,
};
