import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../axios';
import useSupabase from '../../../serverConfig';

import supabase from '../../../supabase';

const initialState = {
  isLoadingUpdateData: false,
  isErrorUpdateData: false,
  isSuccessUpdateData: false,
};

const asyncUpdateDataFetch = createAsyncThunk(
  'updateDataSlice/asyncUpdateDataFetch',
  async (values, { rejectWithValue }) => {
    // 벌크 업데이트
    if (values.isBulk) {
      const { table, bulkDatas } = values;
      try {
        const { data, error } = useSupabase
          ? await supabase.from(table).upsert(bulkDatas).select()
          : await axiosInstance.patch(`/api/dynamic-crud/${table}/bulk`, {
              bulkDatas,
            });

        console.log(data);

        if (error) throw error;
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
    // 판매상품 (sale) 테이블의 경우 code로 update를 함.
    if (values.table === 'sale') {
      const { code, table, ...rest } = values;
      try {
        const { data, error } = useSupabase
          ? await supabase.from(table).update(rest).eq('code', code)
          : await axiosInstance.patch(
              `/api/dynamic-crud/${table}?key=code&value=${code}`,
              rest
            );

        console.log(data);

        if (error) throw error;
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
    // 판매-출고상품 (sale_product) 테이블의 product_code를 바꾸는 경우.
    if (values.table === 'sale_product' && values.isChangedPdCode) {
      const { productCode, table, ...rest } = values;
      try {
        const { data, error } = useSupabase
          ? await supabase
              .from(table)
              .update(rest)
              .eq('product_code', productCode)
          : await axiosInstance.patch(
              `/api/dynamic-crud/${table}?key=prodeuct_code&value=${productCode}`,
              rest
            );

        console.log(data);

        if (error) throw error;
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
    // 판매-출고상품 (sale_product) 테이블의 경우 sale_code로 update를 함.
    if (values.table === 'sale_product') {
      const { saleCode, table, ...rest } = values;
      try {
        const { data, error } = useSupabase
          ? await supabase.from(table).update(rest).eq('sale_code', saleCode)
          : await axiosInstance.patch(
              `/api/dynamic-crud/${table}?key=sale_code&value=${saleCode}`,
              rest
            );

        console.log(data);

        if (error) throw error;
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
    // 신규상담 게시판 (counselling) 테이블의 경우 reg_date로 update를 함.
    if (values.table === 'counselling') {
      const { regDate, table, ...rest } = values;
      console.log(values);
      try {
        const { data, error } = useSupabase
          ? await supabase.from(table).update(rest).eq('reg_date', regDate)
          : await axiosInstance.patch(
              `/api/dynamic-crud/${table}?key=reg_date&value=${regDate}`,
              rest
            );

        console.log(data);

        if (error) throw error;
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
    // 테이블이 hidden_data일 때 (is_hidden === true인 데이터 숨김처리 여부)
    if (values.table === 'hidden_data') {
      const { table, table_name, ...rest } = values;
      try {
        const { data, error } = useSupabase
          ? await supabase.from(table).update(rest).eq('table_name', table_name)
          : await axiosInstance.patch(
              `/api/dynamic-crud/${table}?key=table_name&value=${table_name}`,
              rest
            );

        console.log(data);

        if (error) throw error;
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
    // values의 필수 값 => id, table
    const { id, table, ...rest } = values;
    try {
      const { data, error } = useSupabase
        ? await supabase.from(table).update(rest).eq('id', id)
        : await axiosInstance.patch(
            `/api/dynamic-crud/${table}?key=id&value=${id}`,
            rest
          );

      console.log(data);

      if (error) throw error;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updateDataSlice = createSlice({
  name: 'updateData',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncUpdateDataFetch.pending, (state) => {
      state.isLoadinUpdateeData = true;
      state.isSuccessUpdateData = false;
      state.isErrorUpdateData = false;
    });
    builder.addCase(asyncUpdateDataFetch.fulfilled, (state) => {
      state.isLoadingUpdateData = false;
      state.isSuccessUpdateData = true;
      state.isErrorUpdateData = false;
    });
    builder.addCase(asyncUpdateDataFetch.rejected, (state) => {
      state.isLoadingUpdateData = false;
      state.isSuccessUpdateData = false;
      state.isErrorUpdateData = true;
    });
  },
});

export default updateDataSlice.reducer;
export { asyncUpdateDataFetch };
