import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Datas from './Datas';

const Items = (props) => {
  const { pageType } = props;
  // 주문서 (order) Items
  const orderItems = useMemo(
    () => [
      {
        key: 1,
        type: 'selectSearch',
        name: 'admin_code',
        label: '담당자',
        placeholder: '담당자를 선택해주세요.',
        span: 2,
      },
      {
        key: 2,
        type: 'selectSearch',
        name: 'warehouse_code',
        label: '출하창고',
        placeholder: '출하창고를 선택해주세요.',
        span: 2,
      },
      {
        key: 3,
        type: 'selectSearch',
        name: 'account_code',
        label: '거래처',
        placeholder: '거래처를 선택해주세요.',
        span: 3,
      },
      {
        key: 4,
        type: 'show',
        name: 'account_info',
        label: '기본정보',
        span: 3,
      },
      {
        key: 5,
        type: 'input',
        name: 'account_name',
        label: '거래처명',
        placeholder: '거래처명을 입력해주세요.',
        span: 2,
      },
      {
        key: 6,
        type: 'input',
        name: 'account_phone',
        label: '휴대폰',
        placeholder: '전화번호를 입력해주세요.',
        span: 2,
      },
      {
        key: 7,
        type: 'radio',
        name: 'account_address',
        label: '거래처주소',
        span: 3,
      },
      {
        key: 8,
        type: 'select',
        name: 'deal_type',
        label: '거래유형',
        placeholder: '거래유형을 선택해주세요.',
        span: 2,
      },
      {
        key: 9,
        type: 'select',
        name: 'payment_terms',
        label: '결제조건',
        placeholder: '결제조건을 선택해주세요.',
        span: 2,
      },
      {
        key: 10,
        type: 'date',
        name: 'order_date',
        label: '주문일자',
        placeholder: '날짜를 선택해주세요.',
        span: 2,
      },
      {
        key: 11,
        type: 'date',
        name: 'delivery_date',
        label: '출고일자',
        placeholder: '날짜를 선택해주세요.',
        span: 2,
      },
      {
        key: 12,
        type: 'input',
        name: 'memo',
        label: '메모',
        placeholder: '메모하실 내용을 입력해주세요.',
        span: 3,
      },
    ],
    []
  );

  // 주문서 (order-product) Items
  const orderSaleItems = useMemo(
    () => [
      {
        key: 1,
        type: 'selectSearch',
        name: 'sale',
        label: '판매상품',
        placeholder: '판매상품을 선택해주세요.',
        span: 1,
      },
    ],
    []
  );

  return (
    <Datas
      pageType={pageType}
      orderItems={orderItems}
      orderSaleItems={orderSaleItems}
    />
  );
};

export default React.memo(Items);

Items.propTypes = {
  pageType: PropTypes.string.isRequired,
};
