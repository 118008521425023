import React, { useState } from 'react';
import { Input, Button } from 'antd';
import PropTypes from 'prop-types';
import AuthLinks from './AuthLinks';

const PasswordResetForm = (props) => {
  const { handleResetPassword } = props;

  const [values, setValues] = useState({
    email: null,
    newPassword: null,
    newPasswordCheck: null,
  });

  const handleChangeValue = (name, value) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickSubmit = (e) => {
    e.preventDefault();

    handleResetPassword(values);
  };

  return (
    <form onSubmit={handleClickSubmit}>
      <Input
        size="large"
        placeholder="email"
        onChange={(e) => handleChangeValue('email', e.target.value)}
        value={values.email}
        type="email"
      />
      <Input.Password
        size="large"
        placeholder="new password"
        style={{ marginTop: '30px' }}
        onChange={(e) => handleChangeValue('newPassword', e.target.value)}
        value={values.newPassword}
        minLength={4}
        maxLength={12}
      />
      <Input.Password
        size="large"
        placeholder="new password-check"
        style={{ marginTop: '10px' }}
        onChange={(e) => handleChangeValue('newPasswordCheck', e.target.value)}
        value={values.newPasswordCheck}
        minLength={4}
        maxLength={12}
      />
      <Button
        htmlType="submit"
        size="large"
        style={{ marginTop: '30px', width: '100%' }}
        type="primary"
        disabled={
          !values.email || !values.newPassword || !values.newPasswordCheck
        }
      >
        재생성
      </Button>
      <AuthLinks />
    </form>
  );
};

export default PasswordResetForm;

PasswordResetForm.propTypes = {
  handleResetPassword: PropTypes.func.isRequired,
};
