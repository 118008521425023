import { EditOutlined } from '@ant-design/icons';
import { Button, Input, Select, Table } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {
  COUNSELLING_SEARCH_CONTENT,
  COUNSELLING_SEARCH_DATE,
  COUNSELLING_SEARCH_ACCOUNT,
  COUNSELLING_SEARCH_CONTACT,
} from '../../constants';
import CounsellingTableDisplay from './CounsellingTableDisplay';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';

const CounsellingTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [counsellingTableDatas, setCounsellingTableDatas] = useState([]);
  const [searchFormData, setSearchFormData] = useState({
    option: COUNSELLING_SEARCH_ACCOUNT,
    input: null,
  });
  const [fetchLoading, setFetchLoading] = useState(false);

  const getCounsellingTableDatas = useCallback(async () => {
    try {
      await setFetchLoading(true);
      const datas = await dispatch(
        asyncReadDataFetch({ table: 'counselling' })
      ).unwrap();
      await setFetchLoading(false);
      return datas;
    } catch (error) {
      throw new Error(error);
    }
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const datas = await getCounsellingTableDatas();
        setCounsellingTableDatas(datas);
      } catch (error) {
        throw new Error(error);
      }
    };
    fetchData();
  }, [getCounsellingTableDatas]);

  const filterBySearchInput = useCallback(
    async (key, value) => {
      try {
        const datas = await getCounsellingTableDatas();
        const filteredDatas = datas.filter(
          (item) => item[key] && item[key].includes(value)
        );
        setCounsellingTableDatas(filteredDatas);
      } catch (error) {
        throw new Error(error);
      }
    },
    [getCounsellingTableDatas, setCounsellingTableDatas]
  );

  const handleSearch = useCallback(() => {
    if (!searchFormData.input) {
      alert('검색어를 입력해주세요.');
      return;
    }

    if (searchFormData.option === COUNSELLING_SEARCH_CONTENT) {
      filterBySearchInput('content', searchFormData.input);
    } else if (searchFormData.option === COUNSELLING_SEARCH_DATE) {
      filterBySearchInput('counselling_date', searchFormData.input);
    } else if (searchFormData.option === COUNSELLING_SEARCH_ACCOUNT) {
      filterBySearchInput('account_name', searchFormData.input);
    } else if (searchFormData.option === COUNSELLING_SEARCH_CONTACT) {
      filterBySearchInput('contact', searchFormData.input);
    } else {
      alert('검색할 수 없습니다.');
    }
  }, [searchFormData, filterBySearchInput]);

  return (
    <div style={{ maxWidth: '1180px', margin: '0 auto' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px 0px 16px 0px',
        }}
      >
        <div style={{ fontSize: '22px', fontWeight: '600' }}>
          신규상담/게시판
        </div>
        <Button
          style={{ borderRadius: '0px' }}
          onClick={() => history.push('/counselling/create')}
        >
          <EditOutlined /> 글쓰기
        </Button>
      </div>
      <CounsellingTableDisplay
        counsellingTableDatas={counsellingTableDatas}
        fetchLoading={fetchLoading}
      />
      <SearchConstainer>
        <div className="counselling-search" style={{ marginRight: '5px' }}>
          <Select
            options={[
              {
                label: COUNSELLING_SEARCH_ACCOUNT,
                value: COUNSELLING_SEARCH_ACCOUNT,
              },
              {
                label: COUNSELLING_SEARCH_CONTENT,
                value: COUNSELLING_SEARCH_CONTENT,
              },
              {
                label: COUNSELLING_SEARCH_DATE,
                value: COUNSELLING_SEARCH_DATE,
              },
              {
                label: COUNSELLING_SEARCH_CONTACT,
                value: COUNSELLING_SEARCH_CONTACT,
              },
            ]}
            value={searchFormData.option}
            onSelect={(value) =>
              setSearchFormData((prev) => ({ ...prev, option: value }))
            }
          />
        </div>
        <InputStyle
          placeholder="검색어를 입력해주세요."
          value={searchFormData.input}
          onChange={(e) =>
            setSearchFormData((prev) => ({ ...prev, input: e.target.value }))
          }
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
        <ButtonStyle type="primary" onClick={handleSearch}>
          검색
        </ButtonStyle>
      </SearchConstainer>
    </div>
  );
};

export default CounsellingTable;

const SearchConstainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  height: 70px;
  margin-top: 32px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
`;
const InputStyle = styled(Input)`
  border-radius: 0px;
  font-size: 15px;
  padding: 7px;
  width: 200px;
  height: 40px;
`;
const ButtonStyle = styled(Button)`
  border-radius: 0px;
  font-size: 15px;
  padding: 7px 15px;
  height: 40px;
  border-left: white;
`;
