import React from 'react';

const MapDistribution = () => {
  return (
    <iframe
      src="https://www.google.com/maps/d/embed?mid=1xGMLSzOY2im1T7hGAT0VSP6aPpM&ehbc=2E312F"
      title="지도분포도"
      width="100%"
      height="100%"
      frameBorder="0"
    />
  );
};

export default MapDistribution;
