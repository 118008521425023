import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Form, Checkbox, Input } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

const FormContainer = styled.div`
  width: 100%;
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ButtonStyle = styled(Button)`
  width: 100%;
  padding: 8px;
  height: 40px;
  font-weight: 700;
  font-size: 14.5px;
  background: #001528;
  color: white;
`;

const LineStyle = styled.div`
  height: 1px;
  background-color: lightgray;
`;

const LoginForm = (props) => {
  const { handleSubmit } = props;
  const history = useHistory();
  const { isLoadingSigninUser } = useSelector((state) => state.signinUser);
  const emailMessage = '이메일형식에 맞게 입력해주세요.';
  const passwordMessage = '비밀번호를 입력해주세요.';

  const [email, setEmail] = useState(() => {
    const saved = localStorage.getItem('email');
    const initialValue = JSON.parse(saved);
    return initialValue || '';
  });
  const [password, setPassword] = useState(() => {
    const saved = localStorage.getItem('password');
    const initialValue = JSON.parse(saved);
    return initialValue || '';
  });
  const [check, setCheck] = useState(() => {
    const saved = localStorage.getItem('check');
    const initialValue = JSON.parse(saved);
    return initialValue || true;
  });

  useEffect(() => {
    localStorage.setItem('email', JSON.stringify(email));
    localStorage.setItem('check', JSON.stringify(check));
  }, [email, password, check]);

  const onFinish = (values) => {
    handleSubmit(values);
    if (!values.check) {
      setEmail('');
      setPassword('');
      setCheck(true);
    }
  };

  const onFinishFailed = (values) => {
    const err = values.errorFields[0].errors[0];
    alert(err);
  };

  return (
    <FormContainer>
      <Form
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="on"
        initialValues={{ email, password, check }}
      >
        <div>이메일</div>
        <Form.Item
          noStyle
          name="email"
          rules={[
            { required: true, message: emailMessage },
            { type: 'email', message: emailMessage },
          ]}
        >
          <Input
            bordered={false}
            style={{
              borderBottom: '1px solid lightGray',
              borderRadius: '0px',
            }}
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            size="large"
            placeholder="예) example@gmail.com"
          />
        </Form.Item>
        <div style={{ marginTop: '16px' }}>비밀번호</div>
        <Form.Item
          noStyle
          name="password"
          rules={[{ required: true, message: passwordMessage }]}
        >
          <Input.Password
            id="password"
            bordered={false}
            style={{
              borderBottom: '1px solid lightGray',
              borderRadius: '0px',
            }}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            size="large"
          />
        </Form.Item>
        <div style={{ marginTop: '16px' }} />
        <Form.Item name="check" valuePropName="checked" noStyle>
          <Checkbox
            id="check"
            onChange={(e) => setCheck(e.target.checked)}
            value={check}
          >
            이메일 저장
          </Checkbox>
        </Form.Item>

        <div style={{ textAlign: 'right', margin: '24px 0' }}>
          {/* 
          <Link
            to
            onClick={() => history.push('/link-password')}
            type="primary"
          >
            비밀번호 재설정
          </Link>
          */}
        </div>
        <Form.Item>
          {isLoadingSigninUser ? (
            <ButtonStyle htmlType="submit" loading>
              로그인
            </ButtonStyle>
          ) : (
            <ButtonStyle htmlType="submit">로그인</ButtonStyle>
          )}
        </Form.Item>
      </Form>
      <LineStyle />
    </FormContainer>
  );
};

export default LoginForm;

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
