import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';

const { RangePicker } = DatePicker;

const DateRangePicker = (props) => {
  const { dateRange, setDateRange } = props;

  useEffect(() => {
    const dateRangeType = window.localStorage.getItem('dateRangeType');
    const isExistDateRangeTypeInLocalStorage = !!dateRangeType;

    if (isExistDateRangeTypeInLocalStorage) {
      setDateRange((prev) => ({ ...prev, type: dateRangeType }));
    }
  }, []);

  const calcDateRangeType = (startDate, endDate) => {
    const today = dayjs();
    const yesterday = today.subtract(1, 'day');
    const oneWeekAgo = today.subtract(1, 'week');
    const oneMonthAgo = today.subtract(1, 'month');
    const oneYearAgo = today.subtract(1, 'year');

    if (startDate.isSame(today, 'day') && endDate.isSame(today, 'day')) {
      return 'today';
    } else if (
      startDate.isSame(yesterday, 'day') &&
      endDate.isSame(yesterday, 'day')
    ) {
      return 'yesterday';
    } else if (
      startDate.isSame(oneWeekAgo, 'day') &&
      endDate.isSame(today, 'day')
    ) {
      return 'week';
    } else if (
      startDate.isSame(oneMonthAgo, 'day') &&
      endDate.isSame(today, 'day')
    ) {
      return 'month';
    } else if (
      startDate.isSame(oneYearAgo, 'day') &&
      endDate.isSame(today, 'day')
    ) {
      return 'year';
    }
    return null;
  };

  const handleLocalStorage = (startDate, endDate, dateRangeType) => {
    window.localStorage.setItem('startDate', startDate.format('YYYY-MM-DD'));
    window.localStorage.setItem('endDate', endDate.format('YYYY-MM-DD'));
    if (!dateRangeType) {
      window.localStorage.removeItem('dateRangeType');
    } else {
      window.localStorage.setItem('dateRangeType', dateRangeType);
    }
  };

  const handleChangePicker = (dateArray) => {
    const startDate = dayjs(dateArray[0]);
    const endDate = dayjs(dateArray[1]);
    const dayDiff = endDate.diff(startDate, 'day');
    const isExceedOneYear = dayDiff > 365;
    if (isExceedOneYear) {
      alert('날짜의 범위는 1년을 초과할 수 없습니다.');
      return;
    }
    const dateRangeType = calcDateRangeType(startDate, endDate);
    setDateRange({ start: startDate, end: endDate, type: dateRangeType });
    handleLocalStorage(startDate, endDate, dateRangeType);
  };

  const handleClickToday = () => {
    const today = dayjs();
    setDateRange({ start: today, end: today, type: 'today' });
    handleLocalStorage(today, today, 'today');
  };

  const handleClickYesterday = () => {
    const yesterday = dayjs().subtract(1, 'day');
    setDateRange({ start: yesterday, end: yesterday, type: 'yesterday' });
    handleLocalStorage(yesterday, yesterday, 'yesterday');
  };

  const handleClickOneWeek = () => {
    const dayOfOneWeekAgo = dayjs().subtract(1, 'week');
    const today = dayjs();
    setDateRange({ start: dayOfOneWeekAgo, end: today, type: 'week' });
    handleLocalStorage(dayOfOneWeekAgo, today, 'week');
  };

  const handleClickOneMonth = () => {
    const dayOfOneMonthAgo = dayjs().subtract(1, 'month');
    const today = dayjs();
    setDateRange({ start: dayOfOneMonthAgo, end: today, type: 'month' });
    handleLocalStorage(dayOfOneMonthAgo, today, 'month');
  };

  const handleClickOneYear = () => {
    const dayOfOneYearAgo = dayjs().subtract(1, 'year');
    const today = dayjs();
    setDateRange({ start: dayOfOneYearAgo, end: today, type: 'year' });
    handleLocalStorage(dayOfOneYearAgo, today, 'year');
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <RangePicker
        onChange={handleChangePicker}
        value={[dateRange.start, dateRange.end]}
        size="small"
        style={{ borderRadius: '5px' }}
      />
      <Line />
      <Space.Compact size="small">
        <Button
          onClick={handleClickToday}
          type={dateRange.type === 'today' ? 'primary' : 'default'}
        >
          금일
        </Button>
        <Button
          onClick={handleClickYesterday}
          type={dateRange.type === 'yesterday' ? 'primary' : 'default'}
        >
          전일
        </Button>
        <Button
          onClick={handleClickOneWeek}
          type={dateRange.type === 'week' ? 'primary' : 'default'}
        >
          1주
        </Button>
        <Button
          onClick={handleClickOneMonth}
          type={dateRange.type === 'month' ? 'primary' : 'default'}
        >
          1개월
        </Button>
        <Button
          onClick={handleClickOneYear}
          type={dateRange.type === 'year' ? 'primary' : 'default'}
        >
          1년
        </Button>
      </Space.Compact>
    </div>
  );
};

export default DateRangePicker;

DateRangePicker.propTypes = {
  dateRange: PropTypes.instanceOf(Object).isRequired,
  setDateRange: PropTypes.func.isRequired,
};

const Line = styled.div`
  width: 1px;
  margin: 0 5px;
  height: 15px;
  background-color: lightgray;
`;
