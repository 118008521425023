import { EnvironmentOutlined } from '@ant-design/icons';
import React, { useMemo, useState } from 'react';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import AddressSearch from '../components/AddressSearch';
import SearchResult from '../components/SearchResult';

const Delivery = () => {
  const [dlvyData, setDlvyData] = useState({ distance: 0, dlvyPrice: 0 });
  const [geocode, setGeocode] = useState({
    departure: { x: 127.0822877, y: 37.1086106 },
    destination: { x: 127.0822877, y: 37.1086106 },
  });
  const [loading, setLoading] = useState(false);

  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <EnvironmentOutlined />,
        breadCrumbNav: ' 용달',
      },
    ],
    []
  );

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px' }}>
        <div
          style={{
            fontSize: '16px',
            width: '100%',
            paddingBottom: '10px',
            borderBottom: '1px solid gray',
          }}
        >
          주소 검색
        </div>
        <AddressSearch
          setDlvyData={setDlvyData}
          setGeocode={setGeocode}
          setLoading={setLoading}
        />
        <div style={{ marginBottom: '30px' }} />
        <SearchResult dlvyData={dlvyData} geocode={geocode} loading={loading} />
      </div>
    </LayoutComponent>
  );
};

export default Delivery;
