import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loading from '../../shared/components/Loading';

// 로그인 되지 않았을 때 접근 가능한 컴포넌트
const PublicRoute = (props) => {
  const { component, path, exact } = props;
  const { isLoggedIn, isLoadingSigninUser } = useSelector(
    (state) => state.signinUser
  );

  if (isLoadingSigninUser) {
    return <Loading />;
  }

  if (isLoggedIn) {
    return <Redirect to="/data-calendar" />;
  }

  return <Route component={component} path={path} exact={exact} />;
};

export default PublicRoute;

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
};
