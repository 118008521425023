import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { asyncReadDataFetch } from '../redux/modules/shared/readData';

const useInventoryReceiptsSelectOptions = (initialOptions) => {
  const dispatch = useDispatch();

  const [options, setOptions] = useState(initialOptions);
  const [selectedOptions, setSelectedOptions] = useState(initialOptions);

  const fetchDatas = async (table) => {
    const fetchedDatas = await dispatch(asyncReadDataFetch({ table })).unwrap();
    return fetchedDatas;
  };

  const mapDatas = async (table) => {
    const options = await fetchDatas(table);
    return options.map((data) => ({
      id: data.id,
      code: data.code,
      name: data.name,
    }));
  };

  // 취급품목 id 가져오기
  const getProductSetupIds = async () => {
    const productSetups = await fetchDatas('inventory_product_setup');
    return productSetups.map((data) => data.product_id);
  };

  // 취급 품목 id에 맞는 데이터 거르기 (품목만)
  const filterOptions = ({ productIds, options }) => {
    return options.filter((option) => productIds.includes(option.id));
  };

  const getOptions = async (initialOptions) => {
    const optionKeys = Object.keys(initialOptions);

    let options = {};
    for (const optionKey of optionKeys) {
      if (optionKey === 'product') {
        const optionDatas = filterOptions({
          productIds: await getProductSetupIds(),
          options: await mapDatas(optionKey),
        });
        options = {
          ...options,
          [optionKey]: optionDatas,
        };
      } else {
        const optionDatas = await mapDatas(optionKey);
        options = {
          ...options,
          [optionKey]: optionDatas,
        };
      }
    }

    setOptions(options);
  };

  const selectOption = ({ optionKey, optionDataId }) => {
    const isExistOptionData = selectedOptions[optionKey].some(
      (option) => option.id === optionDataId
    );
    if (isExistOptionData) {
      return;
    }
    const optionData = options[optionKey].filter(
      (option) => option.id === optionDataId
    )[0];
    setSelectedOptions((prev) => ({
      ...prev,
      [optionKey]: [...prev[optionKey], optionData],
    }));
  };

  const removeOption = ({ optionKey, optionDataId }) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [optionKey]: [
        ...prev[optionKey].filter((option) => option.id !== optionDataId),
      ],
    }));
  };

  useEffect(() => {
    getOptions(initialOptions);
  }, []);

  return [options, selectedOptions, selectOption, removeOption];
};

export default useInventoryReceiptsSelectOptions;
