import React, { useMemo } from 'react';
import { GiftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { Tag } from 'antd';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Table from '../../shared/table/Table';

const Sale = () => {
  // breadCrumb 데이터 생성
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <GiftOutlined />,
        breadCrumbNav: ' 판매상품',
      },
    ],
    []
  );

  // 불러올 table이 뭔지
  const table = 'sale';

  // TableHeader에 들어갈 create 버튼 텍스트
  const createButtonText = '판매상품 추가';

  // table header에 들어갈 create 버튼의 Modal객체 (Modal 속성으로 넣어줄 객체)
  const createButtonModal = {
    type: 'sale',
    modalInfo: {
      modalTitle: '판매상품 추가',
      resultStatus: '',
      resultTitle: '',
      resultSubTitle: '',
      buttonText: '',
    },
    dataInfo: {
      dataId: '',
      dataTable: 'sale',
      datas: {},
    },
  };

  // table에 넣을 columns. table View를 위한 배열.
  const tableColumns = [
    {
      title: '판매상품코드',
      dataIndex: 'code',
      key: 'code',
      width: '25%',
      align: 'center',
    },
    {
      title: '판매상품명',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <Link to={`/sale/${record.code}`}>{record.name}</Link>
      ),
      width: '25%',
      align: 'center',
    },
    {
      title: '계산기정렬순서',
      dataIndex: 'sort_order',
      key: 'sort_order',
      render: (v) => {
        if (!v) {
          return <Tag color="red">X</Tag>;
        } else {
          return <Tag color="blue">{v}</Tag>;
        }
      },
      width: '25%',
      align: 'center',
    },
    {
      title: '등록일자',
      dataIndex: 'reg_date',
      key: 'reg_date',
      render: (value) => <div>{value.slice(0, 10)}</div>,
      width: '25%',
      align: 'center',
    },
  ];
  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <Table
        tableColumns={tableColumns}
        table={table}
        createButtonText={createButtonText}
        createButtonModal={createButtonModal}
        items={tableColumns}
      />
    </LayoutComponent>
  );
};

export default Sale;
