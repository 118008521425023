import { FileExcelOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Modal, Popover } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import downloadExcelFile from '../../utils/downloadExcelFile';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import MailModal from './MailModal';

const EstimateButtons = (props) => {
  const { basicInfoData, productInfoDatas } = props;
  const dispatch = useDispatch();
  const { handleEstimateForm } = downloadExcelFile();
  const [isOpenMailModal, setIsOpenMailModal] = useState(false);

  const handleExcelButton = async () => {
    try {
      // 엑셀form 상단 기본정보 데이터
      const headerData = {};
      headerData.date = basicInfoData.order_date;
      const [account, tel, fax] = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'account',
          eqKey: 'code',
          eqValue: basicInfoData.account_code,
        })
      )
        .unwrap()
        .then((res) => {
          return [res[0].name, res[0].phone, res[0].fax];
        });
      headerData.account = account;
      headerData.telFax = `${tel}/${fax}`;
      headerData.reference = '';
      headerData.paymentTerms = basicInfoData.payment_terms;
      const expirationDate = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'estimate_sent',
          eqKey: 'estimate_id',
          eqValue: basicInfoData.id,
        })
      )
        .unwrap()
        .then((res) => res[0].expiration_date);
      headerData.expirationDate = `${expirationDate}일`;
      const admin = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'profiles',
          eqKey: 'code',
          eqValue: basicInfoData.admin_code,
        })
      )
        .unwrap()
        .then((res) => res[0].username);
      headerData.admin = admin;

      // 엑셀form 중간 상품정보 데이터
      const contentData = productInfoDatas.map((data) => ({
        code: data.product_code,
        name: data.product_name,
        quantity: data.product_quantity,
        price: data.discounted_price,
        vat: data.discounted_vat,
        supply: data.discounted_supply,
      }));

      // 엑셀form 하단 합계 데이터
      const footerData = productInfoDatas.map((data) => ({
        quantity: data.product_quantity,
        supply: data.discounted_supply,
        vat: data.discounted_vat,
        total: data.discounted,
      }));

      console.log(headerData, contentData, footerData);

      handleEstimateForm(headerData, contentData, footerData);
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <Button
        size="small"
        style={{
          borderRadius: '20px',
          marginRight: '2px',
        }}
        onClick={handleExcelButton}
      >
        <FileExcelOutlined />
        Excel
      </Button>
      <Button
        size="small"
        style={{ borderRadius: '20px' }}
        onClick={() => setIsOpenMailModal(true)}
      >
        <MailOutlined />
        Mail
      </Button>
      {isOpenMailModal && (
        <MailModal
          setIsOpenMailModal={setIsOpenMailModal}
          accountCode={basicInfoData.account_code}
        />
      )}
    </div>
  );
};

export default EstimateButtons;

EstimateButtons.propTypes = {
  basicInfoData: PropTypes.instanceOf(Object).isRequired,
  productInfoDatas: PropTypes.instanceOf(Array).isRequired,
};
