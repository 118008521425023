import React, { useMemo } from 'react';
import { EditOutlined, DeleteOutlined, ShopOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Button, Space } from 'antd';
import useModal from '../../hook/useModal';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Table from '../../shared/table/Table';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const ButtonStyle = styled(Button)`
  font-weight: 700;
  padding: 0;
`;

const Warehouse = () => {
  const { openModal } = useModal();

  // breadCrumb 데이터 생성
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <ShopOutlined />,
        breadCrumbNav: ' 창고',
      },
    ],
    []
  );

  // 불러올 table이 뭔지
  const table = 'warehouse';
  // TableHeader에 들어갈 create 버튼 텍스트
  const createButtonText = '창고 추가';
  // table header에 들어갈 create 버튼의 Modal객체 (Modal 속성으로 넣어줄 객체)
  const createButtonModal = useMemo(() => {
    return {
      type: 'warehouse',
      modalInfo: {
        modalTitle: '창고 추가',
        resultStatus: '',
        resultTitle: '',
        resultSubTitle: '',
        buttonText: '',
      },
      dataInfo: {
        dataId: '',
        dataTable: 'warehouse',
        datas: {},
      },
    };
  }, []);
  // table에 넣을 columns. table View를 위한 배열.
  const tableColumns = useMemo(() => {
    return [
      {
        title: '창고코드',
        dataIndex: 'code',
        key: 'code',
        width: '30%',
        align: 'center',
      },
      {
        title: '창고명',
        dataIndex: 'name',
        key: 'name',
        width: '30%',
        align: 'center',
      },
      {
        title: '구분',
        dataIndex: 'category',
        key: 'category',
        width: '30%',
        align: 'center',
      },
      {
        title: '',
        dataIndex: 'button',
        key: 'button',
        align: 'center',
        width: '10%',
        render: (_, record) => (
          <ButtonContainer>
            <Space.Compact size="small">
              <Button
                onClick={() =>
                  openModal({
                    type: 'warehouse',
                    modalInfo: {
                      modalTitle: '창고 수정',
                      resultStatus: '',
                      resultTitle: '',
                      resultSubTitle: '',
                      buttonText: '',
                    },
                    dataInfo: {
                      dataId: record.key,
                      dataTable: 'warehouse',
                      datas: {},
                    },
                  })
                }
              >
                <EditOutlined />
              </Button>
              <Button
                onClick={() =>
                  openModal({
                    type: 'warehouse',
                    modalInfo: {
                      modalTitle: '창고 삭제',
                      resultStatus: '',
                      resultTitle: '',
                      resultSubTitle: '',
                      buttonText: '',
                    },
                    dataInfo: {
                      dataId: record.key,
                      dataTable: 'warehouse',
                      datas: {},
                    },
                  })
                }
              >
                <DeleteOutlined />
              </Button>
            </Space.Compact>
          </ButtonContainer>
        ),
      },
    ];
  }, [openModal]);

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <Table
        tableColumns={tableColumns}
        table={table}
        createButtonText={createButtonText}
        createButtonModal={createButtonModal}
        items={tableColumns}
      />
    </LayoutComponent>
  );
};

export default Warehouse;
