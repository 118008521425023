import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Empty, Popconfirm, Spin, Switch } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import { asyncDeleteDataFetch } from '../../redux/modules/shared/deleteData';

const ProductDiscountInfo = (props) => {
  const {
    dcProductDatas,
    dcProductDatasLoading,
    accountId,
    setDcProductDatas,
  } = props;

  const dispatch = useDispatch();

  const switchIsOn = async (productId, value) => {
    try {
      // 데이터베이스 변경
      const dcAcctPdDatas = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'discount_account_product',
          eqKey: 'account_id',
          eqValue: accountId,
        })
      )
        .unwrap()
        .then((res) => res);
      const dataIdToSwitchIsOn = dcAcctPdDatas.filter(
        (data) => data.product_id === productId
      )[0].id;
      await dispatch(
        asyncUpdateDataFetch({
          table: 'discount_account_product',
          id: dataIdToSwitchIsOn,
          is_on: value,
        })
      );
      // 클라이언트 변경
      await setDcProductDatas((prev) =>
        prev.map((data) => {
          if (data.product_id === productId) {
            return { ...data, is_on: value };
          } else {
            return { ...data };
          }
        })
      );
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const deleteSelectedData = async (productId) => {
    try {
      const dcAcctPdDatas = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'discount_account_product',
          eqKey: 'account_id',
          eqValue: accountId,
        })
      )
        .unwrap()
        .then((res) => res);
      const dataIdToDelete = dcAcctPdDatas.filter(
        (data) => data.product_id === productId
      )[0].id;
      await dispatch(
        asyncDeleteDataFetch({
          table: 'discount_account_product',
          id: dataIdToDelete,
        })
      );
      setDcProductDatas((prev) =>
        prev.filter((data) => data.product_id !== productId)
      );
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return (
    <div
      style={{
        padding: '5px',
        margin: '5px 10px',
        borderRadius: '20px',
        border: '1px solid lightgray',
      }}
    >
      <Header>
        <div style={{ width: '15%' }}>할인적용</div>
        <div style={{ width: '25%' }}>품목(출고상품)</div>
        <div style={{ width: '20%' }}>상품가격</div>
        <div style={{ width: '20%' }}>할인금액</div>
        <div style={{ width: '20%' }}>판매금액</div>
      </Header>
      {dcProductDatasLoading && (
        <div style={{ height: '130px', padding: '30px' }}>
          <Spin />
        </div>
      )}
      {!dcProductDatasLoading && dcProductDatas.length === 0 && (
        <Empty description="거래처할인 없음" style={{ marginTop: '10px' }} />
      )}
      <ContentContainer>
        {dcProductDatas.map((data) => (
          <Content key={data.product_id}>
            <div style={{ width: '15%' }}>
              <Switch
                checked={data.is_on}
                onClick={(value) => switchIsOn(data.product_id, value)}
              />
            </div>
            <div style={{ width: '25%' }}>
              <div style={{ fontWeight: '600' }}>{data.code}</div>
              <div style={{ fontSize: '12px' }}>{data.name}</div>
            </div>
            <div style={{ width: '20%' }}>
              {data.release_price.toLocaleString()}원
            </div>
            <div style={{ width: '20%' }}>
              -{data.discounted_release_price.toLocaleString()}원
            </div>
            <div style={{ width: '20%' }}>
              {(
                data.release_price - data.discounted_release_price
              ).toLocaleString()}
              원
            </div>
            <Popconfirm
              title={<div>정말 할인데이터를 삭제하시겠습니까?</div>}
              onConfirm={() => deleteSelectedData(data.product_id)}
            >
              <Button
                size="small"
                style={{
                  position: 'absolute',
                  right: '5px',
                  borderRadius: '50%',
                  width: '17px',
                  height: '17px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                danger
                type="primary"
              >
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </Content>
        ))}
      </ContentContainer>
    </div>
  );
};

export default ProductDiscountInfo;

ProductDiscountInfo.propTypes = {
  dcProductDatas: PropTypes.instanceOf(Array).isRequired,
  dcProductDatasLoading: PropTypes.bool.isRequired,
  accountId: PropTypes.string.isRequired,
  setDcProductDatas: PropTypes.func.isRequired,
};

const Header = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid lightgray;
  margin-top: 10px;
  padding-bottom: 5px;
  color: gray;
`;
const Content = styled.div`
  padding: 10px 0;
  padding-left: 5px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 13px;
`;
const ContentContainer = styled.div`
  max-height: 250px;
  overflow: auto;
`;
