import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, Checkbox, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import ProductQuantityValue from './ProductQuantityValue';

const ProductTable = (props) => {
  const { productDetails, setProductDetails, pageType, formType } = props;
  const dispatch = useDispatch();

  const [tableDatas, setTableDatas] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);

  const handleChecked = (checked, id) => {
    if (checked) {
      setCheckedIds((prev) => [...prev, id]);
    } else {
      setCheckedIds((prev) => [
        ...prev.filter((checkedId) => checkedId !== id),
      ]);
    }
  };

  const deleteChecked = useCallback(() => {
    setProductDetails((prev) => {
      const result = [];
      prev.forEach((data) => {
        let isEqual = false;
        checkedIds.forEach((id) => {
          if (data.productId === id) {
            isEqual = true;
          }
        });
        if (isEqual) return;
        result.push(data);
      });
      return result;
    });
  }, [setProductDetails, checkedIds]);

  const tableColumn = useMemo(
    () => [
      {
        key: 'checkbox',
        title: (
          <Button
            size="small"
            onClick={deleteChecked}
            disabled={formType === 'purchase' || pageType === 'detail'}
          >
            <DeleteOutlined />
          </Button>
        ),
        dataIndex: '',
        width: '10%',
        align: 'center',
        render: (record) => (
          <Checkbox
            onChange={(e) => handleChecked(e.target.checked, record.id)}
            disabled={formType === 'purchase' || pageType === 'detail'}
          />
        ),
      },
      {
        key: 'code',
        title: '품목코드',
        dataIndex: 'code',
        width: '10%',
        align: 'center',
      },
      {
        key: 'name',
        title: '품목명',
        dataIndex: 'name',
        width: '20%',
        align: 'center',
      },
      {
        key: 'standard',
        title: '규격',
        dataIndex: 'standard',
        width: '10%',
        align: 'center',
      },
      {
        key: 'quantity',
        title: '수량',
        dataIndex: 'quantity',
        width: '10%',
        align: 'center',
        render: (value, record) => {
          if (formType === 'purchaseOrder') {
            return value.toLocaleString();
          }
          if (formType === 'purchase') {
            return (
              <ProductQuantityValue
                value={value}
                id={record.id}
                setProductDetails={setProductDetails}
                formType={formType}
              />
            );
          }
        },
      },
      {
        key: 'unitPrice',
        title: '단가',
        dataIndex: 'unitPrice',
        width: '10%',
        align: 'center',
        render: (value) => value.toLocaleString(),
      },
      {
        key: 'supplyPrice',
        title: '공급가액',
        dataIndex: 'supplyPrice',
        width: '10%',
        align: 'center',
        render: (value) => value.toLocaleString(),
      },
      {
        key: 'vatPrice',
        title: '부가세',
        dataIndex: 'vatPrice',
        width: '10%',
        align: 'center',
        render: (value) => value.toLocaleString(),
      },
      {
        key: 'totalPrice',
        title: '총액',
        dataIndex: '',
        width: '10%',
        align: 'center',
        render: (record) =>
          (record.supplyPrice + record.vatPrice).toLocaleString(),
      },
    ],
    [deleteChecked, formType, setProductDetails, pageType]
  );

  useEffect(() => {
    const calcProductPrice = (incomingPrice, quantity) => {
      const unitPrice = Math.round(incomingPrice / 1.1);
      const supplyPrice = Math.round(unitPrice * quantity);
      const vatPrice = Math.round(supplyPrice * 0.1);
      return { unitPrice, supplyPrice, vatPrice };
    };
    const getProductDatas = async () => {
      try {
        const productIdAndQtyArr = productDetails.map((data) => ({
          productId: data.productId,
          productQuantity: data.productQuantity,
        }));
        const productDatas = await Promise.all(
          productIdAndQtyArr.map(async (data) => {
            const productData = await dispatch(
              asyncReadSpecificDataFetch({
                table: 'product',
                id: data.productId,
              })
            )
              .unwrap()
              .then((res) => ({
                ...res[0],
                productQuantity: data.productQuantity,
              }));
            return productData;
          })
        );
        const result = productDatas.map((data) => ({
          key: data.id,
          id: data.id,
          code: data.code,
          name: data.name,
          standard: data.standard,
          quantity: data.productQuantity,
          ...calcProductPrice(data.incoming_price, data.productQuantity),
        }));
        setTableDatas(result);
      } catch (error) {
        throw new Error(error);
      }
    };
    if (productDetails.length >= 1) {
      getProductDatas();
    } else {
      setTableDatas([]);
    }
  }, [productDetails, dispatch, setProductDetails]);

  return (
    <Table
      pagination={false}
      style={{ marginTop: '10px' }}
      columns={tableColumn}
      dataSource={tableDatas}
      summary={(data) => {
        let totalQuantity = 0;
        let totalUnitPrice = 0;
        let totalSupplyPrice = 0;
        let totalVatPrice = 0;
        data.forEach(({ quantity, unitPrice, supplyPrice, vatPrice }) => {
          totalQuantity += quantity;
          totalUnitPrice += unitPrice;
          totalSupplyPrice += supplyPrice;
          totalVatPrice += vatPrice;
        });
        return (
          <Table.Summary.Row
            style={{
              backgroundColor: '#fafafa',
              textAlign: 'center',
              fontWeight: '600',
            }}
          >
            <Table.Summary.Cell />
            <Table.Summary.Cell />
            <Table.Summary.Cell />
            <Table.Summary.Cell />
            <Table.Summary.Cell>
              {totalQuantity.toLocaleString()}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {totalUnitPrice.toLocaleString()}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {totalSupplyPrice.toLocaleString()}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {totalVatPrice.toLocaleString()}
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {(totalSupplyPrice + totalVatPrice).toLocaleString()}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
    />
  );
};

export default ProductTable;

ProductTable.propTypes = {
  productDetails: PropTypes.instanceOf(Array).isRequired,
  setProductDetails: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
  formType: PropTypes.string.isRequired,
};
