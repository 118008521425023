import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../axios';
import useSupabase from '../../../serverConfig';

import supabase from '../../../supabase';

const initialState = {
  isLoadingCreateData: false,
  isErrorCreateData: false,
  isSuccessCreateData: false,
};

const asyncCreateDataFetch = createAsyncThunk(
  'createDataSlice/asyncCreateDataFetch',
  async (values, { rejectWithValue }) => {
    // values의 필수 값 => table
    const { table, ...rest } = values;
    try {
      const { data } = useSupabase
        ? await supabase.from(table).insert(rest).select()
        : await axiosInstance.post(`/api/dynamic-crud/${table}`, rest);

      console.log(data);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createDataSlice = createSlice({
  name: 'createData',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncCreateDataFetch.pending, (state) => {
      state.isLoadingCreateData = true;
      state.isSuccessCreateData = false;
      state.isErrorCreateData = false;
    });
    builder.addCase(asyncCreateDataFetch.fulfilled, (state) => {
      state.isLoadingCreateData = false;
      state.isSuccessCreateData = true;
      state.isErrorCreateData = false;
    });
    builder.addCase(asyncCreateDataFetch.rejected, (state) => {
      state.isLoadingCreateData = false;
      state.isSuccessCreateData = false;
      state.isErrorCreateData = true;
    });
  },
});

export default createDataSlice.reducer;
export { asyncCreateDataFetch };
