const shortenSpecificWord = () => {
  const shortenWarehouseName = (datasToShorten) => {
    const shortenedDatas = datasToShorten.map((data) => {
      if (data.product_warehouse === '00009') {
        return { ...data, warehouse_name: '고려' };
      }
      if (data.product_warehouse === '00004') {
        return { ...data, warehouse_name: '준테크' };
      }
      if (data.product_warehouse === '00011') {
        return { ...data, warehouse_name: '건영' };
      }
      return data;
    });
    return shortenedDatas;
  };

  return { shortenWarehouseName };
};

export default shortenSpecificWord;
