import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, Input, Modal, Progress, Select, Timeline } from 'antd';
import styled from 'styled-components';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const ProductName = (props) => {
  const { productCode, setSaleProductTableRows, productQty, pageType } = props;
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState(0);
  const [dcType, setDcType] = useState('amount');
  const [percent, setPercent] = useState(0);
  const [amount, setAmount] = useState(0);
  const [discounted, setDiscounted] = useState(0);

  const selectValues = [
    { label: '￦ / 할인금액 입력', value: 'amount' },
    { label: '% / 할인율 입력', value: 'percent' },
  ];

  useEffect(() => {
    dispatch(
      asyncReadFilteredDataFetch({
        table: 'product',
        eqKey: 'code',
        eqValue: productCode,
      })
    )
      .unwrap()
      .then((res) => {
        const { name } = res[0];
        const { release_price } = res[0];
        const newPrice = release_price * productQty;
        setProductName(name);
        setProductPrice(newPrice);
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, [dispatch, productCode, productQty]);

  // 할인 불러오기

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (percent === 0 || percent < 0) {
      alert('할인율을 1이상으로 설정해주세요.');
      return;
    }
    if (percent > 100) {
      alert('할인율을 100이하로 설정해주세요.');
      return;
    }
    setSaleProductTableRows((prev) =>
      prev.map((data) =>
        data.product_code === productCode
          ? { ...data, discount_rate: percent }
          : data
      )
    );
    setIsModalOpen(false);
    setAmount(0);
    setDiscounted(0);
    setPercent(0);
    setDcType('amount');
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setAmount(0);
    setDiscounted(0);
    setPercent(0);
    setDcType('amount');
  };
  const handlePercentInput = (value) => {
    const newPercent = value;
    const newAmount = (productPrice * newPercent) / 100;
    const newDiscounted = productPrice - newAmount;
    setPercent(newPercent);
    setAmount(newAmount);
    setDiscounted(newDiscounted);
  };
  const handleAmountInput = (value) => {
    const newAmount = value;
    const newDiscounted = productPrice - newAmount;
    const newPercent = ((productPrice - newDiscounted) / productPrice) * 100;
    setAmount(newAmount);
    setDiscounted(newDiscounted);
    setPercent(newPercent);
  };

  return (
    <div>
      <div>{productName}</div>
      <Modal
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="저장"
        cancelText="취소"
      >
        <Form>
          <div>
            <div style={{ marginBottom: '2px' }}>할인선택</div>
            <div className="select-container">
              <Select
                style={{ width: '100%' }}
                options={selectValues}
                value={dcType}
                onChange={(value) => setDcType(value)}
              />
            </div>
          </div>
          <div style={{ marginTop: '10px' }}>
            <div style={{ marginBottom: '2px' }}>할인금액</div>
            <Input
              style={{ borderRadius: '0px' }}
              type="number"
              disabled={dcType === 'percent'}
              value={amount}
              onChange={(e) => handleAmountInput(e.target.value)}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <div style={{ marginBottom: '2px' }}>할인율</div>
            <Input
              style={{ borderRadius: '0px' }}
              type="number"
              disabled={dcType === 'amount'}
              value={percent}
              onChange={(e) => handlePercentInput(e.target.value)}
            />
          </div>
        </Form>
        <Display>
          <div>
            <div style={{ fontSize: '16px' }}>
              {productName} / {productCode}
            </div>
          </div>
          <Progress
            type="circle"
            percent={percent}
            style={{ marginTop: '20px' }}
            format={(rate) => <div>{rate}%</div>}
          />
          <Timeline style={{ marginTop: '30px' }}>
            <Timeline.Item>
              <div style={{ fontSize: '16px' }}>상품금액</div>
              <div style={{ color: 'gray' }}>
                {productPrice.toLocaleString()}원
              </div>
            </Timeline.Item>
            <Timeline.Item>
              <div style={{ fontSize: '16px' }}>할인금액</div>
              <div style={{ color: 'gray' }}>
                -{Number(amount).toLocaleString()}원
              </div>
            </Timeline.Item>
            <Timeline.Item>
              <div style={{ fontSize: '16px' }}>판매금액</div>
              <div style={{ color: 'gray' }}>
                {discounted.toLocaleString()}원
              </div>
            </Timeline.Item>
          </Timeline>
        </Display>
      </Modal>
    </div>
  );
};

export default ProductName;

ProductName.propTypes = {
  productCode: PropTypes.string.isRequired,
  setSaleProductTableRows: PropTypes.func.isRequired,
  productQty: PropTypes.number.isRequired,
  pageType: PropTypes.string.isRequired,
};

const Form = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
`;
const Display = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid lightgray;
  padding: 20px;
  border-radius: 10px;
`;
