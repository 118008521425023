import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Form,
  Button,
  Descriptions,
  Select,
  Input,
  Checkbox,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import ProductDetail from './ProductDetail';
import ProductName from './ProductName';
import ProductQuantity from './ProductQuantity';
import SaleProductInfo from './SaleProductInfo';

const SaleProductFormComponent = forwardRef((props, ref) => {
  const { saleProductDatas, pageType, saleCode } = props;

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  // 임의로 주어지는 saleCode값
  const [saleCodeValue, setSaleCodeValue] = useState('');
  // 기존 table row값들
  const [saleProductTableRows, setSaleProductTableRows] = useState([]);

  // 출고상품 options
  const [productOptions, setProductOptions] = useState([]);
  // 선택된 출고상품 option (for 추가)
  const [selectedOption, setSelectedOption] = useState('');
  // 체크된 출고상품 options (for 삭제)
  const [checkedOptions, setCheckedOptions] = useState([]);

  // 출고상품 select-search form 생성
  useEffect(() => {
    dispatch(asyncReadDataFetch({ table: 'product' })).then((res) => {
      setProductOptions([
        ...res.payload.map((data) => ({
          value: data.code,
          label: `${data.code} / ${data.name}`,
        })),
      ]);
    });
  }, [dispatch]);

  useEffect(() => {
    // pageType이 detail 또는 update 일때
    if (pageType === 'detail' || pageType === 'update') {
      // sale input에 saleCode값 넣기
      setSaleCodeValue(saleCode);
      // 마운트시 table rows에 saleProductDatas 값 넣기
      setSaleProductTableRows([...saleProductDatas]);
    }
    // pageType이 create일 때
    if (pageType === 'create') {
      const getSaleCode = async () => {
        try {
          const len = await dispatch(
            asyncReadDataFetch({ table: 'sale', reqDataLen: true })
          )
            .unwrap()
            .then((res) => res + 1);
          const createdSaleCode = `S${len.toString().padStart(6, 0)}`;
          setSaleCodeValue(createdSaleCode);
        } catch (err) {
          console.log(err);
          alert(err.message);
        }
      };
      getSaleCode();
    }
  }, [
    setSaleProductTableRows,
    saleProductDatas,
    form,
    pageType,
    dispatch,
    saleCode,
  ]);

  // table에 해당 values값의 row를 추가하는 함수
  const createSelectedOption = useCallback(() => {
    // 아무것도 선택되지 않았을 때를 체크하는 함수
    if (selectedOption.length === 0) {
      alert('출고상품을 선택해주세요.');
      return;
    }
    // 이미 있는 옵션인지 체크하는 함수
    let isDuplicated = null;
    saleProductTableRows.forEach((option) => {
      if (option.product_code === selectedOption) {
        isDuplicated = true;
      }
    });
    if (isDuplicated) {
      alert(
        '이미 존재하는 출고상품입니다. 기존에 추가된 출고상품의 개수를 추가해주세요.'
      );
      return;
    }
    // 추가
    setSaleProductTableRows((prev) => {
      return [
        ...prev,
        {
          product_code: selectedOption,
          product_quantity: 1,
          sale_code: saleCodeValue,
        },
      ];
    });
  }, [selectedOption, saleCodeValue, saleProductTableRows]);

  // table에서 해당 values값의 product_quantity를 수정하는 함수
  const handleEditOption = useCallback(
    (option, e) => {
      const productQuantity = Number(e.target.value);
      const newOptions = saleProductTableRows.map((data) => {
        if (data === option) {
          return {
            ...data,
            product_quantity: productQuantity,
          };
        }
        return data;
      });
      setSaleProductTableRows([...newOptions]);
    },
    [saleProductTableRows]
  );

  // 체크박스 핸들링 함수
  const handleCheckbox = useCallback(
    (v, e) => {
      const { checked } = e.target;
      // checkedOptions에 추가
      if (checked) {
        // 추가하기 전에 이미 추가되어 있는지 체크
        let isDuplicated = false;
        checkedOptions.forEach((option) => {
          if (option === v) {
            isDuplicated = true;
          }
        });
        if (isDuplicated) {
          return;
        }
        // checkedOptions에 추가
        setCheckedOptions((prev) => {
          return [...prev, v];
        });
      }
      // checkedOptions에서 삭제
      if (!checked) {
        const filtered = checkedOptions.filter((option) => {
          return option !== v;
        });
        setCheckedOptions([...filtered]);
      }
    },
    [checkedOptions]
  );

  // table에서 체크된 option들의 row를 삭제하는 함수
  const handleDeleteOption = useCallback(() => {
    const deleted = [
      ...saleProductTableRows.filter(
        (row) => !checkedOptions.find((option) => option === row)
      ),
    ];
    setSaleProductTableRows([...deleted]);
  }, [saleProductTableRows, checkedOptions]);

  const columns = useMemo(
    () => [
      {
        title: (
          <Button size="small" onClick={handleDeleteOption}>
            <DeleteOutlined />
          </Button>
        ),
        key: 'checkbox',
        width: '10%',
        align: 'center',
        render: (v) => (
          <Checkbox
            disabled={pageType === 'detail'}
            onChange={(e) => handleCheckbox(v, e)}
          />
        ),
      },
      {
        title: '판매상품코드',
        dataIndex: 'sale_code',
        key: 'sale_code',
        width: '22.5%',
        align: 'center',
      },
      {
        title: '출고상품코드',
        dataIndex: 'product_code',
        key: 'product_code',
        width: '22.5%',
        align: 'center',
        render: (v, data) => (
          <ProductDetail
            productCode={v}
            setSaleProductTableRows={setSaleProductTableRows}
            productQty={data.product_quantity}
            pageType={pageType}
          />
        ),
      },
      {
        title: '출고상품명',
        dataIndex: 'product_code',
        key: 'product_name',
        width: '22.5%',
        align: 'center',
        render: (v, data) => (
          <ProductName
            productCode={v}
            setSaleProductTableRows={setSaleProductTableRows}
            productQty={data.product_quantity}
            productData={data}
            pageType={pageType}
          />
        ),
      },
      {
        title: '출고상품개수',
        dataIndex: 'product_quantity',
        key: 'product_quantity',
        width: '22.5%',
        align: 'center',
        render: (value, option) => (
          <ProductQuantity
            productQuantity={value}
            productOption={option}
            handleEditOption={handleEditOption}
            pageType={pageType}
          />
        ),
      },
    ],
    [handleDeleteOption, handleCheckbox, pageType, handleEditOption]
  );

  useImperativeHandle(ref, () => ({
    getSaleProductTableRows: () => {
      return saleProductTableRows;
    },
  }));
  return (
    <div>
      <Form disabled={pageType === 'detail'}>
        <Descriptions
          bordered
          labelStyle={{ width: '140px', textAlign: 'center' }}
        >
          <Descriptions.Item label="출고상품">
            <Form.Item noStyle>
              <div className="select-container" style={{ display: 'flex' }}>
                <Select
                  style={{ width: '100%', borderRadius: '0px' }}
                  placeholder="출고상품코드 또는 출고상품명을 입력해주세요."
                  showSearch
                  options={productOptions}
                  filterOption={(input, option) => {
                    return option.label && option.label.includes(input);
                  }}
                  onChange={(e) => setSelectedOption(e)}
                />
                <Button
                  type="primary"
                  style={{ marginLeft: '15px', borderRadius: '0px' }}
                  htmlType="submit"
                  onClick={createSelectedOption}
                >
                  <PlusOutlined />
                </Button>
              </div>
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
      </Form>
      <Table
        columns={columns}
        dataSource={saleProductTableRows}
        pagination={false}
        style={{ marginTop: '20px' }}
      />
      <SaleProductInfo
        saleProductTableRows={saleProductTableRows}
        setSaleProductTableRows={setSaleProductTableRows}
        pageType={pageType}
      />
    </div>
  );
});

export default SaleProductFormComponent;

SaleProductFormComponent.propTypes = {
  saleProductDatas: PropTypes.instanceOf(Array).isRequired,
  pageType: PropTypes.string.isRequired,
  saleCode: PropTypes.string,
};
