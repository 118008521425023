import React, { useMemo } from 'react';
import { EditOutlined, DeleteOutlined, TeamOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Button, Space } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useModal from '../../hook/useModal';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Table from '../../shared/table/Table';
import Avvvatar from '../../shared/components/Avvvatar';
import ManagerPermission from '../components/ManagerPermission';
import ManagerTier from '../components/ManagerTier';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const Manager = () => {
  const history = useHistory();
  const { openModal } = useModal();
  const { tier } = useSelector((state) => state.signinUser.userInfo);

  // breadCrumb 데이터 생성
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <TeamOutlined />,
        breadCrumbNav: ' 관리자리스트',
      },
    ],
    []
  );

  // 불러올 table이 뭔지
  const table = 'profiles';

  // TableHeader에 들어갈 create 버튼 텍스트
  const createButtonText = '관리자 추가';

  // table header에 들어갈 create 버튼의 Modal객체 (Modal 속성으로 넣어줄 객체)
  const createButtonModal = {
    type: 'manager',
    modalInfo: {
      modalTitle: '관리자 추가',
      resultStatus: '',
      resultTitle: '',
      resultSubTitle: '',
      buttonText: '',
    },
    dataInfo: {
      dataId: '',
      dataTable: 'manager',
      datas: {},
    },
  };

  // table에 넣을 columns. table View를 위한 배열.
  const tableColumns = [
    {
      title: ' ',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (_, user) => <Avvvatar username={user.username} />,
      align: 'center',
    },
    {
      title: '이름',
      dataIndex: 'username',
      key: 'username',
      width: '20%',
      align: 'center',
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      render: (_, user) => (
        <Button
          type="link"
          size="small"
          onClick={() => history.push(`/user-info/${user.id}`)}
        >
          {user.email}
        </Button>
      ),
      width: '20%',
      align: 'center',
    },
    {
      title: '전화번호',
      dataIndex: 'phone',
      key: 'phone',
      width: '20%',
      align: 'center',
    },
    {
      title: '허가',
      dataIndex: 'permission',
      key: 'permission',
      render: (_, user) => <ManagerPermission permission={user.permission} />,
      width: '20%',
      align: 'center',
    },
    {
      title: '등급',
      dataIndex: 'tier',
      key: 'tier',
      render: (_, user) => <ManagerTier tier={user.tier} />,
      width: '20%',
      align: 'center',
    },
  ];

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <Table
        tableColumns={tableColumns}
        table={table}
        createButtonText={createButtonText}
        createButtonModal={createButtonModal}
        items={tableColumns}
      />
    </LayoutComponent>
  );
};

export default Manager;
