import { Button, Input, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PaperClipOutlined, SendOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const { TextArea } = Input;

const MailModal = (props) => {
  const { setIsOpenMailModal, accountCode } = props;
  const dispatch = useDispatch();

  const [mailAddress, setMailAddress] = useState({
    from: 'hantongbox@naver.com',
    to: '',
  });
  const [title, setTitle] = useState('(주)한통 견적서 첨부의 건.');
  const [content, setContent] = useState(
    '기능을 사용할 수 없습니다. (오픈예정)'
  );
  const [estimate, setEstimate] = useState('');

  useEffect(() => {
    // 수신이메일 불러오기
    const getToEmail = async () => {
      try {
        const toEmail = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'account',
            eqKey: 'code',
            eqValue: accountCode,
          })
        )
          .unwrap()
          .then((res) => res[0].email);
        if (toEmail.length > 0) {
          setMailAddress((prev) => ({ ...prev, to: toEmail }));
        } else {
          return;
        }
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };
    getToEmail();
  }, [dispatch, accountCode]);

  return (
    <Modal
      open
      title="Mail"
      width={600}
      centered
      onCancel={() => setIsOpenMailModal(false)}
      footer={null}
    >
      <Header>
        <InputStyle
          prefix={<PrefixStyle>발신 : </PrefixStyle>}
          value={mailAddress.from}
        />
        <InputStyle
          prefix={<PrefixStyle>수신 : </PrefixStyle>}
          value={mailAddress.to}
        />
        <InputStyle prefix={<PrefixStyle>제목 : </PrefixStyle>} value={title} />
      </Header>
      <Content>
        <TextStyle rows={10} value={content} />
      </Content>
      <Footer>
        <Button type="link" danger disabled>
          <PaperClipOutlined />
          견적서
        </Button>
        <Button type="primary" style={{ borderRadius: '20px' }} disabled>
          <SendOutlined /> 보내기
        </Button>
      </Footer>
    </Modal>
  );
};

export default MailModal;

MailModal.propTypes = {
  setIsOpenMailModal: PropTypes.func.isRequired,
  accountCode: PropTypes.string.isRequired,
};

const Header = styled.div``;
const Content = styled.div``;
const Footer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
`;

const PrefixStyle = styled.div`
  color: gray;
`;

const InputStyle = styled(Input)`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid lightgray;
  border-radius: 0;
  margin-top: 10px;
`;
const TextStyle = styled(TextArea)`
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  margin-top: 20px;
`;
