import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import ErrorTable from './ErrorTable';

const ErrorDatas = () => {
  const dispatch = useDispatch();
  const [errorDatas, setErrorDatas] = useState({
    order: [],
    sales: [],
    account: [],
  });

  useEffect(() => {
    const getErrorDatas = async () => {
      try {
        const allErrorDatas = await dispatch(
          asyncReadDataFetch({ table: 'ecount_sync_error_record' })
        )
          .unwrap()
          .then((res) => res);

        const order = [];
        const sales = [];
        const account = [];

        allErrorDatas.forEach((item) => {
          const { target_table } = item;
          if (target_table === '주문') {
            order.push(item);
          }
          if (target_table === '판매') {
            sales.push(item);
          }
          if (target_table === '거래처') {
            account.push(item);
          }
        });

        const result = { order, sales, account };
        setErrorDatas(result);
      } catch (error) {
        console.error(error);
      }
    };
    getErrorDatas();
  }, [dispatch]);

  return <ErrorTable errorDatas={errorDatas} setErrorDatas={setErrorDatas} />;
};

export default ErrorDatas;
