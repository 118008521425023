import { Button, DatePicker, Input } from 'antd';
import dayjs from 'dayjs';
import React, { memo, useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import {
  COUNSELLING_PURCHASE_CONSIDERING,
  COUNSELLING_PURCHASE_HOLD,
  COUNSELLING_PURCHASE_OTHER,
  COUNSELLING_PURCHASE_PLAN,
  COUNSELLING_REACTION_BAD,
  COUNSELLING_REACTION_GOOD,
  COUNSELLING_REACTION_NORMAL,
  COUNSELLING_TYPE_MESSAGE,
  COUNSELLING_TYPE_MOBILE,
  COUNSELLING_TYPE_OTHER,
  COUNSELLING_TYPE_PHONE,
  COUNSELLING_TYPE_QNA,
  COUNSELLING_TYPE_TALKTALK,
  ROUTE_COMPETITOR_DELIVERY,
  ROUTE_FRIEND,
  ROUTE_INTERNET,
  ROUTE_OTHER,
  ROUTE_PAST_USER,
} from '../../constants';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';

const CounsellingFormFields = (props) => {
  const { pageType, handleCreate, handleUpdate, savedFormData } = props;
  const history = useHistory();
  const adminInfo = useSelector((state) => state.signinUser.userInfo);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    adminId: adminInfo.id,
    accountName: null,
    contact: null,
    route: ROUTE_INTERNET,
    counsellingType: COUNSELLING_TYPE_MOBILE,
    deliveryDate: null,
    counsellingDate: dayjs().format('YYYY-MM-DD'),
    content: null,
    purchaseDate: null,
    registrationName: null,
    reactionLevel: COUNSELLING_REACTION_GOOD,
    purchaseIntention: COUNSELLING_PURCHASE_PLAN,
  });
  const [adminData, setAdminData] = useState({
    id: adminInfo.id,
    username: adminInfo.username,
    email: adminInfo.email,
    code: adminInfo.code,
  });

  const [isMe, setIsMe] = useState(false);

  const [isDisplayRouteInput, setIsDisplayRouteInput] = useState(false);
  const [isDisplayCounsellingTypeInput, setIsDisplayCounsellingTypeInput] =
    useState(false);
  const [isDisplayPurchaseIntentionInput, setIsDisplayPurchaseIntentionInput] =
    useState(false);
  const [triggeredInput, setTriggeredInput] = useState(null);

  const routeInputRef = useRef(null);
  const counsellingTypeInputRef = useRef(null);
  const purchaseIntentionRef = useRef(null);

  useEffect(() => {
    if (routeInputRef.current && triggeredInput === 'route') {
      routeInputRef.current.focus();
    } else if (
      counsellingTypeInputRef.current &&
      triggeredInput === 'counsellingType'
    ) {
      counsellingTypeInputRef.current.focus();
    } else if (
      purchaseIntentionRef.current &&
      triggeredInput === 'purchaseIntention'
    ) {
      purchaseIntentionRef.current.focus();
    } else {
      return;
    }
  }, [
    isDisplayRouteInput,
    isDisplayCounsellingTypeInput,
    isDisplayPurchaseIntentionInput,
    triggeredInput,
  ]);

  useEffect(() => {
    const isNotEmptyObject = (obj) => {
      return Object.keys(obj).length > 0;
    };
    if (isNotEmptyObject(savedFormData) && pageType === 'update') {
      const { route, counsellingType, purchaseIntention } = savedFormData;
      if (
        route !== ROUTE_COMPETITOR_DELIVERY &&
        route !== ROUTE_FRIEND &&
        route !== ROUTE_INTERNET &&
        route !== ROUTE_PAST_USER
      ) {
        setIsDisplayRouteInput(true);
      }
      if (
        counsellingType !== COUNSELLING_TYPE_MESSAGE &&
        counsellingType !== COUNSELLING_TYPE_MOBILE &&
        counsellingType !== COUNSELLING_TYPE_PHONE &&
        counsellingType !== COUNSELLING_TYPE_QNA
      ) {
        setIsDisplayCounsellingTypeInput(true);
      }
      if (
        purchaseIntention !== COUNSELLING_PURCHASE_CONSIDERING &&
        purchaseIntention !== COUNSELLING_PURCHASE_HOLD &&
        purchaseIntention !== COUNSELLING_PURCHASE_PLAN
      ) {
        setIsDisplayPurchaseIntentionInput(true);
      }
      setFormData((prev) => ({ ...prev, ...savedFormData }));
      const getAdminData = async () => {
        await dispatch(
          asyncReadSpecificDataFetch({
            table: 'profiles',
            id: savedFormData.adminId,
          })
        )
          .unwrap()
          .then((res) =>
            setAdminData({
              username: res[0].username,
              email: res[0].email,
              code: res[0].code,
            })
          )
          .catch((error) => {
            throw new Error(error);
          });
      };
      getAdminData();
    }
  }, [savedFormData]);

  const handleClickMeButton = useCallback(() => {
    if (adminInfo.code === adminData.code) {
      alert('내가 등록한 상담글 입니다.');
      return;
    }
    setIsMe(true);
    setFormData((prev) => ({ ...prev, adminId: adminInfo.id }));
    setAdminData((prev) => ({
      ...prev,
      username: adminInfo.username,
      code: adminInfo.code,
      email: adminInfo.email,
    }));
  }, [adminData, adminInfo]);

  const handleChangeInput = useCallback((e) => {
    const { name, value } = e.target;
    const newValue = value.replace(/\s/g, '');
    setFormData((prev) => ({ ...prev, [name]: newValue }));
  }, []);

  const handleChangeDatePicker = useCallback((name, e) => {
    const value = dayjs(e).format('YYYY-MM-DD');
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleClickButton = useCallback((name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px 0px 12px 0px',
          borderBottom: '1.5px solid gray',
        }}
      >
        <div style={{ fontSize: '22px', fontWeight: '600' }}>게시판 글쓰기</div>
        <div style={{ display: 'flex' }}>
          {pageType === 'create' && (
            <SubmitButtonStyle
              onClick={() => handleCreate(formData)}
              size="large"
            >
              등록
            </SubmitButtonStyle>
          )}
          {pageType === 'update' && (
            <SubmitButtonStyle
              onClick={() => handleUpdate(formData)}
              size="large"
            >
              저장
            </SubmitButtonStyle>
          )}
          <SubmitButtonStyle
            size="large"
            style={{ marginLeft: '5px' }}
            onClick={() => history.push('/counselling')}
          >
            목록
          </SubmitButtonStyle>
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: '12px' }}>
        <InputStyle
          placeholder="업체명을 입력해주세요. (필수)"
          style={{
            marginRight: '12px',
            width: '70%',
          }}
          prefix={<PrefixStyle>업체명</PrefixStyle>}
          name="accountName"
          onChange={handleChangeInput}
          value={formData.accountName}
        />
        <InputStyle
          placeholder="연락처 또는 안심번호를 입력해주세요."
          style={{ width: '30%' }}
          prefix={<PrefixStyle>연락처/안심번호</PrefixStyle>}
          name="contact"
          onChange={handleChangeInput}
          value={formData.contact}
        />
      </div>
      <div>
        <div
          style={{
            border: '1px solid lightgray',
            marginTop: '12px',
            padding: '0px 30px',
          }}
        >
          <FormContainer>
            <FormTitle>담당자</FormTitle>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid lightgray',
                padding: '10px 15px',
                borderRadius: '10px',
                width: pageType === 'update' ? 'fit-content' : '300px',
              }}
            >
              <div>
                <div style={{ fontSize: '15px' }}>
                  {adminData.code} / {adminData.username}
                </div>
                <div style={{ color: 'gray', fontSize: '15px' }}>
                  {adminData.email}
                </div>
              </div>
              {pageType === 'update' && (
                <>
                  <div
                    style={{
                      margin: '0 20px',
                    }}
                  />
                  <Button
                    style={{
                      color: isMe ? 'white' : 'black',
                      backgroundColor: isMe ? 'gray' : 'white',
                      marginRight: '10px',
                      borderRadius: '20px',
                    }}
                    onClick={handleClickMeButton}
                    disabled={isMe}
                  >
                    담당자 나로 변경
                  </Button>
                </>
              )}
              <UserOutlined
                style={{
                  color: 'lightgray',
                  fontWeight: 'bold',
                  fontSize: '17px',
                }}
              />
            </div>
          </FormContainer>
          <FormContainer>
            <FormTitle>상담 반응</FormTitle>
            <div>
              <ButtonStyle
                type={
                  formData.reactionLevel === COUNSELLING_REACTION_GOOD
                    ? 'primary'
                    : 'default'
                }
                onClick={() =>
                  handleClickButton('reactionLevel', COUNSELLING_REACTION_GOOD)
                }
              >
                {COUNSELLING_REACTION_GOOD}
              </ButtonStyle>
              <ButtonStyle
                type={
                  formData.reactionLevel === COUNSELLING_REACTION_NORMAL
                    ? 'primary'
                    : 'default'
                }
                onClick={() =>
                  handleClickButton(
                    'reactionLevel',
                    COUNSELLING_REACTION_NORMAL
                  )
                }
              >
                {COUNSELLING_REACTION_NORMAL}
              </ButtonStyle>
              <ButtonStyle
                type={
                  formData.reactionLevel === COUNSELLING_REACTION_BAD
                    ? 'primary'
                    : 'default'
                }
                onClick={() =>
                  handleClickButton('reactionLevel', COUNSELLING_REACTION_BAD)
                }
                danger={formData.reactionLevel === COUNSELLING_REACTION_BAD}
              >
                {COUNSELLING_REACTION_BAD}
              </ButtonStyle>
            </div>
          </FormContainer>
          <FormContainer>
            <FormTitle>상담 경로</FormTitle>
            <div>
              <ButtonStyle
                type={formData.route === ROUTE_INTERNET ? 'primary' : 'default'}
                onClick={() => {
                  handleClickButton('route', ROUTE_INTERNET);
                  setIsDisplayRouteInput(false);
                }}
              >
                {ROUTE_INTERNET}
              </ButtonStyle>
              <ButtonStyle
                type={formData.route === ROUTE_FRIEND ? 'primary' : 'default'}
                onClick={() => {
                  handleClickButton('route', ROUTE_FRIEND);
                  setIsDisplayRouteInput(false);
                }}
              >
                {ROUTE_FRIEND}
              </ButtonStyle>
              <ButtonStyle
                type={
                  formData.route === ROUTE_COMPETITOR_DELIVERY
                    ? 'primary'
                    : 'default'
                }
                onClick={() => {
                  handleClickButton('route', ROUTE_COMPETITOR_DELIVERY);
                  setIsDisplayRouteInput(false);
                }}
              >
                {ROUTE_COMPETITOR_DELIVERY}
              </ButtonStyle>
              <ButtonStyle
                type={
                  formData.route === ROUTE_PAST_USER ? 'primary' : 'default'
                }
                onClick={() => {
                  handleClickButton('route', ROUTE_PAST_USER);
                  setIsDisplayRouteInput(false);
                }}
              >
                {ROUTE_PAST_USER}
              </ButtonStyle>
              <ButtonStyle
                type={isDisplayRouteInput ? 'primary' : 'default'}
                onClick={() => {
                  handleClickButton('route', null);
                  setIsDisplayRouteInput(true);
                  setTriggeredInput('route');
                }}
              >
                {ROUTE_OTHER}
              </ButtonStyle>
              {isDisplayRouteInput && (
                <OtherInputStyle
                  size="large"
                  value={formData.route}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      route: e.target.value,
                    }))
                  }
                  ref={routeInputRef}
                  placeholder="입력해주세요."
                />
              )}
            </div>
          </FormContainer>
          <FormContainer>
            <FormTitle>상담 방식</FormTitle>
            <div>
              <ButtonStyle
                type={
                  formData.counsellingType === COUNSELLING_TYPE_MOBILE
                    ? 'primary'
                    : 'default'
                }
                onClick={() => {
                  handleClickButton('counsellingType', COUNSELLING_TYPE_MOBILE);
                  setIsDisplayCounsellingTypeInput(false);
                }}
              >
                {COUNSELLING_TYPE_MOBILE}
              </ButtonStyle>
              <ButtonStyle
                type={
                  formData.counsellingType === COUNSELLING_TYPE_PHONE
                    ? 'primary'
                    : 'default'
                }
                onClick={() => {
                  handleClickButton('counsellingType', COUNSELLING_TYPE_PHONE);
                  setIsDisplayCounsellingTypeInput(false);
                }}
              >
                {COUNSELLING_TYPE_PHONE}
              </ButtonStyle>
              <ButtonStyle
                type={
                  formData.counsellingType === COUNSELLING_TYPE_MESSAGE
                    ? 'primary'
                    : 'default'
                }
                onClick={() => {
                  handleClickButton(
                    'counsellingType',
                    COUNSELLING_TYPE_MESSAGE
                  );
                  setIsDisplayCounsellingTypeInput(false);
                }}
              >
                {COUNSELLING_TYPE_MESSAGE}
              </ButtonStyle>
              <ButtonStyle
                type={
                  formData.counsellingType === COUNSELLING_TYPE_TALKTALK
                    ? 'primary'
                    : 'default'
                }
                onClick={() => {
                  handleClickButton(
                    'counsellingType',
                    COUNSELLING_TYPE_TALKTALK
                  );
                  setIsDisplayCounsellingTypeInput(false);
                }}
              >
                {COUNSELLING_TYPE_TALKTALK}
              </ButtonStyle>
              <ButtonStyle
                type={
                  formData.counsellingType === COUNSELLING_TYPE_QNA
                    ? 'primary'
                    : 'default'
                }
                onClick={() => {
                  handleClickButton('counsellingType', COUNSELLING_TYPE_QNA);
                  setIsDisplayCounsellingTypeInput(false);
                }}
              >
                {COUNSELLING_TYPE_QNA}
              </ButtonStyle>
              <ButtonStyle
                type={isDisplayCounsellingTypeInput ? 'primary' : 'default'}
                onClick={() => {
                  handleClickButton('counsellingType', null);
                  setIsDisplayCounsellingTypeInput(true);
                  setTriggeredInput('counsellingType');
                }}
              >
                {COUNSELLING_TYPE_OTHER}
              </ButtonStyle>
              {isDisplayCounsellingTypeInput && (
                <OtherInputStyle
                  size="large"
                  value={formData.counsellingType}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      counsellingType: e.target.value,
                    }))
                  }
                  ref={counsellingTypeInputRef}
                  placeholder="입력해주세요."
                />
              )}
            </div>
          </FormContainer>
          <FormContainer>
            <FormTitle>구매 의향</FormTitle>
            <div>
              <ButtonStyle
                type={
                  formData.purchaseIntention === COUNSELLING_PURCHASE_PLAN
                    ? 'primary'
                    : 'default'
                }
                onClick={() => {
                  handleClickButton(
                    'purchaseIntention',
                    COUNSELLING_PURCHASE_PLAN
                  );
                  setIsDisplayPurchaseIntentionInput(false);
                }}
              >
                {COUNSELLING_PURCHASE_PLAN}
              </ButtonStyle>
              <ButtonStyle
                type={
                  formData.purchaseIntention ===
                  COUNSELLING_PURCHASE_CONSIDERING
                    ? 'primary'
                    : 'default'
                }
                onClick={() => {
                  handleClickButton(
                    'purchaseIntention',
                    COUNSELLING_PURCHASE_CONSIDERING
                  );
                  setIsDisplayPurchaseIntentionInput(false);
                }}
              >
                {COUNSELLING_PURCHASE_CONSIDERING}
              </ButtonStyle>
              <ButtonStyle
                type={
                  formData.purchaseIntention === COUNSELLING_PURCHASE_HOLD
                    ? 'primary'
                    : 'default'
                }
                onClick={() => {
                  handleClickButton(
                    'purchaseIntention',
                    COUNSELLING_PURCHASE_HOLD
                  );
                  setIsDisplayPurchaseIntentionInput(false);
                }}
              >
                {COUNSELLING_PURCHASE_HOLD}
              </ButtonStyle>
              <ButtonStyle
                type={isDisplayPurchaseIntentionInput ? 'primary' : 'default'}
                onClick={() => {
                  handleClickButton('purchaseIntention', null);
                  setIsDisplayPurchaseIntentionInput(true);
                  setTriggeredInput('purchaseIntention');
                }}
              >
                {COUNSELLING_PURCHASE_OTHER}
              </ButtonStyle>
              {isDisplayPurchaseIntentionInput && (
                <OtherInputStyle
                  size="large"
                  value={formData.purchaseIntention}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      purchaseIntention: e.target.value,
                    }))
                  }
                  ref={purchaseIntentionRef}
                  placeholder="입력해주세요."
                />
              )}
            </div>
          </FormContainer>
          <div style={{ display: 'flex' }}>
            <FormContainer>
              <FormTitle>상담일</FormTitle>
              <DatePickerStyle
                value={dayjs(formData.counsellingDate, 'YYYY/MM/DD')}
                placeholder="상담일을 선택해주세요."
                name="counsellingDate"
                onChange={(e) => handleChangeDatePicker('counsellingDate', e)}
              />
            </FormContainer>
            <FormContainer style={{ marginLeft: '20px' }}>
              <FormTitle>샘플발송</FormTitle>
              <DatePickerStyle
                value={
                  formData.deliveryDate
                    ? dayjs(formData.deliveryDate, 'YYYY/MM/DD')
                    : null
                }
                placeholder="샘플발송일을 선택해주세요."
                name="deliveryDate"
                onChange={(e) => handleChangeDatePicker('deliveryDate', e)}
                allowInvalidValue={false}
              />
            </FormContainer>
          </div>
          <div style={{ display: 'flex' }}>
            <FormContainer>
              <FormTitle>등록 업체명</FormTitle>
              <Input
                style={{ width: '300px' }}
                size="large"
                placeholder="등록 업체명을 입력해주세요."
                name="registrationName"
                onChange={handleChangeInput}
                disabled={pageType === 'create'}
              />
            </FormContainer>
            <FormContainer style={{ marginLeft: '20px' }}>
              <FormTitle>구매일</FormTitle>
              <DatePickerStyle
                value={
                  formData.purchaseDate
                    ? dayjs(formData.purchaseDate, 'YYYY/MM/DD')
                    : null
                }
                placeholder="구매일을 선택해주세요."
                name="purchaseDate"
                onChange={(e) => handleChangeDatePicker('purchaseDate', e)}
                disabled={pageType === 'create'}
              />
            </FormContainer>
          </div>
        </div>
      </div>
      <TextAreaStyle
        placeholder="상담내용을 상세하게 적어주세요. (필수)"
        rows={5}
        name="content"
        onChange={(e) =>
          setFormData((prev) => ({ ...prev, content: e.target.value }))
        }
        value={formData.content}
      />
    </>
  );
};

export default memo(CounsellingFormFields);

CounsellingFormFields.propTypes = {
  pageType: PropTypes.string.isRequired,
  handleCreate: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  savedFormData: PropTypes.instanceOf(Object).isRequired,
};

const FormTitle = styled.div`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const FormContainer = styled.div`
  margin: 30px 0;
`;
const ButtonStyle = styled(Button)`
  border-radius: 36px;
  margin-right: 10px;
  font-weight: bold;
  width: 124px;
  height: 37px;
  font-size: 15px;
`;
const InputStyle = styled(Input)`
  border-radius: 0px;
  font-size: 15px;
  padding: 7px;
`;
const TextAreaStyle = styled(Input.TextArea)`
  margin-top: 12px;
  border-radius: 0px;
  min-height: 412px;
  padding: 30px;
  font-size: 15px;
  margin-bottom: 20px;
`;
const DatePickerStyle = styled(DatePicker)`
  font-size: 15px;
  padding: 7px;
  width: 300px;
`;
const PrefixStyle = styled.div`
  font-size: 17px;
  margin-right: 15px;
  font-weight: bold;
  margin-left: 20px;
`;
const SubmitButtonStyle = styled(Button)`
  font-weight: bold;
  background-color: #efefef;
  border: none;
`;
const OtherInputStyle = styled(Input)`
  border-radius: 20px;
  width: 124px;
  height: 37px;
`;
