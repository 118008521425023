import React, { useState, useEffect } from 'react';

const HantongDashboard = () => {
  const [key, setKey] = useState(Date.now()); // 키값 변경으로 iframe 리렌더링

  useEffect(() => {
    const refreshIframe = () => {
      setKey(Date.now()); // iframe을 강제로 새로 렌더링
    };

    refreshIframe(); // 최초 실행
    const interval = setInterval(refreshIframe, 1000 * 60 * 10); // 10분 마다 새로고침

    return () => clearInterval(interval);
  }, []);

  return (
    <iframe
      src={`https://hantongdashboard.web.app/?t=${key}`}
      title="배송현황판"
      width="100%"
      height="100%"
      frameBorder="0"
    />
  );
};

export default HantongDashboard;
