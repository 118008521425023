import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, notification } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';

const FormStyle = styled(Form)`
  padding: 20px;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
`;
const ButtonStyle = styled(Button)`
  font-weight: 700;
`;
const LabelStyle = styled.p`
  font-weight: 700;
`;

const TableFormModal = (props) => {
  const {
    buttonText,
    onFinish,
    merchandiseCodeCheck,
    productCodeCheck,
    setMerchandiseCodeCheck,
    setProductCodeCheck,
  } = props;
  const { modalInfo } = useSelector((state) => state.modal);
  const { dataInfo } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // 판매상품코드 및 출고상품코드 input
  const [merchandiseCodeInput, setMerchandiseCodeInput] = useState('');
  const [productCodeInput, setProductCodeInput] = useState('');

  // 판매상품코드 validation체크
  const merchandiseCodeCheckHandler = () => {
    dispatch(asyncReadDataFetch({ table: 'merchandise' }))
      .unwrap()
      .then(async (res) => {
        const merchandises = res;
        await merchandises.forEach((merchandise) => {
          if (merchandise.code === merchandiseCodeInput) {
            notification.success({
              message: '확인',
              description: '입력하신 판매상품코드가 존재합니다.',
              placement: 'top',
            });
            return setMerchandiseCodeCheck(true);
          }
        });
      })
      .catch((error) => alert(error));
  };
  // 출고상품코드 validation체크
  const productCodeCheckHandler = () => {
    dispatch(asyncReadDataFetch({ table: 'product' }))
      .unwrap()
      .then(async (res) => {
        const products = res;
        await products.forEach((product) => {
          if (product.code === productCodeInput) {
            notification.success({
              message: '확인',
              description: '입력하신 출고상품코드가 존재합니다.',
              placement: 'top',
            });
            return setProductCodeCheck(true);
          }
        });
      })
      .catch((error) => alert(error));
  };

  // form 타입이 삭제 or 수정 or 추가 인지
  // 삭제일 경우 form을 disabled처리하기 위해
  const formType = modalInfo.modalTitle.slice(-2);
  // form span 객체 값
  const spanCol = useMemo(() => {
    return { span: 24 };
  }, []);

  useEffect(() => {
    // 폼에 넣을 데이터 불러오기
    if (dataInfo.dataId) {
      const values = {
        id: dataInfo.dataId,
        table: dataInfo.dataTable,
      };
      dispatch(asyncReadSpecificDataFetch(values))
        .unwrap()
        .then((res) => {
          // 폼에 데이터 넣기
          form.setFieldsValue({
            ...res[0],
          });
          setMerchandiseCodeInput(res[0].merchandise_code);
          setProductCodeInput(res[0].product_code);
        })
        .catch();
    }
  }, [dataInfo.dataId, dataInfo.dataTable, dispatch, form]);

  return (
    <FormStyle
      form={form}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={(e) => console.log(e)}
      labelCol={spanCol}
      wrapperCol={spanCol}
    >
      <Form.Item
        key="merchandise_code"
        label={<LabelStyle>판매상품코드</LabelStyle>}
        name="merchandise_code"
        rules={[{ required: true, message: '판매상품코드를 입력해주세요.' }]}
      >
        <Input
          size="large"
          disabled={formType === '삭제' || merchandiseCodeCheck}
          placeholder="실제 판매상품코드를 입력해주세요."
          onChange={(e) => setMerchandiseCodeInput(e.target.value)}
          suffix={
            formType !== '삭제' &&
            (merchandiseCodeCheck ? (
              <Button
                onClick={() => setMerchandiseCodeCheck(false)}
                size="small"
                type="link"
                danger
              >
                취소
              </Button>
            ) : (
              <Button
                onClick={merchandiseCodeCheckHandler}
                size="small"
                type="link"
              >
                확인
              </Button>
            ))
          }
        />
      </Form.Item>
      <Form.Item
        key="product_code"
        label={<LabelStyle>출고상품코드</LabelStyle>}
        name="product_code"
        rules={[{ required: true, message: '출고상품코드를 입력해주세요.' }]}
      >
        <Input
          size="large"
          disabled={formType === '삭제' || productCodeCheck}
          placeholder="실제 출고상품코드를 입력해주세요."
          onChange={(e) => setProductCodeInput(e.target.value)}
          suffix={
            formType !== '삭제' &&
            (productCodeCheck ? (
              <Button
                onClick={() => setProductCodeCheck(false)}
                size="small"
                type="link"
                danger
              >
                취소
              </Button>
            ) : (
              <Button
                onClick={productCodeCheckHandler}
                size="small"
                type="link"
              >
                확인
              </Button>
            ))
          }
        />
      </Form.Item>
      <Form.Item
        key="product_count"
        label={<LabelStyle>출고상품개수</LabelStyle>}
        name="product_count"
        rules={[{ required: true, message: '출고상품개수를 입력해주세요.' }]}
      >
        <Input
          type="number"
          size="large"
          disabled={formType === '삭제'}
          placeholder="출고상품개수를 입력해주세요."
        />
      </Form.Item>
      <Form.Item style={{ margin: '0', textAlign: 'right' }}>
        <ButtonStyle
          htmlType="submit"
          type="primary"
          danger={buttonText === '삭제'}
          disabled={
            (buttonText !== '삭제' && !merchandiseCodeCheck) ||
            (buttonText !== '삭제' && !productCodeCheck)
          }
        >
          {buttonText === '추가' && <PlusOutlined />}
          {buttonText === '수정' && <EditOutlined />}
          {buttonText === '삭제' && <DeleteOutlined />}
          {buttonText}
        </ButtonStyle>
      </Form.Item>
    </FormStyle>
  );
};

export default React.memo(TableFormModal);

TableFormModal.propTypes = {
  buttonText: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  merchandiseCodeCheck: PropTypes.bool,
  productCodeCheck: PropTypes.bool,
  setMerchandiseCodeCheck: PropTypes.node,
  setProductCodeCheck: PropTypes.node,
};
