import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Skeleton } from 'antd';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const TotalPriceValue = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getProducts = async () => {
      try {
        await setLoading(true);
        const products = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'order_product',
            eqKey: 'order_id',
            eqValue: id,
          })
        )
          .unwrap()
          .then((res) => res);
        const result = products.reduce((acc, cur) => {
          if (cur.discount_rate && cur.discount_rate > 0) {
            return acc + cur.discounted_vat + cur.discounted_supply;
          } else {
            return acc + cur.product_supply_price + cur.product_VAT_price;
          }
        }, 0);
        await setValue(result);
        setLoading(false);
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    };
    getProducts();
  }, [dispatch, id]);

  return (
    <div>
      {loading ? (
        <Skeleton.Input active style={{ height: '20px' }} />
      ) : (
        <div>{value.toLocaleString()}원</div>
      )}
    </div>
  );
};

export default TotalPriceValue;

TotalPriceValue.propTypes = {
  id: PropTypes.string.isRequired,
};
