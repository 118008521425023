import React from 'react';
import { Empty } from 'antd';

const EmptyBox = () => {
  return (
    <Empty
      description="데이터가 없습니다."
      style={{
        padding: '30px 0',
        backgroundColor: '#efefef',
        margin: '0px',
        color: 'gray',
      }}
    />
  );
};

export default EmptyBox;
