import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  asyncRefreshSessionFetch,
  endRefreshSession,
} from '../redux/modules/auth/refreshSession';
import {
  loggedInRefreshSessionReducer,
  notLoggedInRefreshSessionReducer,
} from '../redux/modules/auth/signinUser';

const useRefreshHook = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const refreshSession = async () => {
      try {
        const sessionInfo = await dispatch(asyncRefreshSessionFetch()).unwrap();
        dispatch(loggedInRefreshSessionReducer(sessionInfo));
      } catch (error) {
        dispatch(notLoggedInRefreshSessionReducer());
      } finally {
        dispatch(endRefreshSession());
      }
    };
    refreshSession();
  }, [dispatch]);
};

export default useRefreshHook;
