import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const TotalEstimateValue = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const [totalEstimate, setTotalEstimate] = useState(0);

  useEffect(() => {
    const getTotalEstimate = async () => {
      try {
        const data = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'order_product',
            eqKey: 'order_id',
            eqValue: id,
          })
        )
          .unwrap()
          .then((res) => res);
        setTotalEstimate(
          data.reduce((acc, cur) => {
            return acc + cur.discounted;
          }, 0)
        );
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };
    getTotalEstimate();
  }, [dispatch, id]);

  return <div>{totalEstimate.toLocaleString()}</div>;
};

export default TotalEstimateValue;

TotalEstimateValue.propTypes = {
  id: PropTypes.string.isRequired,
};
