import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';

const ProductCodeOrName = (props) => {
  const { productId, columnName, setTableDatas } = props;
  const dispatch = useDispatch();

  const [product, setProduct] = useState({
    code: null,
    name: null,
    standard: null,
  });

  useEffect(() => {
    const getProductState = async () => {
      try {
        const productData = await dispatch(
          asyncReadSpecificDataFetch({ table: 'product', id: productId })
        )
          .unwrap()
          .then((res) => res[0]);
        setProduct({
          code: productData.code,
          name: productData.name,
          standard: productData.standard,
        });
      } catch (error) {
        throw new Error(error);
      }
    };
    getProductState();
  }, [dispatch, productId, setTableDatas, columnName]);

  if (columnName === 'productCode') {
    return <div>{product.code}</div>;
  }
  if (columnName === 'productName') {
    return (
      <div>
        {product.name} [{product.standard}]
      </div>
    );
  }
};

export default ProductCodeOrName;

ProductCodeOrName.propTypes = {
  productId: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
  setTableDatas: PropTypes.func,
};
