import React, { forwardRef, useImperativeHandle } from 'react';
import useSmsSearchRanger from '../../hook/useSmsSearchRanger';
import RangePicker from './RangePicker';

const SearchRanger = forwardRef((_, ref) => {
  const { values, handleChangeValue } = useSmsSearchRanger();

  useImperativeHandle(ref, () => ({
    values,
  }));

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <RangePicker values={values} onChange={handleChangeValue} />
    </div>
  );
});

export default SearchRanger;
