import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const ManagerPermission = (props) => {
  const { permission } = props;
  if (permission) {
    return <Tag color="blue">O</Tag>;
  } else {
    return <Tag color="red">X</Tag>;
  }
};

export default ManagerPermission;

ManagerPermission.propTypes = {
  permission: PropTypes.bool.isRequired,
};
