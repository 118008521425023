import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 32px;
`;

function SignupHeader() {
  return (
    <HeaderContainer>
      <div>한통OMS에 이메일로 회원가입</div>
      <Link to="/" style={{ color: 'rgb(0, 101, 255)', marginLeft: '10px' }}>
        로그인
      </Link>
    </HeaderContainer>
  );
}

export default SignupHeader;
