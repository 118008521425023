import { MessageOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import useSmsHistory from '../../hook/useSmsHistory';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import SmsHistoryTable from '../components/SmsHistoryTable';
import SmsSearchForm from '../components/SmsSearchForm';

const SmsHistory = () => {
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <MessageOutlined />,
        breadCrumbNav: ' SMS내역',
      },
    ],
    []
  );

  const { historyData, getHistoryData, loading } = useSmsHistory();

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px' }}>
        <div
          style={{
            fontSize: '16px',
            width: '100%',
            paddingBottom: '10px',
            borderBottom: '1px solid gray',
          }}
        >
          메시지 검색
        </div>
        <SmsSearchForm getHistoryData={getHistoryData} />
        <div
          style={{
            marginTop: '50px',
            fontSize: '16px',
            width: '100%',
            paddingBottom: '10px',
            borderBottom: '1px solid gray',
          }}
        >
          메시지 내역
        </div>
        <SmsHistoryTable historyData={historyData} loading={loading} />
      </div>
    </LayoutComponent>
  );
};

export default SmsHistory;
