import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

const SearchResult = (props) => {
  const { dlvyData, geocode, loading } = props;

  useEffect(() => {
    const { departure, destination } = geocode;
    // 예시로 두 지점의 값을 미리 정의합니다.
    const location1 = { lat: departure.y, lng: departure.x };
    const location2 = { lat: destination.y, lng: destination.x };

    // 위도와 경도 값을 받아서 지도와 마커를 생성하고 선을 그림
    const container = document.getElementById('map');

    const options = {
      center: new window.kakao.maps.LatLng(location1.lat, location1.lng),
      level: 12,
    };
    const map = new window.kakao.maps.Map(container, options);

    // 마커 생성
    const marker1 = new window.kakao.maps.Marker({
      position: new window.kakao.maps.LatLng(location1.lat, location1.lng),
    });
    const marker2 = new window.kakao.maps.Marker({
      position: new window.kakao.maps.LatLng(location2.lat, location2.lng),
    });

    // 선 그리기
    const linePath = [
      new window.kakao.maps.LatLng(location1.lat, location1.lng),
      new window.kakao.maps.LatLng(location2.lat, location2.lng),
    ];
    const line = new window.kakao.maps.Polyline({
      path: linePath,
      strokeWeight: 5,
      strokeColor: '#FF0000',
      strokeOpacity: 1,
      strokeStyle: 'solid',
    });

    // 마커와 선 지도에 추가
    marker1.setMap(map);
    marker2.setMap(map);
    line.setMap(map);
  }, [geocode]);

  return (
    <div style={{ width: '100%', height: '750px', position: 'relative' }}>
      <div id="map" style={{ width: '100%', height: '100%' }} />
      <div
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          zIndex: '1',
          padding: '30px',
          backgroundColor: 'black',
          opacity: '0.5',
          color: 'white',
          fontWeight: '600',
          fontSize: '18px',
        }}
      >
        <div style={{ marginBottom: '5px' }}>
          거리: {dlvyData.distance.toLocaleString()}km
        </div>
        <div>요금: {dlvyData.dlvyPrice.toLocaleString()}원</div>
      </div>
      {loading && (
        <div
          style={{
            width: '100%',
            height: '100%',
            zIndex: '2',
            position: 'absolute',
            top: '0',
            backgroundColor: 'black',
            opacity: '0.5',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default SearchResult;

SearchResult.propTypes = {
  dlvyData: PropTypes.instanceOf(Object).isRequired,
  geocode: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
};
