import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const useSmsSearchRanger = () => {
  const [values, setValues] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  });

  useEffect(() => {
    const startDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
    const startTime = dayjs().format('HH:mm:ss');
    const endDate = dayjs().format('YYYY-MM-DD');
    const endTime = dayjs().format('HH:mm:ss');

    setValues({
      startDate,
      startTime,
      endDate,
      endTime,
    });
  }, []);

  const handleChangeValue = (name, newValue) => {
    setValues((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  return { values, handleChangeValue };
};

export default useSmsSearchRanger;
