import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Tag } from 'antd';
import { useSelector } from 'react-redux';
import {
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  LIGHT_MODE_BACKGROUND,
  LIGHT_MODE_FONT_COLOR,
} from '../../../constants';

const BreadCrumbComponent = (props) => {
  const { breadCrumbs } = props;
  const { darkMode } = useSelector((state) => state.darkMode);

  return (
    <Breadcrumb style={{ margin: '12px 0' }}>
      <Breadcrumb.Item>
        <Tag
          style={{
            color: darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR,
            backgroundColor: darkMode
              ? DARK_MODE_BACKGROUND
              : LIGHT_MODE_BACKGROUND,
          }}
        >
          한통 OMS
        </Tag>
      </Breadcrumb.Item>
      {breadCrumbs.map((breadCrumb, index) => {
        return (
          <Breadcrumb.Item key={index}>
            <Tag
              style={{
                color: darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR,
                backgroundColor: darkMode
                  ? DARK_MODE_BACKGROUND
                  : LIGHT_MODE_BACKGROUND,
              }}
            >
              {breadCrumb.breadCrumbIcon}
              {breadCrumb.breadCrumbNav}
            </Tag>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default BreadCrumbComponent;

BreadCrumbComponent.propTypes = {
  breadCrumbs: PropTypes.instanceOf(Array),
};
