import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const ProductQuantityValue = (props) => {
  const { value, id, setProductDetails, formType } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [savedProductIdAndQtys, setSavedProductIdAndQtys] = useState([]);

  const handleQuantity = () => {
    setProductDetails((prev) =>
      prev.map((product) => {
        if (product.productId === id) {
          return { ...product, productQuantity: Number(inputValue) };
        } else {
          return product;
        }
      })
    );
    setIsEdit(false);
  };

  const checkIsPurchaseRegPage = useCallback(() => {
    return history.location.pathname.includes('purchase-order-id');
  }, [history]);

  const handleInput = (e, productId) => {
    const newQuantity = e.target.value;
    const isPurchaseRegPage = checkIsPurchaseRegPage();
    if (isPurchaseRegPage) {
      const savedQuantity = savedProductIdAndQtys.filter(
        (item) => item.productId === productId
      )[0].quantity;
      if (savedQuantity < newQuantity) {
        alert('발주서에 등록된 수량보다 많이 입력할 수 없습니다.');
      } else {
        setInputValue(newQuantity);
      }
    } else {
      setInputValue(newQuantity);
    }
  };

  useEffect(() => {
    const isPurchaseRegPage = checkIsPurchaseRegPage();
    if (!isPurchaseRegPage) return;
    const matches = history.location.pathname.match(/\d+/);
    const purchaseOrderId = Number(matches[0]);
    const getSavedIdAndQuantityDatas = async () => {
      try {
        const result = await dispatch(
          asyncReadFilteredDataFetch({
            table: 'purchase_order_product',
            eqKey: 'purchase_order_id',
            eqValue: purchaseOrderId,
          })
        )
          .unwrap()
          .then((res) =>
            res.map((item) => ({
              quantity: item.product_quantity,
              productId: item.product_id,
            }))
          );
        setSavedProductIdAndQtys(result);
      } catch (error) {
        console.log(error);
        alert(error);
      }
    };
    getSavedIdAndQuantityDatas();
  }, [history, dispatch, checkIsPurchaseRegPage]);

  return (
    <div>
      {isEdit ? (
        <div>
          <Input
            type="number"
            bordered={false}
            suffix={
              <Button size="small" type="primary" onClick={handleQuantity}>
                <SaveOutlined />
              </Button>
            }
            style={{
              borderBottom: '1px solid lightgray',
              borderRadius: '0px',
            }}
            onChange={(e) => handleInput(e, id)}
            value={inputValue}
          />
        </div>
      ) : (
        <Button type="link" onClick={() => setIsEdit(true)}>
          {value.toLocaleString()}
        </Button>
      )}
    </div>
  );
};

export default ProductQuantityValue;

ProductQuantityValue.propTypes = {
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  setProductDetails: PropTypes.func.isRequired,
  formType: PropTypes.string.isRequired,
};
