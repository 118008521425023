import { useDispatch } from 'react-redux';
import { openModal, closeModal } from '../redux/modules/modal';

// 모달에 쓰일 내용을 인자로 받음.
const useModal = () => {
  const dispatch = useDispatch();

  const handleOpenModal = ({ type, modalInfo, dataInfo }) => {
    dispatch(openModal({ type, modalInfo, dataInfo }));
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return { openModal: handleOpenModal, closeModal: handleCloseModal };
};

export default useModal;
