import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider, DatePicker, Descriptions, Select } from 'antd';
import dayjs from 'dayjs';
import { FileExcelOutlined, SearchOutlined } from '@ant-design/icons';

// datepicker 한글화
import 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ko';
import locale from 'antd/lib/locale/ko_KR';

import { getAllAccountsApi } from '../../apis/accountApi';
import { useInvoiceContext } from '../../contexts/InvoiceContext';

const commonItemProps = (label) => ({
  label,
  labelStyle: { fontSize: '15px', width: '10%' },
  contentStyle: { width: '90%' },
  span: 3,
});

const InvoiceSearch = () => {
  const { generateInvoices, generateAccountInfo } = useInvoiceContext();

  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });
  const [accountOptions, setAccountOptions] = useState([]);
  const [accountCode, setAccountCode] = useState(null);

  useEffect(() => {
    const fetchAccounts = async () => {
      const fetchedAccounts = await getAllAccountsApi();
      return fetchedAccounts;
    };
    const getAccountOptions = async () => {
      const fetchedAccounts = await fetchAccounts();
      const formattedAccounts = fetchedAccounts.map((account) => ({
        id: account.id,
        label: `${account.code} / ${account.name}`,
        value: account.code,
      }));
      setAccountOptions(formattedAccounts);
    };
    getAccountOptions();
  }, []);

  const generateDateRange = (newDateRange) => {
    const formattedNewDateRange = newDateRange.map((date) =>
      dayjs(date).format('YYYY-MM-DD')
    );
    const [start, end] = formattedNewDateRange;
    setDateRange({ start, end });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    generateInvoices(accountCode, dateRange);
    generateAccountInfo(accountCode);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Descriptions bordered>
        <Descriptions.Item {...commonItemProps('기준일자')}>
          <ConfigProvider locale={locale}>
            <DatePicker.RangePicker
              style={{ width: '100%', borderRadius: '0px' }}
              onCalendarChange={(newDateRange) =>
                generateDateRange(newDateRange)
              }
            />
          </ConfigProvider>
        </Descriptions.Item>
        <Descriptions.Item
          {...commonItemProps('거래처')}
          className="select-container"
        >
          <Select
            style={{ width: '100%', borderRadius: '0px' }}
            placeholder="거래처를 선택해주세요."
            options={accountOptions}
            onChange={(accountCode) => setAccountCode(accountCode)}
            showSearch
            filterOption={(input, option) => {
              return option.label && option.label.includes(input);
            }}
            disabled={!dateRange.start || !dateRange.end}
          />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('검색')}>
          <button
            type="submit"
            disabled={!accountCode || !dateRange.start || !dateRange.end}
          >
            <SearchOutlined />
            검색
          </button>
        </Descriptions.Item>
      </Descriptions>
    </form>
  );
};

export default InvoiceSearch;
