import React from 'react';

const AuthFooter = () => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: '10px',
        fontSize: '12px',
        color: 'gray',
      }}
    >
      @2025 Hantong-OMS Admin Web.
    </div>
  );
};

export default AuthFooter;
