import React, { useContext, createContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { dateTypes } from '../../constants';
import genBeginAndEndDate from '../../utils/genBeginAndEndDate';

const dateRangeContext = createContext({
  handleClickDateRangeButton: () => {},
});

const DateRangeButtonMain = ({
  handleDateRange,
  children,
  formData,
  optionData,
  getReceiptsData,
}) => {
  const {
    genTodayDate,
    genYesterdayDate,
    genThisWeekDate,
    genLastWeekDate,
    genThisMonthDate,
    genLastMonthDate,
  } = genBeginAndEndDate();

  const handleClickDateRangeButton = useCallback(
    (dateType) => {
      let dateRange;
      switch (dateType) {
        case dateTypes.today:
          dateRange = genTodayDate();
          break;
        case dateTypes.yesterday:
          dateRange = genYesterdayDate();
          break;
        case dateTypes.this_week:
          dateRange = genThisWeekDate();
          break;
        case dateTypes.one_week_ago:
          dateRange = genLastWeekDate();
          break;
        case dateTypes.this_month:
          dateRange = genThisMonthDate();
          break;
        case dateTypes.one_month_ago:
          dateRange = genLastMonthDate();
          break;
        case dateTypes.one_month_ago_and_this_month:
          dateRange = {
            begin: genLastMonthDate().begin,
            end: genThisMonthDate().end,
          };
          break;
        default:
          break;
      }
      const { begin: startDate, end: endDate } = dateRange;
      handleDateRange({ startDate, endDate });
      getReceiptsData({
        formData,
        optionData,
        dateRange: { startDate, endDate },
      });
    },
    [
      handleDateRange,
      genTodayDate,
      genYesterdayDate,
      genThisWeekDate,
      genLastWeekDate,
      genThisMonthDate,
      genLastMonthDate,
      getReceiptsData,
    ]
  );

  const value = useMemo(
    () => ({ handleClickDateRangeButton }),
    [handleClickDateRangeButton]
  );

  return (
    <dateRangeContext.Provider value={value}>
      <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        {children}
      </div>
    </dateRangeContext.Provider>
  );
};
export const useDateRangeButtonContext = () => useContext(dateRangeContext);
export default DateRangeButtonMain;

DateRangeButtonMain.propTypes = {
  children: PropTypes.node.isRequired,
  handleDateRange: PropTypes.func.isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
  optionData: PropTypes.instanceOf(Object).isRequired,
  getReceiptsData: PropTypes.func.isRequired,
};
