import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';

const useSelectRange = (initializeData, localStorageKey) => {
  const [selectedRange, setSelectedRange] = useState({
    start: null,
    end: null,
  });
  const [isSelected, setIsSelected] = useState(false);

  const [localStorageValue, setLocalStorageValue] = useState({
    start: null,
    end: null,
  });

  const initialize = () => {
    setIsSelected(false);
    setSelectedRange({
      start: null,
      end: null,
    });
    initializeData(false);
    localStorage.removeItem(localStorageKey);
  };

  const selectRange = (date) => {
    const { start, end } = selectedRange;

    if (!start && !end) {
      setSelectedRange((prev) => ({ ...prev, start: date }));
      return;
    }

    const formattedDate = dayjs(date);
    const formattedStart = dayjs(start);

    setSelectedRange((prev) => {
      const isSelectedDateBiggerThanStart = formattedStart <= formattedDate;
      let newRange;

      if (isSelectedDateBiggerThanStart) {
        newRange = { start: prev.start, end: date };
      } else {
        newRange = { start: date, end: prev.start };
      }

      setLocalStorageValue(newRange);
      return newRange;
    });

    setIsSelected(true);
  };

  const getStoredRange = useCallback((localStorageKey) => {
    const localStorageValue = localStorage.getItem(localStorageKey);

    if (!localStorageValue) {
      return;
    }

    const [start, end] = localStorageValue.split('/');

    const newRange = { start, end };

    setLocalStorageValue(newRange);
    setSelectedRange(newRange);
    setIsSelected(true);
  }, []);

  useEffect(() => {
    getStoredRange(localStorageKey);
  }, [getStoredRange]);

  useEffect(() => {
    if (localStorageValue.start && localStorageValue.end) {
      localStorage.setItem(
        localStorageKey,
        `${localStorageValue.start}/${localStorageValue.end}`
      );
    }
  }, [localStorageValue, localStorageKey]);

  return [selectedRange, isSelected, selectRange, initialize];
};

export default useSelectRange;
