import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SaleForm from './SaleForm';

const SaleFormFinish = (props) => {
  const {
    pageType,
    saleItems,
    saleProductItems,
    saleData,
    productDatas,
    saleProductDatas,
  } = props;

  return (
    <SaleForm
      pageType={pageType}
      saleItems={saleItems}
      saleProductItems={saleProductItems}
      saleData={saleData}
      productDatas={productDatas}
      saleProductDatas={saleProductDatas}
    />
  );
};

export default React.memo(SaleFormFinish);

SaleFormFinish.propTypes = {
  pageType: PropTypes.string.isRequired,
  saleItems: PropTypes.instanceOf(Array).isRequired,
  saleProductItems: PropTypes.instanceOf(Array).isRequired,
  saleData: PropTypes.instanceOf(Object).isRequired,
  productDatas: PropTypes.instanceOf(Array).isRequired,
  saleProductDatas: PropTypes.instanceOf(Array).isRequired,
};
