import React from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { Button, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const DraggableCard = (props) => {
  const { id, card, moveCard, findCard, deleteSortOrder, cardIdx } = props;

  const originalIndex = findCard(id).index;

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: 'CARD',
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [originalIndex]
  );
  const [, dropRef] = useDrop(
    () => ({
      accept: 'CARD',
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );

  return (
    <Button
      size="large"
      style={{
        margin: '5px',
        borderRadius: '20px',
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
      }}
      ref={(node) => dragRef(dropRef(node))}
      type={isDragging && 'primary'}
    >
      <div
        style={{
          marginRight: '5px',
          borderRadius: '50px',
          backgroundColor: '#001d66',
          width: '30px',
          color: 'white',
          height: '30px',
          position: 'absolute',
          left: '3px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {cardIdx + 1}
      </div>
      <div style={{ marginLeft: '35px', marginRight: '20px' }}>
        {card.sale_name}
      </div>
      <Popconfirm
        onConfirm={() => deleteSortOrder(card.sale_code)}
        title="계산기에서 삭제하시겠습니까?"
        cancelText="취소"
        okText="삭제"
      >
        <Button
          size="small"
          type="link"
          danger
          style={{
            position: 'absolute',
            right: '0px',
            top: '6px',
          }}
        >
          <CloseOutlined />
        </Button>
      </Popconfirm>
    </Button>
  );
};

export default DraggableCard;

DraggableCard.propTypes = {
  card: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.number.isRequired,
  moveCard: PropTypes.func.isRequired,
  findCard: PropTypes.func.isRequired,
  deleteSortOrder: PropTypes.func.isRequired,
  cardIdx: PropTypes.number.isRequired,
};
