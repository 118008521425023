import React from 'react';
import PropTypes from 'prop-types';
import ProductInput from './ProductInput';
import ProductTable from './ProductTable';

const ProductDetails = (props) => {
  const { formType, productDetails, setProductDetails, pageType } = props;
  return (
    <div>
      <div
        style={{
          marginTop: '40px',
          paddingBottom: '10px',
          borderBottom: '1px solid gray',
          fontSize: '16px',
        }}
      >
        {formType === 'purchaseOrder' ? '발주-품목 정보' : '구매-품목 정보'}
      </div>
      <ProductInput
        productDetails={productDetails}
        setProductDetails={setProductDetails}
        pageType={pageType}
        formType={formType}
      />
      <ProductTable
        productDetails={productDetails}
        setProductDetails={setProductDetails}
        pageType={pageType}
        formType={formType}
      />
    </div>
  );
};

export default ProductDetails;

ProductDetails.propTypes = {
  formType: PropTypes.string.isRequired,
  productDetails: PropTypes.instanceOf(Array).isRequired,
  setProductDetails: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
};
