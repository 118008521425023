import React, { useEffect, useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';

const TotalColumnValue = (props) => {
  const { id, importIdValue } = props;
  const dispatch = useDispatch();

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const getAccountName = async () => {
      try {
        const productIdAndQtyArr = await dispatch(
          asyncReadFilteredDataFetch({
            table:
              importIdValue === 'purchaseId'
                ? 'purchase_product'
                : 'purchase_order_product',
            eqKey:
              importIdValue === 'purchaseId'
                ? 'purchase_id'
                : 'purchase_order_id',
            eqValue: id,
          })
        )
          .unwrap()
          .then((res) =>
            res.map((pd) => ({
              productId: pd.product_id,
              productQuantity: pd.product_quantity,
            }))
          );
        const productPriceArr = await Promise.all(
          productIdAndQtyArr.map(async (data) => {
            const productPrice = await dispatch(
              asyncReadSpecificDataFetch({
                table: 'product',
                id: data.productId,
              })
            )
              .unwrap()
              .then((res) => res[0].incoming_price * data.productQuantity);
            return productPrice;
          })
        );
        const productTotalPrice = productPriceArr.reduce(
          (acc, cur) => acc + cur,
          0
        );
        setTotalPrice(productTotalPrice);
      } catch (error) {
        throw new Error();
      }
    };
    getAccountName();
  }, [dispatch, id, importIdValue]);

  return <div>{totalPrice.toLocaleString()}</div>;
};

export default memo(TotalColumnValue);

TotalColumnValue.propTypes = {
  id: PropTypes.number.isRequired,
  importIdValue: PropTypes.string.isRequired,
};
