import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import TableModal from './TableModal';
import useModal from '../../../hook/useModal';
import { asyncUpdateDataFetch } from '../../../redux/modules/shared/updateData';

const UpdateTable = (props) => {
  const { items } = props;
  const dispatch = useDispatch();
  const { dataInfo, modalInfo } = useSelector((state) => state.modal);
  const { openModal, closeModal } = useModal();

  // disabled 처리
  useEffect(() => {
    items.map((item) => {
      return {
        ...item,
        disabled: true,
      };
    });
  }, [items]);

  const onFinish = useCallback(
    (values) => {
      const datas = {
        id: dataInfo.dataId,
        table: dataInfo.dataTable,
        state: 1,
        ...values,
      };
      // 해당 row를 업데이트하는 dispatch
      dispatch(asyncUpdateDataFetch(datas))
        .unwrap()
        .then(() => {
          closeModal();
          // result 모달을 띄움.
          openModal({
            type: 'result',
            modalInfo: {
              modalTitle: modalInfo.modalTitle,
              resultStatus: 'success',
              resultTitle: modalInfo.modalTitle,
              resultSubTitle: `데이터 ${modalInfo.modalTitle} 작업을 완료 했습니다.`,
              buttonText: '확인',
            },
          });
        })
        .catch((err) => {
          alert(err.messege);
          console.log(err);
        });
    },
    [
      closeModal,
      openModal,
      dataInfo.dataId,
      dataInfo.dataTable,
      dispatch,
      modalInfo.modalTitle,
    ]
  );

  return <TableModal items={items} buttonText="수정" onFinish={onFinish} />;
};

export default React.memo(UpdateTable);

UpdateTable.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};
