import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import DescriptionData from './DescriptionData';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const Description = (props) => {
  const { id, table, pageType } = props;
  const dispatch = useDispatch();

  // (수정 or 삭제시 필요) form 데이터
  const [datas, setDatas] = useState({});
  // 거래처그룹 데이터
  const [accountGroups, setAccountGroups] = useState([]);
  // 주소지 데이터
  const [addressDatas, setAddressDatas] = useState([]);
  // 주소지 로딩
  const [addressLoading, setAddressLoading] = useState(false);
  // 담당자 데이터
  const [respDatas, setRespDatas] = useState([]);

  useEffect(() => {
    // form에서 선택할수 있도록 거래처 그룹 data 불러오기
    dispatch(asyncReadDataFetch({ table: 'account_group' }))
      .unwrap()
      .then((res) => {
        // 매출거래처 -> 샘플발송 순으로 데이터정렬
        const groups = res;
        const sortedArr = [];
        const deleteIndex = [];
        for (let i = 0; i < groups.length; i += 1) {
          if (groups[i].name === '매출거래처') {
            sortedArr.push(groups[i]);
            deleteIndex.push(i);
          }
        }
        for (let i = 0; i < groups.length; i += 1) {
          if (groups[i].name === '샘플발송') {
            sortedArr.push(groups[i]);
            deleteIndex.push(i);
          }
        }
        for (const index of deleteIndex) {
          groups.splice(index, 1);
        }
        sortedArr.push(...groups);
        // 거래처그룹 폼 형식으로 변환
        const modifiedDatas = sortedArr.map((data) => {
          return { label: data.name, value: data.code };
        });
        setAccountGroups([...modifiedDatas]);
      })
      .catch((err) => alert(err.message));
    // 거래처 추가일 경우 form의 default value (detail data)들이 필요 없으므로 return
    if (id === undefined) {
      return;
    }
    const values = {
      id,
      table,
    };
    // pageType이 update 또는 detail 일 때, 해당 id의 detail data 불러오기
    if (pageType === 'detail' || pageType === 'update') {
      const asyncFunc = async () => {
        await setAddressLoading(true);
        await dispatch(asyncReadSpecificDataFetch(values))
          .unwrap()
          .then((account) => {
            // form에서 보이도록 거래처 그룹에 저장된 id를 거래처 그룹 name으로 바꿔야됨.
            setDatas({ ...account[0] });
            // 거래처주소지 (account_address) 테이블 데이터 가져오기
            dispatch(
              asyncReadFilteredDataFetch({
                table: 'account_address',
                eqKey: 'account_code',
                eqValue: account[0].code,
              })
            )
              .unwrap()
              .then((address) => {
                const resAddressDatas = address.map((data) => {
                  return {
                    key: uuid(),
                    name: data.name,
                    postCode: data.post_code,
                    address: data.address,
                    isRepresentative: data.is_representative,
                  };
                });
                setAddressDatas([...resAddressDatas]);
              })
              .catch((err) => {
                alert(err.message);
                console.log(err);
              });
            // 거래처담당자 (account_resp) 테이블 데이터 가져오기
            dispatch(
              asyncReadFilteredDataFetch({
                table: 'account_resp',
                eqKey: 'account_code',
                eqValue: account[0].code,
              })
            )
              .unwrap()
              .then((resp) => {
                const resRespDatas = resp.map((data) => {
                  return {
                    key: uuid(),
                    name: data.name,
                    phone: data.phone,
                    email: data.email,
                    isRepresentative: data.is_representative,
                  };
                });
                setRespDatas([...resRespDatas]);
              })
              .catch((err) => {
                alert(err.message);
                console.log(err);
              });
          })
          .catch((err) => {
            alert(err.message);
            console.log(err);
          });
        setAddressLoading(false);
        return;
      };
      asyncFunc();
    }
  }, [dispatch, id, table, pageType]);

  return (
    <DescriptionData
      datas={datas}
      setDatas={setDatas}
      accountGroups={accountGroups}
      id={id}
      pageType={pageType}
      addressDatas={addressDatas}
      setAddressDatas={setAddressDatas}
      addressLoading={addressLoading}
      respDatas={respDatas}
      setRespDatas={setRespDatas}
    />
  );
};

export default Description;

Description.propTypes = {
  id: PropTypes.string,
  table: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
};
