import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { AuditOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Popconfirm } from 'antd';
import dayjs from 'dayjs';
import { asyncUpdateDataFetch } from '../../redux/modules/shared/updateData';
import ecount from '../../ecount';
import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Table from '../../shared/table/Table';
import ProductValue from '../components/ProductValue';
import AdminValue from '../components/AdminValue';
import AccountValue from '../components/AccountValue';
import sms from '../../sms';
import TotalPriceValue from '../components/TotalPriceValue';
import DateRangePicker from '../components/DateRangePicker';
import useDateRangeFromLocalStorage from '../../hook/useDateRangeFromLocalStorage';
import useChangeInventoryData from '../../hook/useChangeInventoryData';
import useInventoryNotification from '../../hook/useInventoryNotification';
import useSupabase from '../../serverConfig';

const Order = () => {
  const dispatch = useDispatch();
  const { sendAllinda } = sms();
  const { saveSalesInquiry } = ecount();
  const { abstractInventoryBySales } = useChangeInventoryData();
  const { alertNotification } = useInventoryNotification();

  const [dateRange, setDateRange] = useState({
    start: dayjs(),
    end: dayjs(),
    type: 'today',
  });

  // localStorage에서 설정된 dateRange정보가 저장되어 있으면 가져옴
  useDateRangeFromLocalStorage(setDateRange);

  // breadCrumb 데이터 생성
  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <AuditOutlined />,
        breadCrumbNav: ' 주문',
      },
    ],
    []
  );

  // 불러올 table이 뭔지
  const table = 'order';

  // TableHeader에 들어갈 create 버튼 텍스트
  const createButtonText = '주문서 추가';

  // table header에 들어갈 create 버튼의 Modal객체 (Modal 속성으로 넣어줄 객체)
  const createButtonModal = {
    type: 'order',
    modalInfo: {
      modalTitle: '주문서 추가',
      resultStatus: '',
      resultTitle: '',
      resultSubTitle: '',
      buttonText: '',
    },
    dataInfo: {
      dataId: '',
      dataTable: 'order',
      datas: {},
    },
  };

  // 판매입력 (payment_status를 "1"로 변경)
  const updatePaymentStatus = useCallback(
    (id) => {
      dispatch(
        asyncUpdateDataFetch({
          id,
          table: 'order',
          payment_status: '1',
          sales_date: useSupabase
            ? dayjs()
            : dayjs().format('YYYY-MM-DD HH:mm:ss'),
        })
      )
        .unwrap()
        .then((res) => console.log(res))
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    },
    [dispatch]
  );

  // 재고 변경 알림 처리
  const handleAlertNotification = (abstractedInventoryDatas) => {
    abstractedInventoryDatas.then((datas) => {
      alertNotification({
        addedInventoryDatas: null,
        abstractedInventoryDatas: datas,
      });
    });
  };

  const handlePaymentStatus = useCallback(
    (id, values) => {
      // 판매 입력
      updatePaymentStatus(id);
      // 재고 감소
      const abstractedInventoryDatas = abstractInventoryBySales(id);
      handleAlertNotification(abstractedInventoryDatas);
      // ecount 연동
      saveSalesInquiry(values);
      // 스토어일 경우 입금확인 sms 안보냄
      if (values.payment_terms === '스토어') {
        return;
      }
      // 입금확인 sms 전송
      const asyncSendSms = async () => {
        // 전화번호
        let mobile = values.sms_phone;
        if ((mobile && mobile.length === 0) || !mobile) {
          mobile = window.prompt(
            '입금확인 sms를 전송하기 위한 전화번호가 주문서에 입력되지 않았습니다. 전화번호를 입력해주세요. (숫자만 입력)'
          );
        }
        if ((mobile && mobile.length === 0) || !mobile) {
          alert('전화번호를 입력하지 않아 sms가 전송되지 않습니다.');
          return;
        }
        const formattedMobile = mobile.replace(/-/g, '');
        // sms 내용
        let confirm = '';
        if (
          values.payment_terms === '법인카드' ||
          values.payment_terms === '신용카드'
        ) {
          confirm = `
결제가 확인되었습니다. 주문해주셔서 감사합니다.
\n한통도시락은 제품의 특성상 택배배송이 평일기준 3~5일 소요됩니다.
`;
        } else {
          confirm = `
입금이 확인되었습니다. 주문해주셔서 감사합니다.
\n한통도시락은 제품의 특성상 택배배송이 평일기준 3~5일 소요됩니다.
`;
        }
        // sms 전송
        const mobileArr = [{ phoneNumber: formattedMobile }];
        sendAllinda({
          recipients: mobileArr,
          contents: confirm,
        });
      };
      asyncSendSms();
    },
    [saveSalesInquiry, dispatch, sendAllinda, abstractInventoryBySales]
  );

  const handlePaymentStatusByCard = useCallback(
    (id, values) => {
      // 판매 입력
      updatePaymentStatus(id);
      // 재고 감소
      abstractInventoryBySales(id);
      // ecount 연동
      saveSalesInquiry(values);
    },
    [updatePaymentStatus, abstractInventoryBySales, saveSalesInquiry]
  );

  // table에 넣을 columns. table View를 위한 배열.
  const orderTableColumns = useMemo(
    () => [
      {
        title: '주문일',
        dataIndex: 'order_date',
        render: (data) => <div>{data}</div>,
        width: '14%',
        align: 'center',
      },
      {
        title: '담당자',
        dataIndex: 'admin_code',
        key: 'admin_code',
        render: (data) => {
          return <AdminValue adminCode={data} />;
        },
        width: '14%',
        align: 'center',
      },
      {
        title: '거래처',
        dataIndex: 'account_code',
        key: 'account',
        render: (data) => <AccountValue accountCode={data} />,
        width: '14%',
        align: 'center',
      },
      {
        title: '출고상품(품목)',
        key: 'product_sale',
        render: (data) => <ProductValue data={data} id={data.id} />,
        width: '15%',
        align: 'center',
      },
      {
        title: '금액',
        key: 'total_price',
        render: (data) => <TotalPriceValue data={data} id={data.id} />,
        width: '15%',
        align: 'center',
      },
      {
        title: '결제수단',
        dataIndex: 'payment_terms',
        key: 'payment_terms',
        width: '14%',
        align: 'center',
      },
      {
        title: '결제상태',
        dataIndex: 'payment_status',
        key: 'payment_status',
        render: (paymentStatus, record) => {
          const isCardPaymentSuccessed =
            !!record.card_payment_date &&
            record.payment_terms === '신용카드' &&
            paymentStatus === '0';
          if (isCardPaymentSuccessed) {
            return (
              <Popconfirm
                title={
                  <div>
                    <h4>판매 입력하시겠습니까?</h4>
                    <div style={{ marginTop: '10px' }}>
                      <p>결제가 완료된 주문건 입니다.</p>
                    </div>
                  </div>
                }
                okText="네"
                cancelText="아니요"
                onConfirm={() => handlePaymentStatusByCard(record.id, record)}
              >
                <Link to>결제 완료(카드)</Link>
              </Popconfirm>
            );
          }
          if (paymentStatus === '0') {
            return (
              <Popconfirm
                title={
                  <div>
                    <h4>입금이 확인되었습니까?</h4>
                    <div style={{ marginTop: '10px' }}>
                      <p>입금확인 SMS를 전송합니다.</p>
                      <p>*스토어 제외</p>
                    </div>
                  </div>
                }
                okText="네"
                cancelText="아니요"
                onConfirm={() => handlePaymentStatus(record.id, record)}
              >
                <Link to>결제 미확인</Link>
              </Popconfirm>
            );
          }
        },
        width: '14%',
        align: 'center',
      },
    ],
    [handlePaymentStatus]
  );

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          backgroundColor: '#f7f7f7',
          alignItems: 'center',
          paddingBottom: '15px',
        }}
      >
        <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
      </div>
      <Table
        tableColumns={orderTableColumns}
        table={table}
        createButtonText={createButtonText}
        createButtonModal={createButtonModal}
        items={orderTableColumns}
        dateRange={dateRange}
      />
      <div
        style={{
          position: 'absolute',
          bottom: '9px',
          right: '15px',
        }}
      />
    </LayoutComponent>
  );
};

export default Order;
