import {
  ArrowRightOutlined,
  InfoCircleOutlined,
  InfoOutlined,
} from '@ant-design/icons';
import { Alert, Button } from 'antd';
import React, { useState } from 'react';

const AlertShortenedWhName = () => {
  const [isShowDetail, setIsShowDetail] = useState(false);
  return (
    <Alert
      style={{
        borderRadius: '20px',
        padding: '15px',
        marginTop: '10px',
      }}
      message="1. 배송비(창고)의 내용을 축약하여 출력합니다. 2. 택배비를 필터링하여 출력합니다."
      description={
        isShowDetail && (
          <div>
            <ul>
              <li>
                배송비(창고)코드 00004 <ArrowRightOutlined /> 준테크
              </li>
              <li>
                배송비(창고)코드 00009 <ArrowRightOutlined /> 고려
              </li>
              <li>
                배송비(창고)코드 00011 <ArrowRightOutlined /> 건영
              </li>
              <li>품목명에 택배비가 포함될 시 필터링</li>
            </ul>
            <p style={{ marginTop: '10px' }}>
              ***창고의 코드가 변경되거나 추가적으로 축약할 내용이 있을시
              개발팀에 문의***
            </p>
          </div>
        )
      }
      type="info"
      showIcon
      action={
        isShowDetail ? (
          <Button
            size="small"
            type="link"
            onClick={() => setIsShowDetail(false)}
          >
            Close
          </Button>
        ) : (
          <Button
            size="small"
            type="link"
            onClick={() => setIsShowDetail(true)}
          >
            Detail
          </Button>
        )
      }
    />
  );
};

export default AlertShortenedWhName;
