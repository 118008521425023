import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import {
  Descriptions,
  Form,
  Button,
  Select,
  Table,
  Input,
  Checkbox,
  Spin,
  Popover,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Option } from 'antd/es/mentions';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import OrderSaleDelivery from './OrderSaleDelivery';

const OrderSaleForm = forwardRef((props, ref) => {
  const {
    pageType,
    orderSaleItems,
    orderSaleDatas,
    setOrderSaleDatas,
    createDataTrigger,
    deleteDataTrigger,
    quantityDataTrigger,
    orderProductDatas,
    setOrderProductDatas,
  } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [saleOptions, setSaleOptions] = useState([]);
  const [selectedSaleOption, setSelectedSaleOption] = useState('');

  const [checkedSaleDatas, setCheckedSaleDatas] = useState([]);

  const [orderLoading, setOrderLoading] = useState(false);

  useEffect(() => {
    // 판매상품 select-search form 생성
    dispatch(asyncReadDataFetch({ table: 'sale' })).then((res) => {
      setSaleOptions([
        ...res.payload.map((data) => ({
          value: data.code,
          label: `${data.code} | ${data.name}`,
        })),
      ]);
    });
  }, [dispatch, pageType]);

  // orderSaleDatas 에 선택된 데이터 추가 데이터 추가
  const handleSaleOption = useCallback(() => {
    if (!selectedSaleOption) return;
    // orderSaleDatas에 이미 있는 데이터인지 중복 체크
    let isDuplicated = false;
    orderSaleDatas.forEach((orderSaleData) => {
      if (orderSaleData.sale_code === selectedSaleOption) {
        isDuplicated = true;
      }
    });
    if (isDuplicated === true) {
      return alert('이미 존재하는 주문-판매 상품입니다.');
    }
    const asyncFunc = async () => {
      const sale = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'sale',
          eqKey: 'code',
          eqValue: selectedSaleOption,
        })
      ).unwrap();
      const delivery = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'sale_product',
          eqKey: 'sale_code',
          eqValue: sale[0].code,
        })
      )
        .unwrap()
        .then((datas) => {
          for (const data of datas) {
            if (data.product_code.includes('parcel')) {
              return data.product_code;
            }
          }
        });
      const newData = {
        sale_code: sale[0].code,
        sale_name: sale[0].name,
        sale_quantity: 1,
        delivery,
      };
      const newDatas = [...orderSaleDatas, newData];
      setOrderSaleDatas([...newDatas]);
      // orderProductForm 데이터 추가 함수 트리거
      createDataTrigger([...newDatas]);
    };
    asyncFunc();
  }, [
    selectedSaleOption,
    dispatch,
    createDataTrigger,
    setOrderSaleDatas,
    orderSaleDatas,
  ]);

  // orderSaleDatas에서 체크된 데이터 삭제
  const handleCheckedDatas = useCallback(() => {
    const asyncFunc = async () => {
      const filtered = await orderSaleDatas.filter(
        (data) => !checkedSaleDatas.includes(data)
      );
      await setOrderSaleDatas([...filtered]);
      // orderProductForm 데이터 삭제 함수 트리거
      deleteDataTrigger([...filtered]);
    };
    asyncFunc();
  }, [checkedSaleDatas, orderSaleDatas, setOrderSaleDatas, deleteDataTrigger]);

  // orderSaleDatas의 데이터 수량 변경
  const handleQuantityInput = useCallback(
    (value, data) => {
      const asyncFunc = async () => {
        const newDatas = await orderSaleDatas.map((saleTableData) => {
          if (
            saleTableData.sale_code === data.sale_code &&
            saleTableData.sale_name === data.sale_name
          ) {
            return { ...saleTableData, sale_quantity: Number(value) };
          }
          return { ...saleTableData };
        });
        await setOrderSaleDatas([...newDatas]);
        // orderProductForm 수량 조절 함수 트리거
        quantityDataTrigger([...newDatas]);
      };
      asyncFunc();
    },
    [orderSaleDatas, setOrderSaleDatas, quantityDataTrigger]
  );

  const handleCheck = useCallback(
    (check, data) => {
      if (check) {
        setCheckedSaleDatas((prev) => [...prev, data]);
      } else {
        const filtered = checkedSaleDatas.filter((checkedSaleData) => {
          return checkedSaleData !== data;
        });
        setCheckedSaleDatas([...filtered]);
      }
    },
    [checkedSaleDatas]
  );

  const saleTableColumns = useMemo(
    () => [
      {
        title: (
          <Button size="small" onClick={handleCheckedDatas}>
            <DeleteOutlined />
          </Button>
        ),
        key: 'checkbox',
        width: '10%',
        align: 'center',
        render: (_, data) => (
          <Checkbox onChange={(e) => handleCheck(e.target.checked, data)} />
        ),
      },
      {
        title: '판매상품코드',
        dataIndex: 'sale_code',
        key: 'sale_code',
        width: '30%',
        align: 'center',
      },
      {
        title: '판매상품명',
        dataIndex: 'sale_name',
        key: 'sale_name',
        width: '30%',
        align: 'center',
      },
      {
        title: '수량',
        dataIndex: 'sale_quantity',
        key: 'sale_quantity',
        width: '15%',
        align: 'center',
        render: (_, data) => (
          <Input
            onChange={(e) => handleQuantityInput(e.target.value, data)}
            type="number"
            style={{ width: '50px', borderRadius: '0px' }}
            value={data.sale_quantity}
          />
        ),
      },
      {
        title: (
          <div>
            택배비선택
            <Popover
              content={
                <div>판매상품이 1개일 때만 택배비를 선택할 수 있습니다.</div>
              }
              placement="bottomLeft"
            >
              <InfoCircleOutlined
                style={{ color: '#1677ff', marginLeft: '5px' }}
              />
            </Popover>
          </div>
        ),
        key: 'delivery',
        width: '15%',
        align: 'center',
        render: (saleData) => (
          <div className="select-container">
            <OrderSaleDelivery
              orderSaleDatas={orderSaleDatas}
              setOrderSaleDatas={setOrderSaleDatas}
              saleData={saleData}
              orderProductDatas={orderProductDatas}
              setOrderProductDatas={setOrderProductDatas}
              pageType={pageType}
            />
          </div>
        ),
      },
    ],
    [
      handleQuantityInput,
      handleCheck,
      handleCheckedDatas,
      orderProductDatas,
      setOrderProductDatas,
      setOrderSaleDatas,
      orderSaleDatas,
      pageType,
    ]
  );

  // 최신주문반영 기능
  const getRecentDatas = (accountCode) => {
    dispatch(
      asyncReadFilteredDataFetch({
        table: 'order',
        eqKey: 'account_code',
        eqValue: accountCode,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.length === 0) {
          alert(
            '해당 거래처의 주문내역이 없으므로 주문-판매정보를 가져올 수 없습니다.'
          );
          return;
        }
        // 주문서 데이터 중 가장 최근 것 가져오기
        let latestDate = null;
        let latestData = null;
        res.forEach((data) => {
          const date = new Date(data.reg_date);
          if (date > latestDate || latestDate === null) {
            latestDate = date;
            latestData = data;
          }
        });
        // 해당 주문서 데이터의 orderSale 정보 불러오기
        dispatch(
          asyncReadFilteredDataFetch({
            table: 'order_sale',
            eqKey: 'order_id',
            eqValue: latestData.id,
          })
        )
          .unwrap()
          .then((datas) => {
            setOrderSaleDatas([...datas]);
          })
          .catch((err) => {
            console.log(err);
            alert(err.message);
          });
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };

  // 주력상품 나열 기능
  const orderOptions = useCallback(
    async (e) => {
      // 이미 정렬 되었는지 검사
      if (saleOptions && saleOptions[0].count) {
        return;
      }
      await setOrderLoading(true);

      const sales = await dispatch(asyncReadDataFetch({ table: 'order_sale' }))
        .unwrap()
        .then((res) => res);
      // 각 판매상품 개수 (array)
      const numberOfSales = [];
      for (const sale of sales) {
        const code = sale.sale_code;
        let isInput = true;
        for (const data of numberOfSales) {
          if (data.code === code) {
            data.count += 1;
            isInput = false;
          }
        }
        if (isInput) {
          numberOfSales.push({ code, count: 1 });
        }
      }
      // 판매상품 데이터 속성 (count)에 개수 표시
      for (const option of saleOptions) {
        let isZero = true;
        for (const data of numberOfSales) {
          if (option.value === data.code) {
            option.count = data.count;
            isZero = false;
          }
        }
        if (isZero) {
          option.count = 0;
        }
      }
      // 판매상품 데이터 count순으로 정렬
      const orderedOptions = saleOptions.sort((a, b) => b.count - a.count);
      setSaleOptions([...orderedOptions]);
      setOrderLoading(false);
    },
    [dispatch, saleOptions]
  );

  useImperativeHandle(ref, () => ({
    handleRecentOrder(accountCode) {
      getRecentDatas(accountCode);
    },
  }));

  return (
    <div style={{ position: 'relative' }}>
      {/* 견적서 -> 주문서 전환상태일 때 블라인드 처리 */}
      {window.location.pathname.includes('estimateToOrder') && (
        <Popover content="주문서 전환상태일 때 주문-판매 정보를 수정할 수 없습니다.">
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: '1',
              cursor: 'not-allowed',
            }}
          />
        </Popover>
      )}
      <Form
        form={form}
        style={{ width: '100%' }}
        disabled={pageType === 'detail'}
      >
        <Descriptions
          bordered
          labelStyle={{ width: '10%', textAlign: 'center' }}
        >
          {orderSaleItems.map((orderItem) => {
            if (orderItem.type === 'selectSearch') {
              return (
                <Descriptions.Item
                  span={orderItem.span}
                  label={orderItem.label}
                >
                  <Form.Item noStyle name={orderItem.name}>
                    <div
                      className="select-container"
                      style={{ display: 'flex' }}
                    >
                      {!orderLoading ? (
                        <Select
                          style={{ width: '100%', borderRadius: '0px' }}
                          placeholder={orderItem.placeholder}
                          showSearch
                          filterOption={(input, option) => {
                            return option.label && option.label.includes(input);
                          }}
                          options={orderItem.name === 'sale' && saleOptions}
                          onChange={(value) => setSelectedSaleOption(value)}
                          onClick={(e) => orderOptions(e)}
                        />
                      ) : (
                        <Select
                          style={{ width: '100%', borderRadius: '0px' }}
                          placeholder={orderItem.placeholder}
                        >
                          <Option
                            key="loading"
                            value="loading"
                            disabled
                            style={{ margin: '5px 0' }}
                          >
                            <Spin />
                            <span
                              style={{ marginLeft: '15px', color: '#1890ff' }}
                            >
                              판매순으로 정렬중...
                            </span>
                          </Option>
                        </Select>
                      )}
                      <Button
                        style={{
                          marginLeft: '15px',
                          borderRadius: '3px',
                        }}
                        onClick={handleSaleOption}
                        type="primary"
                      >
                        <PlusOutlined />
                      </Button>
                    </div>
                  </Form.Item>
                </Descriptions.Item>
              );
            }
          })}
        </Descriptions>
        <Table
          columns={saleTableColumns}
          dataSource={orderSaleDatas}
          style={{ marginTop: '20px' }}
          pagination={false}
        />
      </Form>
    </div>
  );
});

export default React.memo(OrderSaleForm);

OrderSaleForm.propTypes = {
  pageType: PropTypes.string.isRequired,
  orderSaleItems: PropTypes.instanceOf(Array).isRequired,
  orderSaleDatas: PropTypes.instanceOf(Array).isRequired,
  setOrderSaleDatas: PropTypes.func.isRequired,
  createDataTrigger: PropTypes.func.isRequired,
  deleteDataTrigger: PropTypes.func.isRequired,
  quantityDataTrigger: PropTypes.func.isRequired,
  orderProductDatas: PropTypes.instanceOf(Array).isRequired,
  setOrderProductDatas: PropTypes.func.isRequired,
};
