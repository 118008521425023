import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';

import UpdateTable from '../shared/TableModal/UpdateTable';
import CreateTable from '../shared/TableModal/CreateTable';
import DeleteTable from '../shared/TableModal/DeleteTable';
import UpdateComponent from './UpdateComponent';
import DeleteComponent from './DeleteComponent';
import CreateComponent from './CreateComponent';

const ComponentData = (props) => {
  const { items } = props;
  const { modalInfo } = useSelector((state) => state.modal);

  const [selectedItems, setSelectedItems] = useState([]);
  const [formCategory, setFormCategory] = useState('구성품');

  // visibility를 true로 바꿔주는 함수
  useEffect(() => {
    if (formCategory === '구성품') {
      const newItems = items.map((item) => {
        if (
          item.name === 'product_code' ||
          item.name === 'merchandise_code' ||
          item.name === 'product_count'
        ) {
          return {
            ...item,
            visibility: true,
          };
        }
        return { ...item };
      });
      setSelectedItems([...newItems]);
    }
  }, [formCategory, items]);

  // modal 종류에 따라 다른 component 리턴.
  // 공유 TableModal로 들어감.
  const component = useCallback(() => {
    if (modalInfo.modalTitle === '구성품 수정') {
      return <UpdateComponent items={selectedItems} />;
    } else if (modalInfo.modalTitle === '구성품 삭제') {
      return <DeleteComponent items={selectedItems} />;
    } else if (modalInfo.modalTitle === '구성품 추가') {
      return <CreateComponent items={selectedItems} />;
    }
  }, [selectedItems, modalInfo.modalTitle]);

  // Tabs 부분
  const tabItems = useMemo(
    () => [
      {
        key: '구성품',
        label: '구성품',
      },
    ],
    []
  );

  const onChange = useCallback((key) => {
    setFormCategory(key);
  }, []);

  return (
    <>
      <Tabs
        size="large"
        type="card"
        items={tabItems}
        onChange={onChange}
        style={{ marginBottom: '-16px' }}
      />
      {component()}
    </>
  );
};

export default ComponentData;

ComponentData.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};
