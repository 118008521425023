import React from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';

// import ProfileModal from './profileModal/ProfileModal';
// import ManagerModal from './managerModal/ManagerModal';
import UserListInfo from './UserListInfo/UserListInfo';
import LogoutModal from './LogoutModal/LogoutModal';
import PasswordUpdateModal from './PasswordUpdateModal/PasswordUpdateModal';
import ResultModal from './ResultModal';
import ProfileModal from './ProfileModal/ProfileModal';
import ProductModal from './ProductModal/ProductModal';
import WarehouseModal from './warehouseModal/WarehouseModal';
import ManagerModal from './ManagerModal/ManagerModal';
import AccountgroupModal from './accountgroupModal/AccountgroupModal';
import MerchandiseModal from './merchandiseModal/MerchandiseModal';
import ComponentModal from './componentModal/ComponentModal';

const ModalContainer = () => {
  const { type } = useSelector((state) => state.modal);

  if (!type) {
    return null;
  } else if (type === 'profile') {
    return ReactDOM.createPortal(
      <ProfileModal />,
      document.getElementById('modal')
    );
  } else if (type === 'userListInfo') {
    return ReactDOM.createPortal(
      <UserListInfo />,
      document.getElementById('modal')
    );
  } else if (type === 'logout') {
    return ReactDOM.createPortal(
      <LogoutModal />,
      document.getElementById('modal')
    );
  } else if (type === 'passwordUpdate') {
    return ReactDOM.createPortal(
      <PasswordUpdateModal />,
      document.getElementById('modal')
    );
  } else if (type === 'result') {
    return ReactDOM.createPortal(
      <ResultModal />,
      document.getElementById('modal')
    );
  } else if (type === 'manager') {
    return ReactDOM.createPortal(
      <ManagerModal />,
      document.getElementById('modal')
    );
  } else if (type === 'product') {
    return ReactDOM.createPortal(
      <ProductModal />,
      document.getElementById('modal')
    );
  } else if (type === 'warehouse') {
    return ReactDOM.createPortal(
      <WarehouseModal />,
      document.getElementById('modal')
    );
  } else if (type === 'accountgroup') {
    return ReactDOM.createPortal(
      <AccountgroupModal />,
      document.getElementById('modal')
    );
  } else if (type === 'merchandise') {
    return ReactDOM.createPortal(
      <MerchandiseModal />,
      document.getElementById('modal')
    );
  } else if (type === 'component') {
    return ReactDOM.createPortal(
      <ComponentModal />,
      document.getElementById('modal')
    );
  }
};

export default ModalContainer;
