import React from 'react';

const AuthFooter = () => {
  return (
    <>
      <div>ver 1.0.1 {process.env.REACT_APP_VERSION}</div>
      <div style={{ color: 'black' }}>@2024 Hantong-OMS Admin Web.</div>
    </>
  );
};

export default AuthFooter;
