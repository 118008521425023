import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  borderRadiuses,
  DARK_MODE_BACKGROUND,
  LIGHT_MODE_BACKGROUND,
} from '../../constants';

const CourierAndWarehouseStatus = (props) => {
  const { courierStatusData, warehouseStatusData, rangeText } = props;

  const { darkMode } = useSelector((state) => state.darkMode);

  const abstractDoneFromEntire = (entire, done) => {
    return entire - done;
  };

  return (
    <div
      style={{
        textAlign: 'center',
        padding: '10px',
        color: 'gray',
        borderRadius: borderRadiuses.primary,
        height: '100%',
        backgroundColor: darkMode
          ? DARK_MODE_BACKGROUND
          : LIGHT_MODE_BACKGROUND,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          color: 'gray',
          marginBottom: '5px',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h4>택배사, 창고</h4>
      </div>
      <Container>
        <LeftSide>
          <InnerContainer>
            <div>한진</div>
            <div>
              {abstractDoneFromEntire(
                courierStatusData.hanjin,
                courierStatusData.hanjinDone
              )}{' '}
              / {courierStatusData.hanjin} 건
            </div>
          </InnerContainer>
          <InnerContainer>
            <div>CJ</div>
            <div>
              {abstractDoneFromEntire(
                courierStatusData.cjlogistics,
                courierStatusData.cjlogisticsDone
              )}{' '}
              / {courierStatusData.cjlogistics} 건
            </div>
          </InnerContainer>
          <InnerContainer>
            <div>건영</div>
            <div>
              {abstractDoneFromEntire(
                courierStatusData.kunyoung,
                courierStatusData.kunyoungDone
              )}{' '}
              / {courierStatusData.kunyoung} 건
            </div>
          </InnerContainer>
          <InnerContainer>
            <div>로젠</div>
            <div>
              {abstractDoneFromEntire(
                courierStatusData.logen,
                courierStatusData.logenDone
              )}{' '}
              / {courierStatusData.logen} 건
            </div>
          </InnerContainer>
          <InnerContainer>
            <div>경동/우리</div>
            <div>
              {abstractDoneFromEntire(
                courierStatusData.kdexp,
                courierStatusData.kdexpDone
              )}{' '}
              / {courierStatusData.kdexp} 건
            </div>
          </InnerContainer>
        </LeftSide>
        <SideDivider
          style={{
            borderLeft: darkMode ? '0.5px solid gray' : '0.5px solid lightgray',
          }}
        />
        <RightSide>
          <InnerContainer>
            <div>고려포장</div>
            <div>
              {abstractDoneFromEntire(
                warehouseStatusData.koreapk,
                warehouseStatusData.koreapkDone
              )}{' '}
              / {warehouseStatusData.koreapk} 건
            </div>
          </InnerContainer>
          <InnerContainer>
            <div>준테크</div>
            <div>
              {abstractDoneFromEntire(
                warehouseStatusData.joontech,
                warehouseStatusData.joontechDone
              )}{' '}
              / {warehouseStatusData.joontech} 건
            </div>
          </InnerContainer>
        </RightSide>
      </Container>
    </div>
  );
};

export default CourierAndWarehouseStatus;

CourierAndWarehouseStatus.propTypes = {
  courierStatusData: PropTypes.instanceOf(Object).isRequired,
  warehouseStatusData: PropTypes.instanceOf(Object).isRequired,
  rangeText: PropTypes.string.isRequired,
};

const Container = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 20px;
  padding: 10px;
`;
const LeftSide = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  gap: 5px;
`;
const RightSide = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  gap: 5px;
`;
const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: gray;
`;
const SideDivider = styled.div`
  height: 100%;
`;
