import React from 'react';
import { Spin } from 'antd';

import Center from './Center';

const Loading = () => {
  return (
    <Center>
      <Spin size="large" />
      <div style={{ marginTop: '30px' }}>로딩중...</div>
    </Center>
  );
};
export default Loading;
