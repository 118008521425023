import React from 'react';
import { Result } from 'antd';
import { useDispatch } from 'react-redux';
import { SendOutlined } from '@ant-design/icons';

import { asyncResetPasswordFetch } from '../../redux/modules/auth/resetPassword';

import useModal from '../../hook/useModal';
import Center from '../../shared/components/Center';
import PasswordLinkForm from '../components/PasswordLinkForm';

const PasswordLink = () => {
  const { openModal } = useModal();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    dispatch(asyncResetPasswordFetch(values))
      .unwrap()
      .then(() => {
        openModal({
          type: 'result',
          modalInfo: {
            modalTitle: '비밀번호 변경',
            resultStatus: 'success',
            resultTitle: '비밀번호 변경 메일을 보냈습니다.',
            resultSubTitle:
              '메일을 확인하시고 비밀번호 초기화 링크를 클릭해주세요.',
            buttonText: '확인',
          },
        });
      })
      .catch((err) => alert(err));
  };
  const onFinishFailed = (values) => {};

  return (
    <Center>
      <div
        style={{
          width: '400px',
          border: '1px solid lightGray',
          backgroundColor: 'white',
          padding: '32px',
        }}
      >
        <Result
          style={{ padding: '0px' }}
          icon={<SendOutlined style={{ marginTop: '16px' }} />}
          status="info"
          title="이메일을 입력해주세요."
          subTitle={
            <div style={{ margin: '50px 0', padding: '0 30px' }}>
              한통OMS 회원가입된 이메일 주소를 입력하세요.
              <br />
              <br /> 입력하신 이메일 주소로 비밀번호를 재설정할 수 있는 인증
              링크를 보내드립니다.
            </div>
          }
          extra={
            <div style={{ padding: '0 30px' }}>
              <PasswordLinkForm
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              />
            </div>
          }
        />
      </div>
    </Center>
  );
};

export default PasswordLink;
