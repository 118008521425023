import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Center from '../../shared/components/Center';
import AuthContainer from '../components/AuthContainer';
import AuthFooter from '../components/AuthFooter';
import EditUserAccessHeader from '../components/EditUserAccessHeader';
import EditUserAccessForm from '../components/EditUserAccessForm';
import {
  deleteUserByIdApi,
  editUserAccessApi,
  getUserInfoByIdApi,
} from '../../apis/authApi';

const EditUserAccess = () => {
  const history = useHistory();
  const pathname = window.location.pathname;
  const userId = pathname?.split('/')[2] || null;

  const [userAccess, setUserAccess] = useState({
    permission: null,
    tier: null,
  });
  const [username, setUsername] = useState(null);

  useEffect(() => {
    const getUserInfo = async () => {
      const fetchedUserInfo = await getUserInfoByIdApi({ id: userId });
      const { username, permission, tier } = fetchedUserInfo;

      setUserAccess({
        permission,
        tier,
      });
      setUsername(username);
    };
    getUserInfo();
  }, []);

  const handleEditUserAccess = async (values) => {
    const confirm = window.confirm('정말 권한을 수정하시겠습니까?');
    if (!confirm) return;

    const postData = { id: userId, ...values };

    try {
      await editUserAccessApi(postData);
      alert('권한을 수정했습니다.');
    } catch (error) {
      alert(error);
      throw error;
    }
  };

  const handleDeleteUser = async () => {
    const confirm = window.confirm('정말 해당 계정을 삭제하시겠습니까?');
    if (!confirm) return;

    try {
      await deleteUserByIdApi({ id: userId });
      alert('계정을 삭제했습니다.');
      history.goBack();
    } catch (error) {
      alert(error);
      throw error;
    }
  };

  return (
    <Center>
      <AuthContainer>
        <EditUserAccessHeader username={username} />
        <EditUserAccessForm
          userAccess={userAccess}
          handleEditUserAccess={handleEditUserAccess}
          handleDeleteUser={handleDeleteUser}
        />
      </AuthContainer>
      <AuthFooter />
    </Center>
  );
};

export default EditUserAccess;
