import React, { useState } from 'react';
import { Input, Button } from 'antd';
import PropTypes from 'prop-types';

const MyPasswordResetForm = (props) => {
  const { handleResetMyPassword } = props;

  const [values, setValues] = useState({
    password: null,
    newPassword: null,
    newPasswordCheck: null,
  });

  const handleChangeValue = (name, value) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickSubmit = (e) => {
    e.preventDefault();

    handleResetMyPassword(values);
  };

  return (
    <form onSubmit={handleClickSubmit}>
      <Input.Password
        size="large"
        placeholder="password"
        onChange={(e) => handleChangeValue('password', e.target.value)}
        value={values.password}
        minLength={4}
        maxLength={12}
      />
      <Input.Password
        size="large"
        placeholder="new password"
        style={{ marginTop: '30px' }}
        onChange={(e) => handleChangeValue('newPassword', e.target.value)}
        value={values.newPassword}
        minLength={4}
        maxLength={12}
      />
      <Input.Password
        size="large"
        placeholder="new password-check"
        style={{ marginTop: '10px' }}
        onChange={(e) => handleChangeValue('newPasswordCheck', e.target.value)}
        value={values.newPasswordCheck}
        minLength={4}
        maxLength={12}
      />
      <Button
        htmlType="submit"
        size="large"
        style={{ marginTop: '30px', width: '100%' }}
        type="primary"
        disabled={
          !values.password || !values.newPassword || !values.newPasswordCheck
        }
      >
        비밀번호 변경
      </Button>
    </form>
  );
};

export default MyPasswordResetForm;

MyPasswordResetForm.propTypes = {
  handleResetMyPassword: PropTypes.func.isRequired,
};
