import React from 'react';
import PropTypes from 'prop-types';

const InventoryStatusCardContainer = ({ children }) => {
  return (
    <div
      style={{
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      {children}
    </div>
  );
};

export default InventoryStatusCardContainer;

InventoryStatusCardContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
