import React from 'react';

const useInventoryNotification = () => {
  const filterDeliveryItem = (datas) => {
    return datas.filter((item) => !item.productName.includes('택배비'));
  };

  const mapArrayToStr = (datas) => {
    return datas
      .map((item) => ` ${item.productName} ${item.changeQuantity}개`)
      .toString();
  };

  const generateMessage = ({ type, datas }) => {
    const filteredDatas = filterDeliveryItem(datas);
    const mappedStrFromArray = mapArrayToStr(filteredDatas);
    return `
    *${type}
    ${mappedStrFromArray}`;
  };

  const getMessageByAdded = (addedInventoryDatas) => {
    return `
    재고변동 알림
    ${generateMessage({ type: '증가', datas: addedInventoryDatas })}`;
  };

  const getMessageByAbstracted = (abstractedInventoryDatas) => {
    return `
    재고변동 알림
    ${generateMessage({ type: '감소', datas: abstractedInventoryDatas })}`;
  };

  const getMessageByAll = ({
    addedInventoryDatas,
    abstractedInventoryDatas,
  }) => {
    return `
    재고변동 알림
    ${generateMessage({ type: '증가', datas: addedInventoryDatas })}
    ${generateMessage({ type: '감소', datas: abstractedInventoryDatas })}`;
  };

  const returnAlert = ({ addedInventoryDatas, abstractedInventoryDatas }) => {
    const isExistOnlyAddedDatas =
      addedInventoryDatas && !abstractedInventoryDatas;
    const isExistOnlyAbstractedDatas =
      !addedInventoryDatas && abstractedInventoryDatas;
    const isExistBothDatas = addedInventoryDatas && abstractedInventoryDatas;

    if (isExistOnlyAddedDatas) {
      return getMessageByAdded(addedInventoryDatas);
    } else if (isExistOnlyAbstractedDatas) {
      return getMessageByAbstracted(abstractedInventoryDatas);
    } else if (isExistBothDatas) {
      return getMessageByAll({ addedInventoryDatas, abstractedInventoryDatas });
    } else {
      return;
    }
  };

  const alertNotification = ({
    addedInventoryDatas,
    abstractedInventoryDatas,
  }) => {
    return window.alert(
      returnAlert({ addedInventoryDatas, abstractedInventoryDatas })
    );
  };

  return { alertNotification };
};

export default useInventoryNotification;
