import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';

import {
  darkModeColors,
  DARK_MODE_BACKGROUND,
  DARK_MODE_FONT_COLOR,
  LIGHT_MODE_BACKGROUND,
  LIGHT_MODE_FONT_COLOR,
} from '../../constants';
import { asyncReadDataFetch } from '../../redux/modules/shared/readData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MonthlySalesChart = (props) => {
  const { monthValue } = props;

  const dispatch = useDispatch();

  const { darkMode } = useSelector((state) => state.darkMode);
  const bgColor = darkMode ? DARK_MODE_BACKGROUND : LIGHT_MODE_BACKGROUND;
  const fontColor = darkMode ? DARK_MODE_FONT_COLOR : LIGHT_MODE_FONT_COLOR;

  const isLargeScreen = false;

  const initialChartData = {
    year_month: '',
    monthly_sales_amount: 0,
    monthly_sales_store_amount: 0,
    monthly_sales_onsite_amount: 0,
  };

  const [monthlySalesDatas, setMonthlySalesDatas] = useState([]);
  const [chartData, setChartData] = useState(initialChartData);
  const [maxSales, setMaxSales] = useState(0);

  useEffect(() => {
    const fetchMonthSalesData = async () => {
      const fetchedMonthSalesData = await dispatch(
        asyncReadDataFetch({
          table: 'v_monthly_sales_amount',
        })
      ).unwrap();
      setMonthlySalesDatas(fetchedMonthSalesData);
    };
    fetchMonthSalesData();
  }, []);

  useEffect(() => {
    if (!monthlySalesDatas.length) return;
    const curChartData = monthlySalesDatas.filter(
      (data) => data.year_month === monthValue
    );
    const formattedCurChartData = curChartData[0]
      ? {
          year_month: curChartData[0].year_month,
          monthly_sales_amount: Number(curChartData[0].monthly_sales_amount),
          monthly_sales_store_amount: Number(
            curChartData[0].monthly_sales_store_amount
          ),
          monthly_sales_onsite_amount: Number(
            curChartData[0].monthly_sales_onsite_amount
          ),
        }
      : initialChartData;
    setChartData(formattedCurChartData);
  }, [monthValue, monthlySalesDatas]);

  useEffect(() => {
    const handleMaxSales = async () => {
      setMaxSales(chartData.monthly_sales_amount / 10000);
    };
    handleMaxSales();
  }, [chartData]);

  const options = {
    aspectRatio: 5.6,
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (value) => {
            const { monthly_sales_amount: sales } = chartData;
            const { dataset, formattedValue: tooltipSales } = value;
            const formattedTooltipSales = Number(tooltipSales.replace(',', ''));
            const percentage = (
              (formattedTooltipSales / (sales / 10000)) *
              100
            ).toFixed(1);
            return `${dataset.label}: ${tooltipSales} [${percentage}%]`;
          },
        },
      },
    },
    scales: {
      x: {
        min: 0,
        max: maxSales,
        ticks: {
          display: false,
        },
        stacked: true,
      },
      y: {
        ticks: {
          display: false, // Y축 레이블 숨김
        },
        grid: {
          drawTicks: false, // Y축 눈금 숨김
        },
        stacked: true,
      },
    },
  };

  const onsiteBarColor = {
    black: 'black',
    specific: '#181b1e',
  };

  const data = {
    labels: [chartData.year_month],
    datasets: [
      {
        label: '방문판매',
        data: [Math.round(chartData.monthly_sales_onsite_amount / 10000)],
        backgroundColor: onsiteBarColor.specific,
        barThickness: 20,
      },
      {
        label: '온라인',
        data: [Math.round(chartData.monthly_sales_store_amount / 10000)],
        backgroundColor: darkMode ? '#252C33' : 'gray',
        barThickness: 20,
      },
      {
        label: '오프라인',
        data: [
          Math.round(
            (chartData.monthly_sales_amount -
              (chartData.monthly_sales_store_amount +
                chartData.monthly_sales_onsite_amount)) /
              10000
          ),
        ],
        backgroundColor: darkMode ? '#363841' : '#bfbfbf',
        barThickness: 20,
      },
    ],
  };

  return (
    <div
      style={{
        flexDirection: isLargeScreen ? 'row' : 'column',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        padding: '10px',
        color: fontColor,
        backgroundColor: bgColor,
      }}
    >
      <Bar
        options={options}
        data={data}
        style={{ padding: '0 10px' }}
        className="chart-bar-width-100"
      />
    </div>
  );
};

export default MonthlySalesChart;

MonthlySalesChart.propTypes = {
  monthValue: PropTypes.string.isRequired,
};
