import React, { useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Descriptions } from 'antd';
import PropTypes from 'prop-types';

import useBasicForm from '../../hook/useBasicForm';
import SearchInputFormField from './SearchInputFormField';
import SelectFormField from './SelectFormField';
import SearchRanger from './SearchRanger';

const commonItemProps = (label, span) => ({
  label,
  span,
});

const SmsSearchForm = ({ getHistoryData }) => {
  const { values, handleChangeValue } = useBasicForm({
    statusName: null,
    type: null,
    contentType: null,
    from: null,
    to: null,
    rangeType: 'request',
  });

  const getFormFieldProps = (name) => {
    return {
      name,
      value: values[name],
      onChangeValue: handleChangeValue,
    };
  };

  const searchRangerRef = useRef(null);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const { values: rangeValues } = searchRangerRef.current;
        getHistoryData({ values: { ...values, ...rangeValues } });
      }}
    >
      <Descriptions bordered column={3}>
        <Descriptions.Item {...commonItemProps('수신상태', 1)}>
          <SelectFormField {...getFormFieldProps('statusName')} />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('타입', 1)}>
          <SelectFormField {...getFormFieldProps('type')} />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('컨텐츠타입', 1)}>
          <SelectFormField {...getFormFieldProps('contentType')} />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('발신번호', 1)}>
          <SearchInputFormField {...getFormFieldProps('from')} />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('수신번호', 1)}>
          <SearchInputFormField {...getFormFieldProps('to')} />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('기간선택1', 1)}>
          <SelectFormField {...getFormFieldProps('rangeType')} />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('기간선택2', 2)}>
          <SearchRanger ref={searchRangerRef} />
        </Descriptions.Item>
        <Descriptions.Item {...commonItemProps('검색', 1)}>
          <button type="submit">
            <SearchOutlined />
            검색
          </button>
        </Descriptions.Item>
      </Descriptions>
    </form>
  );
};

export default SmsSearchForm;

SmsSearchForm.propTypes = {
  getHistoryData: PropTypes.func.isRequired,
};
