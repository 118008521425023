import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { numberToKorean } from 'number-to-korean';
import * as XLSX from 'xlsx';

export const changeNumToKr = (number) => {
  const krWithNumString = numberToKorean(number);
  const krArray = ['이', '삼', '사', '오', '육', '칠', '팔', '구'];
  const numArray = [2, 3, 4, 5, 6, 7, 8, 9];
  let onlyKrString = '';
  for (const oneLetter of krWithNumString) {
    if (/^[0-9]+$/.test(oneLetter)) {
      const idx = numArray.findIndex((num) => num === Number(oneLetter));
      onlyKrString += krArray[idx];
    } else {
      onlyKrString += oneLetter;
    }
  }
  return onlyKrString;
};

const downloadExcelFile = () => {
  const handleEstimateForm = async (headerData, contentData, footerData) => {
    const filePath = `${process.env.PUBLIC_URL}견적서양식.xlsx`;
    try {
      const response = await fetch(filePath);
      if (!response.ok) {
        console.log(response.status);
        return;
      }
      const blob = await response.blob();
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(blob);
      const worksheet = workbook.getWorksheet('Sheet2');

      // 엑셀form header 데이터 추가
      const dateCell = worksheet.getCell('D2');
      const accountCell = worksheet.getCell('D3');
      const referenceCell = worksheet.getCell('D4');
      const telFaxCell = worksheet.getCell('D5');
      const paymentTermsCell = worksheet.getCell('D6');
      const expirationDateCell = worksheet.getCell('D7');
      const adminCell = worksheet.getCell('K9');
      dateCell.value = headerData.date;
      accountCell.value = headerData.account;
      referenceCell.value = headerData.reference;
      telFaxCell.value = headerData.telFax;
      paymentTermsCell.value = headerData.paymentTerms;
      expirationDateCell.value = headerData.expirationDate;
      adminCell.value = headerData.admin;

      // 엑셀form content 데이터 추가
      for (let i = 0; i < contentData.length; i += 1) {
        worksheet.getCell(`A${i + 15}`).value = contentData[i].code;
        worksheet.getCell(`C${i + 15}`).value = contentData[i].name;
        worksheet.getCell(`L${i + 15}`).value = contentData[i].quantity;
        worksheet.getCell(`M${i + 15}`).value = contentData[i].price;
        worksheet.getCell(`P${i + 15}`).value = contentData[i].supply;
        worksheet.getCell(`Q${i + 15}`).value = contentData[i].vat;
      }

      // 엑셀form footer 데이터 추가
      let quantity = 0;
      let supply = 0;
      let vat = 0;
      let total = 0;
      for (const data of footerData) {
        quantity += data.quantity;
        supply += data.supply;
        vat += data.vat;
        total += data.total;
      }
      worksheet.getCell('B43').value = Math.round(quantity);
      worksheet.getCell('F43').value = Math.round(supply);
      worksheet.getCell('J43').value = Math.round(vat);
      worksheet.getCell('P43').value = Math.round(total);
      worksheet.getCell('D12').value = `${changeNumToKr(Math.round(total))}원`;
      worksheet.getCell('P12').value = Math.round(total);

      const modifiedFileBlob = await workbook.xlsx.writeBuffer();
      const modifiedFile = new File(
        [modifiedFileBlob],
        `${headerData.date}_견적서_${headerData.account}.xlsx`,
        {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }
      );
      saveAs(modifiedFile);
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const handleTaxInvoiceTable = async (title, columns, rows, type) => {
    if (rows.length === 0) {
      alert('데이터가 없으므로 파일을 다운로드할 수 없습니다.');
      return;
    }
    try {
      // 시트명 정의
      const sheetName =
        type === 'hometax'
          ? '세금계산서등록양식(홈택스)'
          : '세금계산서등록양식(경리나라)';
      // row 생성
      const content = rows.map((data) => {
        const keys = Object.keys(data);
        const result = keys.map((key) => data[key]);
        return result;
      });
      // 엑셀데이터 생성
      const excelData =
        type === 'hometax'
          ? [[], [], [], [], [], columns, ...content]
          : [columns, ...content];
      // 엑셀파일 생성
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(excelData);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      XLSX.writeFile(workbook, title);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const handleSalesStatusTable = async (title, columns, rows) => {
    if (rows.length === 0) {
      alert('데이터가 없으므로 파일을 다운로드할 수 없습니다.');
      return;
    }
    try {
      // 시트명 정의
      const sheetName = '판매현황';
      // row 생성
      const content = rows.map((data) => {
        const keys = Object.keys(data);
        const result = keys.map((key) => data[key]);
        return result;
      });
      let totalDcQuantity = 0;
      let totalQuantity = 0;
      let totalSupply = 0;
      let totalVat = 0;
      let totalSum = 0;
      for (const row of content) {
        for (let i = 1; i <= row.length; i += 1) {
          if (i === row.length - 5)
            totalDcQuantity += parseInt(row[row.length - 5].replace(/,/g, ''));
          if (i === row.length - 4)
            totalQuantity += parseInt(row[row.length - 4].replace(/,/g, ''));
          if (i === row.length - 3)
            totalSupply += parseInt(row[row.length - 3].replace(/,/g, ''));
          if (i === row.length - 2)
            totalVat += parseInt(row[row.length - 2].replace(/,/g, ''));
          if (i === row.length - 1)
            totalSum += parseInt(row[row.length - 1].replace(/,/g, ''));
        }
      }
      content.push([
        ...new Array(columns.length - 5),
        totalDcQuantity.toLocaleString(),
        totalQuantity.toLocaleString(),
        totalSupply.toLocaleString(),
        totalVat.toLocaleString(),
        totalSum.toLocaleString(),
      ]);
      // 엑셀데이터 생성
      const excelData = [title, columns, ...content];
      // 엑셀파일 생성
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(excelData);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      XLSX.writeFile(workbook, title[0]);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const handleShippingOrderTable = async (title, columns, rows) => {
    if (rows.length === 0) {
      alert('데이터가 없으므로 파일을 다운로드할 수 없습니다.');
      return;
    }
    try {
      // 시트명 정의
      const sheetName = '출하지시서';
      // 내용줄 생성
      const content = rows.map((data) => {
        const keys = Object.keys(data);
        const result = keys.map((key) => data[key]);
        return result;
      });
      // 헤더+내용의 엑셀데이터 생성
      const excelData = [title, columns, ...content];
      // 엑셀파일 생성
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(excelData);

      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      XLSX.writeFile(workbook, title[0]);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const handlePurchaseOrderForm = async (
    headerData,
    contentData,
    footerData
  ) => {
    const filePath = `${process.env.PUBLIC_URL}발주서양식.xlsx`;
    try {
      const response = await fetch(filePath);
      if (!response.ok) {
        console.log(response.status);
        return;
      }
      const blob = await response.blob();
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(blob);
      const worksheet = workbook.getWorksheet('Sheet2');

      // 엑셀form header 데이터 추가
      const accountCell = worksheet.getCell('D2');
      const representativeCell = worksheet.getCell('D3');
      const dateCell = worksheet.getCell('D4');
      accountCell.value = headerData.account;
      representativeCell.value = headerData.representative;
      dateCell.value = headerData.date;

      // 엑셀 form content 데이터 추가
      for (let i = 0; i < contentData.length; i += 1) {
        worksheet.getCell(
          `A${i + 14}`
        ).value = `${contentData[i].name}[${contentData[i].standard}]`;
        worksheet.getCell(
          `J${i + 14}`
        ).value = `${contentData[i].quantity}(${contentData[i].unit})`;
        worksheet.getCell(`M${i + 14}`).value = contentData[i].price;
        worksheet.getCell(`P${i + 14}`).value = contentData[i].supply;
        worksheet.getCell(`Q${i + 14}`).value = contentData[i].vat;
      }

      // 엑셀form footer 데이터 추가
      let quantity = 0;
      let supply = 0;
      let vat = 0;
      let total = 0;
      for (const data of footerData) {
        quantity += data.quantity;
        supply += data.supply;
        vat += data.vat;
        total += data.total;
      }
      worksheet.getCell('B45').value = quantity;
      worksheet.getCell('F45').value = supply;
      worksheet.getCell('J45').value = vat;
      worksheet.getCell('P45').value = total;
      worksheet.getCell('D11').value = `${changeNumToKr(total)}원`;
      worksheet.getCell('O11').value = total;

      const modifiedFileBlob = await workbook.xlsx.writeBuffer();
      const modifiedFile = new File(
        [modifiedFileBlob],
        `${headerData.date}_발주서_${headerData.account}.xlsx`,
        {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }
      );
      saveAs(modifiedFile);
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return {
    handleEstimateForm,
    handleSalesStatusTable,
    handleShippingOrderTable,
    handlePurchaseOrderForm,
    handleTaxInvoiceTable,
  };
};

export default downloadExcelFile;
