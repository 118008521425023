import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PhoneOutlined, StarTwoTone } from '@ant-design/icons';
import { Tag } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import {
  COUNSELLING_REACTION_BAD,
  COUNSELLING_REACTION_NORMAL,
} from '../../constants';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';

const AccountAndCounsellingInfoValue = (props) => {
  const { data } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [callbackDay, setCallbackDay] = useState(null);
  const [commentQuantity, setCommentQuantity] = useState(null);

  const getCallbackDay = (counsellingDate, responseDate) => {
    let diffDays = null;
    const formattedCounsellingDate = dayjs(counsellingDate).startOf('day');
    if (!responseDate) {
      diffDays = dayjs().startOf('day').diff(formattedCounsellingDate, 'day');
    } else {
      const formattedResponseDate = dayjs(responseDate).startOf('day');
      diffDays = formattedResponseDate.diff(formattedCounsellingDate, 'day');
    }
    return diffDays;
  };

  useEffect(() => {
    const getStates = async () => {
      const comments = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'counselling_comment',
          eqKey: 'counselling_id',
          eqValue: data.id,
        })
      )
        .unwrap()
        .then((res) => res);
      setCommentQuantity(
        comments.filter((item) => item.counselling_id === data.id).length
      );

      const firstComment = comments.sort(
        (a, b) => new Date(a.reg_date) - new Date(b.reg_date)
      )[0];

      const newCallbackDay = getCallbackDay(
        data.counselling_date,
        firstComment && firstComment.reg_date
      );

      setCallbackDay(newCallbackDay);
    };
    getStates();
  }, [data.id]);

  return (
    <AccountNameStyle
      style={{ textAlign: 'start' }}
      onClick={() => history.push(`/counselling/details/${data.id}`)}
    >
      <div style={{ marginRight: '5px' }}>
        {!!data.is_persuaded && (
          <StarTwoTone
            twoToneColor="#ffdd00"
            style={{
              color: 'yellow',
            }}
          />
        )}
      </div>
      <span>{data.account_name}</span>
      <span
        style={{
          padding: '0 5px',
          fontSize: '12px',
        }}
      >
        <PhoneOutlined style={{ fontSize: '12px', marginRight: '1px' }} />
        {data.contact}
      </span>
      {data.route && (
        <TagStyle style={{ marginLeft: '5px' }}>{data.route}</TagStyle>
      )}
      {data.counselling_type && <TagStyle>{data.counselling_type}</TagStyle>}
      {data.purchase_intention && (
        <TagStyle>{data.purchase_intention}</TagStyle>
      )}
      {data.reaction_level && (
        <TagStyle
          color={
            data.reaction_level === COUNSELLING_REACTION_BAD
              ? 'red'
              : data.reaction_level === COUNSELLING_REACTION_NORMAL
              ? 'gray'
              : 'blue'
          }
        >
          {data.reaction_level}
        </TagStyle>
      )}
      <Tag
        color="orange"
        style={{
          marginLeft: '5px',
          fontSize: '12px',
          padding: '0px 5px',
        }}
      >
        {callbackDay === 0 ? '당일' : `${callbackDay}일`}
      </Tag>
      {commentQuantity > 0 && (
        <div style={{ color: 'gray' }}>[{commentQuantity}]</div>
      )}
    </AccountNameStyle>
  );
};

export default AccountAndCounsellingInfoValue;

AccountAndCounsellingInfoValue.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

const AccountNameStyle = styled.div`
  text-align: start;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
const TagStyle = styled(Tag)`
  border-radius: 20px;
  font-size: 12px;
  margin: 0;
`;
