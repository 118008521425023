import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const SafetyStockValue = (props) => {
  const { safetyStock, stackQuantity } = props;
  const [isClicked, setIsClicked] = useState(false);
  const [extraQuantity, setExtraQuantity] = useState(null);

  const getExtraQuantityColor = () => {
    const isExtraQuantityNumber = typeof extraQuantity === 'number';
    if (isExtraQuantityNumber && extraQuantity < 0) {
      return '#f5222d';
    } else if (isExtraQuantityNumber && extraQuantity >= 0) {
      return '#1677ff';
    } else {
      return 'black';
    }
  };

  useEffect(() => {
    const isExistSafetyStock = typeof safetyStock === 'number';
    if (isExistSafetyStock) {
      const calculatedExtraQuantity = stackQuantity - safetyStock;
      setExtraQuantity(calculatedExtraQuantity);
    }
  }, [safetyStock, stackQuantity]);

  return (
    <div
      className="inventory-table-cell"
      style={{ backgroundColor: extraQuantity < 0 && '#ffccc7' }}
      onClick={() => setIsClicked((prev) => !prev)}
    >
      {isClicked ? (
        <div style={{ display: 'flex', fontSize: '11px', gap: '7px' }}>
          <div>
            <div>현재</div>
            <div>{stackQuantity}</div>
          </div>
          <div>
            <div>안전</div>
            <div>{safetyStock !== null ? safetyStock : '-'}</div>
          </div>
          <div>
            <div>여유</div>
            <div style={{ color: getExtraQuantityColor() }}>
              {extraQuantity !== null ? extraQuantity : '-'}
            </div>
          </div>
        </div>
      ) : (
        stackQuantity
      )}
    </div>
  );
};

export default SafetyStockValue;

SafetyStockValue.propTypes = {
  safetyStock: PropTypes.number,
  stackQuantity: PropTypes.number.isRequired,
};
