import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import useModal from '../../hook/useModal';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import UpdateProfile from './UpdateProfile';

const ProfileModal = () => {
  const dispatch = useDispatch();
  // dataInfo에 managerData를 읽어오기 위한 id 값 들어있음.
  const { modalInfo, dataInfo } = useSelector((state) => state.modal);
  const { closeModal } = useModal();
  // profileData 초기값 설정
  const [profileData, setProfileData] = useState({
    email: '',
    username: '',
    phone: '',
    intro: '',
  });

  // 해당 profile 데이터 불러오기
  useEffect(() => {
    const getProfileData = () => {
      // table값으로 profiles 테이블을, id값으로 해당 row를 호출한다.
      const values = { table: dataInfo.dataTable, id: dataInfo.dataId };
      // 데이터를 읽어오도록 dispatch함.
      dispatch(asyncReadSpecificDataFetch(values))
        .then((res) => {
          const { email, username, phone, intro } = res.payload[0];
          setProfileData({ email, username, phone, intro });
        })
        .catch((error) => alert(error));
    };
    getProfileData();
  }, [dispatch, dataInfo.dataId, dataInfo.dataTable, modalInfo.modalTitle]);

  // Read와 Update를 설정하는 함수.
  return (
    <Modal
      open
      onCancel={() => closeModal()}
      title={modalInfo.modalTitle}
      centered
      footer={[<div />]}
      style={{ position: 'relative' }}
    >
      <UpdateProfile profileData={profileData} />
    </Modal>
  );
};

export default ProfileModal;
