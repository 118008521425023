import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import supabase from '../../../supabase';

const initialState = {
  isLoadingReadPaginatedData: false,
  isErrorReadPaginatedData: false,
  isSuccessReadPaginatedData: false,
};

const asyncReadPaginatedDataFetch = createAsyncThunk(
  'paginatedDataSlice/asyncReadPaginatedDataFetch',
  async (values, { rejectWithValue }) => {
    // values의 필수 값 => table
    const { table, from, to } = values;
    try {
      if (isNaN(from) || isNaN(to)) {
        const { data } = await supabase.from(table).select('*');
        return data;
      } else {
        const { data } = await supabase.from(table).select('*').range(from, to);
        return data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const readPaginatedDataSlice = createSlice({
  name: 'readPaginatedData',
  initialState,
  // 비동기가 아닌 액션 로직 작성
  reducers: {},
  // 비동기 로직 작성
  extraReducers: (builder) => {
    builder.addCase(asyncReadPaginatedDataFetch.pending, (state) => {
      state.isLoadingReadPaginatedData = true;
      state.isSuccessReadPaginatedData = false;
      state.isErrorReadPaginatedData = false;
    });
    builder.addCase(asyncReadPaginatedDataFetch.fulfilled, (state) => {
      state.isLoadingReadPaginatedData = false;
      state.isSuccessReadPaginatedData = true;
      state.isErrorReadPaginatedData = false;
    });
    builder.addCase(asyncReadPaginatedDataFetch.rejected, (state) => {
      state.isLoadingReadPaginatedData = false;
      state.isSuccessReadPaginatedData = false;
      state.isErrorReadPaginatedData = true;
    });
  },
});

export default readPaginatedDataSlice.reducer;
export { asyncReadPaginatedDataFetch };
