import React, { useState } from 'react';
import { Button, Modal, Input, Form } from 'antd';
import DaumPostcode from 'react-daum-postcode';
import PropTypes from 'prop-types';
import { MailOutlined } from '@ant-design/icons';

const Postcode = (props) => {
  const { addressType, name, setAddress, setPostcode } = props;
  const [openPostcode, setOpenPostcode] = useState(false);

  const onComplete = (data) => {
    setAddress(data.address);
  };

  return (
    <>
      {addressType === 'postcode' && (
        <div style={{ display: 'flex' }}>
          <Button
            onClick={() => setOpenPostcode(true)}
            type="link"
            style={{ fontWeight: '700' }}
            icon={<MailOutlined />}
          >
            우편번호 찾기
          </Button>
          <Form.Item name={name} noStyle>
            <Input bordered={false} />
          </Form.Item>
        </div>
      )}
      {addressType === 'address' && (
        <Form.Item name={name} noStyle>
          <Input bordered={false} />
        </Form.Item>
      )}
      {openPostcode && (
        <Modal
          title="우편번호 찾기"
          open
          centered
          onCancel={() => setOpenPostcode(false)}
          footer={[
            <Button key="postcode" onClick={() => setOpenPostcode(false)}>
              닫기
            </Button>,
          ]}
        >
          <DaumPostcode onComplete={onComplete} />
        </Modal>
      )}
    </>
  );
};

export default Postcode;

Postcode.propTypes = {
  addressType: PropTypes.string,
  name: PropTypes.string,
  setAddress: PropTypes.string,
  setPostcode: PropTypes.string,
};
