import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { asyncReadDataFetch } from '../redux/modules/shared/readData';
import { asyncReadFilteredDataFetch } from '../redux/modules/shared/readFilteredData';

const useProposalData = () => {
  const dispatch = useDispatch();

  const [proposalData, setProposalData] = useState({
    offerAcctList: [],
    deniedAcctList: [],
    updateDay: null,
  });

  const fetchProposalData = useCallback(
    async (table) => {
      return dispatch(asyncReadDataFetch({ table })).unwrap();
    },
    [dispatch, asyncReadDataFetch]
  );

  const getAdditionalAcctInfo = useCallback(
    async (accountCode) => {
      const account = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'account',
          eqKey: 'code',
          eqValue: accountCode,
        })
      )
        .unwrap()
        .then((res) => res[0]);

      const { name, mobile } = account;

      return {
        account_name: name,
        account_contact_information1: mobile,
      };
    },
    [dispatch, asyncReadFilteredDataFetch]
  );

  const mergeAdditionalAcctInfo = useCallback(
    async (proposalData) => {
      return Promise.all(
        proposalData.map(async (item) => {
          const { account_code } = item;

          if (account_code.lenth === 0 || !account_code) {
            return;
          }

          const key = uuid();
          const additionalAcctInfo = await getAdditionalAcctInfo(account_code);
          return { ...item, ...additionalAcctInfo, key };
        })
      );
    },
    [getAdditionalAcctInfo]
  );

  const handleOfferAcctList = useCallback(async () => {
    const fetchedProposeData = await fetchProposalData('propose_data');
    const dataWithAdditionalAcctInfo = await mergeAdditionalAcctInfo(
      fetchedProposeData
    );
    const offerAcctList = dataWithAdditionalAcctInfo.filter((item) => !!item);
    return offerAcctList;
  }, [fetchProposalData, mergeAdditionalAcctInfo]);

  const handleDiniedAcctList = useCallback(async () => {
    const fetchedProposeReject = await fetchProposalData('propose_reject');
    const dataWithAdditionalAcctInfo = await mergeAdditionalAcctInfo(
      fetchedProposeReject
    );
    const diniedAcctList = dataWithAdditionalAcctInfo.filter((item) => !!item);
    return diniedAcctList;
  }, [fetchProposalData, mergeAdditionalAcctInfo]);

  useEffect(() => {
    const getProposalData = async () => {
      const offerAcctList = await handleOfferAcctList();
      const deniedAcctList = await handleDiniedAcctList();
      const updateDay = offerAcctList[0] ? offerAcctList[0].update_day : null;

      setProposalData({
        offerAcctList,
        deniedAcctList,
        updateDay,
      });
    };
    getProposalData();
  }, [handleOfferAcctList, handleDiniedAcctList, setProposalData]);

  return [proposalData];
};

export default useProposalData;
