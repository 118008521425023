import { useDispatch } from 'react-redux';
import { asyncCreateDataFetch } from '../redux/modules/shared/createData';
import { asyncReadFilteredDataFetch } from '../redux/modules/shared/readFilteredData';
import { asyncReadSpecificDataFetch } from '../redux/modules/shared/readSpecificData';
import { asyncUpdateDataFetch } from '../redux/modules/shared/updateData';
import ecount from '../ecount';

const usePurchaseData = () => {
  const dispatch = useDispatch();
  // const { savePurchase } = ecount();

  const updatePurchaseOrderProduct = async (
    purchaseOrderId,
    productId,
    productQuantity
  ) => {
    try {
      const purchaseOrderProductId = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'purchase_order_product',
          eqKey: 'purchase_order_id',
          eqValue: purchaseOrderId,
        })
      )
        .unwrap()
        .then((res) => {
          let result = '';
          for (const resProduct of res) {
            if (resProduct.product_id === productId) {
              result = resProduct.id;
            }
          }
          return result;
        });
      await dispatch(
        asyncUpdateDataFetch({
          table: 'purchase_order_product',
          id: purchaseOrderProductId,
          product_quantity: productQuantity,
        })
      );
    } catch (error) {
      throw new Error(error);
    }
  };

  const createPurchaseOrderProduct = async (
    purchaseOrderId,
    productId,
    productQuantity
  ) => {
    try {
      await dispatch(
        asyncCreateDataFetch({
          table: 'purchase_order_product',
          purchase_order_id: purchaseOrderId,
          product_id: productId,
          product_quantity: productQuantity,
        })
      );
    } catch (error) {
      throw new Error(error);
    }
  };

  const createDupPurchaseOrder = async (id) => {
    try {
      const originPurchaseOrder = await dispatch(
        asyncReadSpecificDataFetch({ table: 'purchase_order', id })
      )
        .unwrap()
        .then((res) => res[0]);
      const dupPurchaseOrderId = await dispatch(
        asyncCreateDataFetch({
          table: 'purchase_order',
          purchase_order_date: originPurchaseOrder.purchase_order_date,
          delivery_date: originPurchaseOrder.delivery_date,
          admin_id: originPurchaseOrder.admin_id,
          account_id: originPurchaseOrder.account_id,
          warehouse_id: originPurchaseOrder.warehouse_id,
          deal_type: originPurchaseOrder.deal_type,
        })
      )
        .unwrap()
        .then((res) => res[0].id);
      return dupPurchaseOrderId;
    } catch (error) {
      throw new Error(error);
    }
  };

  const updateJustStatus = async (id) => {
    try {
      await dispatch(
        asyncUpdateDataFetch({ id, table: 'purchase_order', status: 1 })
      );
    } catch (error) {
      throw new Error(error);
    }
  };

  const createPurchase = async (purchaseForm, productDetails) => {
    try {
      const purchase = await dispatch(
        asyncCreateDataFetch({
          table: 'purchase',
          purchase_date: purchaseForm.purchaseDate,
          admin_id: purchaseForm.adminId,
          account_id: purchaseForm.accountId,
          warehouse_id: purchaseForm.warehouseId,
          deal_type: purchaseForm.dealType,
        })
      )
        .unwrap()
        .then((res) => res[0]);
      for (const product of productDetails) {
        await dispatch(
          asyncCreateDataFetch({
            table: 'purchase_product',
            purchase_id: purchase.id,
            product_id: product.productId,
            product_quantity: product.productQuantity,
          })
        );
      }
      // savePurchase(purchase);
      return purchase;
    } catch (error) {
      throw new Error(error);
    }
  };

  return {
    updateJustStatus,
    createPurchase,
    createDupPurchaseOrder,
    createPurchaseOrderProduct,
    updatePurchaseOrderProduct,
  };
};

export default usePurchaseData;
