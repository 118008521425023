import React, { useMemo } from 'react';
import {
  SolutionOutlined,
  FileAddOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import LayoutComponent from '../../shared/layout/components/LayoutComponent';
import Description from '../components/Description';

const UpdateAccount = () => {
  // 해당 table 선언하기
  const table = 'account';
  // 해당 id값 받아오기
  const { id } = useParams();
  // 해당 pageType
  const pageType = 'update';

  const breadCrumbs = useMemo(
    () => [
      {
        breadCrumbIcon: <SolutionOutlined />,
        breadCrumbNav: ' 거래처',
      },
      {
        breadCrumbIcon: <EditOutlined />,
        breadCrumbNav: ' 수정',
      },
    ],
    []
  );

  return (
    <LayoutComponent breadCrumbs={breadCrumbs}>
      <div style={{ padding: '20px' }}>
        <Description id={id} table={table} pageType={pageType} />
      </div>
    </LayoutComponent>
  );
};

export default UpdateAccount;
