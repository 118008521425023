import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popconfirm, Popover } from 'antd';
import { useHistory } from 'react-router-dom';
import { FileExcelOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { asyncReadSpecificDataFetch } from '../../redux/modules/shared/readSpecificData';
import { asyncReadFilteredDataFetch } from '../../redux/modules/shared/readFilteredData';
import downloadExcelFile from '../../utils/downloadExcelFile';

const StatusColumnValue = (props) => {
  const { purchaseOrderId, status } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { handlePurchaseOrderForm } = downloadExcelFile();

  const getProductInfoData = async () => {
    try {
      const purchaseOrderProductData = await dispatch(
        asyncReadFilteredDataFetch({
          table: 'purchase_order_product',
          eqKey: 'purchase_order_id',
          eqValue: purchaseOrderId,
        })
      )
        .unwrap()
        .then((res) => res);
      const productInfoData = await Promise.all(
        purchaseOrderProductData.map(async (item) => {
          const productData = await dispatch(
            asyncReadSpecificDataFetch({
              table: 'product',
              id: item.product_id,
            })
          )
            .unwrap()
            .then((res) => res[0]);
          const price = Math.round(productData.incoming_price / 1.1);
          const supply = Math.round(price * item.product_quantity);
          const vat = Math.round(supply * 0.1);
          const total = Math.round(supply + vat);
          return {
            code: productData.code,
            name: productData.name,
            quantity: item.product_quantity,
            price,
            supply,
            vat,
            total,
            standard: productData.standard,
            unit: productData.unit,
          };
        })
      );
      return productInfoData;
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const handleClickExcelButton = async () => {
    try {
      // 엑셀 헤더 데이터
      const headerData = {};
      const [accountId, date] = await dispatch(
        asyncReadSpecificDataFetch({
          table: 'purchase_order',
          id: purchaseOrderId,
        })
      )
        .unwrap()
        .then((res) => [res[0].account_id, res[0].purchase_order_date]);
      const [account, representative] = await dispatch(
        asyncReadSpecificDataFetch({ table: 'account', id: accountId })
      )
        .unwrap()
        .then((res) => [res[0].name, res[0].representative]);
      headerData.account = account;
      headerData.representative = representative;
      headerData.date = date;

      const productInfoData = await getProductInfoData();

      // 엑셀 상품정보 데이터
      const contentData = productInfoData.map((item) => ({
        code: item.code,
        name: item.name,
        quantity: item.quantity,
        price: item.price,
        vat: item.vat,
        supply: item.supply,
        standard: item.standard,
        unit: item.unit,
      }));

      // 엑셀 합계 데이터
      const footerData = productInfoData.map((item) => ({
        quantity: item.quantity,
        supply: item.supply,
        vat: item.vat,
        total: item.total,
      }));

      handlePurchaseOrderForm(headerData, contentData, footerData);
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  return (
    <Popover
      placement="bottom"
      trigger="click"
      content={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }}>
            <Button
              size="small"
              style={{
                marginRight: '5px',
                backgroundColor: '#1d6f42',
                color: 'white',
              }}
              onClick={handleClickExcelButton}
            >
              <FileExcelOutlined />
              Excel
            </Button>
            <Button
              disabled={status === 1}
              size="small"
              onClick={() => {
                history.push(
                  `purchase/create/purchase-order-id/${purchaseOrderId}`
                );
              }}
            >
              구매등록
            </Button>
          </div>
        </div>
      }
    >
      {status === 1 ? (
        <Button type="link">완료</Button>
      ) : (
        <Button type="link">진행중</Button>
      )}
    </Popover>
  );
};

export default StatusColumnValue;

StatusColumnValue.propTypes = {
  purchaseOrderId: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
};
