import React from 'react';
import { Modal, Result, Button } from 'antd';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useModal from '../../hook/useModal';

import { logoutReducer } from '../../redux/modules/auth/signinUser';
import { asyncSignoutUserFetch } from '../../redux/modules/auth/signoutUser';

const ButtonStyle = styled(Button)`
  width: 60%;
  padding: 8px;
  height: 40px;
  font-weight: 700;
  font-size: 14.5px;
  background: black;
  color: white;
`;

const PasswordUpdateModal = () => {
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const history = useHistory();

  // 로그아웃 시키는 함수.
  const handleSubmit = async () => {
    dispatch(asyncSignoutUserFetch()).then((res) => {
      closeModal();
      dispatch(logoutReducer(res.payload));
      history.push('/');
    });
  };

  return (
    <Modal
      title="비밀번호 변경"
      centered
      open
      onCancel={() => closeModal()}
      footer={[<div key="modal" />]}
    >
      <Result
        title="비밀번호를 변경했습니다."
        subTitle="새로운 비밀번호로 다시 로그인 해 주세요."
        status="success"
        extra={[
          <ButtonStyle key="result" onClick={() => handleSubmit()}>
            확인
          </ButtonStyle>,
        ]}
      />
    </Modal>
  );
};

export default PasswordUpdateModal;
