import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Form, Input } from 'antd';

import { useSelector } from 'react-redux';
import ProfileForm from './ProfileForm';

const ButtonStyle = styled(Button)`
  width: 100%;
  padding: 8px;
  height: 40px;
  font-weight: 700;
  font-size: 14.5px;
  background: #001528;
  color: white;
  margin-top: 20px;
`;

const SignupForm = (props) => {
  const { onFinish, onFinishFailed } = props;
  const { isLoadingSignupUser } = useSelector((state) => state.signupUser);

  return (
    <Form
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="on"
      style={{ width: '100%', padding: '0 30px' }}
    >
      <div style={{ marginTop: '16px' }}>이메일</div>
      <Form.Item
        noStyle
        name="email"
        rules={[
          { required: true, message: '이메일을 입력해주세요.' },
          { type: 'email', message: '이메일 형식에 맞게 입력해주세요.' },
        ]}
      >
        <Input
          size="large"
          placeholder="예) example@gmail.com"
          style={{ borderBottom: '1px solid lightGray', borderRadius: '0px' }}
          bordered={false}
        />
      </Form.Item>
      <div style={{ marginTop: '16px' }}>비밀번호</div>
      <Form.Item
        noStyle
        name="password"
        rules={[{ required: true, message: '비밀번호를 입력해주세요.' }]}
      >
        <Input.Password
          size="large"
          style={{ borderBottom: '1px solid lightGray', borderRadius: '0px' }}
          bordered={false}
        />
      </Form.Item>
      <div style={{ marginTop: '16px' }}>비밀번호 확인</div>
      <Form.Item
        noStyle
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          { required: true, message: '비밀번호 확인을 입력해주세요.' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('비밀번호와 동일하지 않습니다.'));
            },
          }),
        ]}
      >
        <Input.Password
          size="large"
          style={{ borderBottom: '1px solid lightGray', borderRadius: '0px' }}
          bordered={false}
        />
      </Form.Item>
      <ProfileForm />
      <Form.Item style={{ marginTop: '16px' }}>
        {isLoadingSignupUser ? (
          <ButtonStyle htmlType="submit" loading disabled>
            회원가입
          </ButtonStyle>
        ) : (
          <ButtonStyle htmlType="submit">회원가입</ButtonStyle>
        )}
      </Form.Item>
    </Form>
  );
};

export default SignupForm;

SignupForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onFinishFailed: PropTypes.func.isRequired,
};
